import {
    BG_IMAGE, USER_IMAGE, SCHEME, CLOSE_HREF,
    BUTTON_ACTION_ENUM, BUTTONS,
    TEMPLATES, DEEP_LINK_ENUM, DARK,
    GREEN, SMOKE, DEFAULT_FONT, DEFAULT_GRADIENT, CANCEL_HREF, BACKGROUND_SIZE, BACKGROUND_SIZE_ENUM
} from './constants';

const { CLOSE, CANCEL } = BUTTON_ACTION_ENUM;

const { Single } = BUTTONS;

const { COVER, INTERSTITIAL, STICKY_BOTTOM, STICKY_TOP, MODAL } = TEMPLATES;

const { URI } = DEEP_LINK_ENUM;

const hasContent = (prop) => typeof prop !== 'undefined' && prop.length > 0;


const BUTTON_STYLES = (background = GREEN, color = SMOKE) => `display: inline-block;text-align:center;
box-sizing: border-box;cursor:pointer;font-size: 18px;font-weight: 600;
background: ${background};color: ${color}; border-radius: 3px; border: none; width: 100%; padding: 16px;`;

const WITH_TITLE = ({title, title_color = DARK}) => hasContent(title) ? `<span style="color:${title_color};">
${title}
</span>`: '';

const WITH_DESCRIPTION = ({description, description_color = DARK}) => hasContent(description) ? `<span style="color:${description_color};">
${description}
</span>` : '';

const WITH_ANCHOR = (content, styles = '', href = CLOSE_HREF) => `<a role="button" style="text-decoration: none; color: #454545; ${styles}" href="${href}">${hasContent(content) ? content : 'Text'}</a>`;

const CLOSE_BUTTON_TEXT = WITH_ANCHOR(`&#10005;`, '', `${SCHEME}xclose`);

const WITH_HTML_HOLDER = (content, fontFamily = DEFAULT_FONT) => `
    <!DOCTYPE html>
    <html>
        <head>
            <style>
                *{
                    box-sizing: border-box;
                }
            </style>
        </head>
        <body style="font-family: ${fontFamily};margin: 0;padding: 0;">
            ${content}
        </body>
    </html>
`;

const CLOSE_BUTTON = `<button style="box-shadow: 0 0 12px 0 rgba(0,0,0,0.5); position: absolute; border-radius: 50%; border: none; width: 28px; height: 28px; right: 8px; top: 8px; background: #F5F5F5; font-size: 22px; line-height: 28px; padding: 1px; margin: 0;">${CLOSE_BUTTON_TEXT}</button>`;
const RAISED_CLOSE_BUTTON = `<button style="box-shadow: 0 0 12px 0 rgba(0,0,0,0.5);position: absolute; border-radius: 50%; border: none; width: 28px; height: 28px; right: -12px; top: -12px; background: #F5F5F5; font-size: 22px; line-height: 28px; padding: 1px;">${CLOSE_BUTTON_TEXT}</button>`;

const WITH_GRADIENT = (content, gradient = DEFAULT_GRADIENT) => `
    <div style="position: absolute;width:100%;height:100%;background: ${gradient};">
        ${content}
    </div>
`;

const WITH_CONTENT_HOLDER_HAVING_BG = (content, background, background_size=BACKGROUND_SIZE_ENUM.COVER, platform) => `
    <div style="position: ${platform === "android" ? "relative" : "absolute"};width:100%;height:100%;background: ${background};background-size: ${BACKGROUND_SIZE[background_size]};">
        ${content}
    </div>
`;

const WITH_MODEL_BACKDROP = (content) => `
    <div style="position:relative; width:100%;height:100%; background: transparent; text-align: center;">
        ${content}
    </div>
`;

const WITH_MODEL_CONTENT_HOLDER_HAVING_BG = (content, background) => `
    <div style="position: absolute; left: 32px; right: 32px; margin-top: 32px; width: calc(100% - 64px); height:calc(100% - 64px); background: ${background};background-size: cover;">
        ${content}
    </div>
`;

const WITH_MODEL_CONTENT_HOLDER = (content, background) => `
    <div style="background: ${background};top: 50%;transform: translateY(-50%); position: absolute; left: 32px; right: 32px; width: calc(100% - 64px); min-height:30%">
        ${content}
    </div>
`;

const SINGLE_BUTTON_CONTENT = (props = {}) => `
    <div style="width: 100%;position: absolute;padding: 32px; bottom: 16px;">
        ${hasContent(props.title) ? `<p style="padding: 16px; font-size: 22px; margin: 8px 0; border-radius: 2px; font-weight: 700; color: #75C221; background: #4D4D4D; display: inline-block">${WITH_TITLE(props)}</p>` : ''}
        ${hasContent(props.description) ? `<p style="font-size: 18px;color:#F1F1F1;">${WITH_DESCRIPTION(props)}</p>` : ''}
        ${BUTTON(props.button_left)}
    </div>
`;

const hrefForButton = (action, redirect_type, href, text) => {
    if(action === CLOSE){
        return `${CLOSE_HREF}buttonName=${text}`;
    } else if(action === CANCEL){
        return `${CANCEL_HREF}buttonName=${text}`;
    }else{
        if(redirect_type === URI){
            return `${SCHEME}dl_url?uri=${href}&action=android.intent.action.VIEW&buttonName=${text}`;
        }else{
            return `${SCHEME}dl?activity=${href}&buttonName=${text}`;
        }
    }
};

const BUTTON = ({action, redirect_type, color, background, text, href, styles = ""}) => WITH_ANCHOR(
    text,
    BUTTON_STYLES(background, color) + styles,
    hrefForButton(action, redirect_type, href, text)
);

const MULTI_BUTTON = ({button_left, button_right}) => `
    <div style="position: relative; width: 100%;">
        ${BUTTON({...button_left, styles: `float: left; width: 45%; ` + button_left.styles})}
        ${BUTTON({...button_right, styles: `float: right; width: 45%;` + button_right.styles})}
    </div>
`;

const CONTENT_WITH_MULTI_BUTTON = (props) => `
    <div style="width: 100%;position: absolute;padding: 32px; bottom: 16px;">
        <p style="padding: 16px; font-size: 22px; margin: 8px 0; border-radius: 2px; font-weight: 700; color: #75C221; background: #4D4D4D; display: inline-block">${WITH_TITLE(props)}</p>
        <p style="font-size: 18px;color:#F1F1F1;">${WITH_DESCRIPTION(props)}</p>
        ${MULTI_BUTTON(props)}
    </div>
`;

const MODAL_WITH_IMAGE_CONTENT = ({ with_icon, icon, button_left, ...others }) => `
    <div style="padding: 32px;">
        ${with_icon ? `<img style="width: 50%; max-width: 240px; height: 100%; border-radius: 50%;" src="${hasContent(icon) ? icon : USER_IMAGE}" alt="icon" />` : ''}
        <p style="font-size: 14px;">${WITH_DESCRIPTION(others)}</p>
        ${BUTTON({...button_left, styles: 'font-size: 16px;' + button_left.styles})}
    </div>
`;

const STICKY_BUTTON_STYLES = (background = GREEN, color = SMOKE) => `display: inline-block;text-align:center;
box-sizing: border-box;cursor:pointer;font-size: 14px;font-weight: 600;
background: ${background};color: ${SMOKE}; border-radius: 3px; border: none; width: 100%; padding: 8px;`;

const STICKY_BUTTON = ({action, color, background, text, href, redirect_type, styles = ""}) => WITH_ANCHOR(
    text,
    STICKY_BUTTON_STYLES(background, color) + styles,
    hrefForButton(action, redirect_type, href, text)
);

const STICKY_CONTENT = ({
                            font_family, background, with_icon, icon,
                            button_left, button_right, buttons, ...others
                        }, isBottom = false) => `
    <div style="position:${isBottom ? 'fixed;bottom: 0': 'absolute'};padding: 16px; background: ${background};left:0;right:0;background-size: cover;">
        <div style="position: relative; width: 100%; margin-bottom: 16px; min-height: 65px;">
            <div style="position: absolute; width: ${with_icon ? 20 : 0}%;">
                <img style="max-width: 64px; vertical-align: middle;border-radius: 50%; width: 100%;" src="${hasContent(icon) ? icon : 'https://via.placeholder.com/64'}" />
            </div>
            <div style="position: absolute; left:${with_icon ? 25 : 0}%; width: ${with_icon ? 75 : 100}%;">
                <p style="font-size: 14px;margin: 0"><strong>${WITH_TITLE(others)}</strong></p>
                <p style="font-size: 12px;">${WITH_DESCRIPTION(others)}</p>
            </div>
        </div>
        <div style="position: relative; width: 100%;">
            ${STICKY_BUTTON({...button_left, styles: 'float: left;width:48%;' + button_left.styles})}
            ${STICKY_BUTTON({...button_right, styles: 'float: right;width:48%;' + button_right.styles})}
        </div>
    </div>
`;

export const templateGenerator = (props, html) => {
    const {
        font_family = DEFAULT_FONT, background_image = '', background = '', background_size = BACKGROUND_SIZE_ENUM.COVER,
        gradient = DEFAULT_GRADIENT,
        buttons, template, is_custom = false, platform
    }  = props;
    if(is_custom){
        return html;
    }
    const finalBackground = hasContent(background_image) ? `url(${background_image}) center center no-repeat` : (hasContent(gradient) ? gradient : background);
    switch (template) {
        case INTERSTITIAL:
            return WITH_HTML_HOLDER(
                WITH_MODEL_BACKDROP(
                    WITH_MODEL_CONTENT_HOLDER_HAVING_BG(
                        WITH_GRADIENT(
                            RAISED_CLOSE_BUTTON +
                            SINGLE_BUTTON_CONTENT(props)
                        ),
                        finalBackground
                    ),
                    gradient
                ),
                font_family
            );
        case STICKY_TOP:
        case STICKY_BOTTOM:
            const finalProps = {...props, background: finalBackground};
            return WITH_HTML_HOLDER(
                STICKY_CONTENT(finalProps, template === STICKY_BOTTOM),
                font_family
            );
        case MODAL:
            return WITH_HTML_HOLDER(
                WITH_MODEL_BACKDROP(
                    WITH_MODEL_CONTENT_HOLDER(
                        RAISED_CLOSE_BUTTON +
                        MODAL_WITH_IMAGE_CONTENT(props),
                        finalBackground
                    )
                ),
                font_family
            );
        case COVER:
        default:
            const BUTTON_CONTENT = buttons === Single ? SINGLE_BUTTON_CONTENT(props)
                : CONTENT_WITH_MULTI_BUTTON(props);
            return WITH_HTML_HOLDER(
                WITH_CONTENT_HOLDER_HAVING_BG(
                    WITH_GRADIENT(
                        CLOSE_BUTTON + BUTTON_CONTENT,
                        gradient
                    ),
                    finalBackground,
                    background_size,
                    platform
                ),
                font_family
            );
    }
};