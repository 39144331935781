/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    USER_PROFILE_FAILED, USER_PROFILE_PENDING,
    USER_PROFILE_SESSION_TIMELINE, USER_PROFILE_SESSIONS_LIST, USER_PROFILE_SESSIONS_TIME_SERIES,
    USER_PROFILE_SESSION_INFO, USER_PROFILE_USP_PERFORMANCE, USER_PROFILE_USP_STABILITY, USER_PROFILE_USP_FUNCTIONALITY,
    USER_PROFILE_USP_USABILITY, USER_PROFILE_CRASH_INFO, USER_PROFILE_TRANSACTION_INFO,
    RESET_USER_PROFILE, USER_PROFILE_SESSION_TIMELINE_FAILED, USER_PROFILE_SESSION_TIMELINE_PENDING,
    USER_PROFILE_STATIC_BASIC_INFO, USER_PROFILE_DYNAMIC_BASIC_INFO,
    USER_PROFILE_SESSIONS_LIST_FAILED, USER_PROFILE_SESSIONS_LIST_PENDING, RESET_PROFILE_SESSIONS_LIST,
    UPDATE_SESSIONS_PAGINATION_COUNT, UPDATE_SESSIONS_PAGINATION_ORDER_BY,
    UPDATE_SESSIONS_PAGINATION_PAGE, UPDATE_SESSIONS_PAGINATION_SORT_ORDER, MARK_AS_TEST_DEVICE, REMOVE_AS_TEST_DEVICE,
    UPDATE_NICK_TEST_DEVICE
} from './actionTypes';
import {USP_ENUM} from '../../../../../../../constants';
import {getUserProfileStaticBasicInfoAPI, getUserProfileDynamicBasicInfoAPI, getUserProfileSessionInfoAPI,
    getUserProfileCrashInfoAPI, getUserProfileSessionsAPI,
    getUserProfileSessionTimelineAPI, getUserProfileSessionTimeSeriesAPI,
    getUserProfileUSPValuesAPI, getUserProfileTransactionInfoAPI
} from './api';
import {addTestDeviceAPI, removeTestDeviceAPI, updateTestDeviceAPI} from "../../../../../../../api";
import {getAppTestDevices} from "../../../Apps/App/actions";
import {updateGlobalModes} from "../../../Filters/actions";
/**
 * FIXME: USERS_PROFILE_PENDING and USERS_PROFILE_FAILED should be separate for all calls
 */

/**
 *
 * @param appId
 * @param userId
 * @returns {function(*, *)}
 */
export const getUserProfileStaticBasicInfo = (appId, userId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                USER_PROFILE_PENDING,
                USER_PROFILE_STATIC_BASIC_INFO,
                USER_PROFILE_FAILED
            ],
            payload: {
                promise: getUserProfileStaticBasicInfoAPI(getState().auth, appId, userId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param userId
 * @returns {function(*, *)}
 */
export const getUserProfileDynamicBasicInfo = (appId, userId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                USER_PROFILE_PENDING,
                USER_PROFILE_DYNAMIC_BASIC_INFO,
                USER_PROFILE_FAILED
            ],
            payload: {
                promise: getUserProfileDynamicBasicInfoAPI(getState().auth, appId, userId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param userId
 * @returns {function(*, *)}
 */
export const getUserProfileSessionInfo = (appId, userId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                USER_PROFILE_PENDING,
                USER_PROFILE_SESSION_INFO,
                USER_PROFILE_FAILED
            ],
            payload: {
                promise: getUserProfileSessionInfoAPI(getState().auth, appId, userId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param userId
 * @returns {function(*, *)}
 */
export const getUserProfileCrashInfo = (appId, userId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                USER_PROFILE_PENDING,
                USER_PROFILE_CRASH_INFO,
                USER_PROFILE_FAILED
            ],
            payload: {
                promise: getUserProfileCrashInfoAPI(getState().auth, appId, userId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param userId
 * @returns {function(*, *)}
 */
export const getUserProfileTransactionInfo = (appId, userId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                USER_PROFILE_PENDING,
                USER_PROFILE_TRANSACTION_INFO,
                USER_PROFILE_FAILED
            ],
            payload: {
                promise: getUserProfileTransactionInfoAPI(getState().auth, appId, userId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param userId
 * @returns {function(*, *)}
 */
export const getUserProfileSessionTimeSeries = (appId, userId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                USER_PROFILE_PENDING,
                USER_PROFILE_SESSIONS_TIME_SERIES,
                USER_PROFILE_FAILED
            ],
            payload: {
                promise: getUserProfileSessionTimeSeriesAPI(getState().auth, appId, userId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param userId
 * @param uspType
 * @returns {function(*, *)}
 */
export const getUserProfileUSPData = (appId, userId, uspType) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        const uspMetric = USP_ENUM[uspType.split('_').slice(-1)[0]];
        return dispatch({
            types: [
                USER_PROFILE_PENDING,
                uspType,
                USER_PROFILE_FAILED
            ],
            payload: {
                promise: getUserProfileUSPValuesAPI(getState().auth, appId, userId, uspMetric , filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param sessionId
 * @returns {function(*, *)}
 */
export const getUserProfileSessionTimeLine = (appId, sessionId, otherParams) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, ...otherParams};
        return dispatch({
            types: [
                USER_PROFILE_SESSION_TIMELINE_PENDING,
                USER_PROFILE_SESSION_TIMELINE,
                USER_PROFILE_SESSION_TIMELINE_FAILED
            ],
            payload: {
                promise: getUserProfileSessionTimelineAPI(getState().auth, appId, sessionId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param userId
 * @param dataVersion
 * @param orderBy
 * @param sortOrder
 * @param countPerPage
 * @returns {function(*, *)}
 */
export const getUserProfileSessions = (appId, userId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters};
        return dispatch({
            types: [
                USER_PROFILE_SESSIONS_LIST_PENDING,
                USER_PROFILE_SESSIONS_LIST,
                USER_PROFILE_SESSIONS_LIST_FAILED
            ],
            payload: {
                promise: getUserProfileSessionsAPI(getState().auth, appId, userId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
            }
        });
    };
};

/**
 *
 * @returns {function(*, *)}
 */
export const resetUserProfileSessions = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_PROFILE_SESSIONS_LIST,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @returns {function(*, *)}
 */
export const resetUserProfile = () => {
    return (dispatch, getState) => {
        dispatch(resetUserProfileSessions()); //resetting user sessions to avoid mutability
        return dispatch({
            type: RESET_USER_PROFILE,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param userId
 * @returns {function(*, *)}
 */
export const loadUserProfile = (appId, userId) => {

    return (dispatch, getState) => {

        dispatch(updateGlobalModes()); //FIXME: user related apis still depending upon *modes*

        //dispatch(resetHeading()); //Resets Heading for User Profile
        /**
         * Basic Info
         */
        dispatch(getUserProfileStaticBasicInfo(appId, userId));
        dispatch(getUserProfileDynamicBasicInfo(appId, userId));
        dispatch(getUserProfileSessionInfo(appId, userId));
        dispatch(getUserProfileCrashInfo(appId, userId));
        //dispatch(getUserProfileTransactionInfo(appId, userId));

        /**
         * USP data
         */
        //dispatch(getUserProfileUSPData(appId, userId, USER_PROFILE_USP_FUNCTIONALITY));
        //dispatch(getUserProfileUSPData(appId, userId, USER_PROFILE_USP_USABILITY));
        //dispatch(getUserProfileUSPData(appId, userId, USER_PROFILE_USP_STABILITY));
        //dispatch(getUserProfileUSPData(appId, userId, USER_PROFILE_USP_PERFORMANCE));

        /**
         * User Session Time Series
         */
        dispatch(getUserProfileSessionTimeSeries(appId, userId));

        /**
         * Sessions
         */
        dispatch(getUserProfileSessions(appId, userId)); //TODO: pagination

        //dispatch(getUserProfileSessionTimeLine(userId, null)); TODO:
    };

};

/**
 *
 * @param dataVersion
 * @returns {function(*, *)}
 */
export function updatePaginationPage(dataVersion) {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_SESSIONS_PAGINATION_PAGE,
            payload: dataVersion,
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @param orderBy
 * @returns {function(*, *)}
 */
export function updatePaginationOrderBy(orderBy) {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_SESSIONS_PAGINATION_ORDER_BY,
            payload: orderBy,
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @param sortOrder
 * @returns {function(*, *)}
 */
export function updatePaginationSortOrder(sortOrder) {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_SESSIONS_PAGINATION_SORT_ORDER,
            payload: sortOrder,
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @param count
 * @returns {function(*, *)}
 */
export function updatePaginationCount(count) {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_SESSIONS_PAGINATION_COUNT,
            payload: count,
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @param appId
 * @param testDevice
 * @returns {function(*, *)}
 */
export const markAsTestDevice = (appId, testDevice) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                USER_PROFILE_PENDING,
                MARK_AS_TEST_DEVICE,
                USER_PROFILE_FAILED
            ],
            payload: {
                promise: addTestDeviceAPI(appId, getState().auth, filters, testDevice)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //if test device added reloading devices
                    if (data) {
                        dispatch(getAppTestDevices(appId));
                    }
                }
            }
        });
    };
};

/**
 *
 * @param appId
 * @param deviceId
 * @returns {function(*, *)}
 */
export const removeAsTestDevice = (appId, deviceId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                USER_PROFILE_PENDING,
                REMOVE_AS_TEST_DEVICE,
                USER_PROFILE_FAILED
            ],
            payload: {
                promise: removeTestDeviceAPI(appId, getState().auth, deviceId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //if test device added reloading devices
                    if (data) {
                        dispatch(getAppTestDevices(appId));
                    }
                }
            }
        });
    };
};

/**
 *
 * @param appId
 * @param deviceId
 * @param patch
 * @returns {function(*, *)}
 */
export const updateTestDevice = (appId, deviceId, patch) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                USER_PROFILE_PENDING,
                UPDATE_NICK_TEST_DEVICE,
                USER_PROFILE_FAILED
            ],
            payload: {
                promise: updateTestDeviceAPI(appId, getState().auth, deviceId, filters, patch)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //if test device added reloading devices
                    if (data) {
                        dispatch(getAppTestDevices(appId));
                    }
                }
            }
        });
    };
};