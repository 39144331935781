/**
 * Created by jyothi on 1/11/17.
 */

import {
    QUERY_BUILDER_ATTRIBUTES,
    QUERY_BUILDER_ATTRIBUTE_VALUES, QUERY_BUILDER_SAVE,
    QUERY_BUILDER_EVENTS,QUERY_BUILDER_EVENT_ATTRIBUTES,
    QUERY_BUILDER_EVENT_ATTRIBUTE_VALUES,QUERY_BUILDER_UPDATE_QUERY,
    QUERY_BUILDER_RESET_QUERY, QUERY_BUILDER_UPDATE_QUERY_TYPE,
    CUSTOM_EVENT_ENUM, QUERY_BUILDER_COUNT
} from './actionTypes';

const INITIAL_QUERY_STATE = {
    user: {},
    session: {},
    event: {}
};

const INITIAL_STATE = {
    count: 0,
    user_attributes: [],
    session_attributes: [],
    attribute_values: {},
    events: [],
    event_attributes: {},
    event_attribute_values: [],
    of: CUSTOM_EVENT_ENUM.users,
    query: {...INITIAL_QUERY_STATE},
    saved: false
};

function customGroupingReducer(state = INITIAL_STATE, action = {}) {
    let query = state.query;
    switch (action.type) {

        case QUERY_BUILDER_ATTRIBUTES:
            const attributes = [ ...action.payload.user, ...action.payload.session ];
            return {
                ...state,
                user_attributes: action.payload.user,
                session_attributes: action.payload.session,
                attribute_values: attributes.reduce((a, b) => {a[b] = []; return a;}, {})
            };

        case QUERY_BUILDER_ATTRIBUTE_VALUES:
            let selectedValues = (query.user.hasOwnProperty(action.meta.attribute) ? query.user[action.meta.attribute] : query.session[action.meta.attribute]) || [];
            return {
                ...state,
                attribute_values: {
                    ...state.attribute_values,
                    [action.meta.attribute]: [ ...selectedValues, ...action.payload ]
                }
            };

        case QUERY_BUILDER_EVENTS:
            return {
                ...state,
                events: action.payload,
                event_attributes: action.payload.reduce((a, b) => {a[b] = []; return a;}, {}),
                event_attribute_values: action.payload.reduce((a, b) => {a[b] = {}; return a;}, {}),
            };

        case QUERY_BUILDER_EVENT_ATTRIBUTES:
            return {
                ...state,
                event_attributes: {
                    ...state.event_attributes,
                    [action.meta.event]: action.payload
                },
                event_attribute_values: {
                    ...state.event_attribute_values,
                    [action.meta.event]: action.payload.reduce((a, b) => {a[b] = []; return a;}, {})
                }
            };

        case QUERY_BUILDER_EVENT_ATTRIBUTE_VALUES:
            const { event, attribute } = action.meta;
            selectedValues = [];
            if(query.event[event] && query.event[event].hasOwnProperty(attribute)){
                selectedValues = query.event[event][attribute] || []
            }
            return {
                ...state,
                event_attribute_values: {
                    ...state.event_attribute_values,
                    [event]: {
                        ...state.event_attribute_values[event],
                        [attribute]: [...selectedValues, ...action.payload]
                    }
                }
            };

        case QUERY_BUILDER_UPDATE_QUERY:
            return {
                ...state,
                query: action.payload
            };

        case QUERY_BUILDER_RESET_QUERY:
            return {
                ...state,
                query: INITIAL_QUERY_STATE
            };

        case QUERY_BUILDER_UPDATE_QUERY_TYPE:
            return {
                ...state,
                of: action.payload
            };

        case QUERY_BUILDER_SAVE:
            return {
                ...state,
                saved: action.payload
            };

        case QUERY_BUILDER_COUNT:
            return {
                ...state,
                count: action.payload
            };

        default:
            return state;
    }
}

export default customGroupingReducer;