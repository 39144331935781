/**
 * Created by Rakesh Peela
 * Date: 05-Dec-2019
 * Time: 3:43 PM
 */

import React from 'react';
import {SketchPicker} from 'react-color';
import {Popper} from "@material-ui/core";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledColorSwatch = styled.div`
    padding: 5px;
    background: #f2f2f2;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
`;

const StyledPopover = styled.div`
    position: absolute;
    z-index: 2;
`;

const StyledColor = styled.div`
    width: 36px;
    height: 14px;
    border-radius: 2px;
    background: ${(props) => props.color};
`;

const StyledCover = styled.div`
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
`;

const defaultPresetColors = ["#EF5350", "#EC407A", "#AB47BC", "#7E57C2", "#5C6BC0", "#42A5F5", "#29B6F6", "#26C6DA", "#26A69A", "#66BB6A", "#9CCC65", "#D4E157"];

class CustomColorPickerModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            displayColorPicker: false,
            anchorEl: null
        }
    }

    handleChange = (color) => {
        this.props.handleColorChange(color);
    };

    handleClick = (e) => {
        this.setState({
            displayColorPicker: true,
            anchorEl: e.currentTarget
        })
    };

    handleClose = () => {
        this.setState({
            displayColorPicker: false,
            anchorEl: null
        })
    };

    render() {
        const {displayColorPicker, anchorEl} = this.state;
        return (
            <div>
                <StyledColorSwatch onClick={this.handleClick}>
                    <StyledColor color={this.props.color}/>
                </StyledColorSwatch>
                {displayColorPicker ?
                    <>
                        <StyledCover onClick={this.handleClose}/>
                        <Popper open={displayColorPicker} placement={"bottom"} anchorEl={anchorEl} style={{zIndex: 5}}>
                            <SketchPicker
                                presetColors={this.props.presetColors || defaultPresetColors}
                                color={this.props.color}
                                onChangeComplete={this.handleChange}/>
                        </Popper>
                    </>
                    : null}
            </div>
        );
    }
}

CustomColorPickerModule.propTypes = {
    handleColorChange: PropTypes.func,
    color: PropTypes.string,
};

export default CustomColorPickerModule;