/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Divider, Grid, Typography, Tooltip, IconButton, Button,
    TextField, Fab, FormControlLabel, Checkbox
} from "@material-ui/core";
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import Collapse from '@material-ui/core/Collapse';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import PersonAdd from '@material-ui/icons/PersonAdd';
import Person from '@material-ui/icons/Person';
import PersonOutline from '@material-ui/icons/PersonOutline';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SecurityIcon from '@material-ui/icons/Security';
import MinimizeIcon from '@material-ui/icons/Remove';
import Dialog from "../../../../../../../components/reusable/MaterialUi/Dialog";
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import red from "@material-ui/core/colors/red";
import classnames from "classnames";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { getGroupNameFromList, isValidEmail} from "../../../../../../../utils";
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";

const styles = theme => ({
    card: {
        width: '100%',
    },
    root: {
        padding: 0,
        '&:last-child': {
            paddingBottom: 0,
        }
    },
    action: {
        padding: 16
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    flexGrow: {
        flex: '1 1 auto',
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    nested: {
        paddingLeft: theme.spacing.unit * 6,
    },
});

class AddCustomer extends React.Component {

    state = {
        customer_id: "",
        addTriggered: false
    };

    handleEmailFieldChange = (e) => {
        this.setState({customer_id: e.target.value});
    };

    handleAddUser = () => {
        const { customer_id } = this.state;
        if(!isValidEmail(customer_id)){
            return;
        }
        const { params: { appId } } = this.props;
        this.props.addCustomer(appId, {
            customer_id: customer_id.trim()
        });
        this.setState({customer_id: ""}); //emptying dashboard object
    };

    render() {
        const { customer_id, addTriggered } = this.state;
        const { classes } = this.props;
        return (
            <Grid container justify="center">
                <Grid item xs={12} sm={8} md={6}>
                    <TextField
                        value={customer_id}
                        fullWidth
                        onChange={this.handleEmailFieldChange}
                        label="User Email"
                        type="text"
                        className={classes.textField}
                        required
                    />
                    <br/>
                    <Button
                        disabled={!isValidEmail(customer_id)}
                        style={{float: 'right'}}
                        variant="contained"
                        color="primary"
                        onClick={(e) => this.setState({addTriggered: true})}
                    >
                        Add User
                    </Button>
                    <Dialog
                        status={addTriggered}
                        handleConfirm={() => {
                            this.handleAddUser();
                            this.setState({addTriggered: false});
                        }}
                        title="Add User Confirmation"
                        handleClose={() => this.setState({addTriggered: false})}
                        confirmLabel="Confirm"
                        allowCancelLabel="Cancel"
                    >
                        <Typography>Are you sure want to give <strong>Full Access</strong> to <strong>{customer_id}</strong> ?</Typography>
                    </Dialog>
                </Grid>
            </Grid>
        );
    }
}

class TransferAppSection extends React.Component {

    state = {
        newOwnerId: "",
        continueAsUser: true,
        transferTriggered: false
    };

    handleTransferAppOwnership = (customer_id) => {
        const {newOwnerId} = this.state;
        if (!isValidEmail(newOwnerId)) {
            return;
        }
        const {params: {appId}} = this.props;
        this.props.transferAppOwnership(appId, {
            userId: customer_id,
            newOwnerId: newOwnerId.trim(),
            continueAsUser: this.state.continueAsUser
        });
        this.setState({newOwnerId: "", transferTriggered: false, continueAsUser: true}); //emptying dashboard object
    };

    handleContinueAsUser = () => {
        this.setState({
            continueAsUser: !this.state.continueAsUser
        })
    };

    handleSelectNewOwner = (customerId) => {
        this.setState({
            newOwnerId: customerId
        })
    };

    render() {
        const {newOwnerId, continueAsUser, transferTriggered} = this.state;
        const {classes, appState: {app: {basic_info: {customers = [], customer_id}}}} = this.props;
        return (
            <Grid container justify="center">
                {customers.length <= 0 && <Grid item xs={12} sm={8} md={6}>
                    <Typography variant={"body2"}>
                        You need to have at least <strong>ONE</strong> active User to Transfer App to.
                    </Typography>
                </Grid>}
                {customers.length > 0 && <Grid item xs={12} sm={8} md={6}>
                    <Typography variant={"body2"}>
                        Transfer application ownership to
                    </Typography>
                    <MultiSelect
                        options={customers.map(o => ({value: o.customer_id, label: o.customer_id}))}
                        value={newOwnerId}
                        single={true}
                        handleChange={this.handleSelectNewOwner}
                        placeholder="Select from Existing Customers"
                        clearable={false}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={continueAsUser}
                                onChange={this.handleContinueAsUser}
                                value="continueAsUser"
                                color={"primary"}
                            />
                        }
                        label="and continue as Regular User"
                    />
                    <br/>
                    <Button
                        disabled={!isValidEmail(newOwnerId)}
                        style={{marginLeft: 'auto'}}
                        variant="contained"
                        color="primary"
                        onClick={(e) => this.setState({transferTriggered: true})}
                    >
                        Proceed
                    </Button>
                    <Dialog
                        status={transferTriggered}
                        handleConfirm={() => {
                            this.handleTransferAppOwnership(customer_id);
                            this.setState({transferTriggered: false});
                        }}
                        title="Transfer App Ownership Confirmation"
                        handleClose={() => this.setState({transferTriggered: false})}
                        confirmLabel="Confirm"
                        allowCancelLabel="Cancel"
                    >
                        <Typography>Are you sure want
                            to <strong> {continueAsUser ? "" : "leave the Application and"} transfer
                                ownership</strong> to <strong>{newOwnerId}</strong> ?</Typography>
                    </Dialog>
                </Grid>}
            </Grid>
        );
    }
}

class Customers extends React.Component{

    state = {
        expanded: false,
        expandedTransfer: false,
        open: null,
        deleteTriggered: false,
        currentCustomer: null,
        currentDashboard: null,
        transferTriggered: false,
        newOwner: "",
        newCustomer: ""
    };

    handleClick = (customerId) => () => {
        this.setState({ open: this.state.open === customerId ? null : customerId });
    };

    handleExpandClick = (key) => {
        this.setState({ [key]: !this.state.expanded });
    };

    render(){
        const {
            session: { user: { name, email }},
            params: { appId },
            appState: { app: { basic_info: { customers = [], customer_id } } },
            removeCustomer, removeDashboardToCustomer,
            classes,
            dashboards: { dashboards },
            iam: { add_customer_failed, remove_customer_failed, remove_dashboard_to_customer_failed }
        } = this.props;
        const { deleteTriggered, currentCustomer, open, currentDashboard, expanded, expandedTransfer, transferTriggered } = this.state;
        return(
            <Grid container justify="center">
                <Grid item xs={12} md={8}>
                    { add_customer_failed && <Snackbar>Adding user failed.</Snackbar> }
                    { remove_customer_failed && <Snackbar>Removing user failed.</Snackbar> }
                    { remove_dashboard_to_customer_failed && <Snackbar>Removing dashboard to user failed.</Snackbar> }
                    <Card className={classes.card}>
                        <CardActions disableActionSpacing className={classes.action}>
                            <Typography variant="h6">Dashboard Users</Typography>
                            <div className={classes.flexGrow} />
                            <Tooltip id="tooltip-fab-add-user" title={expanded ? "Close" : "Add User"}
                                     placement="right">
                                <Fab size="small" color="primary"
                                     className={classnames(classes.expand, {
                                         [classes.expandOpen]: expanded,
                                     })}
                                     onClick={() => this.handleExpandClick("expanded")}
                                     aria-expanded={expanded}
                                     aria-label="Add User">
                                    {
                                        expanded && <MinimizeIcon/>
                                    }
                                    {
                                        !expanded && <PersonAdd/>
                                    }
                                </Fab>
                            </Tooltip>
                        </CardActions>
                        <Divider />
                        <Collapse in={expanded} unmountOnExit>
                            <CardContent>
                                <AddCustomer {...this.props}/>
                            </CardContent>
                        </Collapse>
                        <Divider />
                        <CardContent className={classes.root}>
                            <List className={classes.root}>
                                <ListItem>
                                    <ListItemIcon>
                                        <SecurityIcon color="primary"/>
                                    </ListItemIcon>
                                    <ListItemText primary={<strong><i>{customer_id}</i></strong>} secondary={`Admin`}/>
                                    {(email === customer_id || email === window.masterEmail) &&
                                    <Tooltip id={"transfer-app"} title="Transfer Ownership" placement="right">
                                        <IconButton aria-label="Transfer Ownership" onClick={() => {
                                            this.setState({
                                                currentCustomer: customer_id,
                                                transferTriggered: !transferTriggered,
                                                expandedTransfer: !expandedTransfer,
                                            });
                                        }}>
                                            <SwapHorizIcon color={transferTriggered ? "primary" : "error"}/>
                                        </IconButton>
                                    </Tooltip>}
                                </ListItem>
                                {
                                    transferTriggered &&
                                    <Collapse in={expandedTransfer} unmountOnExit style={{backgroundColor: "#f5f6f8"}}>
                                        <br/>
                                        <TransferAppSection {...this.props}/>
                                        <br/>
                                        <Divider/>
                                    </Collapse>
                                }
                                {
                                    customers.map((item, index) => {
                                        const { customer_id, dashboards: userDashboards, limited_access = false } = item;
                                        if(limited_access){
                                            return(
                                                <span key={customer_id}>
                                                    <ListItem button={Array.isArray(userDashboards)} onClick={this.handleClick(customer_id)}>
                                                        <ListItemIcon>
                                                            <PersonOutline color="secondary"/>
                                                        </ListItemIcon>
                                                        <ListItemText primary={<strong>{ customer_id }</strong>} secondary={`Limited Access`}/>
                                                        {limited_access && open === customer_id ? <ExpandLess /> : <ExpandMore />}
                                                        <Tooltip id={"remove-customer-" + customer_id} title="Click to remove this user" placement="right">
                                                            <IconButton aria-label="Remove" onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.setState({currentCustomer: customer_id, deleteTriggered: true, currentDashboard: null});
                                                            }}>
                                                                <RemoveIcon color="error"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </ListItem>
                                                    {
                                                        Array.isArray(userDashboards) && <Collapse in={open === customer_id} timeout="auto" unmountOnExit>
                                                            <List component="div" disablePadding>
                                                                {
                                                                    userDashboards.map(dashboardId =>
                                                                        <ListItem key={dashboardId} className={classes.nested}>
                                                                            <ListItemIcon>
                                                                                <DashboardIcon />
                                                                            </ListItemIcon>
                                                                            <ListItemText primary={getGroupNameFromList(dashboards, dashboardId) || dashboardId} secondary={dashboardId} />
                                                                            <Tooltip id={"remove-dashboard-" + dashboardId} title="Click to remove access to this dashboard" placement="right">
                                                                                <IconButton aria-label="Remove" onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    this.setState({currentCustomer: customer_id, deleteTriggered: true, currentDashboard: dashboardId});
                                                                                }}>
                                                                                    <RemoveIcon color="error"/>
                                                                                </IconButton>
                                                                            </Tooltip>
                                                                        </ListItem>
                                                                    )
                                                                }
                                                            </List>
                                                        </Collapse>
                                                    }
                                                </span>
                                            )
                                        }
                                        return (
                                            <ListItem key={customer_id}>
                                                <ListItemIcon>
                                                    <Person color="primary"/>
                                                </ListItemIcon>
                                                <ListItemText primary={<strong>{ customer_id }</strong>} secondary={`Full Access`}/>
                                                <Tooltip id={"remove-customer-" + customer_id} title="Click to remove user" placement="right">
                                                    <IconButton aria-label="Remove" onClick={() => {
                                                        this.setState({currentCustomer: customer_id, deleteTriggered: true, currentDashboard: null});
                                                    }}>
                                                        <RemoveIcon color="error"/>
                                                    </IconButton>
                                                </Tooltip>
                                            </ListItem>
                                        );
                                    })
                                }
                            </List>
                            <Dialog
                                status={deleteTriggered}
                                handleConfirm={() => {
                                    //deleteDashboard(appId, _id);
                                    if(currentCustomer && currentDashboard){
                                        removeDashboardToCustomer(appId, currentCustomer, currentDashboard);
                                    }else if(currentCustomer){
                                        removeCustomer(appId, currentCustomer);
                                    }
                                    this.setState({deleteTriggered: false, currentDashboard: null, currentCustomer: null});
                                }}
                                title="Remove Confirmation"
                                handleClose={() => this.setState({deleteTriggered: false})}
                                confirmLabel="Confirm"
                                allowCancelLabel="Cancel"
                            >
                                {
                                    currentCustomer && currentDashboard ?
                                        <p>Are you sure want to <strong>Remove</strong> dashboard <strong>{ getGroupNameFromList(dashboards, currentDashboard) || '' }</strong> for user <strong>{ currentCustomer }</strong></p>
                                        :
                                        <p>Are you sure want to <strong>Remove</strong> user <strong>{ currentCustomer }</strong></p>
                                }
                            </Dialog>
                        </CardContent>
                    </Card>
                    {
                        customers.length === 0 &&
                        <Placeholder
                            text="Welcome to Identity and Access Managment. You can add multiple users to your dashbaord."
                            withIcon
                            icon={<PersonAdd style={{width: 100, height: 100, opacity: 0.2}}/>}
                        />
                    }
                </Grid>
            </Grid>
        )

    }

}

export default withStyles(styles)(Customers);