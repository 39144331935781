/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React, {Component} from 'react';
import Switch from '../../../../../../components/reusable/Switch';
import ComposedChart from '../../../../../../components/reusable/Recharts/ComposedChart';
import {
    BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
    Brush, Cell
} from 'recharts';
import InputRange from 'react-input-range';
import {formatNumber} from "../../../../../../utils/index";
import { Grid} from "@material-ui/core";
import NumberBox from "../../../../../../components/reusable/MaterialUi/NumberBox";
import InsertChartIcon from '@material-ui/icons/InsertChart';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Box from "../../../../../../components/reusable/Box";
import 'react-input-range/lib/css/index.css';
import {randomColorWithIndex } from "../../../../../../utils";
import MultiUtilityChart from "../../../../../../components/reusable/Recharts/MultiUtilityChart";
import Apxor from 'apxor';
import { APXOR_SDK_CATEGORY_CONSTANTS } from '../../../../../../constants';

export class UninstallDistributions extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            value: { min: 7, max: 15 },
            currentIndex: 1,
            expanded: false
        };
    }

    handleExpand = () => {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    handleOnChange = (appId, d, index) => {
        Apxor.logEvent("UninstallSegmentsClicked", {segment: d.key}, APXOR_SDK_CATEGORY_CONSTANTS.UNINSTALLS);
        this.setState({
            currentIndex: index + 1,
            expanded: false
        });
        this.props.getUninstallUserDistributionByIndex(appId, index + 1);
        this.setState({expanded: true})
    }

    render(){
        const {  params: { appId }, uninstalls: { distributions} } = this.props;
        const { value, currentIndex } = this.state;

        return(
            <Box title="Uninstalls by Journey"
                 icon={<InsertChartIcon/>}
                 withPadding
            >
                <ResponsiveContainer height={238}>
                    <BarChart data={distributions}
                              margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                        <XAxis dataKey={"key"} type={"category"}/>
                        <YAxis/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip/>
                        <Bar dataKey='Uninstalls'>
                            {
                                distributions.map((entry, index) => (
                                    <Cell 
                                          fill={randomColorWithIndex(0)}
                                          key={`cell-${index}`}/>
                                ))
                            }
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
                <br />
                <InputRange
                    maxValue={90}
                    minValue={0}
                    value={value}
                    onChange={value => this.setState({ value })}
                    onChangeComplete={value => this.setState({ value }, () => {
                        Apxor.logEvent("JourneyDayScrollClicked", {day_range: value}, APXOR_SDK_CATEGORY_CONSTANTS.UNINSTALLS);
                        this.props.handleRangeUpdate(appId, this.state.value, currentIndex);
                    })}
                />
                <br />
            </Box>
        )
    }
}

const UNINSTALL_USAGE_ENUM = {
    DAY: "Day",
    LIFETIME: "Lifetime"
};

const UNINSTALL_USAGE_SWITCH_DATA = [
    {
        name: UNINSTALL_USAGE_ENUM.DAY,
        value: UNINSTALL_USAGE_ENUM.DAY,
        help: "Usage of uninstalled users on the day they uninstalled"
    },
    {
        name: UNINSTALL_USAGE_ENUM.LIFETIME,
        value: UNINSTALL_USAGE_ENUM.LIFETIME,
        help: "Usage of uninstalled users on the through out their lifetime"
    }
];

export class RetentionUsage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            expanded: this.props.expanded || false,
            currentType: UNINSTALL_USAGE_ENUM.DAY
        };
    }

    getTitle = (day = false) => {
        const { value : {min, max}, currentIndex } = this.props;
        const titlePostfix = day ? "Uninstall Day Usage" : "Uninstall Lifetime Usage";
        const getLabel = (start, end) => `Day ${start} - Day ${end} ${titlePostfix}`;
        return currentIndex === 1 ? getLabel(0, min - 1) : (currentIndex === 2 ? getLabel(min, max -1) : getLabel(max, 90));
    };

    render(){
        const {  uninstalls: { uninstall_usage, uninstall_day_usage }, currentIndex, expanded } = this.props;
        const { currentType } = this.state;
        const data = currentType === UNINSTALL_USAGE_ENUM.DAY ? uninstall_day_usage[currentIndex] : uninstall_usage[currentIndex];
        const title = this.getTitle(currentType === UNINSTALL_USAGE_ENUM.DAY);
        return(
            <Box title={title}
                 icon={<InsertChartIcon/>}
                 withPadding
                 collapsible={true}
                 defaultExpanded={expanded}
                 onCollapse={this.props.handleExpand}
            >
                {
                    Array.isArray(data) && data.length > 0 &&
                    <div>
                        <Switch
                            containerStyles={{minWidth: 240}}
                            data={UNINSTALL_USAGE_SWITCH_DATA}
                            handleChange={currentType => {
                                Apxor.logEvent("UninstallDayUsageClicked", {type: currentType}, APXOR_SDK_CATEGORY_CONSTANTS.UNINSTALLS);
                                this.setState({currentType})}
                            }
                            value={currentType}
                        />
                        <br />
                        <MultiUtilityChart
                            data={data}
                            barDataKeys={["event_count", "user_count"]}
                            xLabelFormatter={e => e}
                            showBrush
                            dataKey="event_name"
                        />

                    </div>
                }
            </Box>
        )
    }
}


export class UninstallTrends extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    render(){

        const {
            uninstalls: { trends }
        } = this.props;
        return (
            <Box title="Uninstall Trends"
                 icon={<ShowChartIcon/>}
                 withPadding
            >
                <Grid container>
                    <Grid item xs={12} md={6} >
                        {
                            Array.isArray(trends) && trends.length > 0 &&
                            trends[0].hasOwnProperty("Active Users") &&
                            trends[0].hasOwnProperty("Uninstalls") &&
                            <MultiUtilityChart
                                data={trends}
                                areaDataKeys={["Active Users", "Uninstalls"]}
                                syncId="uninstalls"
                            />
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        {
                            Array.isArray(trends) && trends.length > 0 &&
                            trends[0].hasOwnProperty("New Installs") &&
                            trends[0].hasOwnProperty("Day 0 Uninstalls") &&
                            <MultiUtilityChart
                                data={trends}
                                areaDataKeys={["New Installs", "Day 0 Uninstalls"]}
                                syncId="uninstalls"
                            />
                        }
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export class Day0UninstallTrends extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    render(){

        const {
            uninstalls: { trends = [] }
        } = this.props;
        return (
            <Box title="Day 0 Uninstall Trends"
                 icon={<ShowChartIcon/>}
                 withPadding
            >
                {
                    Array.isArray(trends) && trends.length > 0 &&
                    trends[0].hasOwnProperty("Day 0 Uninstalls") &&
                    trends[0].hasOwnProperty("Uninstalls") &&
                    <ComposedChart
                        data={trends}
                        areaDataKeys={["Uninstalls", "Day 0 Uninstalls"]}
                    />
                }
            </Box>
        )
    }
}

export class UserCounts extends React.Component{
    render(){
        const {
            uninstalls: { count, day0_count }
        } = this.props;
        return(
            <Grid container spacing={16}>
                <Grid item md lg hidden={{smDown: true}}/>
                <Grid item xs={12} sm={6} md={5} lg={4}>
                    <NumberBox heading={formatNumber(count)} subheading="Total Uninstalls"
                               icon={<ExitToAppIcon nativeColor={randomColorWithIndex(6)}/>}/>
                </Grid>
                <Grid item xs={12} sm={6} md={5} lg={4}>
                    <NumberBox heading={formatNumber(day0_count)} subheading="Day 0 Uninstalls"
                               icon={<ExitToAppIcon nativeColor={randomColorWithIndex(8)}/>}/>
                </Grid>
            </Grid>
        )
    }
}