/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '../../../../../../../components/reusable/MaterialUi/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/ButtonBase';
import {DASHBOARD_ENDPOINTS} from "../../../../../../../constants/EndPoints";
import {allowedDashboards, displayTimeFromNow, formatTime} from "../../../../../../../utils";
import { APXOR_SDK_CATEGORY_CONSTANTS } from '../../../../../../../constants';
import Apxor from 'apxor';

class AddDashboard extends Component {
    state = {
        name: "",
        config: {}
    };

    handleNameChange = (e) => {
        this.setState({name: e.target.value});
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { name } = this.state;
        if (name.trim() === "") {
            return;
        }
        const { params: { appId } } = this.props;
        this.props.saveDashboard(appId, this.state);
        this.setState({name : "", config: {components: []}}); //emptying dashboard object
    };

    render() {
        const { name } = this.state;
        const { classes, dashboards: { add_dashboard_pending } } = this.props;
        return (
            <form onSubmit={this.handleSubmit}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={6}>
                        <TextField
                            value={name}
                            fullWidth
                            onChange={this.handleNameChange}
                            label="Dashboard Name"
                            type="text"
                            className={classes.textField}
                            required
                        />
                        <br/>
                        <Button style={{float: 'right'}} variant="contained" color="primary" type="submit" onClick={this.handleAddApp}>
                            { add_dashboard_pending && <CircularProgress color="secondary" size={30}/> }
                            { !add_dashboard_pending && <span>Create New Dashboard</span> }
                        </Button>
                    </Grid>
                    <Grid item hidden={{xsDown: true}} sm={3}/>
                </Grid>
            </form>
        );
    }
}

export class DashboardListItem extends Component {

    state = {
        deleteTriggered: false
    };

    static propTypes = {
        //title: PropTypes.string.isRequired
    };

    handleNavigation = () => {
        const { _id, params: { appId }, router } = this.props;
        router.push(`/apps/${appId}/${DASHBOARD_ENDPOINTS.DASHBOARDS}/${_id}`);
    };

    render(){
        const {
            classes,
            deleteDashboard,
            _id, name,
            config: { last_updated, last_updated_by, components=[] },
            params: { appId }
        } = this.props;
        const { deleteTriggered } = this.state;
        return (
            <ListItem button className={classes.listItem} onClick={this.handleNavigation} style={{borderBottom: '1px solid #DDD'}}>
                <ListItemIcon>
                    <DashboardIcon/>
                </ListItemIcon>
                <ListItemText primary={name} secondary={`#${components.length}`}/>
                <ListItemText
                    style={{position: 'absolute', left: '70%'}}
                    primary={<Typography title="Last updated by">{(last_updated_by === window.masterEmail || last_updated_by === "kk@apxor.com") ? "support@apxor.com" : last_updated_by || '_'}</Typography>}
                    secondary={<Typography title={formatTime(last_updated)} variant="caption">{displayTimeFromNow(last_updated)}</Typography>}
                />
                <ListItemSecondaryAction>
                    <div style={{display: 'flex'}}>
                        <IconButton style={{marginRight: 16}} color="inherit" onClick={() => this.setState({deleteTriggered: true})}>
                            <DeleteIcon color="error" />
                        </IconButton>
                        <Dialog
                            status={deleteTriggered}
                            handleConfirm={() => {
                                deleteDashboard(appId, _id);
                                this.setState({deleteTriggered: false});
                            }}
                            title="Delete Confirmation"
                            handleClose={() => this.setState({deleteTriggered: false})}
                            confirmLabel="Confirm"
                            allowCancelLabel="Cancel"
                        >
                            <p>Are you sure want to <strong>Delete</strong> dashboard <strong>{ name }</strong></p>
                        </Dialog>
                    </div>
                </ListItemSecondaryAction>
            </ListItem>
        );
    }
}

const styles = theme => ({
    card: {
        width: '100%',
    },
    root: {
        padding: 0,
        '&:last-child': {
            paddingBottom: 0,
        }
    },
    action: {
        padding: 16
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    flexGrow: {
        flex: '1 1 auto',
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
});

class DashboardsList extends React.Component {

    state = {
        expanded: false
    };

    handleExpandClick = () => {
        Apxor.logEvent("AddDashboardButtonClicked", {}, APXOR_SDK_CATEGORY_CONSTANTS.DASHBOARD);
        this.setState({ expanded: !this.state.expanded });
    };

    render() {
        const {
            appState: { app: { basic_info: { customers } } },
            classes, dashboards: { dashboards = [] },
            session: { user: { email } }
        } = this.props;
        let expanded = this.state.expanded;
        if(dashboards.length === 0){
            expanded = true;
        }
        return (
            <Grid container justify ="center">
                <Grid item xs={12} md={8}>
                    <div>
                        <Card className={classes.card}>
                            <CardActions disableActionSpacing className={classes.action}>
                                <Typography variant="h6">Dashboards</Typography>
                                <div className={classes.flexGrow} />
                                {
                                    <Tooltip id="tooltip-fab" title={ expanded ? "Close" : "Add Custom Dashboard" } placement="bottom">
                                        <Fab size="small" color="primary"
                                                className={classnames(classes.expand, {
                                                    [classes.expandOpen]: expanded,
                                                })}
                                                onClick={this.handleExpandClick}
                                                aria-expanded={expanded}
                                                aria-label="Add Dashboard">
                                            {
                                                expanded && <RemoveIcon/>
                                            }
                                            {
                                                !expanded && <AddIcon />
                                            }
                                        </Fab>
                                    </Tooltip>
                                }
                            </CardActions>
                            <Divider />
                            <Collapse in={expanded} unmountOnExit>
                                <CardContent>
                                    <AddDashboard {...this.props}/>
                                </CardContent>
                            </Collapse>
                            <Divider />
                            <CardContent className={classes.root}>
                                <List className={classes.root}>
                                    {
                                        Array.isArray(dashboards) && allowedDashboards(customers, email, dashboards).map(dashboard => <DashboardListItem key={dashboard._id} {...this.props} {...dashboard}/>)
                                    }
                                </List>
                            </CardContent>
                        </Card>
                    </div>
                </Grid>
            </Grid>
        );
    }
}

DashboardsList.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DashboardsList);