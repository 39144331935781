/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

export const DAU_TIME_SERIES = "DAU_TIME_SERIES";
export const MAU_TIME_SERIES = "MAU_TIME_SERIES";
export const NEW_INSTALL_TIME_SERIES = "NEW_INSTALL_TIME_SERIES";
export const SESSION_TIME_SERIES = "SESSION_TIME_SERIES";
export const SESSION_LENGTH_TIME_SERIES = "SESSION_LENGTH_TIME_SERIES";

export const OVERVIEW_PENDING = "OVERVIEW_PENDING";
export const OVERVIEW_FAILED = "OVERVIEW_FAILED";

export const OVERVIEW_USP_STABILITY = "OVERVIEW_USP_STABILITY";
export const OVERVIEW_USP_USABILITY = "OVERVIEW_USP_USABILITY";
export const OVERVIEW_USP_PERFORMANCE = "OVERVIEW_USP_PERFORMANCE";
export const OVERVIEW_USP_FUNCTIONALITY = "OVERVIEW_USP_FUNCTIONALITY";


export const EVENT_ANALYSIS_PENDING = "EVENT_ANALYSIS_PENDING";
export const EVENT_ANALYSIS_FAILED = "EVENT_ANALYSIS_FAILED";

export const EVENT_ANALYSIS_EVENTS = "EVENT_ANALYSIS_EVENTS";
export const EVENT_ANALYSIS_EVENT_ATTRIBUTES = "EVENT_ANALYSIS_EVENT_ATTRIBUTES";
export const EVENT_ANALYSIS_EVENT_ATTRIBUTE_DISTRIBUTION = "EVENT_ANALYSIS_EVENT_ATTRIBUTE_DISTRIBUTION";
export const EVENT_ANALYSIS_EVENT_COUNT = "EVENT_ANALYSIS_EVENT_COUNT";
export const EVENT_ANALYSIS_EVENT_USER_COUNT = "EVENT_ANALYSIS_EVENT_USER_COUNT";
export const EVENT_ANALYSIS_EVENT_SESSION_COUNT = "EVENT_ANALYSIS_EVENT_SESSION_COUNT";
export const EVENT_ANALYSIS_EVENT_COUNT_TIME_SERIES = "EVENT_ANALYSIS_EVENT_COUNT_TIME_SERIES";
export const EVENT_ANALYSIS_EVENT_USER_TIME_SERIES = "EVENT_ANALYSIS_EVENT_USER_TIME_SERIES";
export const EVENT_ANALYSIS_EVENT_SESSION_TIME_SERIES = "EVENT_ANALYSIS_EVENT_SESSION_TIME_SERIES";

export const UPDATE_EVENT_ANALYSIS_EVENT = "UPDATE_EVENT_ANALYSIS_EVENT";

export const ALL_SESSIONS_COUNT_PENDING = "ALL_SESSIONS_COUNT_PENDING";
export const ALL_SESSIONS_COUNT = "ALL_SESSIONS_COUNT";
export const ALL_SESSIONS_COUNT_FAILED = "ALL_SESSIONS_COUNT_FAILED";

export const ALL_USERS_COUNT_PENDING = "ALL_USERS_COUNT_PENDING";
export const ALL_USERS_COUNT = "ALL_USERS_COUNT";
export const ALL_USERS_COUNT_FAILED = "ALL_USERS_COUNT_FAILED";

export const ACTIVE_USERS_COUNT_PENDING = "ACTIVE_USERS_COUNT_PENDING";
export const ACTIVE_USERS_COUNT = "ACTIVE_USERS_COUNT";
export const ACTIVE_USERS_COUNT_FAILED = "ACTIVE_USERS_COUNT_FAILED";

export const NEW_INSTALL_USERS_COUNT_PENDING = "NEW_INSTALL_USERS_COUNT_PENDING";
export const NEW_INSTALL_USERS_COUNT = "NEW_INSTALL_USERS_COUNT";
export const NEW_INSTALL_USERS_COUNT_FAILED = "NEW_INSTALL_USERS_COUNT_FAILED";

export const UNINSTALL_USERS_COUNT_PENDING = "UNINSTALL_USERS_COUNT_PENDING";
export const UNINSTALL_USERS_COUNT = "UNINSTALL_USERS_COUNT";
export const UNINSTALL_USERS_COUNT_FAILED = "UNINSTALL_USERS_COUNT_FAILED";

export const EVENT_ANALYSIS_EVENT_RETENTION_PENDING = "EVENT_ANALYSIS_EVENT_RETENTION_PENDING";
export const EVENT_ANALYSIS_EVENT_RETENTION = "EVENT_ANALYSIS_EVENT_RETENTION";
export const EVENT_ANALYSIS_EVENT_RETENTION_FAILED = "EVENT_ANALYSIS_EVENT_RETENTION_FAILED";

export const EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_PENDING = "EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_PENDING";
export const EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION = "EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION";
export const EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_FAILED = "EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_FAILED";

export const CUSTOM_EVENT_ENUM = {
    event: "event",
    sessions: "sessions",
    users: "users"
};

export const EVENT_ANALYSIS_ATTRIBUTES = "EVENT_ANALYSIS_ATTRIBUTES";
export const EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION = "EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION";
export const RESET_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION = "RESET_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION";

export const EVENT_ANALYSIS_EVENT_KPI_COUNT = "EVENT_ANALYSIS_EVENT_KPI_COUNT";

export const EVENT_ANALYSIS_EVENT_KPIS_PENDING = "EVENT_ANALYSIS_EVENT_KPIS_PENDING";
export const EVENT_ANALYSIS_EVENT_KPIS = "EVENT_ANALYSIS_EVENT_KPIS";
export const EVENT_ANALYSIS_EVENT_KPIS_FAILED = "EVENT_ANALYSIS_EVENT_KPIS_FAILED";

export const ADD_EVENT_ANALYSIS_CURRENT_EVENT = "ADD_EVENT_ANALYSIS_CURRENT_EVENT";
export const DELETE_EVENT_ANALYSIS_CURRENT_EVENT = "DELETE_EVENT_ANALYSIS_CURRENT_EVENT";

export const EVENT_ANALYSIS_RETENTION_PENDING = "EVENT_ANALYSIS_RETENTION_PENDING";
export const EVENT_ANALYSIS_RETENTION = "EVENT_ANALYSIS_RETENTION";
export const EVENT_ANALYSIS_RETENTION_FAILED = "EVENT_ANALYSIS_RETENTION_FAILED";

export const UPDATE_EVENT_ATTRIBUTE_DISTRIBUTION_OF = "UPDATE_EVENT_ATTRIBUTE_DISTRIBUTION_OF";

export const UPDATE_ATTRIBUTE_DISTRIBUTION_OF = "UPDATE_ATTRIBUTE_DISTRIBUTION_OF";

export const UPDATE_EVENT_ANALYSIS_USER_GROUP = "UPDATE_EVENT_ANALYSIS_USER_GROUP";

export const UPDATE_EVENT_ANALYSIS_GLOBAL_FILTERS = "UPDATE_EVENT_ANALYSIS_GLOBAL_FILTERS";

export const UPDATE_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION_FILTERS = "UPDATE_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION_FILTERS";

export const GET_TOP_EVENTS_PENDING = "GET_TOP_EVENTS_PENDING";
export const GET_TOP_EVENTS = "GET_TOP_EVENTS";
export const GET_TOP_EVENTS_FAILED = "GET_TOP_EVENTS_FAILED";

export const EVENT_KPIS_ENUM = { //FIXME: only for one APP
    SEARCH_AUDIO_V2: "Audio Searches",
    SEARCH_GIF_V2: "GIF Searches",
    SHARE_AUDIO_V2: "Audio Shares",
    SHARE_GIF_V2: "GIF Shares",
    LISTEN_V2: "Listens",
    VIEW_FULL_GIF_V2: "GIF Full Views"
};

export const RESET_EVENT_ANALYSIS = "RESET_EVENT_ANALYSIS";

export const EMPTY_FILTERS = {user: [], session: [], event: []};

export const UPDATE_EVENT_ANALYSIS_GROUP_DAYS = "UPDATE_EVENT_ANALYSIS_GROUP_DAYS";

export const CLEAN_EVENT_ANALYSIS = "CLEAN_EVENT_ANALYSIS";

export const TIME_SPENT_PENDING = "TIME_SPENT_PENDING"
export const TIME_SPENT = "TIME_SPENT"
export const TIME_SPENT_FAILED = "TIME_SPENT_FAILED"