
import {
    RESET_FUNNEL,
    FUNNEL_GROUPED_BY,
    FUNNEL_GRAPH,
    FUNNEL_LIST,
    UPDATE_CORRELATIONS_FUNNELS_LIST,
    FUNNEL_TIMESERIES,
    CREATE_COHORT_FUNNEL,
    CREATE_COHORT_FUNNEL_PENDING,
    CREATE_COHORT_FUNNEL_FAILED,
    TOGGLE_FUNNEL_DIALOG, TOGGLE_FUNNEL_SNACKBAR, HIDE_FUNNEL_SNACKBAR, SHOW_FUNNEL_SNACKBAR, FUNNEL_DOWNLOAD, FUNNEL_GROUPED_BY_DOWNLOAD
} from './actionTypes';
import {
    FUNNEL_UPDATE_QUERY, FUNNEL_RESET_QUERY,
    CUSTOM_EVENT_ENUM,
    FUNNEL_OPPORTUNITY_PENDING, FUNNEL_OPPORTUNITY, FUNNEL_OPPORTUNITY_FAILED
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';
import { downloadAsFile } from '../../../../../../utils';

const INITIAL_OPPORTUNITY_STATE = {
    opportunity_pending: false,
    opportunity: [],
    opportunity_failed: false
};

const INITIAL_STATE = {
    comparing_funnels: [],
    time_series: [],
    series: {},
    funnels_list: [],
    group_by: [],
    funnels_group_by_attribute: {},
    events: [],
    event_attributes: {},
    event_attribute_values: [],
    of: CUSTOM_EVENT_ENUM.users,
    query: {},
    saved: false,
    graph: {},
    progress: {
        create_cohort_pending: false,
        create_cohort_failed: false,
        show_funnel_dialog: false,
        show_funnel_snackbar: false,
        snackbar_message: ""
    },
    ...INITIAL_OPPORTUNITY_STATE
};

const INITIAL_QUERY_STATE = {
    name: "",
    user: [],
    session: [],
    event: [],
};

function mergeTimeSeries(all, funnel, funnelName) {
    if(!Array.isArray(all) || !Array.isArray(funnel)) return [];
    if(all.length === 0 || all.length !== funnel.length) return funnel.map(o => ({name: o.key, [funnelName]: o.value}));
    return all.map((item, index) => {
        item[funnelName] = funnel[index].value;
        return item;
    });
}

function funnelsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case FUNNEL_GRAPH:
            return {
                ...state,
                graph: action.payload,
                group_by: []
            };
        case FUNNEL_DOWNLOAD:
            downloadAsFile(action.payload, "funnel", "csv");
            return {
                ...state
            }
        case FUNNEL_GROUPED_BY_DOWNLOAD:
            downloadAsFile(action.payload, "funnel_grouped", "csv");
            return {
                ...state
            }
        case UPDATE_CORRELATIONS_FUNNELS_LIST:
            return {
                ...state,
                comparing_funnels: action.payload
            };

        case FUNNEL_TIMESERIES:
            return {
                ...state,
                series: {
                    [action.meta.funnelId]: action.payload,
                },
                time_series: mergeTimeSeries(state.time_series, action.payload.map(o => {
                    return {key: o.date, value: o.value}
                }), action.meta.funnelName),
            };

        case FUNNEL_LIST:
            return {
                ...state,
                funnels_list: Array.isArray(action.payload) ? action.payload : []
            };

        case FUNNEL_GROUPED_BY:
            return {
                ...state,
                group_by: action.payload
            };

        case FUNNEL_UPDATE_QUERY:
            const { group_by } = action.payload;
            const graph = group_by ? state.graph : {}; //resetting graph
            return {
                ...state,
                query: {
                    ...state.query,
                    ...action.payload
                },
                graph,
                group_by: []
            };

        case FUNNEL_RESET_QUERY:
            return {
                ...state,
                query: INITIAL_QUERY_STATE
            };

        case FUNNEL_OPPORTUNITY_PENDING:
            return {
                ...state,
                ...INITIAL_OPPORTUNITY_STATE,
                opportunity_pending: true
            };

        case FUNNEL_OPPORTUNITY:
            return {
                ...state,
                ...INITIAL_OPPORTUNITY_STATE,
                opportunity: action.payload
            };

        case FUNNEL_OPPORTUNITY_FAILED:
            return {
                ...state,
                ...INITIAL_OPPORTUNITY_STATE,
                opportunity_failed: true
            };

        case CREATE_COHORT_FUNNEL:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    create_cohort_pending: false,
                    create_cohort_failed: false,
                }
            };

        case CREATE_COHORT_FUNNEL_PENDING:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    create_cohort_pending: true,
                    create_cohort_failed: false,
                }
            };

        case CREATE_COHORT_FUNNEL_FAILED:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    create_cohort_pending: false,
                    create_cohort_failed: true,
                }
            };

        case SHOW_FUNNEL_SNACKBAR:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    show_funnel_snackbar: true,
                    snackbar_message: action.payload.message
                }
            };
        case HIDE_FUNNEL_SNACKBAR:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    show_funnel_snackbar: false,
                    snackbar_message: ""
                }
            };

        case TOGGLE_FUNNEL_DIALOG:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    show_funnel_dialog: !state.progress.show_funnel_dialog
                }
            };

        case RESET_FUNNEL:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;

    }
}

export default funnelsReducer;