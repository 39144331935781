/**
 * Created by jyothi on 14/1/17.
 */
import {
    SEND_UI_FAILED, SEND_UI_PENDING, SEND_UI_SUCCESS,
    SEND_MESSAGE_FAILED, SEND_MESSAGE_PENDING, SEND_MESSAGE_SUCCESS,
    RESET_MESSAGES, MESSAGES, MESSAGES_FAILED, MESSAGES_PENDING,
    DELETE_MESSAGE_SUCCESS, DELETE_MESSAGE_FAILED, DELETE_MESSAGE_PENDING,
    UPDATE_UI_PENDING, UPDATE_UI_SUCCESS, UPDATE_UI_FAILED,
    UPDATE_MESSAGE_PENDING, UPDATE_MESSAGE_SUCCESS, UPDATE_MESSAGE_FAILED,
    UIS_PENDING, UIS_SUCCESS, UIS_FAILED,
    DELETE_UI_PENDING, DELETE_UI_SUCCESS, DELETE_UI_FAILED,
    PUBLISH_MESSAGE_PENDING, PUBLISH_MESSAGE, PUBLISH_MESSAGE_FAILED
} from './actionTypes';
import {
    sendUIAPI, getUIsAPI, updateUIAPI, deleteUIAPI
} from './api';
import {
    deleteArtConfigAPI,
    getArtConfigsAPI,
    publishArtConfigAPI,
    saveArtConfigAPI,
    updateArtConfigAPI
} from "../../../../../../api";
import {ART_CONFIG_TYPES} from "../../../../../../constants";

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getMessages = (appId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, configType: [ART_CONFIG_TYPES.RTA, ART_CONFIG_TYPES.WALKTHROUGH] };
        return dispatch({
            types: [
                MESSAGES_PENDING,
                MESSAGES,
                MESSAGES_FAILED
            ],
            payload: {
                promise: getArtConfigsAPI(appId, getState().auth, filters)
                    .then((res) => {
                        if(res.hasOwnProperty("messages")){
                            return res.messages;
                        }
                        return [];
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param data
 * @param configType
 * @returns {function(*, *)}
 */
export const sendMessage = (appId, data, configType = ART_CONFIG_TYPES.RTA) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, configType };
        return dispatch({
            types: [
                SEND_MESSAGE_PENDING,
                SEND_MESSAGE_SUCCESS,
                SEND_MESSAGE_FAILED
            ],
            payload: {
                promise: saveArtConfigAPI(appId, getState().auth, filters, data)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //getting all messages after adding new
                    dispatch(getMessages(appId));
                }
            }
        });
    };
};

/**
 *
 * @param appId
 * @param messageId
 * @param data
 * @param configType
 * @returns {function(*, *)}
 */
export const updateMessage = (appId, messageId, data, configType = ART_CONFIG_TYPES.RTA) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, configType };
        return dispatch({
            types: [
                UPDATE_MESSAGE_PENDING,
                UPDATE_MESSAGE_SUCCESS,
                UPDATE_MESSAGE_FAILED
            ],
            payload: {
                promise: updateArtConfigAPI(appId, getState().auth, messageId, filters, data)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //getting all messages after updating
                    dispatch(getMessages(appId));
                }
            }
        });
    };
};

/**
 *
 * @param appId
 * @param messageId
 * @param configType
 * @returns {function(*, *)}
 */
export const deleteMessage = (appId, messageId, configType = ART_CONFIG_TYPES.RTA) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, configType };
        return dispatch({
            types: [
                DELETE_MESSAGE_PENDING,
                DELETE_MESSAGE_SUCCESS,
                DELETE_MESSAGE_FAILED,
            ],
            payload: {
                promise: deleteArtConfigAPI(appId, getState().auth, messageId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //getting all messages after adding new
                    dispatch(getMessages(appId));
                }
            }
        });
    };
};

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getMessageUIs = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                UIS_PENDING,
                UIS_SUCCESS,
                UIS_FAILED
            ],
            payload: {
                promise: getUIsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param data
 * @returns {function(*, *)}
 */
export const sendMessageUI = (appId, data) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                SEND_UI_PENDING,
                SEND_UI_SUCCESS,
                SEND_UI_FAILED
            ],
            payload: {
                promise: sendUIAPI(getState().auth, appId, filters, data)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //getting all messages after adding new
                    dispatch(getMessageUIs(appId));
                }
            }
        });
    };
};

/**
 *
 * @param appId
 * @param uiId
 * @param data
 * @returns {function(*, *)}
 */
export const updateMessageUI = (appId, uiId, data) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                UPDATE_UI_PENDING,
                UPDATE_UI_SUCCESS,
                UPDATE_UI_FAILED
            ],
            payload: {
                promise: updateUIAPI(getState().auth, appId, filters, uiId, data)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //getting all messages after adding new
                    dispatch(getMessageUIs(appId));
                }
            }
        });
    };
};

/**
 *
 * @param appId
 * @param uiId
 * @returns {function(*, *)}
 */
export const deleteMessageUI = (appId, uiId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                DELETE_UI_PENDING,
                DELETE_UI_SUCCESS,
                DELETE_UI_FAILED,
            ],
            payload: {
                promise: deleteUIAPI(getState().auth, appId, filters,  uiId)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //getting all messages after adding new
                    dispatch(getMessageUIs(appId));
                }
            }
        });
    };
};

/**
 *
 * @returns {function(*, *)}
 */
export const resetMessages = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_MESSAGES,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};

export const publishMessage = (appId, messageId, configType) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, configId: messageId, configType };
        return dispatch({
            types: [
                PUBLISH_MESSAGE_PENDING,
                PUBLISH_MESSAGE,
                PUBLISH_MESSAGE_FAILED
            ],
            payload: {
                promise: publishArtConfigAPI(appId, getState().auth, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successWillDispatch: (dispatch, data) => { //getting all messages after adding new
                    dispatch(getMessages(appId));
                }
            }
        });
    };
};