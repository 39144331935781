/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    MISC_PENDING, MISC_FAILED, RESET_MISC,
    MISC_ACTIVE_VIDEO_WATCHING_HOURS, MISC_DAU_WITH_VIDEO, MISC_FOLLOW_DISTRIBUTION,
    MISC_NEW_INSTALLS_FORMED_RELATIONSHIP, MISC_SOCIAL_TRENDS, MISC_UNFOLLOWS,
    MISC_UNINSTALLED_FOLLOWS, MISC_UNINSTALLS_PER_SESSION, MISC_UNINSTALLS_PER_VIDEOS_WATCHED,
    MISC_USERS_FORMED_RELATIONSHIP, MISC_DROP_OFF_IN_ACTIVITY, MISC_DAU_WITH_AUDIO,
    MISC_DAU_WITH_GIF, MISC_TIME_SPENT_ON_VIDEOS, MISC_TIME_SPENT_ON_OTHERS
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';
import {appendEmptyValueForSequence, mergeSequences } from '../../../../../../utils';
const INITIAL_STATE = {
    uninstalls_per_session: [],
    uninstalls_per_videos_watched: [],
    active_video_watching_hours: [],
    dau_with_video: {},
    unfollows: {},
    uninstalled_follows: {},
    follow_distribution: [],
    users_formed_relationship: {},
    new_installs_formed_relationship: {},
    social_trends: {},
    social_trends_series: [],
    drop_in_activity: {},
    time_spent_on_videos: {},
    time_spent_on_others: {},
    dau_with_audio: {},
    dau_with_gif: {}
};

function overviewReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case MISC_UNINSTALLS_PER_SESSION:
            return {
                ...state,
                uninstalls_per_session: action.payload
            };

        case MISC_UNINSTALLS_PER_VIDEOS_WATCHED:
            return {
                ...state,
                uninstalls_per_videos_watched: action.payload
            };

        case MISC_DAU_WITH_VIDEO:
            return {
                ...state,
                dau_with_video: action.payload
            };

        case MISC_DAU_WITH_AUDIO:
            return {
                ...state,
                dau_with_audio: action.payload
            };

        case MISC_DAU_WITH_GIF:
            return {
                ...state,
                dau_with_gif: action.payload
            };

        case MISC_TIME_SPENT_ON_VIDEOS:
            return {
                ...state,
                time_spent_on_videos: action.payload
            };

        case MISC_TIME_SPENT_ON_OTHERS:
            return {
                ...state,
                time_spent_on_others: action.payload
            };

        case MISC_ACTIVE_VIDEO_WATCHING_HOURS:
            return {
                ...state,
                active_video_watching_hours: appendEmptyValueForSequence(action.payload, "key", "Watch Count", 0, 0)
            };

        case MISC_UNFOLLOWS:
            return {
                ...state,
                unfollows: action.payload
            };

        case MISC_UNINSTALLED_FOLLOWS:
            return {
                ...state,
                uninstalled_follows: action.payload
            };

        case MISC_FOLLOW_DISTRIBUTION:
            return {
                ...state,
                follow_distribution: action.payload.map(o => ({key: o.key, Users: o.value}))
            };

        case MISC_USERS_FORMED_RELATIONSHIP:
            return {
                ...state,
                users_formed_relationship: action.payload
            };

        case MISC_NEW_INSTALLS_FORMED_RELATIONSHIP:
            return {
                ...state,
                new_installs_formed_relationship: action.payload
            };

        case MISC_SOCIAL_TRENDS:
            const socialTrends = {
                ...state.social_trends,
                [action.meta.event]: action.payload
            };
            return {
                ...state,
                social_trends: socialTrends,
                social_trends_series: mergeSequences(socialTrends, "key", "value", 0),
            };

        case MISC_DROP_OFF_IN_ACTIVITY:
            return {
                ...state,
                drop_in_activity: action.payload
            };

        case RESET_MISC:
        case RESET_ALL:
            return INITIAL_STATE;

        case MISC_PENDING:
        case MISC_FAILED:
        default:
            return state;
    }
}

export default overviewReducer;
