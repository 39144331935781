/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    UNINSTALL_SETTINGS_FAILED, UNINSTALL_SETTINGS_PENDING, UNINSTALL_SETTINGS,
    UPDATE_UNINSTALL_SETTINGS, UPDATE_UNINSTALL_SETTINGS_FAILED, UPDATE_UNINSTALL_SETTINGS_PENDING,
    RESET_UNINSTALL_SETTINGS
} from './actionTypes';
import { updateUninstallSettingsAPI, getUninstallSettingsAPI } from './api';
import { sendEventAsMessage } from '../../../../../../../utils';
import { DASHBOARD_EVENTS } from '../../../../../../../constants/ActionTypes';

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getUninstallSettings = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                UNINSTALL_SETTINGS_PENDING,
                UNINSTALL_SETTINGS,
                UNINSTALL_SETTINGS_FAILED
            ],
            payload: {
                promise: getUninstallSettingsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param settings
 * @returns {function(*, *)}
 */
export const updateUninstallSettings = (appId, settings) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                UPDATE_UNINSTALL_SETTINGS_PENDING,
                UPDATE_UNINSTALL_SETTINGS,
                UPDATE_UNINSTALL_SETTINGS_FAILED
            ],
            payload: {
                promise: updateUninstallSettingsAPI(getState().auth, appId, settings)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, res) => {
                    if(res){ //if positive response
                        const {event, message} = DASHBOARD_EVENTS.UNINSTALL_TRACKING;
                        const {basic_info: {app_name}} = getState().app.app;
                        sendEventAsMessage(event, message(JSON.stringify(settings)), appId, app_name, getState().auth.user.email, getState().meta.location);
                    }
                }
            }
        });
    };
};

/**
 *
 * @returns {function(*, *)}
 */
export const resetUninstallSettings = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_UNINSTALL_SETTINGS,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};

export const loadCurrentSettings = (appId) => {
    return (dispatch, getState) => {
        dispatch(getUninstallSettings(appId));
    };
};