/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

/**
 * Actions for AppReducer: A StateContainer of APP for dashboard flow after click a app in List
 * @type {string}
 */
export const ALL_PENDING = "ALL_PENDING";

export const GET_APP = "GET_APP";
export const EXIT_DASHBOARD = "EXIT_DASHBOARD";

export const GET_PATH_IDS = "GET_PATH_IDS";
export const GET_USER_GROUP_IDS = "GET_USER_GROUP_IDS";
export const GET_SESSION_GROUP_IDS = "GET_SESSION_GROUP_IDS";

export const GET_APP_EVENTS = "GET_APP_EVENTS";

export const APP_SCREEN_NAMES = "APP_SCREEN_NAMES";

export const APP_SEGMENTS = "APP_SEGMENTS";

export const APP_TEST_DEVICES = "APP_TEST_DEVICES";

export const APP_ATTRIBUTES = "APP_ATTRIBUTES";

export const TOTAL_INSTALLS = "TOTAL_INSTALLS";
export const TOTAL_UNINSTALLS = "TOTAL_UNINSTALLS";

export const ALL_FAILED = "ALL_FAILED";

export const RESET_APP_DATA = "RESET_APP_DATA";

export const APP_CONTEXTS = "APP_CONTEXTS";

export const APP_VERSIONS = "APP_VERSIONS";