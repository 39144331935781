/**
 * Created by Rakesh Peela
 * Date: 03-Dec-2019
 * Time: 6:53 PM
 */
import MultiSelect from "../../../../../../../../../../../components/reusable/MaterialUi/MultiSelect";
import {capitalizeEachWord} from "../../../../../../../../../../../utils";
import {CustomActionField} from "../../utils";
import ExpansionModuleConfig from "../ExpansionModuleConfig";
import {Grid} from '@material-ui/core';
import CustomColorPickerModule from "./CustomColorPickerModule";
import React from "react";
import debounce from 'lodash/debounce';
import CustomNativeSelect from "./CustomNativeSelect";
import Image_Config from "./image_configs";
import ModuleConfigSection from "./ModuleConfigSection";
import TabsPager from "./TabsPager";
import Text_config from "./text_config";

const BTN_ACTION_ENUM = {
    DISMISS: "dismiss",
    REDIRECT: "redirect",
    CANCEL: "cancel"
};

const REDIRECT_ACTIONS = {
    ACTIVITY: "activity",
    DEEP_LINK: "deep link"
};

const DEFAULT_INTENT_ACTION = "android.intent.action.VIEW";

class ButtonActionConfigModule extends React.Component {
    constructor(props) {
        super(props);
        const { action_config: {action, is_deep_link = false, activity = "", deep_link = {}} } = this.props;

        this.state = {
            actionType: action || BTN_ACTION_ENUM.DISMISS,
            isDeepLink: is_deep_link,
            activityValue: activity,
            uriValue: is_deep_link ? deep_link.uri : "",
            type: is_deep_link ? REDIRECT_ACTIONS.DEEP_LINK : REDIRECT_ACTIONS.ACTIVITY
        };
    }

    render() {
        const {appScreenNames, action_config: {deep_link = {}}} = this.props;
        const {actionType, activityValue, uriValue, isDeepLink, type} = this.state;
        return (
            <div style={{display: "flex", flexDirection: "column"}}>
                <Grid container style={{marginBottom: actionType === BTN_ACTION_ENUM.REDIRECT ? 10 : 0}}>
                    <Grid item md={4}>
                        <CustomNativeSelect
                            options={Object.keys(BTN_ACTION_ENUM).map(action => ({
                                key: BTN_ACTION_ENUM[action],
                                label: capitalizeEachWord(BTN_ACTION_ENUM[action])
                            }))}
                            defaultValue={actionType}
                            itemName={"button Action"}
                            handleChange={(event) => {
                                const value = event.target.value;
                                const isRedirect = value === BTN_ACTION_ENUM.REDIRECT;
                                this.setState({
                                    actionType: value,
                                    activityValue: isRedirect ? activityValue : "",
                                    uriValue: isRedirect && isDeepLink ? uriValue : ""
                                }, () => {
                                    this.props.handleBtnAction(value, isRedirect ? activityValue : "", deep_link, isDeepLink);
                                })
                            }}
                        />
                    </Grid>
                    {
                        actionType === BTN_ACTION_ENUM.REDIRECT
                        && <Grid item md={4}>
                            <CustomNativeSelect
                                options={
                                    Object.keys(REDIRECT_ACTIONS).map(action => ({
                                        key: REDIRECT_ACTIONS[action],
                                        label: capitalizeEachWord(REDIRECT_ACTIONS[action])
                                    }))}
                                defaultValue={type}
                                itemName={"Type"}
                                handleChange={(event) => {
                                    const value = event.target.value;
                                    const isDeepLink = value === REDIRECT_ACTIONS.DEEP_LINK;
                                    this.setState({
                                        type: value,
                                        isDeepLink
                                    }, () => {
                                        this.props.handleBtnAction(actionType, activityValue, {
                                            uri: uriValue,
                                            intent_action: DEFAULT_INTENT_ACTION
                                        }, isDeepLink);
                                    });
                                }}
                            />
                        </Grid>
                    }
                </Grid>
                {
                    actionType === BTN_ACTION_ENUM.REDIRECT
                    && isDeepLink
                    && <CustomActionField
                        isPure={true}
                        placeholder={"yourapp://your_scheme"}
                        value={uriValue}
                        key={"DeepLink"}
                        dataKey={"uri"}
                        onActionFieldValueChange={(value) => {
                            this.setState({
                                uriValue: value
                            }, () => {
                                this.props.handleBtnAction(actionType, "", {
                                    uri: value,
                                    intent_action: DEFAULT_INTENT_ACTION
                                }, true)
                            })
                        }}
                    />
                }
                {actionType === BTN_ACTION_ENUM.REDIRECT
                && !isDeepLink
                && <MultiSelect
                    options={appScreenNames.map(screenName => ({
                        label: screenName,
                        value: screenName
                    }))}
                    placeholder={"Select Activity to Redirect to"}
                    single
                    value={activityValue}
                    handleChange={(activityValue) => {
                        this.setState({
                            activityValue: activityValue
                        }, () => {
                            this.props.handleBtnAction(actionType, activityValue, null, false)
                        })
                    }}
                />}
            </div>
        );
    }
}

class ButtonConfig extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {eachConfig, selectedCampaignStep, handleButtonConfigUpdate, appScreenNames} = this.props;
        const {__id, color, image = "", action_config} = eachConfig;
        return (
            <div key={"btn_config-" + __id}>
                <Grid container spacing={16}>
                    <Grid item xs={3} md={3}>
                        <ModuleConfigSection title={"Button Color"}>
                            <CustomColorPickerModule
                                color={color}
                                handleColorChange={(selectedColor) => handleButtonConfigUpdate(__id, {
                                    ...eachConfig,
                                    color: selectedColor.hex
                                }, selectedCampaignStep)}
                            />
                        </ModuleConfigSection>
                    </Grid>
                    <Grid item xs={9} md={9}>
                        <ModuleConfigSection title={"Button Action"}>
                            <ButtonActionConfigModule
                                {...eachConfig}
                                appScreenNames={appScreenNames}
                                handleBtnAction={(action, activity, deep_link, isDeepLink) => {
                                    let key = isDeepLink ? "deep_link" : REDIRECT_ACTIONS.ACTIVITY;
                                    let data = isDeepLink ? deep_link : activity;
                                    handleButtonConfigUpdate(__id, {
                                        ...eachConfig,
                                        action_config: {
                                            action,
                                            activity: isDeepLink ? "" : data,
                                            [key]: data,
                                            is_deep_link: isDeepLink
                                        }
                                    });
                                }}
                            />
                        </ModuleConfigSection>
                    </Grid>
                </Grid>
                <Text_config
                    multiline={image && image !== ""}
                    config={eachConfig["text_config"]}
                    onChange={(data) => handleButtonConfigUpdate(__id, {
                        ...eachConfig,
                        text_config: {...data}
                    })}
                />
                {
                    eachConfig.hasOwnProperty("description_config") &&
                    <Text_config
                        multiline={image && image !== ""}
                        config={eachConfig["description_config"]}
                        onChange={(data) => handleButtonConfigUpdate(__id, {
                            ...eachConfig,
                            description_config: {...data}
                        })}
                    />
                }
                {eachConfig.hasOwnProperty("image") && <Image_Config
                    isPure
                    onChange={(imageData) => handleButtonConfigUpdate(__id, {
                        ...eachConfig,
                        image: imageData
                    })}
                    currentCampaignStep={selectedCampaignStep}
                    config={image}
                />}
            </div>
        );
    }
}

class Buttons_config extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            configs: this.props.configs,
            selectedTabIndex: 0,
            currentButtonConfig: null
        };

        this.debounce = debounce((fn, data, stepIndex) => {
            fn(data, stepIndex)
        }, 300);
    }

    UNSAFE_componentWillMount() {
        this.setState({
            currentButtonConfig: this.props.configs[this.state.selectedTabIndex]
        })
    }

    handleButtonConfigUpdate = (id, data) => {
        const {configs} = this.state;
        const {message, currentCampaignStep, mod_key} = this.props;
        this.setState({configs, currentButtonConfig: data});
        configs[configs.findIndex(x => x.__id === id)] = data;
        this.debounce(this.props.onChange, {...message, [mod_key]: configs}, currentCampaignStep)
    };

    render() {
        const {currentButtonConfig, configs, selectedTabIndex} = this.state;
        const {currentCampaignStep, appScreenNames} = this.props;
        let buttonsHeader = [];
        configs.forEach((btn, index) => buttonsHeader.push({
            title: "Button " + (index + 1),
            id: "title_" + btn.__id
        }));
        return (
            <ExpansionModuleConfig defaultExpanded title={"Buttons Configuration"}>
                <TabsPager
                    selectedTabIndex={selectedTabIndex}
                    tabsList={buttonsHeader}
                    handleChange={(tabIndex) => {
                        this.setState({
                            selectedIndex: tabIndex,
                            currentButtonConfig: this.props.configs[tabIndex]
                        })
                    }}/>
                <ButtonConfig handleButtonConfigUpdate={this.handleButtonConfigUpdate}
                              selectedCampaignStep={currentCampaignStep}
                              appScreenNames={appScreenNames}
                              key={selectedTabIndex + "__btn__" + currentButtonConfig["__id"]}
                              eachConfig={currentButtonConfig}/>
            </ExpansionModuleConfig>
        );
    }
}

export default Buttons_config;