/**
 * Created by Rakesh Peela
 * Date: 28-Nov-2019
 * Time: 11:07 AM
 */
import {REPORT_FAILED, REPORT_PENDING, REPORT_SUCCESS} from "./actionTypes";

const INITIAL_REPORT_STATE = {
    report_pending: true,
    report_failed: false,
    report: {}
};

function reportsReducer(state = INITIAL_REPORT_STATE, action = {}) {
    switch (action.type) {
        case REPORT_PENDING:
            return {
                ...state,
                report_pending: true
            };
        case REPORT_FAILED:
            return {
                ...state,
                report_failed: true
            };
        case REPORT_SUCCESS:
            return {
                ...state,
                report: action.payload
            };
        default:
            return state
    }
}

export default reportsReducer;