import React, { Component } from 'react';

import withSelfComponent from "./SelfComponent";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Explorer from "./Explorer";
import FlatTable from "./FlatTable";
import Box from "../../../../../../../components/reusable/Box";

const EXPLORER = "explorer";
const FLAT_TABLE = "flat-table";

class CustomReportsTable extends Component {

    state = {
        active: EXPLORER
    }

    render(){
        const {
            self, selfParams: { dataKey, customLabel }
        } = this.props;

        const {active} = this.state
        return (
            <div style={{width: '100%'}}>
                <Box
                    title={customLabel}
                >
                <Grid item xs>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={active}
                            onChange={(e, active) => {
                                this.setState({active});
                            }}
                            variant="fullWidth"
                            centered
                            textColor="secondary"
                            indicatorColor="secondary"
                        >
                            <Tab fullWidth textColor="primary" value={EXPLORER} label="Explorer" />
                            <Tab fullWidth textColor="primary" value={FLAT_TABLE} label="Flat Table" />
                        </Tabs>
                    </AppBar>
                    {
                        active === EXPLORER && <Explorer {...this.props} />
                    }
                    {
                        active === FLAT_TABLE && <FlatTable {...this.props}/>
                    }
                </Grid>
                </Box>
            </div>
        )
    };
}

CustomReportsTable.propTypes = {

};

export default withSelfComponent(CustomReportsTable);