export const SURVEYS_PENDING = "SURVEYS_PENDING";
export const SURVEYS = "SURVEYS";
export const SURVEYS_FAILED = "SURVEYS_FAILED";

export const SAVE_SURVEY_PENDING = "SAVE_SURVEY_PENDING";
export const SAVE_SURVEY = "SAVE_SURVEY";
export const SAVE_SURVEY_FAILED = "SAVE_SURVEY_FAILED";


export const UPDATE_SURVEY_PENDING = "UPDATE_SURVEY_PENDING";
export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const UPDATE_SURVEY_FAILED = "UPDATE_SURVEY_FAILED";

export const DELETE_SURVEY_PENDING = "DELETE_SURVEY_PENDING";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const DELETE_SURVEY_FAILED = "DELETE_SURVEY_FAILED";


export const RESET_SURVEYS = "RESET_SURVEYS";

export const PUBLISH_SURVEY_PENDING = "PUBLISH_SURVEY_PENDING";
export const PUBLISH_SURVEY = "PUBLISH_SURVEY";
export const PUBLISH_SURVEY_FAILED = "PUBLISH_SURVEY_FAILED";

export const UPDATE_CURRENT_SURVEY = "UPDATE_CURRENT_SURVEY";

export const SURVEY_RESULTS_PENDING = "SURVEY_RESULTS_PENDING";
export const SURVEY_RESULTS = "SURVEY_RESULTS";
export const SURVEY_RESULTS_FAILED = "SURVEY_RESULTS_FAILED";

export const SURVEY_RESULT_OTHER_OPTION_PENDING = "SURVEY_RESULT_OTHER_OPTION_PENDING";
export const SURVEY_RESULT_OTHER_OPTION = "SURVEY_RESULT_OTHER_OPTION";
export const SURVEY_RESULT_OTHER_OPTION_FAILED = "SURVEY_RESULT_OTHER_OPTION_FAILED";

export const SURVEY_RESULT_DISTRIBUTIONS_PENDING = "SURVEY_RESULT_DISTRIBUTIONS_PENDING";
export const SURVEY_RESULT_DISTRIBUTIONS = "SURVEY_RESULT_DISTRIBUTIONS";
export const SURVEY_RESULT_DISTRIBUTIONS_FAILED = "SURVEY_RESULT_DISTRIBUTIONS_FAILED";