/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {RESET_APP_APPROVAL, RESET_CREATE_APP_DIALOG} from "./actionTypes";
import * as Actions from './actionTypes';
import {RESET_ALL} from '../../../../../constants/ActionTypes';

const INITIAL_STATE = {
    apps : [],
    appsLoaded: false,
    appsLoading: false,
    appsFailed: false,
    deletingApp: false,
    deleteSuccess: false,
    deleteFailed: false
};

const INITIAL_FLAG_STATE = {
    appsLoaded: false,
    appsLoading: false,
    appsFailed: false,
    deletingApp: false,
    deleteSuccess: false,
    deleteFailed: false,
    approveAppSuccess: false,
    approveAppPending: false,
    approveAppFailed: false,
    showAddAppDialog: false
};

function appsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case Actions.GET_APPS:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                apps : action.payload,
                appsLoaded: true
            });

        case Actions.GET_APPS_PENDING:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                appsLoading: true
            });

        case Actions.GET_APPS_FAILED:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                appsFailed: true
            });

        case Actions.APPROVE_APP:
            return {
                ...state,
                ...INITIAL_FLAG_STATE,
                approveAppSuccess:true
            };

        case Actions.APPROVE_APP_FAILED:
            return {
                ...state,
                ...INITIAL_FLAG_STATE,
                approveAppFailed: true,
            };

        case Actions.APPROVE_APP_PENDING:
            return {
                ...state,
                ...INITIAL_FLAG_STATE,
                approveAppPending: true
            };

        case Actions.DELETE_APP:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                apps : state.apps.filter(app => {
                    return app.app_id !== action.payload.appId
                }),
                deleteSuccess: true
            });

        case Actions.DELETING_APP:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                deletingApp: true
            });

        case Actions.DELETE_FAILED:
            return Object.assign({}, state, {
                deleteFailed: true
            });

        case Actions.RESET_CREATE_APP_DIALOG:
            return {
                ...state,
                showAddAppDialog: true
            };

        case Actions.HIDE_CREATE_APP_DIALOG:
            return {
                ...state,
                showAddAppDialog: false
            };

        case Actions.SHOW_CREATE_APP_DIALOG:
            return {
                ...state,
                showAddAppDialog: true
            };

        case Actions.RESET_APP_APPROVAL:
            return {
                ...state,
                approveAppSuccess: false,
                approveAppPending: false,
                approveAppFailed: false,
                showAddAppDialog: true
            };

        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;

    }
}

export default appsReducer;