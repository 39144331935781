/**
 * Created by Rakesh Peela
 * Date: 07-Mar-2020
 * Time: 1:46 AM
 */
import {List} from "@material-ui/core";
import React from 'react';
import {useDropzone} from 'react-dropzone';
import styled from 'styled-components';

const getColor = (props) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isDragActive) {
        return '#2196f3';
    }
    return '#eeeeee';
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export function StyledDropzone(props) {
    const {acceptType, onChange} = props;
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject
    } = useDropzone({
        accept: acceptType || "text/csv",
        multiple: false,
        noDrag: true,
        maxSize: 5000,
        getFilesFromEvent: (event) => {
            const filesList = event.target.files;
            if (filesList.length > 0) {
                onChange(filesList[0])
            }

            return filesList;
        }
    });

    return (
        <div className="container">
            <Container {...getRootProps({isDragActive, isDragAccept, isDragReject})}>
                <input {...getInputProps()} />
                <p>Click to select CSV file</p>
            </Container>
        </div>
    );
}
