/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    CUSTOM_REPORT_EXPLORER_PENDING, CUSTOM_REPORT_EXPLORER, CUSTOM_REPORT_EXPLORER_FAILED,
    UPDATE_CUSTOM_REPORT_USER_GROUP, UPDATE_EXPLORER_LIMIT, UPDATE_CUSTOM_REPORT_EXPLORER_QUERY,
    RESET_CUSTOM_REPORT, UPDATE_CUSTOM_REPORT_METRICS, UPDATE_CUSTOM_REPORT_DIMENSIONS,
    CUSTOM_REPORT_FLAT_TABLE_PENDING, CUSTOM_REPORT_FLAT_TABLE, CUSTOM_REPORT_FLAT_TABLE_FAILED,
    CUSTOM_REPORTS, CUSTOM_REPORT,
    CUSTOM_REPORT_CREATE_PENDING, CUSTOM_REPORT_CREATE, CUSTOM_REPORT_CREATE_FAILED,
    CUSTOM_REPORT_DELETE_PENDING, CUSTOM_REPORT_DELETE, CUSTOM_REPORT_DELETE_FAILED, UPDATE_CUSTOM_REPORT_FILTERS
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';
import {ALL_USERS} from "../../../../../../constants/index";

const INITIAL_EXPLORER_FLAG_STATE = {
    explorer_pending: false,
    explorer_failed: false
};

const INITIAL_FLAT_TABLE_FLAG_STATE = {
    flat_table_pending: false,
    flat_table_failed: false
};

const INITIAL_CUSTOM_REPORT_SAVE_FLAG_STATE = {
    save_report_pending: false,
    save_report_failed: false
};

const INITIAL_CUSTOM_REPORT_DELETE_FLAG_STATE = {
    delete_report_pending: false,
    delete_report_failed: false
};

const INITIAL_STATE = {
    group: ALL_USERS,
    metrics: [],
    dimensions: [],
    filters: [],
    limit: 1000,
    explorer: {},
    query: {metrics: [], dimensions: []},
    flat_table: {},
    reports: [],
    report: {},
    ...INITIAL_EXPLORER_FLAG_STATE,
    ...INITIAL_FLAT_TABLE_FLAG_STATE,
    ...INITIAL_CUSTOM_REPORT_SAVE_FLAG_STATE,
    ...INITIAL_CUSTOM_REPORT_DELETE_FLAG_STATE
};

function customReportReducer(state = INITIAL_STATE, action = {}) {
    const { type, payload } = action;
    switch (type) {

        case CUSTOM_REPORT_EXPLORER_PENDING:
            return {
                ...state,
                ...INITIAL_EXPLORER_FLAG_STATE,
                explorer_pending: true
            };

        case CUSTOM_REPORT_EXPLORER:
            return {
                ...state,
                ...INITIAL_EXPLORER_FLAG_STATE,
                explorer: payload
            };

        case CUSTOM_REPORT_EXPLORER_FAILED:
            return {
                ...state,
                ...INITIAL_EXPLORER_FLAG_STATE,
                explorer_failed: true,
                explorer: {aggregations: {}, data: []}
            };

        case CUSTOM_REPORT_FLAT_TABLE_PENDING:
            return {
                ...state,
                ...INITIAL_FLAT_TABLE_FLAG_STATE,
                flat_table_pending: true
            };

        case CUSTOM_REPORT_FLAT_TABLE:
            return {
                ...state,
                ...INITIAL_FLAT_TABLE_FLAG_STATE,
                flat_table: payload
            };

        case CUSTOM_REPORT_FLAT_TABLE_FAILED:
            return {
                ...state,
                ...INITIAL_FLAT_TABLE_FLAG_STATE,
                flat_table_failed: true,
                flat_table: {aggregations: {}, data: []}
            };

        case CUSTOM_REPORT_DELETE_PENDING:
            return {
                ...state,
                ...INITIAL_CUSTOM_REPORT_DELETE_FLAG_STATE,
                delete_report_pending: true
            };

        case CUSTOM_REPORT_DELETE:
            return {
                ...state,
                ...INITIAL_CUSTOM_REPORT_DELETE_FLAG_STATE,
                report_deleted: payload
            };

        case CUSTOM_REPORT_DELETE_FAILED:
            return {
                ...state,
                ...INITIAL_CUSTOM_REPORT_DELETE_FLAG_STATE,
                delete_report_failed: true
            };

        case CUSTOM_REPORT_CREATE_PENDING:
            return {
                ...state,
                ...INITIAL_CUSTOM_REPORT_SAVE_FLAG_STATE,
                save_report_pending: true
            };

        case CUSTOM_REPORT_CREATE:
            return {
                ...state,
                ...INITIAL_CUSTOM_REPORT_SAVE_FLAG_STATE,
                report_saved: payload
            };

        case CUSTOM_REPORT_CREATE_FAILED:
            return {
                ...state,
                ...INITIAL_CUSTOM_REPORT_SAVE_FLAG_STATE,
                save_report_failed: true
            };

        case CUSTOM_REPORTS:
            return {
                ...state,
                reports: payload
            };

        case CUSTOM_REPORT:
            return {
                ...state,
                report: payload
            };

        case UPDATE_CUSTOM_REPORT_USER_GROUP:
            return {
                ...state,
                group: payload
            };

        case UPDATE_CUSTOM_REPORT_METRICS:
            return {
                ...state,
                metrics: payload
            };

        case UPDATE_CUSTOM_REPORT_DIMENSIONS:
            return {
                ...state,
                dimensions: payload
            };

        case UPDATE_CUSTOM_REPORT_FILTERS:
            return {
                ...state,
                filters: payload
            };

        case UPDATE_EXPLORER_LIMIT:
            return {
                ...state,
                limit: payload
            };

        case UPDATE_CUSTOM_REPORT_EXPLORER_QUERY:
            return {
                ...state,
                query: payload
            };

        case RESET_CUSTOM_REPORT:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default customReportReducer;
