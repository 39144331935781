import {
     SELF_EVENT_DISTRIBUTION, SELF_EVENT_COUNT,
     SELF_EVENT_HOUR_DISTRIBUTION,
     SELF_RETENTION_PENDING, SELF_RETENTION, SELF_RETENTION_FAILED,
     SELF_STATISTICS, SELF_USERS_FOR_EVENT,
     SELF_EVENT_RELATED, SELF_SEGMENT_RELATED,
     SELF_GLOBAL_RELATED
} from '../actionTypes';
import {RESET_ALL} from '../../../../../../../constants/ActionTypes';
const INITIAL_STATE = {
};

const RETENTION_FLAG_STATE = {
    retention_pending: false,
    retention_failed: false
};

function selfReducer(state = INITIAL_STATE, action = {}) {

    const { key, sub_key } = action.meta || { };

    switch (action.type) {

        case SELF_EVENT_DISTRIBUTION:
        case SELF_EVENT_HOUR_DISTRIBUTION:
        case SELF_EVENT_COUNT:
        case SELF_USERS_FOR_EVENT:
            return {
                ...state,
                [key]: action.payload
            };

        case SELF_RETENTION_PENDING:
            return {
                ...state,
                ...RETENTION_FLAG_STATE,
                retention_pending: true
            };

        case SELF_RETENTION_FAILED:
            return {
                ...state,
                ...RETENTION_FLAG_STATE,
                retention_failed: true
            };

        case SELF_RETENTION:
            return {
                ...state,
                ...RETENTION_FLAG_STATE,
                [key]: action.payload
            };

        case SELF_EVENT_RELATED:
            if(sub_key){ //FIXME: HACK for aggregated time series
                return {
                    ...state,
                    [key]: {
                        ...state[key],
                        [sub_key]: action.payload
                    }
                };
            }
            return {
                ...state,
                [key]: action.payload
            };

        case SELF_GLOBAL_RELATED:
            if(sub_key){ //FIXME: HACK for aggregated time series
                return {
                    ...state,
                    [key]: {
                        ...state[key],
                        [sub_key]: action.payload
                    }
                };
            }
            return {
                ...state,
                [key]: action.payload
            };

        case SELF_SEGMENT_RELATED:
            return {
                ...state,
                [key]: action.payload
            };

        case SELF_STATISTICS:
            return {
                ...state,
                ...RETENTION_FLAG_STATE,
                [key]: {
                    ...state[key],
                    [sub_key]: action.payload
                }
            };

        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default selfReducer;
