/**
 * Created by jyothi on 14/1/17.
 */
import React, {Component, Fragment} from 'react';
import {getGroupFromList, toTimeSpent} from '../../../../../../../utils';
import ColorPicker from '../../../../../../../components/reusable/ColorPicker';
import Switch from '../../../../../../../components/reusable/Switch';
import {MESSAGE_ENUM, MESSAGE_POSITION_ENUM} from '../actionTypes';
import moment from 'moment';
import {ANIMATIONS_ENUM, APP_PLATFORMS, ART_CONFIG_TYPES} from "../../../../../../../constants/index";
import {Fab, Grid, IconButton, TextField, Tooltip} from "@material-ui/core";
import FormSelect from "../../../../../../../components/reusable/MaterialUi/FormSelect";
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import ToggleSwitch from "../../../../../../../components/reusable/MaterialUi/ToggleSwitch";
import DeleteIcon from '@material-ui/icons/Delete';
import Loading from "../../../../../../../components/reusable/Loading";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import Checkbox from "../../../../../../../components/reusable/MaterialUi/Checkbox";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import RemoveIcon from '@material-ui/icons/HighlightOff';
import MinimizeIcon from '@material-ui/icons/RemoveCircle';
import MessageIcon from '@material-ui/icons/Message';
import AddIcon from '@material-ui/icons/Add';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import InAppMobileSimulator from "./InAppMobileSimulator";
import InLineMobileSimulator from "./InLineMobileSimulatorOld";
import {TEXT_STYLES_MAP} from './utils';
import ActionCreator from "../../../../../../../components/reusable/ActionCreator";
import InAppHTMLUISelection from "./InAppHTMLUISelection";
import ARTPublish from "../../../../../../../components/reusable/ARTPublish";
import NumberField from "../../../../../../../components/reusable/MaterialUi/NumberField";
import DeleteAction from "../../../../../../../components/reusable/DeleteAction";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Fade from '@material-ui/core/Fade';
import RichTextField from "../../../../../../../components/reusable/RichTextField";
import WYSIWYGMobile from "../../../../../../../components/reusable/WYSIWYGMobile";

const TEXT_STYLES = Object.keys(TEXT_STYLES_MAP).map(f => ({label: f, key: f}));

const Positions  = [
    MESSAGE_POSITION_ENUM.TOP,
    MESSAGE_POSITION_ENUM.CENTER,
    MESSAGE_POSITION_ENUM.BOTTOM
];

const Fonts = [
    "casual", "cursive", "monospace", "sans-serif",
    "sans-serif-black", "sans-serif-condensed", "sans-serif-condensed-light", "sans-serif-light",
    "sans-serif-medium", "sans-serif-smallcaps", "sans-serif-thin", "serif", "serif-monospace"
].map(f => ({label: f, key: f, style: {fontFamily: f}}));

const AUDIENCE_ENUM = {
    FTU: "FTU",
    ALL: "ALL"
};

export class DesignSelector extends Component{

    constructor(props){
        super(props);
        this.state = {
            delay: 4000,
            type: props.ui.type || MESSAGE_ENUM.IN_APP,
            ui: props.ui || {},
            uis: props.uis || []
        }
    }

    componentWillMount(){
        //this.handleUpdate();
        this.setState({delay: this.state.ui.delay || 4000});
    }

    handleUpdate = () => {
        const { delay, type, ...others } = this.state;
        others.ui.delay = delay;
        others.ui.type = type;
        this.props.handleUpdate(others);
    };


    render(){
        const { messages: { uis = [] } = {}, inEditMode, isNew } = this.props;
        const { type, uiId, ui, delay, needUpdate } = this.state;
        const { IN_LINE, IN_APP, WALKTHROUGH } = MESSAGE_ENUM;
        const disabled = !inEditMode && this.props.disabled;
        return(
            <Grid container>
                <Grid item xs>
                    <br />
                    <Typography component="span" variant="subtitle1">
                        Select design &nbsp;
                    </Typography>
                    <Grid container justify="space-between">
                        <Grid item xs={12} md={4}>
                            <Switch
                                data={Object.keys(MESSAGE_ENUM)}
                                handleChange={type => this.setState({type, ui:{}, uiId: null}, this.handleUpdate)}
                                value={type} disabled={disabled}
                            />
                        </Grid>
                        {type !== WALKTHROUGH &&
                        <Grid item xs={12} md={4}>
                            <MultiSelect
                                options={uis.filter(o => o.type === type).map(o => ({label: o.name, value: o._id}))}
                                value={uiId}
                                label="Select from existing UI templates"
                                handleChange={(uiId) => {
                                    const ui = getGroupFromList(uis, uiId);
                                    this.setState({uiId, ui: {...ui.config}, name: ui.name, needUpdate: true}, () => {
                                        this.handleUpdate();
                                        setTimeout(() => {
                                            this.setState({needUpdate: false});
                                        }, 100);
                                    });
                                }}
                                style={{width: 300, marginLeft: 'auto'}}
                                disabled={disabled}
                                margin="normal"
                                single
                            />
                        </Grid>
                        }
                    </Grid>
                    {
                        type === WALKTHROUGH &&
                        <WalkThroughsConfig
                            {...this.props}
                            disabled={disabled}
                            handleUpdate={uis => this.setState(uis, this.handleUpdate)}
                        />
                    }
                    {
                        type !== WALKTHROUGH && <Fragment>
                            <Typography component="span" style={{lineHeight: '4em', display: 'inline-flex', margin: '8px 0'}} variant="subtitle1">
                                Delay showing the message by &nbsp;
                                {disabled && <strong>{toTimeSpent(delay / 1000)}</strong>}
                                { !disabled && <NumberField
                                    placeholder="2000 (Optional)"
                                    value={delay}
                                    label="Duration"
                                    handleChange={delay => this.setState({delay}, this.handleUpdate)}
                                    disabled={disabled}
                                    metricType="ms"
                                /> }
                            </Typography>
                            { type === IN_LINE && <InLineUISelection
                                {...this.props} config={ui} inEditMode={inEditMode || isNew} disabled={disabled} isNew={!uiId && isNew}
                                handleUpdate={data => this.setState({ui: data.config}, this.handleUpdate)}
                                showControls={false} noName needUpdate={needUpdate}
                            /> }
                            { type === IN_APP && <InAppHTMLUISelection
                                {...this.props} config={ui} inEditMode={inEditMode || isNew} disabled={disabled} isNew={!uiId && isNew}
                                handleUpdate={data => this.setState({ui: {...data.config}}, this.handleUpdate)}
                                showControls={false} noName
                            /> }
                        </Fragment>
                    }
                </Grid>
            </Grid>
        )

    }

}

const ITEM_HEIGHT = 36;

class WalkThroughsConfig extends Component {

    constructor(props){
        super(props);
        const { uis = [] }  = props;
        this.state = { uis, current: 0, anchorEl: null, index: 0 };
    }

    componentWillMount(){

    }

    componentWillReceiveProps(nextProps){
        this.setState({uis: nextProps.uis});
    }

    handleUpdate = (index) => (data) => {
        const { uis } = {...this.state};
        this.setState({
            uis: [...uis.slice(0, index), data, ...uis.slice(index + 1)]
        }, () => this.props.handleUpdate({uis: this.state.uis}));
    };

    handleDelete = (index) => {
        const { uis } = {...this.state};
        this.setState({
            uis: [...uis.slice(0, index), ...uis.slice(index + 1)],
            current: index !== 0 ? index - 1 : 0
        }, () => this.props.handleUpdate({uis: this.state.uis}));
    };

    handleChange = (event, current) => {
        this.setState({ current });
    };

    handleClick = (index) => event => {
        event.stopPropagation();
        this.setState({ anchorEl: event.currentTarget, index });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    handleMoveLeft = (index) => {
        const { uis } = {...this.state};
        const [ previous, current ] = uis.slice(index - 1, index + 1);
        this.setState({
            uis: [...uis.slice(0, index - 1), current, previous, ...uis.slice(index + 1)],
            current: index - 1 //moving left
        }, () => this.props.handleUpdate({uis: this.state.uis}));
    };

    handleMoveRight = (index) => {
        const { uis } = {...this.state};
        const [ current, next ] = uis.slice(index, index + 2);
        this.setState({
            uis: [...uis.slice(0, index), next, current, ...uis.slice(index + 2)],
            current: index + 1 //moving to next
        }, () => this.props.handleUpdate({uis: this.state.uis}));
    };

    handleDuplicate = (index) => {
        const { uis } = {...this.state};
        const current = uis.slice(index,  index + 1);
        this.setState({
            uis: [...uis, ...current],
            current: uis.length //moving to end
        }, () => this.props.handleUpdate({uis: this.state.uis}));
    };

    renderTabWithMenu = (index, ui) => {
        const { anchorEl } = this.state;
        return(
            <span key={"step-" + index}>
                {`Step ${index + 1} (${ui.type})`}
                <IconButton
                    aria-label="More"
                    aria-owns={Boolean(anchorEl) ? 'long-menu' + index : undefined}
                    aria-haspopup="true"
                    onClick={this.handleClick(index)}
                    style={{padding: 2}}
                >
                    <MoreVertIcon color="primary" />
                </IconButton>
            </span>
        )
    };

    render(){
        const {
            disabled = false
        } = this.props;
        const { classes, uis } = this.props;
        const { current, index, anchorEl } = this.state;
        const showAddNew = !disabled;
        return(
            <Grid container>
                <Grid item xs style={{border: '1px solid rgba(229, 221, 243, 0.9)', padding: 8}}>
                    <Tabs
                        value={current}
                        textColor="primary"
                        indicatorColor="primary"
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons="off"
                        style={{minHeight: 36}}
                    >
                        {
                            uis.map((ui, index) =>
                                <Tab
                                    classes={classes}
                                    value={index} component="span"
                                    key={"walk-through-ui-tab-" + index}
                                    label={this.renderTabWithMenu(index, ui)}
                                />
                            )
                        }
                        {
                            showAddNew && <Tab disabled={current === uis.length} classes={classes} value={uis.length} label={<Tooltip title="Add New">
                                <AddIcon color="primary" />
                            </Tooltip>} />
                        }
                    </Tabs>
                    {
                        uis.map((ui, index) =>
                            current === index &&
                            <WalkThroughUIHolder
                                key={"walk-through-ui-" + index}
                                {...this.props}
                                handleUpdate={this.handleUpdate(index)}
                                {...ui}
                                defaultExpanded
                            />
                        )
                    }
                    {
                        current === uis.length &&
                        <WalkThroughUIHolder
                            key={"walk-through-ui-" + uis.length}
                            {...this.props}
                            type=""
                            name=""
                            ui={{}}
                            handleUpdate={this.handleUpdate(uis.length)}
                        />
                    }
                    <Menu
                        id={"long-menu"}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose}
                        TransitionComponent={Fade}
                        PaperProps={{
                            style: {
                                maxHeight: ITEM_HEIGHT * 5.5,
                                width: 120,
                                border: '1px solid rgba(229, 221, 243, 0.9)'
                            },
                        }}
                    >
                        {index > 0 &&
                            <MenuItem onClick={e => {
                                this.handleMoveLeft(index);
                                this.handleClose();
                            }}>
                                Move Left
                            </MenuItem>
                        }
                        {
                            index < uis.length - 1 &&
                                <MenuItem onClick={e => {
                                    this.handleMoveRight(index);
                                    this.handleClose();
                                }}>
                                    Move Right
                                </MenuItem>
                        }
                        <MenuItem onClick={e => {
                            this.handleDuplicate(index);
                            this.handleClose();
                        }}>
                            Duplicate
                        </MenuItem>
                        <MenuItem onClick={e => {
                            this.handleDelete(index);
                            this.handleClose();
                        }}>
                            Delete
                        </MenuItem>
                    </Menu>
                </Grid>
            </Grid>
        );
    }

}

class WalkThroughUIHolder extends Component {

    constructor(props){
        super(props);
        const { type = "", name = "", ui = {}, uiId = "" } = props;
        this.state = { type, name, ui, uiId };
    }

    componentWillMount(){

    }

    handleUpdate = () => {
        this.props.handleUpdate({...this.state});
    };

    render(){
        const {
            disabled = false, inEditMode, isNew,
            messages: { uis = []}
        } = this.props;
        const { type, name, ui, uiId, ui: { delay } } = this.props;
        const { IN_APP, IN_LINE, WALKTHROUGH } = MESSAGE_ENUM;
        return(
            <Grid container>
                <Grid item xs>
                    <Grid container justify="space-between">
                        <Grid item xs={12} md={4}>
                            <Switch
                                data={Object.keys(MESSAGE_ENUM).filter(o => o !== WALKTHROUGH)}
                                handleChange={type => this.setState({type, ui:{}, uiId: null}, this.handleUpdate)}
                                value={type} disabled={disabled}
                            />
                        </Grid>
                        { (!disabled || uiId && uiId.length > 0) && (type && type.length > 0) &&
                            <Grid item xs={12} md={4}>
                                <MultiSelect
                                    options={uis.filter(o => o.type === type).map(o => ({label: o.name, value: o._id}))}
                                    value={uiId}
                                    label="Select from existing UI templates"
                                    handleChange={(uiId) => {
                                        const ui = getGroupFromList(uis, uiId);
                                        this.setState({uiId, ui: {...ui.config}, name: ui.name}, this.handleUpdate);
                                    }}
                                    fullWidth
                                    disabled={disabled}
                                    margin="normal"
                                    single
                                />
                            </Grid>
                        }
                    </Grid>
                    <Typography component="span" style={{lineHeight: '4em', display: 'inline-flex', margin: '8px 0'}} variant="subtitle1">
                        Delay showing the message by &nbsp;
                        {disabled && <strong>{toTimeSpent(delay / 1000)}</strong>}
                        {!disabled &&
                            <NumberField
                                placeholder="2000 (Optional)"
                                value={delay}
                                label="Duration"
                                handleChange={delay => this.setState({ui: { ...ui, delay }}, this.handleUpdate)}
                                disabled={disabled}
                                metricType="ms"
                            />
                        }
                    </Typography>
                    { type === IN_LINE && <InLineUISelection
                        {...this.props} config={ui} inEditMode={inEditMode || isNew} disabled={disabled} isNew={!uiId && isNew}
                        handleUpdate={data => this.setState({ui: data.config, name: data.name}, this.handleUpdate)}
                        showControls={false} noName
                    /> }
                    { type === IN_APP && <InAppHTMLUISelection
                        {...this.props} config={ui} inEditMode={inEditMode || isNew} disabled={disabled} isNew={!uiId && isNew}
                        handleUpdate={data => this.setState({ui: {...data.config}, name: data.name}, this.handleUpdate)}
                        showControls={false} noName
                    /> }
                </Grid>
            </Grid>
        );
    }

}

const DEFAULT_TEXT_COLOR = "#F1F1F1";
/**
 * Takes position and sets background image position
 * @param position
 */

const DISPLAY_TYPE_ENUM = {
    INLINE: "inline",
    COACH_MARK: "coach_mark",
    PASSIVE_NUDGE: 'passive_nudge'
};

const BUTTONS = {
    None: "None",
    Single: "Single",
    Double: "Double"
};

const { INLINE, COACH_MARK, PASSIVE_NUDGE } = DISPLAY_TYPE_ENUM;

export class InLineUISelection extends Component{

    _INITIAL_STATE = {
        name: "",
        activity: null,
        delay: 1000,
        dismiss_outside_touch: false,
        position: MESSAGE_POSITION_ENUM.TOP,
        corners: {
            enabled: false,
            width: 5,
        },
        border: {
            color: '#000000',
            width: 0
        },
        text_config: {
            font_family: "sans-serif",
            size: 14,
            style: "normal",
            color: DEFAULT_TEXT_COLOR,
            text: "Text Here"
        },
        animation: {
            enabled: true,
            type: "cycle",
            duration: 400,
        },
        display_type: "coach_mark",
        coach_mark_details: {
            type: "circle",
            has_ripple: true,
            has_arrow: true,
            arrow_color: DEFAULT_TEXT_COLOR,
            arrow_stroke_width: 10
        },
        bg_color: '#444444', //FIXME: change this
        opacity: 0.6,
        view_id: "",
        dynamic_viewId_app_event: "",
        localization_enabled: true,
        language_attribute: "",
        localized_messages: {},
        isViewDynamic: false,
        hasOkButton: false,
        hasCloseButton: false,
        closeButton: DEFAULT_BUTTON_CONFIG("Close", -1),
        okButton: DEFAULT_BUTTON_CONFIG("Ok", -1),
    };

    constructor(props) {
        super(props);
        this.state = this._propToState(props);
    }

    componentWillMount(){
        //this.setState({inEditMode: this.props.inEditMode || false});
    }

    componentWillReceiveProps(nextProps){
        this.setState(this._propToState(nextProps));
    }

    _propToState = (props) => {
        const { config: propConfig = {} } = props;
        const config = {...this._INITIAL_STATE, ...propConfig};
        if(typeof propConfig === 'object' && propConfig.hasOwnProperty("name")) {
            const {
                text_config, text_config: {localized_messages} = {},
                isNew, sendMessageUI,
                buttons_config = [],
                ...otherProps} = config;
            let okButton = DEFAULT_BUTTON_CONFIG("Ok", -1);
            let closeButton = DEFAULT_BUTTON_CONFIG("Close", -1);
            const assignButton = (button) => {
                if(!button) return;
                if(button.id === "okButton"){
                    okButton = button;
                }else{
                    closeButton = button;
                }
            };
            assignButton(buttons_config[0]);
            assignButton(buttons_config[1]);
            return {
                ...otherProps,
                text_config,
                localized_messages,
                hasOkButton: okButton.visibility !== -1,
                hasCloseButton: closeButton.visibility !== -1,
                closeButton, okButton,
                isViewDynamic: propConfig.hasOwnProperty("dynamic_viewId_app_event")
            };
        }else{
            return this._INITIAL_STATE;
        }
    };

    _stateToModel = () => {
        const {
            inEditMode, isViewDynamic, localized_messages, text_config,
            hasOkButton, hasCloseButton, closeButton, okButton,
            ...others
        } = this.state;
        if(text_config){
            text_config.localized_messages = localized_messages;
        }
        let buttons_config = [];
        if(hasOkButton){
            buttons_config.push({ ...okButton, id: "okButton" });
        }
        if(hasCloseButton){
            buttons_config.push({ ...closeButton, id: "closeButton" });
        }
        let obj = {
            ...others,
            text_config,
            buttons_config
        };
        const {
            name, view_id, activity, dynamic_viewId_app_event, bg_color,
            display_type, extra_config
        } = obj;
        if(obj.display_type === PASSIVE_NUDGE){
            obj = {
                name, extra_config,
                view_id, activity, dynamic_viewId_app_event, bg_color,
                has_ripple: false, //TODO: implement has ripple
                display_type
            };
        }
        //Removing dynamic view id
        if(isViewDynamic){
            delete obj.view_id;
        }else{
            delete obj.dynamic_viewId_app_event;
        }
        return obj;
    };

    handleUpdate = () => {
        setTimeout(() => {
            const { handleUpdate } = this.props;
            if(typeof handleUpdate === 'function'){
                const { name } = this.state;
                handleUpdate({
                    name,
                    type: MESSAGE_ENUM.IN_LINE,
                    config: this._stateToModel()
                });
            }
        }, 0);
    };

    handleSave = (e) => {
        e.preventDefault();
        const { handleSave } = this.props;
        const { name } = this.state;
        handleSave({
            name,
            type: MESSAGE_ENUM.IN_LINE,
            config: this._stateToModel()
        });
        this.setState({...this._INITIAL_STATE})
    };

    handleEdit = (e) => {
        e.preventDefault();
        const { handleEdit, uiId } = this.props;
        const { name } = this.state;
        handleEdit(uiId, {
            name,
            type: MESSAGE_ENUM.IN_LINE,
            config: this._stateToModel()
        });
    };

    render(){
        const {
            name, activity, dismiss_outside_touch, position,
            corners, corners: {width} = {}, text_config,
            animation, animation: {type, duration} = {},
            display_type, coach_mark_details,
            bg_color, opacity, view_id, dynamic_viewId_app_event,
            isViewDynamic, border = {color: bg_color, width: 0},
            okButton, closeButton, hasOkButton, hasCloseButton,
            extra_config
        } = this.state;
        const { color, width: borderWidth } = border;
        const {
            params: { appId },
            isNew,
            inEditMode, appState: {appScreenNames = [], app: {basic_info: {platform}}},
            showControls = true,
            attributeSuggestions, config,
            noName, needUpdate
        } = this.props;
        const disabled = !inEditMode && this.props.disabled;
        const isOldConfig = !isNew && !extra_config;
        return(
            <Grid container spacing={16}>
                <Grid item xs>
                    <ExpansionPanel defaultExpanded>
                        <ExpansionPanelDetails>
                            <Grid container spacing={16} justify="center">
                                <Grid item xs={12} md={4} >
                                    <div style={{margin: '32px 0'}}>
                                        {
                                            !isOldConfig &&
                                            <WYSIWYGMobile
                                                simulatorProps={{...this.state, config: {type: MESSAGE_ENUM.IN_LINE, ui: this._stateToModel()}}}
                                                handleNavigationUpdate={activity => this.setState({activity}, this.handleUpdate)}
                                                handleViewIdUpdate={view_id => this.setState({view_id}, this.handleUpdate)}
                                                handleConfigUpdate={extra_config => this.setState({extra_config}, this.handleUpdate)}
                                                disabled={disabled}
                                                config={extra_config}
                                                appId={appId}
                                                needUpdate={needUpdate}
                                            />
                                        }
                                        {
                                           isOldConfig && <InLineMobileSimulator {...this.state}/>
                                        }
                                    </div>
                                </Grid>
                                { !disabled &&
                                <Grid item xs>
                                    <Grid container spacing={16} style={{maxHeight: '90vh', overflowY: 'auto'}}>
                                        <Grid item xs={12}>
                                            <Switch disabled={disabled} data={Object.values(DISPLAY_TYPE_ENUM)} value={display_type} handleChange={
                                                display_type => this.setState({display_type}, this.handleUpdate)
                                            }/>
                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    {
                                                        !noName && <TextField
                                                            type="text"
                                                            required
                                                            placeholder="button_inline_info"
                                                            value={name}
                                                            label="Design Name"
                                                            onChange={e => this.setState({name: e.target.value}, this.handleUpdate)}
                                                            disabled={disabled}
                                                            fullWidth
                                                            margin="normal"
                                                        />
                                                    }
                                                    <MultiSelect
                                                        options={appScreenNames.map(o => ({label: o, value: o}))}
                                                        value={activity}
                                                        placeholder={platform === APP_PLATFORMS.ios ? "Select View Controller" : "Select Activity"}
                                                        handleChange={
                                                            activity => this.setState({activity}, this.handleUpdate)
                                                        }
                                                        disabled={disabled}
                                                        single
                                                        margin="normal"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={16}>
                                                {platform !== APP_PLATFORMS.ios && <Grid item xs={4}>
                                                    <ToggleSwitch
                                                        handleChange={isViewDynamic =>  {
                                                            this.setState({
                                                                isViewDynamic,
                                                                view_id: isViewDynamic ? undefined : '',
                                                                dynamic_viewId_app_event: isViewDynamic ? '' : undefined
                                                            }, this.handleUpdate);
                                                        }}
                                                        label="Dynamic View"
                                                        checked={isViewDynamic}
                                                        disabled={disabled}
                                                    />
                                                </Grid>}
                                                <Grid item xs={8}>
                                                    {
                                                        !isViewDynamic && <TextField
                                                            type="text"
                                                            placeholder="tiles_tab"
                                                            value={view_id}
                                                            label="View Id"
                                                            onChange={e =>  this.setState({view_id: e.target.value}, this.handleUpdate)}
                                                            fullWidth
                                                            disabled={disabled}
                                                        />
                                                    }
                                                    {
                                                        isViewDynamic && <TextField
                                                            type="text"
                                                            placeholder="SCROLL_VIEW_APP_EVENT"
                                                            value={dynamic_viewId_app_event}
                                                            label="Dynamic View Id"
                                                            onChange={e =>  this.setState({dynamic_viewId_app_event: e.target.value}, this.handleUpdate)}
                                                            fullWidth
                                                            disabled={disabled}
                                                        />
                                                    }
                                                </Grid>
                                            </Grid>
                                            {
                                                display_type !== PASSIVE_NUDGE &&
                                                <Fragment>
                                                    <FormSelect
                                                        options={Object.keys(MESSAGE_POSITION_ENUM).filter(o => o !== MESSAGE_POSITION_ENUM.CENTER)}
                                                        value={position}
                                                        label="Position"
                                                        handleUpdate={
                                                            position =>  this.setState({position}, this.handleUpdate)
                                                        }
                                                        disabled={disabled}
                                                        margin="normal"
                                                        containerStyles={{maxWidth: 120}}
                                                    />
                                                    <TextViewConfig
                                                        withMacros
                                                        attributeSuggestions={attributeSuggestions}
                                                        disabled={disabled}
                                                        label={"Enter Text"}
                                                        {...text_config}
                                                        handleUpdate={text_config => this.setState({text_config}, this.handleUpdate)}
                                                    />
                                                </Fragment>
                                            }
                                            <Grid container spacing={16}>
                                                <Grid item xs={6} md={4}>
                                                    <ColorPicker
                                                        margin="normal" label="Background Color"
                                                        disabled={disabled} color={ bg_color }
                                                        handleChange={bg_color => this.setState({bg_color}, this.handleUpdate)}
                                                    />
                                                </Grid>
                                                {
                                                    display_type !== PASSIVE_NUDGE &&
                                                    <Grid item xs={6} md={4}>
                                                        <TextField
                                                            type="number"
                                                            required
                                                            placeholder="2000"
                                                            value={opacity}
                                                            label="Opacity"
                                                            onChange={e => {
                                                                const opacity = Number(e.target.value);
                                                                if(opacity >= 0 && opacity <= 1){
                                                                    this.setState({opacity: Number(e.target.value)}, this.handleUpdate)
                                                                }
                                                            }}
                                                            disabled={disabled}
                                                            margin="normal"
                                                        />
                                                    </Grid>
                                                }
                                            </Grid>
                                            {
                                                display_type === DISPLAY_TYPE_ENUM.INLINE &&
                                                <div>
                                                    <Grid container spacing={16}>
                                                        <Grid item xs={5}>
                                                            <ColorPicker
                                                                label="Border Color" disabled={disabled} color={ color } fullWidth
                                                                handleChange={color => this.setState({border: {...border, color}}, this.handleUpdate)}
                                                                margin="normal"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <TextField
                                                                type="number"
                                                                required
                                                                placeholder="0"
                                                                value={borderWidth}
                                                                label="Border Width"
                                                                onChange={e => this.setState({border: {...border, width: Number(e.target.value)}}, this.handleUpdate)}
                                                                fullWidth
                                                                disabled={disabled}
                                                                margin="normal"
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <br />
                                                    <ToggleSwitch
                                                        handleChange={
                                                            (enabled) =>  this.setState({animation: { ...this.state.animation, enabled}}, this.handleUpdate)}
                                                        label="Enable Animation"
                                                        checked={animation.enabled}
                                                        disabled={disabled}
                                                    />
                                                    {/*{
                                                        animation.enabled && <div>
                                                            <Switch disabled={disabled} data={["cycle", "blink"]} value={type} handleChange={
                                                                type => this.setState({animation:{...animation, type}}, this.handleUpdate)
                                                            } />
                                                            <NumberField
                                                                required
                                                                placeholder="2000"
                                                                value={duration}
                                                                label="Duration in Milli Secs"
                                                                handleChange={duration =>
                                                                    this.setState({animation:{...animation, duration}}, this.handleUpdate)
                                                                }
                                                                fullWidth
                                                                margin="normal"
                                                                disabled={disabled}
                                                                metricType="ms"
                                                            />
                                                        </div>
                                                    }*/}
                                                    <br />
                                                    <Grid container spacing={16}>
                                                        <Grid item xs={12} md={4}>
                                                            <ToggleSwitch
                                                                handleChange={
                                                                    (enabled) =>  this.setState({corners: {enabled, width: enabled ? corners.width : 0}}, this.handleUpdate)}
                                                                label="Rounded Corners"
                                                                checked={corners.enabled}
                                                                disabled={disabled}
                                                            />
                                                        </Grid>
                                                        {
                                                            corners.enabled && <Grid item xs={12} md>
                                                                <TextField
                                                                    type="number"
                                                                    required
                                                                    placeholder="5"
                                                                    value={width}
                                                                    label="Corner Width"
                                                                    onChange={e => this.setState({corners: {
                                                                            ...corners,
                                                                            width: Number(e.target.value)
                                                                        }}, this.handleUpdate)
                                                                    }
                                                                    disabled={disabled}
                                                                />
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </div>
                                            }
                                            {
                                                display_type === DISPLAY_TYPE_ENUM.COACH_MARK &&
                                                <CoachMarkConfig disabled={disabled} {...coach_mark_details} handleUpdate={
                                                    coach_mark_details => this.setState({coach_mark_details}, this.handleUpdate)
                                                }/>
                                            }
                                            {
                                                display_type !== DISPLAY_TYPE_ENUM.PASSIVE_NUDGE &&
                                                <Fragment>
                                                    <Typography style={{marginTop: 16}}>Buttons</Typography>
                                                    <Switch
                                                        disabled={disabled}
                                                        data={Object.keys(BUTTONS)}
                                                        handleChange={button => {
                                                            const okButton = {...this.state.okButton};
                                                            const closeButton = {...this.state.closeButton};
                                                            this.setState({
                                                                    hasOkButton: button !== BUTTONS.None,
                                                                    okButton: {...okButton, visibility: button !== BUTTONS.None ? 1 : -1},
                                                                    hasCloseButton: button === BUTTONS.Double,
                                                                    closeButton: {...closeButton, visibility: button === BUTTONS.Double ? 1 : -1},
                                                                },
                                                                this.handleUpdate
                                                            );
                                                        }}
                                                        containerStyles={{marginTop: 8}}
                                                        value={hasOkButton && hasCloseButton ? BUTTONS.Double : (hasOkButton || hasCloseButton ? BUTTONS.Single : BUTTONS.None)}
                                                    />
                                                    {
                                                        hasOkButton && <ButtonConfig {...this.props} disabled={disabled} id="okButton" {...okButton} handleUpdate={okButton => this.setState({okButton}, this.handleUpdate)}/>
                                                    }
                                                    {
                                                        hasCloseButton && <ButtonConfig {...this.props} disabled={disabled} id="closeButton" {...closeButton} handleUpdate={closeButton => this.setState({closeButton}, this.handleUpdate)}/>
                                                    }
                                                    <ToggleSwitch
                                                        handleChange={
                                                            (dismiss_outside_touch) =>  this.setState({dismiss_outside_touch}, this.handleUpdate)}
                                                        label="Dismiss outside touch"
                                                        checked={dismiss_outside_touch}
                                                        disabled={disabled}
                                                    />
                                                </Fragment>
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                            </Grid>
                        </ExpansionPanelDetails>
                        {
                            showControls && <ExpansionPanelActions>
                                {
                                    inEditMode && <Button size="small" onClick={this.handleEdit}>Update</Button>
                                }
                                {
                                    isNew && <Button size="small" variant="contained" color="primary" onClick={this.handleSave}>Save</Button>
                                }
                            </ExpansionPanelActions>
                        }
                    </ExpansionPanel>
                </Grid>
            </Grid>
        )
    }

}


const DEFAULT_SPACING_CONFIG = (space = 0) => ({
    top: space,
    bottom: space,
    left: space,
    right: space
});

class SpacingConfig extends Component { //either padding or margin

    MARGIN = "Margin";
    PADDING = "Padding";

    constructor(props){
        super(props);
        this.state = {
            active: this.MARGIN
        };
    }

    mapPropsToState = (props) => {
        const { margin_config, padding_config } = props;
        this.setState({ margin_config, padding_config });
    };

    componentWillMount(){
        this.mapPropsToState(this.props);
    }

    componentWillReceiveProps(nextProps){
        this.mapPropsToState(nextProps);
    }

    handleUpdate = () => {
        const { active, ...others } = this.state;
        const obj = {...others};
        if(!obj.margin_config.hasOwnProperty('top')){
            obj.margin_config = {...DEFAULT_SPACING_CONFIG()}
        }
        if(!obj.padding_config.hasOwnProperty('top')){
            obj.padding_config = {...DEFAULT_SPACING_CONFIG()}
        }
        this.props.handleUpdate(obj);
    };

    handleChange = (event, active) => {
        this.setState({ active });
    };

    render(){
        const { disabled = false } = this.props;
        const { active, margin_config, padding_config } = this.state;
        return(
            <Grid container spacing={16}>
                <Grid item xs>
                    <Tabs value={active} onChange={this.handleChange}>
                        <Tab label={this.MARGIN} value={this.MARGIN}/>
                        <Tab label={this.PADDING} value={this.PADDING}/>
                    </Tabs>
                    { active === this.MARGIN && <SpacingSubConfig disabled={disabled} label={this.MARGIN} {...margin_config} handleUpdate={margin_config => this.setState({margin_config}, this.handleUpdate)} /> }
                    { active === this.PADDING && <SpacingSubConfig disabled={disabled} label={this.PADDING} {...padding_config} handleUpdate={padding_config => this.setState({padding_config}, this.handleUpdate)} /> }
                </Grid>
            </Grid>
        );
    }

}

class SpacingSubConfig extends Component { //either padding or margin

    constructor(props){
        super(props);
        this.state = {
            //Will Fill on mount
        };
    }

    mapPropsToState = (props) => {
        const { top = 0, left = 0, right = 0, bottom = 0 } = props;
        this.setState({top, left, right, bottom});
    };

    componentWillMount(){
        this.mapPropsToState(this.props);
    }

    componentWillReceiveProps(nextProps){
        this.mapPropsToState(nextProps);
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const { disabled = false, label } = this.props;
        const { top, left, right, bottom } = this.state;
        return(
            <Grid container spacing={16}>
                <Grid item xs>
                    <Divider/>
                    <Grid container spacing={0}>
                        <Grid item xs />
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                required
                                placeholder="5"
                                value={top}
                                label="Top"
                                onChange={e => this.setState({top: Number(e.target.value)}, this.handleUpdate)}
                                margin="normal"
                                disabled={disabled}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                required
                                placeholder="5"
                                value={left}
                                label="Left"
                                onChange={e => this.setState({left: Number(e.target.value)}, this.handleUpdate)}
                                margin="normal"
                                disabled={disabled}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs>
                            <Typography style={{marginTop: '1.2em', textAlign: 'center'}} type="headline" color="secondary">{ label }</Typography>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                required
                                placeholder="5"
                                value={right}
                                label="Right"
                                onChange={e => this.setState({right: Number(e.target.value)}, this.handleUpdate)}
                                margin="normal"
                                disabled={disabled}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={0}>
                        <Grid item xs />
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                required
                                placeholder="5"
                                value={bottom}
                                label="Bottom"
                                onChange={e => this.setState({bottom: Number(e.target.value)}, this.handleUpdate)}
                                margin="normal"
                                disabled={disabled}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs />
                    </Grid>
                    <Divider/>
                </Grid>
            </Grid>
        );
    }

}

const DEFAULT_BORDER_CONFIG = {
    radius: 0,
    stroke_width: 1,
    stroke_color: "#"
};

class BorderConfig extends Component { //either padding or margin

    constructor(props){
        super(props);
        const { radius = 0, stroke_width = 1, stroke_color = "#" } = props;
        this.state = {
            radius, stroke_width, stroke_color
        };
    }

    mapPropsToState = (props) => {
        const { radius = 0, stroke_width = 1, stroke_color = "#" } = props;
        this.setState({radius, stroke_width, stroke_color});
    };

    componentWillMount(){
        this.mapPropsToState(this.props);
    }

    componentWillReceiveProps(nextProps){
        this.mapPropsToState(nextProps);
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const { disabled = false } = this.props;
        const { radius, stroke_width, stroke_color } = this.state;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <Typography>Border Properties</Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="number"
                        required
                        placeholder="5"
                        value={radius}
                        label="Radius"
                        onChange={e => this.setState({radius: Number(e.target.value)}, this.handleUpdate)}
                        margin="normal"
                        disabled={disabled}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        type="number"
                        required
                        placeholder="5"
                        value={stroke_width}
                        label="Stroke Width"
                        onChange={e => this.setState({stroke_width: Number(e.target.value)}, this.handleUpdate)}
                        margin="normal"
                        disabled={disabled}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12}>
                    <ColorPicker
                        margin="normal" label="Stroke Color" fullWidth
                        disabled={disabled} color={ stroke_color }
                        handleChange={stroke_color => this.setState({stroke_color}, this.handleUpdate)}
                    />
                </Grid>
            </Grid>
        );
    }

}

class TextViewConfig extends Component {

    constructor(props){
        super(props);
        this.state = {
            //Will Fill on mount
        };
    }

    mapPropsToState = (props) => {
        const {
            id, font_family="sans-serif", size = 14, style = "normal",
            color = DEFAULT_TEXT_COLOR, text = "",
            margin_config = {...DEFAULT_SPACING_CONFIG(8)}, padding_config = {...DEFAULT_SPACING_CONFIG()}
        } = props;
        this.setState({id, font_family, size, style, color, text, margin_config, padding_config});
    };

    componentWillMount(){
        this.mapPropsToState(this.props);
    }

    componentWillReceiveProps(nextProps){
        this.mapPropsToState(nextProps);
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const { disabled = false, label, textFullWidth = true, withMacros, attributeSuggestions } = this.props;
        const { font_family="sans-serif", size, style, color, text, margin_config, padding_config } = this.state;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    {
                        withMacros && <RichTextField
                            type="text"
                            required
                            placeholder={label}
                            value={text}
                            label={label}
                            fullWidth={textFullWidth}
                            disabled={disabled}
                            margin="normal"
                            suggestions={attributeSuggestions}
                            handleChange={text => this.setState({text}, this.handleUpdate)}
                            multiline
                            rowsMax={2}
                        />
                    }
                    {
                        !withMacros &&
                        <TextField
                            type="text"
                            required
                            placeholder={label}
                            value={text}
                            label={label}
                            onChange={e => this.setState({text: e.target.value}, this.handleUpdate)}
                            fullWidth={textFullWidth}
                            disabled={disabled}
                            margin="normal"
                        />
                    }
                </Grid>
                <Grid item xs={5}>
                    <FormSelect
                        options={ Fonts }
                        label="Select Font"
                        value={font_family}
                        handleUpdate={font_family => this.setState({font_family}, this.handleUpdate)}
                        disabled={disabled}
                        margin="normal"
                        single
                    />
                </Grid>
                <Grid item xs={5}>
                    <FormSelect
                        options={ TEXT_STYLES }
                        label="Select Text Style"
                        value={style}
                        handleUpdate={
                            style => this.setState({style}, this.handleUpdate)
                        }
                        disabled={disabled}
                        margin="normal"
                        single
                    />
                </Grid>
                <Grid item xs={5}>
                    <TextField
                        type="number"
                        required
                        placeholder="14"
                        value={size}
                        label="Font Size"
                        onChange={e => this.setState({size: Number(e.target.value)}, this.handleUpdate)}
                        margin="normal"
                        disabled={disabled}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={5}>
                    <ColorPicker
                        label="Color" disabled={disabled} color={ color }
                        handleChange={color => this.setState({color}, this.handleUpdate)}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                {/*<Grid item xs={12}>
                    <SpacingConfig
                        disabled={disabled}
                        margin_config={margin_config}
                        padding_config={padding_config}
                        handleUpdate={spacingConfig => this.setState(spacingConfig, this.handleUpdate)}
                    />
                </Grid>*/}
            </Grid>
        );
    }

}

class CoachMarkConfig extends Component {

    constructor(props){
        super(props);
        const { type = "circle", has_ripple = true, has_arrow = true, arrow_color = "#FFFFFF", arrow_stroke_width = 10 } = props;
        this.state = {
            type,
            has_ripple,
            has_arrow,
            arrow_color,
            arrow_stroke_width
        };
    }

    componentWillMount(){
        //
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const { disabled = false } = this.props;
        const { type, has_ripple, has_arrow, arrow_color, arrow_stroke_width } = this.state;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <Switch
                        data={["circle", "rectangle"]}
                        value={type}
                        disabled={disabled}
                        handleChange={
                            type => this.setState({type}, this.handleUpdate)
                        }
                        containerStyles={{margin: 0}}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <ToggleSwitch
                        handleChange={
                            (has_ripple) =>  this.setState({has_ripple}, this.handleUpdate)}
                        label="Has Ripple"
                        checked={has_ripple}
                        disabled={disabled}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <ToggleSwitch
                        handleChange={
                            (has_arrow) =>  this.setState({has_arrow}, this.handleUpdate)}
                        label="Has Arrow"
                        checked={has_arrow}
                        disabled={disabled}
                    />
                </Grid>
                {/*<TextField
                            type="number"
                            required
                            placeholder="10"
                            value={arrow_stroke_width}
                            label="Arrow Stroke Width"
                            onChange={e => this.setState({arrow_stroke_width: Number(e.target.value)}, this.handleUpdate)}
                            margin="normal"
                            fullWidth
                            disabled={disabled}
                        />*/}
                {
                    has_arrow && <Grid item xs={6} md={6}>
                        <ColorPicker
                            label="Arrow Color" disabled={disabled} color={ arrow_color }
                            handleChange={arrow_color => this.setState({arrow_color}, this.handleUpdate)}
                        />
                    </Grid>
                }
            </Grid>
        );
    }

}


class ButtonConfig extends Component {

    constructor(props){
        super(props);
        const {
            id, color = "#", visibility = -1, action_config = {
            action: ACTIONS.DISMISS, activity: ""
            }, text_config = {
                text: "Ok",
                size: 14,
                style: "bold",
                color: "#F1F1F1",
                font_family: "sans-serif"
            },
            margin_config = DEFAULT_SPACING_CONFIG(6),
            padding_config = DEFAULT_SPACING_CONFIG(),
            borders = DEFAULT_BORDER_CONFIG
        } = props;
        this.state = {
            id, color, visibility,
            text_config, action_config,
            margin_config, padding_config,
            borders
        };
    }

    componentWillMount(){
        //
    }

    handleUpdate = () => {
        this.props.handleUpdate({...this.state});
    };

    render(){
        const { disabled = false, theme } = this.props;
        const {
            color,
            action_config, text_config, borders,
            margin_config, padding_config
        } = this.state;
        return(
            <div style={{background: theme.palette.background.default, padding: theme.spacing.unit, margin: `${theme.spacing.unit * 2}px 0`}}>
            <Grid container spacing={16}>
                <Grid item xs>
                    <TextViewConfig {...text_config} label="Button Text" handleUpdate={
                        text_config => this.setState({text_config}, this.handleUpdate)
                    } disabled={disabled} textFullWidth={false} />
                    <ColorPicker
                        label="Background Color" disabled={disabled} color={ color }
                        handleChange={color => this.setState({color}, this.handleUpdate)}
                        margin="normal"
                    />
                    {/*<SpacingConfig
                        disabled={disabled}
                        margin_config={margin_config}
                        padding_config={padding_config}
                        handleUpdate={spacingConfig => this.setState(spacingConfig, this.handleUpdate)}
                    />*/}
                    {/*<BorderConfig
                        {...borders}
                        disabled={disabled}
                        handleUpdate={borders => this.setState({borders}, this.handleUpdate)}
                    />*/}
                    <ActionConfig {...this.props} {...action_config} handleUpdate={
                        action_config => this.setState({action_config}, this.handleUpdate)
                    } disabled={disabled} />
                </Grid>
            </Grid>
            </div>
        );
    }

}

class ImageConfig extends Component {

    constructor(props){
        super(props);
        const {
             url = "", uri = "", description = "description of image", visibility = 1,
            action_config = {
                action: ACTIONS.DISMISS, activity: ""
            }, margin_config = {...DEFAULT_SPACING_CONFIG()}, padding_config = {...DEFAULT_SPACING_CONFIG()}
        } = props;
        this.state = {
            id: "info_image", //FIXED value
            url, uri, description, visibility, action_config,
            margin_config, padding_config
        };
    }

    componentWillMount(){
        //
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const { disabled = false } = this.props;
        const {  url, uri, description, action_config, margin_config, padding_config } = this.state;
        return(
            <Grid container>
                <Grid item xs>
                    <TextField
                        type="text"
                        placeholder="https://cdn.com/image.webm"
                        value={url}
                        label="Image URL (64 x 64)"
                        onChange={e => this.setState({url: e.target.value}, this.handleUpdate)}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                    />
                    <TextField
                        type="text"
                        placeholder="../drawable/.."
                        value={uri}
                        label="Image URI"
                        onChange={e => this.setState({uri: e.target.value}, this.handleUpdate)}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                    />
                    <TextField
                        type="text"
                        required
                        placeholder="description of image"
                        value={description}
                        label="Image Description"
                        onChange={e => this.setState({description: e.target.value}, this.handleUpdate)}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                    />
                    {/*<FormSelect
                        options={ VISIBILITY_OPTIONS }
                        label="Select Visibility"
                        idKey="value" nameKey="name"
                        value={visibility}
                        handleUpdate={
                            visibility => this.setState({visibility}, this.handleUpdate)
                        }
                        disabled={disabled}
                        margin="dense"
                    />*/}
                    <SpacingConfig
                        disabled={disabled}
                        margin_config={margin_config}
                        padding_config={padding_config}
                        handleUpdate={spacingConfig => this.setState(spacingConfig, this.handleUpdate)}
                    />
                    <br />
                    <ActionConfig {...this.props} {...action_config} disabled={disabled} handleUpdate={
                        action_config => this.setState({action_config}, this.handleUpdate)
                    }/>
                </Grid>
            </Grid>
        );
    }

}

const ACTIONS = {
    DISMISS: "dismiss",
    REDIRECT: "redirect",
    CANCEL: "cancel"
};

class ActionConfig extends Component {

    constructor(props){
        super(props);
        this.state = {
            action: props.action || ACTIONS.DISMISS,
            activity: props.activity || ""
        };
    }

    componentWillMount(){
        if(this.props.isNew){
            this.handleUpdate();
        }
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const { appState: { appScreenNames = [] }, disabled = false } = this.props;
        const { action, activity } = this.state;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12} md={4}>
                    <FormSelect
                        options={ Object.values(ACTIONS) }
                        label="Select Action"
                        value={action}
                        handleUpdate={
                            action => this.setState({action}, this.handleUpdate)
                        }
                        disabled={disabled}
                        margin="dense"
                    />
                </Grid>
                {
                    action !== ACTIONS.DISMISS && <Grid item xs={12} md>
                        <MultiSelect
                            options={appScreenNames.map(o => ({label: o, value: o}))}
                            label="Select Activity"
                            value={activity}
                            handleChange={
                                activity => this.setState({activity}, this.handleUpdate)
                            }
                            disabled={disabled}
                            single
                            fullWidth
                        />
                    </Grid>
                }
            </Grid>
        );
    }

}

const INAPP_LAYOUTS = [
    "inapp_dialog_layout_top_bottom", //TODO: more will come
    "html_layout"
];

const DEFAULT_BUTTON_CONFIG = (text = "Ok", visibility = 1) => ({
    id: "",
    color: "#",
    visibility: visibility,
    action_config: {
        action: ACTIONS.DISMISS, activity: ""
    },
    text_config: {
        text: text,
        size: 14,
        style: "normal",
        color: DEFAULT_TEXT_COLOR,
        font_family: "sans-serif"
    },
    margin_config: DEFAULT_SPACING_CONFIG(6),
    padding_config: DEFAULT_SPACING_CONFIG(),
    borders: DEFAULT_BORDER_CONFIG
});

const DEFAULT_TEXT_CONFIG = () => ({
    color: "#",
    visibility: 1,
    text: "",
    size: 14,
    style: "normal",
    font_family: "sans-serif"
});

export class InAppUISelection extends Component{

    _INITIAL_STATE = {
        name: "",
        layout: "inapp_dialog_layout_top_bottom",
        delay: 4000,
        position: MESSAGE_POSITION_ENUM.CENTER,
        background: "dimmed",
        bg_color: "#FFFFFF",
        animation: "fade_in_fade_out",
        dismiss_outside_touch: true,
        titleConfig: {},
        contentConfig: {},
        hasImage: false,
        image: {},
        hasOkButton: false,
        hasCloseButton: false,
        closeButton: DEFAULT_BUTTON_CONFIG("Close"),
        okButton: DEFAULT_BUTTON_CONFIG("Ok"),
        full_width: false,
        dim_amount: 0.5
    };

    constructor(props){
        super(props);
        this.state = props.isNew ? this._INITIAL_STATE : this._propToState(props);
    }

    componentWillReceiveProps(nextProps){
        if(!nextProps.isNew){
            this.setState(this._propToState(nextProps));
        }
    }

    _propToState = (props) => {
        const { config = {} } = props;
        if(typeof config === 'object' && config.hasOwnProperty("name")) {
            const {
                name, delay, layout,
                window_attributes: {
                    position, background, bg_color, animation, dismiss_outside_touch, full_width = false, dim_amount = 0.5
                } = {},
                text_views_config,
                images_config, buttons_config
            } = props.config;
            const titleConfig = text_views_config[0];
            const contentConfig = text_views_config[1];
            const hasImage = images_config.length > 0;
            const image = hasImage ? images_config[0] : {};
            const okButton = buttons_config[0];
            const closeButton = buttons_config[1];
            return {
                name, delay, layout,
                position, background, bg_color, animation, dismiss_outside_touch,
                titleConfig, contentConfig,
                hasImage, image,
                hasOkButton: okButton.visibility !== -1,
                hasCloseButton: closeButton.visibility !== -1,
                closeButton, okButton, full_width, dim_amount
            };
        }else{
            return this._INITIAL_STATE;
        }
    };

    _stateToModel = () => {
        const {
            name, delay, layout, position, background, bg_color, animation, dismiss_outside_touch,
            titleConfig, contentConfig, hasImage, image, hasOkButton, hasCloseButton, closeButton, okButton,
            full_width = false, dim_amount = 0.5
        } = this.state;
        const mutableTitleConfig = {...DEFAULT_TEXT_CONFIG, ...titleConfig, id: "dialogTitle"};
        const mutableContentConfig = {...DEFAULT_TEXT_CONFIG, ...contentConfig, id: "dialogContent"};
        //FIXME: hack for whom...? Naa jeevitham
        let mutableOkButton = {...okButton}, mutableCloseButton = { ...closeButton };
        if(!hasOkButton){
            mutableOkButton = { ...DEFAULT_BUTTON_CONFIG("Ok"), id: "okButton", visibility: -1};
        }
        if(!hasCloseButton){
            mutableCloseButton = { ...DEFAULT_BUTTON_CONFIG("Close"), id: "closeButton", visibility: -1};
        }
        return {
            name, delay, layout,
            window_attributes: {
                position, background, bg_color, animation, dismiss_outside_touch, full_width, dim_amount
            },
            text_views_config: [ mutableTitleConfig, mutableContentConfig ],
            images_config: hasImage ? [image] : [],
            buttons_config: [mutableOkButton, mutableCloseButton]
        };
    };

    componentWillMount(){
        //this.setState({inEditMode: this.props.inEditMode || false});
    }

    handleUpdate = () => {
        //TODO: use this
        setTimeout(() => {
            const { handleUpdate } = this.props;
            if(typeof handleUpdate === 'function'){
                const { name } = this.state;
                handleUpdate({
                    name,
                    type: MESSAGE_ENUM.IN_APP,
                    config: this._stateToModel()
                });
            }
        }, 0);
    };

    handleSave = (e) => {
        e.preventDefault();
        const { handleSave } = this.props;
        const { name } = this.state;
        handleSave({
            name,
            type: MESSAGE_ENUM.IN_APP,
            config: this._stateToModel()
        });
        this.setState(this._INITIAL_STATE);
    };

    handleEdit = (e) => {
        e.preventDefault();
        const { handleEdit, uiId } = this.props;
        const { name } = this.state;
        handleEdit(uiId, {
            name,
            type: MESSAGE_ENUM.IN_APP,
            config: this._stateToModel()
        });
    };

    render(){
        const {
            isNew, inEditMode, showControls = true
        } = this.props;
        const {
            name, layout,
            position, background, bg_color, animation, dismiss_outside_touch,
            titleConfig, contentConfig,
            hasImage = true, image,
            hasOkButton = true, hasCloseButton = true,
            okButton, closeButton, full_width, dim_amount
        } = this.state;
        const disabled = !inEditMode && this.props.disabled;
        return(
            <Grid container spacing={16}>
                <Grid item xs>
                    <ExpansionPanel defaultExpanded>
                        <ExpansionPanelDetails style={{display: 'block'}}>
                            <Grid container spacing={16} justify="center">
                                {!disabled &&
                                <Grid item xs={12} md={8}>
                                    <Grid container spacing={16} style={{maxHeight: 640, overflowY: 'auto'}}>
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                type="text"
                                                required
                                                placeholder="Settings Helper"
                                                value={name}
                                                label="Name"
                                                onChange={e => this.setState({name: e.target.value}, this.handleUpdate)}
                                                fullWidth
                                                disabled={disabled}
                                                margin="normal"
                                            />
                                            <FormSelect
                                                options={INAPP_LAYOUTS}
                                                value={layout}
                                                label="Layout"
                                                handleUpdate={
                                                    layout =>  this.setState({layout}, this.handleUpdate)
                                                }
                                                disabled={true /*FIXME: remve this after having multiple layouts*/}
                                            />
                                            <ColorPicker
                                                label="Layout Background Color" disabled={disabled} color={ bg_color }
                                                handleChange={bg_color => this.setState({bg_color}, this.handleUpdate)}
                                                margin="normal" fullWidth
                                            />
                                            <ToggleSwitch
                                                handleChange={
                                                    (full_width) =>  this.setState({full_width}, this.handleUpdate)}
                                                label="Full width"
                                                checked={full_width}
                                                disabled={disabled}
                                            />
                                            <FormSelect
                                                options={Positions}
                                                value={position}
                                                label="Position"
                                                handleUpdate={
                                                    position =>  this.setState({position}, this.handleUpdate)
                                                }
                                                disabled={disabled}
                                            />
                                            <ToggleSwitch
                                                handleChange={
                                                    (background) =>  this.setState({background: (background ? "dimmed" : "normal")}, this.handleUpdate)}
                                                label="Dimmed background"
                                                checked={background === "dimmed"}
                                                disabled={disabled}
                                            />
                                            {
                                                background === "dimmed" &&
                                                <TextField
                                                    style={{maxWidth: 100}}
                                                    type="number"
                                                    required
                                                    placeholder="0.5"
                                                    value={dim_amount}
                                                    label="Dim Amount"
                                                    onChange={e => {
                                                        const dim_amount = Number(e.target.value);
                                                        if(dim_amount >=0 && dim_amount <= 1){
                                                            this.setState({dim_amount}, this.handleUpdate);
                                                        }
                                                    }}
                                                    disabled={disabled}
                                                    margin="dense"
                                                />
                                            }
                                            <FormSelect
                                                options={ANIMATIONS_ENUM}
                                                value={animation}
                                                label="Animation"
                                                margin="none"
                                                handleUpdate={
                                                    animation =>  this.setState({animation}, this.handleUpdate)
                                                }
                                                disabled={disabled}
                                            />
                                            <ToggleSwitch
                                                handleChange={
                                                    (dismiss_outside_touch) =>  this.setState({dismiss_outside_touch}, this.handleUpdate)}
                                                label="Dismiss outside touch"
                                                checked={dismiss_outside_touch}
                                                disabled={disabled}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <TextViewConfig disabled={disabled} label={"Title"} {...titleConfig} handleUpdate={titleConfig => this.setState({titleConfig}, this.handleUpdate)}/>
                                            <TextViewConfig disabled={disabled} label={"Content"} {...contentConfig} handleUpdate={contentConfig => this.setState({contentConfig}, this.handleUpdate)}/>
                                            <ExpansionPanel key="ok-button">
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                    <ToggleSwitch
                                                        handleChange={
                                                            (hasOkButton) => {
                                                                const okButton = {...this.state.okButton};
                                                                this.setState({hasOkButton, okButton: hasOkButton ? {...okButton, visibility: 1} : {...okButton, visibility: -1}}, this.handleUpdate);
                                                            }}
                                                        label="Ok Button"
                                                        checked={hasOkButton}
                                                        disabled={disabled}
                                                    />
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    {
                                                        hasOkButton && <ButtonConfig {...this.props} disabled={disabled} id="okButton" {...okButton} handleUpdate={okButton => this.setState({okButton}, this.handleUpdate)}/>
                                                    }
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                            <ExpansionPanel key="close-button">
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                    <ToggleSwitch
                                                        handleChange={
                                                            (hasCloseButton) => {
                                                                const closeButton = {...this.state.closeButton};
                                                                this.setState({hasCloseButton, closeButton: hasCloseButton ? {...closeButton, visibility: 1} : {...closeButton, visibility: -1}}, this.handleUpdate);
                                                            }}
                                                        label="Close Button"
                                                        checked={hasCloseButton}
                                                        disabled={disabled}
                                                    />
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    {
                                                        hasCloseButton && <ButtonConfig {...this.props} disabled={disabled} id="closeButton" {...closeButton} handleUpdate={closeButton => this.setState({closeButton}, this.handleUpdate)}/>
                                                    }
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                            <ExpansionPanel key="has-image">
                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                    <ToggleSwitch
                                                        handleChange={
                                                            (hasImage) =>  this.setState({hasImage}, this.handleUpdate)}
                                                        label="Have Image"
                                                        checked={hasImage}
                                                        disabled={disabled}
                                                    />
                                                </ExpansionPanelSummary>
                                                <ExpansionPanelDetails>
                                                    {
                                                        hasImage && <ImageConfig {...this.props} disabled={disabled} {...image} handleUpdate={image => this.setState({image}, this.handleUpdate)}/>
                                                    }
                                                </ExpansionPanelDetails>
                                            </ExpansionPanel>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                                <Grid item xs={12} md={4}>
                                    <InAppMobileSimulator
                                        fromPrevious={false}
                                        {...this.state}
                                    />
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                        <Divider />
                        {
                            showControls && <ExpansionPanelActions>
                                {
                                    isNew && <Button size="small" variant="contained" color="primary" onClick={this.handleSave}>Save</Button>
                                }
                                {
                                    inEditMode && <Button size="small" onClick={this.handleEdit}>Update</Button>
                                }
                            </ExpansionPanelActions>
                        }
                    </ExpansionPanel>
                </Grid>
            </Grid>
        )
    }

}

const VALIDITY_DATA = [
    { name: "PER SESSION", value: "SESSION" },
    { name: "OVERALL", value: "OVERALL" }
];

const MESSAGE_STATUS_ENUM = {
    All: "All",
    Active: "Active",
    Inactive: "Inactive",
    Completed: "Completed",
    Testing: "Testing"
};

const checkStatusOfMessage = (message, status) => { //TODO: Testing
    const { Active, Inactive, Completed, Testing } = MESSAGE_STATUS_ENUM;
    const {
        enabled, validity: { end_date },
        testing: { enabled:testingEnabled = false } = {}
    } = message;
    const now = moment();
    switch(status){
        case Testing: return testingEnabled;
        case Inactive: return !enabled;
        case Completed: return end_date && now.diff(end_date) > 0;
        case Active:
        default: return true;
    }
    /*switch (true){
        case end_date && now.diff(end_date) > 0:
            return Completed;
        case start_date && now.diff(start_date) > 0 && end_date && now.diff(end_date) < 0:
        case start_date && now.diff(start_date) > 0:
        case end_date && now.diff(end_date) < 0:
            return Active;
        default:
            return Active; //FIXME: re consider this again
    }*/
};

export class MessagesHolder extends Component{

    NEW_MESSAGE_KEY = "new-message";

    constructor(props){
        super(props);
        this.state = {
            message: {},
            messages: [],
            messageStatus: MESSAGE_STATUS_ENUM.All,
            expanded: null
        };
    }

    componentWillMount(){

    }

    componentDidMount(){
        if(!window.localStorage.hasOwnProperty(IS_GIVEN_UI_ALERT_KEY)){
            setTimeout(() => this.setState({showMessage: true}), 4000);
            window.localStorage.setItem(IS_GIVEN_UI_ALERT_KEY, "true");
        }
    }

    componentWillReceiveProps(nextProps){
        this.handleMessageStatus(this.state.messageStatus, nextProps); //FIXME: Not sure about this updation every time
    }

    isReadyToSave = () => {
        const {
            message,
            message: {
                meta: { name } = {  },
                conditions,
                uis = [],
                ui = { },
                ui: { type } = {  }
            } = { meta: {} },
        } = this.state; //FIXME: Need proper validator
        let hasUI = false;
        const hasConditionsAndTrigger = conditions && conditions.length > 0 && conditions[0].hasOwnProperty("trigger");
        const hasMeta = type !== "" && name !== "";
        if(type === MESSAGE_ENUM.WALKTHROUGH){
            hasUI = uis.length > 0;
        }else{
            hasUI = ui.hasOwnProperty("name");
        }
        return Object.keys(message).length > 11 && hasMeta && hasUI && hasConditionsAndTrigger;
    };

    handleSave = () => {
        const { sendMessage, params: { appId } } = this.props;
        const { message, message: { ui: { type } } } = this.state;
        const config_type = type === MESSAGE_ENUM.WALKTHROUGH ? ART_CONFIG_TYPES.WALKTHROUGH : ART_CONFIG_TYPES.RTA;
        sendMessage(
            appId,
            {
                ...message,
                meta: {
                    ...message.meta,
                    config_type,
                    type: type
                }
            },
            config_type
        );
        this.setState({
            message: {},
            expanded: null
        }); //resetting config after saving..! FIXME: need for success tracking
    };

    handleUpdate = (message) => {
        this.setState({ message });
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            message: {},
            expanded: expanded ? panel : false,
        });
    };

    handleEdit = (messageId, body) => {
        const { updateMessage, params: { appId } } = this.props;
        updateMessage(appId, messageId, body);
    };

    handleMessageStatus = (messageStatus, props) => {
        const { messages: { messages = []} } = props || this.props;
        let currentMessages = messages;
        if(messageStatus !== MESSAGE_STATUS_ENUM.All){
            currentMessages = messages.filter(message => checkStatusOfMessage(message, messageStatus));
        }
        this.setState({messages: currentMessages, messageStatus});
    };

    createNewFromExisting = (message) => {
        this.setState({
            message: {
                ...message,
                _id: undefined,
                meta: {name: message.meta.name + " (Copy)"},
                mutation_id: undefined,
                published: undefined
            },
            expanded: this.NEW_MESSAGE_KEY
        })
    };

    render(){
        const {
            expanded, showMessage = false, messageStatus,
            messages = [], message
        } = this.state;
        return(
            <Grid container>
                <Grid item xs>
                    <Grid container justify="flex-end">
                        <Grid item xs={3}>
                            {
                                expanded !== this.NEW_MESSAGE_KEY &&
                                <Button onClick={e => this.handleChange(this.NEW_MESSAGE_KEY)(e, true)}
                                        variant="contained"
                                        color="primary"
                                        style={{margin: '20px 0 20px auto', display: 'block'}}
                                >Create New Message</Button>
                            }
                        </Grid>
                    </Grid>
                    <ExpansionPanel key={this.NEW_MESSAGE_KEY} expanded={expanded === this.NEW_MESSAGE_KEY} onChange={(e, isExpanded) => {
                        if(expanded !== this.NEW_MESSAGE_KEY){
                            this.handleChange(this.NEW_MESSAGE_KEY)(e, true);
                        }
                    }}>
                        {
                            expanded === this.NEW_MESSAGE_KEY && <ExpansionPanelDetails>
                                { showMessage && <Snackbar autoHideDuration={6000}>You can also configure UI in UI Library</Snackbar>}
                                <ActionCreator {...this.props} action={message} handleActionUpdate={this.handleUpdate} requireRetentionConfig>
                                    <DesignSelector {...this.props} {...message} isNew/>
                                </ActionCreator>
                            </ExpansionPanelDetails>
                        }
                        <Divider />
                        <ExpansionPanelActions>
                            <Button size="small" onClick={(e) => {
                                this.handleChange(this.NEW_MESSAGE_KEY)(e, false);
                            }}>Discard</Button>
                            <Button size="small" variant="contained" color="primary" disabled={!this.isReadyToSave()} onClick={this.handleSave}>Save</Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                    {
                        messages.map(message =>
                            <MessageHolderWithControls
                                key={message._id}
                                {...this.props}
                                {...message}
                                message={message}
                                disabled
                                createNewFromExisting={this.createNewFromExisting}
                                handleChange={this.handleChange(message._id)}
                                expanded={expanded}
                            />
                        )
                    }
                    {
                        !this.props.meta.api_pending && (!Array.isArray(messages) || messages.length === 0) &&
                        <Placeholder
                            withIcon
                            icon={<MessageIcon style={{opacity: 0.2, width: 150, height: 150}} />}
                            text="To create your first message, click on Create New Message button."
                        />
                    }
                </Grid>
            </Grid>
        )

    }

}

const IS_GIVEN_UI_ALERT_KEY = "isGivenUIAlert";

export class MessageHolderWithControls extends Component{

    constructor(props){
        super(props);
        this.state = {
            ...props.message,
            inEditMode: false
        }
    }

    componentWillMount(){

    }

    componentWillReceiveProps(nextProps){
        const { expanded, _id } = nextProps;
        if(expanded !== _id){
            this.setState({inEditMode: false}); //remove from editMode when closed
        }
    }

    handleUpdate = (state) => {
        this.setState(state);
    };

    handleEdit = () => {
        const {  updateMessage, _id,  params: { appId }, message: { published = false }}  = this.props;
        const { inEditMode, meta, ...message } = this.state;
        meta.type = message.ui.type; //config type to meta if changed
        updateMessage(appId, _id, {...message, meta, published }, meta.config_type);
        //this.props.handleChange(null, null); //collapsing after update
    };

    handleChange = (event, expanded) => {
        this.props.handleChange(event, expanded);
    };

    render(){
        const {  inEditMode } = this.state;
        const {
            expanded,
            deleteMessage, params: { appId },
            messages: {
                delete_message_pending , delete_message_failed,
                update_message_pending, message_updated, update_message_failed
            },
             _id, meta: { name, type, config_type } = {}, meta = {}, enabled,
            message, published = false, publishMessage, createNewFromExisting
        } = this.props;
        return(
            <div style={{width: '100%', marginBottom: 8}}>
                { message_updated && <Snackbar> Message updated successfully.</Snackbar> }
                { update_message_failed && <Snackbar> Updating Message Failed. Try Again..!</Snackbar> }
                <ExpansionPanel expanded={expanded === _id} onChange={this.handleChange}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container>
                            <Grid item xs>
                                <Typography style={{marginTop: 15}} variant="subtitle1">{name}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{marginTop: 15}} variant="caption">{type}</Typography>
                            </Grid>
                            <Grid item xs={2} onClick={(e) => e.stopPropagation()}>
                                <ARTPublish
                                    published={published}
                                    publishAction={() => {
                                        publishMessage(appId, _id, config_type);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2} onClick={(e) => e.stopPropagation()}>
                                <ToggleSwitch
                                    handleChange={
                                        enabled =>  this.setState({enabled}, this.handleEdit)}
                                    label={`${enabled ? "Disable" : "Enable"} Message`}
                                    checked={enabled}
                                />
                            </Grid>
                            {
                                expanded === _id && <Grid item xs={1} onClick={(e) => e.stopPropagation()}>
                                    <div>
                                        <Tooltip id={`message-edit-${_id}`} title="Edit this Message" placement="bottom">
                                            <IconButton color="secondary" aria-label="Edit" onClick={() => {
                                                this.setState({inEditMode: !inEditMode});
                                            }}>
                                                { !update_message_pending && <EditIcon color={inEditMode ? "primary" : "inherit"} /> }
                                                { update_message_pending && <Loading size={30} /> }
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </Grid>
                            }
                            <Grid item xs={1} onClick={(e) => e.stopPropagation()}>
                                <DeleteAction
                                    deleteAction={() => {
                                        deleteMessage(appId, _id, config_type);
                                    }}
                                    deleteFailed={delete_message_failed}
                                />
                            </Grid>
                            <Grid item xs={1} onClick={(e) => e.stopPropagation()}>
                                <Tooltip title={"Create from this message"} id={`ui-copy-${_id}`}
                                         placement={"bottom"}>
                                    <IconButton disabled={expanded === "new-message"} color={"primary"} aria-label={"Copy"} onClick={() => {
                                        createNewFromExisting(message);
                                    }}>
                                        <FileCopyIcon/>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    {
                        expanded === _id && <ExpansionPanelDetails>
                            <ActionCreator {...this.props} inEditMode={inEditMode} action={message} handleActionUpdate={this.handleUpdate} requireRetentionConfig>
                                <DesignSelector {...this.props} inEditMode={inEditMode} isNew={false} />
                            </ActionCreator>
                        </ExpansionPanelDetails>
                    }
                    <Divider/>
                    {
                        inEditMode &&
                        <ExpansionPanelActions>
                            <Button size="small" variant="contained" color="primary" onClick={this.handleEdit}>Update</Button>
                        </ExpansionPanelActions>
                    }
                </ExpansionPanel>
            </div>
        )

    }

}

export class UIsHolder extends Component{

    NEW_UI_KEY = "new-ui";

    constructor(props){
        super(props);
        this.state = {
            expanded: null,
            type: MESSAGE_ENUM.IN_APP,
        }
    }

    componentWillMount(){

    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleSave = (body) => {
        const { sendMessageUI, params: { appId } } = this.props;
        sendMessageUI(appId, body);
        this.setState({expanded: null,});
    };

    handleEdit = (uiId, body) => {
        const { updateMessageUI, params: { appId } } = this.props;
        updateMessageUI(appId, uiId, body);
    };

    render(){
        const { expanded, type } = this.state;
        const {
            messages: {
                uis = []
            }
        } = this.props;

        const { IN_APP, IN_LINE } = MESSAGE_ENUM;
        return(
            <Grid container  spacing={16}>
                <Grid item xs>
                    <ExpansionPanel key={this.NEW_UI_KEY} expanded={expanded === this.NEW_UI_KEY} onChange={this.handleChange(this.NEW_UI_KEY)}>
                        <ExpansionPanelSummary expandIcon={
                            expanded !== this.NEW_UI_KEY ? <AddCircleIcon /> : <MinimizeIcon />
                        }>
                            <Grid container>
                                <Grid item xs>
                                    <Typography variant="subtitle1" color="secondary">Create New UI</Typography>
                                </Grid>
                            </Grid>
                        </ExpansionPanelSummary>
                        {
                            expanded === this.NEW_UI_KEY && <ExpansionPanelDetails>
                                <Grid container spacing={24}>
                                    <Grid item xs>
                                        <Switch data={[ IN_APP, IN_LINE ]} value={type} handleChange={type => this.setState({type})}/>
                                        {
                                            type === IN_APP &&
                                            <InAppHTMLUISelection {...this.props} isNew config={{}} handleEdit={this.handleEdit} handleSave={this.handleSave}/>
                                        }
                                        {
                                            type === IN_LINE &&
                                            <InLineUISelection {...this.props} isNew config={{}} handleEdit={this.handleEdit} handleSave={this.handleSave}/>
                                        }
                                    </Grid>
                                </Grid>
                            </ExpansionPanelDetails>
                        }
                    </ExpansionPanel>
                    {
                        uis.map(ui =>
                            <UIHolder
                                key={ui._id} {...this.props} {...ui}
                                handleChange={this.handleChange(ui._id)} expanded={expanded}
                                handleEdit={this.handleEdit} handleSave={this.handleSave}
                            />
                        )
                    }
                    {
                        !this.props.meta.api_pending && (!Array.isArray(uis) || uis.length === 0) &&
                        <Placeholder
                            withIcon
                            icon={<MessageIcon style={{opacity: 0.2, width: 150, height: 150}} />}
                            text="To create your first UI, click on Create New UI bar above."
                        />
                    }
                </Grid>
            </Grid>
        )

    }

}

export class UIHolder extends Component{

    NEW_UI_KEY = "new-ui";

    constructor(props){
        super(props);
        this.state = {
            expanded: props.expanded,
            type: props.type,
            inEditMode: false
        }
    }

    handleChange = (event, expanded) => {
        this.props.handleChange(event, expanded);
    };

    componentWillReceiveProps(nextProps){
        const { expanded, _id } = nextProps;
        if(expanded !== _id){
            this.setState({inEditMode: false}); //remove from editMode when closed
        }
    }

    render(){
        const { expanded, type, config, _id, config: { layout, extra_config } } = this.props;
        const {
            deleteMessageUI, params: { appId },
            messages: {
                delete_ui_pending, delete_ui_failed,
                update_ui_pending, update_ui_failed
            },
        } = this.props;
        const { inEditMode }  = this.state;
        const isOldInApp = type === MESSAGE_ENUM.IN_APP && layout === INAPP_LAYOUTS[0];
        const isOldInLine = type === MESSAGE_ENUM.IN_LINE && !extra_config;
        return(
            <ExpansionPanel expanded={expanded === _id} onChange={this.handleChange}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md>
                            <Typography style={{lineHeight: '3em'}} variant="subtitle1">{config.name}</Typography>
                        </Grid>
                        {
                            (isOldInApp || isOldInLine) &&
                            <Grid item xs={12} md={2}>
                                <Tooltip id={`ui-edit-depricated-${_id}`} title="Try creating new UI" placement="left">
                                    <Typography style={{lineHeight: '4em'}} variant="caption">DEPRICATED</Typography>
                                </Tooltip>
                            </Grid>
                        }
                        <Grid item xs={12} md={2}>
                            <Typography style={{lineHeight: '4em'}} variant="caption">{type}</Typography>
                        </Grid>
                        {
                            !isOldInApp && expanded === _id && <Grid item xs={6} md={1} onClick={(e) => e.stopPropagation()}>
                                <div>
                                    { update_ui_failed && <Snackbar> Updating UI Failed. Try Again..!</Snackbar> }
                                    <Tooltip id={`ui-edit-${_id}`} title="Edit this UI" placement="bottom">
                                        <IconButton color="secondary" aria-label="Edit" onClick={() => {
                                            this.setState({inEditMode: !inEditMode});
                                        }}>
                                            { !update_ui_pending && <EditIcon /> }
                                            { update_ui_pending && <Loading size={30} /> }
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            </Grid>
                        }
                        <Grid item xs={6} md={1} onClick={(e) => e.stopPropagation()}>
                            <div>
                                { delete_ui_failed && <Snackbar> Deleting UI Failed. Try Again..!</Snackbar> }
                                <Tooltip id={`ui-delete-${_id}`} title="Delete this UI" placement="bottom">
                                    <IconButton color="secondary" aria-label="Delete" onClick={() => {
                                        deleteMessageUI(appId, _id);
                                    }}>
                                        { !delete_ui_pending && <DeleteIcon /> }
                                        { delete_ui_pending && <Loading size={30} /> }
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
                {
                    expanded === _id && <ExpansionPanelDetails>
                        {
                            isOldInApp && /*Supporting old InApps*/
                            <InAppUISelection {...this.props} config={config} uiId={_id} disabled inEditMode={inEditMode}/>
                        }
                        {
                            type === MESSAGE_ENUM.IN_APP && !isOldInApp &&
                            <InAppHTMLUISelection {...this.props} config={config} uiId={_id} disabled inEditMode={inEditMode}/>
                        }
                        {
                            type === MESSAGE_ENUM.IN_LINE &&
                            <InLineUISelection {...this.props} config={config} uiId={_id} disabled inEditMode={inEditMode}/>
                        }
                    </ExpansionPanelDetails>
                }
            </ExpansionPanel>
        )

    }

}

export class AbyBTesting extends Component {

    handleFirstMessageChange = () => {
        //TODO:
    };

    handleSecondMessageChange = () => {
        //TODO:
    };

    handleDesiredEventChange = () => {
        //TODO:
    };

    handleDesiredEventSwitchChange = () => {
        //TODO:
    };

    render() {
        const { messages: { messages = [] } } = this.props;
        return(
            <Grid container spacing={24}>
                <Grid item xs style={{margin: '20px 0'}}>
                    <Typography>Select the Messages to measure</Typography>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6} md={4}>
                            <MultiSelect
                                options={messages.map(o => ({label: o.meta.name, value: o._id}))}
                                handleChange={this.handleFirstMessageChange}
                                value={null}
                                placeholder="Select Message"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Tooltip id="tooltip-fab-add" title="Add Message to compare" placement="bottom">
                                <Fab size="small" color="primary" aria-label="Add Message to compare">
                                    <AddIcon />
                                </Fab>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={6} md={4}>
                            <MultiSelect
                                options={messages.map(o => ({label: o.meta.name, value: o._id}))}
                                handleChange={this.handleFirstMessageChange}
                                value={null}
                                placeholder="Select Message"
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                            <Tooltip id="tooltip-fab-remove" title="Remove Message" placement="bottom">
                                <IconButton mini color="secondary" aria-label="Remove Message">
                                    <RemoveIcon />
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Grid container spacing={24}>
                        <Grid item xs={12} sm={4} md={3}>
                            <Checkbox label="Select desired event done" value={""} handleChange={this.handleDesiredEventSwitchChange}/>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            <MultiSelect
                                options={messages.map(o => ({label: o.meta.name, value: o._id}))}
                                handleChange={this.handleFirstMessageChange}
                                value={null}
                                placeholder="Select Message"
                            />
                        </Grid>
                    </Grid>
                    <Button variant="contained" color="primary">Measure</Button>
                    <TestingMeasure {...this.props} />
                </Grid>
            </Grid>
        )
    }
}

export class TestingMeasure extends Component {

    render() {
        return(
            <Grid container spacing={24}>
                <Grid item xs style={{margin: '20px 0'}}>
                    <Paper>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Message</TableCell>
                                    <TableCell numeric>Users in Segment</TableCell>
                                    <TableCell numeric>Roll out %</TableCell>
                                    <TableCell numeric>Final No. of Users</TableCell>
                                    <TableCell numeric>Behavior satisfied</TableCell>
                                    <TableCell numeric>Message shown to</TableCell>
                                    <TableCell numeric>Message not shown</TableCell>
                                    <TableCell numeric>Desired event done</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[].map(n => {
                                    return (
                                        <TableRow key={n.id}>
                                            <TableCell>{n.name}</TableCell>
                                            <TableCell numeric>{n.calories}</TableCell>
                                            <TableCell numeric>{n.fat}</TableCell>
                                            <TableCell numeric>{n.carbs}</TableCell>
                                            <TableCell numeric>{n.protein}</TableCell>
                                            <TableCell numeric>{n.protein}</TableCell>
                                            <TableCell numeric>{n.protein}</TableCell>
                                            <TableCell numeric>{n.protein}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
            </Grid>
        )
    }
}

export class MessagesWrapper extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: 0,
        };
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { value } = this.state;
        return (
            <div>
                {/*<InLineUISelection {...this.props} isNew/>*/}
                <AppBar position="static">
                    <Tabs value={value} onChange={this.handleChange}>
                        <Tab label="Messages" href="#messages" />
                        <Tab label="UI Library" href="#ui-library" />
                    </Tabs>
                </AppBar>
                {value === 0 &&
                    <MessagesHolder {...this.props}/>
                }
                {value === 1 &&
                    <UIsHolder {...this.props}/>
                }
            </div>
        );
    }
}