import React, { Component } from 'react';
import { Link } from 'react-router';
import {Grid, Chip, Typography } from "@material-ui/core";
import Box from "../../../../../../components/reusable/Box";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';

const profileWithShares = ({name, id, intents}, appId) => (
    <Grid container key={name} spacing={24}>
        <Grid item xs={12} md={3}>
            <Typography variant="subtitle1"><Link target="_blank" to={`/apps/${appId}/user/${id}`}>{ name }</Link></Typography>
        </Grid>
        <Grid item xs md>
            <div style={{display: 'flex'}}>
                { intents.map(intent => <Chip style={{marginLeft: 5}} key={intent} tabIndex={-1} label={intent} title={intent}/>) }
            </div>
        </Grid>
    </Grid>
);


export default class RemoveThisLater extends Component {

    render(){
        const { top_sharing_profiles = [], top_trending_intents = [], top_underserved_intents = [], appId } = this.props;
        return(
            <Grid container>
                <Grid item md={2}/>
                <Grid item xs={12} md>
                    <Box
                        title="Top Sharing Profiles"
                        headerWithBorder
                    >
                        <div style={{padding: 15, width: '100%'}}>
                            {top_sharing_profiles.map(profile => profileWithShares(profile, appId))}
                        </div>
                    </Box>
                    <Box
                        title="Top Trending Intents"
                        headerWithBorder
                    >
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <List>
                                    {
                                        top_trending_intents.filter(o => o.raising).map(
                                            intent => <ListItem>
                                                <ListItemText primary={
                                                    <span>{intent.name} <span style={{float: 'right'}}><strong>{intent.percent} </strong> % <ArrowUpward style={{height: 16}} color="primary"/></span></span>
                                                }/>
                                            </ListItem>
                                        )
                                    }
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <List>
                                    {
                                        top_trending_intents.filter(o => !o.raising).map(
                                            intent => <ListItem>
                                                <ListItemText primary={
                                                    <span>{intent.name} <span style={{float: 'right'}}><strong>{intent.percent}</strong> % <ArrowDownward style={{height: 16}} color="secondary"/></span></span>
                                                }/>
                                            </ListItem>
                                        )
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box
                        title="Top Underserved Profiles"
                        headerWithBorder
                    >
                        <Grid container>
                            <Grid item xs>
                                <List>
                                    {
                                        top_underserved_intents.map(
                                            intent => <ListItem>
                                                <ListItemText primary={
                                                    <span>{intent.name} <span style={{float: 'right'}}><strong>{intent.results} </strong>&nbsp;results</span></span>
                                                }/>
                                            </ListItem>
                                        )
                                    }
                                </List>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
                <Grid item md={2}/>
            </Grid>
        )
    }

}