/**
 * Created by Araja Jyothi Babu on 22-Oct-16.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as ActionCreators  from './actions';
import {resetAppData} from '../Apps/App/actions';
import AppsComponent from './components';
import logger from '../../../../../utils/Logger';
import { enterDashboard,
    getMetaDataOfApp
} from '../Apps/App/actions';
import Snackbar from '../../../../../components/reusable/MaterialUi/Snackbar';
import Grid from '@material-ui/core/Grid';
import Loading from "../../../../../components/reusable/Loading";
import {DASHBOARD_ENDPOINTS} from "../../../../../constants/EndPoints";
import {getPreviousDashboard} from "../../../../../utils";
import {setDefaultFilters} from "../Filters/actions";

function mapStateToProps(state) {
    return {
        auth: state.auth,
        meta: state.meta,
        router: state.routing,
        appsState: state.apps,
        appState: state.app,
        dashboards:state.dashboards
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getApps: () => dispatch(ActionCreators.getApps()),
        addApp: (app) => dispatch(ActionCreators.addApp(app)),
        deleteApp: (appId) => dispatch(ActionCreators.deleteApp(appId)),
        enableMonitoring: (appId) => dispatch(ActionCreators.enableMonitoring(appId)),
        disableMonitoring: (appId) => dispatch(ActionCreators.disableMonitoring(appId)),
        setDefaultFilters: () => dispatch(setDefaultFilters()),
        resetAppData: () =>  dispatch(resetAppData()),
        hydrateApp: (appId) => {
            dispatch(enterDashboard(appId));
            dispatch(getMetaDataOfApp(appId))
        },
        boundDispatch: dispatch
    };
}

class Apps extends Component {

    constructor(props){
        super(props);
        this.state = {
            currentApp: null
        }
    }

    componentWillMount(){
        this.props.resetAppData();//resets existing app data and DashboardData
        this.props.getApps();
    }

    handleNavigation = (app) => {
        const {router} = this.context;
        if ((app.app_versions && Object.keys(app.app_versions).length === 0) || !app.app_versions) {
            router.push("/apps/" + app.app_id + "/integration");
            //router.push("/apps/"+ app.app_id + "/overview");
        } else {
            this.props.hydrateApp(app.app_id); //hydrating app meta data
            this.setState({currentApp: app});
            // if (process.env.NODE_ENV === "production" && app.domain !== window.location.href.split('/')[2]) {
            //     window.location.assign(`https://${app.domain || "asia.art.apxor.com"}/apps/${app.app_id}/event-analysis`);
            // } else {
                router.push(`/apps/${app.app_id}/event-analysis`);
            // }
        }
    };

    redirectIfPreviousDashboardExists = (dashboards = [], appId) => {
        const previousDashboardId = getPreviousDashboard(dashboards, appId);
        const {router} = this.context;
        this.props.setDefaultFilters(); //for setting groupIds FIXME: remove later
        if(previousDashboardId){
           router.push(`/apps/${appId}/dashboards/${previousDashboardId}`);
        } else{
            router.push(`/apps/${appId}/${DASHBOARD_ENDPOINTS.DASHBOARDS}`); //entering dashboard after having meta data
            //TODO: need to handle fail conditions
        }
    };

    componentWillReceiveProps(nextProps) {
        const { currentApp } = this.state;
        if(currentApp){
            if( !nextProps.meta.api_pending){ //if no APIs pending
                this.props.setDefaultFilters();
            }
        }
    }

    render() {
        const { appsState, appState, addApp, deleteApp} = this.props;
        const {appsLoading, apps} = appsState;
        const {addingFailed, addingSuccess, addingApp} = appState;
        return (
            <section style={{width: '100%', marginTop: 50}} className="content">
                <Grid container justify="center">
                    <Grid item xs={12} sm={10} md={8}>
                        <AppsComponent
                            {...this.props}
                            size={apps.length}
                            apps={apps}
                            addApp={addApp}
                            isDeleteEnabled={false}
                            deleteApp={deleteApp}
                            handleNavigation={this.handleNavigation}
                            currentApp={this.state.currentApp}
                        />
                    </Grid>
                </Grid>
            </section>
        );
    }
}

Apps.propTypes = {
    addApp: PropTypes.func.isRequired,
    deleteApp: PropTypes.func.isRequired,
    enableMonitoring: PropTypes.func.isRequired,
    disableMonitoring: PropTypes.func.isRequired
};

Apps.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Apps);
