/**
 * Created by jyothi on 14/1/17.
 */
import React, { Component, Fragment } from 'react';
import Switch from '../Switch';
import {QueryBuilder, EventAttributeQuery } from "../QueryBuilder/index";
import {CLIENT_EVENTS, ANIMATIONS_ENUM, LOGICAL_OPERATORS, MESSAGE_ENUM, APP_PLATFORMS} from "../../../constants/index";
import {Grid, IconButton, TextField, Tooltip} from "@material-ui/core";
import FormSelect from "../MaterialUi/FormSelect";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MultiSelect from "../MaterialUi/MultiSelect";
import ToggleSwitch from "../MaterialUi/ToggleSwitch";
import RemoveIcon from '@material-ui/icons/HighlightOff';
import {updateQuery} from "../QueryBuilder/actions";
import {MESSAGE_EVENTS} from "../../../constants";
import AddIcon from '@material-ui/icons/Add';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import Paper from "@material-ui/core/Paper";
import NumberField from "../MaterialUi/NumberField";

const STEP_ENUM = {
    ACTIVITY: "ACTIVITY",
    EVENT: "APP EVENT",
    MESSAGE_EVENT: "MESSAGE EVENT",
    CLIENT_EVENT: "CLIENT EVENT",
    ACTIVITY_TIME: "ACTIVITY TIME",
    IDLE_TIME: "IDLE TIME"
};

const OPERATORS = [
    {name: ">", value: LOGICAL_OPERATORS.GT},
    {name: "≥", value: LOGICAL_OPERATORS.GTE},
    {name: "<", value: LOGICAL_OPERATORS.LT},
    {name: "≤", value: LOGICAL_OPERATORS.LTE},
    {name: "=", value: LOGICAL_OPERATORS.EQ},
    {name: "!=", value: LOGICAL_OPERATORS.NEQ}
];

const AUDIENCE_ENUM = {
    FTU: "FTU",
    ALL: "ALL"
};

const AUDIENCE_OPTIONS = [
    { key: AUDIENCE_ENUM.FTU, label: "First Time Users" },
    { key: AUDIENCE_ENUM.ALL, label: "All Users" }
];

const TRIGGER_ENUM = {
    "App Event": "app_event",
    //"System Event": "system_event",
    //"User Event": "user_event",
    "Activity Event": "activity_event",
    "App Start": "app_start",
    //"Observation": "observation"
};

const EVENTS_ENUM = {
    "app_event": "app_event",
    "client_event": "client_event",
    "system_event": "system_event",
    "user_event": "user_event",
    "activity_event": "activity_event",
    "app_start": "app_start",
    "observation": "observation",
    [STEP_ENUM.ACTIVITY]: 'activity_event',
    [STEP_ENUM.EVENT]: 'app_event',
    [STEP_ENUM.MESSAGE_EVENT]: 'app_event',
    [STEP_ENUM.CLIENT_EVENT]: 'client_event',
    [STEP_ENUM.ACTIVITY_TIME]: 'activity_time',
    [STEP_ENUM.IDLE_TIME]: 'idle_time'
};

const TRIGGER_OPTIONS = Object.keys(TRIGGER_ENUM).map(o => ({key: TRIGGER_ENUM[o], label: o}));

const ORDER_ENUM = {
    LOOSE: "LOOSE ORDER",
    ANY: "ANY ORDER",
    ANYONE: "ANY ONE STEP"
};

const USER_DONE_ENUM = {
    DOES: "does",
    COMES_TO: "comes to"
};

const CONDITIONS_ENUM = {
    AND: "AND",
    OR: "OR"
};

const ORDERING_MESSAGES = {
    [ORDER_ENUM.LOOSE]: "Rule is satisfied if all the steps are true but must be executed in the sequence they are configured, any other event occurring in between the steps doesn’t impact the rule configuration.",
    [ORDER_ENUM.ANY]: "Rule is satisfied if all the steps are true in any sequence they are executed, any other event occurring in between the steps doesn’t impact the rule configuration.",
    [ORDER_ENUM.ANYONE]: "Rule is satisfied if at-least one of the steps configured is true."
};

const _CONDITION_INITIAL_STATE = {
    "sequence": -1,
    "time_bounds": {
        "lower": 0,
        "upper": 6000000
    },
    "count_config": {
        "count": 2,
        "operator": "EQ"
    },
    "activity": "",
    "event_type": "activity_event",
    "details": {
        "name": "",
        "additional_info": {}
    },
    combine_operator: CONDITIONS_ENUM.OR,
    "rule_type": ""
};

let messageEventMessages = (selected, messages) => {
    if(selected === MESSAGE_EVENTS.INLINE_SHOWN || selected === MESSAGE_EVENTS.INLINE_DISMISSED){
        return messages.filter(o => o.meta.type === MESSAGE_ENUM.IN_LINE);
    }else{
        return messages.filter(o => o.meta.type === MESSAGE_ENUM.IN_APP);
    }
};

const getRuleType = (condition) => {
    const {
        event_type,
        details: { name, additional_info }, details,
        rule_type
    } = condition;
    if(rule_type) {
        return rule_type;
    }else{
        switch (event_type) {
            case EVENTS_ENUM.app_event:
                if(Object.values(MESSAGE_EVENTS).includes(name)){
                    return STEP_ENUM.MESSAGE_EVENT;
                }
                return STEP_ENUM.EVENT;
            case EVENTS_ENUM[STEP_ENUM.IDLE_TIME]:
            case EVENTS_ENUM[STEP_ENUM.ACTIVITY_TIME]:
            case EVENTS_ENUM.activity_event:
                return STEP_ENUM.ACTIVITY;
            default: return STEP_ENUM.ACTIVITY;
        }
    }
};

const CLIENT_EVENTS_AIRTEL = {
    "apx_hard_back_button_pressed": {},
    "HOME": {
        "isWalletPresent": "boolean"
    },
    "RECHARGE_REMINDER_DETAILS": {
        "lob": "string",
        "siNumber": "string",
        "rechargeExpiryDate": "string"
    }
};

class StepConfig extends Component {

    constructor(props){
        super(props);
        const { event_type } = props.condition;
        const enableActivityTime = event_type === EVENTS_ENUM[STEP_ENUM.ACTIVITY_TIME] ||
            event_type === EVENTS_ENUM[STEP_ENUM.IDLE_TIME];
        this.state = {
            ...props.condition,
            enableActivityTime,
            disableCountCondition: false
        };
    }

    componentWillMount(){
        //
    }

    handleUpdate = () => {
        const { enableActivityTime, disableCountCondition, ...others } = this.state;
        this.props.handleUpdate(others);
    };

    componentWillReceiveProps(nextProps){
        const { enableActivityTime, event_type } = nextProps.condition;
        if(enableActivityTime !== this.state.enableActivityTime) { //on deleteing next step
            //this.setState({combine_operator});
            this.setState({
                enableActivityTime: event_type === EVENTS_ENUM[STEP_ENUM.ACTIVITY_TIME] ||
                    event_type === EVENTS_ENUM[STEP_ENUM.IDLE_TIME]
            });
        }
    }

    isModable = (app_id) => {
        return ["2e301190ed3376549cd12658f9c22844", "e6f0c11cc8e3c7f80f5266528ec9917b"].includes(app_id)
    };

    getOptionsForClientEvents = () => {
        const {app: {app: {app_id}}} = this.props;
        if (!this.isModable(app_id)) {
            return Object.values(CLIENT_EVENTS);
        } else {
            return [...Object.keys(CLIENT_EVENTS_AIRTEL)]
        }
    };

    getAttributesForClientEvents = (event_name) => {
        if (Object.keys(CLIENT_EVENTS_AIRTEL).includes(event_name))
            return Object.keys(CLIENT_EVENTS_AIRTEL[event_name])
    };

    getCEType = (event, attribute) => {
        return CLIENT_EVENTS_AIRTEL[event][attribute]
    };

    render(){
        const {
            time_bounds, time_bounds: { lower, upper },
            count_config, count_config: { operator, count },
            activity, event_type,
            details: { name, additional_info }, details,
            enableActivityTime, disableCountCondition
        } = this.state;
        const {
            defaultExpanded = true, index, order,
            appState: { appScreenNames = [], app: {basic_info: {platform}}}, inEditMode,
            messages: { messages = [] }, isLast,
            condition: { combine_operator },
            theme, app: {app: {app_id}}
        } = this.props;
        const ruleType = getRuleType(this.state);
        const disabled = !inEditMode && this.props.disabled;
        const showCountCondition = (ruleType === STEP_ENUM.ACTIVITY && activity && activity.length > 0) || (name && name.length > 0);
        return(
            <Fragment>
                <Paper style={{background: theme.palette.background.default, padding: 16, margin: '16px 0'}} elevation={0}>
                    <Typography variant="h5"
                                style={{marginBottom: 32}}>{ruleType === STEP_ENUM.ACTIVITY && platform === APP_PLATFORMS.ios ? "VIEW CONTROLLER" : ruleType}</Typography>
                    {
                        index > 1 && order !== ORDER_ENUM.ANY && <Fragment>
                            <Typography style={{lineHeight: '4.5em', display: 'flex'}} component="span" variant="subtitle1">
                                Time after &nbsp;
                                <NumberField
                                    style={{maxWidth: 100}}
                                    type="number"
                                    required
                                    placeholder="1000"
                                    value={lower}
                                    label="Time"
                                    handleChange={
                                        lower => this.setState({
                                                time_bounds: {
                                                    ...time_bounds,
                                                    lower
                                                }},
                                            this.handleUpdate)
                                    }
                                    margin="dense"
                                    metricType="ms"
                                    disabled={disabled}
                                /> &nbsp; and within &nbsp;&nbsp;
                                <NumberField
                                    style={{maxWidth: 100}}
                                    type="number"
                                    required
                                    placeholder="1000"
                                    value={upper}
                                    label="Time"
                                    handleChange={
                                        upper => this.setState({
                                                time_bounds: {
                                                    ...time_bounds,
                                                    upper
                                                }},
                                            this.handleUpdate)
                                    }
                                    metricType="ms"
                                    margin="dense"
                                    disabled={disabled}
                                />
                            </Typography>
                        </Fragment>
                    }
                    <Paper elevation={0} style={{padding: 32}}>
                        <Grid container>
                            <Grid item xs>
                                <Grid container spacing={16}>
                                    {
                                        ruleType === STEP_ENUM.ACTIVITY &&
                                        <Grid item xs={12} md={6}>
                                            <MultiSelect
                                                options={appScreenNames.map(o => ({label: o, value: o}))}
                                                value={activity}
                                                label={platform === APP_PLATFORMS.ios ? "Select View Controller" : "Select Activity"}
                                                placeholder={platform === APP_PLATFORMS.ios ? "Select View Controller" : "Select Activity"}
                                                handleChange={activity => this.setState({activity, details: {additional_info: {}, name: activity}}, this.handleUpdate)}
                                                disabled={disabled}
                                                single
                                            />
                                        </Grid>
                                    }
                                    {
                                        ruleType === STEP_ENUM.EVENT &&
                                        <Grid item xs={12}>
                                            <EventAttributeQuery
                                                {...this.props}
                                                {...details}
                                                disabled={disabled}
                                                handleUpdate={details => this.setState({details}, this.handleUpdate)}
                                            />
                                        </Grid>
                                    }
                                    {
                                        ruleType === STEP_ENUM.CLIENT_EVENT &&
                                        <Grid item xs={12} md={3}>
                                            <FormSelect
                                                options={this.getOptionsForClientEvents()}
                                                value={name}
                                                label="Select Client Event"
                                                handleUpdate={
                                                    (name) => this.setState({activity: "", details: {additional_info: {}, name}}, this.handleUpdate)
                                                }
                                                disabled={disabled}
                                            />
                                        </Grid>
                                    }
                                    {
                                        this.isModable(app_id) && ruleType === STEP_ENUM.CLIENT_EVENT && name && name !== CLIENT_EVENTS.BACK_BUTTON_PRESSED &&
                                        <Grid item xs={12} md={3}>
                                            <FormSelect
                                                options={this.getAttributesForClientEvents(name)}
                                                value={Object.keys(additional_info).length > 0 ? Object.keys(additional_info)[0] : undefined}
                                                label="Select Attribute"
                                                handleUpdate={
                                                    (attribute_name) => {
                                                        this.setState({
                                                            activity: "",
                                                            details: {
                                                                additional_info: {
                                                                    [attribute_name]: {
                                                                        "op": LOGICAL_OPERATORS.EQ,
                                                                        "t": "s",
                                                                        "val": ""
                                                                    }
                                                                },
                                                                name: name
                                                            }
                                                        }, this.handleUpdate)
                                                    }
                                                }
                                                disabled={disabled}
                                            />
                                        </Grid>
                                    }
                                    {
                                        this.isModable(app_id) && ruleType === STEP_ENUM.CLIENT_EVENT && name && Object.keys(additional_info).length > 0 && name !== CLIENT_EVENTS.BACK_BUTTON_PRESSED &&
                                        <Grid item xs={12} md={2}>
                                            <FormSelect
                                                options={[...OPERATORS, {
                                                    name: "ℝegex",
                                                    value: "R"
                                                }].map(o => ({key: o.value, label: o.name})).filter(x => {
                                                    return (this.getCEType(name, Object.keys(additional_info)[0]) === "boolean") ? x.key === LOGICAL_OPERATORS.EQ : true
                                                })}
                                                value={Object.keys(additional_info).length > 0 ? additional_info[Object.keys(additional_info)[0]]["op"] : undefined}
                                                label="Condition"
                                                handleUpdate={
                                                    (operator) => {
                                                        const info = Object.keys(additional_info)[0];
                                                        this.setState({
                                                            activity: "",
                                                            details: {
                                                                additional_info: {
                                                                    [info]: {
                                                                        "op": operator,
                                                                        "t": "s",
                                                                        "val": ""
                                                                    }
                                                                },
                                                                name: name
                                                            }
                                                        }, this.handleUpdate)
                                                    }
                                                }
                                                disabled={disabled}
                                                margin="dense"
                                            />
                                        </Grid>
                                    }
                                    {
                                        this.isModable(app_id) && ruleType === STEP_ENUM.CLIENT_EVENT && name && Object.keys(additional_info).length > 0 && name !== CLIENT_EVENTS.BACK_BUTTON_PRESSED &&
                                        <Grid item xs={12} md={4}>
                                            {this.getCEType(name, Object.keys(additional_info)[0]) === "boolean" ? (
                                                <FormSelect
                                                    options={[{label: "true", key: "true"}, {label: "false", key: "false"}]}
                                                    value={Object.keys(additional_info).length > 0 ? String(additional_info[Object.keys(additional_info)[0]]["val"]) : undefined}
                                                    label="Value"
                                                    handleUpdate={
                                                        (bool) => {
                                                            const info = Object.keys(additional_info)[0];
                                                            console.log(bool);
                                                            this.setState({
                                                                activity: "",
                                                                details: {
                                                                    additional_info: {
                                                                        [info]: {
                                                                            "op": additional_info[info]["op"],
                                                                            "t": "s",
                                                                            "val": bool !== "false"
                                                                        }
                                                                    },
                                                                    name: name
                                                                }
                                                            }, this.handleUpdate)
                                                        }
                                                    }
                                                    disabled={disabled}
                                                    margin="dense"
                                                />) : (<TextField
                                                required
                                                label="Value"
                                                value={Object.keys(additional_info).length > 0 ? additional_info[Object.keys(additional_info)[0]]["val"] : undefined}
                                                disabled={disabled}
                                                onChange={e => {
                                                    const info = Object.keys(additional_info)[0];
                                                    this.setState({
                                                        activity: "",
                                                        details: {
                                                            additional_info: {
                                                                [info]: {
                                                                    "op": additional_info[info]["op"],
                                                                    "t": "s",
                                                                    "val": e.target.value
                                                                }
                                                            },
                                                            name: name
                                                        }
                                                    }, this.handleUpdate)
                                                }
                                                }
                                                margin="dense"
                                            />)}
                                        </Grid>
                                    }
                                    {
                                        ruleType === STEP_ENUM.MESSAGE_EVENT &&
                                        <Grid item xs={12} md={3}>
                                            <FormSelect
                                                options={Object.values(MESSAGE_EVENTS)}
                                                value={name}
                                                label="Select Event"
                                                handleUpdate={
                                                    (name) => this.setState({activity: "", details: {additional_info: {}, name}}, this.handleUpdate)
                                                }
                                                disabled={disabled}
                                            />
                                        </Grid>
                                    }
                                    {
                                        ruleType === STEP_ENUM.MESSAGE_EVENT && name && name.length > 0 &&
                                        <Grid item xs={12} md={4}>
                                            <FormSelect
                                                options={messageEventMessages(name, messages).map(o => ({id: o._id, name: o.meta.name}))}
                                                value={additional_info.id}
                                                idKey="id" nameKey="name"
                                                label="Select Message"
                                                handleUpdate={
                                                    (id) => this.setState({details: {
                                                            ...details, additional_info: { id }
                                                        }}, this.handleUpdate)
                                                }
                                                disabled={disabled}
                                            />
                                        </Grid>
                                    }
                                    { showCountCondition &&
                                    <Grid item xs={12} md={ruleType === STEP_ENUM.ACTIVITY ? 4 : 12}>
                                        <Grid container spacing={16}>
                                            {
                                                ruleType !== STEP_ENUM.ACTIVITY && <Grid item xs={3} />
                                            }
                                            <Grid item xs={3} md={2}>
                                                <FormSelect
                                                    options={OPERATORS.filter(o => o.value !== 'NEQ')}
                                                    value={operator}
                                                    idKey="value"
                                                    nameKey="name"
                                                    label="Condition"
                                                    handleUpdate={
                                                        (operator) => this.setState({count_config: {...count_config, operator}}, this.handleUpdate)
                                                    }
                                                    disabled={disabled || disableCountCondition}
                                                    margin="dense"
                                                />
                                            </Grid>
                                            <Grid item xs>
                                                <TextField
                                                    style={{maxWidth: 50}}
                                                    type="number"
                                                    required
                                                    label="Count"
                                                    placeholder="2"
                                                    value={count}
                                                    onChange={e =>
                                                        this.setState({
                                                                count_config: {
                                                                    ...count_config, count: Number(e.target.value)
                                                                }},
                                                            this.handleUpdate
                                                        )
                                                    }
                                                    disabled={disabled || disableCountCondition}
                                                    margin="dense"
                                                />
                                                <Typography style={{display: 'inline-block', lineHeight: '5em'}}> &nbsp; time (s)</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    }
                                </Grid>
                                {
                                    (!disabled || enableActivityTime) && activity && activity.length > 0 && ruleType === STEP_ENUM.ACTIVITY &&
                                    <Fragment>
                                        <ToggleSwitch
                                            label="Time Spent"
                                            disabled={disabled}
                                            handleChange={checked => {
                                                if (!checked) {
                                                    this.setState({event_type: EVENTS_ENUM[STEP_ENUM.ACTIVITY]}, this.handleUpdate);
                                                } else {
                                                    this.setState({
                                                        count_config: {
                                                            ...count_config,
                                                            count: 1,
                                                            operator: LOGICAL_OPERATORS.GTE
                                                        },
                                                        event_type: EVENTS_ENUM[STEP_ENUM.IDLE_TIME]
                                                    }, this.handleUpdate);
                                                }
                                                this.setState({enableActivityTime: checked, disableCountCondition: checked});
                                            }}
                                            checked={enableActivityTime}
                                        />
                                        {
                                            enableActivityTime &&
                                            <Grid container spacing={16}>
                                                <Grid item xs={12} md={4}>
                                                    <Switch
                                                        disabled={disabled}
                                                        data={[STEP_ENUM.ACTIVITY_TIME, STEP_ENUM.IDLE_TIME]}
                                                        handleChange={current => {
                                                            this.setState({event_type: EVENTS_ENUM[current]}, this.handleUpdate);
                                                        }}
                                                        value={event_type === EVENTS_ENUM[STEP_ENUM.ACTIVITY_TIME] ?
                                                            STEP_ENUM.ACTIVITY_TIME : STEP_ENUM.IDLE_TIME}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                        <NumberField
                                                        required
                                                        placeholder="1000"
                                                        value={additional_info.time || 0}
                                                        label="At least"
                                                        handleChange={time =>
                                                            this.setState({
                                                                    details: {
                                                                        name: "",
                                                                        additional_info: { time }
                                                                    }},
                                                                this.handleUpdate
                                                            )}
                                                        margin="dense"
                                                        disabled={disabled}
                                                        metricType="ms"
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    </Fragment>
                                }
                            </Grid>
                        </Grid>
                    </Paper>
                </Paper>
                {
                    !isLast && <div style={{textAlign: 'center'}}>
                        <Grid container>
                            <Grid item xs style={{borderBottom: `dashed 2px ${theme.palette.background.default}`, height: 14}}/>
                            <Grid item xs={2} md={1}>
                                <Typography variant="subtitle1" style={{background: theme.palette.background.default}}>{combine_operator}</Typography>
                            </Grid>
                            <Grid item xs style={{borderBottom: `dashed 2px ${theme.palette.background.default}`, height: 14}}/>
                        </Grid>
                    </div>
                }
            </Fragment>
        );
    }

}

export class ConditionSelector extends Component{

    _TRIGGER_INITIAL_STATE = {
        "event_type": "app_start",
        "activity": "",
        "details": {
            "name": "",
            "additional_info": {}
        },
        "time_bounds": {
            "lower": 0,
            "upper": 60000000
        }
    };

    constructor(props){
        super(props);
        this.state = this._propToState(props);
    }

    _propToState = (props) => {
        const { conditions = [] } = props;
        const [ { trigger, sequence, combine_operator = CONDITIONS_ENUM.OR } ] = conditions.length > 0 ? conditions : [{trigger: this._TRIGGER_INITIAL_STATE, sequence: -1}];
        return {
            trigger,
            order: sequence === -1 ? (combine_operator === CONDITIONS_ENUM.AND ? ORDER_ENUM.ANY : ORDER_ENUM.ANYONE) : ORDER_ENUM.LOOSE,
            conditions: conditions.map(o => {o._id = '' + Math.random() * 1000000; return o;})
        };
    };

    componentWillMount(){
        //this.handleUpdate();
    }

    handleUpdate = () => {
        const { trigger, conditions, order } = this.state;
        const defaultTrigger =  trigger;
        const sequence_enabled = order === ORDER_ENUM.LOOSE;
        let updatedConditions = conditions.map((condition, index) => {
            let { combine_operator, ...others } = condition;
            if(index < conditions.length){
                combine_operator = sequence_enabled || order === ORDER_ENUM.ANY ? CONDITIONS_ENUM.AND : CONDITIONS_ENUM.OR;
            }
            return {
                ...others,
                combine_operator
            };
        });
        this.setState({conditions: updatedConditions});
        this.props.handleUpdate({
            conditions: updatedConditions.map((condition, index) => {
                let { _id, trigger, activity, details, event_type, ...others } = condition;
                const triggerForTimes = {
                    ...trigger,
                    event_type: TRIGGER_ENUM["Activity Event"],
                    activity: activity,
                    details: {
                        name: activity,
                        additional_info: {}
                    },
                    time_bounds: {
                        lower: 0,
                        upper: 6000000
                    }
                };
                return {
                    ...others,
                    activity,
                    details,
                    event_type,
                    trigger: event_type === EVENTS_ENUM[STEP_ENUM.IDLE_TIME] || event_type === EVENTS_ENUM[STEP_ENUM.ACTIVITY_TIME] ? triggerForTimes : defaultTrigger,
                    sequence: sequence_enabled ? index + 1 : -1
                };
            }),
            sequence_enabled
        });
    };

    addNewCondition = (rule_type) => {
        this.setState({
            conditions: [
                ...this.state.conditions,
                {
                    ..._CONDITION_INITIAL_STATE,
                    event_type: EVENTS_ENUM[rule_type],
                    rule_type,
                    _id: '' + Math.random() * 1000000,
                    details: {
                        name: "",
                        additional_info: {}
                    }
                }
            ]
        }, this.handleUpdate);
    };

    removeCondition = (index) => {
        const { conditions } = this.state;
        this.setState({
            conditions: [...conditions.slice(0, index), ...conditions.slice(index + 1)]
        }, this.handleUpdate);
    };

    handleStepUpdate = (index) => (condition) => {
        const { conditions } = this.state;
        const newConditions = [ ...conditions.slice(0, index), condition, ...conditions.slice(index + 1) ];
        this.setState({conditions: newConditions}, this.handleUpdate);
    };

    render(){
        const {inEditMode, appState: {appScreenNames = [], app: {basic_info: {platform}}}} = this.props;
        const {
            trigger, trigger: {
                event_type, activity,
                time_bounds, time_bounds: { lower, upper },
                details
            },
            order, conditions
        } = this.state;
        const disabled = !inEditMode && this.props.disabled;
        return(
            <Fragment>
                <Paper elevation={1} style={{padding: 32}}>
                    <Typography variant="h5" style={{marginBottom: 32}}>Criteria</Typography>
                    <Grid container spacing={16}>
                        <Grid item xs={8} md={3}>
                            <FormSelect
                                options={TRIGGER_OPTIONS}
                                value={event_type}
                                label="Select Trigger Event"
                                handleUpdate={event_type => {
                                    if(event_type !== EVENTS_ENUM.activity_event){
                                        this.setState({
                                            trigger: {
                                                ...trigger,
                                                event_type,
                                                activity: "", //resetting activity and details
                                                details: {
                                                    name: "",
                                                    additional_info: {}
                                                }
                                            }}, this.handleUpdate)
                                    }else{
                                        this.setState({trigger: {...trigger, event_type}}, this.handleUpdate);
                                    }
                                }}
                                idKey="key"
                                nameKey="label"
                                disabled={disabled}
                                margin="normal"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={16}>
                        <Grid item xs={4} md={1}/>
                        <Grid item xs={12} md={11}>
                            {event_type === EVENTS_ENUM.activity_event &&
                            <MultiSelect
                                options={appScreenNames.map(o => ({label: o, value: o}))}
                                value={activity}
                                label={platform === APP_PLATFORMS.ios ? "Select View Controller" : "Select Activity"}
                                handleChange={activity => this.setState({
                                    trigger: {
                                        ...trigger,
                                        activity,
                                        details: {
                                            ...details,
                                            name: activity
                                        }
                                    }
                                }, this.handleUpdate)
                                }
                                style={{width: 300, display: 'inline-block'}}
                                disabled={disabled}
                                single
                            />
                            }
                            {event_type === EVENTS_ENUM.app_event &&
                            <EventAttributeQuery
                                {...this.props}
                                {...details}
                                disabled={disabled}
                                handleUpdate={details => this.setState({
                                    trigger: {
                                        ...trigger,
                                        details
                                    }
                                }, this.handleUpdate)}
                            />
                            }
                        </Grid>
                    </Grid>
                    <Typography style={{margin: '16px 0'}} variant="subtitle1">Select Time</Typography>
                    <div style={{display: 'flex'}}>
                        <NumberField
                            style={{maxWidth: 150}}
                            type="number"
                            required
                            placeholder="2000"
                            value={lower}
                            label="Start"
                            handleChange={
                                lower => this.setState({
                                        trigger: {
                                            ...trigger,
                                            time_bounds: {...time_bounds, lower}
                                        }},
                                    this.handleUpdate)
                            }
                            margin="none"
                            disabled={disabled}
                            metricType="ms"
                        />
                        <NumberField
                            style={{maxWidth: 150, marginLeft: 24}}
                            type="number"
                            required
                            placeholder="2000"
                            value={upper}
                            label="End"
                            handleChange={
                                upper => this.setState({
                                        trigger: {
                                            ...trigger,
                                            time_bounds: {...time_bounds, upper}
                                        }},
                                    this.handleUpdate)
                            }
                            disabled={disabled}
                            margin="none"
                            metricType="ms"
                        />
                    </div>
                    <div style={{margin: '16px 0'}}/>
                    <Typography variant="h5" style={{margin: '32px 0'}}>Rule Configuration</Typography>
                    <Typography variant="subtitle1">Ordering</Typography>
                    <Switch
                        data={Object.values(ORDER_ENUM)}
                        disabled={disabled}
                        value={order}
                        handleChange={(order) => this.setState({order}, this.handleUpdate)}
                    />
                    <div style={{display: 'flex'}}>
                        <InfoOutlined color="primary"/> &nbsp; <Typography style={{lineHeight: '2.2em'}} variant="caption">{ORDERING_MESSAGES[order]}</Typography>
                    </div>
                    {
                        conditions.map((condition, index) =>
                            <div key={"condition-step-" + index + condition._id}>
                                { !disabled && conditions.length > 1 && <div style={{
                                    position: 'absolute',
                                    zIndex: 3,
                                    right: 96,
                                    marginTop: 72
                                }}>
                                    <Button variant="outlined" size="small" onClick={() => this.removeCondition(index)}>Remove</Button>
                                </div>
                                }
                                <br />
                                <StepConfig
                                    key={"condition-step-config-" + index}
                                    {...this.props}
                                    order={order}
                                    condition={condition}
                                    index={index + 1}
                                    handleUpdate={this.handleStepUpdate(index)}
                                    disabled={disabled}
                                    isLast={index === conditions.length - 1}
                                />
                            </div>
                        )
                    }
                    {
                        !disabled && <div style={{display: 'flex', justifyContent: 'space-around', marginTop: 8}}>
                            <Button size="small" onClick={() => this.addNewCondition(STEP_ENUM.ACTIVITY)} variant="outlined" color="primary">
                                <AddIcon/> Add {platform === APP_PLATFORMS.ios ? "View Controller" : "Activity"}</Button>
                            <Button size="small" onClick={() => this.addNewCondition(STEP_ENUM.EVENT)} variant="outlined" color="primary">
                                <AddIcon/> Add App Event</Button>
                            <Button size="small" onClick={() => this.addNewCondition(STEP_ENUM.CLIENT_EVENT)} variant="outlined" color="primary">
                                <AddIcon/> Add Client Event</Button>
                            <Button size="small" onClick={() => this.addNewCondition(STEP_ENUM.MESSAGE_EVENT)} variant="outlined" color="primary">
                                <AddIcon/> Add Message Event</Button>
                        </div>
                    }
                </Paper>
            </Fragment>
        )

    }

}

export class ContextCreator extends Component {

    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        const {
            disabled,
            appState: { appScreenNames = [], appSegments = [] },
        } = this.props;
        return(
            <ConditionSelector {...this.props} />
        )
    }

}
