/**
 * Created by kodanda_rama on 1/15/17.
 */
import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import MobileSimulator from '../../../../../../../components/reusable/MobileSimulator';
import {
    spacingCSS, positionCSS,
    renderButton, renderImage, renderText
} from'./utils';

export const MOBILE_IMAGE_URL = "/assets/img/mobile-mockup.png";

export default class InAppMobileSimulator extends React.Component{

    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        const {
            position, background, bg_color,
            titleConfig = {}, contentConfig = {},
            hasImage = true, image: {padding_config, margin_config } = {}, image = {},
            hasOkButton = true, hasCloseButton = true,
            okButton = {}, closeButton = {}, full_width = false, dim_amount = 0.5
        } = this.props;
        return(
            <MobileSimulator
                screenStyles={{
                    background: background === "normal" ? '#FFF' : `rgba(51,51,51, ${dim_amount})`
                }}
            >
                <Card style={{
                    color: bg_color,
                    backgroundColor: bg_color,
                    backgroundSize: 'cover',
                    minHeight: 64,
                    width: full_width ? '100%' : 'calc(100% - 32px)',
                    ...positionCSS(position, full_width)
                }}>
                    <CardHeader
                        avatar={
                            hasImage && <div style={{
                                padding: spacingCSS(padding_config),
                                margin: spacingCSS(margin_config)
                            }}>
                                <Avatar aria-label="Image">
                                    {renderImage(image)}
                                </Avatar>
                            </div>
                        }
                        title={renderText(titleConfig)}
                        subheader={renderText(contentConfig)}
                    />
                    <CardActions style={{display: 'block', textAlign: 'right'}}>
                        { hasOkButton && renderButton(okButton)}
                        { hasCloseButton && renderButton(closeButton)}
                    </CardActions>
                </Card>
            </MobileSimulator>
        )
    }

}