export const EM_GET_CLIENT_EVENTS = "EM_GET_CLIENT_EVENTS";
export const EM_GET_CLIENT_EVENTS_PENDING = "EM_GET_CLIENT_EVENTS_PENDING";
export const EM_GET_CLIENT_EVENTS_FAILED = "EM_GET_CLIENT_EVENTS_FAILED";

export const UPSERT_EVENT = "UPSERT_CLIENT_EVENTS";
export const UPSERT_EVENT_PENDING = "UPSERT_CLIENT_EVENTS_PENDING";
export const UPSERT_EVENT_FAILED = "UPSERT_CLIENT_EVENTS_FAILED";

export const DELETE_CLIENT_EVENT = "DELETE_CLIENT_EVENT";
export const DELETE_CLIENT_EVENT_PENDING = "DELETE_CLIENT_EVENT_PENDING";
export const DELETE_CLIENT_EVENT_FAILED = "DELETE_CLIENT_EVENT_FAILED";

export const EDIT_CLIENT_EVENT = "EDIT_CLIENT_EVENT";
export const EDIT_CLIENT_EVENT_PENDING = "EDIT_CLIENT_EVENT_PENDING";
export const EDIT_CLIENT_EVENT_FAILED = "EDIT_CLIENT_EVENT_FAILED";

export const ADD_CLIENT_EVENT = "ADD_CLIENT_EVENT";
export const ADD_CLIENT_EVENT_PENDING = "ADD_CLIENT_EVENT_PENDING";
export const ADD_CLIENT_EVENT_FAILED = "ADD_CLIENT_EVENT_FAILED";

export const PAGINATION_ACTION = "PAGINATION_ACTION";
export const UPDATE_PAGE_COUNTER = "UPDATE_PAGE_COUNTER";
export const FILTERS_CHANGED = "FILTERS_CHANGED";

export const RESET_EV_STATE = "RESET_EV_STATE";

export const MODAL_STATE_CHANGE = "MODAL_STATE_CHANGE";