/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React, {Component, Fragment} from 'react';
import Grid from '@material-ui/core/Grid';
import {
    segmentFilterWithoutCounts,
} from "../../../../../../../utils";
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import AppBar from "@material-ui/core/AppBar/AppBar";
import VOVTimeSpent from "../../SelfComponents/components/VOVTimeSpent";
import AddToDashboard from "../AddToDashboard";
import VOVDAUPercent from "../../SelfComponents/components/VOVDAUPercent";
import VOVEventsPerDay from "../../SelfComponents/components/VOVEventsPerDay";
import { COMPONENT_IDS } from '../../SelfComponents';
import VersionSelector from "../../../../../../../components/reusable/VersionSelector";
import TabLabel from "../../../../../../../components/reusable/TabLabel";
import ToggleSwitch from "../../../../../../../components/reusable/MaterialUi/ToggleSwitch";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import SegmentConfig from "../../Segments/SegmentConfig";
import Typography from "@material-ui/core/Typography";

const { VOV_TIME_SPENT, VOV_AVG_EVENTS_PER_DAY, VOV_DAU_PERCENT } = COMPONENT_IDS;

const VOV_TYPES = {
    TIME_SPENT: "TIME_SPENT",
    DAU_PERCENT: "DAU_PERCENT",
    AVG_EVENTS_PER_DAY: "AVG_EVENTS_PER_DAY"
};

export default class VOV extends Component {

    constructor(props){
        super(props);
        this.state = {
            current: 0,
            versions: [],
            event: "",
            changed: false,
            latest: false,
            nVersions: 5,
            minimumUsers: 100
        };
    }

    componentWillMount(){
        const {
            appState: { appVersions = [] } = { },
        } = this.props;
        this.setState({versions: appVersions.slice(0, 4)});
    }

    handleChange = () => {
        this.setState({changed: true});
        setTimeout(() => {
            this.setState({changed: false});
        }, 1000);
    };

    render(){
        const { current, versions, event, changed, latest, minimumUsers, nVersions } = this.state;
        const {
            appState: { appVersions = [], appEvents = [] } = { },
            segmentBuilder: { query = {} } = {},
            params: { dashboardId }, dispatch
        } = this.props;
        const selfParams = {
            queryParams: { versions, event, latest, minimumUsers, nVersions },
            extraFilters: segmentFilterWithoutCounts(query),
        };
        const componentId = current === 0 ? VOV_TIME_SPENT: (current === 1 ? VOV_DAU_PERCENT : VOV_AVG_EVENTS_PER_DAY);
        const AddToDashboardComponent = (
            <div style={{
                position: 'absolute',
                zIndex: 3,
                right: 0
            }}>
                <AddToDashboard
                    {...this.props}
                    componentId={componentId}
                    selectedDashboard={dashboardId}
                    getComponentParams={() => selfParams}
                >
                    <SegmentConfig user={query.user} session={query.session} event={[]}/>
                    <Typography>{event}</Typography>
                </AddToDashboard>
            </div>
        );
        return(
            <Grid container>
                <Grid item xs>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={current}
                            onChange={(e, current) => this.setState({current})}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab label={<TabLabel help="Average amount of time user spends per day">App Engagement (Version)</TabLabel>} />
                            <Tab label={<TabLabel help="% of daily active users who perform the selected metric per version">Feature Consumption (Version)</TabLabel>} />
                            <Tab label={<TabLabel help="Average number of times the selected metric is performed per day">Feature Engagement (Day)</TabLabel>} />
                        </Tabs>
                    </AppBar>
                    <Grid container spacing={16} justify="space-between">
                        {
                            !latest && <Grid item xs={8} sm={4} md={4}>
                                <VersionSelector
                                    versions={appVersions}
                                    handleUpdate={versions => this.setState({versions}, this.handleChange)}
                                    margin="normal"
                                    value={versions}
                                />
                            </Grid>
                        }
                        <Grid item xs={4} sm={4} md={4}>
                            <Grid container spacing={16}>
                                <Grid item xs={4} sm={3} md={3}>
                                    <ToggleSwitch styles={{marginTop: 8}} value={latest} label="Recent" checked={latest} handleChange={latest => this.setState({latest}, this.handleChange)}/>
                                </Grid>
                                {
                                    latest && <Fragment>
                                        <Grid item xs={4} sm={3} md={3}>
                                            <TextField
                                                value={nVersions}
                                                onChange={e => {
                                                    const nVersions = Number(e.target.value);
                                                    if(nVersions > 0){
                                                        this.setState({nVersions});
                                                    }
                                                }}
                                                label="Versions"
                                                placeholder="5"
                                                margin="dense"
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={4} md>
                                            <TextField
                                                value={minimumUsers}
                                                onChange={e => {
                                                    const minimumUsers = Number(e.target.value);
                                                    if(minimumUsers > 0){
                                                        this.setState({minimumUsers});
                                                    }
                                                }}
                                                label="with minimum Users"
                                                placeholder="100"
                                                margin="dense"
                                                type="number"
                                            />
                                        </Grid>
                                        <Grid item xs={4} sm={2} md={1}>
                                            <Button style={{marginTop: 12}} onClick={this.handleChange} variant="text" color="primary">Apply</Button>
                                        </Grid>
                                    </Fragment>
                                }
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={4} md={4}>
                            {current !== 0 && <MultiSelect
                                single
                                options={appEvents.map(o => ({value: o, label: o}))}
                                value={event}
                                handleChange={event => this.setState({event}, this.handleChange)}
                                placeholder="Select Event"
                                clearable={false}
                                margin="normal"
                            />}
                        </Grid>
                        <Grid item xs={12}>
                            <div style={{position: 'relative', width: '100%'}}>
                                {
                                    current === 0 &&
                                        <Fragment>
                                            {/*{AddToDashboardComponent}*/}
                                            <VOVTimeSpent
                                                {...this.props}
                                                selfParams={{
                                                    ...selfParams,
                                                    dataKey: VOV_TYPES.TIME_SPENT
                                                }}
                                                updateNow={changed}
                                            />
                                        </Fragment>
                                }
                                {
                                    current === 1 &&
                                    <Fragment>
                                        {/*{AddToDashboardComponent}*/}
                                        <VOVDAUPercent
                                            {...this.props}
                                            selfParams={{
                                                ...selfParams,
                                                dataKey: VOV_TYPES.DAU_PERCENT
                                            }}
                                            updateNow={changed}
                                        />
                                    </Fragment>
                                }
                                {
                                    current === 2 &&
                                    <Fragment>
                                        {/*{AddToDashboardComponent}*/}
                                        <VOVEventsPerDay
                                            {...this.props}
                                            selfParams={{
                                                ...selfParams,
                                                dataKey: VOV_TYPES.AVG_EVENTS_PER_DAY
                                            }}
                                            updateNow={changed}
                                        />
                                    </Fragment>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }

}