/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React from 'react';
import Box from "../../../../../../../components/reusable/Box";
import DeleteIcon from '@material-ui/icons/Delete';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import {IconButton, Tooltip} from "@material-ui/core";
import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import EditIcon from '@material-ui/icons/Edit';
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import {Link} from 'react-router';
import {hasAndIsDefined} from "../../../../../../../utils";
import NickNameDialog from "../../../../../../../components/reusable/NickNameDialog";

export class TestDevices extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showDialog: false,
            nick_name: props.nick_name || "",
            model: "",
            deviceId: ""
        };
    }

    setDevice = (model, deviceId) => {
        this.setState({model: model, deviceId: deviceId});
    };

    onNickDone = () => {
        const appId = this.props.params.appId;
        const {updateTestDeviceNick} = this.props;
        updateTestDeviceNick(appId, this.state.deviceId, this.state.nick_name);
        this.setState({showDialog: false, nick_name: ""});
    };

    onNickCancel = () => {
        this.setState({showDialog: false, nick_name: ""});
    };

    onNickChange = (event) => {
        this.setState({nick_name: event.target.value});
    };

    render() {
        const {params: {appId}, appState: {appTestDevices = []}, removeAsTestDevice} = this.props;
        return (
            <Box icon={<ImportantDevicesIcon/>}
                 title="Test Devices"
                 withPadding
            >
                {
                    <List>
                        {
                            appTestDevices.map((device, index) => {
                                const customId = device.custom_id ? ` (${device.custom_id})` : '';
                                const primary = hasAndIsDefined(device, "nick_name") ? device.nick_name : device.model;
                                const secondary = hasAndIsDefined(device, "nick_name") ? `${device.id}${customId} · ${device.model}` : `${device.id}${customId}`;
                                return (
                                    <ListItem key={device.id}>
                                        <ListItemText primary={<strong>{primary}</strong>} secondary={secondary}/>
                                        <ListItemSecondaryAction>
                                            <Tooltip id={"explore-test-device" + device.id} title="Explore User"
                                                     placement="top">
                                                <Link target={"_blank"} to={`/apps/${appId}/users/${device.id}`}>
                                                    <IconButton aria-label="Explore">
                                                        <OpenInNewIcon color="primary"/>
                                                    </IconButton>
                                                </Link>
                                            </Tooltip>
                                            <Tooltip id={"edit-test-device" + device.id} title="Click to edit"
                                                     placement="top">
                                                <IconButton aria-label="Edit" onClick={() => {
                                                    this.setState({
                                                        showDialog: true,
                                                        nick_name: device.nick_name || ""
                                                    });
                                                    this.setDevice(device.model, device.id);
                                                }}>
                                                    <EditIcon color="action"/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip id={"remove-test-device" + device.id} title="Click to remove"
                                                     placement="top">
                                                <IconButton aria-label="Remove" onClick={() => {
                                                    removeAsTestDevice(appId, device.id);
                                                }}>
                                                    <DeleteIcon color="error"/>
                                                </IconButton>
                                            </Tooltip>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                }
                {
                    appTestDevices.length === 0 &&
                    <Placeholder
                        text="No devices found. Add your test device from User Explorer"
                        withIcon
                        icon={<ImportantDevicesIcon style={{width: 100, height: 100, opacity: 0.2}}/>}
                    />
                }
                {
                    this.state.showDialog && <NickNameDialog model={this.state.model} deviceId={this.state.deviceId}
                                                             nick_name={this.state.nick_name}
                                                             onCancel={this.onNickCancel} onChange={this.onNickChange}
                                                             onDone={this.onNickDone}/>
                }
            </Box>
        )

    }

}