/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    UPDATE_HEADING,
    UPDATE_SIDEBAR
} from './actionTypes';

/**
 *
 * @param heading
 * @returns {function(*, *)}
 */
export function updateHeading(heading){
    return (dispatch, getState) => {
        //const appId = getState().app.app.app_id;
        return dispatch({
            type: UPDATE_HEADING,
            payload: heading,
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @returns {function(*, *)}
 */
export function resetHeading(){
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_HEADING,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @param open
 * @param pinned
 * @returns {function(*, *)}
 */
export function updateSidebar(open = true, pinned = false){
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_SIDEBAR,
            payload: open,
            meta: {
                pinned
            }
        });
    };
}