/**
 * Created by Rakesh Peela
 * Date: 27-Nov-2019
 * Time: 11:00 AM
 */

import {Grid} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import {hexToRGBA} from "../../../../../../../../../../../utils";
import {MESSAGE_TOOLTIP_LAYOUT_TYPES, POSITION_ENUM} from "../../models/common";
import {MODULE_TYPES} from "../modules/moduleTypes";
import {ButtonsHolder, ImageHolder, StyledHolder, TextHolder} from "./utils";

class ActionTooltipButton extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {buttonConfig} = this.props;
        const {color, text_config, description_config, image} = buttonConfig;
        return (
            <div style={{
                padding: "2px 4px",
                margin: "4px 0",
                background: color,
                color: text_config.color || "#cccccc",
                fontFamily: text_config.font_family || "sans-serif",
                borderRadius: 4,
            }}>
                <Grid container spacing={8} justify={"center"} alignItems={"center"}>
                    <Grid item>
                        <ImageHolder config={image} isPure style={{padding: 12}}/>
                    </Grid>
                    <Grid item xs sm md>
                        <TextHolder isPure bgColor={"#ffffff"} config={text_config}/>
                        <TextHolder isPure bgColor={"#ffffff"} config={description_config}/>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

class ActionTooltipButtonsHolder extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {bgColor, selected, config, handleModuleSelection} = this.props;
        return (
            <StyledHolder
                bgColor={bgColor}
                selected={selected}
                onClick={handleModuleSelection}
            >
                {config && Array.isArray(config) && config.map((eachButton, index) => (
                    <ActionTooltipButton buttonConfig={eachButton} key={"action_tooltip_btn" + index}/>
                ))}
            </StyledHolder>
        );
    }
}

const getBorderWidth = (border) => {
    return border !== undefined ? border.width : 0;
};

const getArrowPosition = (position) => {
    switch (position) {
        case POSITION_ENUM.BOTTOM:
            return "bottom: 100%; left: 50%";
        case POSITION_ENUM.TOP:
            return "top: 100%; left: 50%";
        case POSITION_ENUM.LEFT:
            return "bottom: 50%; left: calc(100% + 12px);";
        case POSITION_ENUM.RIGHT:
            return "bottom: 50%; right: 100%";
        default:
            return "";
    }
};

const getTooltipArrow = (props) => {
    if (!props.border) {
        return `
        content: '';
        position: absolute;
        ${getArrowPosition(props.position)}
        margin-left: -12px;
        border-width: 12px;
        border-style: solid;
        border-left-color: transparent;
        border-top-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
        border-${props.position.toLowerCase()}-color: ${props.bgColor};
    `
    } else {
        return `
        content: '';
        position: absolute;
        background: ${props.border.color};
        bottom: 100%;
        left: 50%;
        margin-left: -2px;
        height: 30px;
        width: 4px;
    `
    }
};

const getTooltipArrowBulb = (props) => {
    return `
        content: '';
        position: absolute;
        margin-left: -6px;
        bottom: calc(100% + 24px);
        background: ${props.border.color};
        left: 50%;
        height: 12px
        width: 12px;
        border-radius: 8px;
        border: none;
    `
};

const StyledDiv = styled.div`
    max-width: 360px;
    min-width: 180px;
    background-color: ${props => props.bgColor ? props.bgColor : "#ff0000"};
    padding: 8px;
    position: relative;
    line-height: 24px;
    z-index: 5;
    box-shadow: rgba(67, 90, 111, 0.3) 0px 0px 1px, rgba(67, 90, 111, 0.47) 0px 8px 10px -4px;
    border-radius: ${props => props.cornerRadius ? props.cornerRadius : 0}px;
    border: ${props => props.border !== undefined ? getBorderWidth(props.border) + "px solid" + props.border.color : "inherit"} ;
    margin: 24px;
    &::after {
        ${props => props.border ? getTooltipArrowBulb(props) : "content: '';"}
    }
    &::before {
        ${props => getTooltipArrow(props)}
    }
`;

const getLayoutBasedOnTooltipPosition = (position) => {
    switch (position) {
        case POSITION_ENUM.TOP:
            return {
                flexDirection: "column"
            }
        case POSITION_ENUM.LEFT:
            return {
                flexDirection: "row",
            }
        case POSITION_ENUM.BOTTOM:
            return {
                flexDirection: "column-reverse"
            }
        case POSITION_ENUM.RIGHT:
            return {
                flexDirection: "row-reverse",
            }
    }
}

class TooltipContainer extends React.Component {
    render() {
        const {children, config} = this.props;
        const {
            corners: {
                enabled: cornersEnabled,
                width = 8
            },
            border = {},
            position,
            dim_background = false,
            dim_background_opacity = 0.6,
            dim_background_color = "#5c5c5c",
            bg_color,
            layout_type
        } = config;
        return (
            <div style={{
                display: "flex",
                padding: 48,
                borderRadius: 8,
                background: dim_background ? hexToRGBA(dim_background_color, dim_background_opacity) : "transparent",
                ...getLayoutBasedOnTooltipPosition(position),
            }}>
                <StyledDiv
                    bgColor={bg_color}
                    cornerRadius={cornersEnabled ? width : 8}
                    position={position}
                    border={layout_type === MESSAGE_TOOLTIP_LAYOUT_TYPES.INLINE_WITH_DOT ? border : undefined}>
                    {children}
                </StyledDiv>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <div style={{
                        background: "#ebebed",
                        width: 48,
                        height: 48,
                        borderRadius: 4,
                        zIndex: 5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}>
                        <ThumbUpIcon style={{color: "#757575"}}/>
                    </div>
                </div>
            </div>
        )
    }
}

export default class TooltipSim extends React.Component {
    render() {
        const {
            containerConfigs: {config},
            buttonsConfig,
            titleConfig,
            descriptionConfig,
            handleModuleSelection,
            selectedModule,
            image,
            tooltipType
        } = this.props;
        return (
            <TooltipContainer config={config}>
                {tooltipType !== MESSAGE_TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS &&
                <Grid container spacing={8} alignItems={"stretch"} justify={"center"}>
                    {image && <Grid item>
                        <ImageHolder
                            handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.IMAGE_MODULE, image)}
                            selected={selectedModule === MODULE_TYPES.IMAGE_MODULE}
                            config={image}
                        />
                    </Grid>}
                    <Grid item xs sm md>
                        {titleConfig &&
                        <TextHolder
                            handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.TITLE_MODULE, titleConfig)}
                            selected={selectedModule === MODULE_TYPES.TITLE_MODULE}
                            bgColor={config.bg_color}
                            config={titleConfig}/>}
                        {descriptionConfig &&
                        <TextHolder
                            handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.DESCRIPTION_MODULE, descriptionConfig)}
                            selected={selectedModule === MODULE_TYPES.DESCRIPTION_MODULE}
                            bgColor={config.bg_color}
                            config={descriptionConfig}/>}
                    </Grid>
                </Grid>}

                {
                    tooltipType !== MESSAGE_TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS && buttonsConfig && buttonsConfig.length > 0 &&
                    <ButtonsHolder
                        handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.BUTTONS_MODULE, buttonsConfig)}
                        selected={selectedModule === MODULE_TYPES.BUTTONS_MODULE}
                        bgColor={config.bg_color}
                        config={buttonsConfig}
                    />
                }

                {
                    tooltipType === MESSAGE_TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS &&
                    <ActionTooltipButtonsHolder
                        handleModuleSelection={() => {
                            handleModuleSelection(MODULE_TYPES.ACTION_BUTTONS_MODULE, buttonsConfig)
                        }}
                        selected={selectedModule === MODULE_TYPES.ACTION_BUTTONS_MODULE}
                        bgColor={config.bg_color}
                        config={buttonsConfig}
                    />
                }
            </TooltipContainer>
        );
    }
}

