/**
 * Created by Rakesh Peela
 * Date: 14-Nov-2019
 * Time: 2:30 PM
 */

import {ButtonBase, Grid, IconButton, TextField} from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import React from 'react';
import styled from 'styled-components';
import MultiSelect from "../../../../../../../../../../components/reusable/MaterialUi/MultiSelect";
import {EventAttributeQuery, ClientEventAttributeQuery} from "../../../../../../../../../../components/reusable/QueryBuilder";
import {
    APX_FEATURES,
    LOGICAL_OPERATORS,
    MESSAGE_ENUM,
    MESSAGE_EVENTS
} from "../../../../../../../../../../constants";
import ClassicCard from "../../ClassicCard";
import FieldDescription from "./FieldDescription";

import {getClientEventNames, getClientEventAttributes} from "../../../../../Settings/EventsManagement/api"

const Operators = [
    {label: ">", value: LOGICAL_OPERATORS.GT},
    {label: "≥", value: LOGICAL_OPERATORS.GTE},
    {label: "<", value: LOGICAL_OPERATORS.LT},
    {label: "≤", value: LOGICAL_OPERATORS.LTE},
    {label: "=", value: LOGICAL_OPERATORS.EQ},
    {label: "!=", value: LOGICAL_OPERATORS.NEQ}
];

class CountConfigModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const {
            countConfig: {count = 1, operator = LOGICAL_OPERATORS.GTE},
            handleUpdateCondition
        } = this.props;
        return (
            <Grid container spacing={8} alignItems={"center"}>
                <Grid item xs>
                    <MultiSelect
                        options={Operators}
                        label={"Operator"}
                        value={operator}
                        single
                        handleChange={(op) => handleUpdateCondition({
                            count_config: {
                                operator: op,
                                count
                            }
                        })}
                    />
                </Grid>
                <Grid item xs>
                    <TextField
                        label="Count"
                        value={count}
                        onChange={e => handleUpdateCondition({
                            count_config: {
                                operator,
                                count: Number(e.target.value)
                            }
                        })}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
        );
    }
}

class AppEventContextModule extends React.Component {
    render() {
        const {condition: {details: {name}, details, count_config}, handleUpdateCondition, disabled} = this.props;
        return (
            // TODO:<R> Rewrite this
            <div>
                <EventAttributeQuery
                    {...this.props}
                    {...details}
                    disabled={disabled}
                    handleUpdate={details => handleUpdateCondition({
                        details
                    })}
                />
                {
                    name
                    && <Grid container spacing={16}>
                        <Grid item xs={3}/>
                        <Grid item>
                            <CountConfigModule
                                countConfig={{...count_config}}
                                handleUpdateCondition={handleUpdateCondition}
                            />
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }
}

class ClientContextModule extends React.Component {
    constructor(props) {
        super(props);
        const {condition: {details: {additional_info = {}}},} = this.props;
        this.state = {
            availableEventNames: [],
            attributes: {},
            currentAttributeValue: Object.keys(additional_info).length > 0 ? additional_info[Object.keys(additional_info)[0]]["val"] : "",
        }
    }

    hasCustomClientEvents = () => {
        const {appState: {app: {features = []}}} = this.props;
        return features.includes(APX_FEATURES.APX_CLIENT_EVENTS);
    };

    componentDidMount() {
        const {condition: {details: {name}},params: {appId}, session, messages2: {new_message: { meta: {platform}}}} = this.props;
        getClientEventNames(appId, platform, session, true)
            .then((availableEventNames) => {
                this.setState({availableEventNames});
            })
        getClientEventAttributes(appId, name,  platform, session, true)
        .then(attributes => {
            this.setState({attributes});
        })
    }

    onEventSelected = (event_name) => {
        const {params: {appId}, session, messages2: {new_message: { meta: {platform}}}} = this.props;
        getClientEventAttributes(appId, event_name,  platform, session)
            .then(attributes => {
                this.setState({attributes});
            })
    }

    //TODO: Fix this complex Object accessing
    render() {
        const {condition: {details: {name}, details, count_config}, disabled, handleUpdateCondition} = this.props;
        return (
            // TODO:<R> Rewrite this
            <div>
                <ClientEventAttributeQuery
                    {...this.props}
                    {...details}
                    disabled={disabled}
                    events={this.state.availableEventNames}
                    attributes={this.state.attributes}
                    onEventSelected={this.onEventSelected}
                    values={[]}
                    handleUpdate={details => handleUpdateCondition({details})}
                />
                {
                    name && <Grid container spacing={16}>
                        <Grid item xs={3}/>
                        <Grid item>
                            <CountConfigModule
                                countConfig={{...count_config}}
                                handleUpdateCondition={handleUpdateCondition}
                            />
                        </Grid>
                    </Grid>
                }
            </div>
        );
    }
}

const getMessagesListFromType = (messageType, messages) => {
    if (messageType === MESSAGE_EVENTS.INLINE_SHOWN || messageType === MESSAGE_EVENTS.INLINE_DISMISSED) {
        return messages.filter(o => o.uis.some(k => k.type === MESSAGE_ENUM.IN_LINE));
    } else {
        return messages.filter(o => o.uis.some(k => k.type === MESSAGE_ENUM.IN_APP));
    }
};

const MESSAGE_EVENTS_ENUM = {
    "INAPP_SHOWN": "inapp_shown",
    "INAPP_DISMISSED": "inapp_dismissed",
    "INLINE_SHOWN": "inline_shown",
    "INLINE_DISMISSED": "inline_dismissed"
};

class MessageContextModule extends React.Component {
    render() {
        const {
            condition: {
                details: {name},
                details,
                count_config
            },
            handleUpdateCondition,
            disabled
        } = this.props;
        return (
            <>
                <div>
                    <EventAttributeQuery
                        {...this.props}
                        {...details}
                        filteredEvents={["inline_shown", "inapp_shown", "inline_dismissed", "inapp_dismissed"]}
                        disabled={disabled}
                        handleUpdate={details => handleUpdateCondition({
                            details
                        })}
                    />
                    {
                        name
                        && <Grid container spacing={16}>
                            <Grid item xs={3}/>
                            <Grid item>
                                <CountConfigModule
                                    countConfig={{...count_config}}
                                    handleUpdateCondition={handleUpdateCondition}
                                />
                            </Grid>
                        </Grid>
                    }
                </div>
            </>
        );
    }
}

class ActivityContextModule extends React.Component {
    render() {
        const {condition: {activity, count_config}, handleUpdateCondition} = this.props;
        const {appState: {appScreenNames = []}} = this.props;
        const {messages2: {new_message: {meta: {platform}}}} = this.props;
        return (
            <Grid container spacing={8}>
                <Grid item xs={6} md={6}>
                    <MultiSelect
                        options={appScreenNames.map(o => ({label: o, value: o}))}
                        label={"Select Screen"}
                        value={activity}
                        single
                        handleChange={(activity) => handleUpdateCondition({
                            activity, details: {
                                additional_info: {},
                                name: activity
                            }
                        })}
                        style={{marginRight: 8}}
                    />
                </Grid>
                <Grid item xs={3} md={3}>
                    {activity &&
                    <CountConfigModule countConfig={{...count_config}} handleUpdateCondition={handleUpdateCondition}/>}
                </Grid>
            </Grid>
        );
    }
}

const StyledOperatorContainer = styled.div`
    display: inline-block;
    padding: 4px;
    background-color: #dcdcdc;
    border-radius: 36px;
    border: 2px dashed darkgray;
    margin: 16px 0;
    &::before {
        content: '';
        border: 2px dashed darkgray;
        background-color: transparent;
        position: relative;
        top: 30px;
        left: 50%;
        border-width: 1px;
        border-style: dashed;
        border-color: darkgray;
    }
    &::after {
        content: '';
        border: 2px dashed darkgray;
        background-color: transparent;
        position: relative;
        top: -30px;
        left: -49%;
        border-width: 1px;
        border-style: dashed;
        border-color: darkgray;
    }
`;

const COMBINE_OPERATORS_ENUM = {
    OR: {
        label: "OR",
        value: "OR",
        sequence_applicable: false
    },
    AND: {
        label: "AND",
        value: "AND",
        sequence_applicable: false
    },
    AND_THEN: {
        label: "AND THEN",
        value: "AND",
        sequence_applicable: true
    }
}

class CombineOperatorComponent extends React.Component {

    handleOperatorClick = (e, selectedOperator, sequence_enabled = false) => {
        const {updateOperator, operator} = this.props;
        updateOperator(selectedOperator || operator, sequence_enabled);
    };

    render() {
        const {operator, sequenceEnabled} = this.props;
        return (
            <div style={{display: 'flex', justifyContent: "center"}}>
                <StyledOperatorContainer>
                    {
                        Object.keys(COMBINE_OPERATORS_ENUM).map(operatorE => {
                            let isDisabled, isSelected;
                            let currentOperatorData = COMBINE_OPERATORS_ENUM[operatorE];

                            if (sequenceEnabled && currentOperatorData.label === "AND THEN") {
                                isSelected = true;
                                isDisabled = true;
                            } else {
                                if (!sequenceEnabled) {
                                    isSelected = operator === currentOperatorData.label;
                                }
                                isDisabled = operator !== currentOperatorData.label;
                            }

                            return (
                                <ButtonBase
                                    key={"operator_btn_" + operatorE}
                                    style={{
                                        borderRadius: 36,
                                        margin: "0 6px",
                                    }}
                                    disableRipple disableTouchRipple
                                    disabled={isSelected}
                                    onClick={
                                        (e) => {
                                            this.handleOperatorClick(
                                                e,
                                                COMBINE_OPERATORS_ENUM[operatorE].value,
                                                COMBINE_OPERATORS_ENUM[operatorE].sequence_applicable
                                            )
                                        }
                                    }
                                >
                                    <ClassicCard
                                        style={{minWidth: 100, borderRadius: 36, padding: "8px 4px"}}
                                        selected={isSelected}
                                        disabled={isDisabled}
                                    >
                                        {COMBINE_OPERATORS_ENUM[operatorE].label}
                                    </ClassicCard>
                                </ButtonBase>
                            )
                        })
                    }
                </StyledOperatorContainer>
            </div>
        );
    }
}

function getTermForHeading(ctx_type) {
    switch (ctx_type) {
        case "MESSAGE":
            return "Campaign Event";
        case "APP":
            return "App Event";
        case "ACTIVITY":
            return "Screen";
        case "CLIENT":
            return "Client Event";
        default:
            return "Event";
    }
}

class ContextEventSelectorHeader extends React.Component {
    render() {
        const {
            index,
            condition: {
                context_rule_type
            },
            removeCondition,
            moveCondition,
        } = this.props;
        const {messages2: {new_message: {meta: {platform}, conditions = []}}} = this.props;
        return (
            <Grid container justify={"space-between"}>
                <Grid item xs>
                    <FieldDescription
                        title={getTermForHeading(context_rule_type)}/>
                </Grid>
                <Grid item>
                    {
                        index !== 0
                        && <IconButton onClick={() => moveCondition(index, index - 1)}>
                            <ArrowUpwardIcon fontSize={"small"}/>
                        </IconButton>
                    }
                    {
                        (conditions.length !== 1 && index !== conditions.length - 1)
                        && <IconButton onClick={() => moveCondition(index, index + 1)}>
                            <ArrowDownwardIcon fontSize={"small"}/>
                        </IconButton>
                    }
                    <IconButton style={{color: "red"}} aria-label="Delete Condition" onClick={() => {
                        removeCondition(index)
                    }}>
                        <DeleteIcon fontSize="small"/>
                    </IconButton>
                </Grid>
            </Grid>
        );
    }
}

class ContextEventSelector extends React.Component {
    constructor(props) {
        super(props);
    }

    pickContextModule = (context_rule_type, props) => {
        switch (context_rule_type) {
            case "CLIENT":
                return <ClientContextModule {...props}/>;
            case "APP":
                return <AppEventContextModule {...props}/>;
            case "MESSAGE":
                return <MessageContextModule {...props}/>;
            default:
                return <ActivityContextModule {...props}/>;
        }
    };

    render() {
        const {
            condition: {
                context_rule_type = "ACTIVITY"
            },
            updateOperator,
            combineOperator,
            sequenceEnabled,
        } = this.props;
        return (
            <div>
                <ClassicCard>
                    <ContextEventSelectorHeader {...this.props}/>
                    {this.pickContextModule(context_rule_type, this.props)}
                </ClassicCard>
                <CombineOperatorComponent
                    operator={combineOperator}
                    sequenceEnabled={sequenceEnabled}
                    updateOperator={updateOperator}
                />
            </div>
        );
    }
}

export default ContextEventSelector;