/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {RESET_ALL} from '../../../../../../../constants/ActionTypes';
import {
    IAM_ADD_CUSTOMER_PENDING, IAM_ADD_CUSTOMER, IAM_ADD_CUSTOMER_FAILED,
    IAM_REMOVE_CUSTOMER_PENDING, IAM_REMOVE_CUSTOMER, IAM_REMOVE_CUSTOMER_FAILED,
    IAM_ADD_DASHBOARD_TO_CUSTOMER_PENDING, IAM_ADD_DASHBOARD_TO_CUSTOMER, IAM_ADD_DASHBOARD_TO_CUSTOMER_FAILED,
    IAM_REMOVE_DASHBOARD_TO_CUSTOMER_PENDING, IAM_REMOVE_DASHBOARD_TO_CUSTOMER, IAM_REMOVE_DASHBOARD_TO_CUSTOMER_FAILED,
    RESET_IAM, IAM_TRANSFER_APP, IAM_TRANSFER_APP_PENDING, IAM_TRANSFER_APP_FAILED
} from './actionTypes';

const ADD_CUSTOMER_FLAG_STATE = {
    add_customer_pending: false,
    add_customer_failed: false
};

const REMOVE_CUSTOMER_FLAG_STATE = {
    remove_customer_pending: false,
    remove_customer_failed: false
};

const ADD_DASHBOARD_TO_CUSTOMER_FLAG_STATE = {
    add_dashboard_to_customer_pending: false,
    add_dashboard_to_customer: false,
    add_dashboard_to_customer_failed: false
};

const REMOVE_DASHBOARD_TO_CUSTOMER_FLAG_STATE = {
    remove_dashboard_to_customer_pending: false,
    remove_dashboard_to_customer_failed: false
};

const TRANSFER_APP_OWNERSHIP_INITIAL_FLAG_STATE = {
    transfer_app_ownership: false,
    transfer_app_ownership_failed: false,
    transfer_app_ownership_pending: false
};

const INITIAL_STATE = {
    ...ADD_CUSTOMER_FLAG_STATE,
    ...REMOVE_CUSTOMER_FLAG_STATE,
    ...ADD_DASHBOARD_TO_CUSTOMER_FLAG_STATE,
    ...REMOVE_DASHBOARD_TO_CUSTOMER_FLAG_STATE,
    ...TRANSFER_APP_OWNERSHIP_INITIAL_FLAG_STATE
};

function iamReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case IAM_ADD_CUSTOMER_PENDING:
            return {
                ...state,
                ...ADD_CUSTOMER_FLAG_STATE,
                add_customer_pending: true
            };

        case IAM_ADD_CUSTOMER:
            return {
                ...state,
                ...ADD_CUSTOMER_FLAG_STATE
            };

        case IAM_ADD_CUSTOMER_FAILED:
            return {
                ...state,
                ...ADD_CUSTOMER_FLAG_STATE,
                add_customer_failed: true
            };

        case IAM_REMOVE_CUSTOMER_PENDING:
            return {
                ...state,
                ...REMOVE_CUSTOMER_FLAG_STATE,
                remove_customer_pending: true
            };

        case IAM_REMOVE_CUSTOMER:
            return {
                ...state,
                ...REMOVE_CUSTOMER_FLAG_STATE
            };

        case IAM_REMOVE_CUSTOMER_FAILED:
            return {
                ...state,
                ...REMOVE_CUSTOMER_FLAG_STATE,
                remove_customer_failed: true
            };

        case IAM_ADD_DASHBOARD_TO_CUSTOMER_PENDING:
            return {
                ...state,
                ...ADD_DASHBOARD_TO_CUSTOMER_FLAG_STATE,
                add_dashboard_to_customer_pending: true
            };

        case IAM_ADD_DASHBOARD_TO_CUSTOMER:
            return {
                ...state,
                ...ADD_DASHBOARD_TO_CUSTOMER_FLAG_STATE,
                add_dashboard_to_customer: true
            };

        case IAM_ADD_DASHBOARD_TO_CUSTOMER_FAILED:
            return {
                ...state,
                ...ADD_DASHBOARD_TO_CUSTOMER_FLAG_STATE,
                add_dashboard_to_customer_failed: true
            };

        case IAM_REMOVE_DASHBOARD_TO_CUSTOMER_PENDING:
            return {
                ...state,
                ...REMOVE_DASHBOARD_TO_CUSTOMER_FLAG_STATE,
                remove_dashboard_to_customer_pending: true
            };

        case IAM_REMOVE_DASHBOARD_TO_CUSTOMER:
            return {
                ...state,
                ...REMOVE_DASHBOARD_TO_CUSTOMER_FLAG_STATE
            };

        case IAM_REMOVE_DASHBOARD_TO_CUSTOMER_FAILED:
            return {
                ...state,
                ...REMOVE_DASHBOARD_TO_CUSTOMER_FLAG_STATE,
                remove_dashboard_to_customer_failed: true
            };

        case IAM_TRANSFER_APP:
            return {
                ...state,
                ...TRANSFER_APP_OWNERSHIP_INITIAL_FLAG_STATE,
                transfer_app_ownership: true
            };

        case IAM_TRANSFER_APP_PENDING:
            return {
                ...state,
                ...TRANSFER_APP_OWNERSHIP_INITIAL_FLAG_STATE,
                transfer_app_ownership_pending: true
            };

        case IAM_TRANSFER_APP_FAILED:
            return {
                ...state,
                ...TRANSFER_APP_OWNERSHIP_INITIAL_FLAG_STATE,
                transfer_app_ownership_failed: true
            };

        case RESET_IAM:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;

    }
}
export default iamReducer;