/**
 * Created by Rakesh Peela
 * Date: 14-Feb-2020
 * Time: 11:17 PM
 */
import {GET_DOWNLOADABLE, GET_DOWNLOADABLE_FAILED, GET_DOWNLOADABLE_PENDING} from "./actionTypes";

const INITIAL_STATE = {
    progress: {
        downloadable_failed: false,
        downloadable_pending: false
    },
    downloadableFiles: []
};

function settingsDataReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case GET_DOWNLOADABLE:
            return {
                ...state,
                downloadableFiles: action.payload || []
            };
        case GET_DOWNLOADABLE_PENDING:
            return {
                ...state,
                downloadable_pending: true
            };
        case GET_DOWNLOADABLE_FAILED:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    downloadable_failed: true,
                }
            };
        default:
            return {
                ...state
            }
    }
}

export default settingsDataReducer;