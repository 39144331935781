/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    UNINSTALL_USER_DISTRIBUTION_PENDING, UNINSTALL_USER_DISTRIBUTION, UNINSTALL_USER_DISTRIBUTION_FAILED,
    DAY_0_UNINSTALLS_PENDING, DAY_0_UNINSTALLS, DAY_0_UNINSTALLS_FAILED,
    DAY_0_USER_TRENDS_PENDING, DAY_0_USER_TRENDS, DAY_0_USER_TRENDS_FAILED,
    TOTAL_USER_TRENDS_PENDING, TOTAL_USER_TRENDS, TOTAL_USER_TRENDS_FAILED,
    UNINSTALLS_PENDING, UNINSTALLS, UNINSTALLS_FAILED, RESET_UNINSTALLS,
    UPDATE_RANGE_OF_UNINSTALL_DISTRIBUTION,
    UNINSTALL_COUNT_PENDING, UNINSTALL_COUNT, UNINSTALL_COUNT_FAILED,
    DAY0_UNINSTALL_COUNT_PENDING, DAY0_UNINSTALL_COUNT, DAY0_UNINSTALL_COUNT_FAILED,
    UNINSTALL_USAGE_PENDING, UNINSTALL_USAGE, UNINSTALL_USAGE_FAILED,
    UNINSTALL_DAY_USAGE_PENDING, UNINSTALL_DAY_USAGE, UNINSTALL_DAY_USAGE_FAILED
} from './actionTypes';
import {
    getUninstallsAPI, getDay0UninstallsAPI, getDay0UserTrendsAPI, getTotalUserTrendsAPI,
    getUninstallDistributionAPI, getUninstallUsageAPI
} from './api';

/**
 *
 * @param appId
 * @returns {function(*, *): *}
 */
export const getUninstalls = (appId) => {
    return (dispatch, getState) => {
        let filters = {...getState().filters};
        const uninstallFilters = getState().segmentBuilder.query;
        return dispatch({
            types: [
                UNINSTALLS_PENDING,
                UNINSTALLS,
                UNINSTALLS_FAILED
            ],
            payload: {
                promise: getUninstallsAPI(getState().auth, appId, filters, uninstallFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};
/**
 *
 * @param appId
 * @returns {function(*, *): *}
 */
export const getDay0Uninstalls = (appId) => {
    return (dispatch, getState) => {
        let filters = {...getState().filters};
        const uninstallFilters = getState().segmentBuilder.query;
        return dispatch({
            types: [
                DAY_0_UNINSTALLS_PENDING, DAY_0_UNINSTALLS, DAY_0_UNINSTALLS_FAILED
            ],
            payload: {
                promise: getDay0UninstallsAPI(getState().auth, appId, filters, uninstallFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};
/**
 *
 * @param appId
 * @returns {function(*, *): *}
 */
export const getTotalUserTrends = (appId) => {
    return (dispatch, getState) => {
        let filters = {...getState().filters};
        const uninstallFilters = getState().segmentBuilder.query;
        return dispatch({
            types: [
                TOTAL_USER_TRENDS_PENDING, TOTAL_USER_TRENDS, TOTAL_USER_TRENDS_FAILED
            ],
            payload: {
                promise: getTotalUserTrendsAPI(getState().auth, appId, filters, uninstallFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};
/**
 *
 * @param appId
 * @returns {function(*, *): *}
 */
export const getDay0UserTrends = (appId) => {
    return (dispatch, getState) => {
        let filters = {...getState().filters};
        const uninstallFilters = getState().segmentBuilder.query;
        return dispatch({
            types: [
                DAY_0_USER_TRENDS_PENDING, DAY_0_USER_TRENDS, DAY_0_USER_TRENDS_FAILED
            ],
            payload: {
                promise: getDay0UserTrendsAPI(getState().auth, appId, filters, uninstallFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};
/**
 *
 * @returns {function(*, *): *}
 */
export const resetUninstalls = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_UNINSTALLS,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};
/**
 *
 * @param range
 * @returns {function(*, *): *}
 */
export const updateUninstallDistributionRange = (range) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_RANGE_OF_UNINSTALL_DISTRIBUTION,
            payload: range,
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param start
 * @param end
 * @param key
 * @returns {function(*, *)}
 */
export const getUninstallUserDistributionForRange = (appId, start, end, key) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, start: start, end: end};
        const uninstallFilters = getState().segmentBuilder.query;
        return dispatch({
            types: [
                UNINSTALL_USER_DISTRIBUTION_PENDING, UNINSTALL_USER_DISTRIBUTION, UNINSTALL_USER_DISTRIBUTION_FAILED
            ],
            payload: {
                promise: getUninstallDistributionAPI(getState().auth, appId, filters, uninstallFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key,
            }
        });
    };
};


/**
 *
 * @param appId
 * @param start
 * @param end
 * @param key
 * @returns {function(*, *)}
 */
export const getUninstallUsageForRange = (appId, start, end, key) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, start: start, end: end};
        const uninstallFilters = getState().segmentBuilder.query;
        return dispatch({
            types: [
                UNINSTALL_USAGE_PENDING, UNINSTALL_USAGE, UNINSTALL_USAGE_FAILED
            ],
            payload: {
                promise: getUninstallUsageAPI(getState().auth, appId, filters, uninstallFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

/**
 *
 * @param appId
 * @param start
 * @param end
 * @param key
 * @returns {function(*, *)}
 */
export const getUninstallDayUsageForRange = (appId, start, end, key) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, start: start, end: end, uninstallDay: true};
        const uninstallFilters = getState().segmentBuilder.query;
        return dispatch({
            types: [
                UNINSTALL_DAY_USAGE_PENDING, UNINSTALL_DAY_USAGE, UNINSTALL_DAY_USAGE_FAILED
            ],
            payload: {
                promise: getUninstallUsageAPI(getState().auth, appId, filters, uninstallFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                key: key
            }
        });
    };
};

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getUninstallUserCount = (appId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters};
        const uninstallFilters = getState().segmentBuilder.query;
        return dispatch({
            types: [
                UNINSTALL_COUNT_PENDING, UNINSTALL_COUNT, UNINSTALL_COUNT_FAILED
            ],
            payload: {
                promise: getUninstallDistributionAPI(getState().auth, appId, filters, uninstallFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

/**
 *
 * @param appId
 * @param day
 * @returns {function(*, *)}
 */
export const getUninstallUserCountForDay = (appId, day = 0) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, start: day, end: day};
        const uninstallFilters = getState().segmentBuilder.query;
        return dispatch({
            types: [
                DAY0_UNINSTALL_COUNT_PENDING, DAY0_UNINSTALL_COUNT, DAY0_UNINSTALL_COUNT_FAILED
            ],
            payload: {
                promise: getUninstallDistributionAPI(getState().auth, appId, filters, uninstallFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

function getStartEnd(range, key) {
    const {min, max} = range;
    switch (key) {
        case 1:
            return {start: 0, end: min - 1, key};
        case 2:
            return {start: min, end: max - 1, key};
        default:
            return {start: max, end: 90, key};
    }
}

/**
 *
 * @param appId
 * @returns {Function}
 */
export const getUninstallUserDistribution = (appId, _key) => {
    return (dispatch, getState) => {
        const range = {...getState().uninstalls.range};
        const {start, end, key} = getStartEnd(range, _key);
        dispatch(getUninstallUserDistributionForRange(appId, start, end, key));
    };
};

export const getUninstallUsageDistribution = (appId, _key) => {
    return (dispatch, getState)=> {
        const range = {...getState().uninstalls.range};
        const {start, end, key} = getStartEnd(range, _key);
        dispatch(getUninstallUsageForRange(appId, start, end, key));
        dispatch(getUninstallDayUsageForRange(appId, start, end, key));
    }
};

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const loadUninstallsData = (appId) => {

    return (dispatch, getState) => {
        dispatch(getUninstalls(appId));
        dispatch(getDay0Uninstalls(appId));
        dispatch(getTotalUserTrends(appId));
        dispatch(getDay0UserTrends(appId));
        dispatch(getUninstallUserDistribution(appId, 1));
        dispatch(getUninstallUserDistribution(appId, 2));
        dispatch(getUninstallUserDistribution(appId, 3));
        dispatch(getUninstallUserCount(appId));
        dispatch(getUninstallUserCountForDay(appId, 0));
    };

};