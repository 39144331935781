/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {updateHeading} from '../../../Navigation/actions';
import {
    getContexts, getContext, resetContexts,
    saveContext, updateContext, deleteContext, updateLocalContext
} from '../actions';
import ContextForm from "../../../../../../../components/reusable/Context";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import {Link} from "react-router";
import {DASHBOARD_ENDPOINTS} from "../../../../../../../constants/EndPoints";
import Placeholder from "../../../../../../../components/reusable/Placeholder";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        segment: state.segment,
        filters: state.filters,
        meta: state.meta,
        contexts: state.contexts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getContext: (appId, contextId) => dispatch(getContext(appId, contextId)),
        updateContext: (appId, contextId, context) => dispatch(updateContext(appId, contextId, context)),
        resetContexts: () => dispatch(resetContexts()),
        updateLocalContext: (context) => dispatch(updateLocalContext(context))
    };
}

class Context extends Component {

    constructor(props){
        super(props);
        const {
            contexts: { context: { conditions = [], name = "" } = {} }
        } = this.props;
        this.state = {
            conditions,
            name
        }
    }

    componentWillMount(){
        const {
            params: { appId, contextId }, updateHeading,
            contexts: { context: { _id, name } = {} }, getContext
        } = this.props;
        updateHeading(name || "Unknown Context");
        if(contextId !== _id){
            getContext(appId, contextId);
        }
    }

    componentWillReceiveProps(nextProps){
        const {
            updateHeading, contexts: { context: { _id, name } = {} }
        } = nextProps;
        if(this.props.contexts.context._id !== _id){
            updateHeading(name);
            this.setState({name});
        }
    }

    componentWillUnmount(){
        this.props.resetContexts();
    }

    handleSubmit = () => {
        const {
            params: { appId, contextId, }, updateContext
        } = this.props;
        const { name, conditions } = this.state;
        if(name && name.length > 0 && conditions && conditions.length > 0){
            updateContext(appId, contextId, { name, conditions });
        }
    };

    handleContextUpdate = () => {
        this.props.updateLocalContext(this.state);
    };

    render() {
        const {
            params: { appId },
            meta: { api_pending },
            contexts: { update_context, update_context_failed, context: { conditions = [] } = {} }
        } = this.props;
        const { name = "" } = this.state;
        return (
            <section className="content">
                { conditions.length > 0 &&
                    <ContextForm
                        {...this.props}
                        conditions={conditions}
                        handleUpdate={state => {
                            this.setState(state, this.handleContextUpdate);
                        }}
                    />
                }
                {
                    !api_pending && conditions.length === 0 &&
                    <Placeholder
                        withIcon
                        icon={<BrokenImageIcon style={{opacity: 0.2, width: 250, height: 250}} />}
                        text={
                            <span>
                                No Context Found. Try coming from &nbsp;
                                <Link to={`/apps/${appId}/${DASHBOARD_ENDPOINTS.CONTEXTS}`}>Contexts</Link>
                                &nbsp;page.
                            </span>
                        }
                    />
                }
                {
                    conditions.length > 0 && <Grid container spacing={16} justify="flex-end">
                        <Grid item xs={8} md={3}>
                            <TextField
                                type="text"
                                label="Name"
                                placeholder="LoginContext"
                                value={name}
                                onChange={e => {
                                    this.setState({name: e.target.value})
                                }}
                                fullWidth
                                margin="normal"
                                required
                            />
                        </Grid>
                        <Grid item xs={4} md={1}>
                            <Button
                                fullWidth
                                style={{margin: '24px 0'}}
                                onClick={this.handleSubmit}
                                variant="contained"
                                disabled={!(name && name.length > 0 && conditions.length > 0)}
                                color="primary"
                            >Update</Button>
                        </Grid>
                    </Grid>
                }
                { update_context && <Snackbar>Context updated successfully.</Snackbar> }
                { update_context_failed && <Snackbar>Updating Context failed, try again.</Snackbar> }
            </section>
        );
    }
}

Context.propTypes = {

};

Context.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Context);