/**
 * Created by kodanda_rama on 1/15/17.
 */
import React, {Fragment} from 'react';
import {Button, Tooltip, Grid} from "@material-ui/core";
import { spacingCSS, renderButton, renderText } from './utils';
import {withStyles} from "@material-ui/core/styles";
import {MESSAGE_POSITION_ENUM} from "../actionTypes";
import MobileSimulator from '../../../../../../../components/reusable/MobileSimulator';
import ArrowRight from '@material-ui/icons/ArrowForward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowLeft from '@material-ui/icons/ArrowBack';


const { TOP, BOTTOM, LEFT, RIGHT } = MESSAGE_POSITION_ENUM;

const styles = theme => ({
    root: {

    },
    tooltip: {
        background: 'transparent'
    },
    '@keyframes load-progress': {
        '100%': {
            marginLeft: -160,
            marginTop: -160,
            borderWidth: 160,
            opacity: 0
        }
    },
    ripple: {
        width: 0,
        height: 0,
        position: 'absolute',
        webkitAnimation: 'load-progress 1s infinite',
        animation: 'load-progress 1s infinite',
        webkitAnimationDelay: '1s',
        animationDelay: '1s',
        marginTop: 18 //Half og Button Height
    },
    nonRipple: {
        position: 'absolute',
        opacity: 0.6,
        marginTop: -130,
    },
    bounce: {
        webkitAnimation: 'bounce-up-down 0.3s 5',
        animation: 'bounce-up-down 0.3s 5'
    },
    "@keyframes shake": {
        "10%, 90%": {
            "transform": "translateX(0)"
        },
        "10%, 30%, 50%, 70%, 90%": {
            "transform": "translateX(-3px)"
        },
        "20%, 40%, 60%, 80%": {
            "transform": "translateX(3px)"
        }
    },
    "@keyframes bounce-up-down": {
        "0%, 100%": {
            "transform": "translateY(0)"
        },
        "50%": {
            "transform": "translateY(-4px)"
        }
    },
});

export const MOBILE_IMAGE_URL = "/assets/img/mobile-mockup.png";

/**
 * @deprecated
 */
class InLineMobileSimulator extends React.Component{

    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        const { classes, name, position,
            corners: {enabled, width } = {},
            text_config, text_config: {  size, font_family, color, margin_config = {}, padding_config = {}} = {},
            display_type, coach_mark_details: {type, has_ripple, arrow_color, has_arrow} = {},
            bg_color, opacity, view_id,
            border: { width: borderWidth, color: borderColor } = {width: 0, color: bg_color },
            hasOkButton, hasCloseButton, okButton, closeButton,
            animation: { enabled: animationEnabled, type: animationType, duration: animationDuration} = {},
        } = this.props;
        const CoachMark = ({top, left, right, bottom}) => (
            <div style={{
                fontSize: size,
                fontFamily: font_family,
                color: color,
                background: 'transparent',
                textAlign:"center",
                flexDirection: "column",
                border: 0,
                width: "auto",
                display: 'flex',
                padding: spacingCSS(padding_config),
                margin: spacingCSS(margin_config),
                boxSizing: 'border-box'
            }}>
                <div style={{display: 'absolute', zIndex: 5}}>
                    {has_arrow && top}
                    {has_arrow && left}
                    {renderText(text_config)}
                    <div style={{display: 'flex', marginTop: 10, justifyContent: 'center', textAlign: 'center'}}>
                        { hasOkButton && renderButton(okButton)}
                        { hasCloseButton && renderButton(closeButton)}
                    </div>
                    { has_arrow && right}
                    { has_arrow && bottom}
                </div>
            </div>
        );
        const bigRipple = position === LEFT || position === RIGHT;
        const ripple = (
            <div style={{margin: '0 50%'}}>
                <div className={classes.nonRipple} style={{
                    background: bg_color,
                    borderRadius: type === "circle" ? '50%' : '0',
                    opacity,
                    marginLeft: position === LEFT ? -220 : -140,
                    width: bigRipple ? 360 : 280,
                    height: bigRipple ? 360 : 280,
                }}/>
                <div className={has_ripple ? classes.ripple : ""} style={{
                    borderRadius: type === "circle" ? '50%' : '0',
                    border: `1px solid #FFF`
                }} />
            </div>
        );
        const renderView = (
            <div style={{marginTop: 30}}>
                { ripple }
                <Button style={{zIndex: 10}} variant="contained" color="primary">{view_id || "Sample View"}</Button>
            </div>
        );
        return(
            <MobileSimulator screenStyles={display_type === 'inline' ? {} : {
                background: 'transparent',
                opacity: 1
            }}>
                {
                    display_type === "coach_mark" &&
                    <div style={{
                        margin: '50% auto',
                        display: 'block',
                        textAlign: 'center',
                        maxWidth: 294
                    }}>
                        {
                            position === TOP && <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    { <CoachMark bottom={<ArrowDownward nativeColor={arrow_color}/>}/> }
                                </Grid>
                                <Grid item xs={12}>
                                    {renderView}
                                </Grid>
                            </Grid>
                        }
                        {
                            position === BOTTOM && <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    { renderView }
                                </Grid>
                                <Grid item xs={12}>
                                    {<CoachMark top={<ArrowUpward nativeColor={arrow_color}/>} />}
                                </Grid>
                            </Grid>
                        }
                        {
                            position === LEFT && <Grid container spacing={8}>
                                <Grid item xs={8}>
                                    { <CoachMark right={<ArrowRight nativeColor={arrow_color}/>}/>}
                                </Grid>
                                <Grid item xs={4}>
                                    {renderView}
                                </Grid>
                            </Grid>
                        }
                        {
                            position === RIGHT && <Grid container spacing={8}>
                                <Grid item xs={4}>
                                    { renderView }
                                </Grid>
                                <Grid item xs={8}>
                                    {<CoachMark left={<ArrowLeft nativeColor={arrow_color}/>} />}
                                </Grid>
                            </Grid>
                        }
                    </div>
                }
                {
                    display_type === "inline" &&
                    <Tooltip
                        open
                        id={view_id + name + position + color + bg_color}
                        title={
                            <div style={{
                                fontSize: size,
                                fontFamily: font_family,
                                color: color,
                                backgroundColor: bg_color,
                                width: '100%',
                                height: '100%',
                                padding: '7px 5px 0 5px',
                                borderRadius: enabled ? width : 0,
                                opacity: opacity,
                                border: `${borderWidth}px solid ${borderColor}`,
                                maxWidth: 240,
                                animation: animationEnabled ? `shake ${2000}ms ease-in-out infinite` : undefined
                            }}>
                                {renderText(text_config, true)}
                                <div style={{marginTop: 10, textAlign: 'right', width: '100%'}}>
                                    { hasOkButton && renderButton(okButton)}
                                    { hasCloseButton && renderButton(closeButton)}
                                </div>
                            </div>
                        }
                        placement={position.toLowerCase()}
                        PopperProps={{
                            style: {
                                background: 'transparent',
                            }
                        }}
                        classes={{
                            tooltip: classes.tooltip
                        }}
                    >
                        <Button
                            style={{
                                margin: '50% auto',
                                display: 'block',
                                float: position === LEFT ? 'right' : (position === RIGHT ? 'left' : 'none')
                            }}
                            variant="contained"
                            color="primary"
                        >{view_id || "Sample View"}</Button>
                    </Tooltip>
                }
                {
                    display_type === "passive_nudge" &&
                        <div style={{
                            margin: '50% auto',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <Button
                                variant="contained"
                                color="primary"
                            >{view_id || "Sample View"}</Button>
                            <div className={classes.bounce} style={{
                                backgroundColor: bg_color,
                                borderRadius: '50%',
                                width: 8,
                                height: 8,
                                marginLeft: 4
                            }} />
                        </div>
                }
            </MobileSimulator>
        )
    }

}


export default withStyles(styles)(InLineMobileSimulator);