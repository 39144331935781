/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

export const USER_PROFILE_STATIC_BASIC_INFO = "USER_PROFILE_STATIC_BASIC_INFO";
export const USER_PROFILE_DYNAMIC_BASIC_INFO = "USER_PROFILE_DYNAMIC_BASIC_INFO";

export const USER_PROFILE_SESSION_INFO = "USER_PROFILE_SESSION_INFO";
export const USER_PROFILE_CRASH_INFO = "USER_PROFILE_CRASH_INFO";
export const USER_PROFILE_TRANSACTION_INFO = "USER_PROFILE_TRANSACTION_INFO";

export const USER_PROFILE_USP_USABILITY = "USER_PROFILE_USP_USABILITY";
export const USER_PROFILE_USP_STABILITY = "USER_PROFILE_USP_STABILITY";
export const USER_PROFILE_USP_PERFORMANCE = "USER_PROFILE_USP_PERFORMANCE";
export const USER_PROFILE_USP_FUNCTIONALITY = "USER_PROFILE_USP_FUNCTIONALITY";

export const USER_PROFILE_SESSIONS_TIME_SERIES = "USER_PROFILE_SESSIONS_TIME_SERIES";

export const USER_PROFILE_SESSIONS_LIST_PENDING = "USER_PROFILE_SESSIONS_LIST_PENDING";
export const USER_PROFILE_SESSIONS_LIST = "USER_PROFILE_SESSIONS_LIST";
export const USER_PROFILE_SESSIONS_LIST_FAILED = "USER_PROFILE_SESSIONS_LIST_FAILED";
export const RESET_PROFILE_SESSIONS_LIST = "RESET_PROFILE_SESSIONS_LIST";

export const USER_PROFILE_SESSION_TIMELINE_PENDING = "USER_PROFILE_SESSION_TIMELINE_PENDING";
export const USER_PROFILE_SESSION_TIMELINE = "USER_PROFILE_SESSION_TIMELINE";
export const USER_PROFILE_SESSION_TIMELINE_FAILED = "USER_PROFILE_SESSION_TIMELINE_FAILED";

export const USER_PROFILE_PENDING = "USER_PROFILE_PENDING";
export const USER_PROFILE_FAILED = "USER_PROFILE_FAILED";

export const RESET_USER_PROFILE = "RESET_USER_PROFILE";

/**
 * USER List Filters
 * @type {string}
 */
export const UPDATE_SESSIONS_PAGINATION_PAGE = "UPDATE_SESSIONS_PAGINATION_PAGE";
export const UPDATE_SESSIONS_PAGINATION_ORDER_BY = "UPDATE_SESSIONS_PAGINATION_ORDER_BY";
export const UPDATE_SESSIONS_PAGINATION_SORT_ORDER = "UPDATE_SESSIONS_PAGINATION_SORT_ORDER";
export const UPDATE_SESSIONS_PAGINATION_COUNT = "UPDATE_SESSIONS_PAGINATION_COUNT";

export const MARK_AS_TEST_DEVICE = "MARK_AS_TEST_DEVICE";
export const UPDATE_NICK_TEST_DEVICE = "UPDATE_NICK_TEST_DEVICE";
export const REMOVE_AS_TEST_DEVICE = "REMOVE_AS_TEST_DEVICE";