/**
 * Created by Rakesh Peela
 * Date: 07-Mar-2020
 * Time: 12:28 AM
 */

import {
    Button,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import DeleteIcon from '@material-ui/icons/Delete';
import React from "react";
import Loading from "../../../../../../components/reusable/Loading";
import {uploadCohort} from "./actions";
import {StyledDropzone} from "./StyledDropzone";

class UploadCohortDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cohortName: null,
            selectedFile: null,
            uploadInProgress: false,
        }
    }

    componentWillUnmount() {
        // this.clearFileSelect();
    }

    handleCohortNameChange = (e) => {
        this.setState({
            cohortName: e.target.value,
        })
    };

    onInputFileChange = (file) => {
        this.setState({
            selectedFile: file || null
        });
    };

    clearFileSelect = () => {
        this.setState({
            selectedFile: null
        });
    };

    render() {
        const {appId, status, dispatch,} = this.props;
        const {cohortName, selectedFile, uploadInProgress} = this.state;
        return (
            <div>
                <Grid container spacing={16}>
                    <Grid item xs={6} sm={6}>
                        <TextField
                            placeholder="Cohort Name"
                            fullWidth
                            variant={"outlined"}
                            onChange={this.handleCohortNameChange}
                            style={{marginRight: 8}}
                        />
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        {
                            !selectedFile
                            && <div>
                                <StyledDropzone
                                    single
                                    acceptType={".csv"}
                                    onChange={this.onInputFileChange}
                                />
                                <Typography variant={"subtitle2"}>
                                    Max file size: 50MB
                                </Typography>
                            </div>
                        }
                        {
                            selectedFile
                            && <List>
                                <ListItem
                                    style={{
                                        listStyle: "none",
                                        background: "#ced5ff70",
                                        marginTop: 4,
                                        marginBottom: 4,
                                        borderRadius: 4,
                                        padding: 0
                                    }}
                                >
                                    <ListItemText
                                        style={{padding: "2px 12px"}}
                                        primary={`${selectedFile.name}`}
                                    />
                                    <ListItemSecondaryAction>
                                        <IconButton onClick={this.clearFileSelect}>
                                            <DeleteIcon color={"error"}/>
                                        </IconButton>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        }
                    </Grid>
                </Grid>
                <div style={{textAlign: "center", marginTop: 16}}>
                    {
                        status.error_message === null
                        && <Button
                            variant={"outlined"}
                            disabled={
                                status.pending
                                || !(cohortName !== null && cohortName !== "" && selectedFile !== null)
                                && uploadInProgress
                            }
                            onClick={() => {
                                this.setState({
                                    uploadInProgress: true
                                });
                                dispatch(uploadCohort(appId, cohortName, selectedFile.name, selectedFile))
                            }}
                        >
                            <div style={{display: "flex", flexDirection: "row"}}>
                                {
                                    status.pending && <Loading size={10}/>
                                }
                                <Typography>
                                    Upload
                                </Typography>
                            </div>
                        </Button>
                    }

                    {
                        status.error_message !== null
                        && <Typography style={{height: 36}} color={"error"}>
                            {status.error_message}
                        </Typography>
                    }
                </div>
            </div>
        );
    }

}

export default UploadCohortDialog;