import React, { Component } from 'react';
import {
    getConversionRateTrend,
    getEventRelatedTimeSeries, getGlobalRelatedTimeSeries, getSegmentRelatedTimeSeries
} from '../../actions';
import {
    addedWithAverageKeys,
    graphDataToCSVString, keyToTitle, minMaxAverageOf, toTitleCase
} from "../../../../../../../../utils";
import MultiUtilityChart from "../../../../../../../../components/reusable/Recharts/MultiUtilityChart";
import MultiSelect from "../../../../../../../../components/reusable/MaterialUi/MultiSelect";
import Checkbox from "../../../../../../../../components/reusable/MaterialUi/Checkbox";
import withSelfComponent from "../SelfComponent";
import { session } from "../../../../../../../../utils/Storage";

const isRateKey = (key) => /^rate|.*\(rate\).*/.test(key);
const isAllKey = (key) => /^all.*/.test(key);

const formatData = (data = [], groupBy, showAll, withData, endEvent) => {
    let dataKeys = [ "rate" ];
    //Assigning goal event data to Average Key
    const withAverageKey = data.map(o => ({...o, 'avg_rate': "E2="+o[endEvent]}));

    if(Array.isArray(data) && data.length > 0){
        if(withData || groupBy){
            let dataKeys = Object.keys(data[0]).filter(o => o !== "key" && !isRateKey(o));
            if(!withData){
                dataKeys = Object.keys(data[0]).filter(o => isRateKey(o)).map(o => o.replace("(rate)", ""));
                data = data.map(o => {
                    dataKeys.forEach(key => {
                        o[key] = o[key + "(rate)"];
                        o["avg_" + key] = "E2=" + o[`${key}(${endEvent})`]
                    });
                    return o;
                });

            }
            return {
                dataKeys: dataKeys.filter(o => !isAllKey(o) || showAll),
                data: withAverageKey
            }
        }else{
            return {
                dataKeys,
                data: withAverageKey
            };
        }
    }
    return {
        dataKeys,
        data: withAverageKey
    };
};


class Trend extends Component {

    constructor(props){
        super(props);
        this.state = {
            groupBy: null,
            showAll: false,
            withData: false
        };
    }

    hydrator = (props = this.props) => {
        const {
            startEvent, endEvent, dataKey,
            queryParams, extraFilters
        } = props.selfParams;
        const {dispatch, params: {appId}} = props;
        let extendedQueryParams = {startEvent, endEvent, ...queryParams};
        const { groupBy } = this.state;
        if(groupBy){
            extendedQueryParams = {...queryParams, groupBy}
        }
        const filters = {...extraFilters}; //FIXME: why this ?
        dispatch(getConversionRateTrend(appId, dataKey, extendedQueryParams, filters));
    };

    render(){
        const {
            self, selfParams: { dataKey, customLabel, of, startEvent, endEvent}, parentCompLabel,
            appState: { attributes: { user = [], session: sessionAttributes = [], event = [] } = {} },
            meta: { api_pending }
        } = this.props;
        let trendDataKey = "rate";
        const properties = [ ...event, ...user, ...sessionAttributes ];
        const { groupBy, showAll, withData } = this.state;
        let { dataKeys, data } = formatData(self[dataKey], groupBy, showAll, withData, endEvent );
        if(!api_pending) {
            let obj = {};
            obj[customLabel] = graphDataToCSVString(customLabel, data);
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        if(withData) {
            data = addedWithAverageKeys(data, dataKeys);
        }
        const stats = minMaxAverageOf(data, trendDataKey);
        return (
            <div style={{position: 'relative', width: '100%'}}>
                {
                    Array.isArray(data) && data.length > 0 && <div style={{
                        position: 'absolute',
                        zIndex: 3,
                        right: 250,
                        width: groupBy ? 400 : 300,
                        display: 'flex'
                    }}>
                        {
                            !groupBy && <Checkbox
                                checked={withData}
                                handleChange={(withData) => this.setState({withData})}
                                value="showData"
                                label="Numbers"
                            />
                        }
                        {
                            groupBy && <Checkbox
                                checked={showAll}
                                handleChange={(showAll) => this.setState({showAll})}
                                value="showAll"
                                label="All"
                            />
                        }
                        {
                            !withData  && <MultiSelect
                                options={properties.map(o => ({label: o, value: o}))}
                                handleChange={groupBy => {
                                    this.setState({groupBy}, this.hydrator);
                                }}
                                value={this.state.groupBy}
                                placeholder="Group By Property"
                                single
                                margin="normal"
                            />
                        }
                    </div>
                }
                <MultiUtilityChart
                    {...this.props}
                    title={customLabel}
                    height={groupBy ? 400 : 300}
                    withHeader
                    dataKey="key"
                    yLabel={`${keyToTitle(of)}s rate %`}
                    xLabel="Date"
                    data={ data }
                    lineDataKeys={dataKeys}
                    stats={stats}
                    withoutAggregations={!!groupBy}
                    fullWidth
                    showAverage
                />
            </div>
        )
    };
}

Trend.propTypes = {

};

export default withSelfComponent(Trend);