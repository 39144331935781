import React  from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = theme => ({
    checked: {
        color: theme.palette.primary.main + '!important',
    },
    formControl: {
        display: 'flex',
        //margin: apxTheme.spacing.unit * 3,
    },
    group: {
        display: 'flex',
        margin: `${theme.spacing.unit / 10}px 0`,
        flexDirection: 'row'
    },
});

class CustomCheckbox extends React.Component {

    render() {
        const {
            classes, label, handleChange, value,
            checked, disableRipple,
            disabled = false
        } = this.props;

        return (
            <FormControlLabel
                control={
                    <Checkbox
                        disableRipple={disableRipple}
                        checked={checked}
                        onChange={(e, checked) => handleChange(checked)}
                        value={value}
                        classes={{
                            checked: classes.checked,
                        }}
                        disabled={disabled}
                    />
                }
                label={label}
            />
        );
    }
}

CustomCheckbox.propTypes = {
    classes: PropTypes.object,
    label: PropTypes.any,
    handleChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    checked: PropTypes.bool.isRequired,
    disableRipple: PropTypes.bool,
};

export default withStyles(styles, {withTheme: true})(CustomCheckbox);