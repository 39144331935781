/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    UNINSTALL_SETTINGS_FAILED, UNINSTALL_SETTINGS_PENDING, UNINSTALL_SETTINGS,
    UPDATE_UNINSTALL_SETTINGS, UPDATE_UNINSTALL_SETTINGS_FAILED, UPDATE_UNINSTALL_SETTINGS_PENDING,
    RESET_UNINSTALL_SETTINGS
} from './actionTypes';
import {RESET_ALL} from '../../../../../../../constants/ActionTypes';
const INITIAL_STATE = {
    uninstall_settings: []
};

const INITIAL_UNINSTALL_SETTINGS_STATE = {
    uninstall_settings_pending: false,
    uninstall_settings_failed: false
};

const INITIAL_UPDATE_UNINSTALL_SETTINGS_STATE = {
    update_uninstall_settings_pending: false,
    update_uninstall_settings_failed: false,
    uninstall_settings_updated: false
};

function sortedOnVersions(data) {
    if(data && Array.isArray(data.versions)){
        return {
            ...data,
            versions: data.versions.sort((a, b) => a.version > b.version ? -1 : (a.version < b.version ? 1 : 0))
        }
    }else return data;
}

function retentionReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case UNINSTALL_SETTINGS_PENDING:
            return Object.assign({}, state, INITIAL_UNINSTALL_SETTINGS_STATE, INITIAL_UPDATE_UNINSTALL_SETTINGS_STATE, {
                uninstall_settings_pending: true,
            });

        case UNINSTALL_SETTINGS:
            return Object.assign({}, state, INITIAL_UNINSTALL_SETTINGS_STATE, INITIAL_UPDATE_UNINSTALL_SETTINGS_STATE, {
                uninstall_settings: sortedOnVersions(action.payload),
            });

        case UNINSTALL_SETTINGS_FAILED:
            return Object.assign({}, state, INITIAL_UNINSTALL_SETTINGS_STATE, INITIAL_UPDATE_UNINSTALL_SETTINGS_STATE, {
                uninstall_settings_failed: true
            });

        case UPDATE_UNINSTALL_SETTINGS_PENDING:
            return Object.assign({}, state, INITIAL_UPDATE_UNINSTALL_SETTINGS_STATE, {
                update_uninstall_settings_pending: true
            });

        case UPDATE_UNINSTALL_SETTINGS:
            return Object.assign({}, state, INITIAL_UPDATE_UNINSTALL_SETTINGS_STATE, {
                uninstall_settings_updated: action.payload
            });

        case UPDATE_UNINSTALL_SETTINGS_FAILED:
            return Object.assign({}, state, INITIAL_UPDATE_UNINSTALL_SETTINGS_STATE, {
                update_uninstall_settings_failed: true
            });

        case RESET_UNINSTALL_SETTINGS:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default retentionReducer;