import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ItemTypes } from './constants';
import { DropTarget } from 'react-dnd';
import Grid from '@material-ui/core/Grid';
import Widget from './Widget';
import withDragDropContext from "../../../../../../../components/plugins/withDragDropContext";

const widgetTarget = {
    drop(props, monitor) {

    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        currentWidget: monitor.getItem()
    };
}

class WidgetsContainer extends Component {

    render() {
        const {
            connectDropTarget,
            widgets, persistableComponents,
        } = this.props;
        return connectDropTarget(
            <div style={{width: '100%', minHeight: 100}}>
                <Grid container spacing={24}>
                    {
                        persistableComponents.map((component, index) => {
                            const widget = widgets[index];
                            const id = component._id;
                            return(
                                <GridWrappedWidget {...this.props} index={index} key={id} id={id} component={component} widget={widget}/>
                            );
                        })
                    }
                </Grid>
            </div>
        );
    }
}

class GridWrappedWidget extends Component {

    constructor(props){
        super(props);
        this.state = {
            size: props.component.size || 12
        };
    }

    render(){

        const { index, id, component, widget } = this.props;

        return(
            <Grid item xs={12} md={component.size || 12} id={id} style={{overflow: 'hidden'}}>
                <Widget
                    {...this.props}
                    component={component}
                    key={id}
                    id={id}
                    widget={widget}
                    index={index}
                />
            </Grid>
        )

    }

}

WidgetsContainer.propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    widgets: PropTypes.array.isRequired,
    onDrop: PropTypes.func.isRequired,
    movable: PropTypes.bool
};

export default withDragDropContext(DropTarget(ItemTypes.WIDGET, widgetTarget, collect)(WidgetsContainer));