/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getDashboards, saveDashboard, updateDashboard, deleteDashboard
} from '../actions';
import {getGroupFromList, getGroupNameFromList, uniqueId} from "../../../../../../../utils";
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import IconButton from '@material-ui/core/IconButton';
import {Button, Divider, Grid, Typography} from "@material-ui/core";
import Dialog from "../../../../../../../components/reusable/MaterialUi/Dialog";
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import ToolTip from '@material-ui/core/Tooltip';
import TextField from '@material-ui/core/TextField';
import DashboardIcon  from '@material-ui/icons/Dashboard';
import Components from "../../SelfComponents";
import { APXOR_SDK_CATEGORY_CONSTANTS } from '../../../../../../../constants';
import Apxor from "apxor";

function mapStateToProps(state) {
    return {
        ...state,
        session: state.auth,
        router: state.routing,
        appState: state.app,
        meta: state.meta,
        self: state.self,
        dashboards: state.dashboards,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getDashboards: (appId) => dispatch(getDashboards(appId)),
        saveDashboard: (appId, dashboard) => dispatch(saveDashboard(appId, dashboard)),
        deleteDashboard: (appId, dashboardId) => dispatch(deleteDashboard(appId, dashboardId)),
        updateDashboard: (appId, dashboard) => dispatch(updateDashboard(appId, dashboard)),
        dispatch
    };
}

const INITIAL_ADD_TO_DASHBOARD_STATE = {
    addTriggered: false,
    selectedDashboard: null,
    customLabel: "",
    newDashboardName: "",
    selectDashboardError: false,
    nameError: false,
    showNewDashboardForm: false,
    addedToDashboard: false,
    submittedToAdd: false
};

class AddToDashboard extends Component {

    timeOut = null;

    constructor(props){
        super(props);
        const { selectedDashboard  } = props;
        this.state = {
            ...INITIAL_ADD_TO_DASHBOARD_STATE,
            selectedDashboard
        };
    }

    componentWillMount(){
        const { getDashboards, params: { appId } } = this.props;
        getDashboards(appId);
    }

    componentWillReceiveProps(nextProps) { //FIXME: Not sure of this HACK :( Waiting for newly created dashboardId
        const { dashboards: { dashboards = [] }} = this.props;
        const { dashboards: { dashboards: newDashboards, config_updated } } = nextProps;
        const { newDashboardName } = this.state;
        if (newDashboardName && newDashboardName.length > 0 && dashboards.length < newDashboards.length) {
            const newDashboardCreated = newDashboards[newDashboards.length - 1]; //Not sure of getting at this index
            this.setState({
                selectedDashboard: newDashboardCreated._id,
                newDashboardName: "",
                showNewDashboardForm: false
            });
        } else if (config_updated) {
            this.setState({addedToDashboard: true}, () => { //FIXME: memory leak
                this.timeOut = setTimeout(() => { // Resetting to old state after 5 seconds
                    this.setState({...INITIAL_ADD_TO_DASHBOARD_STATE})
                }, 6000);
            });
        }
    }

    updatedConfig = (config = {}) => {
        const { getComponentParams = () => ({}), componentId } = this.props;
        const { components = [] } = config;
        const { customLabel } = this.state;
        const { component, icon, ...withoutComponent} = Components.get(componentId);
        const newComponent = {
            _id: uniqueId("widget"),
            selfParams: {
                ...getComponentParams(),
                customLabel,
                dataKey: uniqueId(uniqueId("data")) //FIXME: need better key than this
            },
            ...withoutComponent
        };
        return {
            ...config,
            last_updated: new Date().toJSON(),
            components: [...components, newComponent]
        }
    };

    componentWillUnmount(){
        if(this.timeOut){
            clearTimeout(this.timeOut); //To avoid memory leakages
        }
    }

    render(){
        const {
            params: { appId },
            dashboards: { dashboards = []},
            saveDashboard, updateDashboard, children
        } = this.props;
        const {
            addTriggered, selectedDashboard, customLabel,
            newDashboardName, selectDashboardError, nameError,
            showNewDashboardForm, addedToDashboard, submittedToAdd
        } = this.state;
        return(
            <div>
                {
                    submittedToAdd && addedToDashboard &&
                    <Snackbar
                        action={
                            <Button key="anchor-to-dashboard" color="primary" size="small" onClick={e => {
                                this.context.router.push(`/apps/${appId}/dashboards/${selectedDashboard}`);
                            }}>{getGroupNameFromList(dashboards, selectedDashboard)}</Button>
                        }
                    >
                        <strong>{customLabel}</strong> added to&nbsp;
                    </Snackbar>
                }
                <ToolTip id="tooltip-add-to-dashboard" title="Add to dashboard" placement="bottom">
                    <IconButton style={{float: 'right'}} onClick={e => this.setState({addTriggered: true})} color="primary" aria-label="Add to dashboard">
                        <DashboardIcon />
                    </IconButton>
                </ToolTip>
                <Dialog
                    status={addTriggered}
                    handleConfirm={() => {
                        if(!selectedDashboard || selectedDashboard.length === 0){
                            this.setState({selectDashboardError: true});
                            setTimeout(() => {
                                this.setState({selectDashboardError: false});
                            }, 4000);
                        }else if(customLabel && customLabel.trim().length > 0){
                            const dashboard = getGroupFromList(dashboards, selectedDashboard);
                            updateDashboard(appId, {...dashboard, config: this.updatedConfig(dashboard.config)});
                            this.setState({addTriggered: false, submittedToAdd: true});
                        }else{
                            this.setState({nameError: true});
                            setTimeout(() => {
                                this.setState({nameError: false});
                            }, 4000);
                        }
                    }}
                    title="Adding to Dashboard"
                    handleClose={() => this.setState(INITIAL_ADD_TO_DASHBOARD_STATE)}
                    confirmLabel="Confirm"
                    allowCancelLabel="Cancel"
                    dialogProps={{
                        maxWidth: 'sm',
                        fullWidth: true,
                        disableBackdropClick: true
                    }}
                >
                    { children }
                    { children && <Divider style={{margin: '16px 0'}}/> }
                    <MultiSelect
                        placeholder="Select dashboard to add"
                        options={dashboards.map(o => ({label: o.name, value: o._id}))}
                        handleChange={selectedDashboard => this.setState({selectedDashboard})}
                        value={selectedDashboard}
                        single
                        error={selectDashboardError}
                    />
                    <Typography onClick={e => this.setState({showNewDashboardForm: !showNewDashboardForm})} variant="caption" component="a" style={{margin: '10px 0', cursor: 'pointer'}}>Create new dashboard</Typography>
                    {
                        showNewDashboardForm && <Grid container justify="flex-end">
                            <Grid item xs>
                                <TextField
                                    placeholder="New Dashboard Name"
                                    fullWidth
                                    value={newDashboardName}
                                    onChange={e => this.setState({newDashboardName: e.target.value})}
                                    margin="dense"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <Button variant="contained" color="primary" onClick={e => {
                                    if(newDashboardName && newDashboardName.trim().length > 0){
                                        saveDashboard(appId, {name: newDashboardName, config: {components: []}}); //creating new dashboard
                                    }
                                }}>Create New Dashboard</Button>
                            </Grid>
                        </Grid>
                    }
                    <TextField
                        label="Label for Component"
                        placeholder="Label"
                        fullWidth
                        value={customLabel}
                        onChange={e => this.setState({customLabel: e.target.value})}
                        margin="normal"
                        error={nameError}
                    />
                    <Typography variant="caption" component="p" style={{marginTop: 5, color: 'red'}}>
                        {nameError && <span>Name should not be empty.</span>}
                        {selectDashboardError && <span>Dashboard not selected.</span>}
                        &nbsp;
                    </Typography>
                </Dialog>
            </div>
        )
    }

}

AddToDashboard.propTypes = {
    componentId: PropTypes.string.isRequired,
    getComponentParams: PropTypes.func.isRequired,
    selectedDashboard: PropTypes.string
};

AddToDashboard.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddToDashboard);