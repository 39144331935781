/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {updateHeading} from '../../../Navigation/actions';
import {
    getContexts, getContext, resetContexts,
    saveContext, updateContext, deleteContext, updateLocalContext
} from '../actions';
import Context from "../../../../../../../components/reusable/Context";
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import {DASHBOARD_ENDPOINTS} from "../../../../../../../constants/EndPoints";
import {Link} from "react-router";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        segment: state.segment,
        filters: state.filters,
        meta: state.meta,
        contexts: state.contexts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getContext: (appId, contextId) => dispatch(getContext(appId, contextId)),
        saveContext: (appId, context) => dispatch(saveContext(appId, context)),
        resetContexts: () => dispatch(resetContexts()),
        updateLocalContext: (context) => dispatch(updateLocalContext(context))
    };
}

class NewContext extends Component {

    constructor(props){
        super(props);
        this.state = {
            conditions: [],
            name: ""
        }
    }

    componentWillMount(){
        this.props.updateHeading("New Context");
    }

    componentWillReceiveProps(nextProps){
        //
    }

    componentWillUnmount(){
        this.props.resetContexts();
    }

    handleSubmit = () => {
        const {
            params: { appId }, saveContext,
            contexts: { context: { name, conditions = [] } = [] }
        } = this.props;
        if(name && name.length > 0 && conditions && conditions.length > 0){
            saveContext(appId, {name, conditions });
        }
    };

    handleContextUpdate = () => {
        this.props.updateLocalContext(this.state);
    };

    render() {
        const {
            params: { appId },
            contexts: { save_context, save_context_failed, context: { conditions = [], name = "" } = {} }
        } = this.props;
        return (
            <section className="content">
                <Context
                    {...this.props}
                    conditions={conditions}
                    handleUpdate={state => {
                        this.setState(state, this.handleContextUpdate);
                    }}
                />
                <Grid container spacing={16} justify="flex-end">
                    <Grid item xs={8} md={3}>
                        <TextField
                            type="text"
                            label="Name"
                            placeholder="LoginContext"
                            value={name}
                            onChange={e => {
                                this.setState({name: e.target.value}, this.handleContextUpdate)
                            }}
                            fullWidth
                            margin="normal"
                            required
                        />
                    </Grid>
                    <Grid item xs={4} md={1}>
                        <Button
                            fullWidth
                            style={{margin: '24px 0'}}
                            onClick={this.handleSubmit}
                            variant="contained"
                            disabled={!(name && name.length > 0 && conditions.length > 0)}
                            color="primary"
                        >Save</Button>
                    </Grid>
                </Grid>
                { save_context && <Snackbar
                    action={<Link to={`/apps/${appId}/${DASHBOARD_ENDPOINTS.CONTEXTS}/${save_context}`}><Button color="secondary" variant="text">Contexts</Button></Link>}
                >Context saved successfully.</Snackbar> }
                { save_context_failed && <Snackbar>Saving Context failed, try again.</Snackbar> }
            </section>
        );
    }
}

NewContext.propTypes = {

};

NewContext.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewContext);