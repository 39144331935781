/**
 * Created by Rakesh Peela
 * Date: 08-Sep-2019
 * Time: 10:37 AM
 */

import { makeDefaultQueryString, withQueryStrings } from "../../../../../../utils";
import { callApi } from "../../../../../../api";

const GET_COHORTS_API = 'cohorts';
const CREATE_COHORTS_API = 'cohorts/create';
const UPDATE_COHORT_API = 'cohorts/update';

export function createCohortFromSegmentAPI(auth, appId, segmentId, cohortSince, cohortTill) {
    const url = makeDefaultQueryString(CREATE_COHORTS_API, auth, appId) + withQueryStrings({
        segmentId,
        since: cohortSince,
        till: cohortTill
    });

    const config = {
        method: "POST",
        body: {}
    };

    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function updateCohortAPI(auth, appId, cohortId, since, till) {
    const url = makeDefaultQueryString(UPDATE_COHORT_API, auth, appId) + withQueryStrings({cohortId, since, till});
    const config = {
        method: "POST"
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getCohortsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(GET_COHORTS_API, auth, appId);
    const config = {
        method: "GET",
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}
