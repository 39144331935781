import React from 'react';
import {Cell, Column, Table} from 'fixed-data-table-2';
import Dimensions from 'react-dimensions';
import Box from "./Box";
import IconButton from '@material-ui/core/ButtonBase';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import axios from 'axios';
import {withQueryStrings} from "../../utils";
import {API_BASE, API_ROOT, API_ROOT_V4} from "../../config";
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadButton from "./DownloadButton";
import Snackbar from "./MaterialUi/Snackbar";

const DefaultTextCell = ({rowIndex, data, col, ...props}) => (
    <Cell {...props}>
        {data[rowIndex][col]}
    </Cell>
);

const IndexCell = ({rowIndex, data, col, ...props}) => (
    <Cell {...props}>
        {rowIndex + 1}
    </Cell>
);

const SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
    return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {

    render() {
        const {onSortChange, sortDir, children, ...props} = this.props;
        return (
            <Cell {...props}>
                <span style={{cursor: 'pointer'}} onClick={this._onSortChange}>
                    {children} {sortDir ? (sortDir === SortTypes.DESC ? '↑' : '↓') : ''}
                </span>
            </Cell>
        );
    }

    _onSortChange = (e) => {
        e.preventDefault();

        if (this.props.onSortChange) {
            this.props.onSortChange(
                this.props.columnKey,
                this.props.sortDir ?
                    reverseSortDirection(this.props.sortDir) :
                    SortTypes.DESC
            );
        }
    };

}

class SortableDataTable extends React.Component {
    constructor(props) {
        super(props);
        const {data = []} = props;
        this.state = {
            sortedDataList: data,
            sortKey: null,
            sortOrder: SortTypes.ASC,
            query: "",
            downloadInProgress: false,
            hasEncounteredError: false
        };

    }

    initializeDataList = (data, sortKey = this.state.sortKey, sortOrder = this.state.sortOrder) => {
        this.setState({
            sortedDataList: this.filteredData(this.sortedData(data, sortKey, sortOrder)),
            sortKey,
            sortOrder
        });
    };

    componentWillReceiveProps(nextProps) {
        const {meta: {globalFiltersChanged}} = this.props;
        const {meta, data, needUpdate} = nextProps;
        if (meta.globalFiltersChanged && globalFiltersChanged !== meta.globalFiltersChanged) {
            const {sortKey, sortOrder} = this.state;
            this.initializeDataList(data, sortKey, sortOrder);
        }
        if (needUpdate) { //FIXME: Will be called several times
            this.initializeDataList(data);
        }
    }

    sortedData = (data, sortKey, sortOrder) => {
        if (!sortKey || !sortOrder) {
            return data.slice();
        }
        const defaultComparator = (valueA, valueB, isASC) => {
            let sortVal = 0;
            if (valueA > valueB) {
                sortVal = 1;
            }
            if (valueA < valueB) {
                sortVal = -1;
            }
            if (sortVal !== 0 && isASC) {
                sortVal = sortVal * -1;
            }
            return sortVal;
        };
        const {comparators = {}} = this.props;
        const comparator = comparators[sortKey] || defaultComparator;
        return data.slice().sort((indexA, indexB) => {
            const valueA = indexA[sortKey];
            const valueB = indexB[sortKey];
            return comparator(valueA, valueB, sortOrder === SortTypes.ASC);
        });
    };

    filteredData = (data, query = this.state.query) => {
        return data.filter(o => {
            return Object.values(o).some(term => String(term).toLowerCase().indexOf(query.toLowerCase()) > -1);
        });
    };

    _onSortChange = (sortKey, sortOrder) => {
        this.setState({
            sortedDataList: this.sortedData(this.state.sortedDataList, sortKey, sortOrder),
            sortKey,
            sortOrder
        });
    };

    render() {
        const {
            sortedDataList, sortKey, sortOrder, query, downloadInProgress, hasEncounteredError
        } = this.state;
        const {
            data = [],
            headerLabels = [], keys = [], containerWidth, TextCell = DefaultTextCell,
            withoutBox = false, boxProps = {},
            withoutSearch = false, withoutDownload = false,
            withoutSerialNumbers = false, tableType, downloadReportTitle
        } = this.props;
        const {
            params: {appId},
            session: {user: {email}},
            filters,
            customReport = {},
            selfParams: {
                extraFilters = {},
            } = {},
            selectedDimensions
        } = this.props;

        let rowHeight = 0, height = 0, length = 0;
        if (sortedDataList) {
            length = sortedDataList.length;
            rowHeight = 50;
            height = rowHeight * (length > 10 ? 10 : length + 1);
        }
        if (withoutBox) {
            return (
                <Table
                    {...this.props}
                    rowHeight={rowHeight}
                    rowsCount={length}
                    headerHeight={rowHeight}
                    width={containerWidth - 16}
                    height={height}
                >
                    {!withoutSerialNumbers &&
                    <Column header={<Cell>Sno.</Cell>} cell={<IndexCell data={sortedDataList} col="sno"/>} fixed={true}
                            width={50}/>}
                    {
                        keys.map((key, index) => {
                            return (
                                <Column
                                    key={key}
                                    columnKey={key}
                                    header={
                                        <SortHeaderCell
                                            onSortChange={this._onSortChange}
                                            sortDir={key === sortKey ? sortOrder : null}>
                                            {headerLabels[index]}
                                        </SortHeaderCell>
                                    }
                                    cell={<TextCell data={sortedDataList} col={key}/>}
                                    width={120}
                                    flexGrow={1}
                                />
                            )
                        })
                    }
                </Table>
            )
        } else {
            return (
                <Box
                    {...boxProps}
                    controls={
                        <div style={{display: 'flex', overflow: 'hidden'}}>
                            {
                                !withoutSearch && <TextField
                                    value={query}
                                    onChange={e => {
                                        const query = e.target.value;
                                        this.setState({
                                            query,
                                            sortedDataList: this.sortedData(this.filteredData(data, query))
                                        });
                                    }}
                                    placeholder="Search..."
                                    type="search"
                                />
                            }
                            {
                                (!withoutDownload) &&
                                <DownloadButton
                                    appId={appId}
                                    url={`${API_BASE}${API_ROOT}custom-reports/${tableType}?customerId=${email}&appId=${appId}`
                                    + withQueryStrings({
                                        ...filters,
                                        download: true
                                    })}
                                    method={"POST"}
                                    data={{
                                        metrics: [
                                            "impressions",
                                            "sessions",
                                            "users"
                                        ],
                                        dimensions:
                                            selectedDimensions ||
                                            extraFilters.dimensions ||
                                            customReport.query.dimensions,
                                        filters:
                                            extraFilters.filters ||
                                            customReport.query.filters
                                    }}
                                >
                                    <CloudDownloadIcon color="primary"/>
                                </DownloadButton>
                            }
                        </div>
                    }
                >
                    <Table
                        {...this.props}
                        rowHeight={rowHeight}
                        rowsCount={length}
                        headerHeight={rowHeight}
                        width={containerWidth - 16}
                        height={height}
                    >
                        {!withoutSerialNumbers &&
                        <Column header={<Cell>Sno.</Cell>} cell={<IndexCell data={sortedDataList} col="sno"/>}
                                fixed={true} width={50}/>}
                        {
                            keys.map((key, index) => {
                                return (
                                    <Column
                                        key={key}
                                        columnKey={key}
                                        header={
                                            <SortHeaderCell
                                                onSortChange={this._onSortChange}
                                                sortDir={key === sortKey ? sortOrder : null}>
                                                {headerLabels[index]}
                                            </SortHeaderCell>
                                        }
                                        cell={<TextCell data={sortedDataList} col={key}/>}
                                        width={120}
                                        flexGrow={1}
                                    />
                                )
                            })
                        }
                    </Table>
                </Box>
            )
        }
    }
}

export default Dimensions()(SortableDataTable)