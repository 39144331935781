import React, { Component } from 'react';
import {
    getEventRelatedCount, getSegmentRelatedCount,
    getGlobalRelatedCount
} from '../actions';
import CountBox from "../../../../../../../components/reusable/MaterialUi/CountBox";
import withSelfComponent from './SelfComponent';
import {session} from "../../../../../../../utils/Storage";

class Count extends Component {

    hydrator = (props = {}) => {
        const {
            event, segment, dataKey,
            queryParams, extraFilters
        } = props.selfParams || {};
        const {dispatch, params: {appId}} = props;
        if(segment && segment.length > 0) {
            dispatch(getSegmentRelatedCount(appId, segment, dataKey, queryParams, extraFilters));
            return;
        }
        if(event && event.length > 0){
            dispatch(getEventRelatedCount(appId, event, dataKey, queryParams, extraFilters));
            return;
        }
        dispatch(getGlobalRelatedCount(appId, dataKey, queryParams, extraFilters));
    };

    render(){
        const {
            self, selfParams: { dataKey, customLabel } = {}, parentCompLabel,
            meta: { api_pending }
        } = this.props;
        if(!api_pending){
            let obj = {};
            obj[customLabel] = `${self[dataKey]}`;
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        return <CountBox parentCompLabel={parentCompLabel} subheading={`${customLabel}`} heading={self[dataKey]} />;
    };
}

Count.propTypes = {

};

export default withSelfComponent(Count);