/**
 * Created by Rakesh Peela
 * Date: 01-Jan-2020
 * Time: 1:34 PM
 */
import {callApi} from "../../../../../../../../../../../api";
import {makeDefaultQueryString, withQueryStrings} from "../../../../../../../../../../../utils";

const PREVIEW_API = "ui-config";

export function publishForPreview(appId, auth, data) {
    const url = makeDefaultQueryString(PREVIEW_API, auth, appId);
    const config = {
        method: "POST",
        body: JSON.stringify(data),
        auth
    };

    const onSuccessCallback = response => {
        return response;
    };

    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback, "https://sse.apxor.com/v1/api/");
}