import React from "react";
import PropTypes from 'prop-types';
import {areArraysEqual} from "../../utils";
import MultiSelect from "./MaterialUi/MultiSelect";
import Button from "@material-ui/core/Button/Button";

export default class VersionSelector extends React.Component {

    constructor(props){
        super(props);
        const { value = [] } = props;
        this.state = {
            currentVersions: value,
        };
    }

    handleVersionsUpdate = (newVersions) => {
        this.props.handleUpdate(newVersions);
    };

    handleChange = (versions) => {
        this.setState({currentVersions: versions});
    };

    render() {
        const { versions, margin, value } = this.props;
        const { currentVersions } = this.state;
        const changed = !areArraysEqual(value, currentVersions);
        return (
            <div style={{display: 'flex', minWidth: 220}}>
                <MultiSelect
                    multiple
                    options={versions.map(o => ({value: o, label: o}))}
                    value={currentVersions}
                    handleChange={this.handleChange}
                    placeholder="Select Version"
                    valueRenderer={value => value.length === 0 ? "All Versions" : value.join(", ")}
                    clearable={false}
                    margin={margin}
                />
                {
                    changed && <Button
                        style={{margin: 16, maxHeight: 42}}
                        color="primary"
                        variant="contained"
                        onClick={e => {
                            this.handleVersionsUpdate(currentVersions);
                        }}
                    >Apply</Button>
                }
            </div>
        );
    }
}

VersionSelector.propTypes = {
    versions: PropTypes.array.isRequired,
    handleUpdate: PropTypes.func.isRequired
};
