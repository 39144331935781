/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    CUSTOM_OVERVIEW_CONFIG_PENDING, CUSTOM_OVERVIEW_CONFIG, CUSTOM_OVERVIEW_CONFIG_FAILED,
    CUSTOM_OVERVIEW_UPDATE_CONFIG_PENDING, CUSTOM_OVERVIEW_UPDATE_CONFIG, CUSTOM_OVERVIEW_UPDATE_CONFIG_FAILED,
    DASHBOARDS_PENDING, DASHBOARDS, DASHBOARDS_FAILED,
    DASHBOARDS_ADD_PENDING, DASHBOARDS_ADD, DASHBOARDS_ADD_FAILED,
    DASHBOARDS_DELETE_PENDING, DASHBOARDS_DELETE, DASHBOARDS_DELETE_FAILED,
    DASHBOARDS_UPDATE_PENDING, DASHBOARDS_UPDATE, DASHBOARDS_UPDATE_FAILED, DASHBOARDS_UPDATE_GROUP,
} from './actionTypes';
import {
    getCustomOverviewConfigAPI, updateCustomOverviewConfigAPI,
    dashboardsAPI, saveDashboardAPI,
    deleteDashboardAPI, updateDashboardAPI
} from './api';
import {ALL_USERS} from "../../../../../../constants";

/**
 * @deprecated
 * @param appId
 * @returns {function(*, *)}
 */
export const getCustomOverviewConfig = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                CUSTOM_OVERVIEW_CONFIG_PENDING,
                CUSTOM_OVERVIEW_CONFIG,
                CUSTOM_OVERVIEW_CONFIG_FAILED,
            ],
            payload: {
                promise: getCustomOverviewConfigAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {

            }
        });
    };
};

/**
 * @deprecated
 * @param appId
 * @param config
 * @returns {function(*, *)}
 */
export const updateCustomOverviewConfig = (appId, config) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                CUSTOM_OVERVIEW_UPDATE_CONFIG_PENDING,
                CUSTOM_OVERVIEW_UPDATE_CONFIG,
                CUSTOM_OVERVIEW_UPDATE_CONFIG_FAILED
            ],
            payload: {
                promise: updateCustomOverviewConfigAPI(getState().auth, appId, filters, config)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {

            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(data){
                        setTimeout(() => {
                            dispatch(getCustomOverviewConfig(appId));
                        }, 2000);
                    }
                }
            }
        });
    };
};

export const getDashboards = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                DASHBOARDS_PENDING,
                DASHBOARDS,
                DASHBOARDS_FAILED
            ],
            payload: {
                promise: dashboardsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        res = res.map(dashboard => {
                            return {
                                ...dashboard,
                                config: {
                                    ...dashboard.config,
                                    components: dashboard.config.components
                                        && dashboard.config.components
                                        .filter(x => x.componentId !== "retention-trend")
                                }
                            }
                        })
                        return res;
                    }),
            },
            meta: {

            }
        });
    };
};

export const saveDashboard = (appId, dashboard) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                DASHBOARDS_ADD_PENDING,
                DASHBOARDS_ADD,
                DASHBOARDS_ADD_FAILED
            ],
            payload: {
                promise: saveDashboardAPI(getState().auth, appId, filters, dashboard)
                    .then((res) => {
                        return res || {};
                    }),
            },
            meta: {

            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(data){
                        dispatch(getDashboards(appId));
                    }
                }
            }
        });
    };
};

export const updateDashboard = (appId, dashboard) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        const { _id: dashboardId } = dashboard;
        const { user: { email } } = getState().auth;
        const dashboardWithMetaConfig = {
            ...dashboard, config: {
                ...dashboard.config,
                last_updated: new Date().toJSON(),
                last_updated_by: email
            }
        };
        return dispatch({
            types: [
                DASHBOARDS_UPDATE_PENDING, DASHBOARDS_UPDATE, DASHBOARDS_UPDATE_FAILED
            ],
            payload: {
                promise: updateDashboardAPI(getState().auth, appId, dashboardId, filters, dashboardWithMetaConfig)
                    .then((res) => {
                        return res || {};
                    }),
            },
            meta: {

            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(data){
                        dispatch(getDashboards(appId));
                    }
                }
            }
        });
    };
};

export const deleteDashboard = (appId, dashboardId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                DASHBOARDS_DELETE_PENDING, DASHBOARDS_DELETE, DASHBOARDS_DELETE_FAILED
            ],
            payload: {
                promise: deleteDashboardAPI(getState().auth, appId, dashboardId, filters)
                    .then((res) => {
                        return res || {};
                    }),
            },
            meta: {

            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(data){
                        dispatch(getDashboards(appId));
                    }
                }
            }
        });
    };
};

export const updateCustomDashboardGroup = (group = ALL_USERS) => {
    return (dispatch, getState) => {
        return dispatch({
            type: DASHBOARDS_UPDATE_GROUP,
            payload: group,
            meta: {

            }
        });
    };
};