/**
 * Created by Araja Jyothi Babu on 20-Oct-16.
 */
import React, { Component } from "react";
import { Link } from "react-router";
import ForgotPassword from "./ForgotPassword";
import { initialiseForm, loginUserWithOTP } from "../actions";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Snackbar from "../../../../../components/reusable/MaterialUi/Snackbar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Loading from "../../../../../components/reusable/Loading";
import { isValidEmail } from "../../../../../utils";
import { Checkbox, FormControlLabel } from "@material-ui/core";

function mapStateToProps(state) {
  return {
    initialValues: state.auth.form_initial_data,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    sfaLogin: (data) => dispatch(loginUserWithOTP(data)),
    loadData: (data) => dispatch(initialiseForm(data)),
  };
}

class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      showResetPassword: false,
      disable: false,
      otp: "",
      recovery: false,
    };
  }

  handleUserNameChange = (e) => {
    this.setState({ userName: e.target.value.trim() });
  };

  handleCodeChange = (e) => {
    this.setState({ otp: e.target.value });
  };

  componentDidMount() {
    if (this.props.location.query.user) {
      const data = {
        username: this.props.location.query.user,
        password: "",
      };
      this.props.loadData(data);
      //this.setState({disable: true}); disable facility
    }
  }
  render() {
    const { login, session, checkUser } = this.props;
    const { userName = "", showResetPassword, password = "" } = this.state;
    const {
      login_pending,
      login_failed,
      host,
      host_pending,
      host_failed,
      auth_error_message,
      user,
      login_err,
    } = session;
    const isHostRecognised = !!host;
    return (
      <div style={{ margin: 10 }}>
        <Card elevation={0}>
          <CardHeader title="Login" />
          <CardContent>
            <form
              name="login"
              onSubmit={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                if (user.checkpoint_token) {
                  this.props.sfaLogin({
                    otp: this.state.otp,
                    checkpoint_token: user.checkpoint_token,
                    is_recovery: this.state.recovery,
                  });
                } else {
                  isHostRecognised
                    ? login(userName, password)
                    : checkUser(userName); //form submit based on host recognition
                }
              }}
            >
              <TextField
                id="email"
                value={userName}
                fullWidth
                placeholder="john@company.com"
                required
                onChange={(e) => this.setState({ userName: e.target.value })}
                label="Email"
                margin="dense"
              />
              {isHostRecognised && (
                <TextField
                  id="password"
                  value={password}
                  fullWidth
                  placeholder="P@$$w0rd"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  label="Password"
                  type="password"
                  required
                  margin="dense"
                />
              )}
              {user.checkpoint_token && (
                <>
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      marginTop: 10,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={this.state.recovery}
                          onChange={(checked) => {
                            this.setState({ recovery: !this.state.recovery });
                          }}
                        />
                      }
                      label="Login With Recovery Code"
                    />

                    <TextField
                      variant="outlined"
                      value={this.state.otp}
                      fullWidth
                      placeholder="123 456"
                      onChange={this.handleCodeChange}
                      label={this.state.recovery ? "Recovery Code" : "OTP"}
                      style={{ maxWidth: 150, marginRight: 20 }}
                    />
                  </Grid>
                </>
              )}
              <Grid container spacing={16} style={{ marginTop: 20 }}>
                <Grid item xs={4} xl={4} lg={4} md={4} sm={4}>
                  {!isHostRecognised && (
                    <Button
                      disabled={!isValidEmail(userName)}
                      color="primary"
                      variant="contained"
                      type="submit"
                    >
                      {host_pending && <Loading size={20} />}
                      {!host_pending && <span>Next</span>}
                    </Button>
                  )}
                  {isHostRecognised && (
                    <Button color="primary" variant="contained" type="submit">
                      {login_pending && <Loading size={20} />}
                      {!login_pending && <span>Sign In</span>}
                    </Button>
                  )}
                </Grid>
                <Grid item xs xl lg md sm>
                  {isHostRecognised && (
                    <div>
                      <Typography
                        variant="body2"
                        style={{ cursor: "pointer" }}
                        onClick={(e) =>
                          this.setState({
                            showResetPassword: !showResetPassword,
                          })
                        }
                      >
                        Forgot your password?
                        <br />
                        <Link className="reset-password" to="/logout">
                          Sign in with a different account
                        </Link>
                      </Typography>
                    </div>
                  )}
                </Grid>
              </Grid>
            </form>
            {showResetPassword && <ForgotPassword {...this.props} />}
            {!isHostRecognised && (
              <Typography style={{ marginTop: 10 }}>
                Don't have an account? <Link to="/sign_up">Get Started</Link>
              </Typography>
            )}
            {isHostRecognised && (
              <Typography style={{ marginTop: 10 }}>
                By continuing, you agree to Apxor's <Link href="#">terms</Link>{" "}
                &amp; <Link href="#">privacy</Link>.
              </Typography>
            )}
          </CardContent>
        </Card>
        {login_failed && (
          <Snackbar>
            <strong>{auth_error_message}</strong>
          </Snackbar>
        )}
        {login_err !== "" && (
          <Snackbar>
            <strong>{login_err}</strong>
          </Snackbar>
        )}
        {host_failed && (
          <Snackbar>
            <strong>Doesn't recognize this user..!</strong>
          </Snackbar>
        )}
      </div>
    );
  }
}

LoginForm = connect(
  mapStateToProps,
  mapDispatchToProps // bind account loading action creator
)(LoginForm);

export default LoginForm;
