/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 3:22 PM
 */

import {IconButton, InputAdornment, TextField, Tooltip, Typography} from "@material-ui/core";
import ExtensionIcon from '@material-ui/icons/Extension';
import PropTypes from 'prop-types';
import React from 'react';
import MacrosDialog from './modules/MacrosDialog';

class ActionsTextField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showMacroSelector: false,
            caretPos: 0,
            value: props.value || ""
        };
    }

    hideMacroSelector = () => {
        this.setState({
            showMacroSelector: false,
        });
    };

    showMacroSelector = () => {
        this.setState({showMacroSelector: true});
    };

    onPropertySelectorDone = (propertyType, property, defaultValue) => {
        const value = this.state.value;
        const caretPos = this.state.caretPos;
        const macro = ` [${propertyType}_${property}(${defaultValue})] `;
        // Insert macro where the caret's last position'
        const updatedValue = value.slice(0, caretPos) + macro + value.slice(caretPos);
        this.setState({value: updatedValue});
        this.props.onChange({target: {value: updatedValue}});
        this.hideMacroSelector();
    };

    onChange = (event) => {
        this.setState({value: event.target.value});
        this.updateCaret(event);
        this.props.onChange(event);
    };

    updateCaret = (event) => {
        if (event.target.selectionStart !== null) {
            this.setState({caretPos: event.target.selectionStart});
        }
    };

    render() {
        const {
            style,
            withPropertySelector = false,
            userProperties = [],
            sessionProperties = [],
            eventProperties = [],
            InputProps = {},
            multiline = false,
            onChange, // TODO: find a better fix, we simply don't want onChange to end up in otherProps.
            ...otherProps
        } = this.props;

        let selectorProps = {};

        if (withPropertySelector) {
            selectorProps = {
                endAdornment: (
                    <InputAdornment position="end">
                        <Tooltip title={"Macros"}>
                            <IconButton
                                style={{padding: 8}}
                                aria-label="Insert macros"
                                onClick={this.showMacroSelector}
                            >
                                <ExtensionIcon fontSize={"small"}/>
                            </IconButton>
                        </Tooltip>
                    </InputAdornment>
                ),
            }
        }

        return (
            <div style={{marginBottom: 24, ...style}}>
                <TextField
                    inputProps={{
                        style: {padding: 8},
                        maxLength: otherProps.maxLength,
                        ...InputProps
                    }}
                    InputProps={{
                        style: {padding: multiline ? 8 : 0},
                        ...InputProps,
                        ...selectorProps,
                    }}
                    fullWidth
                    multiline={multiline}
                    type={this.props.type}
                    margin="dense"
                    variant="outlined"
                    style={{margin: 0}}
                    required={otherProps.required}
                    value={this.state.value}
                    onChange={this.onChange}
                    onSelect={this.updateCaret}
                    {...otherProps}
                />
                {withPropertySelector && this.state.showMacroSelector && <MacrosDialog
                    onDone={this.onPropertySelectorDone}
                    onCancel={this.hideMacroSelector}
                    userProperties={userProperties}
                    sessionProperties={sessionProperties}
                    eventProperties={eventProperties}
                />}
                {
                    withPropertySelector
                    && <Typography variant={"subtitle2"} style={{color: "#696969", padding: "2px 4px"}}>
                        Note: Defaults for Macros supports only Alpha Numeric string
                    </Typography>
                }
            </div>
        );
    }
}

ActionsTextField.propTypes = {
    placeholder: PropTypes.string,
};

export default ActionsTextField;