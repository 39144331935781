/**
 * Created by Araja Jyothi Babu on 03-Nov-16.
 */
import React from 'react';
import { Table, Column, Cell } from 'fixed-data-table-2';
import Dimensions from 'react-dimensions';
import SortedData from '../../../../../../../../components/reusable/SortedData';
import {SortHeaderCell, SortTypes } from '../../../../../../../../components/reusable/SortHeaderCell';
import Placeholder from '../../../../../../../../components/reusable/Placeholder';
import Loading from '../../../../../../../../components/reusable/Loading';
import {indexSorter, roundOffNumber, formatTime } from '../../../../../../../../utils'
import {SortUtils, APXOR_SDK_CATEGORY_CONSTANTS} from '../../../../../../../../constants';
import Snackbar from '@material-ui/core/Snackbar';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import Chip from '@material-ui/core/Chip';
import Apxor from 'apxor';
const TextCell = ({rowIndex, data, col, ...props}) => (
    <Cell {...props} onClick={() => props.onClick(data[rowIndex])} style={{cursor: "pointer"}}>
        { data[rowIndex][col] }
    </Cell>
);

const FloatCell = ({rowIndex, data, col, ...props}) => (
    <Cell {...props} onClick={() => props.onClick(data[rowIndex])} style={{cursor: "pointer"}}>
        { data[rowIndex][col] === 0 ? "NA" : roundOffNumber(data[rowIndex][col]) }
    </Cell>
);

export function sortSessionList(
    sortedDataList,
    columnKey,
    sortDir,
    SortTypes
  ) {
    return sortedDataList.sort((elem1, elem2) => {
      let valueA = elem1[columnKey];
      let valueB = elem2[columnKey];
      let sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }
      if (sortVal !== 0 && sortDir === SortTypes.ASC) {
        sortVal = sortVal * -1;
      }
      return sortVal;
    });
  }
  

const IS_FIRST_SESSION_KEY = "is_first_session";
const IS_UNINSTALL_BEFORE_SESSION_KEY = "is_uninstall_before_session";

const TimeCell = ({rowIndex, data, col, ...props}) => (
    <Cell {...props} onClick={() => props.onClick(data[rowIndex])} style={{cursor: "pointer"}}>
        <span style={{display: 'flex'}}>{ formatTime(data[rowIndex][col]) }
            <span style={{marginLeft: '10px'}}>
                { data[rowIndex][IS_FIRST_SESSION_KEY] && <Chip label="FIRST SESSION" /> }
                { data[rowIndex][IS_UNINSTALL_BEFORE_SESSION_KEY] && <Chip label="UNINSTALL SESSION" /> }
            </span>
        </span>
    </Cell>
);

//FIXME: highlight row on click
class SessionsDataTable extends React.Component{

    constructor(props){
        super(props);
        let {session_list, meta_info} = this.props.data;
        this.state = {
            sortedDataList: session_list,
            colSortDirs: {
                time : SortTypes.ASC
            },
            isEmptySession: false
        };

        this._onSortChange = this._onSortChange.bind(this);
    }

    _onSortChange(columnKey, sortDir) {
        const {sortedDataList} = this.state;

        this.setState({
            sortedDataList: sortSessionList([...sortedDataList], columnKey, sortDir, SortTypes),
            colSortDirs: {[columnKey]: sortDir}
        });
    }

    componentWillReceiveProps(nextProps){
        const { data: {session_list, meta_info} } = nextProps;
        this.setState({
            sortedDataList: sortSessionList([...session_list], "time", SortTypes.ASC, SortTypes),
        });
    }

    handleRowClick(rowData){
        if(rowData.navigation_count === 0 && rowData.events === 0){
            this.setState({isEmptySession: true});
        }else {
            Apxor.logEvent("SelectSession", {session_time: rowData.length, navigation_count: rowData.navigation_count, event_count: rowData.events}, APXOR_SDK_CATEGORY_CONSTANTS.USER_SEGMENTS);
            this.props.onSessionClick(rowData.session_id, rowData.time, rowData); //sending session id and session start time
            this.setState({isEmptySession: false});
        }
    }

    handleRequestClose = () => {
        this.setState({isEmptySession: false});
    };

    render(){
        const {sortedDataList, colSortDirs} = this.state;
        const {sessions_pending, sessions_failed} = this.props;
        let rowHeight = 0, height = 100,  length = 0;
        rowHeight = 50;
        if(sortedDataList) {
            length = sortedDataList.length;
            height = rowHeight * (length > 10 ? 10 : length + 1);
        }
        return (
            <div>
                {sessions_failed && <Placeholder height={200}/>}
                <Table
                    rowHeight={rowHeight}
                    headerHeight={rowHeight}
                    rowsCount={length}
                    width={this.props.containerWidth || 800}
                    height={height}
                    {...this.props}>
                    <Column fixed={true} columnKey="time" header={<SortHeaderCell
                        columnKey="time"
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.time}>
                        Time
                    </SortHeaderCell>} cell={<TimeCell onClick={this.handleRowClick.bind(this)} data={sortedDataList} col="time" />} width={350}/>
                    <Column flexGrow={1} columnKey="length" header={<SortHeaderCell
                        columnKey="length"
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.length}>
                        Duration <span className="small">(Secs)</span>
                    </SortHeaderCell>} cell={<FloatCell onClick={this.handleRowClick.bind(this)} data={sortedDataList} col="length" />} width={100}/>
                    <Column flexGrow={1} columnKey="navigation_count" header={<SortHeaderCell
                        columnKey="navigation_count"
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.navigation_count}>
                        Navigations
                    </SortHeaderCell>} cell={<TextCell onClick={this.handleRowClick.bind(this)} data={sortedDataList} col="navigation_count" />} width={100}/>
                    <Column flexGrow={1} columnKey="events" header={<SortHeaderCell
                        columnKey="events"
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.events}>
                        Events
                    </SortHeaderCell>} cell={<TextCell onClick={this.handleRowClick.bind(this)} data={sortedDataList} col="events" />} width={100}/>
                    <Column flexGrow={1} columnKey="crashes" header={<SortHeaderCell
                        columnKey="crashes"
                        onSortChange={this._onSortChange}
                        sortDir={colSortDirs.crashes}>
                        Crashes/Hangs
                    </SortHeaderCell>} cell={<TextCell onClick={this.handleRowClick.bind(this)} data={sortedDataList} col="crashes" />} width={100}/>
                </Table>
                {sessions_pending && <Loading/>}
                <Snackbar
                    open={this.state.isEmptySession}
                    message="Timeline not available for Empty Session..!"
                    autoHideDuration={4000}
                    onRequestClose={this.handleRequestClose}
                />
            </div>
        );
    }

}

export default Dimensions()(SessionsDataTable);