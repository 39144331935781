/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import {Tab, Tabs} from "@material-ui/core";
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {updateHeading, resetHeading} from '../../Navigation/actions';
import {getCohorts, createCohortFromSegment, updateCohort} from "../Cohorts/actions";
import {
    loadSegmentData, resetCorrelations,
    updateSegments, deleteSegment, updateSegmentAttributeFilters,
    updateSegmentUserGroup, getSegmentUserAttributeDistribution, getSegmentSessionAttributeDistribution,
    updateSegmentGroupByAttribute, getSegmentEventAttributeDistribution,
    getSegmentCurrentEventAttributes, updateSegmentCurrentEvent, getSegmentList
} from './actions';
import {
    SegmentsHolder
} from './components';
import {getMessages} from "../Messages/actions";
import {updateQuery} from "./NewSegment/actions";

function mapStateToProps(state) {
    return {
        appState: state.app,
        cohorts: state.cohorts,
        filters: state.filters,
        messages: state.messages.messages,
        meta: state.meta,
        queryBuilder: state.queryBuilder,
        router: state.routing,
        segment: state.segment,
        session: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetHeading: () => dispatch(resetHeading()),
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        loadData: (appId, segmentId, segmentName, isThroughFilters = false) => dispatch(loadSegmentData(appId, segmentId, segmentName, isThroughFilters)),
        getSegments: (appId, segmentId) => dispatch(getSegmentList(appId, segmentId)),
        deleteSegment: (appId, segmentId) => dispatch(deleteSegment(appId, segmentId)),
        resetData: () => dispatch(resetCorrelations()),
        getCohorts: (appId, filters) => dispatch(getCohorts(appId, filters)),
        createCohortFromSegment: (appId, segmentId, since, till) => dispatch(createCohortFromSegment(appId, segmentId, since, till)),
        updateCohort: (appId, cohortId, since, till) => dispatch(updateCohort(appId, cohortId, since, till)),
        updateSegmentList: (appId) => (list) => dispatch(updateSegments(appId, list)),
        updateSegmentAttributeFilters: (filters) => dispatch(updateSegmentAttributeFilters(filters)),
        getSegmentUserAttributeDistribution: (appId, segmentId, attribute) =>
            dispatch(getSegmentUserAttributeDistribution(appId, segmentId, attribute)),
        getSegmentSessionAttributeDistribution: (appId, segmentId, attribute) =>
            dispatch(getSegmentSessionAttributeDistribution(appId, segmentId, attribute)),
        getSegmentEventAttributeDistribution: (appId, segmentId, attribute) =>
            dispatch(getSegmentEventAttributeDistribution(appId, segmentId, attribute)),
        updateSegmentUserGroup: (appId, segmentId, group) => {
            dispatch(updateSegmentUserGroup(group));
            dispatch(loadSegmentData(appId, segmentId, null, true));
        },
        updateSegmentGroupByAttribute: (appId, attribute) => {
            dispatch(updateSegmentGroupByAttribute(appId, attribute));
        },
        updateSegmentCurrentEvent: (appId, attribute) => {
            dispatch(updateSegmentCurrentEvent(appId, attribute));
        },
        getSegmentCurrentEventAttributes: (appId, event) => dispatch(getSegmentCurrentEventAttributes(appId, event)),
        getMessages: (appId) => dispatch(getMessages(appId)), //FIXME: need better way to get messages
        updateSegmentQuery: (query) => dispatch(updateQuery(query)),
        bindedDispatch: dispatch
    };
}

class Correlations extends Component {

    constructor(props){
        super(props);
        this.appId = this.props.params.appId;
        this.state = {
            segmentEvents: [],
            tabValue: 0
        };
        //this.segmentId = this.something.segmentId;
    }

    componentWillMount(){
        this.props.updateHeading("Segments");
        const { params: { appId, segmentId }, filters, getSegments, getMessages, getCohorts } = this.props;
        getSegments(appId, segmentId);
        getMessages(appId);
        getCohorts(appId, filters);
    }

    componentWillReceiveProps(nextProps){
        //
    }

    componentWillUnmount(){
        //this.props.resetData();
    }

    render() {
        const {tabValue} = this.state;
        return (
            <section className="content">
                <SegmentsHolder {...this.props}/>
            </section>
        );
    }
}

Correlations.propTypes = {

};

Correlations.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Correlations);