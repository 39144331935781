/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading } from '../../../Navigation/actions';
import { ResultsHolder } from './components';
import {
    surveyResults,
    surveyResultDistributions,
    getSurveys,
    resetSurveys,
    surveyResultForOtherOption
} from "../actions";
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import BrokenImage from "@material-ui/icons/BrokenImage";
import {Link} from "react-router";


function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        filters: state.filters,
        meta: state.meta,
        surveys: state.surveys
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        surveyResults: (...args) => dispatch(surveyResults(...args)),
        surveyResultDistributions: (...args) => dispatch(surveyResultDistributions(...args)),
        surveyResultForOtherOption: (...args) => dispatch(surveyResultForOtherOption(...args)),
        getSurveys: (...args) => dispatch(getSurveys(...args)),
        resetSurveys: () => dispatch(resetSurveys())
    };
}

class SurveyResults extends Component {

    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentWillMount(){
        const {
            updateHeading,
            surveys: { survey: { meta: { name } = {}} = { meta: {name: "Unknown"} } }
        } = this.props;
        updateHeading(`Survey Results: ${name}`);
        this.updateSurvey(this.props);
        this.hydrate(this.props);
    }

    updateSurvey(props = this.props){
        const {
            params: { appId, surveyId },
            getSurveys,
            surveys: { surveys = [] }
        } = this.props;
        if(surveys.length === 0){
            getSurveys(appId, surveyId);
        }
    }

    hydrate = (props = this.props) => {
        const {
            params: { appId, surveyId },
            surveyResults, surveyResultDistributions
        } = props;
        surveyResults(appId, surveyId);
        //surveyResultDistributions(appId, surveyId);
    };

    componentWillReceiveProps(nextProps){
        const { surveys: { survey: { meta: {name: oldName} = {}} = { meta: {name: "Unknown"} } } } = this.props;
        const { surveys: { survey: { meta: {name} = {}} = { meta: {name: "Unknown"} } } } = nextProps;
        if(oldName !== name){
            this.props.updateHeading(`Survey Results: ${name}`);
        }
        const { meta } = nextProps;
        //reloading data for updated filters
        const { meta: { globalFiltersChanged }  } = this.props;
        if(meta.globalFiltersChanged && globalFiltersChanged !== meta.globalFiltersChanged){
            this.hydrate(nextProps);
        }
    }

    componentWillUnmount(){
        this.props.resetSurveys();
    }

    render() {
        const {
            params: {appId},
            meta, surveys: { surveys, survey: { meta: surveyMeta }, results }
        } = this.props;
        return (
            <section className="content">
                {
                    !meta.api_pending && (surveys.length === 0 || !surveyMeta || !Array.isArray(results.data)) ?
                    <Placeholder
                        withIcon
                        icon={<BrokenImage style={{opacity: 0.2, width: 150, height: 150}} />}
                        text={
                            (surveys.length === 0 || !surveyMeta) ? <span>Not found what you are looking for.
                                <Link to={`/apps/${appId}/surveys`}> Try coming from surveys</Link></span>
                                : <span>Survey results seems broken. May be edited after publishing?.</span>
                        }
                    /> : <ResultsHolder {...this.props}/>
                }
            </section>
        );
    }
}

SurveyResults.propTypes = {

};

SurveyResults.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SurveyResults);