/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React, {Component, } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { Link } from 'react-router';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography"

function mapStateToProps(state) {
    return {
        session: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
    };
}

class Error404 extends Component {
    static propTypes = {
        children: PropTypes.object,
        location: PropTypes.object,
    };

    render(){
        return (
            <section className="content">
                <Grid container style={{marginTop: "150px"}} spacing={24}>
                    <Grid item xs={4}>
                        <Typography variant="display4" color="secondary"> 404</Typography>
                    </Grid>
                    <Grid item xs>
                        <div>
                            <Typography variant="h5"><i className="fa fa-warning text-yellow"></i> Oops! Page not found.</Typography>
                            <Typography variant="h6">We could not find the page you were looking for.</Typography>
                            <Typography variant="subtitle1">Meanwhile, you may <Link to="/">return to dashboard</Link></Typography>
                        </div>
                    </Grid>
                </Grid>
            </section>
        );
    }
}

Error404.contextTypes = {
    router: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Error404);