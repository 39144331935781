/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

/**
 * Resets all filters
 * @type {string}
 */
export const RESET_FILTERS = "RESET_FILTERS";

/**
 * Global Filters
 * @type {string}
 */
export const UPDATE_GLOBAL_VERSIONS = "UPDATE_GLOBAL_VERSIONS";
export const UPDATE_GLOBAL_MODES = "UPDATE_GLOBAL_MODES";
export const UPDATE_GLOBAL_DATE_TIME = "UPDATE_GLOBAL_DATE_TIME";

/**
 * User Group Filters used by more than one components
 * @type {{acquisition_sources: Array, custom_keys: null, app_versions: Array, devices: Array, os_versions: Array}}
 */
export const USER_GROUP_FILTERS_CONSTANTS = {
    app_versions: "app_versions",
    devices: "devices",
    os_versions: "os_versions",
    locations: "locations",
    languages: "languages",
    acquisition_sources: "acquisition_sources",
    custom_keys: "custom_keys"
};

/**
 * USER Group Filters
 * @type {string}
 */
export const UPDATE_USER_GROUP = "UPDATE_USER_GROUP";
export const RESET_USER_GROUP = "RESET_USER_GROUP";
export const UPDATE_USER_GROUP_ID = "UPDATE_USER_GROUP_ID";
export const UPDATE_USER_GROUP_NAME = "UPDATE_USER_GROUP_NAME";
export const UPDATE_USER_GROUP_TYPE = "UPDATE_USER_GROUP_TYPE";
export const UPDATE_USER_GROUP_ACQUISITION_SOURCES = "UPDATE_USER_GROUP_ACQUISITION_SOURCES";
export const UPDATE_USER_GROUP_CUSTOM_KEYS = "UPDATE_USER_GROUP_CUSTOM_KEYS";
export const UPDATE_USER_GROUP_APP_VERSIONS = "UPDATE_USER_GROUP_APP_VERSIONS";

/**
 * USER List Filters
 * @type {string}
 */
export const UPDATE_PAGINATION_PAGE = "UPDATE_PAGINATION_PAGE";
export const UPDATE_PAGINATION_ORDER_BY = "UPDATE_PAGINATION_ORDER_BY";
export const UPDATE_PAGINATION_SORT_ORDER = "UPDATE_PAGINATION_SORT_ORDER";
export const UPDATE_PAGINATION_COUNT = "UPDATE_PAGINATION_COUNT";

/**
 * Path Filters
 * @type {string}
 */
export const UPDATE_PATH_ID = "UPDATE_PATH_ID";

/**
 * Session Group Id
 */
export const UPDATE_SESSION_GROUP_ID = "UPDATE_SESSION_GROUP_ID";

/**
 * Incident Type
 * @type {string}
 */

export const UPDATE_SOURCE_USER_GROUP_TYPE = "UPDATE_SOURCE_USER_GROUP_TYPE";

/**
 *
 * @type {string}
 */
export const UPDATE_SELECTED_PATH_LIST = "UPDATE_SELECTED_PATH_LIST";

/**
 *
 * @type {string}
 */
//export const UPDATE_PATH_NAME = "UPDATE_PATH_NAME";

export const RESET_PATH_FILTERS = "RESET_PATH_FILTERS";

export const UPDATE_CUSTOM_GROUPING_EVENTS = "UPDATE_CUSTOM_GROUPING_EVENTS";

export const UPDATE_COHORT_FILTER = "UPDATE_COHORT_FILTER";

export const UPDATE_OPERATOR = "UPDATE_OPERATOR";

export const UPDATE_SEARCH_STRING = "UPDATE_SEARCH_STRING";