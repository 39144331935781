/**
 * Created by jyothi on 14/1/17.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getNotifications, saveNotification, deleteNotification, updateNotification,
    resetNotifications, sendNotification
} from './actions';
import {updateHeading} from '../../Navigation/actions';
import {
    NotificationsWrapper
} from './components/components';
import {getAttributes, getAttributeValues, resetQuery, updateQuery} from "../Segments/NewSegment/actions";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        meta: state.meta,
        notification: state.notification,
        segmentBuilder: state.segmentBuilder,
        filters: state.filters,
        push: state.push
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        resetNotifications: () => dispatch(resetNotifications()),
        getNotifications: (appId) => dispatch(getNotifications(appId)),
        saveNotification: (appId, data) => dispatch(saveNotification(appId, data)),
        sendNotification: (appId, notificationId) => dispatch(sendNotification(appId, notificationId)),
        updateNotification: (appId, notificationId, data) => dispatch(updateNotification(appId, notificationId, data)),
        deleteNotification: (appId, notificationId) => dispatch(deleteNotification(appId, notificationId)),
        bindedDispatch: dispatch,
        //For User and Session Attributes
        getAttributes: (appId) => dispatch(getAttributes(appId)),
        getAttributeValues: (appId, attribute, q) => dispatch(getAttributeValues(appId, attribute, q)),
        handleQueryUpdate: (query) => dispatch(updateQuery(query)),
        resetQuery: () => dispatch(resetQuery())
    };
}

class Notifications extends Component {

    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentWillMount(){
        const {appId} = this.props.params;
        const { updateHeading, getAttributes, getNotifications } = this.props;
        updateHeading("Push Notifications");
        getNotifications(appId); //for Configurator
        getAttributes(appId);
    }

    componentWillReceiveProps(nextProps){

    }

    componentWillUnmount(){
        this.props.resetNotifications();
        this.props.resetQuery(); //resetting segmentBuilder
    }

    render() {
        return (
            <section className="content">
                <NotificationsWrapper {...this.props}/>
            </section>
        );
    }
}

Notifications.propTypes = {

};

Notifications.contextTypes = {
    router: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notifications);
