/**
 * Created by Rakesh Peela
 * Date: 09-Nov-2019
 * Time: 11:12 AM
 */

import {Button, Card, Grid, IconButton, Tooltip, Typography, withStyles} from '@material-ui/core';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Snackbar from '@material-ui/core/Snackbar';
import BarChartIcon from '@material-ui/icons/BarChart';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import SendIcon from '@material-ui/icons/Send';
import Apxor from 'apxor';
import _ from 'lodash';
import moment from 'moment'
import PropTypes from 'prop-types';
import React from 'react';
import {Link} from 'react-router';
import Loading from "../../../../../../../../components/reusable/Loading";
import StatusIdentifier from "../../../../../../../../components/reusable/StatusIdentifier";
import {APP_PLATFORMS, APXOR_SDK_CATEGORY_CONSTANTS} from '../../../../../../../../constants';
import {deleteMessage, publishMessage, toggleMessage} from "../actions";
import {RESET_STATUS_MESSAGE} from "../actionTypes";
import {SECTIONS_ENUM} from "../utils";
import CampaignSummary from "./Flow/pages/CampaignSummary";
import {PLATFORM_IMAGES} from "./Flow/utils";

const STATUS_PALETTE = {
    [SECTIONS_ENUM.ACTIVE]: {label: SECTIONS_ENUM.ACTIVE, color: "#0eb796"},
    [SECTIONS_ENUM.EXPIRED]: {label: SECTIONS_ENUM.EXPIRED, color: "#9e9e9e"},
    [SECTIONS_ENUM.PAUSED]: {label: SECTIONS_ENUM.PAUSED, color: "#ef5350"},
    [SECTIONS_ENUM.SCHEDULED]: {label: SECTIONS_ENUM.SCHEDULED, color: "#fb8c00"},
    [SECTIONS_ENUM.DRAFTS]: {label: SECTIONS_ENUM.DRAFTS, color: "#039be5"},
};

class DialogBox extends React.Component {
    render() {
        const {showDialog, message, positiveHandler, negativeHandler, positiveWord, negativeWord, withActions = true, dialogProps, extraInfo} = this.props;
        return (
            <div>
                <Dialog
                    open={showDialog}
                    onClose={negativeHandler}
                    {...dialogProps}
                >
                    <DialogContent>
                        {message}
                    </DialogContent>
                    {
                        withActions
                        && <DialogActions style={{display: "flex", justifyContent: "space-between"}}>
                            <div>
                                {
                                    extraInfo && <Typography style={{paddingLeft: 12}}>
                                        Campaign Status: <StatusIdentifier
                                        statusType={extraInfo}
                                        palette={STATUS_PALETTE}
                                    />
                                    </Typography>
                                }
                            </div>
                            <div>
                                {
                                    negativeHandler
                                    && <Button onClick={negativeHandler}>
                                        {negativeWord ? negativeWord : "Cancel"}
                                    </Button>
                                }
                                {
                                    positiveHandler
                                    && <Button onClick={positiveHandler} color="primary">
                                        {positiveWord ? positiveWord : "Ok"}
                                    </Button>
                                }
                            </div>
                        </DialogActions>
                    }
                </Dialog>
            </div>
        );
    }
}

DialogBox.propTypes = {
    message: PropTypes.object,
    showDialog: PropTypes.bool,
    positiveHandler: PropTypes.func,
    negativeHandler: PropTypes.func
};

const styles = (theme) => ({
    root: {
        width: "100%",
        minHeight: 72,
        borderRadius: 10,
        border: "1px solid #e4eaec",
        padding: "8px 16px 4px 16px",
        marginBottom: 6,
        display: "flex",
        alignItems: "center",
        boxShadow: "rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.10) 0px 1px 1px 0px"
    }
});

const INITIAL_STATE = {
    alertMessage: null,
    showDialog: false,
    positiveFunction: null,
    positiveWord: "Ok",
    negativeFunction: null,
    negativeWord: "Cancel",
};

class ActionIconHolder extends React.Component {
    render() {
        const {children} = this.props;
        return (
            <div style={{margin: "0 2px"}}>
                {children}
            </div>
        );
    }
}

const getColorCodeForPlatform = (platform) => {
    switch (platform) {
        case APP_PLATFORMS.android:
            return "#3DDC84";
        case APP_PLATFORMS.ios:
            return "#7D7D7D";
        default:
            return null;
    }
};

class ClassicHolderCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...INITIAL_STATE,
            showSnackbar: false,
            snackBarMessage: "",
            showCampaignInfo: false
        }
    }

    handleSnackbarClosed = () => {
        this.setState({
            showSnackbar: false
        }, () => {
            this.props.dispatch({type: RESET_STATUS_MESSAGE, payload: null})
        })
    };

    handleShowCampaignInfo = () => {
        this.setState({
            showCampaignInfo: !this.state.showCampaignInfo
        })
    }

    render() {
        const {classes, message, statusType, appId, dispatch, progressState, basicMetrics: {shown, engaged}, appPlatform, segments, cohorts, testDevices} = this.props;
        const {alertMessage, showDialog, positiveFunction, positiveWord, negativeFunction, negativeWord, showCampaignInfo} = this.state;
        const {meta: {name = "", platform}, track = {}, enabled} = message;

        const startDate = message.validity.start_date;
        const endDate = message.validity.end_date;

        const canPublish = (statusType === SECTIONS_ENUM.DRAFTS);
        const canPause = (statusType === SECTIONS_ENUM.ACTIVE || statusType === SECTIONS_ENUM.SCHEDULED || (statusType === SECTIONS_ENUM.DRAFTS && enabled));
        const canEdit = !message.published;
        const canResume = ((statusType === SECTIONS_ENUM.DRAFTS && !enabled) || statusType === SECTIONS_ENUM.PAUSED);
        const canAnalyse = (statusType !== SECTIONS_ENUM.DRAFTS) && (statusType !== SECTIONS_ENUM.SCHEDULED) && (message.uis.length > 0);
        const createTimestamp = track.created_at && moment(track.created_at).format("LLL");
        const updateTimestamp = track.updated_at && moment(track.updated_at).format("LLL");

        return (
            <Card className={classes.root} elevation={0} onClick={(e) => {
                e.stopPropagation();

            }}>
                <Grid spacing={8} container justify={"flex-start"} alignItems={"center"}>
                    <Grid item xs={1} sm={1}>
                        <StatusIdentifier
                            disabled={!enabled}
                            statusType={statusType}
                            palette={STATUS_PALETTE}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={statusType !== SECTIONS_ENUM.DRAFTS ? 4 : (appPlatform === APP_PLATFORMS.omni ? 5 : 6)}
                        style={{textAlign: "left"}}
                    >
                        <Button variant={"text"} size={"small"}
                                style={{
                                    textTransform: "none",
                                    paddingLeft: 4,
                                    fontWeight: 800,
                                    display: "inline-block"
                                }}
                                component={Typography}
                                onClick={this.handleShowCampaignInfo}
                        >
                            <div style={{textDecoration: "underline"}}>{name}</div>
                        </Button>
                        {
                            track.created_at && !track.updated_at &&
                            <Typography variant={"caption"} style={{color: "#a6b9ca"}}>
                                Created: {track.created_at && createTimestamp || ""}
                            </Typography>
                        }
                        {
                            track.created_at && track.updated_at &&
                            <Typography variant={"caption"} style={{color: "#a6b9ca"}}>
                                Last updated: {track.updated_at && updateTimestamp || ""}
                            </Typography>
                        }
                    </Grid>
                    {
                        appPlatform === APP_PLATFORMS.omni
                        && <Grid item xs={1} style={{textAlign: "center"}}>
                            {PLATFORM_IMAGES(platform, getColorCodeForPlatform(platform), 22)}
                        </Grid>
                    }
                    {
                        statusType !== SECTIONS_ENUM.DRAFTS && <>
                            <Grid item xs={1} sm={1} style={{textAlign: "center"}}>
                                <Typography variant={"subtitle1"} gutterBottom>
                                    {shown}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sm={1} style={{textAlign: "center"}}>
                                <Typography variant={"subtitle1"} gutterBottom>
                                    {engaged}
                                </Typography>
                            </Grid>
                        </>
                    }
                    <Grid item xs={1} sm={1} style={{textAlign: "left"}}>
                        <Typography variant={"subtitle1"} gutterBottom title={moment(startDate).format("LLL")}>
                            {moment(startDate).format("DD MMM YYYY")}
                        </Typography>
                    </Grid>
                    <Grid item xs={1} sm={1} style={{textAlign: "left"}}>
                        <Typography variant={"subtitle1"} gutterBottom title={moment(endDate).format("LLL")}>
                            {moment(endDate).format("DD MMM YYYY")}
                        </Typography>
                    </Grid>

                    <Grid item style={{display: "flex", alignItems: "center"}}>
                        {/* Edit Message Start */}
                        {canEdit && <ActionIconHolder>
                            <Tooltip title={"Edit"}>
                                <Link
                                    to={{pathname: `/apps/${appId}/walkthroughs/${message._id}/edit`, state: message}}>
                                    <IconButton>
                                        <EditIcon fontSize={"small"}/>
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </ActionIconHolder>}
                        {/* Edit Message End */}

                        {/* Analyse Message Start */}
                        {canAnalyse && <ActionIconHolder>
                            <Tooltip title={"Campaign Analytics"}>
                                <div>
                                    <Link to={{
                                        pathname: `/apps/${appId}/walkthroughs/${message._id}/analysis`,
                                        state: {...message, statusType}
                                    }}>
                                        <IconButton color="secondary" aria-label="Analysis"
                                                    disabled={statusType === SECTIONS_ENUM.SCHEDULED}
                                                    onClick={() => {
                                                    }}>
                                            <BarChartIcon color="primary"/>
                                        </IconButton>
                                    </Link>
                                </div>
                            </Tooltip>
                        </ActionIconHolder>}
                        {/* Analyse Message End */}

                        {/* Publish Message Start */}
                        {
                            canPublish && <ActionIconHolder>
                                <Tooltip title={"Publish"}>
                                    <IconButton
                                        onClick={() => {
                                            this.setState({
                                                alertMessage: <p>
                                                    Are you sure you want to Publish
                                                    "<b>{message.meta.name}</b>" which
                                                    is {statusType === SECTIONS_ENUM.DRAFTS ? "Draft" : statusType}?
                                                </p>,
                                                positiveFunction: () => {
                                                    dispatch(publishMessage(appId, message._id, message));
                                                    Apxor.logEvent("PublishCampaign", {name: message.meta.name}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS)
                                                    this.setState({
                                                        showDialog: false
                                                    });
                                                },
                                                positiveWord: "Publish",
                                                negativeFunction: () => {
                                                    this.setState({
                                                        ...INITIAL_STATE
                                                    })
                                                },
                                                negativeWord: "Cancel",
                                                showDialog: true,
                                            })
                                        }}
                                    >
                                        <SendIcon fontSize={"small"} color={"primary"}/>
                                    </IconButton>
                                </Tooltip>
                                {progressState.actingID === message._id && progressState.message_publish_pending &&
                                <Loading size={24}/>}
                            </ActionIconHolder>
                        }
                        {/* Publish Message End */}

                        {/* Pause Message Start */}
                        {canPause && <ActionIconHolder>
                            {progressState.actingID !== message._id &&
                            <Tooltip title={"Pause"}>
                                <IconButton onClick={() => {
                                    this.setState({
                                        alertMessage: <p>Are you sure you want to Pause "<b>{message.meta.name}</b>"
                                            which
                                            is {statusType === SECTIONS_ENUM.DRAFTS ? "Draft" : statusType}?</p>,
                                        positiveFunction: () => {
                                            Apxor.logEvent("PauseCampaignClicked", {message: message.meta.name}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
                                            dispatch(toggleMessage(appId, message._id, !enabled));
                                            this.setState({
                                                showDialog: false
                                            });
                                        },
                                        positiveWord: "Ok",
                                        negativeFunction: () => {
                                            this.setState({
                                                ...INITIAL_STATE
                                            })
                                        },
                                        negativeWord: "Cancel",
                                        showDialog: true,
                                    })
                                }}>
                                    <PauseCircleOutlineIcon fontSize={"small"}/>
                                </IconButton>
                            </Tooltip>
                            }

                            {progressState.actingID === message._id && progressState.message_pause_pending &&
                            <Loading size={24}/>}
                        </ActionIconHolder>}
                        {/* Pause Message End */}

                        {/* Resume Message Start */}
                        {canResume && <ActionIconHolder>
                            {progressState.actingID !== message._id &&
                            <Tooltip title={"Resume"}>
                                <IconButton onClick={() => {
                                    this.setState({
                                        alertMessage: <p>Are you sure you want to Resume "<b>{message.meta.name}</b>"
                                            which
                                            is {statusType === SECTIONS_ENUM.DRAFTS ? "Draft" : statusType}?</p>,
                                        positiveFunction: () => {
                                            dispatch(toggleMessage(appId, message._id, !enabled));
                                            this.setState({
                                                showDialog: false
                                            });
                                        },
                                        positiveWord: "Ok",
                                        negativeFunction: () => {
                                            this.setState({
                                                ...INITIAL_STATE
                                            })
                                        },
                                        negativeWord: "Cancel",
                                        showDialog: true,
                                    })
                                }}>
                                    <PlayCircleOutlineIcon fontSize={"small"}/>
                                </IconButton>
                            </Tooltip>
                            }
                            {progressState.actingID === message._id && progressState.message_resume_pending &&
                            <Loading size={24}/>}
                        </ActionIconHolder>}
                        {/* Resume Message End */}

                        {/* Copy/Duplicate Message Start */}
                        <ActionIconHolder>
                            <Tooltip title={"Duplicate"}>
                                <Link
                                    to={() => {
                                        const {_id, mutation_id, ...others} = message;
                                        return {
                                            pathname: `/apps/${appId}/walkthroughs/new`,
                                            state: {
                                                ...others,
                                                meta: {
                                                    ...message.meta,
                                                    name: message.meta.name + " (Copy)"
                                                },
                                                enabled: false,
                                                published: false,
                                                track: {},
                                                isCopy: true
                                            }
                                        }
                                    }}>
                                    <IconButton>
                                        <FileCopyIcon color={"primary"} fontSize={"small"}/>
                                    </IconButton>
                                </Link>
                            </Tooltip>
                        </ActionIconHolder>
                        {/* Copy/Duplicate Message End */}

                        {/* Delete Message Start */}
                        <ActionIconHolder>
                            {progressState.actingID !== message._id &&
                            <Tooltip title={"Delete"}>
                                <IconButton onClick={() => {
                                    this.setState({
                                        alertMessage: <p>Are you sure you want to delete "<b>{message.meta.name}</b>"
                                            which
                                            is {statusType === SECTIONS_ENUM.DRAFTS ? "Draft" : statusType}?</p>,
                                        positiveFunction: () => {
                                            Apxor.logEvent("DeleteCampaignClicked", {
                                                name: message.meta.name,
                                                status: statusType
                                            }, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
                                            dispatch(deleteMessage(appId, message._id));
                                            this.setState({
                                                showDialog: false
                                            });
                                        },
                                        positiveWord: "Ok",
                                        negativeFunction: () => {
                                            this.setState({
                                                ...INITIAL_STATE
                                            })
                                        },
                                        negativeWord: "Cancel",
                                        showDialog: true,
                                    })
                                }}>
                                    <DeleteIcon fontSize={"small"} style={{color: "#f44336"}}/>
                                </IconButton>
                            </Tooltip>
                            }
                            {progressState.actingID === message._id && progressState.message_delete_pending &&
                            <Loading size={24}/>}
                        </ActionIconHolder>
                        {/* Delete Message End */}
                    </Grid>
                </Grid>

                <Snackbar
                    key={message._id}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={progressState.statusMessage !== ""}
                    autoHideDuration={3000}
                    onClose={this.handleSnackbarClosed}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{progressState.statusMessage || ""}</span>}
                />

                {
                    showDialog
                    && <DialogBox
                        dialogProps={{
                            disableBackdropClick: true,
                            disableEscapeKeyDown: true
                        }}
                        message={alertMessage}
                        positiveHandler={positiveFunction}
                        negativeHandler={negativeFunction}
                        showDialog={showDialog}
                        positiveWord={positiveWord}
                        negativeWord={negativeWord}
                    />
                }
                {
                    showCampaignInfo
                    && <DialogBox
                        positiveHandler={this.handleShowCampaignInfo}
                        positiveWord={"Ok"}
                        showDialog={showCampaignInfo}
                        {..._.omit(this.props, "classes")}
                        dialogProps={{
                            maxWidth: "lg",
                            fullWidth: true,
                            disableBackdropClick: false,
                            disableEscapeKeyDown: false,
                        }}
                        extraInfo={statusType}
                        message={
                            <CampaignSummary
                                hidden={!showCampaignInfo}
                                withEdit={false}
                                cohorts={cohorts}
                                messages2={{
                                    new_message: message
                                }}
                                testDevices={testDevices}
                                segments={segments}
                            />
                        }
                    />
                }
            </Card>
        )
    }
}

export default withStyles(styles)(ClassicHolderCard);