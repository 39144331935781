/**
 * Created by jyothi on 2/3/17.
 */

import { UPDATE_GLOBAL_VERSIONS } from '../../containers/Root/Application/RequireAuth/Filters/actionTypes';
import {
    GLOBAL_FILTERS_CHANGED, NAVIGATION_LINK_CLICK, INVALIDATE_NAVIGATION_LINK_CLICK,
    ROUTER_LOCATION_CHANGE, RESET_ALL, DO_NOT_SAVE_STATE, DO_SAVE_STATE,
    API_PENDING, API_FAILED, API_SUCCESS
} from '../../constants/ActionTypes';
import {DASHBOARD_ENDPOINTS} from '../../constants/EndPoints';
import { GET_LOCATION } from '../../containers/Root/Application/actionTypes';

let API_CALL_COUNT = 0;
const INITIAL_STATE = { //TODO: bring more meta data here for dashboard next level functionality
    app_versions: [],
    globalFiltersChanged: false,
    navigationClicked: true, //default true for enabling data on direct page load
    saveState: false,
    location: {},
    routerLocation: {},
    api_pending: false
};

export default function(state = INITIAL_STATE, action) {

    switch(action.type) {

        case UPDATE_GLOBAL_VERSIONS:
            return {...state, app_versions: action.payload};

        case GLOBAL_FILTERS_CHANGED:
            return {
                ...state,
                globalFiltersChanged: action.payload,
                saveState: false
            };

        case NAVIGATION_LINK_CLICK:
            return {...state, navigationClicked: true};

        case INVALIDATE_NAVIGATION_LINK_CLICK:
            return {...state, navigationClicked: false};

        case ROUTER_LOCATION_CHANGE:
            const { pathname } = action.payload;
            let saveStateNeeded = state.saveState;
            const isSavableState = pathname.indexOf(DASHBOARD_ENDPOINTS.HEALTH) > -1 || pathname.indexOf(DASHBOARD_ENDPOINTS.USERS) > -1;
            //checking whether if routes are endpoints where saving state needed
            if(state.saveState) {
                saveStateNeeded = isSavableState;
            }
            return {...state, saveState: saveStateNeeded, routerLocation: action.payload };

        case DO_SAVE_STATE:
            return {...state, saveState: true};

        case DO_NOT_SAVE_STATE:
            return {...state, saveState: false};

        case GET_LOCATION:
            return {
                ...state,
                location: action.payload
            };

        case API_PENDING:
            API_CALL_COUNT++;
            return {
                ...state,
                api_pending: API_CALL_COUNT !== 0
            };

        case API_FAILED:
        case API_SUCCESS:
            API_CALL_COUNT--;
            return {
                ...state,
                api_pending: API_CALL_COUNT !== 0
            };

        case RESET_ALL:
            return INITIAL_STATE;
            
        default:
            return {
                ...state,
                api_pending: API_CALL_COUNT !== 0
            };
    }
}