import Cookie from '../utils/Storage/Cookie';
import Logger from '../utils/Logger';

export default function getSessionState(key){
    const cookie = new Cookie(window, Logger);
    try {
        const sessionFromCookie = JSON.parse(atob(cookie.get(key) || "")) || {token: null, profile: {}};
        return {auth: { token: sessionFromCookie.token, user: sessionFromCookie.profile }};
    } catch (e) {
        Logger.warn("Session cookie failed");
        return {};
    }
}