/**
 * Created by Rakesh Peela
 * Date: 09-Nov-2019
 * Time: 10:39 AM
 */

import {Button, Dialog, DialogActions, DialogContent, withStyles} from "@material-ui/core";
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AddIcon from '@material-ui/icons/Add';
import Apxor from 'apxor';
import moment from "moment";
import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import Loading from "../../../../../../../components/reusable/Loading";
import {APXOR_SDK_CATEGORY_CONSTANTS} from "../../../../../../../constants";
import {updateHeading} from "../../../Navigation/actions";
import {getCohorts} from "../../Cohorts/actions";
import {getMessagesList, setActingID} from "./actions";
import {resetNewMessageFlowState} from "./components/Flow/actions";
import MessagesListing from "./components/MessagesListing";
import {SECTIONS_ENUM} from "./utils";

function mapStateToProps(state) {
    return {
        appState: state.app,
        cohorts: state.cohorts,
        filters: state.filters,
        messages2: state.messages2,
        meta: state.meta,
        router: state.routing,
        session: state.auth,
        queryBuilder: state.queryBuilder,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getMessages: (appId) => dispatch(getMessagesList(appId)),
        resetMessagesCacheState: (appId) => dispatch(resetNewMessageFlowState({appId})),
        resetActingID: (id) => dispatch(setActingID(id)),
        loadData: (appId) => {
            dispatch(getMessagesList(appId));
        },
        boundDispatch: dispatch
    };
}

const styles = (theme) => ({
    content: {
        padding: '25px 15%',
        height: 'auto'
    },
    tabsRoot: {
        background: "#fff",
        paddingTop: 48,
        flex: "1 0 auto"
    },
    tabsIndicator: {
        height: 3,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontSize: 14,
        fontWeight: "500 !important",
        marginRight: theme.spacing.unit * 4,
        '&:focus': {
            // color: '#40a9ff',
        },
    },
});

function getMessageStatus(message) {
    const enabled = message.enabled, published = message.published, startDate = moment(message.validity.start_date),
        endDate = moment(message.validity.end_date);
    const now = moment();

    if (!published)
        return SECTIONS_ENUM.DRAFTS;
    else if (enabled && published && now.isBetween(startDate, endDate))
        return SECTIONS_ENUM.ACTIVE;
    else if (!enabled && published && now.isBetween(startDate, endDate))
        return SECTIONS_ENUM.PAUSED;
    else if (now.isAfter(endDate))
        return SECTIONS_ENUM.EXPIRED;
    else
        return SECTIONS_ENUM.SCHEDULED;
}

class NewMessagesComponent extends React.Component {
    constructor(props) {
        super(props);
        const {location: {query: {s = 0}}} = this.props;
        this.state = {
            sectionFilter: Number(s) > 5 ? 0 : Number(s),
            hasCache: false,
            jsonCache: {},
            showCacheDialog: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        const {messages2: {messages_list = []}} = props;
        if (messages_list.length > 0) {
            let messageStatusList = messages_list.map(eachMessage => {
                    return {[eachMessage._id]: getMessageStatus(eachMessage)}
                }
            );
            return {messageStatusMap: Object.assign({}, ...messageStatusList)};
        } else {
            return {};
        }
    }

    componentDidMount() {
        const {params: {appId}, filters, boundDispatch} = this.props;
        this.props.loadData(appId);
        this.props.updateHeading("Campaigns");
        this.props.resetActingID(null);
        boundDispatch(getCohorts(appId, filters));
        // try {
        //     let cache = window.localStorage.getItem("apx_messages2_cache-" + appId);
        //     let cacheJSON = JSON.parse(cache);
        //     if (cacheJSON !== {} && cacheJSON !== null) {
        //         this.setState({
        //             hasCache: true,
        //             jsonCache: cacheJSON
        //         });
        //     } else {
        //         console.log("No cache JSON")
        //     }
        // } catch (e) {
        //     console.log(e)
        // }
    }

    // TODO: Fix clear state on app change //
    componentWillUnmount() {
        this.setState({
            messageStatusMap: {}
        })
    }

    handleTabsChange = (event, value) => {
        let listOfSections = Object.keys(SECTIONS_ENUM);
        this.setState({sectionFilter: value})
        // Apxor.logEvent("CampaignTabSelected", {tab: listOfSections[value]}, APXOR_SDK_CATEGORY_CONSTANTS.ACTIONS);

    };

    render() {
        const {
            classes, messages2: {messages_list = {}, progress_state = {}, basicMetrics = {}}, meta,
            appState: {app: {basic_info: {platform}}, appSegments, appTestDevices},
            cohorts,
        } = this.props;
        const {appState: {app: {app_id}}} = this.props;
        const {boundDispatch,} = this.props;
        const {messageStatusMap, sectionFilter, hasCache, showCacheDialog, jsonCache} = this.state;
        return (
            <div>
                <section style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 12,
                    background: "#fff",
                    borderBottom: '1px solid #e8e8e8',
                    padding: "0 15%",
                    // boxShadow: "rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.10) 0px 1px 1px 0px"
                }}>
                    <Tabs
                        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
                        value={sectionFilter}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleTabsChange}
                    >
                        {
                            Object.keys(SECTIONS_ENUM).map(eachSection =>
                                <Tab disableRipple key={eachSection} classes={{root: classes.tabRoot}}
                                     label={SECTIONS_ENUM[eachSection]}/>
                            )
                        }
                    </Tabs>
                    <div style={{paddingTop: 48}}>
                        <Link onClick={() => {
                            Apxor.logEvent("CreateNewCampaignClicked", {has_cache: hasCache}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
                        }} to={{pathname: `/apps/${app_id}/walkthroughs/new`}}>
                            <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                disabled={meta.api_pending}
                                aria-label="create-new"
                            >
                                {meta.api_pending && <Loading size={12} style={{marginRight: 6}}/>}
                                {!meta.api_pending && <AddIcon style={{marginRight: 6}}/>}
                                Create Campaign
                            </Button>
                        </Link>
                        {/*{hasCache && <Button*/}
                        {/*    variant="outlined"*/}
                        {/*    size="small"*/}
                        {/*    color="primary"*/}
                        {/*    aria-label="create-new"*/}
                        {/*    disabled={meta.api_pending}*/}
                        {/*    onClick={() => {*/}
                        {/*        Apxor.logEvent("CreateNewCampaignClicked", {has_cache: hasCache}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);*/}
                        {/*        this.setState({*/}
                        {/*            showCacheDialog: true*/}
                        {/*        })*/}
                        {/*    }}*/}
                        {/*>*/}
                        {/*    {meta.api_pending && <Loading size={12} style={{marginRight: 6}}/>}*/}
                        {/*    {!meta.api_pending && <AddIcon style={{marginRight: 6}}/>}*/}
                        {/*    Create Campaign*/}
                        {/*</Button>}*/}
                    </div>
                </section>
                <section className={classes.content} style={{paddingTop: 0, paddingBottom: 96}}>
                    {
                        Object.keys(SECTIONS_ENUM).map((section, index) => {
                                return sectionFilter === index && (<MessagesListing
                                    key={"tab_" + section}
                                    dispatch={boundDispatch}
                                    messagesList={messages_list}
                                    messageStatusMap={messageStatusMap}
                                    appId={app_id}
                                    appPlatform={platform}
                                    sectionFilter={SECTIONS_ENUM[section]}
                                    progressState={progress_state}
                                    appMeta={{...meta, appSegments, appTestDevices}}
                                    cohorts={cohorts}
                                    basicMetrics={basicMetrics}
                                />)
                            }
                        )
                    }
                </section>

                <Dialog
                    open={showCacheDialog}
                    onClose={() => [this.setState({showCacheDialog: false})]}
                >
                    <DialogContent>
                        Do you want to continue with your last unsaved Campaign?
                    </DialogContent>
                    <DialogActions>
                        <Link to={{pathname: `/apps/${app_id}/walkthroughs/new`, state: {}}} onClick={() => {
                            this.props.resetMessagesCacheState(app_id);
                            this.setState({
                                showCacheDialog: false,
                            });
                        }}>
                            <Button style={{color: "red"}}>
                                No, start Afresh
                            </Button>
                        </Link>
                        <Link onClick={() => {
                            Apxor.logEvent("StartFromScratchMessage", {}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS)
                        }} to={{
                            pathname: `/apps/${app_id}/walkthroughs/new`,
                            state: {
                                _id: undefined,
                                published: false,
                                enabled: false,
                                mutation_id: undefined,
                                track: {},
                            }
                        }}>
                            <Button onClick={() => {
                                Apxor.logEvent("ContinueWhereYouLeftoffMessage", {}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
                                this.setState({
                                    showCacheDialog: false,
                                })
                            }}>
                                Yes
                            </Button>
                        </Link>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewMessagesComponent))