/**
 * Created by Rakesh Peela
 * Date: 29-Nov-2019
 * Time: 11:59 PM
 */

import {Card, CardContent, Divider, Grid, Typography} from "@material-ui/core";
import moment from "moment";
import React from 'react';

export default class ReportItemHolder extends React.Component {
    render() {
        const {children, title, description} = this.props;
        return (
            <div style={{marginTop: 12, marginBottom: 12}}>
                <Card>
                    <CardContent style={{padding: 16}}>
                        <Typography variant={"h5"} gutterBottom>
                            {title}
                        </Typography>
                        <Typography variant={"subtitle1"} gutterBottom>
                            {description}
                        </Typography>
                        <Divider style={{marginTop: 12, marginBottom: 12}}/>
                        {children}
                    </CardContent>
                </Card>
            </div>
        );
    }
}