import React, {} from 'react';
import PropTypes from 'prop-types';
import InfoRounded from "@material-ui/icons/InfoRounded";
import Tooltip from "@material-ui/core/Tooltip";

const VERY_SMALL = "very-small";
const SIZES = ["default", "inherit", "small", "large", VERY_SMALL];

class InfoHelper extends React.Component {

    render(){
        const { children, size = "default", color = '#AAAAAA', name } = this.props;
        return (
            <Tooltip title={children} placement="top">
                <InfoRounded
                    nativeColor={color}
                    fontSize={size !== VERY_SMALL ? size : undefined}
                    style={{...(size === VERY_SMALL) && {fontSize: 14}}}
                    name={name}
                />
            </Tooltip>
        );
    }
}

InfoHelper.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string
    ]).isRequired,
    name: PropTypes.string,
    size: PropTypes.oneOf(SIZES)
};

export default InfoHelper;