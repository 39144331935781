import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import omit from 'lodash/omit';
import Button from '@material-ui/core/Button';
import 'react-dates/initialize';

import {SingleDatePicker, DateRangePicker, isSameDay} from 'react-dates';

import 'react-dates/lib/css/_datepicker.css';
import {normalizedDate} from "../../../utils";

const START_DATE = "startDate";
const END_DATE = "endDate";

const today = moment();
const yesterday = moment().subtract(1, 'days');
const presets = [
    {
        text: 'Today',
        start: today,
        end: today,
    },
    {
        text: 'Yesterday',
        start: yesterday,
        end: yesterday,
    },
    {
        text: 'Last 7 Days',
        start: moment().subtract(7, 'days'),
        end: yesterday
    },
    {
        text: 'Last 15 Days',
        start: moment().subtract(15, 'days'),
        end: yesterday
    },
    {
        text: 'Last 30 Days',
        start: moment().subtract(30, 'days'),
        end: yesterday
    }
];

const defaultProps = {
    // example props for the demo
    autoFocus: false,
    autoFocusEndDate: false,
    initialStartDate: null,
    initialEndDate: null,
    presets: presets,

    // input related props
    startDateId: START_DATE,
    startDatePlaceholderText: 'Start Date',
    endDateId: END_DATE,
    endDatePlaceholderText: 'End Date',
    disabled: false,
    required: false,
    screenReaderInputMessage: '',
    showClearDates: false,
    showDefaultInputIcon: false,
    customInputIcon: null,
    customArrowIcon: null,
    customCloseIcon: null,

    // calendar presentation and interaction related props
    renderMonth: null,
    orientation: "horizontal",
    anchorDirection: "right",
    horizontalMargin: 0,
    withPortal: false,
    withFullScreenPortal: false,
    initialVisibleMonth: null,
    numberOfMonths: 2,
    keepOpenOnDateSelect: false,
    reopenPickerOnClearDates: false,
    isRTL: false,

    // navigation related props
    navPrev: null,
    navNext: null,
    onPrevMonthClick() {
    },
    onNextMonthClick() {
    },
    onClose() {
    },

    // day presentation and interaction related props
    renderDayContents: null,
    minimumNights: 0,
    enableOutsideDays: false,
    isDayBlocked: () => false,
    isOutsideRange: day => false,
    isDayHighlighted: () => false,

    // internationalization
    displayFormat: () => "MMM Do",//moment.localeData().longDateFormat('ll'),
    monthFormat: 'MMMM YYYY',
    //phrases: DateRangePickerPhrases,
};

const diffFromToday = day => moment().startOf('day').diff(day.startOf('day'));

const diffBetweenDays = (dayA, dayB) => dayA.startOf('day').diff(dayB.startOf('day'));

const diffFromDate = (disableBefore, day) => moment(disableBefore).startOf("day").diff(day.startOf('day'));

class DateRangePickerWrapper extends React.Component {
    constructor(props) {
        super(props);

        let focusedInput = null;
        if (props.autoFocus) {
            focusedInput = START_DATE;
        } else if (props.autoFocusEndDate) {
            focusedInput = END_DATE;
        }

        this.state = {
            focusedInput,
            startDate: props.startDate || moment(),
            endDate: props.endDate || moment(),
            date: props.date || moment(),
            focused: false,
            changed: false
        };

        this.onDatesChange = this.onDatesChange.bind(this);
        this.onFocusChange = this.onFocusChange.bind(this);
        this.renderDatePresets = this.renderDatePresets.bind(this);
    }

    componentWillMount() {
        /*const { startDate, endDate, date } = this.state;
        if(this.props.single){
            const {  date : singleDate =  date } = this.props;
            this.onDateChange(singleDate);
        }else{
            const { startDate: since = startDate.toJSON(), endDate: till = endDate.toJSON() } = this.props;
            this.onDatesChange({startDate: moment(since), endDate: moment(till)});
        }*/
    }

    onDatesChange({startDate, endDate}, callback = () => null) {
        this.setState({startDate, endDate, changed: true}, callback);
    }

    onDateChange = (date) => {
        this.setState({date});
        if (date) {
            this.props.handleChange(normalizedDate(date));
        }
    };

    onFocusChange(focusedInput) {
        this.setState({focusedInput}, () => {
            let {startDate, endDate, changed} = this.state;
            if (!focusedInput && changed && startDate && endDate) { //calls only on lost focus
                const {inUTC = false, handleChange} = this.props;
                const normalizedStartTime = normalizedDate(startDate, inUTC);
                const normalizedEndTime = normalizedDate(endDate, inUTC);
                handleChange(normalizedStartTime, normalizedEndTime);
                this.setState({changed: false});
            }
        });
    }

    renderDatePresets() {
        const {presets} = this.props;
        const {startDate, endDate} = this.state;

        return (
            <div style={{padding: '0 20px 10px'}}>
                {presets.map(({text, start, end}) => {
                    const isSelected = isSameDay(start, startDate) && isSameDay(end, endDate);
                    return (
                        <Button
                            key={text}
                            size="small"
                            color="primary"
                            variant={isSelected ? "contained" : undefined}
                            onClick={() => {
                                this.onDatesChange({startDate: start, endDate: end}, () => {
                                    this.onFocusChange(null);
                                });
                            }}
                            style={{marginRight: 5}}
                        >
                            {text}
                        </Button>
                    );
                })}
            </div>
        );
    }

    render() {
        const {date, focusedInput, startDate, endDate, focused} = this.state;

        const {
            disableBefore = false, disableAfter = false, withoutPresets = false,
            single = false, enabledBetweenDates
        } = this.props;

        // autoFocus, autoFocusEndDate, initialStartDate and initialEndDate are helper props for the
        // example wrapper but are not props on the SingleDatePicker itself and
        // thus, have to be omitted.
        const props = omit(this.props, [
            'autoFocus',
            'autoFocusEndDate',
            'initialStartDate',
            'initialEndDate',
            'presets',
            'handleChange',
            'filters',
            'disableBefore',
            'disableAfter',
            'enabledBetweenDates',
            'single',
            'withoutPresets',
            'inUTC'
        ]);

        const singleDPProps = omit(props, [
            'single',
            'disableFuture',
            'startDateId',
            'enabledBetweenDates',
            'startDatePlaceholderText',
            'endDateId',
            'endDatePlaceholderText',
            'showClearDates',
            'customArrowIcon',
            'reopenPickerOnClearDates',
            'minimumNights'
        ]);

        return (
            <div>
                {
                    single &&
                    <SingleDatePicker
                        {...singleDPProps}
                        onDateChange={this.onDateChange}
                        onFocusChange={({focused}) => this.setState({focused})}
                        focused={focused}
                        date={date}
                        isDayBlocked={(day) => {
                            if (enabledBetweenDates) {
                                return !(diffBetweenDays(enabledBetweenDates[0], day) <= 0
                                    && diffBetweenDays(enabledBetweenDates[1], day) >= 0);
                            }
                            if (moment.isMoment(disableBefore)) {
                                return diffFromDate(disableBefore, day) > 0;
                            }
                            if (disableBefore) {
                                return diffFromToday(day) > 0;
                            }
                            if (disableAfter) {
                                return diffFromToday(day) < 0;
                            }
                            return false;
                        }}
                        numberOfMonths={1}
                        showDefaultInputIcon
                        inputIconPosition="after"
                    />
                }
                {
                    !single &&
                    <DateRangePicker
                        {...props}
                        renderCalendarInfo={withoutPresets ? null : this.renderDatePresets}
                        onDatesChange={this.onDatesChange}
                        onFocusChange={this.onFocusChange}
                        focusedInput={focusedInput}
                        startDate={startDate}
                        endDate={endDate}
                        isDayBlocked={(day) => {
                            if (enabledBetweenDates) {
                                return !(diffBetweenDays(enabledBetweenDates[0], day) <= 0
                                    && diffBetweenDays(enabledBetweenDates[1], day) >= 0);
                            }
                            if (disableBefore) {
                                return diffFromToday(day) > 0;
                            }
                            if (disableAfter) {
                                return diffFromToday(day) < 0;
                            }
                            return false;
                        }}
                    />
                }
            </div>
        );
    }
}

DateRangePickerWrapper.propTypes = {
    //handleChange: PropTypes.func.required,
    //filters: PropTypes.object
    inUTC: PropTypes.bool
};
DateRangePickerWrapper.defaultProps = defaultProps;

export default DateRangePickerWrapper