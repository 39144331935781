/**
 * Created by Rakesh Peela
 * Date: 03-Dec-2019
 * Time: 11:35 PM
 */

export const MODULE_TYPES = {
    "ACTION_BUTTONS_MODULE": "ACTION_BUTTONS_MODULE",
    "BUTTONS_MODULE": "BUTTONS_MODULE",
    "TITLE_MODULE": "TITLE_MODULE",
    "DESCRIPTION_MODULE": "DESCRIPTION_MODULE",
    "IMAGE_MODULE": "IMAGE_MODULE"
};