/**
 * Created by jyothi on 1/11/17.
 */
import { callApi } from '../../../api';
import { makeDefaultQueryString, withQueryStrings} from '../../../utils';

const ATTRIBUTES_API = "attributes";
const attributeValuesAPI = (attribute, q) => `attributes/${attribute}`;
const EVENTS_API = "events";
const EVENT_ATTRIBUTES_API = "events/attributes";
const SEGMENT_COUNT_API = "segmentation/count";
const SAVE_SEGMENT_API = "segmentation";

export function getAttributesAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(ATTRIBUTES_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getAttributeValuesAPI(auth, appId, attribute, filters) {
    const url = makeDefaultQueryString(attributeValuesAPI(attribute), auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json.filter(o => o && o.length > 0);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(EVENTS_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json.sort((a, b) => a.localeCompare(b)); //default sorting on strings
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventAttributesAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(EVENT_ATTRIBUTES_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventAttributeValuesAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(attributeValuesAPI(filters.attribute), auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json.filter(o => o && o.length > 0);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getSegmentCountAPI(auth, appId, filters, segment) {
    const url = makeDefaultQueryString(SEGMENT_COUNT_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(segment)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function saveSegmentAPI(auth, appId, filters, segment) {
    const url = makeDefaultQueryString(SAVE_SEGMENT_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(segment)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}