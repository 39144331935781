/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../api';
import {makeDefaultQueryString, withQueryStrings} from '../../../../../utils';
import { APP_PLATFORMS } from '../../../../../constants';

const GET_APPS_API = "apps";
const DELETE_APP_API = "apps"; //FIXME: Not sure
const ADD_APP_API = "apps";
export const SDK_MONITORING_API = "apps/monitor";

const PLAY_STORE_ICON_URL = "/assets/img/playStore.ico";
const APP_STORE_ICON_URL = "/assets/img/defaultAppImage.png";
const WEB_ICON_URL = "/assets/img/web.png";
const FAVICON_URL = "/assets/img/favicon.png";
const OMNI_ICON_URL = "/assets/img/omni.png";

const { android, ios, web, omni } = APP_PLATFORMS;

const ICONS = {
    [android]: PLAY_STORE_ICON_URL,
    [ios]: APP_STORE_ICON_URL,
    [web]: WEB_ICON_URL,
    [omni]: OMNI_ICON_URL
};

function getIcon(platform) {
    return ICONS[platform] || FAVICON_URL;
}

/**
 *
 * @param auth
 * @param app
 * @returns {{app_name: (*|string), icon_url: string, customer_id: *, bundle_id: (*|string), platform: (*|string|string)}}
 */
const makeAddAppModel = (auth, app) => {
    return {
        app_name: app.app_name,
        icon_url: getIcon(app.platform),
        customer_id: auth.user.email,
        bundle_id: app.bundle_id,
        platform: app.platform
    };
};

/**
 *
 * @param auth
 * @returns {Promise}
 */
export function getAppsAPI(auth) {
    const url = makeDefaultQueryString(GET_APPS_API, auth);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param app
 * @returns {Promise}
 */
export function addAppAPI(auth, app) {
    const url = makeDefaultQueryString(ADD_APP_API, auth);
    const config = {
        method: 'POST',
        auth: auth,
        body: JSON.stringify(makeAddAppModel(auth, app))
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function approveAppAPI(auth, appId, approverId) {
    const url = makeDefaultQueryString(`${GET_APPS_API}/${appId}/approve`, auth)
        + withQueryStrings({approverId: auth.user.email});
    const config = {
        method: "PATCH",
        auth: auth,
        body: JSON.stringify({})
    };

    const onSuccessCallback = json => {
        return json;
    };

    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @returns {Promise}
 */
export function deleteAppAPI(auth, appId) {
    const url = makeDefaultQueryString(DELETE_APP_API, auth, appId);
    const config = {
        method: "DELETE",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}