/**
 * Created by Rakesh Peela
 * Date: 15-Feb-2020
 * Time: 12:12 AM
 */

import {
    Button,
    IconButton, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    withStyles
} from "@material-ui/core";
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from "moment";
import React from 'react';
import Loading from "../../../../../../components/reusable/Loading";
import StatusIdentifier from "../../../../../../components/reusable/StatusIdentifier";
import ClassicCard from "../Campaigns/Messages2/components/ClassicCard";
import {getDownloadableFiles} from "./actions";

const CustomTableCell = withStyles(theme => ({
    // head: {
    //     backgroundColor: theme.palette.common.black,
    //     color: theme.palette.common.white,
    // },
    body: {
        fontSize: 14,
    },
}))(TableCell);

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.background.default,
        },
    },
});

const Status_palette = {
    "In Progress": {label: "In Progress", color: "#039be5"},
    "Available": {label: "Available", color: "#0eb796"},
    "Failed": {label: "Failed", color: "#ef5350"},
    "Expired": {label: "Expired", color: "#9e9e9e"}
};

class FileDownloads extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            downloadableFiles: [],
            isRefreshing: false,
            expiredLinkError: false
        }
    }

    componentDidMount() {
        const {dispatch, appId} = this.props;
        dispatch(getDownloadableFiles(appId))
    }

    getFileStatus = (status, create_date, expiry_date, now, error) => {
        if (error !== "") {
            return "Failed"
        }
        if (!status && error === "") {
            return "In Progress"
        } else if (moment().isBefore(expiry_date)) {
            return "Available"
        } else {
            return "Expired"
        }
    };

    render() {
        const {classes, downloadableFiles = [], dispatch, appId, dashboardMeta: {api_pending}} = this.props;
        const {isRefreshing, expiredLinkError} = this.state;
        const now = moment();
        const availableDownloads = downloadableFiles.filter(x => moment(x.validity.expiresAt).isAfter(now));
        return (
            <div>
                <div style={{marginBottom: 12, display: "flex", flexDirection: "row-reverse"}}>
                    <Button
                        disabled={isRefreshing || api_pending}
                        onClick={() => {
                            this.setState({
                                isRefreshing: true
                            });
                            dispatch(getDownloadableFiles(appId)).then(() => {
                                this.setState({
                                    isRefreshing: false
                                })
                            })
                        }}>
                        <RefreshIcon color={"primary"} style={{marginRight: 4}}/> Refresh
                    </Button>
                </div>
                {
                    api_pending
                    && <Loading/>
                }
                {
                    !isRefreshing
                    && !api_pending
                    && downloadableFiles
                    && availableDownloads.length !== 0
                    && <ClassicCard style={{
                        padding: 0,
                        borderRadius: 4,
                        overflowX: 'auto',
                        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
                    }}>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <CustomTableCell>File Name</CustomTableCell>
                                    <CustomTableCell style={{textAlign: "center"}}>Status</CustomTableCell>
                                    <CustomTableCell>Created At</CustomTableCell>
                                    <CustomTableCell>Expiry</CustomTableCell>
                                    <CustomTableCell style={{textAlign: "center"}}>Actions</CustomTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    downloadableFiles && availableDownloads && availableDownloads
                                    .map(eachFile => {
                                        const {validity: {createdAt = now, expiresAt = now}, uploadStatus: {status = false, error = ""}} = eachFile;
                                        const moment_createdAt = moment(createdAt);
                                        const moment_expiresAt = moment(expiresAt);
                                        const fileStatus = this.getFileStatus(status, moment_createdAt, moment_expiresAt, now, error);
                                        return (
                                            <TableRow className={classes.row} key={eachFile._id}>
                                                <CustomTableCell component="th" scope="row">
                                                    {eachFile.fileName || "file"}
                                                </CustomTableCell>
                                                <CustomTableCell style={{textAlign: "center"}}>
                                                    {
                                                        eachFile.hasOwnProperty("uploadStatus")
                                                        && <StatusIdentifier
                                                            palette={Status_palette}
                                                            statusType={fileStatus}
                                                        />
                                                    }
                                                </CustomTableCell>
                                                <CustomTableCell>{moment_createdAt.format("DD-MM-YYYY LTS")}</CustomTableCell>
                                                <CustomTableCell>{moment_expiresAt.fromNow()}</CustomTableCell>
                                                <CustomTableCell style={{textAlign: "center"}}>
                                                    {
                                                        !["In Progress", "Failed"].includes(fileStatus)
                                                        && <IconButton onClick={() => {
                                                            if (moment_expiresAt.isAfter(moment())) {
                                                                let link = document.createElement("a");
                                                                link.href = eachFile.hasOwnProperty("signedUrl") ? eachFile.signedUrl : null;
                                                                link.download = `${eachFile.fileName}.csv`;
                                                                link.click();
                                                                link.remove();
                                                            } else {
                                                                this.setState({
                                                                    expiredLinkError: true
                                                                })
                                                            }
                                                        }}>
                                                            <CloudDownloadIcon color={"primary"}/>
                                                        </IconButton>
                                                    }
                                                    {
                                                        fileStatus === "In Progress"
                                                        && <AllInclusiveIcon color={"disabled"}/>
                                                    }
                                                    {
                                                        fileStatus === "Failed"
                                                        && <NotInterestedIcon color={"error"}/>
                                                    }
                                                </CustomTableCell>
                                            </TableRow>
                                        )
                                    })
                                }

                            </TableBody>
                        </Table>
                    </ClassicCard>
                }
                {
                    expiredLinkError
                    && <Snackbar
                        autoHideDuration={3000}
                        onClose={() => {
                            this.setState({expiredLinkError: false})
                        }}
                        open={expiredLinkError}>
                        Link Expired
                    </Snackbar>
                }
                {
                    !isRefreshing
                    && !api_pending
                    && availableDownloads.length === 0
                    && <div style={{textAlign: "center", padding: 36, marginTop: 36}}>
                        <Typography color={"textPrimary"}> You do not have any available Downloads</Typography>
                    </div>
                }
                < /div>

                    );
                    }
                    }

                    export default withStyles(styles)(FileDownloads);
