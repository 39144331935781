/**
 * Created by jyothi on 1/11/17.
 */


export const CUSTOM_EVENT_ENUM = {
    event: "event",
    sessions: "sessions",
    users: "users"
};

export const CUSTOM_QUERY_ATTRIBUTES_PENDING = "CUSTOM_QUERY_ATTRIBUTES_PENDING";
export const CUSTOM_QUERY_ATTRIBUTES = "CUSTOM_QUERY_ATTRIBUTES";
export const CUSTOM_QUERY_ATTRIBUTES_FAILED = "CUSTOM_QUERY_ATTRIBUTES_FAILED";

export const CUSTOM_QUERY_ATTRIBUTE_VALUES_PENDING = "CUSTOM_QUERY_ATTRIBUTE_VALUES_PENDING";
export const CUSTOM_QUERY_ATTRIBUTE_VALUES = "CUSTOM_QUERY_ATTRIBUTE_VALUES";
export const CUSTOM_QUERY_ATTRIBUTE_VALUES_FAILED = "CUSTOM_QUERY_ATTRIBUTE_VALUES_FAILED";

export const CUSTOM_QUERY_SAVE_PENDING = "CUSTOM_QUERY_SAVE_PENDING";
export const CUSTOM_QUERY_SAVE = "CUSTOM_QUERY_SAVE";
export const CUSTOM_QUERY_SAVE_FAILED = "CUSTOM_QUERY_SAVE_FAILED";

export const CUSTOM_QUERY_COUNT_PENDING = "CUSTOM_QUERY_COUNT_PENDING";
export const CUSTOM_QUERY_COUNT = "CUSTOM_QUERY_COUNT";
export const CUSTOM_QUERY_COUNT_FAILED = "CUSTOM_QUERY_COUNT_FAILED";

export const CUSTOM_QUERY_EVENTS_PENDING = "CUSTOM_QUERY_EVENTS_PENDING";
export const CUSTOM_QUERY_EVENTS = "CUSTOM_QUERY_EVENTS";
export const CUSTOM_QUERY_EVENTS_FAILED = "CUSTOM_QUERY_EVENTS_FAILED";

export const CUSTOM_QUERY_EVENT_ATTRIBUTES_PENDING = "CUSTOM_QUERY_EVENT_ATTRIBUTES_PENDING";
export const CUSTOM_QUERY_EVENT_ATTRIBUTES = "CUSTOM_QUERY_EVENT_ATTRIBUTES";
export const CUSTOM_QUERY_EVENT_ATTRIBUTES_FAILED = "CUSTOM_QUERY_EVENT_ATTRIBUTES_FAILED";

export const CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES_PENDING = "CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES_PENDING";
export const CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES = "CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES";
export const CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES_FAILED = "CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES_FAILED";

export const CUSTOM_QUERY_UPDATE_QUERY = "CUSTOM_QUERY_UPDATE_QUERY";

export const CUSTOM_QUERY_UPDATE_GROUP = "CUSTOM_QUERY_UPDATE_GROUP";

export const CUSTOM_QUERY_UPDATE_SEGMENT = "CUSTOM_QUERY_UPDATE_SEGMENT";

export const CUSTOM_QUERY_RESET_QUERY = "CUSTOM_QUERY_RESET_QUERY";

export const CUSTOM_QUERY_UPDATE_QUERY_TYPE = "CUSTOM_QUERY_UPDATE_QUERY_TYPE"; //users, sessions