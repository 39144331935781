import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading } from '../../Navigation/actions';
import { Counts, DropOffDetails, Filters } from './components';
import {
    loadDropOffData,
    getDropOffsByEvents, getDropOffsByScreens,
    updateFilters, updateGroup, updateScreen, updateSegment, updateCurrentActive,
    getEventAttributes, getScreenEventAttributes, getScreenLastEventAttributes,
    updateEvent, updateScreenEvent, updateScreenLastEvent,
    getEventAttributeDistribution, getScreenEventAttributeDistribution, getScreenLastEventAttributeDistribution,
    updateTimeSlot, getScreenUsers, getEventUsers, getScreenEventUsers, getScreenLastEventUsers
} from './actions';
import {getAttributes, getAttributeValues, resetQuery} from "../Segments/NewSegment/actions";
import {getSegmentList} from "../Segments/actions";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        dropOff: state.dropOff,
        segmentBuilder: state.segmentBuilder,
        segment: state.segment,
        meta: state.meta
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        loadData: (appId) => dispatch(loadDropOffData(appId)),
        getDropOffsByScreens: (appId) => dispatch(getDropOffsByScreens(appId)),
        getDropOffsByEvents: (appId) => dispatch(getDropOffsByEvents(appId)),
        getScreenData: (appId, screen, timeSlot) => dispatch(updateScreen(appId, screen, timeSlot)),
        getAttributes: (appId) => dispatch(getAttributes(appId)),
        getAttributeValues: (appId, attribute, q) => dispatch(getAttributeValues(appId, attribute, q)),
        getSegments: (appId) => dispatch(getSegmentList(appId)),
        updateFilters: (filters) => dispatch(updateFilters(filters)),
        updateGroup: (group) => dispatch(updateGroup(group)),
        updateScreen: (screen) => dispatch(updateScreen(screen)),
        updateSegment: (segmentId) => dispatch(updateSegment(segmentId)),
        updateTimeSlot: (timeSlot) => dispatch(updateTimeSlot(timeSlot)),
        updateCurrentActive: (appId, current) => dispatch(updateCurrentActive(appId, current)),
        getEventAttributes: (appId, event) => dispatch(getEventAttributes(appId, event)),
        getScreenEventAttributes: (appId, screen, event) => dispatch(getScreenEventAttributes(appId, screen, event)),
        getScreenLastEventAttributes: (appId, screen, event) => dispatch(getScreenLastEventAttributes(appId, screen, event)),
        updateEvent: (appId, event) => dispatch(updateEvent(appId, event)),
        updateScreenEvent: (appId, screen, event) => dispatch(updateScreenEvent(appId, screen, event)),
        updateScreenLastEvent: (appId, screen, event) => dispatch(updateScreenLastEvent(appId, screen, event)),
        getEventAttributeDistribution: (appId, event, attribute) => dispatch(getEventAttributeDistribution(appId, event, attribute)),
        getScreenEventAttributeDistribution: (appId, screen, event, attribute) => dispatch(getScreenEventAttributeDistribution(appId, screen, event, attribute)),
        getScreenLastEventAttributeDistribution: (appId, screen, event, attribute) => dispatch(getScreenLastEventAttributeDistribution(appId, screen, event, attribute)),
        getScreenUsers: (appId, screen) => dispatch(getScreenUsers(appId, screen)),
        getScreenEventUsers: (appId, screen, event) => dispatch(getScreenEventUsers(appId, screen, event)),
        getScreenLastEventUsers: (appId, screen, event) => dispatch(getScreenLastEventUsers(appId, screen, event)),
        getEventUsers: (appId, event) => dispatch(getEventUsers(appId, event)),
        resetQuery: () => dispatch(resetQuery())
    };
}

class UserExplorer extends Component {

    constructor(props){
        super(props);
        this.state = {
            userId : null,
            obtainedUserIds: [],
            loading: false,
            failed: false,
            isRequested: false
       };
        this.appId = props.params.appId;
    }
    componentWillMount(){
        const { updateHeading, getSegments, loadData, getAttributes } = this.props;
        getSegments(this.appId);
        getAttributes(this.appId);
        updateHeading("Drop Off Analysis");
        loadData(this.appId);
    }

    componentDidMount(){

    }

    render() {
        return (
            <section className="content">
                <Filters {...this.props}/>
                <Counts {...this.props}/>
                <DropOffDetails {...this.props}/>
            </section>
        );
    }
}

UserExplorer.propTypes = {

};

UserExplorer.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserExplorer);