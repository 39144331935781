/**
 * Created by kodanda_rama on 1/15/17.
 */
import React, {Fragment} from 'react';
import {Button, Tooltip, Grid} from "@material-ui/core";
import { spacingCSS, renderButton, renderText } from './utils';
import {withStyles} from "@material-ui/core/styles";
import {MESSAGE_POSITION_ENUM} from "../actionTypes";
import ArrowRight from '@material-ui/icons/ArrowForward';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowLeft from '@material-ui/icons/ArrowBack';


const { TOP, BOTTOM, LEFT, RIGHT } = MESSAGE_POSITION_ENUM;

const Z_INDEX = 998;

const styles = theme => ({
    root: {

    },
    tooltip: {
        background: 'transparent'
    },
    '@keyframes load-progress': {
        '100%': {
            marginLeft: -160,
            marginTop: -160,
            borderWidth: 160,
            opacity: 0
        }
    },
    ripple: {
        width: 0,
        height: 0,
        position: 'absolute',
        webkitAnimation: 'load-progress 1s infinite',
        animation: 'load-progress 1s infinite',
        webkitAnimationDelay: '1s',
        animationDelay: '1s',
        marginTop: 18 //Half og Button Height
    },
    bounce: {
        webkitAnimation: 'bounce-up-down 0.3s 5',
        animation: 'bounce-up-down 0.3s 5'
    },
    nonRipple: {
        position: 'absolute',
        opacity: 0.6
    },
    "@keyframes shake": {
        "10%, 90%": {
            "transform": "translateX(0)"
        },
        "10%, 30%, 50%, 70%, 90%": {
            "transform": "translateX(-3px)"
        },
        "20%, 40%, 60%, 80%": {
            "transform": "translateX(3px)"
        }
    },
    "@keyframes bounce-up-down": {
        "0%, 100%": {
            "transform": "translateY(0)"
        },
        "50%": {
            "transform": "translateY(-4px)"
        }
    },
});


const getLayoutStyles = (view, position) => {
    const half = COACHMARK_LENGTH / 2;
    if(!view){
        return {left: half, right: half};
    }
    const { width, height } = view;
    switch (position) {
        case LEFT:
            return {
                left: 0,
                top: half - height / 2
            };
        case RIGHT:
            return {
                left: half + width / 2,
                top: half - height / 2
            };
        case TOP:
            return {
                left: half - width / 2,
                top: 16
            };
        case BOTTOM:
            return {
                left: half - width / 2,
                top: half + height / 2
            };
        default: return { left: half, top: half };
    }
};

const COACHMARK_LENGTH = 360;

const PASSIVE_NUDGE_SIZE = 8;

class InLineMobileSimulator extends React.Component{

    constructor(props){
        super(props);
        this.state = {

        }
    }

    render(){
        const { classes, name, position,
            corners: {enabled, width } = {},
            text_config, text_config: {  size, font_family, color, margin_config = {}, padding_config = {}} = {},
            display_type, coach_mark_details: {type, has_ripple, arrow_color, has_arrow} = {},
            bg_color, opacity, view_id,
            border: { width: borderWidth, color: borderColor } = {width: 0, color: bg_color },
            hasOkButton, hasCloseButton, okButton, closeButton,
            animation: { enabled: animationEnabled, type: animationType, duration: animationDuration} = {},
            children, view
        } = this.props;
        const CoachMark = ({styles}) => (
            <div style={{
                fontSize: size,
                fontFamily: font_family,
                color: color,
                background: 'transparent',
                textAlign:"center",
                flexDirection: "column",
                border: 0,
                width: "auto",
                display: 'flex',
                padding: spacingCSS(padding_config),
                margin: spacingCSS(margin_config),
                boxSizing: 'border-box',
                position: 'absolute',
                zIndex: Z_INDEX,
                ...styles
            }}>
                <div>
                    {has_arrow && position === BOTTOM && <ArrowUpward nativeColor={arrow_color}/>}
                    {has_arrow && position === RIGHT && <ArrowLeft nativeColor={arrow_color}/>}
                    {renderText(text_config)}
                    <div style={{display: 'flex', marginTop: 10, justifyContent: 'center', textAlign: 'center'}}>
                        { hasOkButton && renderButton(okButton)}
                        { hasCloseButton && renderButton(closeButton)}
                    </div>
                    { has_arrow && position === LEFT && <ArrowRight nativeColor={arrow_color}/>}
                    { has_arrow && position === TOP && <ArrowDownward nativeColor={arrow_color}/>}
                </div>
            </div>
        );
        const bigRipple = position === LEFT || position === RIGHT;
        const renderView = (position) => {
            const { width, height } = view || {};
            const left = -(COACHMARK_LENGTH / 2 - view.left - width / 2);
            const top = -(COACHMARK_LENGTH / 2 - view.top - height / 2);
            return(
                <div className={classes.nonRipple} style={{
                    background: bg_color,
                    borderRadius: type === "circle" ? '50%' : '0',
                    opacity,
                    width: COACHMARK_LENGTH,
                    height: COACHMARK_LENGTH,
                    position: 'absolute',
                    zIndex: Z_INDEX,
                    left, top
                }}>
                    <div className={has_ripple ? classes.ripple : ""} style={{
                        borderRadius: type === "circle" ? '50%' : '0',
                        border: `1px solid #FFF`,
                        position: 'absolute',
                        zIndex: Z_INDEX,
                        left: COACHMARK_LENGTH / 2,
                        top: COACHMARK_LENGTH / 2
                    }} />
                    <CoachMark styles={getLayoutStyles(view, position)}/>
                </div>
            )
        };

        const renderPassiveNudge = () => {
            const { width, height } = view || {};
            const left = view.left + width + PASSIVE_NUDGE_SIZE;
            const top = view.top - PASSIVE_NUDGE_SIZE;
            return(
                <div className={classes.bounce} style={{
                    backgroundColor: bg_color,
                    borderRadius: '50%',
                    width: PASSIVE_NUDGE_SIZE,
                    height: PASSIVE_NUDGE_SIZE,
                    position: 'absolute',
                    zIndex: Z_INDEX,
                    left, top
                }}>
                    {/*<div className={has_ripple ? classes.ripple : ""} style={{
                        borderRadius: type === "circle" ? '50%' : '0',
                        border: `1px solid #FFF`,
                        position: 'absolute',
                        zIndex: Z_INDEX,
                        left: PASSIVE_NUDGE_SIZE / 2,
                        top: PASSIVE_NUDGE_SIZE / 2
                    }} />*/}
                </div>
            )
        };

        return(
            <Fragment>
                {
                    display_type === "coach_mark" &&
                    <Fragment>
                        {children}
                        {renderView(position)}
                    </Fragment>
                }
                {
                    display_type === "passive_nudge" &&
                    <Fragment>
                        {children}
                        {renderPassiveNudge()}
                    </Fragment>
                }
                {
                    display_type === "inline" &&
                    <Tooltip
                        open
                        id={view_id + name + position + color + bg_color}
                        title={
                            <div style={{
                                fontSize: size,
                                fontFamily: font_family,
                                color: color,
                                backgroundColor: bg_color,
                                width: '100%',
                                height: '100%',
                                padding: '7px 5px 0 5px',
                                borderRadius: enabled ? width : 0,
                                opacity: opacity,
                                border: `${borderWidth}px solid ${borderColor}`,
                                maxWidth: 240,
                                animation: animationEnabled ? `shake ${2000}ms ease-in-out infinite` : undefined
                            }}>
                                {renderText(text_config, true)}
                                <div style={{marginTop: 10, textAlign: 'right', width: '100%'}}>
                                    { hasOkButton && renderButton(okButton)}
                                    { hasCloseButton && renderButton(closeButton)}
                                </div>
                            </div>
                        }
                        placement={position.toLowerCase()}
                        PopperProps={{
                            style: {
                                background: 'transparent',
                            }
                        }}
                        classes={{
                            tooltip: classes.tooltip
                        }}
                    >
                        {children}
                    </Tooltip>
                }
            </Fragment>
        )
    }

}


export default withStyles(styles)(InLineMobileSimulator);