/**
 * Created by jyothi on 14/1/17.
 */
import {
    SAVE_NOTIFICATION_FAILED, SAVE_NOTIFICATION_SUCCESS,
    NOTIFICATIONS, NOTIFICATIONS_FAILED, DELETE_NOTIFICATION_FAILED, DELETE_NOTIFICATION_SUCCESS,
     SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_FAILED,
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';
const INITIAL_STATE = {
    notifications: []
};

const INITIAL_SAVE_NOTIFICATION_FLAG_STATE = {
    save_notification_pending: false,
    save_notification_failed: false,
    save_notification_success: false
};

const INITIAL_SEND_NOTIFICATION_FLAG_STATE = {
    send_notification_pending: false,
    send_notification_failed: false,
    notification_sent: false
};

const INITIAL_NOTIFICATION_DELETE_FLAG_STATE = {
    delete_notification_pending: false,
    delete_notification_failed: false
};

const INITIAL_NOTIFICATIONS_FLAG_STATE = {
    notifications_pending: false,
    notifications_failed: false
};


function notificationsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case SAVE_NOTIFICATION_FAILED:
            return {
                ...state,
                ...INITIAL_SAVE_NOTIFICATION_FLAG_STATE,
                ...INITIAL_SEND_NOTIFICATION_FLAG_STATE,
                save_notification_failed: true
            };

        case SAVE_NOTIFICATION_SUCCESS:
            return {
                ...state,
                ...INITIAL_SAVE_NOTIFICATION_FLAG_STATE,
                ...INITIAL_SEND_NOTIFICATION_FLAG_STATE,
                save_notification_success: true,
            };

        case NOTIFICATIONS_FAILED:
            return {
                ...state,
                ...INITIAL_SAVE_NOTIFICATION_FLAG_STATE,
                ...INITIAL_SEND_NOTIFICATION_FLAG_STATE,
                notifications_pending: true
            };

        case NOTIFICATIONS:
            let notifications = action.payload;
            if(notifications === undefined || notifications === null){
                notifications = [];
            }
            return {
                ...state,
                ...INITIAL_NOTIFICATIONS_FLAG_STATE,
                notifications
            };

        case DELETE_NOTIFICATION_SUCCESS:
            return state;

        case DELETE_NOTIFICATION_FAILED:
            return {
                ...state,
                ...INITIAL_NOTIFICATION_DELETE_FLAG_STATE,
                delete_notification_failed: true
            };

        case SEND_NOTIFICATION_FAILED:
            return {
                ...state,
                ...INITIAL_SAVE_NOTIFICATION_FLAG_STATE,
                ...INITIAL_SEND_NOTIFICATION_FLAG_STATE,
                send_notification_failed: true
            };

        case SEND_NOTIFICATION_SUCCESS:
            return {
                ...state,
                ...INITIAL_SAVE_NOTIFICATION_FLAG_STATE,
                ...INITIAL_SEND_NOTIFICATION_FLAG_STATE,
                notification_sent: true
            };

        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default notificationsReducer;