import React  from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import routes from '../../routes';
import { Router } from 'react-router';
import ReactGA from 'react-ga';
import Apxor from 'apxor';
import Package from '../../../package.json';

let previousPathname = null;

/**
 * Initializing Google Analytics
 */
ReactGA.initialize(window.gaTrackingId);

/**
 * Initializing Apxor
 */
Apxor.init("f4ca930f-dad0-4700-931e-3f4baa1c52a1", {
    version: Package.version
});

Apxor.setSessionProperties({"dashboard_version": Package.version});

/**
 * logs the new page view
 */
function logPageView() {
    const pathname = window.location.pathname;
    if(previousPathname && previousPathname !== pathname){
        Apxor.logPageView(pathname);
        ReactGA.set({ page: pathname });
        ReactGA.pageview(pathname);
    }
    previousPathname = pathname;
}


const Root = ({ store, history }) => (
  <Provider store={store}>
      <Router history={history} routes={routes} onUpdate={logPageView}/>
  </Provider>
);

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default Root
