/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../api';
import {makeDefaultQueryString} from '../../../../../../utils';
import {formatTime, withQueryStrings} from "../../../../../../utils/index";

const CONTEXTS_API = "contexts";

/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @returns {Promise}
 */
export function getContextsAPI(appId, auth, queryParams) {
    const url = makeDefaultQueryString(CONTEXTS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


export function getContextAPI(appId, auth, contextId, queryParams) {
    const url = makeDefaultQueryString(CONTEXTS_API + '/' + contextId, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function saveContextAPI(appId, auth, queryParams, context) {
    const url = makeDefaultQueryString(CONTEXTS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(context)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function updateContextAPI(appId, auth, contextId, queryParams, context) {
    const url = makeDefaultQueryString(CONTEXTS_API + '/' + contextId, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "PUT",
        auth: auth,
        body: JSON.stringify(context)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function deleteContextAPI(appId, auth, contextId, queryParams) {
    const url = makeDefaultQueryString(CONTEXTS_API + '/' + contextId, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "DELETE",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}