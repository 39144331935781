import React from 'react';
import {
    LOGICAL_OPERATORS,
    CONDTIONAL_OPERATORS,  COUNT_TYPE_ENUM
} from '../../../../../../../constants';
import MultiSelect from '../../../../../../../components/reusable/MaterialUi/MultiSelect';
import Box from "../../../../../../../components/reusable/Box";
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import PeopleIcon from '@material-ui/icons/People';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import FilterIcon from '@material-ui/icons/FilterList';
import WebAssetIcon from '@material-ui/icons/WebAsset';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, Typography} from "@material-ui/core";
import Switch from "../../../../../../../components/reusable/Switch";
import CreatorHolder from "../components/CreatorHolder";
import Count from "../../SelfComponents/components/Count";
import {uniqueKeyFromFilters, getGroupNameFromList } from "../../../../../../../utils";
import Trend from "../../SelfComponents/components/Trend";
import Components, { COMPONENT_IDS } from '../../SelfComponents';
import TimeSpent from "../../SelfComponents/components/TimeSpent";
import DistributionOverEvent from "../../SelfComponents/components/DistributionOverEvent";
import HourlyEventDistribution from "../../SelfComponents/components/HourlyEventDistribution";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AggregatedTrend, { AGGREGATE_ENUM } from "../../SelfComponents/components/AggregatedTrend";
import RetentionTrend from "../../SelfComponents/components/RetentionTrend";
import TabLabel from "../../../../../../../components/reusable/TabLabel";
import Stickiness from "../../SelfComponents/components/Stickiness";

const Operators = [
    {label: ">", value: LOGICAL_OPERATORS.GT, lingual: "greater"},
    {label: "≥", value: LOGICAL_OPERATORS.GTE, lingual: "greater or equal"},
    {label: "<", value: LOGICAL_OPERATORS.LT, lingual: "less"},
    {label: "≤", value: LOGICAL_OPERATORS.LTE, lingual: "less or equal"},
    {label: "=", value: LOGICAL_OPERATORS.EQ, lingual: "is"},
    {label: "≠", value: LOGICAL_OPERATORS.NEQ, lingual: "is not"}
];

export class AttributeBuilder extends React.Component {

    constructor(props){
        super(props);
        const { name, value = [], operator } = props;
        this.state = {
            name, value, operator
        };
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const {
            attributes = [], values = [],
            disabled = false,
            getRecommendations, deleteAttribute, index
        } = this.props;
        const { name, value, operator} = this.state;
        return(
            <Grid container justify="flex-end" spacing={16}>
                <Grid item xs={12} md={3}>
                    <MultiSelect
                        value={name}
                        options={attributes.map(o => ({label: o, value: o}))}
                        placeholder="Select Property"
                        handleChange={(name) => {
                            this.setState({name}, () => {
                                this.handleUpdate();
                                if(name && name.length) {
                                    getRecommendations(name, "");
                                }
                            }, this.handleUpdate);
                        }}
                        single
                        disabled={disabled}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <MultiSelect
                        value={operator}
                        options={ Operators }
                        placeholder="Condition"
                        handleChange={operator => this.setState({operator}, this.handleUpdate)}
                        single
                        disabled={disabled}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={12} md>
                    <MultiSelect
                        placeholder="Search.."
                        options={
                            [...value.map(o => ({label: o, value: o})), ...values.map(item => ({label: item, value: item}))]
                        }
                        handleChange={(value) => {
                            this.setState({value}, this.handleUpdate);
                        }}
                        onInputChange={(q, callback) => {
                            getRecommendations(name, q);
                        }}
                        isAsync
                        value={value}
                        disabled={disabled}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={12} md={1}>
                    <Tooltip id={"attribute-filter-remove-" + name + index} title="Remove Filter" placement="top">
                        <IconButton aria-label="Remove" onClick={() => deleteAttribute(index)}>
                            <RemoveIcon color="action"/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }

}


export class EventBuilder extends React.Component {

    constructor(props) {
        super(props);
        const { name, count, attributes = [], days, start_time, end_time } = props;
        this.state = {
            name, count, attributes,
            days, start_time, end_time,
        };
    }

    componentWillMount(){
        const {
            name, params: { appId }, getEventAttributes
        } = this.props;
        if(name && name.length) {
            getEventAttributes(appId, name);
        }
    }

    handleUpdate = () => {
        const { attributes = [], ...others } = this.state;
        let validAttributes = attributes.filter(o => o.name && o.name.length > 0); //checking for empty name
        this.props.handleUpdate({attributes: validAttributes, ...others});
    };

    handleAttributeUpdate = (index) => (attribute) => {
        const { attributes } = this.state;
        this.setState({attributes: [...attributes.slice(0, index), attribute, ...attributes.slice(index + 1)]}, this.handleUpdate);
    };

    addAttribute = () => {
        const newAttributeFilter = {name: "", operator: LOGICAL_OPERATORS.EQ, value: []};
        this.setState({attributes: [...this.state.attributes, newAttributeFilter]});
    };

    deleteAttribute = (index) => {
        const { attributes } = this.state;
        this.setState({attributes: [...attributes.slice(0, index), ...attributes.slice(index + 1)]}, this.handleUpdate);
    };

    render(){
        const {
            params: { appId },
            disabled = false,
            getEventAttributes, getEventAttributeValues
        } = this.props;
        const {
            customQueryBuilder: {
                events = [], event_attributes = {}, event_attribute_values = {}
            }
        } = this.props;
        const {
            name,
            attributes = [] } = this.state;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12} md={3}>
                    <MultiSelect
                        value={name}
                        options={events.map(o => ({label: o, value: o}))}
                        placeholder="Select Event"
                        handleChange={(name) => {
                            this.setState({name}, this.handleUpdate);
                            if(name && name.length) {
                                getEventAttributes(appId, name);
                            }else{
                                this.setState({attributes: []}, this.handleUpdate); //resetting attributes
                            }
                        }}
                        single
                        disabled={disabled}
                        margin="dense"
                    />
                </Grid>
                <Grid item xs={12} md>
                    {
                        attributes.map((attribute, index) =>
                            <div key={name + attribute.name + index}>
                                <AttributeBuilder
                                    {...this.props}
                                    {...attribute}
                                    deleteAttribute={this.deleteAttribute}
                                    index={index}
                                    attributes={event_attributes[name] || []}
                                    values={event_attribute_values[name][attribute.name]}
                                    handleUpdate={this.handleAttributeUpdate(index)}
                                    getRecommendations={(attribute, q) => getEventAttributeValues(appId, name, attribute, q)}
                                />
                            </div>
                        )
                    }
                    {
                        event_attribute_values.hasOwnProperty(name) && <Button color="primary" onClick={this.addAttribute} style={{margin:'10px 0'}}>
                            Add Filter
                            <FilterIcon />
                        </Button>
                    }
                </Grid>
            </Grid>
        )
    }

}

export class AttributeBuilderWrapper extends React.Component {

    constructor(props) {
        super(props);
        const { attributes = [] } = props;
        this.state = { attributes };
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state.attributes);
    };

    handleAttributeUpdate = (index) => (attribute) => {
        const { attributes } = this.state;
        this.setState({attributes: [...attributes.slice(0, index), attribute, ...attributes.slice(index + 1)]}, this.handleUpdate);
    };

    addAttribute = () => {
        const newAttributeFilter = {name: "", operator: LOGICAL_OPERATORS.EQ, value: []};
        this.setState({attributes: [...this.state.attributes, newAttributeFilter]});
    };

    deleteAttribute = (index) => {
        const { attributes = [] } = this.state;
        this.setState({attributes: [...attributes.slice(0, index), ...attributes.slice(index + 1)]}, this.handleUpdate);
    };

    render(){
        const {
            params: { appId },
            title, disabled = false, data, values,
            getAttributeValues
        } = this.props;
        const { attributes = [] } = this.state;
        return(
            <Box
                title={title}
                collapsible
                withPadding
            >
                {
                    attributes.map((attribute, index) =>
                        <div key={attribute.name + index}>
                            <AttributeBuilder
                                {...this.props}
                                {...attribute}
                                deleteAttribute={this.deleteAttribute}
                                index={index}
                                attributes={data}
                                values={values[attribute.name] || []}
                                handleUpdate={this.handleAttributeUpdate(index)}
                                getRecommendations={(attribute, q) => getAttributeValues(appId, attribute, q)}
                            />
                        </div>
                    )
                }
                <Button disabled={disabled} color="primary" onClick={this.addAttribute} style={{margin:'10px 0'}}>
                    Add Property
                    <WebAssetIcon />
                </Button>
            </Box>
        )
    }

}


const CURRENT_SELECTED = {
    ALL: "All",
    EVENT: "Event",
    SEGMENT: "Segment"
};

export class CustomQueryBuilder extends React.Component {

    constructor(props){
        super(props);
        const {
            name = "",
            user = [],
            session = [],
            event = [],
            condition = CONDTIONAL_OPERATORS.AND,
            params: { appId }
        } = props;
        this.state = {
            name, user, session, event, condition,
            positiveEvents: event.filter(o => o.count.value > 0),
            negativeEvents: event.filter(o => o.count.value === 0),
            saveClicked: false,
            currentSelected: CURRENT_SELECTED.ALL
        };
        this.appId = appId;
    }

    handleUpdate = (getCount = true) => {
        const {
            positiveEvents = [], negativeEvents = [],
            user = [], session = [],
            saveClicked, currentSelected,
            ...others
        } = this.state;
        const { handleQueryUpdate, getSegmentCount, handleFiltersChange  } = this.props;
        //below lines to ensure everything name selected for each filter
        const validUserProperties = user.filter(o => o.name && o.name.length > 0);
        const validSessionProperties = session.filter(o => o.name && o.name.length > 0);
        const validPositiveEvents = positiveEvents.filter(o => o.name && o.name.length > 0);
        const validNegativeEvents = negativeEvents.filter(o => o.name && o.name.length > 0);
        handleQueryUpdate({
            ...others,
            user: validUserProperties,
            session: validSessionProperties,
            event: [ ...validPositiveEvents, ...validNegativeEvents ]
        });
        handleFiltersChange(true);
        if(getCount){
            getSegmentCount(this.appId);
        }
    };

    addEvent = (countValue = 1) => {
        const newEvent = {
            name: "",
            count: {value: countValue, operator: countValue === 1 ? LOGICAL_OPERATORS.GTE : LOGICAL_OPERATORS.EQ},
            attributes: []
        };
        if(countValue > 0){
            this.setState({positiveEvents: [...this.state.positiveEvents, newEvent]});
        }else{
            this.setState({negativeEvents: [...this.state.negativeEvents, newEvent]});
        }
    };

    handlePositiveEventUpdate = (index) => (event) => {
        const { positiveEvents } = this.state;
        this.setState({positiveEvents: [...positiveEvents.slice(0, index), event, ...positiveEvents.slice(index + 1)]}, this.handleUpdate);
    };

    deletePositiveEvent = (index) => {
        const { positiveEvents } = this.state;
        this.setState({positiveEvents: [...positiveEvents.slice(0, index), ...positiveEvents.slice(index + 1)]}, this.handleUpdate);
    };

    handleApply = () => {
        this.props.handleFiltersChange(false);
    };

    componentWillReceiveProps(nextProps){
        const { saved } = nextProps.customQueryBuilder;
        if(this.state.saveClicked && saved && saved._id){
            //this.props.resetQuery();
        }
    }

    render(){
        const {
            customQueryBuilder: {
                count, user_attributes, session_attributes, attribute_values,
                segment
            },
            handleSegmentUpdate,
            appState: { appSegments = [] },
            readyToApply, handleFiltersChange,
            handleCancel
        } = this.props;
        const {
            user = [], session = [],
            positiveEvents = [],currentSelected
        } = this.state;
        return(
            <Box
                title=""
                headerWithBorder
                footer={
                    <Grid container justify="flex-end">
                        <Grid item xs style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                size="small"
                                color="secondary"
                                onClick={handleCancel}
                                style={{margin: '0 10px'}}
                            >
                                Cancel
                            </Button>
                            <Button
                                disabled={!readyToApply}
                                size="small"
                                color="primary"
                                onClick={this.handleApply}
                            >
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                }
                controls={
                    currentSelected !== CURRENT_SELECTED.SEGMENT && <div style={{display: 'flex'}}>
                        <PeopleIcon color="primary"/>
                        <Typography variant="h6" title="Users in selected query" style={{margin: '0 10px'}}>{ count }</Typography>
                    </div>
                }
            >
                <AttributeBuilderWrapper
                    {...this.props}
                    title="User Properties"
                    attributes={user}
                    data={user_attributes}
                    values={attribute_values}
                    handleUpdate={attributes => this.setState({user: attributes}, this.handleUpdate)}
                />
                <AttributeBuilderWrapper
                    {...this.props}
                    title="Session Properties"
                    attributes={session}
                    data={session_attributes}
                    values={attribute_values}
                    handleUpdate={attributes => this.setState({session: attributes}, this.handleUpdate)}
                />
                <Switch
                    data={Object.values(CURRENT_SELECTED)}
                    handleChange={(currentSelected) => {
                        this.setState({currentSelected});
                        handleSegmentUpdate(null);
                        if(positiveEvents.length > 0){
                            this.deletePositiveEvent(0);
                        }
                        if(currentSelected === CURRENT_SELECTED.EVENT){
                            this.addEvent(1);
                        }
                        if(currentSelected === CURRENT_SELECTED.ALL){
                            handleFiltersChange(true);
                        }
                    }}
                    value={currentSelected}
                />
                {
                    currentSelected === CURRENT_SELECTED.SEGMENT &&
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={3}>
                            <MultiSelect
                                options={appSegments.map(o => ({label: o.name, value: o._id}))}
                                handleChange={segment => {
                                    handleSegmentUpdate(segment);
                                    handleFiltersChange(true);
                                }}
                                value={segment}
                                placeholder="Select Segment"
                                single
                                margin="dense"
                            />
                        </Grid>
                    </Grid>
                }
                {
                    positiveEvents.map((event, index) =>
                        <div key={event.name + index}>
                            <EventBuilder {...this.props} positive {...event} handleUpdate={this.handlePositiveEventUpdate(index)}/>
                        </div>
                    )
                }
            </Box>
        )
    }

}

const countWidget = Components.get(COMPONENT_IDS.COUNT);
const trendWidget = Components.get(COMPONENT_IDS.TREND);
const aggregatedTrendWidget = Components.get(COMPONENT_IDS.AGGREGATED_TREND);
const retentionTrendWidget = Components.get(COMPONENT_IDS.RETENTION_TREND);
const timeSpentWidget = Components.get(COMPONENT_IDS.TIME_SPENT);
const hourlyEventDistributionWidget = Components.get(COMPONENT_IDS.HOURLY_EVENT_DISTRIBUTION);
const eventCountDistributionWidget = Components.get(COMPONENT_IDS.DISTRIBUTION_OVER_EVENT);
const tabbedWidget = Components.get(COMPONENT_IDS.TABBED_WIDGET);
const stickinessWidget = Components.get(COMPONENT_IDS.EVENT_STICKINESS);

export class DummyComponent extends React.Component {
    render(){
        return this.props.children;
    }
}

export class ComponentHolder extends React.Component {

    state = {
        currentSegment: 0,
        currentEvent: 0,
        currentGlobal: 0,
        widgetLocalData: {}
    };

    getDataKey = (props) => {
        const { name, queryParams, extraFilters, extension  } = props;
        const keyFromQueryParams = Object.keys(queryParams).map(o => [o, queryParams[o]].join("_")).join("_");
        return [ name, keyFromQueryParams, uniqueKeyFromFilters(extraFilters), extension ].filter(o => o.length > 0).join("_");
    };

    getCustomLabel = (props) => {
        const { name, extension  } = props;
        return `${name} ${extension}`;
    };

    render(){
        let {
            customQueryBuilder: {
                group, segment, query
            },
            appState: { appSegments = [] },
            handleAdd
        } = this.props;
        let segmentName = null;

        let constantALLQuery = {
            "name": "",
            "user": [],
            "session": [],
            "event": [
                {
                    "attributes": [],
                    "name": "apx_app_opened",
                    "count": {
                        "value": 1,
                        "operator": "GTE"
                    }
                }
            ],
            "condition": "AND"
        }

        if(segment){
            segmentName = getGroupNameFromList(appSegments, segment);
        }
        let event = null;
        if(query.event && query.event.length > 0){
            event = query.event[0].name;
        }
        let groupName = null;
        groupName = ""; //readableUserGroupName(group);FIXME: remove this seamlessly
        const queryParams = { event, group };
        const { currentEvent, currentGlobal, currentSegment, widgetLocalData } = this.state;
        return(
            <Grid container>
                <Grid item xs>
                    <CreatorHolder
                        handleAdd={handleAdd}
                        widget={tabbedWidget}
                        selfParams={{
                            segment: segment,
                            event: event,
                            queryParams,
                            extraFilters: query,
                            customLabel: segmentName || event || 'User Group'
                        }}
                    >
                        <DummyComponent>
                            <Box title={segmentName || event || 'User Group'}>
                                {
                                    segment &&
                                    <Grid container spacing={16}>
                                        <Grid item xs>
                                            <AppBar position="static" color="default">
                                                <Tabs
                                                    value={currentSegment}
                                                    onChange={(e, currentSegment) => this.setState({currentSegment})}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                >
                                                    <Tab label="Users Count" />
                                                    <Tab label="Users Trend" />
                                                </Tabs>
                                            </AppBar>
                                            {currentSegment === 0 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        segment: segment,
                                                        queryParams,
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: segmentName,
                                                            queryParams,
                                                            extraFilters: query,
                                                            extension: "Users"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: segmentName,
                                                            extension: `Users`
                                                        }),
                                                        segmentName: segmentName,
                                                        of: COUNT_TYPE_ENUM.users
                                                    }}
                                                    widget={countWidget}
                                                >
                                                    <Count />
                                                </CreatorHolder>
                                            </div>}
                                            {currentSegment === 1 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams = {{
                                                        segment: segment,
                                                        queryParams,
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: getGroupNameFromList(appSegments, segment),
                                                            queryParams,
                                                            extraFilters: query,
                                                            extension: "Trend"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: getGroupNameFromList(appSegments, segment),
                                                            extension: `Users Trend`
                                                        }),
                                                        segmentName: segmentName,
                                                        of: "users"
                                                    }}
                                                    widget={trendWidget}
                                                >
                                                    <Trend />
                                                </CreatorHolder>
                                            </div>}
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    event &&
                                    <Grid container spacing={16}>
                                        <Grid item xs>
                                            <AppBar position="static" color="default">
                                                <Tabs
                                                    value={currentEvent}
                                                    onChange={(e, currentEvent) => this.setState({currentEvent})}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                >
                                                    <Tab label={<TabLabel help="Event, User and Session metrics for selected event">Metrics</TabLabel>} />
                                                    <Tab label="Event Trend" />
                                                    <Tab label="Users Trend" />
                                                    <Tab label="Sessions Trend" />
                                                    <Tab label={<TabLabel help="Average number of times the selected metric is performed per day">Feature Engagement (Day)</TabLabel>} />
                                                    <Tab label={<TabLabel help="Average number of times the selected metric is performed per session">Feature Engagement (Session)</TabLabel>} />
                                                    <Tab label={<TabLabel help="% of daily active users who perform the selected metric">Feature Consumption (Trend)</TabLabel>} />
                                                    <Tab label={<TabLabel help="Hourly distribution of selected events">Hourly Distribution</TabLabel>} />
                                                    <Tab label={<TabLabel help="Number of users who consume a feature -  number of times">Feature Consumption (Strength)</TabLabel>} />
                                                    {/*<Tab label="Session Distribution" />*/}
                                                    {/*<Tab label={<TabLabel help="Average session lengths per feature consumption">Feature Consumption Vs Engagement</TabLabel>} />*/}
                                                    {/*<Tab label="Stickiness" />*/}
                                                </Tabs>
                                            </AppBar>
                                            {currentEvent === 0 && <div>
                                                <Grid container spacing={16}>
                                                    <Grid item xs={12} md={4}>
                                                        <CreatorHolder
                                                            {...this.props}
                                                            selfParams={{
                                                                event: event,
                                                                queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                                extraFilters: query,
                                                                dataKey: this.getDataKey({
                                                                    name: event,
                                                                    queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                                    extraFilters: query,
                                                                    extension: "Count"
                                                                }),
                                                                customLabel: this.getCustomLabel({
                                                                    name: event,
                                                                    extension: `Event Count`
                                                                }),
                                                                of: COUNT_TYPE_ENUM.event
                                                            }}
                                                            widget={countWidget}
                                                        >
                                                            <Count />
                                                        </CreatorHolder>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <CreatorHolder
                                                            {...this.props}
                                                            selfParams={{
                                                                event: event,
                                                                queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                                                extraFilters: query,
                                                                dataKey: this.getDataKey({
                                                                    name: event,
                                                                    queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                                                    extraFilters: query,
                                                                    extension: "Users"
                                                                }),
                                                                customLabel: this.getCustomLabel({
                                                                    name: event,
                                                                    extension: `Users`
                                                                }),
                                                                of: COUNT_TYPE_ENUM.users
                                                            }}
                                                            widget={countWidget}
                                                        >
                                                            <Count />
                                                        </CreatorHolder>
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <CreatorHolder
                                                            {...this.props}
                                                            selfParams={{
                                                                event: event,
                                                                queryParams: {...queryParams, of: COUNT_TYPE_ENUM.sessions},
                                                                extraFilters: query,
                                                                dataKey: this.getDataKey({
                                                                    name: event,
                                                                    queryParams: {...queryParams, of: COUNT_TYPE_ENUM.sessions},
                                                                    extraFilters: query,
                                                                    extension: "Sessions"
                                                                }),
                                                                customLabel: this.getCustomLabel({
                                                                    name: event,
                                                                    extension: `Sessions`
                                                                }),
                                                                of: COUNT_TYPE_ENUM.sessions
                                                            }}
                                                            widget={countWidget}
                                                        >
                                                            <Count />
                                                        </CreatorHolder>
                                                    </Grid>
                                                </Grid>
                                            </div>}
                                            {currentEvent === 1 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: event,
                                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: event,
                                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                            extraFilters: query,
                                                            extension: "Trend"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: event,
                                                            extension: `Event Trend`
                                                        }),
                                                        of: COUNT_TYPE_ENUM.event
                                                    }}
                                                    widget={trendWidget}
                                                >
                                                    <Trend />
                                                </CreatorHolder>
                                            </div>}
                                            {currentEvent === 2 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: event,
                                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: event,
                                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                                            extraFilters: query,
                                                            extension: "users_trend"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: event,
                                                            extension: `Users Trend`
                                                        }),
                                                        of: COUNT_TYPE_ENUM.users
                                                    }}
                                                    widget={trendWidget}
                                                >
                                                    <Trend />
                                                </CreatorHolder>
                                            </div>}
                                            {currentEvent === 3 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: event,
                                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.sessions},
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: event,
                                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.sessions},
                                                            extraFilters: query,
                                                            extension: "sessions_trend"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: event,
                                                            extension: `Sessions Trend`
                                                        }),
                                                        of: COUNT_TYPE_ENUM.sessions
                                                    }}
                                                    widget={trendWidget}
                                                >
                                                    <Trend />
                                                </CreatorHolder>
                                            </div>}
                                            {currentEvent === 4 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: event,
                                                        queryParams: {...queryParams},
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: event,
                                                            queryParams: {...queryParams},
                                                            extraFilters: query,
                                                            extension: "aggregate_per_user_trend"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: event,
                                                            extension: `Events Per User`
                                                        }),
                                                        aggregateType: AGGREGATE_ENUM.PER_USER
                                                    }}
                                                    widget={aggregatedTrendWidget}
                                                >
                                                    <AggregatedTrend withRetainedDay={false}/>
                                                </CreatorHolder>
                                            </div>}
                                            {currentEvent === 5 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: event,
                                                        queryParams: {...queryParams},
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: event,
                                                            queryParams: {...queryParams},
                                                            extraFilters: query,
                                                            extension: "aggregate_per_session_trend"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: event,
                                                            extension: `Events Per Session`
                                                        }),
                                                        aggregateType: AGGREGATE_ENUM.PER_SESSION
                                                    }}
                                                    widget={aggregatedTrendWidget}
                                                >
                                                    <AggregatedTrend withRetainedDay={false}/>
                                                </CreatorHolder>
                                            </div>}
                                            {currentEvent === 6 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: event,
                                                        queryParams: {...queryParams},
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: event,
                                                            queryParams: {...queryParams},
                                                            extraFilters: query,
                                                            extension: "aggregate_percent_of_dau_trend"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: event,
                                                            extension: `Events Done By Percentage Of DAU`
                                                        }),
                                                        aggregateType: AGGREGATE_ENUM.PERCENT_OF_DAU
                                                    }}
                                                    widget={aggregatedTrendWidget}
                                                >
                                                    <AggregatedTrend withRetainedDay={false}/>
                                                </CreatorHolder>
                                            </div>}
                                            {currentEvent === 7 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: event,
                                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: event,
                                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                            extraFilters: query,
                                                            extension: "hourly_distribution"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: event,
                                                            extension: `Hourly Distribution`
                                                        }),
                                                        of: COUNT_TYPE_ENUM.event
                                                    }}
                                                    widget={hourlyEventDistributionWidget}
                                                >
                                                    <HourlyEventDistribution />
                                                </CreatorHolder>
                                            </div>}
                                            {currentEvent === 8 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: event,
                                                        queryParams: {...queryParams},
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: event,
                                                            queryParams: {...queryParams},
                                                            extraFilters: query,
                                                            extension: "users_distribution"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: event,
                                                            extension: `Users Distribution`
                                                        }),
                                                        of: COUNT_TYPE_ENUM.users
                                                    }}
                                                    widget={eventCountDistributionWidget}
                                                >
                                                    <DistributionOverEvent />
                                                </CreatorHolder>
                                            </div>}
                                            {/*{currentEvent === 9 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: event,
                                                        queryParams: {...queryParams},
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: event,
                                                            queryParams: {...queryParams},
                                                            extraFilters: query,
                                                            extension: "sessions_distribution"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: event,
                                                            extension: `Sessions Distribution`
                                                        }),
                                                        of: COUNT_TYPE_ENUM.sessions
                                                    }}
                                                    widget={eventCountDistributionWidget}
                                                >
                                                    <DistributionOverEvent />
                                                </CreatorHolder>
                                            </div>}*/}
                                            {currentEvent === 9 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: event,
                                                        queryParams: {...queryParams},
                                                        extraFilters: query,
                                                        dataKey: this.getDataKey({
                                                            name: event,
                                                            queryParams: {...queryParams},
                                                            extraFilters: query,
                                                            extension: "session_length_distribution"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: event,
                                                            extension: `Avg Session Length Distribution (Sec)`
                                                        }),
                                                        of: COUNT_TYPE_ENUM.session_length
                                                    }}
                                                    widget={eventCountDistributionWidget}
                                                >
                                                    <DistributionOverEvent />
                                                </CreatorHolder>
                                            </div>}
                                            {
                                                currentEvent === 10 &&
                                                <Grid container>
                                                    <Grid item xs={12}>
                                                        <CreatorHolder
                                                            {...this.props}
                                                            selfParams={{
                                                                event: event,
                                                                queryParams: {...queryParams, event, ...widgetLocalData},
                                                                extraFilters: query,
                                                                dataKey: this.getDataKey({
                                                                    name: event,
                                                                    queryParams: {...queryParams, event},
                                                                    extraFilters: query,
                                                                    extension: "Stickiness"
                                                                }),
                                                                customLabel: `${event} Stickiness ${widgetLocalData.groupBy ? ("grouped by " + widgetLocalData.groupBy) : ""}`
                                                            }}
                                                            widget={stickinessWidget}
                                                        >
                                                            <Stickiness raw handleLocalUpdate={widgetLocalData => this.setState({widgetLocalData})}/>
                                                        </CreatorHolder>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                }
                                {
                                    !segment && !event &&
                                    <Grid container spacing={16}>
                                        <Grid item xs>
                                            <AppBar position="static" color="default">
                                                <Tabs
                                                    value={currentGlobal}
                                                    onChange={(e, currentGlobal) => this.setState({currentGlobal})}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                >
                                                    <Tab label={<TabLabel help="Event, User and Session metrics for selected event">Metrics</TabLabel>} />
                                                    <Tab label="Users Trend" />
                                                    <Tab label="Sessions Trend" />
                                                    {/*<Tab label="Time Spent Trend" />*/}
                                                    {/*<Tab label="Retention Trend" />*/}
                                                </Tabs>
                                            </AppBar>
                                            {currentGlobal === 0 && <div>
                                                <Grid container spacing={16}>
                                                    <Grid item xs={12} md={6}>
                                                        <CreatorHolder
                                                            {...this.props}
                                                            selfParams={{
                                                                queryParams: {group, of: COUNT_TYPE_ENUM.users},
                                                                extraFilters: query,
                                                                dataKey: this.getDataKey({
                                                                    name: groupName,
                                                                    queryParams: {group, of: COUNT_TYPE_ENUM.users},
                                                                    extraFilters: query,
                                                                    extension: "users_count"
                                                                }),
                                                                customLabel: this.getCustomLabel({
                                                                    name: groupName,
                                                                    extension: "Users"
                                                                }),
                                                                of: COUNT_TYPE_ENUM.users
                                                            }}
                                                            widget={countWidget}
                                                        >
                                                            <Count />
                                                        </CreatorHolder>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CreatorHolder
                                                            {...this.props}
                                                            selfParams={{
                                                                queryParams: {group, of: COUNT_TYPE_ENUM.sessions},
                                                                extraFilters: query,
                                                                dataKey: this.getDataKey({
                                                                    name: groupName,
                                                                    queryParams: {group, of: COUNT_TYPE_ENUM.sessions},
                                                                    extraFilters: query,
                                                                    extension: "sessions_count"
                                                                }),
                                                                customLabel: this.getCustomLabel({
                                                                    name: groupName,
                                                                    extension: "Sessions"
                                                                }),
                                                                of: COUNT_TYPE_ENUM.sessions
                                                            }}
                                                            widget={countWidget}
                                                        >
                                                            <Count />
                                                        </CreatorHolder>
                                                    </Grid>
                                                </Grid>
                                            </div>}
                                            {currentGlobal === 1 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: "apx_app_opened",
                                                        queryParams: {...queryParams, event: "apx_app_opened", of: COUNT_TYPE_ENUM.users},
                                                        extraFilters: constantALLQuery,
                                                        dataKey: this.getDataKey({
                                                            name: "apx_app_opened",
                                                            queryParams: {...queryParams, event: "apx_app_opened", of: COUNT_TYPE_ENUM.users},
                                                            extraFilters: constantALLQuery,
                                                            extension: "users_trend"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: groupName,
                                                            extension: "Users Trend"
                                                        }),
                                                        of: COUNT_TYPE_ENUM.users
                                                    }}
                                                    widget={trendWidget}
                                                >
                                                    <Trend />
                                                </CreatorHolder>
                                            </div>}
                                            {currentGlobal === 2 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        event: "apx_app_opened",
                                                        queryParams: {...queryParams, event: "apx_app_opened", of: COUNT_TYPE_ENUM.users},
                                                        extraFilters: constantALLQuery,
                                                        dataKey: this.getDataKey({
                                                            name: "apx_app_opened",
                                                            queryParams: {...queryParams, event: "apx_app_opened", of: COUNT_TYPE_ENUM.users},
                                                            extraFilters: constantALLQuery,
                                                            extension: "sessions_trend"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: groupName,
                                                            extension: "Sessions Trend"
                                                        }),
                                                        of: COUNT_TYPE_ENUM.sessions
                                                    }}
                                                    widget={trendWidget}
                                                >
                                                    <Trend />
                                                </CreatorHolder>
                                            </div>}
                                            {/*{currentGlobal === 3 && <div>*/}
                                            {/*    <CreatorHolder*/}
                                            {/*        {...this.props}*/}
                                            {/*        selfParams={{*/}
                                            {/*            queryParams: {group},*/}
                                            {/*            extraFilters: query,*/}
                                            {/*            dataKey: this.getDataKey({*/}
                                            {/*                name: groupName,*/}
                                            {/*                queryParams: {group},*/}
                                            {/*                extraFilters: query,*/}
                                            {/*                extension: "time_spent"*/}
                                            {/*            }),*/}
                                            {/*            customLabel: this.getCustomLabel({*/}
                                            {/*                name: groupName,*/}
                                            {/*                extension: "Time Spent"*/}
                                            {/*            })*/}
                                            {/*        }}*/}
                                            {/*        widget={timeSpentWidget}*/}
                                            {/*    >*/}
                                            {/*        <TimeSpent />*/}
                                            {/*    </CreatorHolder>*/}
                                            {/*</div>}*/}
                                            {/*{currentGlobal === 4 && <div>*/}
                                            {/*    <CreatorHolder*/}
                                            {/*        {...this.props}*/}
                                            {/*        selfParams={{*/}
                                            {/*            queryParams: {group},*/}
                                            {/*            extraFilters: query,*/}
                                            {/*            dataKey: this.getDataKey({*/}
                                            {/*                name: groupName,*/}
                                            {/*                queryParams: {group},*/}
                                            {/*                extraFilters: query,*/}
                                            {/*                extension: "retention_trend"*/}
                                            {/*            }),*/}
                                            {/*            customLabel: this.getCustomLabel({*/}
                                            {/*                name: groupName,*/}
                                            {/*                extension: "Retention Trend"*/}
                                            {/*            }),*/}
                                            {/*            of: COUNT_TYPE_ENUM.users*/}
                                            {/*        }}*/}
                                            {/*        widget={retentionTrendWidget}*/}
                                            {/*    >*/}
                                            {/*        <RetentionTrend />*/}
                                            {/*    </CreatorHolder>*/}
                                            {/*</div>}*/}
                                        </Grid>
                                    </Grid>
                                }
                            </Box>
                        </DummyComponent>
                    </CreatorHolder>
                </Grid>
            </Grid>
        )
    }

}

/**
 * Can be used anywhere
 * Required props:
 * getAttributes(appId),
 * getAttributeValues(appId, attribute, q),
 * handleQueryUpdate(query)
 */
export class UserAndSessionProperties extends React.Component {

    constructor(props){
        super(props);
        const {
            params: { appId },
            user = [],
            session = [],
        } = props;
        this.state = {
            user, session
        };
        this.appId = appId;
    }

    handleUpdate = (getCount = true) => {
        const { handleQueryUpdate  } = this.props;
        handleQueryUpdate({...this.state});
    };

    render(){
        const {
            customQueryBuilder: {
                user_attributes, session_attributes, attribute_values
            },
            withoutBox = false
        } = this.props;
        const {
            user = [], session = []
        } = this.state;
        if(withoutBox){
            return(
                <div>
                    <AttributeBuilderWrapper
                        {...this.props}
                        title="User Properties"
                        attributes={user}
                        data={user_attributes}
                        values={attribute_values}
                        handleUpdate={attributes => this.setState({user: attributes}, this.handleUpdate)}
                    />
                    <AttributeBuilderWrapper
                        {...this.props}
                        title="Session Properties"
                        attributes={session}
                        data={session_attributes}
                        values={attribute_values}
                        handleUpdate={attributes => this.setState({session: attributes}, this.handleUpdate)}
                    />
                </div>
            );
        }else{
            return(
                <Box
                    icon={<FilterIcon />}
                    title="Property Filters"
                    headerWithBorder
                >
                    <AttributeBuilderWrapper
                        {...this.props}
                        title="User Properties"
                        attributes={user}
                        data={user_attributes}
                        values={attribute_values}
                        handleUpdate={attributes => this.setState({user: attributes}, this.handleUpdate)}
                    />
                    <AttributeBuilderWrapper
                        {...this.props}
                        title="Session Properties"
                        attributes={session}
                        data={session_attributes}
                        values={attribute_values}
                        handleUpdate={attributes => this.setState({session: attributes}, this.handleUpdate)}
                    />
                </Box>
            )
        }
    }

}

/**
 * can be used anywhere
 * Required Props:
 * getEvents(appId),
 * getEventAttributes(appId, event),
 * getEventAttributeValues(appId, event, attribute, q),
 * handleQueryUpdate(query)
 */
export class EventProperties extends React.Component {

    constructor(props){
        super(props);
        const {
            params: { appId },
            event = [],
            condition = CONDTIONAL_OPERATORS.AND,
        } = props;
        this.state = {
            event, condition,
            positiveEvents: event.filter(o => o.count.value > 0),
            negativeEvents: event.filter(o => o.count.value === 0)
        };
        this.appId = appId;
    }

    handleUpdate = (getCount = true) => {
        const { positiveEvents, negativeEvents } = this.state;
        const { handleQueryUpdate } = this.props;
        handleQueryUpdate({event: [ ...positiveEvents, ...negativeEvents ]});
    };

    handlePositiveEventUpdate = (index) => (event) => {
        const { positiveEvents } = this.state;
        this.setState({positiveEvents: [...positiveEvents.slice(0, index), event, ...positiveEvents.slice(index + 1)]}, this.handleUpdate);
    };

    handleNegativeEventUpdate = (index) => (event) => {
        const { negativeEvents } = this.state;
        this.setState({negativeEvents: [...negativeEvents.slice(0, index), event, ...negativeEvents.slice(index + 1)]}, this.handleUpdate);
    };

    render(){
        const {
            event
        } = this.state;
        return(
            <Box
                title="Event"
                headerWithBorder
            >
                <EventBuilder {...this.props} positive {...event} handleUpdate={this.handlePositiveEventUpdate(0)}/>
            </Box>
        )
    }

}