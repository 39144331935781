/**
 * Created by Araja Jyothi Babu on 15-Nov-16.
 */
import React, {  } from 'react';
import PropTypes from 'prop-types';
import {Cell} from 'fixed-data-table-2'
export const SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
    return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

export class SortHeaderCell extends React.Component {
    constructor(props) {
        super(props);

        this._onSortChange = this._onSortChange.bind(this);
    }

    render() {
        var {onSortChange, sortDir, children, ...props} = this.props;
        return (
            <Cell {...props}>
                <span style={{cursor: 'pointer'}} onClick={this._onSortChange}>
                    {children} {sortDir ? (sortDir === SortTypes.DESC ? '↓' : '↑') : ''}
                </span>
            </Cell>
        );
    }

    _onSortChange(e) {
        e.preventDefault();

        if (this.props.onSortChange) {
            this.props.onSortChange(
                this.props.columnKey,
                this.props.sortDir ?
                    reverseSortDirection(this.props.sortDir) :
                    SortTypes.DESC
            );
        }
    }
}

SortHeaderCell.propTypes = {
    onSortChange: PropTypes.func.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string
    ]).isRequired,
    columnKey: PropTypes.string.isRequired
}