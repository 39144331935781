import React  from 'react';
import { DASHBOARD_ENDPOINTS } from "../../../../../../constants/EndPoints";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import AddIcon from '@material-ui/icons/Add';
import Placeholder from "../../../../../../components/reusable/Placeholder";
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ToolTip from "@material-ui/core/Tooltip/Tooltip";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardActions from "@material-ui/core/CardActions/CardActions";
import {Link} from "react-router";
import {Button, Grid, Typography, Fab} from "@material-ui/core";
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import ARTPublish from "../../../../../../components/reusable/ARTPublish";
import DeleteAction from "../../../../../../components/reusable/DeleteAction";
import ToggleSwitch from "../../../../../../components/reusable/MaterialUi/ToggleSwitch";

export class SurveysHolder extends React.Component{

    constructor(props){
        super(props);
        const { surveys = [] } = props.surveys;
        this.state = {
            expanded: null,
            surveys,
            query: ""
        };
    }

    componentWillMount(){

    }

    componentWillReceiveProps(nextProps){
        const { surveys: { surveys = [] }, meta: { api_pending } } = nextProps;
        if(!api_pending){
            this.updateSurveysWithQuery(surveys);
        }
    }

    updateSurveysWithQuery = (surveys = []) => {
        const query = this.state.query.toLowerCase();
        const updatedSurveys = surveys.filter(({name = "", conditions = []}) => {
            const inName = name.toLowerCase().includes(query);
            const inConditions = conditions.some(({activity = "", details, trigger}) => {
                const inActivity = activity.toLowerCase().includes(query);
                const inName = details.name.toLowerCase().includes(query);
                const inTrigger = trigger.details.name.toLowerCase().includes(query);
                return inActivity || inName || inTrigger;
            });
            return inName || inConditions;
        });
        this.setState({surveys: updatedSurveys});
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleQueryChange = (event) => {
        const { survey_list } = this.props.survey;
        this.setState({query: event.target.value}, () => this.updateSurveysWithQuery(survey_list));
    };


    render(){
        const { expanded, surveys = [] } = this.state;
        const {
            params: { appId }, surveys: { surveys: propSurveys = [] }
        } = this.props;
        return(
            <Grid container justify="center" spacing={24}>
                <Grid item xs={12} md={8}>
                    <Grid container justify="flex-end" spacing={16}>
                        <Grid item xs={12} sm={3} style={{marginBottom: 20}}>
                            <TextField
                                placeholder="Search Surveys"
                                id="query-adornment"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="end" style={{padding: '20px 0'}}><SearchIcon/></InputAdornment>
                                }}
                                onChange={this.handleQueryChange}
                            />
                        </Grid>
                        <Grid item xs={6} sm={1} style={{marginBottom: 20}}>
                            <Link to={`/apps/${appId}/${DASHBOARD_ENDPOINTS.SURVEYS}/new`} style={{spacing:10}} >
                                <ToolTip id="tooltip-fab-add" title="Create New Survey" placement="bottom">
                                    <Fab size="small" color="primary" aria-label="Create New Survey">
                                        <AddIcon />
                                    </Fab>
                                </ToolTip>
                            </Link>
                        </Grid>
                    </Grid>
                    {
                        surveys.map(survey =>
                            <SurveyHolder
                                key={survey._id}
                                {...this.props}
                                {...survey}
                                survey={survey}
                                handleChange={this.handleChange(survey._id)}
                                expanded={expanded}
                            />
                        )
                    }
                    {
                        !this.props.meta.api_pending && (!Array.isArray(propSurveys) || propSurveys.length === 0) &&
                        <Placeholder
                            withIcon
                            icon={<QuestionAnswerIcon style={{opacity: 0.2, width: 150, height: 150}} />}
                            text="To create your first survey, tap the plus on the top right of the dashboard."
                        />
                    }
                </Grid>
            </Grid>
        )

    }

}

export class SurveyHolder extends React.Component {

    state = {
        showTooltip: true
    };

    handleChange = (event, expanded) => {
        this.props.handleChange(event, expanded);
    };

    componentDidMount(){
        setTimeout(() => {
            this.setState({showTooltip: false});
        }, 5000);
    }

    render(){
        const {
            expanded, params: { appId },
            surveys: {
                survey_delete_failed
            },
            deleteSurvey, publishSurvey, updateSurvey,
            _id, meta: { name }, published, enabled,
            survey,
            location: { query: { saved, surveyName } }
        } = this.props;
        const { showTooltip } = this.state;
        return(
            <div style={{width: '100%'}}>
                <ExpansionPanel expanded={expanded === _id} onChange={this.handleChange}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container spacing={24}>
                            <Grid item xs>
                                <Typography variant="subtitle1" style={{marginTop: '0.8em'}}>{name}</Typography>
                            </Grid>
                            <Grid item xs={2} onClick={(e) => e.stopPropagation()}>
                                {saved && surveyName === name ?
                                    <ToolTip
                                        placement="left"
                                        title="Click here to publish"
                                        open={!published && showTooltip}
                                    >
                                        <ARTPublish
                                            published={published}
                                            publishAction={() => {
                                                publishSurvey(appId, _id);
                                            }}
                                        />
                                    </ToolTip>
                                    :
                                    <ARTPublish
                                        published={published}
                                        publishAction={() => {
                                            publishSurvey(appId, _id);
                                        }}
                                    />
                                }
                            </Grid>
                            <Grid item xs={3} onClick={(e) => e.stopPropagation()}>
                                <ToggleSwitch
                                    handleChange={enabled =>  {
                                        updateSurvey(appId, _id, {
                                            ...survey,
                                            enabled
                                        });
                                    }}
                                    label={`${enabled ? "Disable" : "Enable"} Survey`}
                                    checked={enabled}
                                />
                            </Grid>
                            <Grid item xs={1} onClick={(e) => e.stopPropagation()}>
                                <DeleteAction
                                    deleteAction={() => {
                                        deleteSurvey(appId, _id);
                                    }}
                                    deleteFailed={survey_delete_failed}
                                />
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    {
                        expanded === _id && <ExpansionPanelDetails>
                            <Survey {...this.props}/>
                        </ExpansionPanelDetails>
                    }
                </ExpansionPanel>
            </div>
        )

    }

}

export class Survey extends React.Component {

    render(){
        const {
            params: { appId },
            _id, survey,
            updateCurrentSurvey,
            survey: { ui: { questions = [] } }
        } = this.props;
        return(
            <Card style={{width: '100%'}}>
                <CardContent>
                    <Typography variant="body2">Questions</Typography>
                    {questions.map((question, index) =>
                        <Typography key={question.id} title={question.title}>{index + 1}. {question.title}</Typography>
                    )}
                </CardContent>
                <CardActions style={{float: 'right'}}>
                    <Link to={`/apps/${appId}/surveys/new`}>
                        <Button size="small" color="secondary" variant="outlined" onClick={() => {
                            //update survey create new
                            updateCurrentSurvey({...survey, meta: {...survey.meta, name: ""}});
                        }}>
                            Create A new survey from this
                        </Button>
                    </Link>
                    <Link to={`/apps/${appId}/surveys/${_id}`}>
                        <Button size="small" variant="contained" color="default">
                            Edit
                        </Button>
                    </Link>
                    <Link to={`/apps/${appId}/surveys/${_id}/results`}>
                        <Button
                            size="small" variant="contained" color="primary"
                            onClick={e => {
                                updateCurrentSurvey(survey);
                            }}
                        >
                            Analyse
                        </Button>
                    </Link>
                </CardActions>
            </Card>
        )

    }

}


/*
export class SurveysHolder extends Component {

    constructor(props) {
        super(props);
        const { params: { appId } } = props;
        this.newSurveyLink = `/apps/${appId}/${DASHBOARD_ENDPOINTS.SURVEYS}/new`;
    }


    state = {
        deleteTriggered: false,
        currentName: '',
        currentId: null
    };

    render(){
        const {
            params: { appId },
            surveys: { surveys =[] },
            context: { router },
            deleteSurvey, publishSurvey
        } = this.props;
        const { deleteTriggered, currentName = '', currentId } = this.state;
        return(
            <Grid container spacing={16} justify="center">
                <Grid item xs={12} md={8}>
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={{float: 'right', marginBottom: 24}}>
                                <Button variant="contained" color="primary" onClick={() => {
                                    router.push(this.newSurveyLink);
                                }}><AddIcon/> Create Survey / Feedback</Button>
                            </div>
                        </Grid>
                    </Grid>
                    <Dialog
                        status={deleteTriggered}
                        handleConfirm={() => {
                            this.setState({deleteTriggered: false});
                            if(currentId){
                                deleteSurvey(appId, currentId);
                            }
                        }}
                        title="Delete Confirmation"
                        handleClose={() => this.setState({deleteTriggered: false})}
                        confirmLabel="Confirm"
                        allowCancelLabel="Cancel"
                    >
                        <p>Are you sure want to <strong>Delete</strong> Survey <strong>{ currentName } </strong></p>
                    </Dialog>
                    { surveys.length > 0 &&
                        <Paper>
                            <List>
                                {
                                    surveys.map(survey =>
                                        <ListItem key={survey._id} button onClick={() => {
                                            router.push(`/apps/${appId}/${DASHBOARD_ENDPOINTS.SURVEYS}/${survey._id}`);
                                        }} style={{borderBottom: '1px solid #DDD'}}>
                                            <ListItemIcon>
                                                <QuestionAnswerIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary={survey.meta.name} secondary={`#${survey.ui.hasOwnProperty("questions") && survey.ui.questions.length}`}/>
                                            {/!*<ListItemText
                                            style={{position: 'absolute', left: '70%'}}
                                            primary={<Typography title="Type">{"Type"}</Typography>}
                                            secondary={<Typography title="Sub Type" variant="caption">{"Sub TYpe"}</Typography>}
                                        />*!/}
                                            <ListItemSecondaryAction>
                                                <div style={{display: 'flex'}}>
                                                    <Button onClick={e => {
                                                        e.stopPropagation();
                                                        publishSurvey(appId, survey._id);
                                                    }} variant="flat" disabled={survey.published}>{survey.published ? "Published" : "Publish"}</Button>
                                                    <IconButton style={{marginRight: 16}} color="inherit" onClick={() => {
                                                        this.setState({deleteTriggered: true, currentName: survey.meta.name, currentId: survey._id})
                                                    }}>
                                                        <DeleteIcon color="secondary" />
                                                    </IconButton>
                                                </div>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                }
                            </List>
                        </Paper>
                    }
                    {
                        !this.props.meta.api_pending && (!Array.isArray(surveys) || surveys.length === 0) &&
                        <Placeholder
                            withIcon
                            icon={<QuestionAnswerIcon style={{opacity: 0.2, width: 150, height: 150}} />}
                            text="Create your first survey or feedback."
                        />
                    }
                </Grid>
            </Grid>
        )
    }
}*/
