/**
 * Created by Rakesh Peela
 * Date: 04-Dec-2019
 * Time: 12:00 AM
 */

import React from 'react';
import {APP_PLATFORMS} from "../../../../../../../../../../../constants";
import {ACTION_CLASSES} from "../../models/AvailableOptions";
import {MESSAGE_TOOLTIP_LAYOUT_TYPES} from "../../models/common";
import Buttons_config from "./buttons_config";
import Image_Config from "./image_configs";
import {MODULE_TYPES} from "./moduleTypes";
import Text_config from "./text_config";
import _ from "lodash";

export default class ModulesConfigurator extends React.Component {
    constructor(props) {
        super(props);
    }

    getModuleFromSelected = (selectedModule) => {
        const {updateInNewUI, currentCampaignStep, selectedConfig} = this.props;
        const {appState: {appScreenNames = []}} = this.props;
        const {messages2: {new_message: {uis, meta: {platform}}, new_ui: {layout_type, action_class}, new_ui}} = this.props;
        const {buttons_config = [], text_config = {}, title_config = {}, action_buttons_config = []} = uis[currentCampaignStep];
        const isActionButtonsLayout = layout_type === MESSAGE_TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS;
        let btns_mod_key = isActionButtonsLayout ? "action_buttons_config" : "buttons_config";
        const propertiesForMacros = _.pick(this.props, ["userProperties", "sessionProperties", "eventProperties"]);
        let multiline = platform === APP_PLATFORMS.android && [ACTION_CLASSES.TOOLTIP, ACTION_CLASSES.COACHMARK].includes(action_class);
        switch (selectedModule) {
            case MODULE_TYPES.TITLE_MODULE:
                return <Text_config isPure dataKey={"title_config"}
                                    multiline={multiline}
                                    onChange={updateInNewUI}
                                    currentCampaignStep={currentCampaignStep}
                                    titleC={"Title Configuration"}
                                    message={this.props.messages2.new_ui}
                                    config={title_config}
                                    propertiesForMacros={propertiesForMacros}
                                    withPropertySelector={platform !== APP_PLATFORMS.ios}
                />;
            case MODULE_TYPES.DESCRIPTION_MODULE:
                return <Text_config isPure dataKey={"text_config"}
                                    onChange={updateInNewUI}
                                    multiline={multiline}
                                    currentCampaignStep={currentCampaignStep}
                                    titleC={"Description Configuration"}
                                    message={this.props.messages2.new_ui}
                                    propertiesForMacros={propertiesForMacros}
                                    config={text_config}
                                    withPropertySelector={platform !== APP_PLATFORMS.ios}
                />;
            case MODULE_TYPES.BUTTONS_MODULE:
            case MODULE_TYPES.ACTION_BUTTONS_MODULE:
                return <Buttons_config onChange={updateInNewUI}
                                       mod_key={btns_mod_key}
                                       currentCampaignStep={currentCampaignStep}
                                       message={this.props.messages2.new_ui}
                                       configs={isActionButtonsLayout ? action_buttons_config : buttons_config}
                                       appScreenNames={appScreenNames}/>;
            case MODULE_TYPES.IMAGE_MODULE:
                return <Image_Config onChange={updateInNewUI}
                                     currentCampaignStep={currentCampaignStep}
                                     message={this.props.messages2.new_ui}
                                     config={selectedConfig}/>;
            default:
                return null;
        }
    };

    render() {
        const {selectedModule} = this.props;
        return (
            <div>
                {selectedModule && this.getModuleFromSelected(selectedModule)}
                {}
            </div>
        );
    }
}