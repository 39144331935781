/**
 * Created by kodanda_rama on 2/1/17.
 */
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '../../../../../components/reusable/MaterialUi/Snackbar';
import Grid from '@material-ui/core/Grid';
import Loading from "../../../../../components/reusable/Loading";

export default class ForgotPassword  extends Component {
    constructor(props){
        super(props);
        this.state = {
            isCollapsed: this.props.collapse,
            value: ''
        }
    }

    handleChange = (e) => {
        this.setState({value: e.target.value});
    };

    render(){
        const {
            forgot_password_pending,
            forgot_password,
            forgot_password_failed,
            auth_error_message
        } = this.props.session;
        const { value } = this.state;
        return (
            <div style={{marginTop: 15}}>
                { forgot_password_failed &&
                    <Snackbar>
                        <strong>{auth_error_message}</strong>
                    </Snackbar>
                }
                { forgot_password &&
                    <Snackbar>
                        <strong>Please check your mail to reset password..!</strong>
                    </Snackbar>
                }
                <form onSubmit={(e) => {
                    e.preventDefault();
                    this.props.handleForgotPassword(value);
                }}>
                    <Grid container spacing={0}>
                        <Grid item xs>
                            <TextField
                                id="forgot-email"
                                value={value}
                                fullWidth
                                placeholder="john@company.com"
                                onChange={this.handleChange}
                                label="Email"
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <Button variant="contained" color="primary" type="submit">
                                { forgot_password_pending && <Loading size={30}/> }
                                { !forgot_password_pending && <span>Submit</span>}
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        )
    }
}