/**
 * Created by Rakesh Peela
 * Date: 20-Dec-2019
 * Time: 1:04 PM
 */

import shortid from 'shortid';
import {APP_PLATFORMS} from "../../../../../../../constants";

shortid.characters("0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ-_");

export default function generateShortID(separator = '-', repeat = 1, prefix = "", suffix = "") {
    let shortID_repeated = shortid.generate().replace(/[-_]/, "x");
    for (let val = 0; val < repeat; val++) {
        shortID_repeated += separator + shortid.generate().toString().replace(/[-_]/, "x");
    }
    return prefix + shortID_repeated + suffix;
}

export const SECTIONS_ENUM = {
    ALL: "All",
    ACTIVE: "Active",
    EXPIRED: "Expired",
    PAUSED: "Paused",
    SCHEDULED: "Scheduled",
    DRAFTS: "Drafts",
};

export const  getPlatformBasedTimelimitKey = (messages2) => {
    const {new_message: {meta: {platform}}} = messages2
    if (platform === APP_PLATFORMS.ios) {
        return "time_limits_in_day";
    } else {
        return "at_specific_time";
    }
    return false
}
