/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading } from '../../Navigation/actions';
import { PathAnalysis } from './components';
import {
    getEventPath, getScreenPath, resetPath
} from './actions';
import {
    getAttributes, getAttributeValues, resetQuery,
    updateQuery, getEventAttributes, getEventAttributeValues
} from "../Segments/NewSegment/actions";
import ArrowedLabel from "../../../../../../components/reusable/ArrowedLabel";


function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        filters: state.filters,
        meta: state.meta,
        path: state.path,
        queryBuilder: state.queryBuilder,
        segmentBuilder: state.segmentBuilder,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getEventPath: (...args) => dispatch(getEventPath(...args)),
        getScreenPath: (...args) => dispatch(getScreenPath(...args)),
        resetPath: () => dispatch(resetPath()),
        getAttributes: (appId) => dispatch(getAttributes(appId)),
        getAttributeValues: (appId, attribute, q) => dispatch(getAttributeValues(appId, attribute, q)),
        getEventAttributes: (appId, event) => dispatch(getEventAttributes(appId, event)),
        getEventAttributeValues: (appId, event, attribute, q) => dispatch(getEventAttributeValues(appId, event, attribute, q)),
        handleQueryUpdate: (query) => dispatch(updateQuery(query)),
        resetQuery: () => dispatch(resetQuery()),
    };
}

class Path extends Component {

    componentWillMount(){
        const { params: {appId}, updateHeading, getAttributes } = this.props;
        updateHeading("Path Analysis");
        getAttributes(appId);
    }

    componentWillUnmount(){
        this.props.resetPath();
        this.props.resetQuery();
    }

    render() {
        return (
            <section className="content">
                <PathAnalysis {...this.props}/>
            </section>
        );
    }
}

Path.propTypes = {

};

Path.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Path);