import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {IconButton, Tooltip, TextField} from "@material-ui/core";
import DashboardIcon  from '@material-ui/icons/Dashboard';
import Dialog from "../../../../../../../components/reusable/MaterialUi/Dialog";
import {uniqueId} from "../../../../../../../utils";

export default class CreatorHolder extends Component {

    constructor(props){
        super(props);
        const { customLabel = "Label for component" } = props.selfParams || {};
        this.state = {
            addTriggered: false,
            customLabel: customLabel
        };

    }

    componentWillReceiveProps(nextProps){
        const { customLabel } = nextProps.selfParams;
        if(this.state.customLabel !== customLabel){
            this.setState({customLabel}); //FIXME: have a look on this :(
        }
    }

    render(){
        const { children, handleAdd, widget, selfParams } = this.props;
        const { addTriggered, customLabel } = this.state;
        return (
            <div style={{position: 'relative', width: '100%'}}>
                {
                    <div style={{
                        position: 'absolute',
                        zIndex: 3,
                        right: 0
                    }}>
                        <Tooltip id={"" + Math.random()} title="Add to dashboard" placement="top">
                            <IconButton aria-label="Add to dashboard" onClick={() => this.setState({addTriggered: true})}>
                                <DashboardIcon color="secondary"/>
                            </IconButton>
                        </Tooltip>
                    </div>
                }
                <Dialog
                    status={addTriggered}
                    handleConfirm={() => {
                        if(customLabel && customLabel.length > 0){
                            handleAdd({...widget, selfParams: {...selfParams, customLabel, dataKey: uniqueId()}});
                        }
                        this.setState({addTriggered: false});
                    }}
                    title="Adding component to Dashboard"
                    handleClose={() => this.setState({addTriggered: false})}
                    confirmLabel="Add"
                    allowCancelLabel="Cancel"
                    dialogProps={{disableBackdropClick: true}}
                >
                    <TextField
                        value={customLabel}
                        fullWidth
                        placeholder="Some Count or Trend"
                        onChange={e => this.setState({customLabel: e.target.value})}
                        label="Component Name"
                        type="text"
                        required
                    />
                </Dialog>
                {children && React.cloneElement(children, {...this.props, selfParams: {...selfParams, customLabel}})}
            </div>
        )
    };
}

CreatorHolder.propTypes = {
    handleAdd: PropTypes.func.isRequired,
    widget: PropTypes.object,
    selfParams: PropTypes.object
};