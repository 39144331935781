/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    RESET_SURVEYS,
    SURVEYS_PENDING, SURVEYS, SURVEYS_FAILED,
    SAVE_SURVEY_PENDING, SAVE_SURVEY, SAVE_SURVEY_FAILED,
    UPDATE_SURVEY_PENDING, UPDATE_SURVEY, UPDATE_SURVEY_FAILED,
    DELETE_SURVEY_PENDING, DELETE_SURVEY, DELETE_SURVEY_FAILED,
    PUBLISH_SURVEY_PENDING, PUBLISH_SURVEY, UPDATE_CURRENT_SURVEY,
    SURVEY_RESULTS_PENDING, SURVEY_RESULTS, SURVEY_RESULTS_FAILED,
    SURVEY_RESULT_DISTRIBUTIONS_PENDING, SURVEY_RESULT_DISTRIBUTIONS, SURVEY_RESULT_DISTRIBUTIONS_FAILED,
    SURVEY_RESULT_OTHER_OPTION_PENDING, SURVEY_RESULT_OTHER_OPTION, SURVEY_RESULT_OTHER_OPTION_FAILED
} from './actionTypes';
import {
    getArtConfigsAPI, saveArtConfigAPI,
    updateArtConfigAPI, deleteArtConfigAPI, publishArtConfigAPI
} from '../../../../../../api';
import {ART_CONFIG_TYPES} from "../../../../../../constants";
import {PUBLISH_MESSAGE_FAILED} from "../Messages/actionTypes";
import {surveyResultDistributionsAPI, surveyResultForOtherOptionAPI, surveyResultsAPI} from "./api";

/**
 *
 * @param appId
 * @param surveyId [Optional] to updateCurrentSurvey
 * @returns {function(*, *): *}
 */
export const getSurveys = (appId, surveyId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, configType: ART_CONFIG_TYPES.SURVEY };
        return dispatch({
            types: [
                SURVEYS_PENDING, SURVEYS, SURVEYS_FAILED
            ],
            payload: {
                promise: getArtConfigsAPI(appId, getState().auth, filters)
                    .then((res) => {
                        return res.messages || [];
                    })
            },
            meta: {

            },
            callbacks: {
                successWillDispatch: (dispatch, data) => {
                    if(surveyId && Array.isArray(data)){
                        const survey = data.find(o => o._id === surveyId);
                        if(survey){
                            dispatch(updateCurrentSurvey(survey));
                        }
                    }
                }
            }
        });
    };
};

export const saveSurvey = (appId, survey) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, configType: ART_CONFIG_TYPES.SURVEY };
        return dispatch({
            types: [
                SAVE_SURVEY_PENDING, SAVE_SURVEY, SAVE_SURVEY_FAILED
            ],
            payload: {
                promise: saveArtConfigAPI(appId, getState().auth, filters, survey)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {

            },
            callbacks: {
                successWillDispatch: (dispatch, data) => {
                    if(data){
                        dispatch(getSurveys(appId));
                    }
                }
            }
        });
    };
};

export const updateSurvey = (appId, surveyId, survey) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, configType: ART_CONFIG_TYPES.SURVEY };
        return dispatch({
            types: [
                UPDATE_SURVEY_PENDING, UPDATE_SURVEY, UPDATE_SURVEY_FAILED
            ],
            payload: {
                promise: updateArtConfigAPI(appId, getState().auth, surveyId, filters, survey)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {

            },
            callbacks: {
                successWillDispatch: (dispatch, data) => {
                    if(data){
                        dispatch(getSurveys(appId));
                    }
                }
            }
        });
    };
};

export const deleteSurvey = (appId, surveyId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, configType: ART_CONFIG_TYPES.SURVEY };
        return dispatch({
            types: [
                DELETE_SURVEY_PENDING, DELETE_SURVEY, DELETE_SURVEY_FAILED
            ],
            payload: {
                promise: deleteArtConfigAPI(appId, getState().auth, surveyId, filters)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {

            },
            callbacks: {
                successWillDispatch: (dispatch, data) => {
                    if(data){
                        dispatch(getSurveys(appId));
                    }
                }
            }
        });
    };
};


/**
 *
 * @returns {function(*, *)}
 */
export const resetSurveys = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_SURVEYS,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};

export const updateCurrentSurvey = (survey) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_CURRENT_SURVEY,
            payload: survey,
            meta: {
                //If Any
            }
        });
    };
};

export const publishSurvey = (appId, surveyId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, configId: surveyId, configType: ART_CONFIG_TYPES.SURVEY };
        return dispatch({
            types: [
                PUBLISH_SURVEY_PENDING,
                PUBLISH_SURVEY,
                PUBLISH_MESSAGE_FAILED
            ],
            payload: {
                promise: publishArtConfigAPI(appId, getState().auth, filters)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {

            },
            callbacks: {
                successWillDispatch: (dispatch, data) => {
                    if(data){
                        dispatch(getSurveys(appId));
                    }
                }
            }
        });
    };
};

export const surveyResults = (appId, surveyId) => {
    return (dispatch, getState) => {
        const queryParams = { ...getState().filters, readable: true };
        const filters = { //TODO: survey filters
            user: [],
            session: [],
            event: []
        };
        return dispatch({
            types: [
                SURVEY_RESULTS_PENDING, SURVEY_RESULTS, SURVEY_RESULTS_FAILED
            ],
            payload: {
                promise: surveyResultsAPI(appId, getState().auth, surveyId, queryParams, filters)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {

            }
        });
    };
};

export const surveyResultForOtherOption = (appId, surveyId, questionId, answerId) => {
    return (dispatch, getState) => {
        const queryParams = { ...getState().filters, readable: true, questionId, answerId };
        const filters = { //TODO: survey filters
            user: [],
            session: [],
            event: []
        };
        return dispatch({
            types: [
                SURVEY_RESULT_OTHER_OPTION_PENDING, SURVEY_RESULT_OTHER_OPTION, SURVEY_RESULT_OTHER_OPTION_FAILED
            ],
            payload: {
                promise: surveyResultForOtherOptionAPI(appId, getState().auth, surveyId, queryParams, filters)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {
                questionId, answerId
            }
        });
    };
};

export const surveyResultDistributions = (appId, surveyId) => {
    return (dispatch, getState) => {
        const queryParams = { ...getState().filters };
        return dispatch({
            types: [
                SURVEY_RESULT_DISTRIBUTIONS_PENDING, SURVEY_RESULT_DISTRIBUTIONS, SURVEY_RESULT_DISTRIBUTIONS_FAILED
            ],
            payload: {
                promise: surveyResultDistributionsAPI(appId, getState().auth, surveyId, queryParams)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {

            }
        });
    };
};