import {createMuiTheme} from '@material-ui/core/styles';
import ReactDatesStyles from './ReactDatesStyles';

const customShadow = '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);'; //'0 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 0px 3px 0px rgba(0, 0, 0, 0.14), 0px 0px 3px -1px rgba(0, 0, 0, 0.12)';

const BACKGROUND = "#f9fafc"; //"#e9e9ff";
const PAPER = '#FFFFFF';
const TEXT = '0,72,114';

export const PALETTE = {
    background: {
        default: BACKGROUND,
        paper: PAPER
    },
    primary: {
        main: "#3f50b5",
    },
    secondary: {
        main: "#0b011c"
    },
    error: {
        main: "#f05165"
    },
    text: {
        primary: `rgba(${TEXT}, 0.87)`,
        secondary: `rgba(${TEXT}, 0.54)`,
        disabled: `rgba(${TEXT}, 0.38)`,
        hint: `rgba(${TEXT}, 0.38)`,
    },
    action: {}
};

const FONT_FAMILY_REGULAR = '"Karla Regular", "Roboto", "Helvetica", "Arial", sans-serif';
const FONT_FAMILY_BOLD = '"Karla Bold", "Roboto", "Helvetica", "Arial", sans-serif';

const fontConfig = (fontSize, fontFamily = FONT_FAMILY_BOLD) => ({
    fontSize,
    fontFamily
});

export const apxTheme = createMuiTheme({
    typography: {
        fontFamily: FONT_FAMILY_REGULAR,
        h4: fontConfig(24),
        h5: fontConfig(18),
        h6: fontConfig(16),
        subtitle1: fontConfig(14),
        subtitle2: fontConfig(14),
        caption: fontConfig(12),
        body1: fontConfig('0.875rem', FONT_FAMILY_REGULAR), //18
        body2: fontConfig(16, FONT_FAMILY_REGULAR)
    },
    palette: {
        ...PALETTE
    },
    custom: {
        shadow: customShadow
    },
    shadows: [
        'none',
        customShadow
    ],
    overrides: {
        MuiCollapse: {
            container: {
                overflow: 'initial !important',
                display: 'none'
            },
            entered: {
                display: 'block'
            }
        },
        MuiInput: {
            input: {
                //background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                //padding: '4px 0'
            },
            inputType: {
                //height: 36
            }
        },
        MuiPaper: {
            /*shadow2: {
                boxShadow: customShadow
            }*/
        },
        MuiCard: {
            root: {
                overflow: 'inherit'
            }
        },
        MuiBottomNavigationAction: {
            root: {
                '&$selected': {
                    paddingTop: 12,
                    fontSize: '0.75rem !important'
                }
            }
        },
        MuiChip: {
            root: {
                color: PALETTE.text.primary
            }
        },
        MuiSwitch: {
            iconChecked: {
                backgroundColor: PALETTE.primary.main
            }
        }
    }
});

const scrollbarCSS = (theme) => ({
    "::-webkit-scrollbar": {
        height: 2,
        width: 2
    },
    "body::-webkit-scrollbar": { //FIXME: hover not working for body
        height: theme.spacing.unit,
        width: theme.spacing.unit
    },
    "*:not([role='tablist']):hover::-webkit-scrollbar": {
        height: theme.spacing.unit,
        width: theme.spacing.unit
    },
    "::-webkit-scrollbar-thumb": {
        borderRadius: 4,
        backgroundColor: "#757575"
    },
});

export const globalStyles = theme => ({
    '@global': {
        html: {
            background: PALETTE.background.default,
            //background: theme.palette.background.default, //FIXME: not working
            WebkitFontSmoothing: 'antialiased', // Antialiasing.
            MozOsxFontSmoothing: 'grayscale', // Antialiasing.
            boxSizing: 'border-box',
            '@media print': {
                background: PALETTE.background.default,
                //background: theme.palette.background.default //FIXME: theme not applying
            }
        },
        '*, *:before, *:after': {
            boxSizing: 'inherit',
        },
        body: {
            margin: 0,
            height: '100%'
        },
        'section.content': {
            padding: '15px 30px 30px 30px',
            height: 'auto'
        },
        a: {
            textDecoration: 'none !important'
        },
        "::-webkit-scrollbar": {
            height: 6,
            width: 6,
            background: "rgba(0,0,0,0.05)",
            transition: "all 0.2s ease-in-out"
        },
        "::-webkit-scrollbar-thumb": {
            margin: 6,
            width: 6,
            borderRadius: 12,
            backgroundColor: "#757575"
        },
        "*:hover::-webkit-scrollbar": {
            background: "rgba(0,0,0,0.05)"
        },
        ...ReactDatesStyles(apxTheme),
        '.highcharts-credits': {display: "none"},
        '.recharts-default-tooltip': { //Tweak for recharts tooltip
            background: "rgb(255, 255, 255, 0.64) !important", //'linear-gradient(45deg, rgba(254, 254, 254, 0.54) 30%, rgba(255, 142, 83, 0.56) 90%) !important'
            fontSize: 16,
            fontWeight: 600
        },
        '.item-center': {
            margin: '0 auto'
        }
    },
    root: {
        width: '100%',
        height: '100%',
        //marginTop: apxTheme.spacing.unit * 3,
        zIndex: 1,
        overflow: 'hidden',
        flexGrow: 1,
        position: 'relative',
        display: 'flex',
    },
    appFrame: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
    },
    progress: {
        width: '100%',
        position: 'fixed',
        zIndex: 3000,
        height: theme.spacing.unit / 2,
        top: 0,
    }
});

export const darkTheme = createMuiTheme({
    palette: {
        type: 'dark',
        background: {
            default: '#000000',
            paper: '#070112'
        },
        primary: {
            main: "#3f50b5",
        },
        secondary: {
            main: "#070112"
        },
    },
    overrides: {
        MuiListItem: { //FIXME: Tweak for supporting sidebar menu
            gutters: {
                paddingLeft: '16px !important',
                paddingRight: '16px !important'
            }
        }
    }
});