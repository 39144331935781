/**
 * Created by jyothi on 14/1/17.
 */

export const SOURCE_PATHS_PENDING = "SOURCE_PATHS_PENDING";
export const SOURCE_PATHS = "SOURCE_PATHS";
export const SOURCE_PATHS_FAILED = "SOURCE_PATHS_FAILED";
export const SOURCE_ISSUES_PENDING = "SOURCE_ISSUES_PENDING";
export const SOURCE_ISSUES = "SOURCE_ISSUES";
export const SOURCE_ISSUES_FAILED = "SOURCE_ISSUES_FAILED";

export const UPDATE_TARGET_SELECTION = "UPDATE_TARGET_SELECTION";

export const UPDATE_CONFIGURATION_SELECTION = "UPDATE_CONFIGURATION_SELECTION";

export const UPDATE_NOTIFICATION_SELECTION = "UPDATE_NOTIFICATION_SELECTION";

export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";

export const NOTIFICATIONS_PENDING = "NOTIFICATIONS_PENDING";
export const NOTIFICATIONS = "NOTIFICATIONS";
export const NOTIFICATIONS_FAILED = "NOTIFICATIONS_FAILED";

export const DELETE_NOTIFICATION_PENDING = "DELETE_NOTIFICATION_PENDING";
export const DELETE_NOTIFICATION_SUCCESS = "DELETE_NOTIFICATION_SUCCESS";
export const DELETE_NOTIFICATION_FAILED = "DELETE_NOTIFICATION_FAILED";

export const SAVE_NOTIFICATION_PENDING = "SAVE_NOTIFICATION_PENDING";
export const SAVE_NOTIFICATION_SUCCESS = "SAVE_NOTIFICATION_SUCCESS";
export const SAVE_NOTIFICATION_FAILED = "SAVE_NOTIFICATION_FAILED";

export const SEND_NOTIFICATION_PENDING = "SEND_NOTIFICATION_PENDING";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILED = "SEND_NOTIFICATION_FAILED";

export const UPDATE_FIRST_NOTIFICATION = "UPDATE_FIRST_NOTIFICATION";
export const UPDATE_NEXT_NOTIFICATION = "UPDATE_NEXT_NOTIFICATION";
export const UPDATE_DESIRED_EVENT = "UPDATE_DESIRED_EVENT";