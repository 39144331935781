
export const USER_SEARCH_ATTRIBUTES_PENDING = "USER_SEARCH_ATTRIBUTES_PENDING";
export const USER_SEARCH_ATTRIBUTES = "USER_SEARCH_ATTRIBUTES";
export const USER_SEARCH_ATTRIBUTES_FAILED = "USER_SEARCH_ATTRIBUTES_FAILED";

export const USER_SEARCH_ATTRIBUTE_VALUES_PENDING = "USER_SEARCH_ATTRIBUTE_VALUES_PENDING";
export const USER_SEARCH_ATTRIBUTE_VALUES = "USER_SEARCH_ATTRIBUTE_VALUES";
export const USER_SEARCH_ATTRIBUTE_VALUES_FAILED = "USER_SEARCH_ATTRIBUTE_VALUES_FAILED";

export const USER_PROFILES_PENDING = "USER_PROFILES_PENDING";
export const USER_PROFILES = "USER_PROFILES";
export const USER_PROFILES_FAILED = "USER_PROFILES_FAILED";

export const UPDATE_USER_EXPLORER_GROUP = "UPDATE_USER_EXPLORER_GROUP";

export const UPDATE_USER_EXPLORER_SEGMENT = "UPDATE_USER_EXPLORER_SEGMENT";

export const UPDATE_USER_EXPLORER_FILTERS = "UPDATE_USER_EXPLORER_FILTERS";

export const UPDATE_USER_EXPLORER_SEARCH_FIELD = "UPDATE_USER_EXPLORER_SEARCH_FIELD";

export const UPDATE_USER_EXPLORER_QUERY_TERM = "UPDATE_USER_EXPLORER_QUERY_TERM";