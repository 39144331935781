export const CLIENT_EVENT = "client_event";
export const APP_EVENT = "app_event";
export const AGGREGATE_EVENT = "aggregate_event";

export const ATTRIBUTE_TYPES = {
    STRING: "String",
    INT: "Int",
    DOUBLE: "Double",
    BOOLEAN: "Boolean",
}
export const ATTRIBUTE_TYPES_MAP = [
    {key: "String", label: "String"},
    {key: "Int", label: "Int"},
    {key: "Double", label: "Double"},
    {key: "Boolean", label: "Boolean"},
]

export const EVENT_SOURCES = {
    "sdk": "sdk",
    "manual": "manual",
    "csv": "csv",
}

export const MODAL_TYPES = {
    CONVERT_EVENT_TYPE: "CONVERT_EVENT_TYPE",
    DELETE_EVENT_TYPE: "DELETE_EVENT_TYPE",
    EDIT_EVENT_TYPE: "EDIT_EVENT_TYPE",
    CREATE_NEW: "CREATE_NEW",
}