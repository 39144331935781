/**
 * Created by Rakesh Peela
 * Date: 08-Sep-2019
 * Time: 10:37 AM
 */

import {
    GET_COHORTS_PENDING,
    GET_COHORTS,
    GET_COHORTS_FAILED,
    CREATE_COHORTS_PENDING,
    CREATE_COHORTS,
    CREATE_COHORTS_FAILED,
    UPDATING_COHORT,
    UPDATING_COHORT_FAILED,
    UPDATING_COHORT_PENDING
} from './actionTypes';
import {createCohortFromSegmentAPI, getCohortsAPI, updateCohortAPI} from './api';

export const createCohortFromSegment = (appId, segmentId, cohortSince, cohortTill) => {
    return (dispatch, getState) => {
        return dispatch( {
            types: [
                CREATE_COHORTS_PENDING,
                CREATE_COHORTS,
                CREATE_COHORTS_FAILED
            ],
            payload: {
                promise: createCohortFromSegmentAPI(getState().auth, appId, segmentId, cohortSince, cohortTill)
                    .then(res => {
                        return res || {}
                    })
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, status) => {
                    if (status) {
                        dispatch(getCohorts(appId, getState().filters))
                    }
                }
            }
        })

    }
};

export const updateCohort = (appId, cohortId, since, till) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                UPDATING_COHORT_PENDING,
                UPDATING_COHORT,
                UPDATING_COHORT_FAILED
            ],
            payload: {
                promise: updateCohortAPI(getState().auth, appId, cohortId, since, till)
                    .then(res => {
                        return res || {}
                    })
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, status) => {
                    if (status) {
                        dispatch(getCohorts(appId, getState().filters))
                    }
                }
            }
        })
    }
}

export const getCohorts = (appId, filters) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                GET_COHORTS_PENDING,
                GET_COHORTS,
                GET_COHORTS_FAILED
            ],
            payload: {
                promise: getCohortsAPI(getState().auth, appId, filters)
                    .then(res => {
                        return res || []
                    }),
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, cohorts=[]) => {
                    // dispatch({
                    //     type: GET_COHORTS,
                    //     payload: cohorts
                    // })
                }
            }
        });
    };
};
