/**
 * Created by Rakesh Peela
 * Date: 01-Jan-2020
 * Time: 1:34 PM
 */

import {PUBLISH_FOR_PREVIEW, PUBLISH_FOR_PREVIEW_PENDING, PUBLISH_FOR_PREVIEW_SUCCESS} from "./actionTypes";
import {publishForPreview} from "./api";

export const publishDesignForPreview_action = (data, appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                PUBLISH_FOR_PREVIEW_PENDING,
                PUBLISH_FOR_PREVIEW,
                PUBLISH_FOR_PREVIEW_SUCCESS
            ],
            payload: {
                promise: publishForPreview(appId, getState().auth, data)
                    .then((res) => {
                    }),
            },
            meta: {}
        })
    }
};