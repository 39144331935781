import {
    CUSTOM_REPORT_EXPLORER_PENDING, CUSTOM_REPORT_EXPLORER, CUSTOM_REPORT_EXPLORER_FAILED,
    UPDATE_CUSTOM_REPORT_USER_GROUP, UPDATE_CUSTOM_REPORT_EXPLORER_QUERY,
    RESET_CUSTOM_REPORT, UPDATE_CUSTOM_REPORT_METRICS, UPDATE_CUSTOM_REPORT_DIMENSIONS,
    CUSTOM_REPORT_FLAT_TABLE_PENDING, CUSTOM_REPORT_FLAT_TABLE, CUSTOM_REPORT_FLAT_TABLE_FAILED,
    CUSTOM_REPORTS_PENDING, CUSTOM_REPORTS, CUSTOM_REPORTS_FAILED,
    CUSTOM_REPORT_PENDING, CUSTOM_REPORT, CUSTOM_REPORT_FAILED,
    CUSTOM_REPORT_CREATE_PENDING, CUSTOM_REPORT_CREATE, CUSTOM_REPORT_CREATE_FAILED,
    CUSTOM_REPORT_DELETE_PENDING, CUSTOM_REPORT_DELETE, CUSTOM_REPORT_DELETE_FAILED, UPDATE_CUSTOM_REPORT_FILTERS
} from './actionTypes';
import {
    saveCustomReportAPI,
    getExplorerAPI,
    getFlatTableAPI,
    getCustomReportsAPI,
    getCustomReportAPI,
    deleteCustomReportAPI
} from "./api";
import {getGroupFromList} from "../../../../../../utils";

let getCustomReportLimit = (state, limit) => {
    let key = "APX_CR_LIMIT_";
    const {app: {app: {features = []}}} = state;
    let updatedLimit = limit;
    features.forEach(feature => {
        if(feature.indexOf(key) !== -1) {
            updatedLimit = feature.slice(key.length)
        }
    });
    return Number(updatedLimit)
};

export const getExplorer = (appId) => {
    return (dispatch, getState) => {
        const { group, limit, query } = getState().customReport;
        const filters = { ...getState().filters, group, limit: getCustomReportLimit(getState(), limit) };
        return dispatch({
            types: [
                CUSTOM_REPORT_EXPLORER_PENDING, CUSTOM_REPORT_EXPLORER, CUSTOM_REPORT_EXPLORER_FAILED
            ],
            payload: {
                promise: getExplorerAPI(getState().auth, appId, filters, query)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getFlatTable = (appId) => {
    return (dispatch, getState) => {
        const { group, limit, query } = getState().customReport;
        const filters = { ...getState().filters, group, limit: getCustomReportLimit(getState(), limit) };
        return dispatch({
            types: [
                CUSTOM_REPORT_FLAT_TABLE_PENDING, CUSTOM_REPORT_FLAT_TABLE,CUSTOM_REPORT_FLAT_TABLE_FAILED
            ],
            payload: {
                promise: getFlatTableAPI(getState().auth, appId, filters, query)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getFlatTableForId = (appId, reportId) => {
    return (dispatch, getState) => {
        const { group, limit } = getState().customReport;
        const filters = { ...getState().filters, group, limit: getCustomReportLimit(getState(), limit), reportId };
        return dispatch({
            types: [
                CUSTOM_REPORT_FLAT_TABLE_PENDING, CUSTOM_REPORT_FLAT_TABLE,CUSTOM_REPORT_FLAT_TABLE_FAILED
            ],
            payload: {
                promise: getFlatTableAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const saveCustomReport = (appId, name) => {
    return (dispatch, getState) => {
        const { metrics = [], dimensions = [], filters = [] } = getState().customReport;
        const globalFilters = { ...getState().filters };
        const query = { name, metrics, dimensions, filters };
        return dispatch({
            types: [
                CUSTOM_REPORT_CREATE_PENDING, CUSTOM_REPORT_CREATE, CUSTOM_REPORT_CREATE_FAILED
            ],
            payload: {
                promise: saveCustomReportAPI(getState().auth, appId, globalFilters, query)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(data){
                        dispatch(getCustomReports(appId));
                    }
                }
            }
        });
    };
};

export const getCustomReports = (appId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        return dispatch({
            types: [
                CUSTOM_REPORTS_PENDING, CUSTOM_REPORTS, CUSTOM_REPORTS_FAILED
            ],
            payload: {
                promise: getCustomReportsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getCustomReport = (appId, reportId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        return dispatch({
            types: [
                CUSTOM_REPORT_PENDING, CUSTOM_REPORT, CUSTOM_REPORT_FAILED
            ],
            payload: {
                promise: getCustomReportAPI(getState().auth, appId, reportId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const updateCustomReport = (reportId) => {
    return (dispatch, getState) => {
        const { reports = [] } = getState().customReport;
        const report = getGroupFromList(reports, reportId) || {};
        return dispatch({
            type: CUSTOM_REPORT,
            payload: report,
            meta: {
                //If Any
            }
        });
    };
};

export const deleteCustomReport = (appId, reportId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        return dispatch({
            types: [
                CUSTOM_REPORT_DELETE_PENDING, CUSTOM_REPORT_DELETE, CUSTOM_REPORT_DELETE_FAILED
            ],
            payload: {
                promise: deleteCustomReportAPI(getState().auth, appId, reportId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(data){
                        dispatch(getCustomReports(appId));
                        dispatch(updateCustomReport())
                    }
                }
            }
        });
    };
};

export const updateUserGroup = (group) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_CUSTOM_REPORT_USER_GROUP,
            payload: group,
            meta: {
                //If Any
            }
        });
    };
};

export const updateMetrics = (filter) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_CUSTOM_REPORT_METRICS,
            payload: filter,
            meta: {
                //If Any
            }
        });
    };
};

export const updateDimensions = (filter) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_CUSTOM_REPORT_DIMENSIONS,
            payload: filter,
            meta: {
                //If Any
            }
        });
    };
};

export const updateFilters = (filters) => {
    return (dispatch, getState) => {
        const validFilters = filters.filter(obj => obj.property.length > 0);
        return dispatch({
            type: UPDATE_CUSTOM_REPORT_FILTERS,
            payload: validFilters,
            meta: {
                //If Any
            }
        });
    };
};

export const updateQuery = (query) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_CUSTOM_REPORT_EXPLORER_QUERY,
            payload: query,
            meta: {
                //If Any
            }
        });
    };
};

export const resetCustomReport = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_CUSTOM_REPORT,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};