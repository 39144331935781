/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 11:42 AM
 */
import {
    UPDATE_IN_FLOW,
    UPDATE_IN_NEW_UI,
    DELETE_STEP_FROM_CAMPAIGN,
    UPDATE_CURRENT_STEP_SELECT,
    ADD_NEXT_STEP_TO_CAMPAIGN,
    SEND_MESSAGE_TO_TEST_DEVICES,
    SEND_MESSAGE_TO_TEST_DEVICES_PENDING,
    SEND_MESSAGE_TO_TEST_DEVICES_FAILED,
    SET_NEW_MESSAGE_FROM_EDIT,
    RESET_NEW_MESSAGE_STATE
} from "../../actionTypes";
import {sendAsTestMessageAPI} from "./api";

export const updateInFlowAction = (data) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_IN_FLOW,
            payload: {
                ...getState().messages2.new_message,
                ...data
            },
            meta: {}
        })
    }
};

export const setMessageFromEdit = (data) => {
    return (dispatch, getState) => {
        return dispatch({
            type: SET_NEW_MESSAGE_FROM_EDIT,
            payload: {
                ...data
            },
            meta: {}
        })
    }
}

export const updateOnSelectStep = (data, index) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_CURRENT_STEP_SELECT,
            payload: {
                ...data
            },
            meta: {
                index
            }
        })
    }
};

export const deleteStepFromCampaignAction = (stepIndex) => {
    return (dispatch, getState) => {
        return dispatch({
            type: DELETE_STEP_FROM_CAMPAIGN,
            payload: {stepIndex},
            meta: {}
        })
    }
};

export const addNewStepToCampaign = (stepData) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ADD_NEXT_STEP_TO_CAMPAIGN,
            payload: stepData,
            meta: {}
        })
    }
};

export const updateInNewUI = (data, index) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_IN_NEW_UI,
            payload: {
                ...data
            },
            meta: {
                index
            }
        })
    }
};

export const resetNewMessageFlowState = (props) => {
    let from = null;
    if (props && props.hasOwnProperty("from")) {
        from = props.from
    }
    return (dispatch, getState) => {
        dispatch({
            type: RESET_NEW_MESSAGE_STATE,
            payload: null,
            meta: {
                from: from || "",
                nullifyLocalStorage: true,
                appId: props.appId
            }
        });
        return Promise.resolve();
    }
};

export const sendAsTestMessage = (appId, data) => {
    return (dispatch, getState) => {
        const {auth: {user: {email}}, auth} = getState();
        const {meta} = data;
        let updatedMeta = {};

        if (meta.hasOwnProperty("created_at")) {
            updatedMeta = {
                ...meta,
                updated_at: Date.now(),
                last_updated_by: email
            }
        } else {
            updatedMeta = {
                ...meta,
                created_at: Date.now(),
                created_by: email
            }
        }

        return dispatch({
            types: [
                SEND_MESSAGE_TO_TEST_DEVICES_PENDING,
                SEND_MESSAGE_TO_TEST_DEVICES,
                SEND_MESSAGE_TO_TEST_DEVICES_FAILED
            ],
            payload: {
                promise: sendAsTestMessageAPI(appId, auth, {
                    ...data,
                    meta: {
                        ...updatedMeta
                    }
                })
                .then(response => {
                    return response
                })
            },
            meta: {}
        })
    }
};