/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    MISC_PENDING, MISC_FAILED, RESET_MISC,
    MISC_ACTIVE_VIDEO_WATCHING_HOURS, MISC_DAU_WITH_VIDEO, MISC_FOLLOW_DISTRIBUTION,
    MISC_NEW_INSTALLS_FORMED_RELATIONSHIP, MISC_SOCIAL_TRENDS, MISC_UNFOLLOWS,
    MISC_UNINSTALLED_FOLLOWS, MISC_UNINSTALLS_PER_SESSION, MISC_UNINSTALLS_PER_VIDEOS_WATCHED,
    MISC_USERS_FORMED_RELATIONSHIP, SOCIAL_EVENTS, MISC_DROP_OFF_IN_ACTIVITY, MISC_DAU_WITH_AUDIO,
    MISC_DAU_WITH_GIF, MISC_TIME_SPENT_ON_VIDEOS, MISC_TIME_SPENT_ON_OTHERS
} from './actionTypes';
import {
    miscActiveEventHoursAPI, miscDAUWithEventAPI, miscDistributionOfEventAPI, miscDropInActivityAPI,
    miscNewInstallsFormedRelationshipAPI, miscTimeSpentAPI,
    miscUninstallsPerSessionAPI, miscUninstallsPerVideosWatchedAPI, miscUsersFormedRelationshipAPI
} from './api';


const VIDEO_WATCH_EVENT = "VIDEO_VIEW_V3"; //FIXME:
export const FOLLOW_EVENT = "PROFILE_FOLLOW_CLICK_V3"; //FIXME:
const UN_FOLLOW_EVENT = "PROFILE_UNFOLLOW_CLICK_V3";
const LOGIN_ACTIVITY = "v2.activities.LoginActivity";
const AUDIO_LISTEN_EVENT = "LISTEN_V3";
const GIF_WATCH_EVENT = "VIEW_FULL_GIF_V3";
const VIDEO_WATCH_ACTIVITY = "v2.activities.VideoFullViewActivity";

export const getMiscUninstallsPerSession = (appId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, isUninstalled: true };
        return dispatch({
            types: [
                MISC_PENDING,
                MISC_UNINSTALLS_PER_SESSION,
                MISC_FAILED
            ],
            payload: {
                promise: miscUninstallsPerSessionAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getMiscUninstallsPerVideosWatched = (appId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, event: VIDEO_WATCH_EVENT, isUninstalled: true };
        return dispatch({
            types: [
                MISC_PENDING,
                MISC_UNINSTALLS_PER_VIDEOS_WATCHED,
                MISC_FAILED
            ],
            payload: {
                promise: miscUninstallsPerVideosWatchedAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getMiscDAU = (appId, type, event) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                MISC_PENDING,
                type,
                MISC_FAILED
            ],
            payload: {
                promise: miscDAUWithEventAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getMiscTimeSpent = (appId, type, activity = false) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, activity, negation: !Boolean(activity)};
        return dispatch({
            types: [
                MISC_PENDING,
                type,
                MISC_FAILED
            ],
            payload: {
                promise: miscTimeSpentAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getMiscDropInActivity = (appId, activity) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, activity};
        return dispatch({
            types: [
                MISC_PENDING,
                MISC_DROP_OFF_IN_ACTIVITY,
                MISC_FAILED
            ],
            payload: {
                promise: miscDropInActivityAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getMiscActiveVideoWatchingHours = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                MISC_PENDING,
                MISC_ACTIVE_VIDEO_WATCHING_HOURS,
                MISC_FAILED
            ],
            payload: {
                promise: miscActiveEventHoursAPI(getState().auth, appId, filters, VIDEO_WATCH_EVENT)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getMiscUnfollows = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                MISC_PENDING,
                MISC_UNFOLLOWS,
                MISC_FAILED
            ],
            payload: {
                promise: miscDAUWithEventAPI(getState().auth, appId, filters, UN_FOLLOW_EVENT) //FIXME:
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getMiscUninstallsWithFollows = (appId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, isUninstalled: true};
        return dispatch({
            types: [
                MISC_PENDING,
                MISC_UNINSTALLED_FOLLOWS,
                MISC_FAILED
            ],
            payload: {
                promise: miscDAUWithEventAPI(getState().auth, appId, filters, FOLLOW_EVENT) //FIXME:
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getMiscDistributionOfEvent = (appId, event = VIDEO_WATCH_EVENT) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                MISC_PENDING,
                MISC_FOLLOW_DISTRIBUTION,
                MISC_FAILED
            ],
            payload: {
                promise: miscDistributionOfEventAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getMiscUsersFormedRelationship = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                MISC_PENDING,
                MISC_USERS_FORMED_RELATIONSHIP,
                MISC_FAILED
            ],
            payload: {
                promise: miscUsersFormedRelationshipAPI(getState().auth, appId, filters, FOLLOW_EVENT) //FIXME:
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getMiscNewInstallsFormedRelationship = (appId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, isNewinstalled: true};
        return dispatch({
            types: [
                MISC_PENDING,
                MISC_NEW_INSTALLS_FORMED_RELATIONSHIP,
                MISC_FAILED
            ],
            payload: {
                promise: miscNewInstallsFormedRelationshipAPI(getState().auth, appId, filters, FOLLOW_EVENT) //FIXME:
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getMiscSocialTrends = (appId, event) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, of: "users"};
        return dispatch({
            types: [
                MISC_PENDING,
                MISC_SOCIAL_TRENDS,
                MISC_FAILED
            ],
            payload: {
                promise: miscDistributionOfEventAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            }
        });
    };
};

/**
 *
 * @returns {function(*, *)}
 */
export const resetMisc = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_MISC,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};

/**
 * Loads all overview data
 * @param appId
 * @returns {function(*, *)}
 */
export function loadMiscData(appId){
    return (dispatch, getState) => {
        dispatch(resetMisc());
        dispatch(getMiscUninstallsPerSession(appId));
        dispatch(getMiscUninstallsPerVideosWatched(appId));
        dispatch(getMiscDAU(appId, MISC_DAU_WITH_VIDEO, VIDEO_WATCH_EVENT));
        dispatch(getMiscDAU(appId, MISC_DAU_WITH_AUDIO, AUDIO_LISTEN_EVENT));
        dispatch(getMiscDAU(appId, MISC_DAU_WITH_GIF, GIF_WATCH_EVENT));
        dispatch(getMiscTimeSpent(appId, MISC_TIME_SPENT_ON_VIDEOS, VIDEO_WATCH_ACTIVITY));
        dispatch(getMiscTimeSpent(appId, MISC_TIME_SPENT_ON_OTHERS));
        dispatch(getMiscActiveVideoWatchingHours(appId));
        dispatch(getMiscDropInActivity(appId, LOGIN_ACTIVITY));
        dispatch(getMiscUnfollows(appId));
        dispatch(getMiscUninstallsWithFollows(appId));
        dispatch(getMiscDistributionOfEvent(appId, FOLLOW_EVENT));
        dispatch(getMiscUsersFormedRelationship(appId));
        dispatch(getMiscNewInstallsFormedRelationship(appId));
        SOCIAL_EVENTS.forEach((event) => {
            dispatch(getMiscSocialTrends(appId, event));
        });
    };
}