/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi, getServerAPI } from "../../../../api";
import { makeDefaultQueryString } from "../../../../utils";
import { API_BASE } from "../../../../config";

const LOGIN_API = "authenticate/customer";
const USER_EXISTENCE_API = "customer/exists";
const SIGN_UP_API = "register/customer";
const LOGOUT_API = "logout";
const REFRESH_TOKEN_API = "authenticate/extend";
const FORGOT_PASSWORD_API = "forgot-password/";
const RESET_PASSWORD_API = "reset-password/customer";
const LOGIN_OTP_API = "authenticate/2fa";

/**
 * Makes a  login Model for Authentication request
 * @param username
 * @param password
 * @returns {{userId: *, password: *}}
 */
const makeLoginModel = (username, password) => {
  return {
    userId: username,
    password: password,
  };
};

/**
 *
 * @param email
 * @param password
 * @param token
 * @returns {{userId: *, token: *, password: *}}
 */
const makeResetPasswordModel = (email, password, token) => {
  return {
    userId: email,
    token: token,
    password: password,
  };
};

/**
 *
 * @param name
 * @param username
 * @param password
 * @returns {{name: *, userId: *, password: *}}
 */
const makeSignUpModel = (name, username, password) => {
  return {
    name: name,
    userId: username,
    password: password,
  };
};

/**
 *
 * @param username
 * @param password
 * @param auth {Object}
 * @returns {Promise}
 */
export function login(username, password, auth) {
  const config = {
    method: "POST",
    auth: auth,
    body: JSON.stringify(makeLoginModel(username, password)),
  };
  const onSuccessCallback = (json) => {
    return {
      token: "1234-5678-9101-1213", //FIXME: need token from server
      profile: {
        email: json.userId,
        name: json.name,
        checkpoint_token: json.checkpoint_token,
      },
      accessExpiry: json.expiry,
    };
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(LOGIN_API, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param email
 * @param auth
 * @returns {Promise}
 */
export function forgotPasswordAPI(email, auth) {
  const url = `${FORGOT_PASSWORD_API}${email}?host=${auth.host}`;
  const config = {
    method: "GET",
    auth: auth,
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param email
 * @param password
 * @param token
 * @param auth
 * @returns {Promise}
 */
export function resetPasswordAPI(email, password, token, auth) {
  const config = {
    method: "POST",
    auth: auth,
    body: JSON.stringify(makeResetPasswordModel(email, password, token)),
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    RESET_PASSWORD_API,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}

/**
 *
 * @param name
 * @param username
 * @param password
 * @param auth
 * @returns {Promise}
 */
export function signUp(name, username, password, auth) {
  const config = {
    method: "POST",
    auth: auth, //FIXME: don't know what to do here
    body: JSON.stringify(makeSignUpModel(name, username, password)),
  };
  const onSuccessCallback = (json) => {
    return {
      token: "1234-5678-9101-1213",
      profile: {
        email: json.userId,
        name: json.name,
      },
      accessExpiry: json.expiry,
    };
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(SIGN_UP_API, config, onSuccessCallback, onFailureCallback);
}

export function logOut() {
  const config = {
    method: "POST",
    body: JSON.stringify({}),
  };
  const onSuccessCallback = (json) => {
    return true;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return callApi(LOGOUT_API, config, onSuccessCallback, onFailureCallback);
}

export function refreshToken(auth) {
  const api =
    API_BASE + getServerAPI(makeDefaultQueryString(REFRESH_TOKEN_API, auth));
  let { host, token } = auth || {};
  const config = {
    method: "POST",
    credentials: "include",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Apx-Host": host,
      "X-Auth-Token": token,
      "Access-Control-Allow-Credentials": true,
    },
    body: JSON.stringify({}),
  };
  return fetch(api, config);
}

/**
 *
 * @param auth
 * @param host
 * @returns {Promise}
 */
export function userExistenceApi(auth, host) {
  const api = makeDefaultQueryString(USER_EXISTENCE_API, auth);
  const config = {
    method: "GET",
    auth: {
      host: host,
    },
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(api, config, onSuccessCallback, onFailureCallback);
}

export function loginWithOTP(data, auth) {
  const config = {
    method: "POST",
    auth: auth,
    body: JSON.stringify(data),
  };
  const onSuccessCallback = (json) => {
    return {
      token: "1234-5678-9101-1213", //FIXME: need token from server
      profile: {
        email: json.userId,
        name: json.name,
      },
      accessExpiry: json.expiry,
    };
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(LOGIN_OTP_API, config, onSuccessCallback, onFailureCallback);
}
