import {
    Button,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography,
    withStyles
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import debounce from "lodash/debounce";
import React, {Component} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import {APP_PLATFORMS} from "../../../../../../../constants";
import {getPlatformLogoColor} from "../../../Apps/components";
import {updateHeading} from "../../../Navigation/actions";
import ClassicCard from "../../Campaigns/Messages2/components/ClassicCard";
import CustomNativeSelect from "../../Campaigns/Messages2/components/Flow/components/modules/CustomNativeSelect";
import ModuleConfigSection from "../../Campaigns/Messages2/components/Flow/components/modules/ModuleConfigSection";
import {PLATFORM_IMAGES} from "../../Campaigns/Messages2/components/Flow/utils";
import {
    getEventRowsAction,
    handleAddAction,
    handleConvertAction,
    handleDeleteAction,
    handleDialogStateAction,
    handleEditAction,
    handleFiltersChangeAction,
    handleRowsPerPageChangeAction,
    updatePageCounterAction,
} from "./actions";
import {RESET_EV_STATE} from "./actionTypes";
import EM_Modal from "./components/EM_Modal";
import PaginationController from "./components/PaginationController";
import {EVENT_SOURCES, MODAL_TYPES} from "./constants";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

const eventMenuStyles = theme => ({
    icon: {
        marginRight: 4,
    }
})

function EventMenu(props) {
    const {classes, canEdit = true, canDelete = true, canConvert = true, handleDialog, onClose} = props;
    return (<MenuList>
        {
            canEdit
            && (<MenuItem
                className={classes.menuItem}
                onClick={() => {
                    handleDialog(true, MODAL_TYPES.EDIT_EVENT_TYPE)
                    onClose();
                }}>
                <ListItemIcon className={classes.icon}>
                    <EditIcon/>
                </ListItemIcon>
                <ListItemText classes={{primary: classes.primary}} inset primary="Edit"/>
            </MenuItem>)
        }
        {
            canConvert
            && (<MenuItem
                className={classes.menuItem}
                onClick={() => {
                    handleDialog(true, MODAL_TYPES.CONVERT_EVENT_TYPE)
                    onClose();
                }}>
                <ListItemIcon className={classes.icon}>
                    <SwapHorizIcon/>
                </ListItemIcon>
                <ListItemText classes={{primary: classes.primary}} inset primary="Convert"/>
            </MenuItem>)
        }
        {
            canDelete
            && (<MenuItem
                className={classes.menuItem}
                onClick={() => {
                    handleDialog(true, MODAL_TYPES.DELETE_EVENT_TYPE)
                    onClose();
                }}>
                <ListItemIcon className={classes.icon}>
                    <DeleteIcon color={"error"}/>
                </ListItemIcon>
                <ListItemText classes={{primary: classes.primary}} inset primary="Delete"/>
            </MenuItem>)
        }
    </MenuList>)
}

EventMenu = withStyles(eventMenuStyles)(EventMenu);

const TableHeaderCell = styled(TableCell)`
    color: #ffffff;
    font-weight: 600;
    font-size: 14px;
`;

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getClientEvents: (appId, filters, after, before, limit) => dispatch(getEventRowsAction(appId, filters, after, before, limit)),
        handleRowsPerPageChange: (key, value) => dispatch(handleRowsPerPageChangeAction(key, value)),
        handleEvMgmtFiltersChange: (key, value) => dispatch(handleFiltersChangeAction(key, value)),
        updatePageCounter: (direction) => dispatch(updatePageCounterAction(direction)),
        handleDialogState: (open, type) => dispatch(handleDialogStateAction(open, type)),
        handleAdd: (appId, event) => dispatch(handleAddAction(appId, event)),
        handleEdit: (appId, event) => dispatch(handleEditAction(appId, event)),
        handleConvert: () => dispatch(handleConvertAction()),
        handleDelete: (appId, eventId) => dispatch(handleDeleteAction(appId, eventId)),
        boundDispatch: dispatch,
    }
}

function mapStateToProps(state) {
    return {
        eventsManagement: state.eventsManagement,
        appVersions: state.app.appVersions,
    };
}

const styles = theme => ({
    content: {
        paddingTop: 36,
        maxWidth: "85%",
        margin: "auto",
    },
    root: {
        width: '100%',
        marginTop: theme.spacing.unit * 3,
    },
    thead: {
        color: "#ffffff",
    },
    table: {
        minWidth: 500,
    },
    tableRow: {
        '&:nth-of-type(odd)': {
            background: "#f7f7f7",
        },
    },
    tableWrapper: {
        overflowX: 'auto',
    },
});

export const SourceChip = styled.div`
    padding: 4px 6px;
    background: ${props => props.color || "#a3aaae"};
    color: white;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
`

const StyledModuleConfigSection = (props) => {
    return (
        <ModuleConfigSection {...props} style={{...props.style, marginRight: 4, marginLeft: 4}}/>
    )
}

class EventsMgmt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            currentRow: null,
            currentRowId: null,
        }
        this.debounce = debounce((fn, ...data) => {
            fn(...data)
        }, 300);
    }

    componentDidMount() {
        const {updateHeading, getClientEvents, params: {appId = ""}, eventsManagement} = this.props;
        const {filters, pagination: {after, before, limit}} = eventsManagement;
        updateHeading("Events Management");
        getClientEvents(appId, filters, after, before, limit);
    }

    componentWillUnmount() {
        const {updateHeading, boundDispatch} = this.props;
        updateHeading(null);
        boundDispatch({type: RESET_EV_STATE, payload: {}, meta: {}})
    }

    render() {
        const {anchorEl, currentRowId, currentRow} = this.state;
        const {eventsManagement: {pagination}, handleRowsPerPageChange, handleEvMgmtFiltersChange, updatePageCounter} = this.props;
        const {classes, getClientEvents, params: {appId = ""}, eventsManagement, appVersions} = this.props;
        const {filters, pagination: {limit}, rows = [], get_client_events_pending} = eventsManagement;
        const {event_type, ...otherFilters} = filters;

        const {handleAdd, handleEdit, handleDelete, handleConvert, handleDialogState, eventsManagement: {dialog}} = this.props;

        const emptyRows = limit - rows.length;
        let numCols = 7;
        return (
            <section className={classes.content}>
                <Grid container justify={"center"}>
                    <ClassicCard style={{
                        padding: 0,
                        borderRadius: 6,
                        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 3px 1px -2px rgba(0,0,0,0.1)",
                    }}>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table}>
                                <thead>
                                <TableRow style={{height: 128}}>
                                    <TableCell colSpan={numCols}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}>
                                            <div style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                alignItems: "center",
                                                alignContent: "center"
                                            }}>
                                                <StyledModuleConfigSection title={"Event Type"}>
                                                    <CustomNativeSelect
                                                        disabled={get_client_events_pending}
                                                        defaultValue={filters.event_type}
                                                        options={
                                                            [
                                                                {key: "client_event", label: "Client Event"}
                                                            ]
                                                        }
                                                        itemName={"event_type"}
                                                        handleChange={(e) => {
                                                            this.debounce(handleEvMgmtFiltersChange, "event_type", (e.target.value));
                                                        }}
                                                    />
                                                </StyledModuleConfigSection>
                                                <StyledModuleConfigSection title={"Platform"}>
                                                    <CustomNativeSelect
                                                        disabled={get_client_events_pending}
                                                        defaultValue={filters.platform}
                                                        options={
                                                            [
                                                                {key: null, label: "All"},
                                                                {key: APP_PLATFORMS.android, label: "Android"},
                                                                {key: APP_PLATFORMS.ios, label: "iOS"}
                                                            ]
                                                        }
                                                        itemName={"Platform"}
                                                        handleChange={(e) => {
                                                            this.debounce(handleEvMgmtFiltersChange, "platform", (e.target.value));
                                                        }}
                                                    />
                                                </StyledModuleConfigSection>
                                                <StyledModuleConfigSection title={"Source"}>
                                                    <CustomNativeSelect
                                                        disabled={get_client_events_pending}
                                                        defaultValue={filters.source}
                                                        options={
                                                            [
                                                                {key: null, label: "All"},
                                                                {key: EVENT_SOURCES.sdk, label: "SDK"},
                                                                {key: EVENT_SOURCES.manual, label: "Manual"},
                                                            ]
                                                        }
                                                        itemName={"ce_source"}
                                                        handleChange={(e) => {
                                                            this.debounce(handleEvMgmtFiltersChange, "source", (e.target.value));
                                                        }}
                                                    />
                                                </StyledModuleConfigSection>
                                                <StyledModuleConfigSection title={"Event Name"} style={{width: 200}}>
                                                    <TextField
                                                        disabled={get_client_events_pending}
                                                        defaultValue={filters.event_name}
                                                        inputProps={{
                                                            style: {padding: 8},
                                                        }}
                                                        fullWidth
                                                        margin="dense"
                                                        variant="outlined"
                                                        style={{margin: 0}}
                                                        placeholder={`event_name`}
                                                        InputProps={{
                                                            endAdornment: (
                                                              <InputAdornment position="end">
                                                                <SearchIcon />
                                                              </InputAdornment>
                                                            ),
                                                        }}
                                                        onChange={(e) => {
                                                            this.debounce(handleEvMgmtFiltersChange, "event_name", e.target.value);
                                                        }}
                                                    />
                                                </StyledModuleConfigSection>
                                                <StyledModuleConfigSection title={"App Version"}>
                                                    <MultiSelect
                                                        style={{width: 200}}
                                                        options={appVersions.map(version => ({
                                                            label: version,
                                                            value: version,
                                                        }))}
                                                        placeholder={"App Version"}
                                                        single
                                                        clearable
                                                        value={filters.app_version}
                                                        handleChange={(app_version) => {
                                                            this.debounce(handleEvMgmtFiltersChange, "app_version", app_version !== "" ? app_version : null);
                                                        }}
                                                    />
                                                </StyledModuleConfigSection>
                                            </div>
                                            <Grid container spacing={16} justify={"flex-end"} alignItems={"center"}>
                                                <Button
                                                    variant={"outlined"}
                                                    color={"primary"}
                                                    onClick={() => {
                                                        handleDialogState(true, MODAL_TYPES.CREATE_NEW)
                                                    }}
                                                >
                                                    <AddIcon style={{marginRight: 4}}/> Add New
                                                </Button>
                                            </Grid>
                                        </div>
                                    </TableCell>
                                </TableRow>
                                <TableRow style={{background: "#305f84"}}>
                                    <TableHeaderCell>Event Name</TableHeaderCell>
                                    <TableHeaderCell>Attribute Name</TableHeaderCell>
                                    <TableHeaderCell>Attribute Type</TableHeaderCell>
                                    <TableHeaderCell align={"center"}>Source</TableHeaderCell>
                                    <TableHeaderCell align={"center"}>Platform</TableHeaderCell>
                                    <TableHeaderCell align={"center"}>App Version</TableHeaderCell>
                                    <TableHeaderCell> </TableHeaderCell>
                                </TableRow>
                                </thead>
                                <TableBody>
                                    {
                                        rows.length > 0
                                        && rows.map(row => {
                                            const rowId = row._id.$oid;
                                            const canEdit = row.source !== EVENT_SOURCES.sdk;
                                            // const canConvert = filters.event_type === CLIENT_EVENT;
                                            const canConvert = false;
                                            const canDelete = row.source !== EVENT_SOURCES.sdk;
                                            const hasActions = [canEdit, canConvert, canDelete].some(x => x);
                                            return (
                                                <TableRow className={classes.tableRow} hover key={rowId}>
                                                    <TableCell> {row.event_name} </TableCell>
                                                    <TableCell> {row.attr_name} </TableCell>
                                                    <TableCell> {row.attr_type} </TableCell>
                                                    <TableCell align={"center"}>
                                                        <SourceChip
                                                            color={row.source === EVENT_SOURCES.sdk ? "slategray" : "#4198ca"}
                                                        >
                                                            {row.source}
                                                        </SourceChip>
                                                    </TableCell>
                                                    <TableCell align={"center"}>
                                                        {PLATFORM_IMAGES(row.platform, getPlatformLogoColor(row.platform), 24)}
                                                    </TableCell>
                                                    <TableCell align={"center"}> {row.app_version} </TableCell>
                                                    <TableCell style={{width: 36, textAlign: "center"}}>
                                                        {
                                                            hasActions
                                                            && <IconButton onClick={(e) => {
                                                                this.setState({
                                                                    anchorEl: e.currentTarget,
                                                                    currentRowId: rowId,
                                                                    currentRow: row,
                                                                })
                                                            }}>
                                                                <MoreVertIcon fontSize={"small"}/>
                                                            </IconButton>
                                                        }

                                                        {
                                                            currentRowId === rowId
                                                            && <Menu
                                                                anchorEl={anchorEl}
                                                                open={Boolean(anchorEl)}
                                                                onClose={() => this.setState({
                                                                    anchorEl: null,
                                                                    currentRow: row,
                                                                    currentRowId,
                                                                })}
                                                                PaperProps={{
                                                                    style: {
                                                                        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.1), 0px 2px 2px 0px rgba(0,0,0,0.1), 0px 3px 1px -2px rgba(0,0,0,0.1)",
                                                                    }
                                                                }}
                                                            >
                                                                <EventMenu
                                                                    canEdit={canEdit}
                                                                    canConvert={canConvert}
                                                                    canDelete={canDelete}
                                                                    handleDialog={handleDialogState}
                                                                    onClose={() => this.setState({
                                                                        anchorEl: null,
                                                                    })}
                                                                />
                                                            </Menu>
                                                        }
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </div>
                        <PaginationController
                            disabled={get_client_events_pending}
                            handleRowsPerPageChange={(...attrs) => this.debounce(handleRowsPerPageChange, ...attrs)}
                            pagination={pagination}
                            updatePageCounter={updatePageCounter}
                            fetchResults={(after, before) => getClientEvents(appId, filters, after, before, limit)}
                        />
                    </ClassicCard>
                </Grid>

                {
                    dialog.open
                    && <EM_Modal
                        event={{...currentRow}}
                        open={dialog.open}
                        dialog_type={dialog.dialog_type}
                        handleAdd={(event) => handleAdd(appId, event)}
                        handleConvert={handleConvert}
                        handleClose={() => handleDialogState(false, null)}
                        handleDelete={(eventId) => handleDelete(appId, eventId)}
                        handleEdit={(event) => handleEdit(appId, event)}
                        appVersions={appVersions}
                        statusMap={{
                            edit_client_events_pending: eventsManagement.edit_client_events_pending,
                            delete_client_events_pending: eventsManagement.delete_client_events_pending,
                        }}
                    />
                }
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(EventsMgmt));
