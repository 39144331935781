/**
 * Created by Rakesh Peela
 * Date: 01-Dec-2019
 * Time: 10:57 PM
 */
import {callApi} from "../../../../api";
import {makeDefaultQueryString, makeDefaultQueryStringUnAuth, withQueryStrings} from "../../../../utils";

const REPORTS_API = 'digest/reports';

export function getReportAPI(appId, reportId, customerId) {
    const url = makeDefaultQueryString(`${REPORTS_API}/${appId}/${reportId}`, {user: {email: customerId}}, null);
    const config = {
        method: "GET",
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback)
}