/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    FOLLOW_EVENT,
    loadMiscData, resetMisc
} from './actions';
import {updateHeading, resetHeading} from '../../Navigation/actions';
import Grid from '@material-ui/core/Grid';
import Box from "../../../../../../components/reusable/Box";
import {
    XAxis, YAxis,
    CartesianGrid, Tooltip, ResponsiveContainer,
    Line, ReferenceLine,
    ComposedChart, Brush
} from 'recharts';
import 'react-input-range/lib/css/index.css';
import CountBox from "../../../../../../components/reusable/MaterialUi/CountBox";
import {roundOffNumber} from "../../../../../../utils";
import {SOCIAL_EVENTS} from "./actionTypes";
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { UserDistributionOverEvent } from './components';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        overview: state.overview,
        meta: state.meta,
        misc: state.misc
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadMiscData: (appId) => dispatch(loadMiscData(appId)),
        resetHeading: () => dispatch(resetHeading()),
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        resetData: () => dispatch(resetMisc()),
        dispatch: dispatch
    };
}

class Misc extends Component {

    state = {
        expanded: null
    };

    componentWillMount(){
        this.props.updateHeading("Custom Overview");
        this.props.loadMiscData(this.props.params.appId);
    }

    componentWillUnmount(){
        this.props.resetData();
    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    render() {
        const {
            misc: {
                uninstalls_per_session = [],
                uninstalls_per_videos_watched = [],
                active_video_watching_hours = [],
                dau_with_video = {},
                unfollows = {},
                users_formed_relationship = {},
                new_installs_formed_relationship = {},
                social_trends_series = {},
                drop_in_activity = {},
                time_spent_on_videos = {},
                time_spent_on_others = {},
                dau_with_audio = {},
                dau_with_gif = {}
            }
        } = this.props;
        return (
            <section className="content">
                <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">Consumption</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{display:'block'}}>
                        <Grid container>
                            <Grid item xs xl lg md sm>
                                <CountBox type="display2" heading={roundOffNumber(time_spent_on_videos / 60, 2)} subheading="Time spent on watching videos (Mins)" />
                            </Grid>
                            <Grid item xs xl lg md sm>
                                <CountBox type="display2" heading={roundOffNumber(time_spent_on_others / 60, 2)} subheading="Time spent on others (Mins)" />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs xl lg md sm>
                                <CountBox heading={roundOffNumber(100 - dau_with_video.percent_not_done_event, 2)} subheading={`% DAU who watched videos (${dau_with_video.event_done_users})`} />
                            </Grid>
                            <Grid item xs xl lg md sm>
                                <CountBox heading={roundOffNumber(100 - dau_with_audio.percent_not_done_event, 2)} subheading={`% DAU who listened audios (${dau_with_audio.event_done_users})`} />
                            </Grid>
                            <Grid item xs xl lg md sm>
                                <CountBox heading={roundOffNumber(100 - dau_with_gif.percent_not_done_event, 2)} subheading={`% DAU who opened gifs (${dau_with_gif.event_done_users})`} />
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">Make users form more relationships</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{display:'block'}}>
                        <Grid container>
                            <Grid item xs xl lg md sm>
                                <CountBox heading={unfollows.event_done_users} subheading="No. of Unfollows" />
                            </Grid>
                            <Grid item xs xl lg md sm>
                                {/*<CountBox heading={uninstalled_follows.event_done_users} subheading="No. of Followed yet uninstalled" />*/}
                                <CountBox heading="NA" subheading="No. of Followed yet uninstalled" />
                            </Grid>
                        </Grid>
                        <UserDistributionOverEvent {...this.props} event={FOLLOW_EVENT}/>
                        <Grid container>
                            <Grid item xs xl lg md sm>
                                <CountBox heading={users_formed_relationship.event_done_users} subheading="No. of existing users who formed their first relationship" />
                            </Grid>
                            <Grid item xs xl lg md sm>
                                <CountBox heading={new_installs_formed_relationship.event_done_users} subheading="No. of new installs who formed their first relationship" />
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">Make users watch more</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{display:'block'}}>
                        <Grid container>
                            <Grid item xs xl lg md sm>
                                <CountBox heading={roundOffNumber(dau_with_video.percent_not_done_event, 2)} subheading="% DAU who haven't watched a Video" />
                            </Grid>
                            <Grid item xs xl lg md sm>
                                <CountBox heading={drop_in_activity.value} subheading="DAU dropped off at sign up page" />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs xl lg md sm>
                                <CountBox heading="NA" subheading="DAU dropped off after language selection" />
                            </Grid>
                            <Grid item xs xl lg md sm>
                                {/*<CountBox heading="NA" subheading="DAU dropped off because it took too long to load the first video" />*/}
                                <CountBox heading="NA" subheading="DAU dropped off because it took too long to load the first video" />
                            </Grid>
                        </Grid>
                        <Box title="Active video watching hours during day">
                            <ResponsiveContainer height={250}>
                                <ComposedChart data={active_video_watching_hours}
                                               margin={{top: 20, right: 20, bottom: 20, left: 20}}>
                                    <XAxis dataKey="key" label={{ value: "Hours", position: "insideBottomRight", dy: -8}} height={45}/>
                                    <YAxis label={{ value: "Watch Count", position: "insideLeft", angle: -90,   dy: 20}}/>
                                    <Tooltip/>
                                    <CartesianGrid stroke='#f5f5f5'/>
                                    <Line type='monotone' dataKey="Watch Count" stroke='#ff7300'/>
                                </ComposedChart>
                            </ResponsiveContainer>
                        </Box>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">User distribution for Social Trends</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{display:'block'}}>
                        {
                            SOCIAL_EVENTS.map(social_event =>
                                <ResponsiveContainer height={220} key={social_event}>
                                    <ComposedChart data={social_trends_series}
                                                   margin={{top: 20, right: 20, bottom: 20, left: 20}} syncId="social-trends">
                                        <XAxis dataKey="key" label={{ value: "Events", position: "insideBottomRight", dy: -8}} height={45}/>
                                        <YAxis label={{ value: "Users", position: "insideLeft", angle: -90,   dy: 30}}/>
                                        <Tooltip/>
                                        <ReferenceLine y={0} stroke='#000'/>
                                        {social_trends_series && social_trends_series.length > 10 && SOCIAL_EVENTS.indexOf(social_event) === (SOCIAL_EVENTS.length - 1) && <Brush
                                            dataKey='key'
                                            height={30}
                                            stroke="#429ef4"
                                            /*tickFormatter={label => getVeoozLabel(label)}*/
                                            endIndex={10}
                                        />
                                        }
                                        <CartesianGrid stroke='#f5f5f5'/>
                                        <Line type='monotone' dataKey={social_event} stroke='#ff7300'/>
                                    </ComposedChart>
                                </ResponsiveContainer>
                            )
                        }
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel defaultExpanded>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h5">Number of uninstalls</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails style={{display:'block'}}>
                        <Grid container>
                            <Grid item  xs xl lg md sm>
                                <Box title="Uninstalls per session">
                                    <ResponsiveContainer height={250}>
                                        <ComposedChart data={uninstalls_per_session}
                                                       margin={{top: 20, right: 20, bottom: 20, left: 20}}>
                                            <XAxis dataKey="key" label={{ value: "Session Count", position: "insideBottomRight", dy: -8}} height={45}/>
                                            <YAxis label={{ value: "Uninstalls", position: "insideLeft", angle: -90,   dy: 20}}/>
                                            <Tooltip/>
                                            <CartesianGrid stroke='#f5f5f5'/>
                                            <Line type='monotone' dataKey='Users' stroke='#ff7300'/>
                                            <ReferenceLine y={0} stroke='#000'/>
                                            { uninstalls_per_session && uninstalls_per_session.length > 10 &&
                                                <Brush
                                                    dataKey='key'
                                                    height={30}
                                                    stroke="#429ef4"
                                                    endIndex={10}
                                                />
                                            }
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </Box>
                            </Grid>
                            <Grid item  xs xl lg md sm>
                                <Box title="Uninstalls per videos watched">
                                    <ResponsiveContainer height={250}>
                                        <ComposedChart data={uninstalls_per_videos_watched}
                                                       margin={{top: 20, right: 20, bottom: 20, left: 20}}>
                                            <XAxis dataKey="key" label={{ value: "Watch Count", position: "insideBottomRight", dy: -8}} height={45}/>
                                            <YAxis label={{ value: "Uninstalls", position: "insideLeft", angle: -90,   dy: 20}}/>
                                            <Tooltip/>
                                            <CartesianGrid stroke='#f5f5f5'/>
                                            <Line type='monotone' dataKey='Users' stroke='#ff7300'/>
                                            <ReferenceLine y={0} stroke='#000'/>
                                            {uninstalls_per_videos_watched && uninstalls_per_videos_watched.length > 10 &&
                                            <Brush
                                                dataKey='key'
                                                height={30}
                                                stroke="#429ef4"
                                                endIndex={10}
                                            />
                                            }
                                        </ComposedChart>
                                    </ResponsiveContainer>
                                </Box>
                            </Grid>
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </section>
        );
    }
}

Misc.propTypes = {

};

Misc.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Misc);
