/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { USER_GROUP_ENUM } from '../../../../../../constants';
import {
    DAU_TIME_SERIES,
    MAU_TIME_SERIES,
    SESSION_TIME_SERIES,
    SESSION_LENGTH_TIME_SERIES,
    NEW_INSTALL_TIME_SERIES,
    OVERVIEW_PENDING,
    OVERVIEW_FAILED,
    RESET_EVENT_ANALYSIS,
    EVENT_ANALYSIS_EVENT_ATTRIBUTES,
    EVENT_ANALYSIS_EVENTS,
    EVENT_ANALYSIS_EVENT_COUNT,
    EVENT_ANALYSIS_EVENT_COUNT_TIME_SERIES,
    EVENT_ANALYSIS_EVENT_SESSION_COUNT,
    EVENT_ANALYSIS_EVENT_SESSION_TIME_SERIES,
    EVENT_ANALYSIS_EVENT_USER_COUNT,
    EVENT_ANALYSIS_EVENT_USER_TIME_SERIES,
    EVENT_ANALYSIS_EVENT_ATTRIBUTE_DISTRIBUTION,
    UPDATE_EVENT_ANALYSIS_EVENT,
    ALL_SESSIONS_COUNT,
    ALL_USERS_COUNT,
    NEW_INSTALL_USERS_COUNT,
    ACTIVE_USERS_COUNT,
    EVENT_ANALYSIS_ATTRIBUTES,
    EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION,
    EVENT_ANALYSIS_EVENT_KPI_COUNT,
    RESET_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION,
    ADD_EVENT_ANALYSIS_CURRENT_EVENT,
    DELETE_EVENT_ANALYSIS_CURRENT_EVENT,
    EVENT_ANALYSIS_RETENTION_PENDING,
    EVENT_ANALYSIS_RETENTION,
    EVENT_ANALYSIS_RETENTION_FAILED,
    EVENT_ANALYSIS_EVENT_KPIS,
    CUSTOM_EVENT_ENUM,
    UPDATE_EVENT_ATTRIBUTE_DISTRIBUTION_OF,
    EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_PENDING,
    EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION,
    EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_FAILED,
    EVENT_ANALYSIS_EVENT_RETENTION_PENDING,
    EVENT_ANALYSIS_EVENT_RETENTION,
    EVENT_ANALYSIS_EVENT_RETENTION_FAILED,
    GET_TOP_EVENTS,
    UNINSTALL_USERS_COUNT,
    UPDATE_ATTRIBUTE_DISTRIBUTION_OF,
    UPDATE_EVENT_ANALYSIS_USER_GROUP,
    UPDATE_EVENT_ANALYSIS_GLOBAL_FILTERS,
    UPDATE_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION_FILTERS,
    UPDATE_EVENT_ANALYSIS_GROUP_DAYS,
    CLEAN_EVENT_ANALYSIS,
    TIME_SPENT
} from './actionTypes';
import { RESET_ALL } from '../../../../../../constants/ActionTypes';
const INITIAL_STATE = {
    install_users: 0,
    uninstall_users: 0,
    active_users: 0,
    inactive_users: 0,
    happy_users: 0,
    unhappy_users: 0,
    crashes: 0,
    anrs: 0,
    all_users: 0,
    all_sessions: 0,
    custom_group_users: 0,
    usp_usability: {},
    usp_stability: {},
    usp_performance: {},
    usp_functionality: {},
    dau_time_series: [],
    mau_time_series: [],
    new_install_time_series: [],
    session_time_series: [],
    session_length_time_series: [],
    events: [],
    event_filters: {},
    distributions: {},
    attributes: [],
    user_attributes: [],
    session_attributes: [],
    current_events: {},
    users_time_series: [],
    sessions_time_series: [],
    counts_time_series: [],
    retention: {},
    top_events: [],
    of: CUSTOM_EVENT_ENUM.users,
    group: USER_GROUP_ENUM.ALL_USERS,
    days: {
        //startDay: undefined,
        //endDay: undefined
    },
    filters: {},
    distributions_filters: {},
    time_spent_sessions: [],
    time_spent_users: []
};

const INITIAL_RETENTION_STATE = {
    retention_pending: false,
    retention_failed: false
};

const INITIAL_REPEATED_RETENTION_STATE = {
    repeated_retention_pending: false,
    repeated_retention_failed: false
};

const INITIAL_CURRENT_EVENT = {
    name: null,
    count: 0,
    users: 0,
    sessions: 0,
    attributes: [],
    distributions: {},
    count_time_series: [],
    user_time_series: [],
    session_time_series: [],
    kpis: {},
    retention: {},
    repeated_retention: {},
    ...INITIAL_RETENTION_STATE,
    ...INITIAL_REPEATED_RETENTION_STATE,
    of: CUSTOM_EVENT_ENUM.users, //default users
};

/**
 *
 * @param payload
 * @param key
 */

function addedWithAverageKey(data, valueKey = null) {
    if (!Array.isArray(data)) return [];
    const average = data.reduce((a, b) => a + b.value, 0) / data.length;
    return data.map(item => {
        let obj = { ...item };
        obj["avg_" + valueKey] = average.toFixed(2); //for calculating value
        if (valueKey) {
            obj[valueKey] = item.value;
            delete obj.value;
        }
        return obj;
    });
}

function mergeTimeSeries(all, event, valueKey) {
    if (!Array.isArray(all) || !Array.isArray(event)) return [];
    event = addedWithAverageKey([...event], valueKey);
    //all = addedWithAverageKey([...all], "All Avg", "All");
    if (all.length === 0 || all.length !== event.length) return event;
    return all.map((item, index) => {
        for (let key in event[index]) {
            if (event[index].hasOwnProperty(key) && key !== "key") {
                item[key] = event[index][key];
            }
        }
        return item;
    });
}

function replaceKeys(data = [], oldKey, newKey) {
    return data.map(item => ({
        [newKey]: item[oldKey],
        ...item
    }));
}

function eventAnalysisReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case NEW_INSTALL_TIME_SERIES:
            return Object.assign({}, state, {
                new_install_time_series: addedWithAverageKey(action.payload)
            });

        case MAU_TIME_SERIES:
            return Object.assign({}, state, {
                mau_time_series: addedWithAverageKey(action.payload)
            });

        case DAU_TIME_SERIES:
            return Object.assign({}, state, {
                dau_time_series: [...action.payload],
                users_time_series: mergeTimeSeries(state.users_time_series, action.payload, "All")
            });

        case SESSION_TIME_SERIES:
            return Object.assign({}, state, {
                session_time_series: [...action.payload],
                sessions_time_series: mergeTimeSeries(state.sessions_time_series, action.payload, "All")
            });

        case SESSION_LENGTH_TIME_SERIES:
            return Object.assign({}, state, {
                session_length_time_series: addedWithAverageKey(action.payload)
            });

        case EVENT_ANALYSIS_EVENTS:
            return {
                ...state,
                events: action.payload
            };

        case ADD_EVENT_ANALYSIS_CURRENT_EVENT:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.payload]: {
                        ...INITIAL_CURRENT_EVENT,
                        ...state.current_events[action.payload] || {},
                        name: action.payload,
                    }
                }
            };

        case DELETE_EVENT_ANALYSIS_CURRENT_EVENT:
            const currentEvents = { ...state.current_events };
            delete currentEvents[action.payload];
            return {
                ...state,
                current_events: currentEvents
            };

        case EVENT_ANALYSIS_EVENT_ATTRIBUTES:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        attributes: action.payload
                    }
                }
            };

        case EVENT_ANALYSIS_EVENT_COUNT:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        count: action.payload
                    }
                }
            };

        case EVENT_ANALYSIS_EVENT_USER_COUNT:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        users: action.payload
                    }
                }
            };

        case EVENT_ANALYSIS_EVENT_SESSION_COUNT:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        sessions: action.payload
                    }
                }
            };

        case EVENT_ANALYSIS_EVENT_COUNT_TIME_SERIES:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        count_time_series: addedWithAverageKey(action.payload, "Avg", action.meta.event)
                    }
                },
                counts_time_series: mergeTimeSeries(state.counts_time_series, action.payload, action.meta.event)
            };

        case EVENT_ANALYSIS_EVENT_USER_TIME_SERIES:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        user_time_series: [...action.payload]
                    }
                },
                users_time_series: mergeTimeSeries(state.users_time_series, action.payload, action.meta.event)
            };

        case EVENT_ANALYSIS_EVENT_SESSION_TIME_SERIES:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        session_time_series: [...action.payload]
                    }
                },
                sessions_time_series: mergeTimeSeries(state.sessions_time_series, action.payload, action.meta.event)
            };

        case EVENT_ANALYSIS_EVENT_ATTRIBUTE_DISTRIBUTION:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        distributions: {
                            ...state.current_events[action.meta.event].distributions,
                            [action.meta.attribute]: action.payload
                        }
                    }
                }
            };

        case RESET_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION:
            let distributions = { ...state.current_events[action.meta.event].distributions };
            if (action.payload) {
                for (let key in distributions) {
                    if (action.payload.indexOf(key) > -1) {
                        delete distributions[key];
                    }
                }
            } else {
                distributions = {};
            }
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        distributions: { ...distributions }
                    }
                }
            };

        case UPDATE_EVENT_ANALYSIS_EVENT:
            return {
                ...state,
                current_events: {
                    ...INITIAL_CURRENT_EVENT,
                    name: action.payload
                }
            };

        case EVENT_ANALYSIS_EVENT_KPIS:
            const kpis = action.payload || [];
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        kpis: kpis.reduce((a, b) => { a[b] = 0; return a; }, {})
                    }
                }
            };

        case EVENT_ANALYSIS_EVENT_KPI_COUNT:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        kpis: {
                            ...state.current_events[action.meta.event].kpis,
                            [action.meta.kpi]: action.payload
                        }
                    }
                }
            };

        case ALL_SESSIONS_COUNT:
            return {
                ...state,
                all_sessions: action.payload
            };

        case ALL_USERS_COUNT:
            return {
                ...state,
                all_users: action.payload
            };

        case NEW_INSTALL_USERS_COUNT:
            return {
                ...state,
                install_users: action.payload
            };

        case UNINSTALL_USERS_COUNT:
            return {
                ...state,
                uninstall_users: action.payload
            };

        case ACTIVE_USERS_COUNT:
            return {
                ...state,
                active_users: action.payload
            };

        case EVENT_ANALYSIS_ATTRIBUTES:
            const { user = [], session = [] } = action.payload || {};
            const allAttributes = [...user, ...session];
            return {
                ...state,
                attributes: allAttributes,
                user_attributes: user,
                session_attributes: session
            };

        case EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION:
            return {
                ...state,
                distributions: {
                    ...state.distributions,
                    [action.meta.attribute]: action.payload
                }
            };

        case EVENT_ANALYSIS_RETENTION_PENDING:
            return {
                ...state,
                ...INITIAL_RETENTION_STATE,
                retention_pending: true
            };

        case EVENT_ANALYSIS_RETENTION:
            return {
                ...state,
                ...INITIAL_RETENTION_STATE,
                retention: action.payload
            };

        case EVENT_ANALYSIS_RETENTION_FAILED:
            return {
                ...state,
                ...INITIAL_RETENTION_STATE,
                retention_failed: true
            };

        case UPDATE_EVENT_ATTRIBUTE_DISTRIBUTION_OF:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        of: action.payload
                    }
                }
            };

        case EVENT_ANALYSIS_EVENT_RETENTION_PENDING:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        ...INITIAL_RETENTION_STATE,
                        retention_pending: true
                    }
                }
            };

        case EVENT_ANALYSIS_EVENT_RETENTION:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        ...INITIAL_RETENTION_STATE,
                        retention: action.payload
                    }
                }
            };

        case EVENT_ANALYSIS_EVENT_RETENTION_FAILED:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        ...INITIAL_RETENTION_STATE,
                        retention_failed: true
                    }
                }
            };

        case EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_PENDING:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        ...INITIAL_REPEATED_RETENTION_STATE,
                        repeated_retention_pending: true
                    }
                }
            };

        case EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        ...INITIAL_REPEATED_RETENTION_STATE,
                        repeated_retention: action.payload
                    }
                }
            };

        case EVENT_ANALYSIS_EVENT_REPEAT_USAGE_RETENTION_FAILED:
            return {
                ...state,
                current_events: {
                    ...state.current_events,
                    [action.meta.event]: {
                        ...state.current_events[action.meta.event],
                        ...INITIAL_REPEATED_RETENTION_STATE,
                        repeated_retention_failed: true
                    }
                }
            };

        case GET_TOP_EVENTS:
            return {
                ...state,
                top_events: action.payload
            };

        case UPDATE_ATTRIBUTE_DISTRIBUTION_OF:
            return {
                ...state,
                of: action.payload
            };

        case UPDATE_EVENT_ANALYSIS_USER_GROUP:
            return {
                ...state,
                group: action.payload
            };

        case UPDATE_EVENT_ANALYSIS_GLOBAL_FILTERS:
            return {
                ...state,
                filters: action.payload
            };

        case UPDATE_EVENT_ANALYSIS_ATTRIBUTE_DISTRIBUTION_FILTERS:
            return {
                ...state,
                distributions_filters: {
                    [action.meta.event]: action.payload
                }
            };

        case UPDATE_EVENT_ANALYSIS_GROUP_DAYS:
            return {
                ...state,
                days: action.payload || {}
            };

        case RESET_EVENT_ANALYSIS:
        case RESET_ALL:
            return INITIAL_STATE;

        case CLEAN_EVENT_ANALYSIS:
            const current_events = Object.keys(state.current_events);
            return {
                ...state,
                counts_time_series: [],
                users_time_series: [],
                sessions_time_series: []
            };

        case OVERVIEW_PENDING:
        case OVERVIEW_FAILED:
        case TIME_SPENT:
            let key = (action.meta.normalized ? "normalized_" : "") + "time_spent_" + action.meta.of;
            let modifiedData = replaceKeys(
                action.payload,
                action.meta.normalized ? "average" : "value",
                "seconds"
            );
            return {
                ...state,
                [key]: (!action.meta.normalized ? addedWithAverageKey(modifiedData, "seconds") : modifiedData) || [],
            };
        default:
            return state;
    }
}

export default eventAnalysisReducer;
