/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../api';
import { makeDefaultQueryString, withQueryStrings} from '../../../../../../utils';

const UNINSTALLS_API = "day-uninstall-trends";
const USER_TRENDS_API = "user-trends";
const UNINSTALL_USER_DISTRIBUTION_API = "uninstall/count";
const UNINSTALL_USAGE_API = "uninstall-usage";

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param body
 * @returns {Promise}
 */
export function getUninstallsAPI(auth, appId, filters, body) {
    const url = makeDefaultQueryString(UNINSTALLS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param body
 * @returns {Promise}
 */
export function getDay0UninstallsAPI(auth, appId, filters, body) {
    const url = makeDefaultQueryString(UNINSTALLS_API, auth, appId) + withQueryStrings({...filters, day: 0});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param body
 * @returns {Promise}
 */
export function getUninstallDistributionAPI(auth, appId, filters, body) {
    const url = makeDefaultQueryString(UNINSTALL_USER_DISTRIBUTION_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param body
 * @returns {Promise}
 */
export function getTotalUserTrendsAPI(auth, appId, filters, body) {
    const url = makeDefaultQueryString(USER_TRENDS_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param body
 * @returns {Promise}
 */
export function getDay0UserTrendsAPI(auth, appId, filters, body) {
    const url = makeDefaultQueryString(USER_TRENDS_API, auth, appId) + withQueryStrings({...filters, day: 0});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param body
 * @returns {Promise}
 */
export function getUninstallUsageAPI(auth, appId, filters, body) {
    const url = makeDefaultQueryString(UNINSTALL_USAGE_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json.sort((a, b) => a.event_count > b.event_count ? -1 : a.event_count < b.event_count ? 1 : 0); //.map(o => {o.event_name = getVeoozLabel(o.event_name); return o;});
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}