/**
 * Created by Rakesh Peela
 * Date: 31-July-2019
 * Time: 6:22 PM
 */

import React from "react";
import { connect } from "react-redux";
import {
  Typography,
  withStyles,
  Card,
  CardContent,
  TextField,
  Button,
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { APXOR_ROLES } from "../../../../../constants";
import {
  PasswordEditSection,
  PendingAppsApproval,
  ProfileSettings,
} from "./components";
import {
  updateCustomerProfileInfo,
  updatePassword,
  resetToDefaults,
  getUserRoleAction,
  start2faSettingsAction,
  enable2faSettingsAction,
  disable2faSettingsAction,
  get2faStatusAction,
} from "./actions";
import Snackbar from "../../../../../components/reusable/MaterialUi/Snackbar";
import Dialog from "../../../../../components/reusable/MaterialUi/Dialog";
import LockIcon from "@material-ui/icons/Lock";
import ToggleSwitch from "../../../../../components/reusable/MaterialUi/ToggleSwitch";

function mapStateToProps(state) {
  return {
    auth: state.auth,
    appState: state.app,
    meta: state.meta,
    customerProfile: state.customerProfile,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    updatePassword: (body) => dispatch(updatePassword(body)),
    updateUserProfileInfo: (body) => dispatch(updateCustomerProfileInfo(body)),
    resetToDefaults: () => dispatch(resetToDefaults()),
    getUserRole: (userId) => dispatch(getUserRoleAction(userId)),
    start2faSettings: (appId) => dispatch(start2faSettingsAction(appId)),
    enable2faSettings: (appId, data) =>
      dispatch(enable2faSettingsAction(appId, data)),
    disable2faSettings: (appId, data) =>
      dispatch(disable2faSettingsAction(appId, data)),
    get2faStatus: (appId) => dispatch(get2faStatusAction(appId)),
    boundDispatch: dispatch,
  };
}

const styles = {
  bigAvatar: {
    margin: "auto",
    width: 120,
    height: 120,
    marginBottom: 16,
  },
};

function RecoveryCard({ code }) {
  return (
    <Grid container spacing={24}>
      <Grid item xs>
        <Typography variant="subtitle1" style={{ marginTop: "0.8em" }}>
          {code}
        </Typography>
      </Grid>
    </Grid>
  );
}

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirm: false,
      disable: false,
      authenticatorCode: "",
      submitted: false,
      password: "",
      dialog: false,
      sfa_inprogress: false
    };
  }

  componentWillReceiveProps(nextProps) {
    nextProps.customerProfile.disabled_2fa && nextProps.customerProfile.sfa_error_message === "" && this.setState({dialog: false})
    nextProps.customerProfile.sfa && this.setState({sfa_inprogress: false})
  }

  componentDidMount() {
    const {
      auth: {
        user: { email },
      },
      getUserRole,
    } = this.props;
    getUserRole(email);
    this.props.get2faStatus();
  }

  componentWillMount() {
    resetToDefaults();
  }

  componentWillUnmount() {
    resetToDefaults();
  }

  handleToggle = (checked) => {
    const {
      params: { appId },
    } = this.props;
    if (checked) {
      this.props.start2faSettings(appId);
      this.setState({sfa_inprogress: true})
    } else {
      if(this.state.sfa_inprogress)
        this.setState({ sfa_inprogress: false });
      else
        this.setState({ dialog: true });
    }
  };

  handleCodeChange = (e) => {
    this.setState({ authenticatorCode: e.target.value });
  };

  handlePasswordChange = (e) => {
    this.setState({ password: e.target.value });
  };

  render() {
    const {
      customerProfile: {
        update_password_success = false,
        update_profile_success = false,
        update_password_failed = false,
        update_profile_failed = false,
        role = APXOR_ROLES.REGULAR_USER,
        pending_app_approvals = [],
        sfa,
        sfa_qr,
        recovery,
        enabled_2fa,
        disabled_2fa,
        sfa_error_message,
      },
      boundDispatch,
      resetToDefaults,
    } = this.props;
    const {
      auth: {
        user: { email },
      },
    } = this.props;

    const { changed, dialog } = this.state;

    return (
      <section style={{ width: "100%", marginTop: 80 }} className="content">
        <Grid container spacing={16} justify={"center"}>
          <Grid item xs={12} sm={10} md={8}>
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <ProfileSettings {...this.props} />
              </Grid>
              <Grid item xs={6}>
                <PasswordEditSection {...this.props} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} style={{ marginTop: 20 }}>
                <Card>
                  <CardContent>
                    <Grid container alignItems={"center"}>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <LockIcon />
                        <Typography variant={"h5"}>
                          {" "}
                          Two Factor Authentication{" "}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        <ToggleSwitch
                          handleChange={this.handleToggle}
                          value={"All"}
                          checked={sfa || this.state.sfa_inprogress}
                        />
                      </Grid>
                    </Grid>

                    <Grid container alignItems={"center"}>
                      {sfa_qr !== "" && this.state.sfa_inprogress && !enabled_2fa && (
                        <Grid
                          item
                          xs={12}
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <img
                            src={sfa_qr}
                            alt={""}
                            style={{ width: 150, height: 150 }}
                          />
                        </Grid>
                      )}
                      {sfa_qr !== "" && this.state.sfa_inprogress && !enabled_2fa && (
                        <>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <Typography
                              variant={"caption"}
                              style={{ marginTop: 20, marginBottom: 20 }}
                            >
                              Enter the six-digit code from the application.
                              After scanning the QR code image, the app will
                              display a six-digit code that you can enter below.
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            <TextField
                              variant="outlined"
                              value={this.state.authenticatorCode}
                              fullWidth
                              placeholder="619 007"
                              onChange={this.handleCodeChange}
                              label="OTP"
                              style={{ maxWidth: 150, marginRight: 20 }}
                            />
                            <Button
                              color="primary"
                              type="submit"
                              variant="contained"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();

                                this.props.enable2faSettings({
                                  otp: this.state.authenticatorCode,
                                });
                                this.setState({ submitted: true });
                              }}
                            >
                              Submit
                              {/* {forgot_password_pending && <Loading size={30} />}
                          {!forgot_password_pending && <span>Submit</span>} */}
                            </Button>
                            {sfa_error_message !== "" && (
                              <Typography
                                variant={"caption"}
                                style={{
                                  marginTop: 20,
                                  marginBottom: 20,
                                  color: "red",
                                  marginLeft: 20,
                                }}
                              >
                                {sfa_error_message}
                              </Typography>
                            )}
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </CardContent>
                  {Array.isArray(recovery) && recovery.length > 0 && (
                    <CardContent>
                      <Typography
                        variant={"caption"}
                        style={{ marginTop: 20, marginBottom: 20 }}
                      >
                        Please Save the below recovery codes for future use.
                      </Typography>
                      {recovery.map((eachCode) => {
                        return <RecoveryCard code={eachCode} />;
                      })}
                    </CardContent>
                  )}
                  <Dialog
                    confirmLabel={"Cancel"}
                    handleConfirm={() => this.setState({ dialog: false })}
                    status={this.state.dialog}
                    title="Disable 2FA"
                  >
                    <CardContent>
                      <Grid container alignItems={"center"}>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <TextField
                            variant="outlined"
                            value={this.state.password}
                            fullWidth
                            placeholder="p@$$w0rd"
                            onChange={this.handlePasswordChange}
                            label="Password"
                            type="password"
                            style={{ maxWidth: 150, marginRight: 20 }}
                          />
                          <Button
                            color="primary"
                            type="submit"
                            variant="contained"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              const {
                                params: { appId },
                              } = this.props;
                              this.props.disable2faSettings({
                                password: this.state.password,
                              });
                            }}
                          >
                            Submit
                            {/* {forgot_password_pending && <Loading size={30} />}
                          {!forgot_password_pending && <span>Submit</span>} */}
                          </Button>
                          {sfa_error_message !== "" && (
                            <Typography
                              variant={"caption"}
                              style={{
                                marginTop: 20,
                                marginBottom: 20,
                                color: "red",
                                marginLeft: 20,
                              }}
                            >
                              {sfa_error_message}
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Dialog>
                </Card>
              </Grid>
            </Grid>

            <hr style={{ marginTop: 16, marginBottom: 16 }} />
            <Grid container spacing={32}>
              {role === APXOR_ROLES.APX_ADMIN && (
                <Grid item>
                  <PendingAppsApproval
                    userId={email}
                    boundDispatch={boundDispatch}
                    pendingApps={pending_app_approvals}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Dialog
          status={Boolean(update_profile_success)}
          title="Profile Updated"
          confirmLabel={"Ok"}
          handleConfirm={() => resetToDefaults()}
        >
          <Typography>
            Your <strong>Profile</strong> has been successfully updated
          </Typography>
        </Dialog>

        {update_profile_failed && (
          <Snackbar>There was a problem updating your profile</Snackbar>
        )}

        {update_password_failed && (
          <Snackbar>{update_password_failed}</Snackbar>
        )}
        <Dialog
          noActions={true}
          status={Boolean(update_password_success)}
          title="Logout Warning"
        >
          <Typography>
            Password change <strong>successful</strong>.
          </Typography>
          <Typography>Please login again.</Typography>
        </Dialog>
      </section>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Profile));
