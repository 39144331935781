/**
 * Created by Rakesh Peela
 * Date: 08-Sep-2019
 * Time: 10:37 AM
 */

import {
    CREATE_COHORTS,
    CREATE_COHORTS_FAILED, CREATE_COHORTS_PENDING,
    GET_COHORTS,
    GET_COHORTS_FAILED,
    GET_COHORTS_PENDING, UPDATING_COHORT, UPDATING_COHORT_FAILED, UPDATING_COHORT_PENDING
} from './actionTypes';
import { RESET_ALL } from '../../../../../../constants/ActionTypes';

const INITIAL_STATE = {
    cohorts: []
};

const INITIAL_FLAG_STATE = {
    savingCohort: false,
    savingCohortSuccess: false,
    savingCohortFailed: false,
    updateCohortPending: false,
    updateCohortSuccess: false,
    updateCohortFailed: false
};

function cohortsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case GET_COHORTS:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                cohorts: action.payload
            });

        case CREATE_COHORTS:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                savingCohortSuccess: true
            });

        case CREATE_COHORTS_FAILED:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                savingCohortFailed: true
            });

        case CREATE_COHORTS_PENDING:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                savingCohort: true
            });

        case UPDATING_COHORT_PENDING:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                updateCohortPending: true
            });

        case UPDATING_COHORT:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                updateCohortSuccess: true
            });

        case UPDATING_COHORT_FAILED:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                updateCohortFailed: true
            });

        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default cohortsReducer;
