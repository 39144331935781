/**
 * Created by jyothi on 1/11/17.
 */

import {
     CUSTOM_QUERY_ATTRIBUTES,CUSTOM_QUERY_ATTRIBUTE_VALUES,
     CUSTOM_QUERY_SAVE, CUSTOM_QUERY_SAVE_FAILED,
     CUSTOM_QUERY_EVENTS,CUSTOM_QUERY_EVENT_ATTRIBUTES,
     CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES,
     CUSTOM_QUERY_UPDATE_QUERY, CUSTOM_QUERY_RESET_QUERY, CUSTOM_QUERY_UPDATE_QUERY_TYPE,
     CUSTOM_EVENT_ENUM, CUSTOM_QUERY_COUNT, CUSTOM_QUERY_UPDATE_GROUP, CUSTOM_QUERY_UPDATE_SEGMENT
} from './actionTypes';
import {CONDTIONAL_OPERATORS, USER_GROUP_ENUM } from "../../../../../../../constants";
import {DASHBOARDS_UPDATE_GROUP} from "../actionTypes";

const INITIAL_QUERY_STATE = {
    name: "",
    user: [],
    session: [],
    event: [],
    condition: CONDTIONAL_OPERATORS.AND
};

const INITIAL_CUSTOM_QUERY_SAVE_FLAG_STATE = {
    save_failed: false,
    save_pending: false
};

const INITIAL_STATE = {
    count: 0,
    user_attributes: [],
    session_attributes: [],
    attribute_values: {},
    events: [],
    event_attributes: {},
    event_attribute_values: [],
    of: CUSTOM_EVENT_ENUM.users,
    query: {...INITIAL_QUERY_STATE},
    saved: false,
    group: USER_GROUP_ENUM.ALL_USERS,
    segment: null,
    ...INITIAL_CUSTOM_QUERY_SAVE_FLAG_STATE
};

function customQueryBuilderReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case CUSTOM_QUERY_ATTRIBUTES:
            const attributes = [ ...action.payload.user, ...action.payload.session ];
            return {
                ...state,
                user_attributes: action.payload.user,
                session_attributes: action.payload.session,
                attribute_values: attributes.reduce((a, b) => {a[b] = []; return a;}, {})
            };

        case CUSTOM_QUERY_ATTRIBUTE_VALUES:
            return {
                ...state,
                attribute_values: {
                    ...state.attribute_values,
                    [action.meta.attribute]: action.payload
                }
            };

        case CUSTOM_QUERY_EVENTS:
            return {
                ...state,
                events: action.payload,
                event_attributes: action.payload.reduce((a, b) => {a[b] = []; return a;}, {}),
                event_attribute_values: action.payload.reduce((a, b) => {a[b] = {}; return a;}, {}),
            };

        case CUSTOM_QUERY_EVENT_ATTRIBUTES:
            return {
                ...state,
                event_attributes: {
                    ...state.event_attributes,
                    [action.meta.event]: action.payload
                },
                event_attribute_values: {
                    ...state.event_attribute_values,
                    [action.meta.event]: action.payload.reduce((a, b) => {a[b] = []; return a;}, {})
                }
            };

        case CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES:
            const { event, attribute } = action.meta;
            return {
                ...state,
                event_attribute_values: {
                    ...state.event_attribute_values,
                    [event]: {
                        ...state.event_attribute_values[event],
                        [attribute]: action.payload
                    }
                }
            };

        case CUSTOM_QUERY_UPDATE_QUERY:
            return {
                ...state,
                query: action.payload,
                ...INITIAL_CUSTOM_QUERY_SAVE_FLAG_STATE,
                saved: false
            };

        case CUSTOM_QUERY_RESET_QUERY:
            return {
                ...state,
                query: INITIAL_QUERY_STATE,
                segment: null,
                saved: false
            };

        case CUSTOM_QUERY_UPDATE_QUERY_TYPE:
            return {
                ...state,
                of: action.payload
            };

        case CUSTOM_QUERY_SAVE:
            return {
                ...state,
                ...INITIAL_CUSTOM_QUERY_SAVE_FLAG_STATE,
                saved: action.payload
            };

        case CUSTOM_QUERY_SAVE_FAILED:
            return {
                ...state,
                ...INITIAL_CUSTOM_QUERY_SAVE_FLAG_STATE,
                save_failed: true
            };

        case CUSTOM_QUERY_COUNT:
            return {
                ...state,
                count: action.payload,
                saved: false
            };

        case DASHBOARDS_UPDATE_GROUP: //FIXME: to sync group filters in dashboard and custom query
        case CUSTOM_QUERY_UPDATE_GROUP:
            return {
                ...state,
                group: action.payload,
                saved: false
            };

        case CUSTOM_QUERY_UPDATE_SEGMENT:
            return {
                ...state,
                segment: action.payload,
                saved: false
            };

        default:
            return state;
    }
}

export default customQueryBuilderReducer;