/**
 * Created by jyothi on 14/1/17.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getMessages, sendMessage, deleteMessage, updateMessage,
    getMessageUIs, sendMessageUI, deleteMessageUI, updateMessageUI,
    resetMessages, publishMessage
} from './actions';
import {updateHeading} from '../../Navigation/actions';
import {
    MessagesWrapper, InLineUISelection
} from './components/components';
import { withStyles } from '@material-ui/core/styles';
import {attributesAsMentions} from "../../../../../../utils";

const styles = theme => ({
    root: {
        border: '1px solid rgba(229, 221, 243, 0.9)',
        margin: `0 ${theme.spacing.unit}px 0 0`,
        minWidth: 60,
        minHeight: 36
    },
    labelContainer: {
        padding: `0 ${theme.spacing.unit / 2}px`,
    },
    selected: {
        color: theme.palette.text.primary
    }
});

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        meta: state.meta,
        notification: state.notification,
        queryBuilder: state.queryBuilder,
        filters: state.filters,
        messages: state.messages
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        resetMessages: () => dispatch(resetMessages()),
        getMessages: (appId) => dispatch(getMessages(appId)),
        sendMessage: (appId, data, configType) => dispatch(sendMessage(appId, data, configType)),
        updateMessage: (appId, messageId, data, configType) => dispatch(updateMessage(appId, messageId, data, configType)),
        deleteMessage: (appId, messageId, configType) => dispatch(deleteMessage(appId, messageId, configType)),
        getMessageUIs: (appId) => dispatch(getMessageUIs(appId)),
        sendMessageUI: (appId, data) => dispatch(sendMessageUI(appId, data)),
        updateMessageUI: (appId, uiId, data) => dispatch(updateMessageUI(appId, uiId, data)),
        deleteMessageUI: (appId, uiId) => dispatch(deleteMessageUI(appId, uiId)),
        publishMessage: (appId, messageId, configType) => dispatch(publishMessage(appId, messageId, configType)),
        loadData: (appId, groupId) => {
            dispatch(getMessages(appId));
            dispatch(getMessageUIs(appId));
        },
        bindedDispatch: dispatch
    };
}

class Messages extends Component {

    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentWillMount(){
        const {appId} = this.props.params;
        this.props.loadData(appId); //for Configurator
        this.props.updateHeading("Messages");
    }

    componentWillReceiveProps(nextProps){

    }

    componentWillUnmount(){
        this.props.resetMessages();
    }

    render() {
        const { appState: { attributes } } = this.props;
        return (
            <section className="content">
                <MessagesWrapper {...this.props} attributeSuggestions={attributesAsMentions(attributes)}/>
            </section>
        );
    }
}

Messages.propTypes = {

};

Messages.contextTypes = {
    router: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles, {withTheme: true})(Messages));
