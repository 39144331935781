/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    GET_APP, EXIT_DASHBOARD,
    GET_PATH_IDS, GET_SESSION_GROUP_IDS,
    GET_USER_GROUP_IDS, TOTAL_UNINSTALLS,
    TOTAL_INSTALLS, ALL_PENDING, ALL_FAILED,
    RESET_APP_DATA, GET_APP_EVENTS, APP_SCREEN_NAMES,
    APP_ATTRIBUTES, APP_SEGMENTS, APP_TEST_DEVICES, APP_CONTEXTS, APP_VERSIONS
} from './actionTypes';
import {ADDING_APP_FAILED, ADDING_APP, ADD_APP, RESET_CREATE_APP_DIALOG} from '../../Apps/actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';

const INITIAL_STATE = {
    app : null,
    installs: 0,
    uninstalls: 0,
    userGroupIds: [],
    pathIds: [],
    sessionGroupIds: [],
    appEvents: [],
    appScreenNames: [],
    appSegments: [],
    appTestDevices: [],
    appContexts: [],
    attributes: {},
    appVersions: [],
    addingApp: false,
    addingFailed: false,
    addingSuccess: false
};

const INITIAL_FLAG_STATE = {
    addingApp: false,
    addingFailed: false,
    addingSuccess: false
};

/**
 * It maintains state of APP after entering DashBoard.
 * Also maintains state of Add App container to hold states like addingApp, addingApp Failed, addingDone etc.
 * @param state
 * @param action
 * @returns {*}
 */

function appReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case GET_APP:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                app: action.payload //hydrating app
            });

        case EXIT_DASHBOARD:
            return Object.assign({}, state, INITIAL_FLAG_STATE);

        case GET_PATH_IDS:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                pathIds: action.payload
            });

        case GET_USER_GROUP_IDS:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                userGroupIds: action.payload
            });

        case ADD_APP:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                apps : state.apps,
                addingSuccess: true
            });
        case ADDING_APP:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                addingApp: true,
            });

        case ADDING_APP_FAILED:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                addingFailed: true,
            });

        case GET_SESSION_GROUP_IDS:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                sessionGroupIds: action.payload
            });

        case GET_APP_EVENTS:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                appEvents: action.payload
            });

        case APP_SCREEN_NAMES:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                appScreenNames: action.payload
            });

        case TOTAL_INSTALLS:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                installs: action.payload,
            });

        case TOTAL_UNINSTALLS:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                uninstalls: action.payload
            });

        case APP_ATTRIBUTES:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                attributes: action.payload
            });

        case APP_SEGMENTS:
            return {
                ...state,
                ...INITIAL_FLAG_STATE,
                appSegments: action.payload,
            };

        case APP_TEST_DEVICES:
            return {
                ...state,
                ...INITIAL_FLAG_STATE,
                appTestDevices: action.payload,
            };

        case APP_CONTEXTS:
            return {
                ...state,
                ...INITIAL_FLAG_STATE,
                appContexts: action.payload,
            };

        case APP_VERSIONS:
            return {
                ...state,
                ...INITIAL_FLAG_STATE,
                appVersions: action.payload,
            };

        case RESET_APP_DATA:
            return INITIAL_STATE;

        case RESET_ALL:
            return INITIAL_STATE;

        /**
         * FIXME: these actionTypes must be for all
         */
        case ALL_FAILED:
        case ALL_PENDING:
        default:
            return state;

    }
}

export default appReducer;