/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../api';
import {makeDefaultQueryString, withQueryStrings} from '../../../../../../utils'

const PATH_IDS = "paths";
const USER_GROUP_IDS = "user-groups";
const SESSION_GROUP_IDS = "session-groups";
const APP_INSTALLS = "total-users/install_users";
const APP_UNINSTALLS = "total-users/uninstall_users";
const GET_APP_API = "apps/";

const APP_EVENTS_API = "events";

const APP_SCREEN_NAMES_API = "activities"; //FIXME: not activities name it as screens

const ATTRIBUTES_API = "attributes";
const VERSIONS_API = "versions";

/**
 *
 * @param auth
 * @param appId
 * @returns {Promise}
 */
export function getAppAPI(auth, appId) {
    const url = makeDefaultQueryString(GET_APP_API + appId, auth);
    const config = {
        method: "GET",
        auth: auth
    };

    const onSuccessCallback = json => {
        return json;
    };

    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 * Selected Path Ids with their filters information
 * @param auth
 * @param appId
 * @returns {Promise}
 */
export function getPathIdsAPI(auth, appId) {
    const url = makeDefaultQueryString(PATH_IDS, auth, appId);
    const config = {
        method: "GET",
        auth: auth
    };

    const onSuccessCallback = json => {
        return Array.isArray(json) ? json : []; //FIXME: server sending Object {}
    };

    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 * user Groups with complete information
 * @param auth
 * @param appId
 * @returns {Promise}
 */
export function getUserGroupIdsAPI(auth, appId) {
    const url = makeDefaultQueryString(USER_GROUP_IDS, auth, appId); //TODO: can give version also
    const config = {
        method: "GET",
        auth: auth
    };

    const onSuccessCallback = json => {
        return json;
    };

    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getAppEventsAPI(auth, appId) {
    const url = makeDefaultQueryString(APP_EVENTS_API, auth, appId); //TODO: can give version also
    const config = {
        method: "GET",
        auth: auth
    };

    const onSuccessCallback = json => {
        return json;
    };

    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getAppScreenNamesAPI(auth, appId) {
    const url = makeDefaultQueryString(APP_SCREEN_NAMES_API, auth, appId); //TODO: can give version also
    const config = {
        method: "GET",
        auth: auth
    };

    const onSuccessCallback = json => {
        return json;
    };

    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 * session groups with complete information
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getSessionGroupIdsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(SESSION_GROUP_IDS, auth, appId)
    const config = {
        method: "GET",
        auth: auth
    };

    const onSuccessCallback = json => {
        return json;
    };

    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 * @deprecated
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getTotalInstallsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(APP_INSTALLS, auth, appId);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };

    const onSuccessCallback = json => {
        return json;
    };

    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 * @deprecated
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getTotalUninstallsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(APP_UNINSTALLS, auth, appId);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };

    const onSuccessCallback = json => {
        return json;
    };

    const onFailureCallback = response => {
        return {};
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getAttributesAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(ATTRIBUTES_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getAppVersionsAPI(appId, auth, filters) {
    const url = makeDefaultQueryString(VERSIONS_API, auth, appId);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}