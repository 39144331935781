import React, { Component } from 'react';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from "../../../../../../../components/reusable/Box";

const iconGenerator = (config) => {
    const { icon_path, large_icon_path, large_icon_url }  = config || {};
    if(icon_path || large_icon_path || large_icon_url) {
        return <Avatar src={large_icon_url} alt="Icon"/>
    }else{
        return null;
    }
};

const renderTitle = (text) => (
    <Typography style={{fontSize: 12}}>{text}</Typography>
);

const renderSubTitle = (text) => (
    <Typography variant="caption" style={{fontSize: 9}}>{text}</Typography>
);

const renderBody = (text) => (
    <Typography variant="caption" style={{fontSize: 10}}>{text}</Typography>
);

const renderButtons = (buttons) => {
    return buttons.length > 0 ? <div style={{display: 'flex', justifyContent: 'space-between'}}>
        {
            buttons.map(button => <Button size="small" key={button.name}>{button.name}</Button>)
        }
    </div> : undefined
};

export class Simple extends Component {

    render() {
        const {
            notification_title = "Title", notification_body = "Body", buttons_config = [],
            icon_config
        } = this.props;
        return (
            <Box
                title={renderTitle(notification_title)}
                icon={iconGenerator(icon_config)}
                footer={renderButtons(buttons_config)}
            >
                {renderBody(notification_body)}
            </Box>
        );
    }
}

export class BigText extends Component {

    state = {
        expanded: false
    };

    render() {
        const {
            notification_title = "Title", notification_body = "Body", buttons_config = [],
            icon_config, long_text = "Long Text"
        } = this.props;
        const { expanded } = this.state;
        return (
            <Box
                title={
                    <CardHeader
                        title={renderTitle(notification_title)}
                        subheader={!expanded && renderSubTitle(notification_body)}
                    />
                }
                icon={iconGenerator(icon_config)}
                collapsible
                footer={renderButtons(buttons_config)}
                onCollapse={expanded => this.setState({expanded})}
                defaultExpanded
            >
                {renderBody(long_text)}
            </Box>
        );
    }
}

export class BigImage extends Component {

    state = {
        expanded: false
    };

    render() {
        const {
            notification_title = "Title",buttons_config = [],
            icon_config, big_content_title = "Big Content Title", image_path, image_url,
        } = this.props;
        const { expanded } = this.state;
        return (
            <Box
                title={renderTitle(expanded ? big_content_title : notification_title)}
                icon={iconGenerator(icon_config)}
                collapsible
                footer={renderButtons(buttons_config)}
                onCollapse={expanded => this.setState({expanded})}
                defaultExpanded
            >
                <img src={image_url || image_path} alt={image_path} style={{width: '100%', height: '100%'}} />
            </Box>
        );
    }
}