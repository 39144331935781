/**
 * Created by Araja Jyothi Babu on 22-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {copyToClipboard} from "../../../../../utils";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/ButtonBase';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import {APP_PLATFORMS} from "../../../../../constants";

function mapStateToProps(state) {
    return {
        auth: state.auth,
        router: state.routing,
        appsState: state.apps,
        appState: state.app
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

class Apps extends Component {

    constructor(props){
        super(props);
        this.state = {
            isIntegrated: false
        }
    }

    componentWillMount(){
        if(this.props.appState.app){
            this.handleNavigation(this.props.appState.app);
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.appState.app){
            this.handleNavigation(nextProps.appState.app);
        }
    }

    handleNavigation(app){
        const { router } = this.context;
        if(app.app_versions && Object.keys(app.app_versions).length !== 0){
            router.push("/apps/"+ app.app_id + "/event-analysis");
        }
    }

    render() {
        const { appState: { app: { basic_info: { icon_url, app_name, platform } } }, params: { appId }} = this.props;
        const isWeb = platform === APP_PLATFORMS.web;
        return (
            <section style={{width: '100%', marginTop: 100, height: 'calc(100vh - 100px)'}}>
                <Grid container justify="center">
                    <Grid item xs={12} md={8}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Integrate ApxorSDK with <strong>{app_name}</strong></Typography>
                                <Typography variant="subtitle1">
                                    The Integration Guide will be mailed to your registered email.
                                    Please contact us for any queries.
                                </Typography>
                            </CardContent>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="App Icon" style={{background: 'transparent'}}>
                                        <img src={icon_url} alt={app_name} style={{width: '100%', height: '100%'}} />
                                    </Avatar>
                                }
                                action={
                                    <Tooltip id="appId-copy" title={this.state.copied ? 'Copied' : 'Copy AppId'}>
                                        <IconButton style={{margin: 16}} onClick={() => {
                                            this.setState({copied: true}, () => {
                                                copyToClipboard(appId);
                                                setTimeout(() => {
                                                    this.setState({copied: false});
                                                }, 2000);
                                            });
                                        }}>
                                            <ContentCopyIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                                title={appId}
                                subheader={`${isWeb ? "Site " : "App " }Id`}
                            />
                        </Card>
                    </Grid>
                </Grid>
            </section>
        );
    }
}

Apps.propTypes = {

};

Apps.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Apps);