/**
 * Created by Rakesh Peela
 * Date: 28-Nov-2019
 * Time: 10:44 AM
 */

import {Card, CardContent, Divider, Grid, Typography, withStyles} from "@material-ui/core";
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';
import moment from "moment";
import {connect} from "react-redux";
import React from 'react';
import {updateHeading} from "../RequireAuth/Navigation/actions";
import {getReport} from "./actions";
import ReportItemHolder from "./components/ReportItemHolder";
import Report_TimeseriesTrend from "./components/Report_TimeseriesTrend";
import queryString from 'query-string';

const mapStateToProps = (state) => {
    return {
        dailyReport: state.dailyReport
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        loadData: (appId, reportId, customerId) => dispatch(getReport(appId, reportId, customerId))
    }
};

const styles = theme => ({
    sectionStyle: {
        width: '80%',
        margin: "auto",
        marginTop: 36,
        paddingTop: 36,
        paddingBottom: 64,
        [theme.breakpoints.down('md')]: {
            width: '95%'
        },
    },
    warningLandscape: {
        display: "none",
        textAlign: "center",
        maxWidth: "75%",
        [`${theme.breakpoints.down('md')} and (orientation:portrait)`]: {
            display: 'inline-block',
            margin: "auto",
            marginTop: 12,
        },
    }
});

const REPORT_WIDGET_TYPES = {
    "TIMESERIES_COUNT": "timeseries_count",
    "TIMESERIES_PERCENTAGE": "timeseries_percentage"
};

class DailyReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentWillMount() {
        const {appId, reportId} = this.props.params;
        const queryParams = this.props.location.search;
        const values = queryString.parse(queryParams);
        this.props.loadData(appId, reportId, values.customerId || "kk@apxor.com");
        // this.props.updateHeading("Daily Reports")
    }

    render() {
        const {dailyReport, classes} = this.props;
        const queryParams = this.props.location.search;
        const values = queryString.parse(queryParams);
        const {report: {owner, title}} = dailyReport;

        return (
            <section className={classes.sectionStyle}>
                <Card>
                    <CardContent style={{padding: 16}}>
                        <Grid container spacing={16}>
                            <Grid item xs={12} md={8}>
                                <Typography style={{marginBottom: 16, fontWeight: 800}} variant={"h5"}>
                                    <span style={{
                                        fontSize: 15,
                                        fontWeight: 400
                                    }}> Here's your daily report between </span> {moment().subtract(30, 'd').format("DD MMM YYYY")}
                                    <span
                                        style={{
                                            fontSize: 15,
                                            fontWeight: 400
                                        }}> and </span> {moment().subtract(1, 'd').format("DD MMM YYYY")}
                                </Typography>
                                <Typography variant={"h4"} style={{fontSize: 20, fontWeight: 800}}>
                                    {title || "--"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant={"h6"} style={{fontWeight: 800}}>
                                    <span style={{fontWeight: 400}}> Owner: </span> {owner || "loading ..."}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <div style={{textAlign: "center", width: "100%"}}>
                    <Card className={classes.warningLandscape}>
                        <CardContent style={{display: "flex", flexDirection: "column"}}>
                            <ScreenRotationIcon style={{margin: "auto", marginBottom: 12, fontSize: 32}}/>
                            <Typography variant={"subtitle1"}>
                                For better experience, rotate your device to Landscape
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div>
                    {
                        dailyReport && dailyReport.report.widgets && dailyReport.report.widgets.map(widget => {
                            const {config: {query_params: {of, group_by}}, widget_type} = widget;
                            switch (widget_type) {
                                case REPORT_WIDGET_TYPES.TIMESERIES_COUNT:
                                    return <ReportItemHolder title={widget.title}
                                                             description={widget.description + (group_by !== "" ? ` [groupBy=${group_by}]` : "")}
                                                             key={widget.id}>
                                        <Report_TimeseriesTrend widgetData={widget}/>
                                    </ReportItemHolder>;
                                case REPORT_WIDGET_TYPES.TIMESERIES_PERCENTAGE:
                                    return <ReportItemHolder key={widget.id}>
                                        <div/>
                                    </ReportItemHolder>;
                                default:
                                    return <ReportItemHolder key={widget.id}>
                                        <div/>
                                    </ReportItemHolder>;
                            }
                        })
                    }
                </div>
                <div style={{textAlign: "center", paddingTop: 12}}>
                    <Divider/>
                    <Typography variant={"subtitle2"} style={{paddingTop: 12}}>
                        For further details contact: <span style={{fontWeight: 800}}>
                        <a href={"mailto:support@apxor.com"} style={{textDecoration: "none"}}>support@apxor.com</a>
                    </span>
                    </Typography>
                </div>
            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DailyReport))