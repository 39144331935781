/**
 * Created by Araja Jyothi Babu on 04-Nov-16.
 */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

//TODO: add proper loading symbol
export default class Loading extends React.Component{

    render() {
        const { size = 50 } = this.props;
        return (
            <div style={{textAlign: 'center'}}>
                <CircularProgress color="primary" size={size} {...this.props}/>
            </div>
        );
    }
}