import React from 'react';
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PeopleIcon from '@material-ui/icons/People';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AssignmentIcon from '@material-ui/icons/Assignment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import {withStyles} from '@material-ui/core/styles';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

class PropertySelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            selectedProperty: null,
            searchText: ''
        };
    }

    onTabChange = (event, value) => {
        this.setState({selectedTab: value});
        this.props.onTabChange(event, value);
    };

    onSearchChange = (event) => {
        this.setState({
            searchText: event.target.value
        });
    };

    render() {
        const {classes, onPropertySelected} = this.props;
        const {userProperties = [], sessionProperties = [], eventProperties = []} = this.props;
        const propertiesMapping = [userProperties, sessionProperties, eventProperties];
        return (
            <React.Fragment>
                <Tabs style={{borderCollapse: 'separate'}} variant="fullWidth" value={this.state.selectedTab}
                      onChange={this.onTabChange}>
                    <Tab icon={<PeopleIcon/>} label="User Properties"/>
                    <Tab icon={<AccessTimeIcon/>} label="Session Properties"/>
                    {/* <Tab icon={<AssignmentIcon />} label="Event Properties" /> */}
                </Tabs>
                <Divider/>
                <TextField
                    id="search"
                    type="search"
                    label="Search for a property"
                    onChange={this.onSearchChange}
                    className={classes.search}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon/>
                            </InputAdornment>
                        ),
                    }}
                />
                <List component="nav" className={classes.results}>
                    {propertiesMapping[this.state.selectedTab]
                    .filter(property => property.startsWith(this.state.searchText))
                    .map(property => (
                        <React.Fragment key={property + "_frag"}>
                            <ListItem button onClick={() => {
                                onPropertySelected(property)
                            }}>
                                <ListItemText primary={property}/>
                            </ListItem>
                            <Divider/>
                        </React.Fragment>
                    ))}
                </List>
            </React.Fragment>
        )
    }
}

PropertySelector.propTypes = {
    classes: PropTypes.object.isRequired,
    onPropertySelected: PropTypes.func,
    userProperties: PropTypes.array.isRequired,
    sessionProperties: PropTypes.array.isRequired,
    eventProperties: PropTypes.array.isRequired
};

const StyledPropertySelector = withStyles(theme => ({
    dialogPaper: {
        minHeight: 'calc(100% - 96px)',
        maxHeight: 'calc(100% - 96px)',
    },
    search: {
        marginTop: 10,
        width: "100%",
    },
    results: {
        width: '100%',
        position: 'relative',
        overflow: 'auto',
        maxHeight: 300,
    },
}))(PropertySelector);

class PropertyFinalizer extends React.Component {
    render() {
        const {classes, onClear, icon, property, onDefaultInput} = this.props;
        return (
            <div>
                <Button
                    color="primary"
                    size="medium"
                    onClick={onClear}
                >
                    <ChevronLeftIcon fontSize="default" style={{marginRight: 4}}/>
                    Edit
                </Button>
                <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="center"
                >
                    <Grid xs={12} item key="property_chip">
                        <Typography component="h2" variant="h6">Selected property</Typography>
                    </Grid>
                    <Grid>
                        <Chip
                            icon={icon}
                            label={property}
                            onDelete={onClear}
                            color="primary"
                            style={{
                                marginBottom: 32
                            }}
                        />
                    </Grid>
                    <Grid xs={12} item key="fallback_title">
                        <Typography>
                            Enter a fallback value
                        </Typography>
                    </Grid>
                    <Grid xs={12} item key="default_value">
                        <TextField
                            required
                            onChange={onDefaultInput}
                            id="standard-required"
                            label="Fallback Value"
                            margin="normal"
                            variant="outlined"
                        />
                    </Grid>

                </Grid>
            </div>
        );
    }
}

const StyledPropertyFinalizer = withStyles(theme => ({
    item: {
        margin: "10px"
    }
}))(PropertyFinalizer);

class MacrosDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            selectedProperty: null,
            defaultValue: ""
        };
    }

    handlePropertySelected = (property) => {
        this.setState({
            selectedProperty: property
        });
    };

    handleClearProperty = () => {
        this.setState({
            selectedProperty: null
        });
    };

    handleTabChange = (_, tabIndex) => {
        this.setState({selectedTab: tabIndex})
    };

    handleDefaultValueInput = (event) => {
        this.setState({defaultValue: event.target.value})
    };

    propertyType = () => {
        const mapping = ["user", "session", "event"];
        return mapping[this.state.selectedTab];
    };

    render() {
        const {onCancel, onDone} = this.props;
        const {classes} = this.props;
        const iconMapping = [(<PeopleIcon/>), (<AccessTimeIcon/>), (<AssignmentIcon/>)];
        return (
            <Dialog
                maxWidth="sm"
                fullWidth
                scroll="paper"
                open={true}
                onClose={this.handleCloseDialog}
                disableBackdropClick
                disableEscapeKeyDown
                classes={{paper: classes.dialogPaper}}
            >
                <DialogTitle>Select a property for macro</DialogTitle>
                <DialogContent>
                    {this.state.selectedProperty === null ? <StyledPropertySelector
                        {...this.props}
                        onPropertySelected={this.handlePropertySelected}
                        onTabChange={this.handleTabChange}

                    /> : <StyledPropertyFinalizer
                        icon={iconMapping[this.state.selectedTab]}
                        property={this.state.selectedProperty}
                        onClear={this.handleClearProperty}
                        onDefaultInput={this.handleDefaultValueInput}
                    />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">Cancel</Button>
                    <Button
                        onClick={() => {
                            onDone(this.propertyType(), this.state.selectedProperty, this.state.defaultValue)
                        }}
                        color="primary"
                        disabled={this.state.defaultValue === "" || this.state.selectedProperty === null}
                    >
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

MacrosDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    onDone: PropTypes.func,
    onCancel: PropTypes.func,
    userProperties: PropTypes.array.isRequired,
    sessionProperties: PropTypes.array.isRequired,
    eventProperties: PropTypes.array.isRequired
};

export default withStyles(theme => ({
    dialogPaper: {
        minHeight: 'calc(100% - 96px)',
        maxHeight: 'calc(100% - 96px)',
    }
}))(MacrosDialog);