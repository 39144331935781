/**
 * Created by Araja Jyothi Babu on 01-Nov-16.
 */
import React  from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const defaultText = "No Data Available...!";

export default class Placeholder extends React.Component{

    render(){
        const { height = 100, withIcon = false, icon, text, typographyStyles, children } = this.props;
        if(withIcon){
            return(
                <div style={{textAlign: 'center'}}>
                    { icon }
                    <Typography variant="caption" style={{...typographyStyles}}>{text}</Typography>
                    {children}
                </div>
            )
        }
        return(
            <div style={{ width: '100%', height: height, textAlign: 'center'}}>
                {this.props.isStretchable?
                    <svg width="100%" height={height + "px"} xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <text y="50%" textLength="100%" lengthAdjust="spacingAndGlyphs">{this.props.text}</text>
                        </g>
                    </svg>
                    :
                    <Typography variant="caption">{text || defaultText }</Typography>
                }
            </div>
        )
    }

}

Placeholder.propTypes = {
    isStretchable: PropTypes.bool,
    text: PropTypes.string,
    height: PropTypes.any,
    withIcon: PropTypes.bool
};