import {
    addAdditionalCustomerAPI, removeAdditionalCustomerAPI,
    addDashboardToAdditionalCustomerAPI, removeDashboardToAdditionalCustomerAPI, transferAppAPI
} from "../../../../../../../api";
import {
    IAM_ADD_CUSTOMER_PENDING, IAM_ADD_CUSTOMER, IAM_ADD_CUSTOMER_FAILED,
    IAM_REMOVE_CUSTOMER_PENDING, IAM_REMOVE_CUSTOMER, IAM_REMOVE_CUSTOMER_FAILED,
    IAM_ADD_DASHBOARD_TO_CUSTOMER_PENDING, IAM_ADD_DASHBOARD_TO_CUSTOMER, IAM_ADD_DASHBOARD_TO_CUSTOMER_FAILED,
    IAM_REMOVE_DASHBOARD_TO_CUSTOMER_PENDING, IAM_REMOVE_DASHBOARD_TO_CUSTOMER, IAM_REMOVE_DASHBOARD_TO_CUSTOMER_FAILED,
    IAM_TRANSFER_APP, IAM_TRANSFER_APP_FAILED, IAM_TRANSFER_APP_PENDING
} from "./actionTypes";
import {
    getApp
} from "../../../Apps/App/actions";

/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

export const addCustomer = (appId, config) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                IAM_ADD_CUSTOMER_PENDING, IAM_ADD_CUSTOMER, IAM_ADD_CUSTOMER_FAILED
            ],
            payload: {
                promise: addAdditionalCustomerAPI(appId, getState().auth, filters, config)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {

            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(data){
                        setTimeout(() => {
                            dispatch(getApp(appId, true));
                        }, 1);
                    }
                }
            }
        });
    };
};

export const transferAppOwnership = (appId, body) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                IAM_TRANSFER_APP_PENDING, IAM_TRANSFER_APP, IAM_TRANSFER_APP_FAILED
            ],
            payload: {
                promise: transferAppAPI(appId, getState().auth, body)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {

            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(data) {
                        setTimeout(() => {
                            dispatch(getApp(appId, true))
                        }, 1);
                    }
                }
            }
        })
    }
};

export const removeCustomer = (appId, customerId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        const auth = { user: { email: customerId }};
        return dispatch({
            types: [
                IAM_REMOVE_CUSTOMER_PENDING, IAM_REMOVE_CUSTOMER, IAM_REMOVE_CUSTOMER_FAILED
            ],
            payload: {
                promise: removeAdditionalCustomerAPI(appId, auth, filters, customerId)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {

            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(data){
                        setTimeout(() => {
                            dispatch(getApp(appId, true));
                        }, 1);
                    }
                }
            }
        });
    };
};

export const addDashboardToCustomer = (appId, customerId, dashboardId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, customerId, dashboardId};
        const auth = { user: { email: customerId }};
        return dispatch({
            types: [
                IAM_ADD_DASHBOARD_TO_CUSTOMER_PENDING, IAM_ADD_DASHBOARD_TO_CUSTOMER, IAM_ADD_DASHBOARD_TO_CUSTOMER_FAILED
            ],
            payload: {
                promise: addDashboardToAdditionalCustomerAPI(appId, auth, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {

            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(data){
                        setTimeout(() => {
                            dispatch(getApp(appId, true));
                        }, 1);
                    }
                }
            }
        });
    };
};

export const removeDashboardToCustomer = (appId, customerId, dashboardId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, dashboardId};
        const auth = { user: { email: customerId }};
        return dispatch({
            types: [
                IAM_REMOVE_DASHBOARD_TO_CUSTOMER_PENDING, IAM_REMOVE_DASHBOARD_TO_CUSTOMER, IAM_REMOVE_DASHBOARD_TO_CUSTOMER_FAILED
            ],
            payload: {
                promise: removeDashboardToAdditionalCustomerAPI(appId, auth, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {

            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(data){
                        setTimeout(() => {
                            dispatch(getApp(appId, true));
                        }, 1);
                    }
                }
            }
        });
    };
};