/**
 * Created by Rakesh Peela
 * Date: 26-Nov-2019
 * Time: 3:21 PM
 */

import {Grid} from "@material-ui/core";
import debounce from "lodash/debounce";
import React from 'react';
import {ACTION_CLASSES} from "../../models/AvailableOptions";
import {CONTAINER_CONFIG, MESSAGE_TOOLTIP_LAYOUT_TYPES, TEXT_CONFIG} from "../../models/common";
import {ButtonGroup} from "../../utils";
import CoachmarkSim from "./coachmark";
import NudgeSim from "./nudge";
import TooltipSim from "./tooltip";
import InAppSimulator from "./inapp";
import ModuleConfigSection from "../modules/ModuleConfigSection";
import generateShortID from "../../../../utils";

const DEFAULT_ORIENTATIONS = ["Portrait", "Landscape"];

const getContainerConfigs = (completeSet, keysSet) => {
    if (completeSet.action_class === ACTION_CLASSES.NUDGE) {
        return completeSet;
    }
    let resultSet = {};
    Object.keys(keysSet).forEach(key => resultSet[key] = completeSet[key]);
    return resultSet;
};

class MessageSimulator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            containerConfigs: {
                hasTitle: true,
                hasDescription: true,
                hasButtons: true,
                config: {...getContainerConfigs(this.props.messages2.new_ui, CONTAINER_CONFIG)}
            },
            orientation: "Portrait",
        };

        this.debounce = debounce((fn, data, stepIndex) => {
            fn(data, stepIndex)
        }, 300);
    }

    onButtonSelect = (index) => {
        const {updateInNewUI, currentStep, messages2: {new_ui}} = this.props;

        this.setState({
            orientation: DEFAULT_ORIENTATIONS[index]
        }, () => {
            this.debounce(updateInNewUI, {...new_ui, orientation: DEFAULT_ORIENTATIONS[index]}, currentStep);
        });
    };

    componentWillReceiveProps(nextProps) {
        const {orientation} = this.state;

        if (nextProps.messages2 && nextProps.messages2.new_ui && nextProps.messages2.new_ui.orientation !== orientation) {
            this.setState({
                orientation: nextProps.messages2.new_ui.orientation || "Portrait",
            });
        }
    }

    render() {
        const {handleModuleSelection, selectedModule} = this.props;
        const {messages2: {new_ui: {action_class}}} = this.props;
        const {orientation} = this.state;
        const {messages2: {new_ui}} = this.props;
        let Compound = null;

        if (action_class === ACTION_CLASSES.TOOLTIP) {
            const {messages2: {new_ui: {layout_type, action_buttons_config = [], buttons_config, title_config, text_config, image}}} = this.props;
            Compound = (
                <TooltipSim
                    selectedModule={selectedModule}
                    containerConfigs={{config: {...getContainerConfigs(this.props.messages2.new_ui, CONTAINER_CONFIG)}}}
                    titleConfig={title_config}
                    tooltipType={layout_type}
                    descriptionConfig={text_config}
                    buttonsConfig={layout_type === MESSAGE_TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS ? action_buttons_config : buttons_config}
                    image={image}
                    handleModuleSelection={handleModuleSelection}
                />
            )
        }

        if (action_class === ACTION_CLASSES.NUDGE) {
            Compound = (
                <NudgeSim config={new_ui}/>
            )
        }

        if (action_class === ACTION_CLASSES.INAPP) {
            // A grid with ButtonGroup and InAppSimulator components
            // FIXME: UI Layout is getting disturbed with this two item grid
            return (
                <Grid container direction={"row"}>
                    <Grid item xs={12} md={12} sm={12}>
                        <Grid container justify={"flex-end"} alignContent={"flex-end"} alignItems={"flex-end"}>
                            <Grid item>
                                <ModuleConfigSection
                                    title={"Screen Orientation"}
                                    style={{marginTop: 20, marginRight: 10}}>
                                    <ButtonGroup
                                        buttons={DEFAULT_ORIENTATIONS}
                                        selectedIndex={DEFAULT_ORIENTATIONS.indexOf(orientation)}
                                        onButtonSelected={(index) => this.onButtonSelect(index)}/>
                                </ModuleConfigSection>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} sm={12}>
                        <Grid container justify={"center"} alignContent={"center"} alignItems={"center"}>
                            <Grid item>
                                <InAppSimulator
                                    containerConfigs={{config: {...getContainerConfigs(this.props.messages2.new_ui, CONTAINER_CONFIG)}}}
                                    config={new_ui}
                                    handleModuleSelection={handleModuleSelection}
                                    selectedModule={selectedModule}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }

        if (action_class === ACTION_CLASSES.COACHMARK) {
            const {messages2: {new_ui: {text_config}}} = this.props;
            Compound = (
                <CoachmarkSim
                    config={new_ui}
                    textConfig={text_config}
                    handleModuleSelection={handleModuleSelection}
                />
            )
        }

        return (
            <Grid container justify={"center"} alignContent={"center"} alignItems={"center"}>
                <Grid item>
                    {Compound}
                </Grid>
            </Grid>
        );
    }
}

export default MessageSimulator