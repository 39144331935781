import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ItemTypes } from './constants';
import { DragSource, DropTarget } from 'react-dnd';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveIcon from '@material-ui/icons/RemoveCircle';
import IconButton from "@material-ui/core/ButtonBase";

/*
const widgetSource = {
    beginDrag(props) {
        return props;
    }
};
*/

const widgetSource = {
    beginDrag(props) {
        return {
            ...props,
            id: props.id,
            originalIndex: props.findWidget(props.id).index
        }
    },

    endDrag(props, monitor) {
        const { id: droppedId, originalIndex } = monitor.getItem();
        const didDrop = monitor.didDrop();

        if (!didDrop) {
            props.moveWidget(droppedId, originalIndex)
        }
    },

    canDrag: function(props, monitor) {
        return props.canDrag;
    }

};

const widgetTarget = {
    canDrop() {
        return false
    },

    hover(props, monitor) {
        const { id: draggedId } = monitor.getItem();
        const { id: overId } = props;

        if (draggedId !== overId) {
            const { index: overIndex } = props.findWidget(overId);
            props.moveWidget(draggedId, overIndex);
        }
    },
};

function sourceCollect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}

function targetCollect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget()
    }
}

class Widget extends Component {

    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        index: PropTypes.number.isRequired,
        isDragging: PropTypes.bool.isRequired,
        id: PropTypes.any.isRequired,
        text: PropTypes.string.isRequired,
    };

    constructor(props){
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const {
            connectDragSource, connectDropTarget,
            inEditMode
        } = this.props;
        const styles = inEditMode ?
            {cursor: 'move', border: BORDER_STYLE} : {pointerEvents: 'none', cursor: 'default'};
        return connectDragSource(
            connectDropTarget(
                <div style={{...styles}}>
                    <ComponentWrapper {...this.props}/>
                </div>
            )
        )
    }

}

const BORDER_STYLE = 'dotted 1px #333';

class ComponentWrapper extends Component {

    render(){
        const {
            handleRemove, inEditMode, updateWidgetProps,
            index, component, id, widget
        } = this.props;
        return(
            <div style={{pointerEvents: 'auto', width:'100%', position: 'relative', padding: 0, margin: 0, marginBottom: -10, overflow: 'hidden'}}>
                {   inEditMode && <div style={{
                    position: 'absolute',
                    zIndex: 30,
                    right: 0,
                }}>
                    <Tooltip id={"widget-remove-tooltip-" + Math.random()} title="Remove" placement="top">
                        <IconButton aria-label="Remove" onClick={() => handleRemove(index, component)} style={{height: 'auto', width: 'auto'}}>
                            <RemoveIcon/>
                        </IconButton>
                    </Tooltip>
                </div>
                }
                <div style={{resize: 'horizontal', overflowX: 'auto', maxWidth: '100%'}}>
                    <widget.component {...this.props} {...component} _id={id} updateWidgetProps={updateWidgetProps(index)}/>
                </div>
            </div>
        );
    }

}

ComponentWrapper.propTypes = {
    component: PropTypes.object,
    id: PropTypes.string
};

Widget.propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    id: PropTypes.any.isRequired,
    moveWidget: PropTypes.func.isRequired,
    findWidget: PropTypes.func.isRequired,
};

export default DropTarget(ItemTypes.WIDGET, widgetTarget, targetCollect)(DragSource(ItemTypes.WIDGET, widgetSource, sourceCollect)(Widget));