/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 10:26 AM
 */

import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    Grid,
    IconButton,
    ListItemIcon,
    Menu,
    MenuItem,
    Tooltip,
    Typography,
    withStyles
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from "@material-ui/icons/Save";
import Apxor from 'apxor';
import React from 'react';
import {connect} from "react-redux/src";
import {Link} from "react-router";
import Loading from "../../../../../../../../../components/reusable/Loading";
import {APXOR_SDK_CATEGORY_CONSTANTS} from "../../../../../../../../../constants";
import {updateHeading} from "../../../../../Navigation/actions";
import {getCohorts} from "../../../../Cohorts/actions";
import {getClientEventsList, getMessagesList, saveMessageAction, updateMessageAction} from "../../actions";
import {RESET_NEW_MESSAGE_STATE, RESET_STATUS_MESSAGE} from "../../actionTypes";
import {
    deleteStepFromCampaignAction,
    resetNewMessageFlowState,
    setMessageFromEdit,
    updateInFlowAction,
    updateInNewUI,
    updateOnSelectStep
} from './actions';
import CampaignSummary from "./pages/CampaignSummary";
import Page1 from "./pages/Page1";
import Page2 from "./pages/Page2";
import Page3 from "./pages/Page3";
import Page4 from "./pages/Page4";
import {AUDIENCE_TYPES} from "./utils";

function mapStateToProps(state) {
    return {
        appState: state.app,
        filters: state.filters,
        cohorts: state.cohorts,
        messages2: state.messages2,
        meta: state.meta,
        session: state.auth,
        queryBuilder: state.queryBuilder,
        segmentBuilder: state.segmentBuilder
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        // saveDataToState: (data) => dispatch(updateStateWithData(data)),
        updateInFlow: (data) => dispatch(updateInFlowAction(data)),
        setNewMessageInFlow: (data) => dispatch(setMessageFromEdit(data)),
        updateInNewUI: (data, index) => dispatch(updateInNewUI(data, index)),
        getMessagesList: (appId) => dispatch(getMessagesList(appId)),
        saveMessage: (appId, messageData) => dispatch(saveMessageAction(appId, messageData)),
        updateEditMessage: (appId, messageData) => dispatch(updateMessageAction(appId, messageData)),
        getClientEventsList: (appId) => dispatch(getClientEventsList(appId)),
        updateOnSelectStep: (stepData, index) => dispatch(updateOnSelectStep(stepData, index)),
        resetNewMessageFlowState: (appId) => dispatch(resetNewMessageFlowState(appId)),
        deleteStepFromCampaign: (stepIndex) => dispatch(deleteStepFromCampaignAction(stepIndex)),
        bindedDispatch: dispatch
    }
}

const styles = (theme) => ({
    content: {
        padding: '25px 5%',
        height: 'auto'
    },
    root: {
        width: "100%",
        minHeight: 360,
        borderRadius: 10,
        border: "1px solid #e4eaec",
        marginBottom: 6,
        boxShadow: "rgba(60, 64, 67, 0.1) 0px 1px 2px 0px, rgba(60, 64, 67, 0.10) 0px 1px 1px 0px"
    },
    cardContent: {
        padding: "16px 32px"
    },
    cardActions: {
        padding: 16,
        paddingTop: 0
    },
    menuPaper: {
        border: "1px solid #e4eaec"
    },
    cardHeaderActionsSection: {
        minWidth: 120
    }
});

const STEPS_ENUM = [
    {
        title: "Information",
        description: "Create a new Campaign.",
        avatarIcon: 1
    },
    {
        title: "Design",
        description: "Design experience of your Multi-Step Walkthrough",
        avatarIcon: 2
    },
    {
        title: "Context",
        description: "Set the Hyper Contextual Conditions when to Trigger the Walkthrough for Live users.",
        avatarIcon: 3
    },
    {
        title: "Strategy",
        description: "Choose Campaign Audience, Goal events and Scheduling Strategy",
        avatarIcon: 4
    },
    {
        title: "Summary",
        description: "A summary of the Campaign you created",
        avatarIcon: 5
    }
];

const navigationStyles = () => ({
    bar: {
        height: 8,
        borderRadius: 8
    },
    determinate: {
        borderRadius: 8
    }
});

const hasNecessaryAudienceConditions = (audience_type, cohort_id, segment_id) => {
    if (audience_type === AUDIENCE_TYPES[1].key) {
        if (segment_id !== null && segment_id !== "") return true;
    } else if (audience_type === AUDIENCE_TYPES[2].key) {
        if (cohort_id !== null && cohort_id !== "") return true;
    } else return audience_type === AUDIENCE_TYPES[0].key;
}

class NavigationButtons extends React.Component {
    render() {
        const {currentStep, incrementStep, decrementStep, withProgress} = this.props;
        return (
            <div style={{marginLeft: "auto", display: "flex", flexDirection: "row", alignItems: "center"}}>
                <Button color={"primary"}
                        size={"small"}
                        disabled={!(currentStep > 0)}
                        onClick={decrementStep}
                >
                    <ChevronLeftIcon fontSize="small" style={{marginRight: 4}}/>
                    Prev
                </Button>
                {withProgress && <Typography style={{marginRight: 12, marginLeft: 12}}>
                    {currentStep + 1} / 5
                </Typography>}
                <Button variant={"contained"}
                        size={"small"}
                        color={"primary"}
                        disabled={!(currentStep < 4)}
                        style={{
                            marginLeft: 6,
                        }}
                        onClick={incrementStep}
                >
                    Next
                    <ChevronRightIcon fontSize="small" style={{marginLeft: 4}}/>
                </Button>
            </div>
        );
    }
}

NavigationButtons = withStyles(navigationStyles)(NavigationButtons);

class NewMessageFlow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentStep: 0,
            ready: false,
            fromEditMessage: false,
            // stepsStatus: [].concat(...Array.from({length: 5}, () => [{completed: false}])),
            menuTargetElement: null,
            inProgress: false,
            saveInProgress: false,
            showDiscardDialog: false,
            showCreateNewDialog: false,
            showSaveDialog: false,
            showSnackbar: false
        }
    }

    handleShowExitDialog = () => {
        console.log("Show Exit Dialog");
    };

    componentDidMount() {
        const {params: {appId}, filters, bindedDispatch} = this.props;
        bindedDispatch(getCohorts(appId, filters));
    }

    UNSAFE_componentWillMount() {
        const {appState: {app: {app_id}}} = this.props;
        this.props.updateHeading("");
        if (this.props.location.hasOwnProperty("state") && this.props.location.state) {
            const {location: {state: messageState}} = this.props;
            if (messageState !== {}) {
                if (messageState.hasOwnProperty("isCopy")) {
                    let {isCopy, ...others} = messageState;
                    this.props.updateInFlow({
                        ...others
                    });
                    setTimeout(() => {
                        this.setState({
                            fromEditMessage: false,
                            ready: true
                        });
                    }, 1000)
                } else {
                    this.props.updateInFlow(messageState);
                    setTimeout(() => {
                        this.setState({
                            fromEditMessage: true,
                            ready: true
                        });
                    }, 1000)
                }
            }
            if (messageState.hasOwnProperty("published")) {
                console.log("Editing Saved Message");
            }
        } else {
            this.props.getMessagesList(app_id);
            this.setState({
                ready: true,
            });
        }
        // this.props.getClientEventsList(app_id);
        window.onbeforeunload = () => {
            this.handleShowExitDialog();
        }
    }

    componentWillUnmount() {
        this.handleShowExitDialog();
        this.props.bindedDispatch({type: RESET_STATUS_MESSAGE, payload: null});
        this.props.bindedDispatch({type: RESET_NEW_MESSAGE_STATE, payload: null, meta: {nullifyLocalStorage: false}});
    };

    handleUpdate = () => {
    };

    handleMenuClose = () => {
        this.setState({
            menuTargetElement: null
        })
    };

    handleMenuItemClick = (event, index) => {
        this.setState({
            menuTargetElement: null,
            currentStep: index
        })
    };

    handleSwitchStep = (event) => {
        event.stopPropagation();
        this.setState({
            menuTargetElement: event.currentTarget
        })
    };

    incrementStep = () => {
        const {messages2: {new_message}, appState: {app: {app_id}}} = this.props;
        Apxor.logEvent("ClickNext", {step: this.state.currentStep + 1}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
        // window.localStorage.setItem("apx_messages2_cache-" + app_id, JSON.stringify({...new_message}));
        this.setState({currentStep: this.state.currentStep + 1});
    };

    decrementStep = () => {
        const {messages2: {new_message}, appState: {app: {app_id}}} = this.props;
        Apxor.logEvent("ClickNext", {step: this.state.currentStep}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
        // window.localStorage.setItem("apx_messages2_cache-" + app_id, JSON.stringify({...new_message}));
        this.setState({currentStep: this.state.currentStep - 1});
    };

    jumpToStep = (stepIndex) => {
        this.setState({currentStep: stepIndex})
    };

    handleSnackbarClosed = () => {
        this.setState({
            showSnackbar: false
        }, () => {
            this.props.bindedDispatch({type: RESET_STATUS_MESSAGE, payload: null})
        })
    };

    render() {
        const {classes, ...otherProps} = this.props;
        const {
            messages2: {
                progress_state,
                new_message,
                new_message: {
                    audience: {
                        audience_type = AUDIENCE_TYPES[0].key,
                        cohort_id = "",
                        segment_id = ""
                    }
                }
            }
        } = this.props;
        const {
            appState: {
                app: {
                    app_id,
                    basic_info: {platform}
                },
                attributes,
                appTestDevices = []
            },
            bindedDispatch, saveMessage, updateEditMessage
        } = this.props;
        const {currentStep, menuTargetElement, inProgress, showDiscardDialog, showSaveDialog, saveInProgress} = this.state;
        const {ready, fromEditMessage} = this.state;

        const macroProperties = {
            userProperties: attributes.user || [],
            sessionProperties: attributes.session || [],
            eventProperties: attributes.event || [],
        };

        // Add more validations here
        // let validUIs = new_message.uis.length > 0 && new_message.uis[0].hasOwnProperty("type") && new_message.uis[0].hasOwnProperty("ui");

        const canBeSaved = new_message.meta.name !== ""
            && new_message.uis.length > 0
            && hasNecessaryAudienceConditions(audience_type, cohort_id, segment_id);

        return (
            <section className={classes.content}>
                <Card className={classes.root} elevation={0}>
                    {ready && <div>
                        <Grid container justify={"space-between"} alignItems={"center"} style={{
                            borderTopRightRadius: 10,
                            // backgroundColor: "#0d47a1",
                            borderTopLeftRadius: 10
                        }}>
                            <Grid item>
                                <div style={{
                                    padding: 12,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                }}>
                                    <Avatar style={{
                                        paddingRight: 12,
                                        paddingLeft: 12
                                    }}>{STEPS_ENUM[currentStep].avatarIcon}/5</Avatar>
                                    <div style={{marginLeft: 10}}>
                                        <Typography variant={"h5"} style={{
                                            fontWeight: 600,
                                            // color: "#ffffff"
                                        }}>
                                            {STEPS_ENUM[currentStep].title}
                                        </Typography>
                                        <Typography style={{
                                            // color: "#ffffff"
                                        }}>
                                            {STEPS_ENUM[currentStep].description}
                                        </Typography>
                                    </div>
                                </div>
                            </Grid>
                            <Grid item>
                                <CardActions style={{padding: 12, display: 'flex', alignItems: "center"}}>
                                    <div style={{
                                        paddingRight: 12,
                                        marginRight: 12,
                                        borderRight: "1px solid #e1e1e1",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center"
                                    }}>
                                        <Button
                                            color={"primary"}
                                            variant={"outlined"}
                                            style={{marginRight: 6}}
                                            disabled={!canBeSaved}
                                            onClick={() => {
                                                this.setState({
                                                    showSaveDialog: true,
                                                })
                                            }}
                                        >
                                            <SaveIcon style={{marginRight: 6}}/>
                                            {
                                                fromEditMessage
                                                && new_message.hasOwnProperty("_id")
                                                && new_message._id
                                                    ? "Update"
                                                    : "Save"
                                            }
                                        </Button>
                                        {
                                            !inProgress &&
                                            <Tooltip title={"Discard changes"}>
                                                <IconButton style={{marginLeft: 6, color: "red"}} onClick={() => {
                                                    this.setState({
                                                        inProgress: true,
                                                        showDiscardDialog: true
                                                    })
                                                }}>
                                                    <DeleteForeverIcon fontSize={"small"}/>
                                                </IconButton>
                                            </Tooltip>
                                        }
                                        {
                                            inProgress && <div style={{minWidth: 44, marginLeft: 6}}>
                                                <Loading size={18}/>
                                            </div>
                                        }
                                    </div>
                                    <NavigationButtons
                                        incrementStep={this.incrementStep}
                                        decrementStep={this.decrementStep}
                                        currentStep={currentStep}
                                    />
                                </CardActions>
                            </Grid>
                        </Grid>
                        <Divider/>
                        <CardContent className={classes.cardContent}>
                            <Page1 hidden={currentStep !== 0} appPlatform={platform} {...otherProps}/>
                            <Page2 hidden={currentStep !== 1} {...otherProps} {...macroProperties}/>
                            <Page3 hidden={currentStep !== 2} appTestDevices={appTestDevices} {...otherProps}/>
                            <Page4 hidden={currentStep !== 3} appPlatform={platform} {...otherProps}/>
                            <CampaignSummary
                                hidden={currentStep !== 4}
                                jumpToStep={this.jumpToStep}
                                testDevices={appTestDevices}
                                withEdit
                                {...otherProps}
                            />
                        </CardContent>
                        <CardActions className={classes.cardActions}>
                            <NavigationButtons
                                incrementStep={this.incrementStep}
                                decrementStep={this.decrementStep}
                                currentStep={currentStep}
                            />
                        </CardActions>
                    </div>}
                    {
                        !ready &&
                        <div style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: 36,
                            margin: 36,
                            flexDirection: "column",
                            textAlign: "center"
                        }}>
                            <Loading size={72}/>
                            <Typography style={{marginTop: 12}}>
                                Please wait
                            </Typography>
                        </div>
                    }
                </Card>
                <Menu
                    id="change-step-menu"
                    classes={{paper: classes.menuPaper}}
                    anchorEl={menuTargetElement}
                    open={Boolean(menuTargetElement)}
                    onClose={this.handleMenuClose}
                >
                    {
                        STEPS_ENUM.map((step, index) =>
                            (<MenuItem
                                key={"step_i_" + index}
                                disabled={index === currentStep}
                                selected={index === currentStep}
                                onClick={event => this.handleMenuItemClick(event, index)}
                            >
                                <ListItemIcon>
                                    <Avatar style={{width: 24, height: 24}}>
                                        {step.avatarIcon}
                                    </Avatar>
                                </ListItemIcon>
                                <Typography variant={"subheading"}>
                                    {step.title}
                                </Typography>
                            </MenuItem>)
                        )
                    }
                </Menu>

                {showDiscardDialog &&
                <Dialog
                    open={showDiscardDialog}
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={() => {
                        this.setState({
                            showDiscardDialog: false,
                            inProgress: false
                        })
                    }}
                >
                    <DialogContent>
                        Do you really want to discard changes?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                this.setState({
                                    showDiscardDialog: false,
                                    inProgress: false
                                })
                            }}
                        >
                            No
                        </Button>
                        <Link to={`/apps/${app_id}/walkthroughs`} onClick={() => {
                            Apxor.logEvent("DiscardChanges", {}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
                            window.localStorage.removeItem("apx_messages2_cache-" + app_id);
                            bindedDispatch(resetNewMessageFlowState({from: "discard", appId: app_id}));
                        }}>
                            <Button style={{color: "red"}}>
                                Yes
                            </Button>
                        </Link>
                    </DialogActions>
                </Dialog>}

                {showSaveDialog && <Dialog
                    open={showSaveDialog}
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={() => {
                        this.setState({
                            showDiscardDialog: false,
                            inProgress: false
                        })
                    }}
                >
                    <DialogContent>
                        {fromEditMessage && new_message.hasOwnProperty("_id") ? "Do you want to Update changes?" : "Do you want to Save changes?"}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => {
                            Apxor.logEvent(
                                (fromEditMessage && new_message.hasOwnProperty("_id")) ? "UpdateMessage" : "SaveMessage",
                                {name: new_message.meta.name},
                                APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS
                            );
                            this.setState({
                                showSaveDialog: false,
                                inProgress: false
                            })
                        }}>
                            No
                        </Button>
                        <Button
                            color={"primary"}
                            disabled={saveInProgress}
                            onClick={() => {
                                this.setState({
                                    saveInProgress: true
                                });
                                if (fromEditMessage && new_message.hasOwnProperty("_id")) {
                                    updateEditMessage(app_id, new_message)
                                } else {
                                    saveMessage(app_id, new_message)
                                }
                            }}
                        >
                            {saveInProgress && <Loading size={16}/>}
                            {!saveInProgress && "Yes"}
                        </Button>
                    </DialogActions>
                </Dialog>}

                <Snackbar
                    key={"new_message_snackbar"}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    open={progress_state.statusMessage !== ""}
                    autoHideDuration={3000}
                    onClose={this.handleSnackbarClosed}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<span id="message-id">{progress_state.statusMessage || ""}</span>}
                />
            </section>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NewMessageFlow));