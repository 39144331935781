/**
 * Created by Rakesh Peela
 * Date: 27-Dec-2019
 * Time: 11:12 AM
 */
import React from "react";
import styled from 'styled-components';
import {Button, Card, Fab, Grid, Typography, withStyles} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EditIcon from '@material-ui/icons/Edit';

const styles = (theme) => ({
    sheetC: {
        border: 0,
        display: "inline-flex",
        outline: "none",
        padding: 0,
        position: "relative",
        alignItems: "center",
        userSelect: "none",
        verticalAlign: "middle",
        justifyContent: "center",
        textDecoration: "none",
        backgroundColor: "transparent",
        textAlign: "initial",
        minWidth: 120,
        minHeight: 120,
        margin: 12,
    }
});

function getAbsolutePosition(pos) {
    switch (pos) {
        case ("top_center"):
            return ` left: 50%; top: 0%; `;
        case ("left"):
            return ` left: 0%; top: 0%; `;
        case ("center"):
            return ` left: 50%; top: 50%; `;
        case ("right"):
        default:
            return ` left: 100%; top: 0%; `;

    }
}

function getNotificationDotStyle(props) {
    const {bg_color, position, animation} = props;
    return `
        position: relative;
        @keyframes cycle {
            0% {
                transform: translateY(4px);
            }
            5% {
                transform: translateY(0px);
            }
            10% {
                transform: translateY(-4px);
            }
            15% {
                transform: translateY(0px);
            }
            20% {
                transform: translateY(4px);
            }
            25% {
                transform: translateY(0px);
            }
        }
        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            background: ${bg_color || "red"};
            ${getAbsolutePosition(position || "right")}
            margin: -4px;
            height: 8px;
            width: 8px;
            border-radius: 8px;
            border: none;
            ${animation !== "none" ? "animation: " + animation + " 1.5s ease-in-out 0s infinite;" : ""}
        }
    `
}

const StyledButton = styled(Button)`
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
    background-color: #2196f3 !important;
    ${props => getNotificationDotStyle(props)}
`;


const StyledIcon = styled.div`
    ${props => getNotificationDotStyle(props)}
`;

const StyledFAB = styled(Fab)`
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);
    background-color: #2196f3 !important;
    color: #fff;
    ${props => getNotificationDotStyle(props)}
`;

const StyledCard = styled(({bg_color, withImage, position, animation, children, ...rest}) =>
    <Card {...rest}> {children}</Card>)`
    min-width: 120px;
    height: 72px;
    text-align: center;
    display: flex;
    align-items: center;
    padding: 12px 18px;
    font-weight: 800;
    color: #a3a3a3;
    ${props => getNotificationDotStyle(props)}
    ${props => props.withImage ? "background: url(https://upload.wikimedia.org/wikipedia/en/2/27/Bliss_%28Windows_XP%29.png) center no-repeat; " +
    "height: 120px; width: 150px;" +
    "box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);"
    : ""}
`;

class NudgeSim extends React.Component {
    constructor(props) {
        super(props);
    }

// {
//     "name": "",
//     "view_id": "",
//     "activity": "",
//     "bg_color": "#e40002",
//     "layout_type": "default",
//     "has_ripple": false,
//     "display_type": "passive_nudge",
//     "delay": 0,
//     "type": "IN_LINE",
//     "action_class": "nudge"
// }

    render() {
        const {config} = this.props;
        const {bg_color, position, animation = "none"} = config;
        return (
            <div>
                <Grid container spacing={32} alignItems={"center"}>
                    <Grid item>
                        <StyledIcon bg_color={bg_color} position={position} animation={animation}>
                            <NotificationsIcon fontSize={"large"}/>
                        </StyledIcon>
                    </Grid>
                    <Grid item>
                        <StyledIcon bg_color={bg_color} position={position} animation={animation}>
                            <AccountCircleIcon fontSize={"large"}/>
                        </StyledIcon>
                    </Grid>
                    <Grid item>
                        <StyledIcon bg_color={bg_color} position={position} animation={animation}>
                            <MoreVertIcon fontSize={"large"}/>
                        </StyledIcon>
                    </Grid>
                    <Grid item>
                        <StyledFAB bg_color={bg_color} position={position} animation={animation}>
                            <EditIcon style={{color: "#ffffff"}}/>
                        </StyledFAB>
                    </Grid>
                    <Grid item>
                        <StyledButton variant={"contained"} bg_color={bg_color} position={position}
                                      animation={animation}>
                            <Typography style={{fontWeight: 600, color: "#ffffff"}}>
                                Button
                            </Typography>
                        </StyledButton>
                    </Grid>
                </Grid>
                <Grid container spacing={32} alignItems={"center"} style={{marginTop: 24}}>
                    <Grid item>
                        <StyledCard bg_color={bg_color} position={position} animation={animation} withImage={false}>
                            Card with content
                        </StyledCard>
                    </Grid>
                    <Grid item>
                        <div style={{background: "#ffffff", borderRadius: 4, border: "1px solid #d6d6d6", padding: 8}}>
                            <StyledCard bg_color={bg_color} position={position} animation={animation} withImage={true}/>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(NudgeSim);