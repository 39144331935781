/**
 * Created by jyothi on 1/11/17.
 */

import {
    SEGMENT_ATTRIBUTES, SEGMENT_ATTRIBUTE_VALUES,
    SEGMENT_SAVE, SEGMENT_SAVE_FAILED, SEGMENT_EVENTS,
    SEGMENT_EVENT_ATTRIBUTES,SEGMENT_EVENT_ATTRIBUTE_VALUES,
    SEGMENT_UPDATE_QUERY, SEGMENT_RESET_QUERY, SEGMENT_UPDATE_QUERY_TYPE,
    CUSTOM_EVENT_ENUM, SEGMENT_COUNT
} from './actionTypes';
import {CONDTIONAL_OPERATORS} from "../../../../../../../constants";

const INITIAL_QUERY_STATE = {
    name: "",
    user: [],
    session: [],
    event: [],
    condition: CONDTIONAL_OPERATORS.AND
};

const INITIAL_SEGMENT_SAVE_FLAG_STATE = {
    save_failed: false,
    save_pending: false
};

const INITIAL_STATE = {
    count: 0,
    user_attributes: [],
    session_attributes: [],
    attribute_values: {},
    events: [],
    event_attributes: {},
    event_attribute_values: [],
    of: CUSTOM_EVENT_ENUM.users,
    query: {...INITIAL_QUERY_STATE},
    saved: false,
    ...INITIAL_SEGMENT_SAVE_FLAG_STATE
};

function segmentBuilderReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case SEGMENT_ATTRIBUTES:
            const attributes = [ ...action.payload.user, ...action.payload.session ];
            return {
                ...state,
                user_attributes: action.payload.user,
                session_attributes: action.payload.session,
                attribute_values: attributes.reduce((a, b) => {a[b] = []; return a;}, {})
            };

        case SEGMENT_ATTRIBUTE_VALUES:
            return {
                ...state,
                attribute_values: {
                    ...state.attribute_values,
                    [action.meta.attribute]: action.payload
                }
            };

        case SEGMENT_EVENTS:
            return {
                ...state,
                events: action.payload,
                event_attributes: action.payload.reduce((a, b) => {a[b] = []; return a;}, {}),
                event_attribute_values: action.payload.reduce((a, b) => {a[b] = {}; return a;}, {}),
            };

        case SEGMENT_EVENT_ATTRIBUTES:
            return {
                ...state,
                event_attributes: {
                    ...state.event_attributes,
                    [action.meta.event]: action.payload
                },
                event_attribute_values: {
                    ...state.event_attribute_values,
                    [action.meta.event]: action.payload.reduce((a, b) => {a[b] = []; return a;}, {})
                }
            };

        case SEGMENT_EVENT_ATTRIBUTE_VALUES:
            const { event, attribute } = action.meta;
            return {
                ...state,
                event_attribute_values: {
                    ...state.event_attribute_values,
                    [event]: {
                        ...state.event_attribute_values[event],
                        [attribute]: action.payload
                    }
                }
            };

        case SEGMENT_UPDATE_QUERY:
            return {
                ...state,
                query: { ...INITIAL_QUERY_STATE, ...action.payload },
                ...INITIAL_SEGMENT_SAVE_FLAG_STATE,
                saved: false
            };

        case SEGMENT_RESET_QUERY:
            return {
                ...state,
                query: INITIAL_QUERY_STATE,
                saved: false
            };

        case SEGMENT_UPDATE_QUERY_TYPE:
            return {
                ...state,
                of: action.payload
            };

        case SEGMENT_SAVE:
            return {
                ...state,
                ...INITIAL_SEGMENT_SAVE_FLAG_STATE,
                saved: action.payload
            };

        case SEGMENT_SAVE_FAILED:
            return {
                ...state,
                ...INITIAL_SEGMENT_SAVE_FLAG_STATE,
                save_failed: true
            };

        case SEGMENT_COUNT:
            return {
                ...state,
                count: action.payload,
                saved: false
            };

        default:
            return state;
    }
}

export default segmentBuilderReducer;