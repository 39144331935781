/**
 * Created by jyothi on 14/1/17.
 */
import {
    SEND_MESSAGE_FAILED,  SEND_MESSAGE_SUCCESS,
    MESSAGES, MESSAGES_FAILED, DELETE_MESSAGE_FAILED, DELETE_MESSAGE_SUCCESS,
    UPDATE_UI_SUCCESS, UPDATE_UI_FAILED,SEND_UI_SUCCESS, SEND_UI_FAILED, DELETE_UI_FAILED,
    UPDATE_MESSAGE_PENDING, UPDATE_MESSAGE_SUCCESS, UPDATE_MESSAGE_FAILED,
    UIS_SUCCESS, UIS_FAILED
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';

const INITIAL_SEND_MESSAGE_FLAG_STATE = {
    send_message_pending: false,
    send_message_failed: false
};

const INITIAL_SEND_UI_FLAG_STATE = {
    send_ui_pending: false,
    send_ui_failed: false
};

const INITIAL_UPDATE_UI_FLAG_STATE = {
    update_ui_pending: false,
    update_ui_failed: false,
    ui_updated: false
};

const INITIAL_UPDATE_MESSAGE_FLAG_STATE = {
    update_message_pending: false,
    update_message_failed: false,
    message_updated: false
};

const INITIAL_MESSAGE_DELETE_FLAG_STATE = {
    delete_message_pending: false,
    delete_message_failed: false
};

const INITIAL_UI_DELETE_FLAG_STATE = {
    delete_ui_pending: false,
    delete_ui_failed: false
};

const INITIAL_UIS_FLAG_STATE = {
    uis_pending: false,
    uis_failed: false
};

const INITIAL_MESSAGES_FLAG_STATE = {
    messages_pending: false,
    messages_failed: false
};

const INITIAL_STATE = {
    ...{
        uis: [], messages: []
    },
    ...INITIAL_UIS_FLAG_STATE,
    ...INITIAL_SEND_MESSAGE_FLAG_STATE,
    ...INITIAL_MESSAGE_DELETE_FLAG_STATE,
    ...INITIAL_UPDATE_UI_FLAG_STATE,
    ...INITIAL_SEND_UI_FLAG_STATE,
    ...INITIAL_UPDATE_MESSAGE_FLAG_STATE
};

function messagesReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case SEND_MESSAGE_FAILED:
            return {
                ...state,
                ...INITIAL_SEND_MESSAGE_FLAG_STATE,
                send_message_failed: true
            };

        case SEND_MESSAGE_SUCCESS:
            return state;

        case UPDATE_UI_SUCCESS:
            return {
                ...state,
                ...INITIAL_UPDATE_UI_FLAG_STATE,
                ui_updated: true
            };

        case UPDATE_UI_FAILED:
            return {
                ...state,
                ...INITIAL_UPDATE_UI_FLAG_STATE,
                update_ui_failed: true
            };

        case SEND_UI_SUCCESS:
            return state;

        case SEND_UI_FAILED:
            return {
                ...state,
                ...INITIAL_SEND_UI_FLAG_STATE,
                send_ui_failed: true
            };

        case MESSAGES_FAILED:
            return {
                ...state,
                ...INITIAL_MESSAGES_FLAG_STATE,
                messages_pending: true
            };

        case MESSAGES:
            let messages = action.payload;
            if(messages === undefined || messages === null){
                messages = [];
            }
            return {
                ...state,
                ...INITIAL_MESSAGES_FLAG_STATE,
                messages: messages.filter(x => !x.meta.hasOwnProperty("v2"))
            };

        case UIS_FAILED:
            return {
                ...state,
                ...INITIAL_UIS_FLAG_STATE,
                uis_pending: true
            };

        case UIS_SUCCESS:
            let uis = action.payload;
            return {
                ...state,
                ...INITIAL_MESSAGES_FLAG_STATE,
                uis: Array.isArray(uis) ? uis : []
            };

        case DELETE_MESSAGE_SUCCESS:
            return state;

        case DELETE_MESSAGE_FAILED:
            return {
                ...state,
                ...INITIAL_MESSAGE_DELETE_FLAG_STATE,
                delete_message_failed: true
            };

        case UPDATE_MESSAGE_PENDING:
            return {
                ...state,
                ...INITIAL_UPDATE_MESSAGE_FLAG_STATE,
                update_message_pending: true
            };

        case UPDATE_MESSAGE_FAILED:
            return {
                ...state,
                ...INITIAL_UPDATE_MESSAGE_FLAG_STATE,
                update_message_failed: true
            };

        case UPDATE_MESSAGE_SUCCESS:
            return {
                ...state,
                ...INITIAL_UPDATE_MESSAGE_FLAG_STATE,
                message_updated: true
            };

        case DELETE_UI_FAILED:
            return {
                ...state,
                ...INITIAL_UI_DELETE_FLAG_STATE,
                delete_ui_failed: true
            };

        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default messagesReducer;