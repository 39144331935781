/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import { withStyles }  from '@material-ui/core/styles';

const styles = theme => ({
    card: {
        width: '100%',
        marginBottom: 12
    },
    root: {
        width: '100%',
        padding: 8,
        '&:last-child': {
            paddingBottom: 8
        }
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: red[500],
    },
    flexGrow: {
        flex: '1 1 auto',
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    container: {
        overflow: 'initial !important'
    },
    actions: {
        height: 'auto'
    },
    cursor: {
        cursor: 'pointer'
    }
});

class Box extends React.Component {

    constructor(props){
        super(props);
        const { defaultExpanded = false } = props;
        this.state = {
            expanded: defaultExpanded
        };
    }

    componentWillReceiveProps(nextProps){
        const { defaultExpanded = false, collapseNow = undefined } = nextProps;
        if(!this.state.expanded && this.props.defaultExpanded !== defaultExpanded){
            this.setState({expanded: defaultExpanded});
        }
        if(collapseNow && collapseNow !== this.props.expandNow){
            this.setState({expanded: false});
        }
    }

    handleExpandClick = () => {
        const { onCollapse = () => null } = this.props;
        this.setState({ expanded: !this.state.expanded }, () => {
            onCollapse(this.state.expanded);
        });
    };

    render() {
        const {
            classes, children, controls,
            title, collapsible = false,
            icon = null, footer,
            headerWithBorder = false, withPadding = false,
            unmountOnExit = true,
            raised = false, elevation = 1
        } = this.props;
        const { expanded } = this.state;
        const rootStyles = () => {
            if(withPadding){
                if(collapsible && !expanded){
                    return {};
                }else{
                    return {padding: 20};
                }
            }
            return {};
        };
        return (
            <Card className={classes.card} style={rootStyles()} raised={raised} elevation={elevation}>
                <CardActions
                    disableActionSpacing
                    classes={{
                        root: typeof title !== 'string' && classes.actions,
                    }}
                    style={collapsible ? {cursor: 'pointer'} : {}}
                    onClick={this.handleExpandClick}
                >
                    { icon && React.cloneElement(icon, {color: 'primary'}) }
                    {
                        typeof title === 'string' && <Typography variant="subheading" noWrap style={{marginLeft: 5}}>{ title }</Typography>
                    }
                    {
                        typeof title !== 'string' && title
                    }
                    <div className={classes.flexGrow} />
                    { controls }
                    {
                        collapsible && <IconButton color="primary"
                                                   className={classnames(classes.expand, {
                                                       [classes.expandOpen]: expanded,
                                                   })}
                                                   onClick={this.handleExpandClick}
                                                   aria-expanded={expanded}
                                                   aria-label="Expand">
                                 <DownIcon/>
                        </IconButton>
                    }
                </CardActions>
                { headerWithBorder && <Divider /> }
                {
                    collapsible && <Collapse in={this.state.expanded} className={classes.container} unmountOnExit={unmountOnExit}>
                        <CardContent className={classes.root}>
                            {children}
                        </CardContent>
                    </Collapse>
                }
                {
                    !collapsible && <CardContent className={classes.root}>
                        {children}
                    </CardContent>
                }
                {
                    footer && <Divider/>
                }
                {
                    footer && <CardContent className={classes.root}>
                        {footer}
                    </CardContent>
                }
            </Card>
        );
    }
}

Box.propTypes = {
    classes: PropTypes.object,
    title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
    ]),
    collapsible: PropTypes.bool,
    controls: PropTypes.node,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.string
    ]),
    icon: PropTypes.node,
    footer: PropTypes.node,
    headerWithBorder: PropTypes.bool,
    withPadding: PropTypes.bool,
    defaultExpanded: PropTypes.bool,
    collapseNow: PropTypes.bool, //FIXME: Not sure with this implementation, giving handle to parent NOT A GOOD IDEA
    onCollapse: PropTypes.func
};

export default withStyles(styles)(Box);