/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    USER_PROFILES_PENDING, USER_PROFILES, USER_PROFILES_FAILED,
    USER_SEARCH_ATTRIBUTES_PENDING, USER_SEARCH_ATTRIBUTES, USER_SEARCH_ATTRIBUTES_FAILED,
    UPDATE_USER_EXPLORER_GROUP, UPDATE_USER_EXPLORER_SEGMENT, UPDATE_USER_EXPLORER_FILTERS,
    UPDATE_USER_EXPLORER_SEARCH_FIELD, UPDATE_USER_EXPLORER_QUERY_TERM,
    USER_SEARCH_ATTRIBUTE_VALUES_PENDING, USER_SEARCH_ATTRIBUTE_VALUES, USER_SEARCH_ATTRIBUTE_VALUES_FAILED
} from './actionTypes';
import {searchUserProfiles, userProfileSearchAttributes} from "./api";
import {CUSTOM_EVENT_ENUM} from "../../Segments/NewSegment/actionTypes";
import {getAttributeValuesAPI} from "../../Segments/NewSegment/api";

/**
 *
 * @param appId
 * @param limit
 * @returns {function(*, *)}
 */
export const getUserProfiles = (appId, limit = 10) => {
    return (dispatch, getState) => {
        const { group, segmentId, field, q , user, session } = getState().userExplorer;
        let term = q && q.length > 0 ? q : null; //checking for search term
        let segment = segmentId && segmentId.length > 0 ? segmentId : null;
        const filters = {...getState().filters, group, segmentId: segment, field, q: term, limit };
        const searchFilters = { user, session };
        return dispatch({
            types: [
                USER_PROFILES_PENDING, USER_PROFILES, USER_PROFILES_FAILED
            ],
            payload: {
                promise: searchUserProfiles(getState().auth, appId, filters, searchFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getUserProfileSearchAttributes = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                USER_SEARCH_ATTRIBUTES_PENDING, USER_SEARCH_ATTRIBUTES, USER_SEARCH_ATTRIBUTES_FAILED
            ],
            payload: {
                promise: userProfileSearchAttributes(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getUserProfileSearchFieldValues = (appId, field, q) => {
    return (dispatch, getState) => {
        const of = getState().segmentBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of, q: q};
        return dispatch({
            types: [
                USER_SEARCH_ATTRIBUTE_VALUES_PENDING, USER_SEARCH_ATTRIBUTE_VALUES, USER_SEARCH_ATTRIBUTE_VALUES_FAILED
            ],
            payload: {
                promise: getAttributeValuesAPI(getState().auth, appId, field, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                field
            }
        });
    };
};

export const updateGroup = (group) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_USER_EXPLORER_GROUP,
            payload: group,
            meta: {
                //If Any
            }
        });
    };
};

export const updateSegment = (segmentId) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_USER_EXPLORER_SEGMENT,
            payload: segmentId,
            meta: {
                //If Any
            }
        });
    };
};

export const updateSearchField = (field) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_USER_EXPLORER_SEARCH_FIELD,
            payload: field,
            meta: {
                //If Any
            }
        });
    };
};

export const updateSearchQueryTerm = (term) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_USER_EXPLORER_QUERY_TERM,
            payload: term,
            meta: {
                //If Any
            }
        });
    };
};

export const updateFilters = (filters) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_USER_EXPLORER_FILTERS,
            payload: filters,
            meta: {
                //If Any
            }
        });
    };
};