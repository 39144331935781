/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {getCohorts} from "../Cohorts/actions";
import {
    RESET_SEGMENT,
    SEGMENT_PENDING,
    SEGMENT_FAILED,
    CORRELATIONS_TIMESERIES,
    CORRELATIONS_TIMESERIES_FAILED,
    CORRELATIONS_TIMESERIES_PENDING,
    CORRELATION_SEGMENTLIST_FAILED,
    CORRELATION_SEGMENTLIST,
    CORRELATION_SEGMENTLIST_PENDING,
    UPDATE_SEGMENTS,
    SEGMENT_COUNT_PENDING,
    SEGMENT_COUNT,
    SEGMENT_COUNT_FAILED,
    DELETE_SEGMENT_PENDING,
    DELETE_SEGMENT,
    DELETE_SEGMENT_FAILED,
    SEGMENT_USER_ATTRIBUTE_DISTRIBUTION,
    SEGMENT_SESSION_ATTRIBUTE_DISTRIBUTION,
    SEGMENT_CURRENT_EVENT_ATTRIBUTE_DISTRIBUTION,
    UPDATE_SEGMENT_ATTRIBUTE_FILTERS,
    UPDATE_SEGMENT_USER_GROUP,
    UPDATE_SEGMENT_GROUP_BY_ATTRIBUTE,
    SEGMENT_GROUP_BY_ATTRIBUTE,
    SEGMENT_CURRENT_EVENT_ATTRIBUTES,
    UPDATE_SEGMENT_CURRENT_EVENT,
    SEGMENT_RETENTION,
    SEGMENT_RETENTION_FAILED,
    SEGMENT_RETENTION_PENDING,
    COHORT_UPLOAD_PENDING,
    COHORT_UPLOAD_DONE,
    COHORT_UPLOAD_FAILED,
    CLOSE_DIALOG, OPEN_DIALOG, ERROR_MESSAGE
} from './actionTypes';
import {
    getSegmentTimeSeriesAPI,
    getSegmentRetentionAPI,
    getSegmentListAPI, getSegmentCountAPI, deleteSegmentAPI,
    segmentAttributeDistributionAPI, uploadCohortAPI
} from './api';
import { getGroupNameFromList } from '../../../../../../utils';
import {getEventAttributesAPI} from "./NewSegment/api";

/**
 *
 * @param appId
 * @param segmentId
 * @param segmentName
 * @returns {function(*, *)}
 */
export const getCorrelationsTimeSeriesData = (appId, segmentId, segmentName) => {
    return (dispatch, getState) => {
        const attributeFilters = { ...getState().segment.filters };
        const { group_by, group } = getState().segment;
        const filters = { ...getState().filters, group };
        if(group_by){
            dispatch(getSegmentGroupByAttributeDistribution(appId, segmentId, group_by));
        }
        return dispatch({
            types: [
                CORRELATIONS_TIMESERIES_PENDING,
                CORRELATIONS_TIMESERIES,
                CORRELATIONS_TIMESERIES_FAILED
            ],
            payload: {
                promise: getSegmentTimeSeriesAPI(appId, getState().auth, segmentId, filters, attributeFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                segmentId: segmentId,
                segmentName: segmentName
            }
        });
    };
};

export const getSegmentCount = (appId, segmentId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, group: getState().segment.group };
        const attributeFilters = { ...getState().segment.filters};
        return dispatch({
            types: [
                SEGMENT_COUNT_PENDING,
                SEGMENT_COUNT,
                SEGMENT_COUNT_FAILED
            ],
            payload: {
                promise: getSegmentCountAPI(appId, getState().auth, segmentId, filters, attributeFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getSegmentRetention = (appId, segmentId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, group: getState().segment.group };
        const attributeFilters = { ...getState().segment.filters};
        return dispatch({
            types: [
                SEGMENT_RETENTION_PENDING,
                SEGMENT_RETENTION,
                SEGMENT_RETENTION_FAILED
            ],
            payload: {
                promise: getSegmentRetentionAPI(appId, getState().auth, segmentId, filters, attributeFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getSegmentList = (appId, segmentId = null) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                CORRELATION_SEGMENTLIST_PENDING,
                CORRELATION_SEGMENTLIST,
                CORRELATION_SEGMENTLIST_FAILED
            ],
            payload: {
                promise: getSegmentListAPI(appId, getState().auth, getState().filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(segmentId) {
                        dispatch(getCorrelationsTimeSeriesData(appId, segmentId, getGroupNameFromList(data, segmentId)));
                    }
                }
            }
        });
    };
};

/**
 *
 * @param appId
 * @param segmentId
 * @returns {function(*, *)}
 */
export const deleteSegment = (appId, segmentId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                DELETE_SEGMENT_PENDING,
                DELETE_SEGMENT,
                DELETE_SEGMENT_FAILED
            ],
            payload: {
                promise: deleteSegmentAPI(appId, getState().auth, getState().filters, segmentId)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successWillDispatch: (dispatch, data) => {
                    dispatch(getSegmentList(appId));
                }
            }
        });
    };
};

/**
 *
 * @param appId
 * @param segmentList
 * @param forceAll
 * @returns {function(*, *)}
 */
export const updateSegments = (appId, segmentList, forceAll = false) => {
    return (dispatch, getState) => {
        const existingSegments = Object.keys(getState().segment.selected_segments) || [];
        const newSegments = [];
        segmentList.forEach(segmentId => {
            if (!existingSegments.includes(segmentId)) {
                newSegments.push(segmentId);
            }
        });
        /*if(forceAll){
            existingSegments.forEach(segmentId => {
                const segmentName = getGroupNameFromList(getState().segment.segment_list, segmentId);
                if(segmentName) {
                    dispatch(getCorrelationsTimeSeriesData(appId, segmentId, segmentName));
                }
            });
            segmentList = existingSegments;
        }else{
            segmentList.forEach(segmentId => {
                if(existingSegments.indexOf(segmentId) < 0){
                    // const segmentName = getGroupNameFromList(getState().segment.segment_list, segmentId);
                    if(segmentName) {
                        dispatch(getCorrelationsTimeSeriesData(appId, segmentId, segmentName));
                    }
                }
            });
        }*/
        if (forceAll) {
            if (Array.isArray(segmentList) && segmentList.length > 0) {
                const segmentSet = new Set([...segmentList, ...existingSegments]); //to remove duplicates
                segmentList = [...segmentSet];
            } else {
                segmentList = existingSegments;
            }
        }
        newSegments.forEach(segmentId => { //FIXME: calling all segments every time for sanity in data and selected segments
            const segmentName = getGroupNameFromList(getState().segment.segment_list, segmentId);
            if(segmentName) {
                dispatch(getCorrelationsTimeSeriesData(appId, segmentId, segmentName));
            }
        });

        return dispatch({
            type: UPDATE_SEGMENTS,
            payload: segmentList,
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param filters
 * @returns {function(*, *)}
 */
export const updateSegmentAttributeFilters = (filters) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_SEGMENT_ATTRIBUTE_FILTERS,
            payload: filters,
            meta: {
                //If Any
            }
        });
    };
};

export const updateSegmentUserGroup = (group) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_SEGMENT_USER_GROUP,
            payload: group,
            meta: {
                //If Any
            }
        });
    };
};

export const updateSegmentGroupByAttribute = (appId, attribute) => {
    return (dispatch, getState) => {
        const { selected_segments = {} } = getState().segment;
        Object.keys(selected_segments).forEach(segmentId => {
            dispatch(getSegmentGroupByAttributeDistribution(appId, segmentId, attribute));
        });
        return dispatch({
            type: UPDATE_SEGMENT_GROUP_BY_ATTRIBUTE,
            payload: attribute,
            meta: {
                //If Any
            }
        });
    };
};

export const updateSegmentCurrentEvent = (appId, event) => {
    return (dispatch, getState) => {
        dispatch(getSegmentCurrentEventAttributes(appId, event));
        return dispatch({
            type: UPDATE_SEGMENT_CURRENT_EVENT,
            payload: event,
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @returns {function(*, *)}
 */
export const resetCorrelations = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_SEGMENT,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param segmentId
 * @param segmentName
 * @param isThroughFilters
 * @returns {function(*, *)}
 */
export function loadSegmentData(appId, segmentId, segmentName = null, isThroughFilters = false){
    return (dispatch, getState) => {
        if(isThroughFilters){
            dispatch(getSegmentList(appId, segmentId));
        }
        if(!getState().segment.group_by){ //loading group by for default attribute
            const { user = [], session = [] } = getState().app.attributes;
            const attributes = [...user, ...session].filter(item => item !== "custom_user_id");
            if(attributes.length > 0){
                dispatch(updateSegmentGroupByAttribute(appId, attributes[0]));
            }
        }
        dispatch(getSegmentCount(appId, segmentId));
        dispatch(getSegmentRetention(appId, segmentId));
        dispatch(updateSegments(appId, [segmentId], isThroughFilters));
        if(isThroughFilters) {
            const {
                user_attribute_distributions = {},
                session_attribute_distributions = {},
                event_attribute_distributions = {}
            } = getState().segment || {};
            Object.keys(user_attribute_distributions).forEach(attribute => {
                dispatch(getSegmentUserAttributeDistribution(appId, segmentId, attribute));
            });
            Object.keys(session_attribute_distributions).forEach(attribute => {
                dispatch(getSegmentSessionAttributeDistribution(appId, segmentId, attribute));
            });
            Object.keys(event_attribute_distributions).forEach(attribute => {
                dispatch(getSegmentEventAttributeDistribution(appId, segmentId, attribute));
            });
        }
    };
}

/**
 *
 * @param appId
 * @param segmentId
 * @param attribute
 * @returns {function(*, *)}
 */
export const getSegmentUserAttributeDistribution = (appId, segmentId, attribute) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, group: getState().segment.group, attribute };
        const attributeFilters = getState().segment.filters;
        return dispatch({
            types: [
                SEGMENT_PENDING,
                SEGMENT_USER_ATTRIBUTE_DISTRIBUTION,
                SEGMENT_FAILED
            ],
            payload: {
                promise: segmentAttributeDistributionAPI(appId, getState().auth, segmentId, filters, attributeFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute
            }
        });
    };
};

export const getSegmentSessionAttributeDistribution = (appId, segmentId, attribute) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, group: getState().segment.group, attribute };
        const attributeFilters = getState().segment.filters;
        return dispatch({
            types: [
                SEGMENT_PENDING,
                SEGMENT_SESSION_ATTRIBUTE_DISTRIBUTION,
                SEGMENT_FAILED
            ],
            payload: {
                promise: segmentAttributeDistributionAPI(appId, getState().auth, segmentId, filters, attributeFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute
            }
        });
    };
};

export const getSegmentEventAttributeDistribution = (appId, segmentId, attribute) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, group: getState().segment.group, attribute };
        const attributeFilters = getState().segment.filters;
        return dispatch({
            types: [
                SEGMENT_PENDING,
                SEGMENT_CURRENT_EVENT_ATTRIBUTE_DISTRIBUTION,
                SEGMENT_FAILED
            ],
            payload: {
                promise: segmentAttributeDistributionAPI(appId, getState().auth, segmentId, filters, attributeFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute
            }
        });
    };
};

export const getSegmentGroupByAttributeDistribution = (appId, segmentId, attribute) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, group: getState().segment.group, attribute };
        const attributeFilters = getState().segment.filters;
        return dispatch({
            types: [
                SEGMENT_PENDING,
                SEGMENT_GROUP_BY_ATTRIBUTE,
                SEGMENT_FAILED
            ],
            payload: {
                promise: segmentAttributeDistributionAPI(appId, getState().auth, segmentId, filters, attributeFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                segmentId
            }
        });
    };
};

export const getSegmentCurrentEventAttributes = (appId, event) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters, event };
        return dispatch({
            types: [
                SEGMENT_PENDING,
                SEGMENT_CURRENT_EVENT_ATTRIBUTES,
                SEGMENT_FAILED
            ],
            payload: {
                promise: getEventAttributesAPI(getState().auth, appId , filters) //FIXME: need new API which global for all
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

export const cohortUploadErrorMessage = (message) => {
    return (dispatch, getState) => {
        return dispatch({
            type: ERROR_MESSAGE,
            payload: {
                message
            },
            meta: {}
        })
    }
};

export const closeDialog = (name) => {
    return (dispatch, getState) => {
        return dispatch({
            type: CLOSE_DIALOG,
            payload: {},
            meta: {name}
        })
    }
};

export const openDialog = (name) => {
    return (dispatch, getState) => {
        return dispatch({
            type: OPEN_DIALOG,
            payload: {},
            meta: {name}
        })
    }
};

export const uploadCohort = (appId, cohortName, cohortDescription, cohortFile) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                COHORT_UPLOAD_PENDING,
                COHORT_UPLOAD_DONE,
                COHORT_UPLOAD_FAILED,
            ],
            payload: {
                promise: uploadCohortAPI(appId, getState().auth, {
                    cohortName,
                    cohortDescription,
                    cohortFile
                }).then(res => {
                    if (res.hasOwnProperty("status")) {
                        if (res.status) {
                            dispatch(getCohorts(appId, getState().filters));
                            dispatch(closeDialog("UPLOAD_COHORT"));
                        }
                    }
                    return res;
                }).catch((err) => {
                    dispatch(cohortUploadErrorMessage("Cohort Upload failed. Please try again after sometime."));
                    setTimeout(() => {
                        dispatch(cohortUploadErrorMessage(null));
                    }, 1500);
                    return err;
                })
            },
            meta: {}
        })
    }
};
