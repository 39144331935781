/**
 * Created by Rakesh Peela
 * Date: 06-Dec-2019
 * Time: 5:14 PM
 */

import React from "react";
import {Typography} from "@material-ui/core";
import PropTypes from 'prop-types';

class ModuleConfigSection extends React.Component {
    render() {
        const {children, title, style, typographyStyleProps} = this.props;
        return (
            <div style={{...style}}>
                <Typography style={{fontWeight: 400, ...typographyStyleProps}} variant={"subtitle1"} gutterBottom>
                    {title}
                </Typography>
                {children}
            </div>
        );
    }
}

ModuleConfigSection.propTypes = {
    title: PropTypes.string.isRequired,
    style: PropTypes.object,
    typographyProps: PropTypes.object,
};

export default ModuleConfigSection;