/**
 * Created by jyothi on 14/1/17.
 */
import { callApi } from '../../../../../../api';
import { makeDefaultQueryString, withQueryStrings} from '../../../../../../utils';

const MESSAGES_API = "messages";

const MESSAGE_UI_API = "messages/ui";

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getMessagesAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(MESSAGES_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth: auth,
        //body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json ? json.messages : [];
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param message
 * @returns {Promise}
 */
export function sendMessageAPI(auth, appId, filters, message) {
    const url = makeDefaultQueryString(MESSAGES_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(message)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param messageId
 * @param message
 * @returns {Promise}
 */
export function updateMessageAPI(auth, appId, filters, messageId, message) {
    const url = makeDefaultQueryString(MESSAGES_API + '/' + messageId, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "PUT",
        auth: auth,
        body: JSON.stringify(message)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param messageId
 * @param message
 * @returns {Promise}
 */
export function deleteMessageAPI(auth, appId, filters, messageId, message) {
    const url = makeDefaultQueryString(MESSAGES_API + '/' + messageId, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "DELETE",
        auth: auth,
        //body: JSON.stringify(message)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param type
 * @returns {Promise}
 */
export function getUIsAPI(auth, appId, filters, type = undefined) {
    const url = makeDefaultQueryString(MESSAGE_UI_API, auth, appId) + withQueryStrings({...filters, type});
    const config = {
        method: "GET",
        auth: auth,
        //body: JSON.stringify(message)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param uiConfig
 * @returns {Promise}
 */
export function sendUIAPI(auth, appId, filters, uiConfig) {
    const url = makeDefaultQueryString(MESSAGE_UI_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(uiConfig)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param uiId
 * @param uiConfig
 * @returns {Promise}
 */
export function updateUIAPI(auth, appId, filters, uiId, uiConfig) {
    const url = makeDefaultQueryString(MESSAGE_UI_API + '/' + uiId, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "PUT",
        auth: auth,
        body: JSON.stringify(uiConfig)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param uiId
 * @returns {Promise}
 */
export function deleteUIAPI(auth, appId, filters, uiId) {
    const url = makeDefaultQueryString(MESSAGE_UI_API + '/' + uiId, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "DELETE",
        auth: auth,
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

