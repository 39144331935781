import React, { Component, Fragment, PureComponent } from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withTheme } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Switch from "../../../../../../../../components/reusable/Switch";
import ColorPicker from "../../../../../../../../components/reusable/ColorPicker";
import FormSelect from "../../../../../../../../components/reusable/MaterialUi/FormSelect";
import {
    IMG_DIR, BG_IMAGE, USER_IMAGE, SCHEME, CLOSE_HREF,
    BUTTON_ACTION_ENUM, BUTTONS, DEFAULT_BUTTON_CONFIG,
    TEMPLATES, DEEP_LINK_ENUM,
    GREEN, SMOKE, WHITE, DEFAULT_FONT, DEFAULT_GRADIENT, DARK, BACKGROUND_SIZE, BACKGROUND_SIZE_ENUM
} from './constants';
import {MESSAGE_ENUM, MESSAGE_POSITION_ENUM} from "../../actionTypes";
import logger from "../../../../../../../../utils/Logger";
import ExpansionPanel from "@material-ui/core/ExpansionPanel/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails/ExpansionPanelDetails";
import Divider from "@material-ui/core/Divider/Divider";
import ToggleSwitch from "../../../../../../../../components/reusable/MaterialUi/ToggleSwitch";
import HTMLEditor from "../../../../../../../../components/plugins/HTMLEditor";
import InAppHTMLMobileSimulator from "../InAppHTMLMobileSimulator";
import ExpansionPanelActions from "@material-ui/core/ExpansionPanelActions/ExpansionPanelActions";
import Button from "@material-ui/core/Button/Button";
import {templateGenerator} from "./InAppTemplates";
import MultiSelect from "../../../../../../../../components/reusable/MaterialUi/MultiSelect";
import Dialog from "../../../../../../../../components/reusable/MaterialUi/Dialog";
import RichTextField from  "../../../../../../../../components/reusable/RichTextField.js"

const { REDIRECT, CLOSE } = BUTTON_ACTION_ENUM;

const { Single, Double } = BUTTONS;

const DEFAULT_STATE = {
    font_family: DEFAULT_FONT,
    background_image: "", //BG_IMAGE
    background_size: BACKGROUND_SIZE_ENUM.COVER,
    background: WHITE,
    gradient: DEFAULT_GRADIENT,
    title: "This is Title",
    title_color: SMOKE,
    description: "This is description, and can be long",
    description_color: SMOKE,
    //icon: "", //USER_IMAGE,
    button_left: DEFAULT_BUTTON_CONFIG,
    button_right: DEFAULT_BUTTON_CONFIG,
    buttons: Single
};

const { COVER, INTERSTITIAL, STICKY_BOTTOM, STICKY_TOP, MODAL } = TEMPLATES;

const { URI, ACTIVITY } = DEEP_LINK_ENUM;

const TemplateItem = ({ img, title, selected, handleClick }) => (
    <Grid item xs style={{padding: 8}} onClick={handleClick}>
        <div style={{
            border: '1px solid #C8CBFB', cursor: 'pointer',
            padding: '16px 0', textAlign: 'center', width: '100%',
            background: selected ? '#FFFFFF' : 'transparent',
            boxShadow: selected ? '0 2px 24px 0 rgba(0, 0, 0, 0.31)' : undefined
        }}>
            <img src={`${IMG_DIR}${img}`} />
            <Typography variant="caption">{title}</Typography>
        </div>
    </Grid>
);

class TemplateSelector extends Component {

    render(){
        const { handleUpdate, template } = this.props;
        return(
            <Grid container style={{background: '#F2F2F3', padding: 16}}>
                <TemplateItem
                    handleClick={handleUpdate(COVER)} img="cover.png"
                    title="Cover" selected={template === COVER}/>
                <TemplateItem
                    handleClick={handleUpdate(INTERSTITIAL)} img="interstitial.png"
                    title="Interstitial" selected={template === INTERSTITIAL}/>
                <TemplateItem
                    handleClick={handleUpdate(MODAL)} img="modal.png"
                    title="Modal" selected={template === MODAL}/>
                <TemplateItem
                    handleClick={handleUpdate(STICKY_TOP)} img="sticky_top.png"
                    title="Sticky Top" selected={template === STICKY_TOP} />
                <TemplateItem
                    handleClick={handleUpdate(STICKY_BOTTOM)} img="sticky_bottom.png"
                    title="Sticky Bottom" selected={template === STICKY_BOTTOM} />
            </Grid>
        )
    }
}

class ButtonForm extends PureComponent {

    state = {
        ...DEFAULT_BUTTON_CONFIG,
        redirect_type: URI
    };

    handleUpdate = () => {
        const { handleUpdate } = this.props;
        setTimeout(() => {
            handleUpdate(this.state);
        }, 100);
    };

    componentWillMount(){
        this.propToState();
    }

    propToState = (props = this.props) => {
        const { config = {} } = this.props;
        this.setState(config);
    };

    componentWillReceiveProps(nextProps){
        const { config = {} } = nextProps;
        const hasChange = Object.keys(config).some(key => { //shallow comparing
            return config[key] !== this.state[key];
        });
        if(hasChange){
            this.propToState(nextProps);
        }
    }

    render(){
        const { label } = this.props;
        const { activities = [] } = this.props;
        const { action, color, background, text, href, styles, redirect_type } = this.state;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12} md={4}>
                    <TextField
                        value={text}
                        onChange={e => this.setState({text: e.target.value}, this.handleUpdate)}
                        margin="normal"
                        label="Button Label"
                        helperText="Max limit 12 characters"
                        placeholder="Enter Text"
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ColorPicker
                        handleChange={color => this.setState({color}, this.handleUpdate)}
                        label="Text Color"
                        color={color}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ColorPicker
                        handleChange={background => this.setState({background}, this.handleUpdate)}
                        label="Background Color"
                        color={background}
                        margin="normal"
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <TextField
                        value={styles}
                        onChange={e => this.setState({styles: e.target.value}, this.handleUpdate)}
                        margin="normal"
                        label="Button Styles"
                        helperText="CSS styles"
                        placeholder="margin: 8px;padding.."
                        fullWidth
                        multiline
                    />
                </Grid>
                <Grid item xs={12} md={2}>
                    <FormSelect
                        options={Object.keys(BUTTON_ACTION_ENUM)}
                        value={action}
                        handleUpdate={action => {
                            let hREF = href;
                            if(action === BUTTON_ACTION_ENUM.REDIRECT){
                                hREF = "";
                            }
                            this.setState({action, href: hREF}, this.handleUpdate)
                        }}
                        label="Button Action"
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                {
                    action === REDIRECT && <Fragment>
                        <Grid item xs={12} md={2}>
                            <FormSelect
                                options={Object.keys(DEEP_LINK_ENUM)}
                                value={redirect_type}
                                handleUpdate={redirect_type => {
                                    this.setState({redirect_type, href: ""}, this.handleUpdate)
                                }}
                                label="Deep Link Type"
                                fullWidth
                                margin="normal"
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {
                                redirect_type !== URI && <MultiSelect
                                    options={activities}
                                    value={href}
                                    handleChange={href => this.setState({href}, this.handleUpdate) }
                                    fullWidth
                                    single
                                    margin="normal"
                                    placeholder="com.company.app.Activity"
                                    label="Deep link Activity"
                                />
                            }
                            {
                                redirect_type === URI && <TextField
                                    value={href}
                                    onChange={e => this.setState({href: e.target.value}, this.handleUpdate)}
                                    margin="normal"
                                    label="Deep link URL"
                                    placeholder="https://m.example.com"
                                    fullWidth
                                />
                            }
                        </Grid>
                    </Fragment>
                }
            </Grid>
        )
    }

}

ButtonForm.propTypes = {
    handleUpdate: PropTypes.func.isRequired
};

const has = (property) => typeof property !== "undefined";

class HTMLInAppUIForm extends React.Component {

    state = {
        ...DEFAULT_STATE,
        template: COVER
    };

    componentWillMount(){
        this.propsToState();
    }

    componentWillReceiveProps(nextProps){
        this.propsToState(nextProps);
    }

    propsToState = (props = this.props) => {
        const {
            font_family, background_image, background,
            title, title_color,
            description, description_color,
            icon, with_icon, gradient,
            button_left, button_right, buttons,
            template
        } = props;
        this.setState({
            font_family, background_image, background,
            title, title_color,
            description, description_color,
            icon, gradient, with_icon,
            button_left, button_right, buttons,
            template
        });
    };

    handleUpdate = () => {
        const { handleUpdate = () => null } = this.props;
        handleUpdate(this.state); //config update to parent
    };

    handleTemplateSelection = (template) => () => {
        const {
            font_family = DEFAULT_FONT, background_image = '', background = '',
            gradient = DEFAULT_GRADIENT,
            title = DEFAULT_STATE.title, title_color,
            description = '', description_color,
            icon = '',
            button_left = DEFAULT_BUTTON_CONFIG,
            button_right = DEFAULT_BUTTON_CONFIG,
            buttons, with_icon = true
        } = this.state;
        const commonConf = {
            font_family, gradient: DEFAULT_GRADIENT, background_image, background,
            title, title_color: SMOKE,
            description, description_color: SMOKE,
            button_left, button_right: DEFAULT_BUTTON_CONFIG,
            buttons: Single
        };
        const newState = (template) => {
            switch (template) {
                case MODAL:
                    return {
                        ...commonConf,
                        icon: '',
                        buttons: undefined,
                        title: undefined,
                        title_color: undefined,
                        button_right: undefined,
                        description_color: DARK,
                        with_icon: true
                    };
                case INTERSTITIAL:
                    return {
                        ...commonConf,
                        with_icon: undefined,
                        icon: undefined,
                        button_right: undefined,
                        buttons: undefined
                    };
                case STICKY_TOP:
                case STICKY_BOTTOM:
                    return {
                        ...commonConf,
                        icon: '',
                        buttons: Double,
                        gradient: '',
                        title_color: DARK,
                        description_color: DARK,
                        with_icon: true
                    };
                case COVER:
                default:
                    return {
                        ...commonConf,
                        with_icon: undefined,
                        icon: undefined
                    };
            }
        };
        this.setState({template, ...newState(template)}, this.handleUpdate);
    };

    render(){
        const {
            font_family, background_image, background, background_size,
            title, description, with_icon, icon, gradient,
            button_left, button_right, buttons,
            template, title_color, description_color
        } = this.state;
        const { activities, attributeSuggestions } = this.props;
        return(
            <Paper style={{padding: 32, maxHeight: '90vh', overflowY: 'auto'}}>
                <Typography variant="subtitle1">Select Template</Typography>
                <TemplateSelector
                    handleUpdate={this.handleTemplateSelection}
                    template={template}
                />
                <TextField
                    value={font_family}
                    onChange={e => this.setState({font_family: e.target.value}, this.handleUpdate)}
                    margin="normal"
                    label="Font Family"
                    placeholder="sans-serif"
                    fullWidth
                />
                {
                    has(title) && <Grid container spacing={16}>
                        <Grid item xs={12} md={8}>
                            <RichTextField
                                value={title}
                                suggestions={attributeSuggestions}
                                handleChange={title => this.setState({title}, this.handleUpdate)}
                                label="Title ('[' for macros)"
                                helperText="Max limit 25 characters"
                                placeholder="Diwali offer 25% discount"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ColorPicker
                                handleChange={title_color => this.setState({title_color}, this.handleUpdate)}
                                label="Title Color"
                                color={title_color}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                }
                {
                    has(description) && <Grid container spacing={16}>
                        <Grid item xs={12} md={8}>
                            <RichTextField
                                value={description}
                                label="Compose ('[' for macros)"
                                suggestions={attributeSuggestions}
                                handleChange={description => this.setState({description}, this.handleUpdate)}
                                helperText="Max limit 150 characters"
                                placeholder="Lorem ipsum.."
                                multiline
                                rowsMax={2}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <ColorPicker
                                handleChange={description_color => this.setState({description_color}, this.handleUpdate)}
                                label="Compose Color"
                                color={description_color}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                }
                {
                    template !== STICKY_TOP && template !== STICKY_BOTTOM && has(buttons) && <Fragment>
                        <Typography variant="subtitle1">Buttons</Typography>
                        <Switch
                            data={Object.keys(BUTTONS)}
                            value={buttons}
                            handleChange={buttons => this.setState({buttons}, this.handleUpdate)}
                        />
                    </Fragment>
                }
                {
                    has(button_left) && <Fragment>
                        <Typography style={{marginTop: 16}} variant="subtitle1">Button {buttons === Double && "Left"}</Typography>
                        <ButtonForm activities={activities} config={button_left} handleUpdate={(button_left) => this.setState({button_left}, this.handleUpdate)} />
                    </Fragment>
                }
                {has(buttons) && buttons === Double && <Divider style={{margin: '16px 0'}}/>}
                { has(buttons) && buttons === Double &&
                    <Fragment>
                        <Typography variant="subtitle1">Button Right</Typography>
                        <ButtonForm activities={activities} config={button_right} handleUpdate={(button_right) => this.setState({button_right}, this.handleUpdate)} />
                    </Fragment>
                }
                {
                    has(icon) && <Grid container spacing={16}>
                        {
                            has(with_icon) && <Grid item xs={12} md={3}>
                                <ToggleSwitch
                                    styles={{marginTop: '1.5em'}}
                                    handleChange={with_icon =>  {
                                        this.setState({with_icon}, this.handleUpdate);
                                    }}
                                    label="With Icon"
                                    checked={with_icon}
                                />
                            </Grid>
                        }
                        {
                            with_icon && <Grid item xs={12} md={9}>
                                <TextField
                                    value={icon}
                                    onChange={e => this.setState({icon: e.target.value}, this.handleUpdate)}
                                    margin="normal"
                                    label="Icon URL"
                                    helperText="URL or Drawable"
                                    placeholder="https://cdn.example.com/bg.png"
                                    fullWidth
                                />
                            </Grid>
                        }
                    </Grid>
                }
                <Grid container spacing={16}>
                    { has(background_image) && <Grid item xs={12} md={8}>
                        <TextField
                            value={background_image}
                            onChange={e => this.setState({background_image: e.target.value}, this.handleUpdate)}
                            margin="normal"
                            label="Background Image URL"
                            helperText=""
                            placeholder="https://cdn.example.com/bg.png"
                            fullWidth
                        />
                        <Switch
                            data={Object.keys(BACKGROUND_SIZE_ENUM)}
                            value={background_size}
                            handleChange={background_size => this.setState({background_size}, this.handleUpdate)}
                        />
                    </Grid>
                    }
                    {
                        has(background) && <Grid item xs={12} md={4}>
                            <ColorPicker
                                handleChange={background => this.setState({background}, this.handleUpdate)}
                                label={ `${has(background_image) ? "or use " : ""}Background Color`}
                                color={background}
                                margin="normal"
                                fullWidth
                            />
                        </Grid>
                    }
                </Grid>
                {
                    has(gradient) && <Grid container spacing={16}>
                        <Grid item xs={12}>
                            <TextField
                                value={gradient}
                                onChange={e => this.setState({gradient: e.target.value}, this.handleUpdate)}
                                margin="normal"
                                label="or use Overlay Gradient"
                                helperText=""
                                placeholder="linear-gradient(...)"
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                }
            </Paper>
        )
    }
}


export default class InAppHTMLUISelection extends Component{

    _INITIAL_CONFIG = {
        ...DEFAULT_STATE,
        template: COVER,
        is_custom: false,
        platform: this.props.appState.app.basic_info.platform
    };

    _INITIAL_STATE = {
        name: "",
        layout: "html_layout",
        delay: 4000,
        html_config: {
            width: 100,
            height: 100,
            data: templateGenerator(this._INITIAL_CONFIG), //default first template
            url: ''
        },
        window_attributes: {
            position: MESSAGE_POSITION_ENUM.CENTER,
            dimmed_background: false,
            dismiss_outside_touch: true,
            has_animation: true,
            animation_duration: 1000
        },
        config: this._INITIAL_CONFIG,
        is_custom_triggered: false
    };

    constructor(props){
        super(props);
        const { isNew, config: { html_config = {} } = {} } = props;
        this.state = !html_config.hasOwnProperty("data") ? this._INITIAL_STATE : this._propToState(props);
    }

    componentWillReceiveProps(nextProps){
        if(!nextProps.isNew){
            this.setState(this._propToState(nextProps));
        }
    }

    _propToState = (props) => {
        const { config = {} } = props;
        if(typeof config === 'object' && config.hasOwnProperty("name")) {
            return config;
        }else{
            return this._INITIAL_STATE;
        }
    };

    isStickyTemplate = (template) => template === STICKY_BOTTOM || template === STICKY_TOP;

    _stateToModel = (isForSave = false) => {
        if(!isForSave){
            return this.state;
        }
        const { is_custom_triggered, window_attributes, html_config, config, ...others } = this.state;
        return {
            ...others,
            window_attributes: {
                ...window_attributes,
                //Not necessary but for
                position: config.template === STICKY_BOTTOM ?
                    MESSAGE_POSITION_ENUM.BOTTOM :
                    (config.template === STICKY_TOP ? MESSAGE_POSITION_ENUM.TOP : MESSAGE_POSITION_ENUM.CENTER)
            },
            html_config: {
                ...html_config,
                height: this.isStickyTemplate(config.template) ? 40 : 100
            },
            config
        };
    };

    componentWillMount(){
        //this.setState({inEditMode: this.props.inEditMode || false});
    }

    handleUpdate = () => {
        //TODO: use this
        logger.info("InApp Config ", this.state);
        setTimeout(() => {
            const { handleUpdate } = this.props;
            if(typeof handleUpdate === 'function'){
                const { name } = this.state;
                handleUpdate({
                    name,
                    type: MESSAGE_ENUM.IN_APP,
                    config: this._stateToModel(true)
                });
            }
        }, 0);
    };

    handleSave = (e) => {
        e.preventDefault();
        const { handleSave } = this.props;
        const { name } = this.state;
        handleSave({
            name,
            type: MESSAGE_ENUM.IN_APP,
            config: this._stateToModel(true)
        });
        this.setState(this._INITIAL_STATE);
    };

    handleEdit = (e) => {
        e.preventDefault();
        const { handleEdit, uiId } = this.props;
        const { name } = this.state;
        handleEdit(uiId, {
            name,
            type: MESSAGE_ENUM.IN_APP,
            config: this._stateToModel(true)
        });
    };

    handleTemplateChange = (config) => {
        const { html_config } = this.state;
        this.setState({
            html_config: { ...html_config, data: templateGenerator(config) },
            config
        }, this.handleUpdate);
    };

    render(){
        const {
            isNew, inEditMode, showControls = true,
            appState: { appScreenNames },
            attributeSuggestions, noName = false
        } = this.props;
        const {appState: {app: {basic_info: {platform}}}} = this.props;
        const activities = appScreenNames.map(o => ({label: o, value: o}));
        const {
            name, layout, html_config: {width, height, data, url }, html_config,
            window_attributes: {
                position, dimmed_background, dismiss_outside_touch,
                has_animation, animation_duration
            }, window_attributes,
            config, config: { is_custom } = this._INITIAL_CONFIG,
            is_custom_triggered = false
        } = this.state;
        const disabled = !inEditMode && this.props.disabled;
        const withURL = url && url.length > 0;
        return(
            <Grid container spacing={16}>
                <Grid item xs>
                    <ExpansionPanel defaultExpanded>
                        <ExpansionPanelDetails style={{display: 'block'}}>
                            <Grid container spacing={16} justify="center">
                                <Grid item xs={12} md={4}>
                                    <div style={{margin: '32px 0'}}>
                                        <InAppHTMLMobileSimulator
                                            data={data}
                                            position={position}
                                            dimmed_background={dimmed_background}
                                        />
                                    </div>
                                </Grid>
                                {
                                    !disabled &&
                                    <Grid item xs={12} md={8}>
                                        <Grid container spacing={16} justify="space-between">
                                            <Grid item xs={12} md={4}>
                                                {!noName &&
                                                    <TextField
                                                        type="text"
                                                        required
                                                        placeholder="Diwali Offer"
                                                        value={name}
                                                        label="Name"
                                                        onChange={e => this.setState({name: e.target.value}, this.handleUpdate)}
                                                        fullWidth
                                                        disabled={disabled}
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={6} md={4}>
                                                <ToggleSwitch
                                                    checked={is_custom}
                                                    handleChange={is_custom => {
                                                        if(!is_custom){
                                                            this.setState({is_custom_triggered: true});
                                                        }else{
                                                            this.setState({config: {...config, is_custom}}, this.handleUpdate);
                                                        }
                                                    }}
                                                    label="Custom HTML"
                                                />
                                                <Dialog
                                                    status={is_custom_triggered}
                                                    handleConfirm={() => {
                                                        this.setState({is_custom_triggered: false, config: {...config, is_custom: false}});
                                                    }}
                                                    title="Confirm template switch!"
                                                    handleClose={() => this.setState({is_custom_triggered: false})}
                                                    confirmLabel="Confirm"
                                                    allowCancelLabel="Cancel"
                                                >
                                                    <Typography variant="subtitle1">Your customizations will be lost. Are you sure want to switch ?</Typography>
                                                </Dialog>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12}>
                                                {
                                                    !is_custom && <HTMLInAppUIForm
                                                        {...config}
                                                        handleUpdate={this.handleTemplateChange}
                                                        html={data}
                                                        activities={activities}
                                                        attributeSuggestions={attributeSuggestions}
                                                    />
                                                }
                                                {
                                                    is_custom &&
                                                    <Fragment>
                                                        <HTMLEditor
                                                            value={data}
                                                            handleChange={data => {
                                                                this.setState({html_config: {...html_config, data}}, this.handleUpdate)
                                                            }}
                                                        />
                                                        <div style={{padding: 16}}>
                                                            <Typography variant="caption">Action formats for deep linking (<i><code>href</code> value for button</i>):</Typography>
                                                            <Typography variant="caption"><strong>Deep Link Activity: </strong><code>apxor://dl?activity=<b>com.apxor.testapp.apxortestapp.APIActivity</b></code></Typography>
                                                            <Typography variant="caption"><strong>Deep Link URI: </strong><code>apxor://dl_url?uri=<b>https://www.apxor.com</b>&action=android.intent.action.VIEW</code></Typography>
                                                        </div>
                                                        <TextField
                                                            type="text"
                                                            placeholder="https://www.apxor.com/views/inapp/helper.html"
                                                            value={url}
                                                            label="Load HTML from URL"
                                                            onChange={e => {
                                                                this.setState({html_config: { ...html_config, url: e.target.value }}, this.handleUpdate);
                                                            }}
                                                            fullWidth
                                                            disabled={disabled}
                                                            margin="normal"
                                                        />
                                                    </Fragment>
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={16}>
                                            <Grid item xs={6} md={3}>
                                                <ToggleSwitch
                                                    handleChange={dimmed_background =>  {
                                                        this.setState({window_attributes: { ...window_attributes, dimmed_background }}, this.handleUpdate);
                                                    }}
                                                    label="Dimmed background"
                                                    checked={dimmed_background}
                                                    disabled={disabled}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <ToggleSwitch
                                                    handleChange={dismiss_outside_touch =>  {
                                                        this.setState({window_attributes: { ...window_attributes, dismiss_outside_touch }}, this.handleUpdate);
                                                    }}
                                                    label="Dismiss on outside touch"
                                                    checked={dismiss_outside_touch}
                                                    disabled={disabled}
                                                />
                                            </Grid>
                                            <Grid item xs={6} md={3}>
                                                <ToggleSwitch
                                                    handleChange={has_animation =>  {
                                                        this.setState({window_attributes: { ...window_attributes, has_animation }}, this.handleUpdate);
                                                    }}
                                                    label="Has animation"
                                                    checked={has_animation}
                                                    disabled={disabled}
                                                />
                                            </Grid>
                                            {
                                                has_animation && <Grid item xs={6} md={3}>
                                                    <TextField
                                                        type="number"
                                                        required
                                                        placeholder="1000"
                                                        value={animation_duration}
                                                        label="Animation Duration (Millies)"
                                                        onChange={e => {
                                                            this.setState({window_attributes: { ...window_attributes, animation_duration: Number(e.target.value) }}, this.handleUpdate);
                                                        }}
                                                        fullWidth
                                                        disabled={disabled}
                                                    />
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </ExpansionPanelDetails>
                        <Divider />
                        {
                            showControls && <ExpansionPanelActions>
                                {
                                    isNew && <Button disabled={!(name && name.length > 0)} size="small" variant="contained" color="primary" onClick={this.handleSave}>Save</Button>
                                }
                                {
                                    inEditMode && <Button size="small" onClick={this.handleEdit}>Update</Button>
                                }
                            </ExpansionPanelActions>
                        }
                    </ExpansionPanel>
                </Grid>
            </Grid>
        )
    }

}