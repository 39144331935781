import React from 'react';
import MobileSimulator from '../../../../../../../components/reusable/MobileSimulator';
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import {replaceMacrosWithDefault} from "../../../../../../../utils";

export const MOBILE_IMAGE_URL = "/assets/img/mobile-mockup.png";

export default class InAppMobileSimulator extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            showAnchorMessage: false
        }
    }

    componentDidUpdate(){
         this.handleAnchorTags();
    }

    handleAnchorTags = () => {
        if(this.dom && this.dom.querySelectorAll){
            this.dom.querySelectorAll("a").forEach(node => {
                node.addEventListener("click", e => {
                    e.preventDefault();
                    this.setState({showAnchorMessage: true});
                    setTimeout(() => {
                        this.setState({showAnchorMessage: false});
                    }, 4000);
                    return false;
                });
            });
        }
    };

    replaceFixedForHTML = (html = "") => {
        return html.replace('fixed;', 'absolute;');
    };

    render(){
        const {
            data, dimmed_background = false,
        } = this.props;
        return(
            <MobileSimulator
                screenStyles={{
                    background: `rgba(51,51,51, ${dimmed_background ? 0.8 : 0})`
                }}
            >
                {this.state.showAnchorMessage && <Snackbar>Anchor element's default action prevented.</Snackbar>}
                <div
                    ref={dom => {this.dom = dom;}}
                    style={{width: '100%', height: 462}}
                    dangerouslySetInnerHTML={{
                        __html: this.replaceFixedForHTML(replaceMacrosWithDefault(data))
                    }}
                />
            </MobileSimulator>
        )
    }

}