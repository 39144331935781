import React, { Component } from 'react';
import {
    getGlobalRelatedTimeSpent
} from '../actions';
import {
    addedWithAverageKeys,
    graphDataToCSVString, minMaxAverageOf
} from "../../../../../../../utils";
import MultiUtilityChart from "../../../../../../../components/reusable/Recharts/MultiUtilityChart";
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import Checkbox from "../../../../../../../components/reusable/MaterialUi/Checkbox";
import withSelfComponent from "./SelfComponent";
import {session} from "../../../../../../../utils/Storage";

const TIME_SPENT_DATA_KEY = "Time";

class TimeSpent extends Component {

    constructor(props){
        super(props);
        this.state = {
            groupBy: null,
            showAll: false
        };
    }

    hydrator = (props = this.props) => {
        const {
            dataKey,
            queryParams, extraFilters
        } = props.selfParams;
        const {dispatch, params: {appId}} = props;
        let extendedQueryParams = {...queryParams};
        const { groupBy } = this.state;
        if(groupBy){
            extendedQueryParams = {...queryParams, groupBy}
        }
        /*if(segment && segment.length > 0) {
            dispatch(getSegmentRelatedTimeSeries(appId, segment, dataKey, queryParams, extraFilters));
            return;
        }
        if(event && event.length > 0){
            dispatch(getEventRelatedTimeSeries(appId, event, dataKey, queryParams, extraFilters));
            return;
        }*/
        dispatch(getGlobalRelatedTimeSpent(appId, dataKey, extendedQueryParams, extraFilters));
    };

    render(){
        const {
            self, selfParams: { dataKey, customLabel},
            appState: { attributes: { user = [], session: sessionAttributes = [], event: eventAttributes = [] } = {} },
            meta: { api_pending }, parentCompLabel
        } = this.props;
        let data = self[dataKey];
        let trendDataKey = TIME_SPENT_DATA_KEY;
        let dataKeys = [ trendDataKey ];
        const properties = [ ...user, ...sessionAttributes, ...eventAttributes ];
        const { groupBy, showAll } = this.state;
        if(groupBy && data.length > 0){
            trendDataKey = "all";
            dataKeys = Object.keys(data[0]).filter(o => o !== "key" && (o !== trendDataKey || showAll));
        }
        if(!api_pending) {
            let obj = {};
            obj[customLabel] = graphDataToCSVString(customLabel, data);
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        data = addedWithAverageKeys(data, dataKeys);
        const stats = minMaxAverageOf(data, trendDataKey);
        return (
            <div style={{position: 'relative', width: '100%'}}>
                {
                    Array.isArray(data) && data.length > 0 && <div style={{
                        position: 'absolute',
                        zIndex: 3,
                        right: 250,
                        width: groupBy ? 300 : 200,
                        display: 'flex'
                    }}>
                        {
                            groupBy && <Checkbox
                                checked={showAll}
                                handleChange={(showAll) => this.setState({showAll})}
                                value="showAll"
                                label="All"
                            />
                        }
                        <MultiSelect
                            options={properties.map(o => ({label: o, value: o}))}
                            handleChange={groupBy => {
                                this.setState({groupBy}, this.hydrator);
                            }}
                            value={this.state.groupBy}
                            placeholder="Group By Property"
                            single
                            margin="normal"
                        />
                    </div>
                }
                <MultiUtilityChart
                    {...this.props}
                    title={customLabel}
                    withHeader
                    height={groupBy ? 400 : 300}
                    dataKey="key"
                    yLabel="Time Spent (Seconds)"
                    xLabel="Date"
                    data={ data }
                    lineDataKeys={dataKeys}
                    stats={stats}
                    withoutAggregations={!!groupBy}
                    showAverage
                />
            </div>
        )
    };
}

TimeSpent.propTypes = {

};

export default withSelfComponent(TimeSpent);