/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    UPDATE_HEADING, RESET_HEADING,
    UPDATE_SIDEBAR
} from './actionTypes';
const INITIAL_STATE = {
    heading: null,
    isSidebarOpen: false, //closed by default
    pinnedSidebar: false
    //TODO:
};

function navigationReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case UPDATE_HEADING:
            return Object.assign({}, state, {
                heading: action.payload
            });

        case RESET_HEADING:
            return Object.assign({}, state, {
                heading: null
            });

        case UPDATE_SIDEBAR:
            return {
                ...state,
                isSidebarOpen: action.payload,
                pinnedSidebar: action.meta.pinned
            };

        default:
            return state;

    }
}
export default navigationReducer;