/**
 * Created by Rakesh Peela
 * Date: 21-Mar-2020
 * Time: 11:55 PM
 */

import {Button, CardActions, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import React, {Component,} from 'react';
import {connect} from 'react-redux';
import Loading from "../../../../../../components/reusable/Loading";
import {approveAppAction} from "../actions";
import {RESET_APP_APPROVAL} from "../actionTypes";

function mapStateToProps(state) {
    return {
        auth: state.auth,
        router: state.routing,
        appsState: state.apps,
        appState: state.app
    };
}

function mapDispatchToProps(dispatch) {
    return {
        approveApp: (appId, approverId) => dispatch(approveAppAction(appId, approverId)),
        boundDispatch: dispatch
    };
}

class AppApproval extends Component {

    constructor(props) {
        super(props);
        this.state = {
            waiting: false,
            showApprovalDialog: false
        }
    }

    componentWillMount() {
        if (this.props.appState.app) {
            this.handleNavigation(this.props.appState.app);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.appState.app) {
            this.handleNavigation(nextProps.appState.app);
        }
    }

    handleNavigation(app) {
        const {router} = this.context;
        const {basic_info: {approved, approved_by}, app_id} = app;
        const {} = app;
        if (approved && approved_by) {
            this.setState({
                waiting: true
            });
            setTimeout(() => {
                router.push("/apps/" + app_id + "/event-analysis");
            }, 2000);
        }
    }

    render() {
        const {
            appState: {
                app: {
                    basic_info: {
                        customers,
                        icon_url,
                        approved,
                        approved_by,
                        ...otherInfoKeys
                    },
                    basic_info
                }
            },
            auth: {
                user: {email}
            },
            params: {appId}, boundDispatch
        } = this.props;
        const {approveAppFailed, approveAppPending, approveAppSuccess} = this.props.appsState;
        const {waiting, showApprovalDialog} = this.state;
        return (
            <section style={{width: '100%', marginTop: 100, height: 'calc(100vh - 100px)'}}>
                <Grid container justify="center">
                    <Grid item xs={4} md={4}>
                        <Card>
                            <CardContent>
                                {
                                    waiting && <Loading size={36}/>
                                }

                                {
                                    !waiting
                                    && <div>
                                        <Grid container spacing={32}>
                                            {
                                                Object.keys(otherInfoKeys).map((key, idx) => {
                                                    return <Grid item xs={6} key={idx}>
                                                        <div style={{fontWeight: 600, paddingRight: 12}}>
                                                            {key.replace("_", " ").toUpperCase()}
                                                        </div>
                                                        <div>
                                                            {basic_info[key]}
                                                        </div>
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    </div>
                                }
                            </CardContent>
                            <CardActions style={{borderTop: "1px solid #cecece"}}>
                                <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                                    {/*<Button color={"secondary"}>*/}
                                    {/*    Decline*/}
                                    {/*</Button>*/}
                                    <Button variant={"outlined"} color={"primary"} onClick={() => {
                                        this.setState({
                                            showApprovalDialog: true
                                        })
                                    }}>
                                        {waiting && <Loading size={12} style={{marginRight: 12}}/>} Approve
                                    </Button>
                                </div>
                            </CardActions>
                        </Card>
                    </Grid>
                </Grid>
                {
                    showApprovalDialog
                    && <Dialog
                        open={showApprovalDialog}
                        onClose={() => {
                            boundDispatch({
                                type: RESET_APP_APPROVAL,
                                payload: {},
                                meta: {}
                            })
                        }}
                    >
                        <DialogContent>
                            <Typography variant={"h6"}>
                                Are you sure you want to Approve the
                                app "<b>{otherInfoKeys.app_name}</b>"?
                            </Typography>
                        </DialogContent>
                        <DialogActions>
                            <div>
                                <div>
                                    <Button
                                        disabled={approveAppPending}
                                        onClick={() => {
                                            boundDispatch({
                                                type: RESET_APP_APPROVAL,
                                                payload: {},
                                                meta: {}
                                            });
                                            this.setState({showApprovalDialog: false})
                                        }}> No </Button>
                                    <Button
                                        disabled={approveAppPending}
                                        onClick={() => {
                                            this.props.approveApp(appId, email)
                                        }}>
                                        {approveAppPending && <Loading size={12} style={{marginRight: 12}}/>} Yes
                                    </Button>
                                </div>
                                <div>
                                    {
                                        approveAppFailed && <Typography color={"error"}>
                                            App Approval Failed. Try again after sometime.
                                        </Typography>
                                    }
                                    {
                                        approveAppSuccess && <Typography color={"primary"}>
                                            App Approval Success
                                        </Typography>
                                    }
                                </div>
                            </div>
                        </DialogActions>
                    </Dialog>
                }
            </section>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppApproval);