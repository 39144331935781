import {
    SURVEYS_PENDING, SURVEYS, SURVEYS_FAILED,
    SAVE_SURVEY_PENDING, SAVE_SURVEY, SAVE_SURVEY_FAILED,
    UPDATE_SURVEY_PENDING, UPDATE_SURVEY, UPDATE_SURVEY_FAILED,
    DELETE_SURVEY_PENDING, DELETE_SURVEY, DELETE_SURVEY_FAILED,
    RESET_SURVEYS, UPDATE_CURRENT_SURVEY,
    SURVEY_RESULTS_PENDING, SURVEY_RESULTS, SURVEY_RESULTS_FAILED,
    SURVEY_RESULT_DISTRIBUTIONS_PENDING, SURVEY_RESULT_DISTRIBUTIONS, SURVEY_RESULT_DISTRIBUTIONS_FAILED,
    SURVEY_RESULT_OTHER_OPTION_PENDING, SURVEY_RESULT_OTHER_OPTION, SURVEY_RESULT_OTHER_OPTION_FAILED
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';

const SURVEYS_FLAG_STATE = {
    surveys_pending: false,
    surveys_failed: false
};

const SURVEY_SAVE_FLAG_STATE = {
    survey_saved: false,
    survey_save_pending: false,
    survey_save_failed: false
};

const SURVEY_UPDATE_FLAG_STATE = {
    survey_updated: false,
    survey_update_pending: false,
    survey_update_failed: false
};

const SURVEY_DELETE_FLAG_STATE = {
    survey_deleted: false,
    survey_delete_pending: false,
    survey_delete_failed: false
};

const INITIAL_STATE = {
    surveys: [],
    survey: {},
    results: {},
    others: {},
    result_distributions: {},
    ...SURVEYS_FLAG_STATE,
    ...SURVEY_SAVE_FLAG_STATE,
    ...SURVEY_DELETE_FLAG_STATE,
    ...SURVEY_UPDATE_FLAG_STATE
};

function uninstallReducer(state = INITIAL_STATE, action = {}) {
    const { type, payload, meta } = action;
    switch (type) {
        
        case SURVEYS_PENDING:
            return {
                ...state,
                ...SURVEYS_FLAG_STATE,
                surveys_pending: true
            };

        case SURVEYS:
            return {
                ...state,
                ...SURVEYS_FLAG_STATE,
                surveys: payload
            };

        case SURVEYS_FAILED:
            return {
                ...state,
                ...SURVEYS_FLAG_STATE,
                surveys_failed: true
            };

        case SAVE_SURVEY_PENDING:
            return {
                ...state,
                ...SURVEY_SAVE_FLAG_STATE,
                survey_save_pending: true
            };

        case SAVE_SURVEY:
            return {
                ...state,
                ...SURVEY_SAVE_FLAG_STATE,
                survey_saved: payload
            };

        case SAVE_SURVEY_FAILED:
            return {
                ...state,
                ...SURVEY_SAVE_FLAG_STATE,
                survey_save_failed: true
            };

        case UPDATE_SURVEY_PENDING:
            return {
                ...state,
                ...SURVEY_UPDATE_FLAG_STATE,
                survey_update_pending: true
            };

        case UPDATE_SURVEY:
            return {
                ...state,
                ...SURVEY_UPDATE_FLAG_STATE,
                survey_updated: payload
            };

        case UPDATE_SURVEY_FAILED:
            return {
                ...state,
                ...SURVEY_UPDATE_FLAG_STATE,
                survey_update_failed: true
            };

        case DELETE_SURVEY_PENDING:
            return {
                ...state,
                ...SURVEY_DELETE_FLAG_STATE,
                survey_delete_pending: true
            };

        case DELETE_SURVEY:
            return {
                ...state,
                ...SURVEY_DELETE_FLAG_STATE,
                survey_deleted: payload
            };

        case DELETE_SURVEY_FAILED:
            return {
                ...state,
                ...SURVEY_DELETE_FLAG_STATE,
                survey_delete_failed: true
            };

        case UPDATE_CURRENT_SURVEY:
            return {
                ...state,
                survey: payload
            };

        case SURVEY_RESULTS:
            return {
                ...state,
                results: payload,
                others: {}
            };

        case SURVEY_RESULT_OTHER_OPTION:
            const { questionId, answerId } = meta;
            return {
                ...state,
                others: {
                    ...state.others,
                    [questionId + '-' + answerId]: payload
                }
            };

        case SURVEY_RESULT_DISTRIBUTIONS:
            return {
                ...state,
                result_distributions: payload
            };

        case RESET_SURVEYS:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default uninstallReducer;