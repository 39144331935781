/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import moment from "moment";
import React from 'react';
import { Link } from 'react-router';
import MultiSelect from "../../../../../../components/reusable/MaterialUi/MultiSelect";
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import get from 'lodash/get';
import {DEMO_END_DATE, DEMO_START_DATE} from "../../../../../../config";
import {
    formatNumber, getGroupNameFromList,
    minMaxAverageOf, onlyAlphaNumericChars,
    randomColorWithIndex, roundOffNumber
} from "../../../../../../utils";
import Placeholder from "../../../../../../components/reusable/Placeholder";
import {
    Button,
    Grid,
    Typography,
    Fab,
    Divider,
    CircularProgress,
    DialogTitle,
    Tab, Tabs, withStyles,
} from "@material-ui/core";
import Box from "../../../../../../components/reusable/Box";
import PeopleGroupIcon from '@material-ui/icons/Group';
import AddIcon from '@material-ui/icons/Add';
import WarningIcon from '@material-ui/icons/Warning';
import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import CancelIcon from '@material-ui/icons/Cancel';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import MultiUtilityChart from "../../../../../../components/reusable/Recharts/MultiUtilityChart";
import Switch from "../../../../../../components/reusable/Switch";
import { USER_GROUPS, APXOR_SDK_CATEGORY_CONSTANTS} from "../../../../../../constants";
import Dialog from "../../../../../../components/reusable/MaterialUi/Dialog";
import {
    XAxis, YAxis,
    CartesianGrid, ResponsiveContainer, ReferenceLine,
    BarChart, Bar, Brush, Tooltip
} from 'recharts';
import Snackbar from "../../../../../../components/reusable/MaterialUi/Snackbar";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ToolTip from '@material-ui/core/Tooltip';
import CardActions from '@material-ui/core/CardActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DialogContent from "@material-ui/core/DialogContent";
import {default as MaterialDialog} from "@material-ui/core/Dialog";
import {SEGMENTS_TREND} from "../SelfComponents";
import Chip from '@material-ui/core/Chip';
import AddToDashboard from '../CustomDashboards/AddToDashboard';
import {closeDialog, openDialog} from "./actions";
import SegmentConfig from "./SegmentConfig";
import PresetDateRangePicker from '../../../../../../components/reusable/DateRangePicker';
import Apxor from 'apxor';
import UploadCohortDialog from "./UploadCohortDialog";

export class ToolBar extends React.Component{

    state = {
        deleteTriggered: false
    };

    render(){
        const {
            params: { appId, segmentId },
            value, segment: {
                count, group, segment_list
            },
            updateSegmentUserGroup
        } = this.props;
        const isSegmentSelected = value && value.length;
        return(
            <Grid container justify="flex-end" style={{marginBottom: 10}} spacing={16}>
                <Grid item xs={12} sm>
                    {
                        isSegmentSelected &&
                        <Switch
                            data={USER_GROUPS}
                            value={group}
                            handleChange={group => updateSegmentUserGroup(appId, segmentId, group)}
                            containerStyles={{margin: '0 auto'}}
                        />
                    }
                </Grid>
                <Grid item xs={9} sm={3} md={3}>
                    <MultiSelect {...this.props} />
                </Grid>
                <Grid item xs={3} sm={1} md={1}>
                    {
                        isSegmentSelected &&
                        <ToolTip id="tooltip-users" title={`Users in this Segment (${count})`} placement="top">
                            <div style={{display: 'flex', marginTop: 8}}>
                                <PeopleGroupIcon color="primary"/>
                                <Typography variant="h6" noWrap style={{marginLeft: 3}}>{formatNumber(count)}</Typography>
                            </div>
                        </ToolTip>
                    }
                </Grid>
                <Grid item xs={6} sm={1} md={1}>
                    {
                        segment_list.length === 0 &&
                        <Link to={`/apps/${appId}/segments/new`}>
                            <ToolTip id="tooltip-fab-add" title="Create New Segment" placement="bottom" open={true}>
                                <Fab size="small" color="primary" aria-label="Create New Segment">
                                    <AddIcon />
                                </Fab>
                            </ToolTip>
                        </Link>
                    }
                    {
                        segment_list.length !== 0 &&
                        <Link to={`/apps/${appId}/segments/new`}>
                            <ToolTip id="tooltip-fab-add" title="Create New Segment" placement="bottom">
                                <Fab size="small" color="primary" aria-label="Create New Segment">
                                    <AddIcon />
                                </Fab>
                            </ToolTip>
                        </Link>
                    }
                </Grid>
            </Grid>
        )
    }

}

export class SegmentTimeSeries extends React.Component{

    render(){
        const {
            segment,
            params
        } = this.props;
        const {
            group, time_series,
            segment_list = [], selected_segments, updated_segments = []
        } = segment;
        const updatedSegments = updated_segments; //.map(id => ({value: id, label: getGroupNameFromList(segment_list, id)}));
        const selectedSegments = segment_list.filter(o => selected_segments.hasOwnProperty(o._id)).map(o => o.name);
        const segmentName = getGroupNameFromList(segment_list, params.segmentId);
        let stats = {};
        if(params.segmentId && time_series && time_series.length > 0){
            stats = minMaxAverageOf(time_series, segmentName, "name");
        }
        const segmentNames = Object.keys(selected_segments).map(id => getGroupNameFromList(segment_list, id));
        return(
            <Box
                withPadding
                title={
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={6}>
                            <div style={{display: 'flex', marginTop: 15}}>
                                <PeopleGroupIcon />
                                <Typography variant="subtitle1" style={{marginLeft: 5}}> Segment Comparison</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MultiSelect
                                placeholder="Select Segments to compare"
                                options={segment_list.map(o => ({label: o.name, value: o._id}))}
                                handleChange={(data) => {
                                    Apxor.logEvent("SegmentComparisonSelected");
                                    this.props.updateSegmentList(params.appId)(data)}
                                }
                                value={updatedSegments}
                            />
                        </Grid>
                        {/*<Grid item xs={12} md={1}>*/}
                        {/*    <AddToDashboard {...this.props} componentId={SEGMENTS_TREND} getComponentParams={() => {*/}
                        {/*        return {*/}
                        {/*            extraFilters: {user: [], session: [], event: []},*/}
                        {/*            queryParams: {group, segmentId: [...Object.keys(selected_segments)]}*/}
                        {/*        };*/}
                        {/*    }}>*/}
                        {/*        <div style={{display: 'flex', flexWrap: 'wrap'}}>*/}
                        {/*            {*/}
                        {/*                segmentNames.map(segment => <Chip style={{margin: 3}} key={segment} label={segment} />)*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </AddToDashboard>*/}
                        {/*</Grid>*/}
                    </Grid>
                }
                footer={
                    <SegmentsGroupByAttribute {...this.props} selectedSegments={selectedSegments}/>
                }
            >
                {
                    !this.props.meta.api_pending && (!Array.isArray(time_series) || time_series.length === 0) && <Placeholder />
                }
                {
                    Array.isArray(time_series) && <MultiUtilityChart
                        {...this.props}
                        withHeader
                        dataKey="name"
                        yLabel="Users"
                        data={ time_series }
                        lineDataKeys={selectedSegments}
                        stats={stats}
                    />
                }
            </Box>
        )
    }

}

const _USERS_KEY = "_users";

function listToObject(list = []){
    if(Array.isArray(list)){
        return list.reduce((a, b) => {
            a[b.key] = b.segmentaion_percent; //FIXME: spelling saamyyyy @Kiran _/|\_
            a[b.key + _USERS_KEY] = b.value;
            return a;
        }, {});
    }else{
        return {};
    }
}

function toBarGraphModel(obj = {}, segmentList, attribute) { //FIXME:need proper name
    const segmentIds = Object.keys(obj);
    if(attribute && segmentIds.length > 0){
        //FIXME: Pure hack for more values having segment, so that won't screwed up for not having data in attribute distribution
        const sortedSegmentIds = segmentIds.sort((a, b) => obj[a].length > obj[b].length ? -1 : (obj[a].length < obj[b].length ? 1 : 0));
        const [ segmentId, ...otherSegmentIds ] = sortedSegmentIds;
        const segmentName = getGroupNameFromList(segmentList, segmentId);
        const baseList = obj[segmentId].map(o => ({[segmentName]: o.segmentaion_percent, [segmentName + _USERS_KEY]: o.value, [attribute]: o.key}));
        const attributeValuesMap = {...obj};
        for(let segmentId in attributeValuesMap){
            if(attributeValuesMap.hasOwnProperty(segmentId)){
                const segmentName = getGroupNameFromList(segmentList, segmentId);
                attributeValuesMap[segmentName] = listToObject(attributeValuesMap[segmentId]);
            }
        }
        const otherSegments = otherSegmentIds.map(segmentId => getGroupNameFromList(segmentList, segmentId));
        return baseList.map(o => {
            otherSegments.forEach(segment => {
                o[segment] = attributeValuesMap[segment][o[attribute]] || 0;
                o[segment + _USERS_KEY] = attributeValuesMap[segment][o[attribute] + _USERS_KEY] || 0;
            });
            return o;
        });
    }else {
        return [];
    }
}

const _HACK_IDENTIFIER_FOR_INDEX = "APX#";

class CustomToolTip extends React.Component{
    render(){
        const {active, payload, label} = this.props;
        if(active && Array.isArray(payload))
            return(
                <div className="recharts-default-tooltip" style={{margin: "0px", padding: "10px", backgroundColor: "rgb(255, 255, 255)", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
                    <p className="recharts-tooltip-label" style={{margin: "0px"}}>{label}</p>
                    <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>
                        {
                            payload.map((item, index) => {
                                const color = randomColorWithIndex(Number(item.color.match(/APX#\d+/)[0].slice(4)));
                                return(
                                    <li className="recharts-tooltip-item" key={`li${index}`} style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: color}}>
                                        <span className="recharts-tooltip-item-name">{ item.name }</span>
                                        <span className="recharts-tooltip-item-separator"> : </span>
                                        <span className="recharts-tooltip-item-value">{roundOffNumber(item.value)} ({item.payload[`${item.name + _USERS_KEY}`]})</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            );
        else return <span/>
    }
}

export class SegmentsGroupByAttribute extends React.Component{

    state = {
        selectedAttributeKeys: []
    };

    render(){
        const {
            appState: { attributes = { user: [], session: []} },
            params: { appId }, segment,
            updateSegmentGroupByAttribute,
            selectedSegments
        } = this.props;
        const {
            segment_list = [],
            segments_group_by_attribute, group_by
        } = segment;
        const groupByAttributes = [...attributes.user, ...attributes.session].filter(item => (item !== "custom_user_id")).map(o => ({label: o, value: o}));
        const data = toBarGraphModel(segments_group_by_attribute, segment_list, group_by);
        return(
            <Box
                withPadding
                title="Group By Property"
                controls={
                    <MultiSelect
                        placeholder="Select Property"
                        options={groupByAttributes}
                        handleChange={attribute => {
                            Apxor.logEvent("GroupBySegmentPropertyClicked", {}, APXOR_SDK_CATEGORY_CONSTANTS.USER_SEGMENTS);
                            updateSegmentGroupByAttribute(appId, attribute)}
                        }
                        value={group_by}
                        single
                        style={{maxWidth: 200}}
                        clearable={false}
                    />
                }
            >
                { !this.props.meta.api_pending && (!Array.isArray(data) || data.length === 0) && <Placeholder /> }
                {
                    Array.isArray(data) &&
                    <ResponsiveContainer height={300}>
                        <BarChart data={data}
                                  margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                            <XAxis dataKey={group_by}/>
                             <YAxis width={90} label={{ value:"Users", position: "insideLeft", angle: -90,   dy: 40}}/>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <Tooltip content={<CustomToolTip/>} />
                            <ReferenceLine y={0} stroke='#000'/>
                            {
                                data.length > 10 &&
                                <Brush
                                    dataKey={group_by}
                                    height={30}
                                    stroke="#429ef4"
                                    endIndex={10}
                                />
                            }
                            <defs>
                                {
                                    selectedSegments.map((segmentName, index) =>
                                        <linearGradient key={"gradient-" + segmentName} id={`${onlyAlphaNumericChars(segmentName)}${_HACK_IDENTIFIER_FOR_INDEX}${index}`} x1="0" y1="0" x2="0" y2="1"> {/*&withIndex$ :FIXME: a dodgy hack for getting color for tooltip*/}
                                            <stop offset="5%" stopColor={randomColorWithIndex(index)} stopOpacity={0.8}/>
                                            <stop offset="95%" stopColor={randomColorWithIndex(index)} stopOpacity={0}/>
                                        </linearGradient>
                                    )
                                }
                            </defs>
                            {
                                selectedSegments.map((segmentName, index) =>
                                    <Bar
                                        key={"bar-" + segmentName}
                                        type='monotone' dataKey={segmentName}
                                        fillOpacity={1} fill={`url(#${onlyAlphaNumericChars(segmentName)}${_HACK_IDENTIFIER_FOR_INDEX}${index})`}
                                    />
                                )
                            }
                        </BarChart>
                    </ResponsiveContainer>
                }
            </Box>
        )
    }

}

export class SegmentsHolder extends React.Component{

    constructor(props){
        super(props);
        const { segment_list = [] } = props.segment;
        this.state = {
            expanded: null,
            segments: segment_list,
            query: "",
            tabValue: 0
        };
    }

    componentWillMount(){

    }

    componentWillReceiveProps(nextProps){
        const { segment_list } = nextProps.segment;
        const oldSegmentList = this.props.segment.segment_list;
        if(segment_list.length !== oldSegmentList.length){
            this.updateSegmentsWithQuery(segment_list);
        }
    }

    updateSegmentsWithQuery = (segments = []) => {
        const query = this.state.query.toLowerCase();
        const updatedSegments = segments.filter(segment => segment.name.toLowerCase().includes(query));
        this.setState({segments: updatedSegments});
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleQueryChange = (event) => {
        const { segment_list } = this.props.segment;
        this.setState({query: event.target.value}, () => this.updateSegmentsWithQuery(segment_list));
    };

    render(){
        const { expanded,segments = [], tabValue} = this.state;
        const {
            classes, params: { appId }, segment, cohorts: {cohorts}
        } = this.props;
        const {
            segment_list = [],
            close_dialog
        } = segment;
        const filteredCohorts = cohorts.filter(
            eachCohort => get(eachCohort, "meta.created_from", null) === "ExternalFile"
        );
        return(
            <Grid container justify="center" spacing={24}>
                <Grid item xs={12} md={8}>
                    <Grid container justify="space-between" spacing={16}>
                        <Grid item xs={12} sm={3} style={{marginBottom: 12}}>
                            <Button size={"small"} variant={"outlined"} color={"primary"} onClick={() => {
                                this.props.bindedDispatch(openDialog("UPLOAD_DIALOG"))
                            }}>
                                <CloudUploadIcon style={{marginRight: 8}}/>
                                Upload
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={3} style={{marginBottom: 12}}>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <TextField
                                    placeholder="Search Segments"
                                    id="query-adornment"
                                    fullWidth
                                    InputProps={{
                                        startAdornment: <InputAdornment position="end"
                                                                        style={{padding: '20px 0'}}><SearchIcon/></InputAdornment>
                                    }}
                                    onFocus={() => {
                                        this.setState({tabValue: 0, expanded: null})
                                    }}
                                    onChange={this.handleQueryChange}
                                    style={{marginRight: 8}}
                                />
                                <Link to={`/apps/${appId}/segments/new`} style={{spacing: 10}}>
                                    <ToolTip id="tooltip-fab-add" title="Create New Segment" placement="bottom">
                                        <Fab size="small" color="primary" aria-label="Create New Segment">
                                            <AddIcon/>
                                        </Fab>
                                    </ToolTip>
                                </Link>
                            </div>
                        </Grid>
                    </Grid>
                    <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        value={tabValue}
                        style={{borderBottom: "1px solid #d0d0d0", marginBottom: 16}}
                        onChange={(event, value) => {
                            this.setState({
                                tabValue: value
                            })
                        }}
                    >
                        <Tab disableRipple disableTouchRipple label={"Segments"}/>
                        <Tab disableRipple disableTouchRipple label={"Cohorts"}/>
                    </Tabs>

                    {
                        tabValue === 0 && segments.map(segment =>
                            <SegmentHolder
                                key={segment._id}
                                {...this.props}
                                {...segment}
                                handleChange={this.handleChange(segment._id)}
                                expanded={expanded}
                            />
                        )
                    }

                    {
                        tabValue === 1 && filteredCohorts.length === 0 && <Placeholder text={"No External Cohorts Available"}/>
                    }

                    {
                        tabValue === 1 && filteredCohorts.length > 0 && filteredCohorts.map(cohort =>
                            <CohortCard
                                expanded={expanded}
                                key={cohort.cohort_id || "o=o"}
                                handleChange={this.handleChange(cohort.cohort_id)}
                                cohort={cohort}
                            />
                        )
                    }

                    {
                        !close_dialog &&
                        <MaterialDialog
                            disableEscapeKeyDown={segment.cohort_upload_status.pending}
                            disableBackdropClick={segment.cohort_upload_status.pending}
                            open={!close_dialog}
                            maxWidth={"md"} scroll={"paper"} fullWidth
                            onClose={() => {
                                this.props.bindedDispatch(closeDialog("UPLOAD_DIALOG"));
                            }}
                        >
                            <DialogTitle>Upload</DialogTitle>
                            <DialogContent>
                                <UploadCohortDialog
                                    status={segment.cohort_upload_status}
                                    appId={appId}
                                    dispatch={this.props.bindedDispatch}
                                />
                            </DialogContent>
                        </MaterialDialog>
                    }
                    {
                        tabValue === 0
                        && !this.props.meta.api_pending && (!Array.isArray(segment_list) || segment_list.length === 0) &&
                        <Placeholder
                            withIcon
                            icon={<NaturePeopleIcon style={{opacity: 0.2, width: 150, height: 150}}/>}
                            text="To create your first segment, tap the plus on the top right of the dashboard."
                        />
                    }
                </Grid>
            </Grid>
        )

    }

}

export class CohortCard extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const {
            expanded, handleChange, cohort: {name, cohort_id, created_time, meta: {description, created_from}}
        } = this.props;
        return (
            <div style={{width: '100%', margin: 4}}>
                <ExpansionPanel expanded={expanded === cohort_id} onChange={handleChange}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon/>}>
                        <Grid container spacing={24}>
                            <Grid item xs>
                                <Typography variant="subtitle1" style={{marginTop: '0.8em'}}>{name}</Typography>
                            </Grid>
                            <Grid item xs style={{alignSelf: "center"}}>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                                    <AttachFileIcon style={{fontSize: 16}} color={"disabled"}/>
                                    <Chip
                                        label="Cohort" variant={"outlined"}
                                        style={{marginRight: 4, height: 28}}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    {
                        expanded === cohort_id && <ExpansionPanelDetails>
                            <Grid container spacing={16} alignItems={"center"}>
                                <Grid item xs={6}>
                                    <div style={{
                                        background: "rgb(238,238,238)",
                                        borderRadius: 6,
                                        padding: 12,
                                        marginTop: 4
                                    }}>
                                        <Typography style={{color: "#666"}}>
                                            {`Created From: ${created_from}`}
                                        </Typography>
                                        <Typography style={{color: "#666", fontWeight: 600}}>
                                            {`File: ${description}`}
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography style={{color: "#666"}}>
                                        Created at:
                                    </Typography>
                                    <Typography style={{color: "#666", fontWeight: 600}}>
                                        {moment(created_time).format("LLL")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </ExpansionPanelDetails>
                    }
                </ExpansionPanel>
            </div>
        )
    }
}

function isCurrentCohort(_id, cohorts_list) {
    let currentCohort = null;
    cohorts_list.forEach((cohort, idx) => {
        if (cohort.segment_id === _id)
            currentCohort = cohorts_list[idx]
    });
    return currentCohort;
}

export class SegmentHolder extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            deleteTriggered: false
        };
    }

    handleChange = (event, expanded) => {
        this.props.handleChange(event, expanded);
    };

    render(){
        const {
            expanded, params: { appId },
            segment: {
                segment_delete_failed
            },
            cohorts: {
                cohorts = []
            },
            deleteSegment,
            name, _id
        } = this.props;
        const { deleteTriggered } = this.state;
        const currentCohort = isCurrentCohort(_id, cohorts);
        return(
            <div style={{width: '100%', margin: 4}}>
                <ExpansionPanel expanded={expanded === _id} onChange={this.handleChange}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container spacing={24}>
                            <Grid item xs>
                                <Typography variant="subtitle1" style={{marginTop: '0.8em'}}>{name}</Typography>
                            </Grid>
                            <Grid item xs style={{alignSelf: "center"}}>
                                {(currentCohort && !(expanded === _id)) &&
                                <Chip label="Cohort" variant={"outlined"} style={{marginRight: 4, height: 28}}/>}
                            </Grid>
                            <Grid item xs={1} onClick={(e) => e.stopPropagation()}>
                                { segment_delete_failed && <Snackbar>Deleting Segment Failed. Try Again..!</Snackbar> }
                                <ToolTip id="tooltip-fab" title="Delete this Segment" placement="bottom">
                                    <IconButton aria-label="Delete"
                                                onClick={() => this.setState({deleteTriggered: true})}>
                                        <DeleteIcon color="error" />
                                    </IconButton>
                                </ToolTip>
                                <Dialog
                                    status={deleteTriggered}
                                    handleConfirm={() => {
                                        deleteSegment(appId, _id);
                                        this.setState({deleteTriggered: false});
                                    }}
                                    title="Delete Segment Confirmation"
                                    handleClose={() => this.setState({deleteTriggered: false})}
                                    confirmLabel="Confirm"
                                    allowCancelLabel="Cancel"
                                >
                                    <Typography variant="subtitle1">Are you sure want to delete the segment <strong>{name}</strong> ? </Typography>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    {
                        expanded === _id && <ExpansionPanelDetails>
                            <Segment {...this.props}/>
                        </ExpansionPanelDetails>
                    }
                </ExpansionPanel>
            </div>
        )
    }
}

const STATUS_MESSAGES = {
    "updateCohortSuccess": "Updated Cohort Successfully",
    "updateCohortFailed": "Updating Cohort Failed",
    "savingCohortFailed": "Saving Cohort Failed",
    "savingCohortSuccess": "Saved Cohort Successfully"
};

export class CohortDetails extends React.Component {
    state = {
        updatedCohortSince: null,
        updatedCohortTill: null,
        updateEnabled: false,
        snackbarMessage: null
    };

    componentWillReceiveProps(nextProps, nextContext) {
        const tickers = [
            "updateCohortSuccess",
            "updateCohortFailed",
            "savingCohortFailed",
            "savingCohortSuccess"
        ];
        const trueTicker = tickers.find(el => nextProps.cohorts[el]);
        if (trueTicker) {
            this.setState({
                updateEnabled: false,
                snackbarMessage: STATUS_MESSAGES[trueTicker]
            })
        }
    }

    render() {
        const {
            params: {appId}, currentCohort, updateCohort,
            appState: {app: {settings: {timezone = "UTC"}}},
            cohorts: {updateCohortPending}
        } = this.props;
        const {updateEnabled, updatedCohortSince, updatedCohortTill, snackbarMessage} = this.state;
        return (
            <div>
                <Typography variant="h6" style={{fontWeight: 600}}>
                    Cohort Details
                </Typography>
                <div style={{marginTop: 12}}>
                    <Grid container spacing={16}>
                        {!updateEnabled && <Grid item xs={5}>
                            <Typography>
                                From:
                            </Typography>
                            <Typography style={{fontWeight: 600}}>
                                {moment(currentCohort["since"]).utc().format("LL")}
                            </Typography>
                        </Grid>}
                        {!updateEnabled && <Grid item xs={5}>
                            <Typography>
                                To:
                            </Typography>
                            <Typography style={{fontWeight: 600}}>
                                {moment(currentCohort["till"]).utc().endOf("d").format("LL")}
                            </Typography>
                        </Grid>}
                        {updateEnabled && <Grid item xs={6}>
                            <PresetDateRangePicker
                                handleChange={(start, end) => {
                                    this.setState({
                                        updatedCohortSince: moment(start).utc(),
                                        updatedCohortTill: moment(end).utc().endOf("d")
                                    })
                                }}
                                inUTC
                                small
                                startDate={moment(currentCohort["since"]).utc()}
                                endDate={moment(currentCohort["till"]).utc().endOf("d")}
                                showDefaultInputIcon
                                inputIconPosition="before"
                                disableAfter
                            />
                            <Typography>{timezone}</Typography>
                        </Grid>}
                        {updateEnabled && <Grid item xs={4}>
                            <div style={{position: "absolute", verticalAlign: "middle"}}>
                                <Button size="small" variant="contained" color="primary"
                                        disabled={updateCohortPending}
                                        onClick={() => {
                                            updateCohort(
                                                appId, currentCohort["cohort_id"],
                                                moment(updatedCohortSince ? updatedCohortSince : currentCohort["since"]).utc().toISOString(),
                                                moment(updatedCohortTill ? updatedCohortTill : currentCohort["till"]).utc().endOf("d").toISOString()
                                            );
                                        }}>
                                    Go
                                </Button>
                                {updateCohortPending && <CircularProgress
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12,
                                    }}
                                    size={24}/>}
                            </div>
                        </Grid>}
                        {!updateEnabled && <Grid item xs={2}>
                            <ToolTip title={"Edit"}>
                                <IconButton onClick={() => {
                                    this.setState({
                                        updateEnabled: true
                                    })
                                }}>
                                    <EditIcon color={"primary"}/>
                                </IconButton>
                            </ToolTip>
                        </Grid>}
                        {updateEnabled && <Grid item xs={2}>
                            <ToolTip title={"Cancel Edit"}>
                                <IconButton disabled={updateCohortPending} onClick={() => {
                                    this.setState({
                                        updateEnabled: false
                                    })
                                }}>
                                    <CancelIcon color={"error"}/>
                                </IconButton>
                            </ToolTip>
                        </Grid>}
                    </Grid>
                    <Divider style={{marginTop: 8, marginBottom: 8}}/>
                    <Grid container spacing={16}>
                        <Grid item xs={5}>
                            <Typography style={{fontSize: 12, color: "#666"}}>
                                Created at:
                            </Typography>
                            <Typography style={{fontSize: 12, color: "#666", fontWeight: 600}}>
                                {moment(currentCohort["created_time"]).format("LLL")}
                            </Typography>
                        </Grid>
                        {currentCohort["modified_time"] && <Grid item xs={5}>
                            <Typography style={{fontSize: 12, color: "#666"}}>
                                Updated At:
                            </Typography>
                            <Typography style={{fontSize: 12, color: "#666", fontWeight: 600}}>
                                {moment(currentCohort["modified_time"]).format("LL LTS")}
                            </Typography>
                        </Grid>}
                        {snackbarMessage && <Snackbar onClose={() => {
                            this.setState({
                                snackbarMessage: null
                            })
                        }} autoHideDuration={2500}>{snackbarMessage}</Snackbar>}
                    </Grid>
                </div>
            </div>
        )
    }
}

export class CohortCreator extends React.Component {
    state = {
        cohortSince : moment().utc().subtract(8, "days").startOf("d"),
        cohortTill: moment().utc().subtract(1, "day").endOf("d"),
    };

    render() {
        const {cohortSince, cohortTill} = this.state;
        const {params: {appId}, cohorts: {savingCohort}, _id, appState: {app: {settings: {timezone = "UTC"}}}} = this.props;

        return (
            <div>
                <Typography variant={"h6"}>
                    Create Cohort for the date range
                </Typography>
                <div style={{marginTop: 8, marginBottom: 12}}>
                    <PresetDateRangePicker
                        handleChange={(start,end) => {
                            this.setState({
                                cohortSince: moment(start).utc(),
                                cohortTill: moment(end).utc().endOf("d")
                            })
                        }}
                        inUTC
                        small
                        startDate={moment(cohortSince).utc()}
                        endDate={moment(cohortTill).utc().endOf("d")}
                        showDefaultInputIcon
                        inputIconPosition="before"
                        disableAfter
                    />
                    <Typography>{timezone}</Typography>
                </div>
                <div style={{marginBottom: 12, marginTop: 12}}>
                    <Typography style={{
                        display: "inline-flex",
                        color: "#463815",
                        backgroundColor: "#ecc86c",
                        borderRadius: 4,
                        padding: 4
                    }}>
                        <WarningIcon style={{marginRight: 4}}/> Creating Cohorts can take a while.
                    </Typography>
                </div>
                <div style={{position: "relative"}}>
                    <Button size="small" variant="contained" color="primary"
                            disabled={savingCohort}
                            onClick={() => {
                                this.props.createCohortFromSegment(
                                    appId, _id,
                                    cohortSince.utc().toISOString(),
                                    cohortTill.utc().endOf("d").toISOString()
                                );
                            }}>
                        Go
                    </Button>
                    {savingCohort && <CircularProgress
                        style={{position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12,}}
                        size={24}/>}
                </div>
            </div>
        );
    }
}

export class Segment extends React.Component {

    render(){
        const {
            params: {appId},
            _id, user, session, event, condition,
            cohorts: {
                cohorts = []
            },
            updateSegmentQuery
        } = this.props;
        const currentCohort = isCurrentCohort(_id,cohorts);
        return(
            <Card style={{width: '100%'}}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={6} style={{paddingRight: 24}}>
                            <Typography variant="h6" style={{fontWeight: 600}}>
                                Segment Config
                            </Typography>
                            <div style={{backgroundColor: "#eee", borderRadius: 6, padding: 12, marginTop: 4}}>
                                <SegmentConfig {...this.props}/>
                            </div>
                        </Grid>
                        {currentCohort && <Grid xs={6} item>
                            <CohortDetails {...this.props} currentCohort={currentCohort}/>
                        </Grid>}
                        {!currentCohort && <Grid xs={6} item align={"center"}>
                            <CohortCreator {...this.props} currentCohort={currentCohort}/>
                        </Grid>}
                    </Grid>
                </CardContent>
                <CardActions>
                    <Link to={`/apps/${appId}/segments/${_id}`}>
                        <Button size="small" variant="contained" color="primary">
                            Analyse
                        </Button>
                    </Link>
                    <Link to={`/apps/${appId}/segments/new`} style={{marginLeft: 12}}>
                        <Button size="small" variant={"outlined"} color="secondary" onClick={() => {
                            //update segment filters to segmentBuilder query
                            Apxor.logEvent("CreateNewSegmentFromExistingSegment", {}, APXOR_SDK_CATEGORY_CONSTANTS.USER_SEGMENTS);
                            updateSegmentQuery({name: "", user, session, event, condition});
                        }}>
                            Create new segment from this
                        </Button>
                    </Link>
                </CardActions>
            </Card>
        )

    }

}