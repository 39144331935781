/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    PREPROCESS_PENDING, PREPROCESS, PREPROCESS_FAILED,
    RESET_PREPROCESS,
    PREPROCESS_CONFIGS_PENDING, PREPROCESS_CONFIGS, PREPROCESS_CONFIGS_FAILED
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';

const INITIAL_PREPROCESS_STATE = {
    preprocess_pending: false,
    preprocess_failed: false
};

const INITIAL_PREPROCESS_CONFIGS_STATE = {
    preprocess_configs_pending: false,
    preprocess_configs_failed: false
};

const INITIAL_STATE = {
    preprocess: false,
    preprocess_configs: [],
    ...INITIAL_PREPROCESS_STATE
};

function preprocessReducer(state = INITIAL_STATE, action = {}) {
    const { type, payload } = action;
    switch (type) {

        case PREPROCESS_PENDING:
            return {
                ...state,
                ...INITIAL_PREPROCESS_STATE,
                preprocess_pending: true
            };

        case PREPROCESS:
            return {
                ...state,
                ...INITIAL_PREPROCESS_STATE,
                preprocess: payload
            };

        case PREPROCESS_FAILED:
            return {
                ...state,
                ...INITIAL_PREPROCESS_STATE,
                preprocess_failed: true
            };

        case PREPROCESS_CONFIGS_PENDING:
            return {
                ...state,
                ...INITIAL_PREPROCESS_CONFIGS_STATE,
                preprocess_configs_pending: true
            };

        case PREPROCESS_CONFIGS:
            return {
                ...state,
                ...INITIAL_PREPROCESS_CONFIGS_STATE,
                preprocess_configs: payload
            };

        case PREPROCESS_CONFIGS_FAILED:
            return {
                ...state,
                ...INITIAL_PREPROCESS_CONFIGS_STATE,
                preprocess_configs_failed: true
            };

        case RESET_PREPROCESS:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default preprocessReducer;