import React, { Component } from 'react';
import {getRetention } from '../actions';
import { GRAPH_DEFAULTS} from "../../../../../../../constants";
import RetentionGraph from "../../../../../../../components/plugins/RetentionGraph";
import withSelfComponent from "./SelfComponent";

class Retention extends Component {

    hydrator = (props = this.props) => {
        const { dataKey, queryParams, type, extraFilters } = props.selfParams;
        const {dispatch, params: {appId}} = props;
        dispatch(getRetention(appId, dataKey, type, extraFilters, queryParams));
    };

    render(){
        const {
            self, selfParams: { dataKey, customLabel }, filters,
        } = this.props;
        const data = self[dataKey] || {};
        const { retention_pending, retention_failed } = self;
        return (
            <div style={{width: '100%'}}>
                <RetentionGraph
                    pending={retention_pending}
                    failed={retention_failed}
                    data={data}
                    dateDisplayFormat={GRAPH_DEFAULTS.dateDisplayFormat}
                    title={customLabel}
                    dataDisplayFormat={GRAPH_DEFAULTS.dataDisplayFormat}
                    enableTooltip={true}
                    filters={filters}
                    windowSize={1}
                />
            </div>
        )
    };
}

Retention.propTypes = {

};

export default withSelfComponent(Retention);