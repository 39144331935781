/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

import { callApi } from '../../../../../../../api';
import { makeDefaultQueryString, withQueryStrings } from '../../../../../../../utils';

export const USER_PROFILE_SEARCH_API = "users/search"; //TODO:
export const USER_PROFILE_SEARCH_ATTRIBUTES_API = "TODO"; //TODO:

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param searchFilters
 * @returns {Promise}
 */
export function searchUserProfiles(auth, appId, filters, searchFilters) {
    const url = makeDefaultQueryString(USER_PROFILE_SEARCH_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(searchFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function userProfileSearchAttributes(auth, appId, filters) {
    const url = makeDefaultQueryString(USER_PROFILE_SEARCH_ATTRIBUTES_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}