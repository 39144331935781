import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField
} from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";

const renderValue = (value) => <strong style={{float: 'right'}}>{value || "NA"}</strong>;

export default class NickNameDialog extends React.Component {
    render() {
        const {onCancel, onDone, onChange} = this.props;
        const {model, deviceId, nick_name = ""} = this.props;
        return (
            <Dialog
                open={true}
                onClose={this.handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Set device nick name</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Give this device a nick name for easy identification
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Nick Name"
                        value={nick_name}
                        fullWidth
                        onChange={onChange}
                    />
                    <List>
                        <ListItem>
                            <ListItemText primary={
                                <span title={model}>Model {renderValue(model)}</span>
                            }/>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary={
                                <span title={deviceId}>Device ID {renderValue(deviceId)}</span>
                            }/>
                        </ListItem>
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={onDone} color="primary">
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}


NickNameDialog.propTypes = {
    onDone: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    model: PropTypes.string.isRequired,
    deviceId: PropTypes.string.isRequired
};