/**
 * Created by Araja Jyothi Babu on 21-Oct-16.
 */
/**
 * Configurations of dashboard, which can be manipulated here.
 * @type {string}
 */
import moment from 'moment';

export const API_BASE = process.env.NODE_ENV !== "production" ? "https://localhost/host-redirect/" : window.API_BASE;

export const API_ROOT = window.API_ROOT_V4;
export const API_ROOT_V4 = window.API_ROOT_V4;

export const ApxorAppInfo = window.apxorAppInfo;

export const DateDisplayFormat = window.dateDisplayFormat || 'MMM D, YYYY';

const startDate = window.startDate || moment().subtract(29, 'days');

export const DEFAULT_START_TIME  = startDate;

export const MOBILE_IMAGE = "/assets/img/mobile.png";

export const IOS_DEVICE_MAP = {
    "i386": "iPhone Simulator",
    "x86_64": "iPhone Simulator",
    "iPad1,1": "iPad",
    "iPad1,2": "iPad 3G",
    "iPad2,1": "2nd Gen iPad",
    "iPad2,2": "2nd Gen iPad GSM",
    "iPad2,3": "2nd Gen iPad CDMA",
    "iPad2,4": "2nd Gen iPad New Revision",
    "iPad2,5": "iPad mini",
    "iPad2,6": "iPad mini GSM+LTE",
    "iPad2,7": "iPad mini CDMA+LTE",
    "iPad3,1": "3rd Gen iPad",
    "iPad3,2": "3rd Gen iPad CDMA",
    "iPad3,3": "3rd Gen iPad GSM",
    "iPad3,4": "4th Gen iPad",
    "iPad3,5": "4th Gen iPad GSM+LTE",
    "iPad3,6": "4th Gen iPad CDMA+LTE",
    "iPad4,1": "iPad Air (WiFi)",
    "iPad4,2": "iPad Air (GSM+CDMA)",
    "iPad4,4": "iPad mini Retina (WiFi)",
    "iPad4,5": "iPad mini Retina (GSM+CDMA)",
    "iPad4,6": "iPad mini Retina (China)",
    "iPad4,7": "iPad mini 3 (WiFi)",
    "iPad4,8": "iPad mini 3 (GSM+CDMA)",
    "iPad4,9": "iPad Mini 3 (China)",
    "iPad5,3": "iPad Air 2 (WiFi)",
    "iPad5,4": "iPad Air 2 (Cellular)",
    "iPad6,3": "iPad Pro (9.7 inch, Wi-Fi)",
    "iPad6,4": "iPad Pro (9.7 inch, Wi-Fi+LTE)",
    "iPad6,7": "iPad Pro (12.9 inch, Wi-Fi)",
    "iPad6,8": "iPad Pro (12.9 inch, Wi-Fi+LTE)",
    "iPhone1,1": "iPhone",
    "iPhone1,2": "iPhone 3G",
    "iPhone2,1": "iPhone 3GS",
    "iPhone3,1": "iPhone 4",
    "iPhone3,2": "iPhone 4 GSM Rev A",
    "iPhone3,3": "iPhone 4 CDMA",
    "iPhone4,1": "iPhone 4S",
    "iPhone5,1": "iPhone 5 GSM+LTE",
    "iPhone5,2": "iPhone 5 CDMA+LTE",
    "iPhone5,3": "iPhone 5C (GSM)",
    "iPhone5,4": "iPhone 5C (Global)",
    "iPhone6,1": "iPhone 5S (GSM)",
    "iPhone6,2": "iPhone 5S (Global)",
    "iPhone7,1": "iPhone 6 Plus",
    "iPhone7,2": "iPhone 6",
    "iPhone8,1": "iPhone 6s",
    "iPhone8,2": "iPhone 6s Plus",
    "iPhone8,3": "iPhone SE (GSM+CDMA)",
    "iPhone8,4": "iPhone SE (GSM)",
    "iPhone9,1": "iPhone 7",
    "iPhone9,2": "iPhone 7 Plus",
    "iPhone9,3": "iPhone 7",
    "iPhone9,4": "iPhone 7 Plus",
    "iPod1,1": "1st Gen iPod",
    "iPod2,1": "2nd Gen iPod",
    "iPod3,1": "3rd Gen iPod",
    "iPod4,1": "4th Gen iPod",
    "iPod5,1": "5th Gen iPod",
    "iPod7,1": "6th Gen iPod"
};

export const DEMO_APP_ID = window.demoAppId;
export const APP_ID_FOR_DEMO = window.appIdForDemo;

export const DEMO_START_DATE = window.demoStartDate;
export const DEMO_END_DATE = window.demoEndDate;