/**
 * Created by Rakesh Peela
 * Date: 13-Aug-2019
 * Time: 1:18 PM
 */

import { callApi } from "../../../../../api";
import { makeDefaultQueryString, withQueryStrings } from "../../../../../utils";

const UPDATE_PASSWORD_ENDPOINT = "update/password";
const UPDATE_PROFILE_INFO_ENDPOINT = "update/customerInfo";
const SFA = "2fa/start";
const SFA_ENABLE = "2fa/enable";
const SFA_DISABLE = "2fa/disable";
const SFA_STATUS = "2fa/status";

export function updatePasswordAPI(auth, body) {
  const config = {
    method: "POST",
    auth: auth,
    body: JSON.stringify(body),
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    UPDATE_PASSWORD_ENDPOINT,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}

export function updateProfileDetailsAPI(auth, body) {
  const config = {
    method: "POST",
    auth: auth,
    body: JSON.stringify(body),
  };
  const onSuccessCallback = (json) => {
    let profile = {
      email: json.userId,
      name: json.name,
    };
    return {
      profile: profile,
    };
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(
    UPDATE_PROFILE_INFO_ENDPOINT,
    config,
    onSuccessCallback,
    onFailureCallback
  );
}

export function getUserRoleAPI(auth, userId) {
  const url = makeDefaultQueryString("profile", auth, null);
  const config = {
    method: "GET",
    auth: auth,
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function fetchPendingAppApprovalsAPI(auth, userId) {
  const url = makeDefaultQueryString("pending-approvals", auth, null);
  const config = {
    method: "GET",
    auth: auth,
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return response;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function start2faSettingsAPI(appId, auth) {
  const debug = process.env.NODE_ENV !== "production";
  const url = makeDefaultQueryString(SFA, auth, appId) + withQueryStrings();
  const config = {
    method: "POST",
    auth: auth,
    body: {},
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function enable2faSettingsAPI(auth, data) {
  const debug = process.env.NODE_ENV !== "production";
  const url = makeDefaultQueryString(SFA_ENABLE, auth) + withQueryStrings();
  const config = {
    method: "POST",
    auth: auth,
    body: JSON.stringify(data),
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function disable2faSettingsAPI(auth, data) {
  const debug = process.env.NODE_ENV !== "production";
  const url = makeDefaultQueryString(SFA_DISABLE, auth) + withQueryStrings();
  const config = {
    method: "POST",
    auth: auth,
    body: JSON.stringify(data),
  };
  const onSuccessCallback = (json) => {
    //SET QRCODE IMAGE
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}
export function get2faStatusAPI(auth) {
  const url = makeDefaultQueryString(SFA_STATUS, auth);
  const config = {
    method: "GET",
    auth: auth,
  };
  const onSuccessCallback = (json) => {
    return json;
  };
  const onFailureCallback = (response) => {
    return false;
  };
  return callApi(url, config, onSuccessCallback, onFailureCallback);
}
