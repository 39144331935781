import React, { Component } from 'react';
import {
    getVOVDAUPercent
} from '../actions';
import withSelfComponent from './SelfComponent';
import {session} from "../../../../../../../utils/Storage";
import Box from "../../../../../../../components/reusable/Box";
import MultiUtilityChart from "../../../../../../../components/reusable/Recharts/MultiUtilityChart";
import MultilineChart from '@material-ui/icons/MultilineChart';
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import {getTopVersionsWithMinimumUsers, sortedByVersion} from "../../../../../../../utils";


class VOVDAUPercent extends Component {

    hydrator = (props = {}) => {
        const {
            dataKey, extraFilters, queryParams,
            queryParams: { versions = [], latest, nVersions, minimumUsers, event },
        } = props.selfParams || {};
        const {dispatch, params: {appId}, appState: { appVersions = [] } = {}} = props;
        let qParams = {...queryParams};
        if(latest){
            qParams = {...qParams, versions: getTopVersionsWithMinimumUsers(appVersions, nVersions, minimumUsers)};
        }
        if(qParams.versions.length > 0 && event && event.length > 0){
            dispatch(getVOVDAUPercent(appId, dataKey, qParams, extraFilters));
        }
    };

    render(){
        const {
            self, selfParams: { dataKey, customLabel, queryParams: { versions, event }} = {},
            meta: { api_pending }, parentCompLabel
        } = this.props;
        if(!api_pending){
            let obj = {};
            obj[customLabel] = `${self[dataKey]}`;
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        const data = self[dataKey];
        return (
            <Box icon={<MultilineChart/>} title={customLabel}>
                { (!versions || versions.length === 0) && <Placeholder text="No Versions Selected." /> }
                { (!event || event.length === 0) && <Placeholder text="No Event Selected." /> }
                {
                    data && <MultiUtilityChart
                        barDataKeys={["percent"]}
                        data={sortedByVersion(data).map(o => ({...o, avg_percent: o.user_count}))}
                        dataKey="version"
                        xLabelFormatter={(e) => e}
                        showBrush
                        maxBrushLength={12}
                        showAverage
                        yLabel="Percent"
                    />
                }
            </Box>
        )
    };
}

VOVDAUPercent.propTypes = {

};

export default withSelfComponent(VOVDAUPercent);