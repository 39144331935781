/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../api';
import { makeDefaultQueryString, withQueryStrings} from '../../../../../../utils';
import { API_QUERY_PARAMS } from '../../../../../../constants/EndPoints';
import { RETENTION_ENUM } from './actionTypes';


const UNINSTALL_DISTRIBUTION_API = "uninstall-distribution";
const EVENTS_API = "events";
const EVENT_ATTRIBUTES_API = "events/attributes";
const attributeValuesAPI = (attribute, q) => `attributes/${attribute}`;
const RETENTION_USAGE_API = "retention-usage";
const RETENTION_USAGE_COUNT_API = "retention-usage/count";

const RETENTION_APIS = {
    [RETENTION_ENUM.FTU]: "ftu-retention",
    [RETENTION_ENUM.ALL]: "all-retention",
    [RETENTION_ENUM.RETURNING]: "returning-retention"
};

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param body
 * @param apiType
 * @returns {Promise}
 */
export function getRetentionDataAPI(auth, appId, filters, body, apiType = RETENTION_ENUM.FTU, dayWindow) {
    const url = makeDefaultQueryString(RETENTION_APIS[apiType], auth, appId) + withQueryStrings({...filters, [API_QUERY_PARAMS.installationDate]: filters.till, dayWindow: dayWindow});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getUninstallDistributionDataAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(UNINSTALL_DISTRIBUTION_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(EVENTS_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json.sort((a, b) => a.localeCompare(b)).filter(o => o && o.length); //default sorting on strings
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventAttributesAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(EVENT_ATTRIBUTES_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json.filter(o => o && o.length);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventAttributeValuesAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(attributeValuesAPI(filters.attribute), auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json.filter(o => o && o.length);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getRetentionUsageCountAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(RETENTION_USAGE_COUNT_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}