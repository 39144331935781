/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import {updateHeading, resetHeading} from '../../../Navigation/actions';
import { updateFirstQuery, updateNextQuery } from "../../Retention/actions";
import {
    getCustomOverviewConfig, updateCustomOverviewConfig,
    getDashboards, updateDashboard
} from './../actions';
import {getAttributes, getAttributeValues, resetQuery, updateQuery} from "../../Segments/NewSegment/actions";
import {changeInGlobalFilters} from "../../../../../../../actions";
import DashboardComponent from "./components";
import {getGroupFromList, isDashboardAllowed, persistCurrentDashboard} from "../../../../../../../utils";
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import BrokenImageIcon from '@material-ui/icons/BrokenImage';
import {DASHBOARD_ENDPOINTS} from "../../../../../../../constants/EndPoints";
import {updateCustomDashboardGroup} from "../actions";
import RemoveThisLater from "../RemoveThisLater";
import {addCustomer, addDashboardToCustomer} from "../../Settings/IAM/actions";
import {setDefaultFilters} from "../../../Filters/actions";

function mapStateToProps(state) {
    return {
        ...state,
        session: state.auth,
        router: state.routing,
        appState: state.app,
        overview: state.overview,
        meta: state.meta,
        self: state.self,
        dashboards: state.dashboards,
        queryBuilder: state.queryBuilder,
        segmentBuilder: state.segmentBuilder,
        iam: state.iam
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetHeading: () => dispatch(resetHeading()),
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        updateFirstQuery: (appId, query) => {
            dispatch(updateFirstQuery(appId, query));
        },
        updateNextQuery: (appId, query) => {
            dispatch(updateNextQuery(appId, query));
        },
        getCustomOverviewConfig: (appId) => dispatch(getCustomOverviewConfig(appId)),
        updateCustomOverviewConfig: (appId, config) => dispatch(updateCustomOverviewConfig(appId, config)),
        getAttributes: (appId) => dispatch(getAttributes(appId)),
        getAttributeValues: (appId, attribute, q) => dispatch(getAttributeValues(appId, attribute, q)),
        handleQueryUpdate: (query) => dispatch(updateQuery(query)),
        triggerGlobalFilters: () => dispatch(changeInGlobalFilters()),
        updateDashboard: (appId, dashboard) => dispatch(updateDashboard(appId, dashboard)),
        getDashboards: (appId) => dispatch(getDashboards(appId)),
        updateGroup: (group) => dispatch(updateCustomDashboardGroup(group)),
        resetQuery: () => dispatch(resetQuery()),
        addCustomer: (appId, data) => dispatch(addCustomer(appId, data)),
        addDashboardToCustomer: (appId, customerId, dashboardId) => dispatch(addDashboardToCustomer(appId, customerId, dashboardId)),
        setDefaults: () => dispatch(setDefaultFilters()),
        dispatch
    };
}

class Dashboard extends Component {

    componentWillMount(){
        const {
            updateHeading, getAttributes, getDashboards,
            params: { appId, dashboardId },
            dashboards: { dashboards = [] },
            setDefaults
        } = this.props;
        this.heading = "Custom Dashboard";
        if(dashboards.length === 0){
            getDashboards(appId); //only if dashboards are empty
        }else{
            const dashboard = getGroupFromList(dashboards, dashboardId) || false;
            if(dashboard && dashboard.name){
                this.heading = dashboard.name;
            }
        }
        setDefaults(); //FIXME: groupId undefined handling, remove this later
        updateHeading(this.heading);
        getAttributes(appId);
        persistCurrentDashboard(dashboardId, appId); //persisting to local storage for better UX
    }

    componentWillReceiveProps(nextProps){
        const {
            updateHeading, dashboards: { dashboards = [] },
            params: { dashboardId }
        } = this.props;
        const { dashboards: nextDashboards = [] } = nextProps.dashboards;
        if(dashboards.length === 0 && nextDashboards.length > 0){ //updating heading
            const dashboard = getGroupFromList(nextDashboards, dashboardId) || false;
            this.heading = dashboard ? dashboard.name : "Custom Dashboard";
            updateHeading(this.heading);
        }
    }

    componentWillUnmount(){
        //this.props.resetData();
    }

    render() {
        const {
            appState: { app: { basic_info: { customers } } },
            meta: { api_pending },
            dashboards: { dashboards = [] },
            params: { appId, dashboardId },
            session: { user: { email } }
        } = this.props;
        const dashboard = getGroupFromList(dashboards, dashboardId) || false;
        if(!dashboard){
            return !api_pending ? <section className="content">
                <Placeholder
                    withIcon
                    icon={<BrokenImageIcon style={{opacity: 0.2, width: 250, height: 250}} />}
                    text={
                        <span>
                            Not a valid Dashboard. Try coming from custom&nbsp;
                            <Link to={`/apps/${appId}/${DASHBOARD_ENDPOINTS.DASHBOARDS}`}>Dashboards</Link>
                            &nbsp;page.
                        </span>
                    }
                />
            </section> : <span/>;
        }else if(!isDashboardAllowed(customers, email, dashboardId)){
            return !api_pending ? <section className="content">
                <Placeholder
                    withIcon
                    icon={<BrokenImageIcon style={{opacity: 0.2, width: 250, height: 250}} />}
                    text={
                        <span>
                            Unauthorised. Try coming from custom&nbsp;
                            <Link to={`/apps/${appId}/${DASHBOARD_ENDPOINTS.DASHBOARDS}`}>Dashboards</Link>
                            &nbsp;page.
                        </span>
                    }
                />
            </section> : <span/>;
        } else{
            const { components = [] } = dashboard.config || {};
            return (
                <section className="content">
                    <DashboardComponent {...this.props} heading={this.heading} dashboard={dashboard} components={components}/>
                </section>
            );
        }
    }
}

Dashboard.propTypes = {

};

Dashboard.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard);