/**
 * Created by Rakesh Peela
 * Date: 17-Mar-2020
 * Time: 6:55 AM
 */

import debounce from "lodash/debounce";
import React from "react";
import {Button, ButtonBase, Grid, Typography} from "@material-ui/core";
import {APP_PLATFORMS} from "../../../../../constants";
import ClassicCard from "../Dashboard/Campaigns/Messages2/components/ClassicCard";
import ActionsTextField from "../Dashboard/Campaigns/Messages2/components/Flow/components/ActionsTextField";
import {PLATFORM_IMAGES} from "../Dashboard/Campaigns/Messages2/components/Flow/utils";

const NAME_FIELD_TYPE = "NAME";

const getPlatformWiseString = (platform, type = "BUNDLE") => {
    switch (platform) {
        case APP_PLATFORMS.web:
            return type === NAME_FIELD_TYPE ? "Site Name" : "Website URL";
        case APP_PLATFORMS.android:
            return type === NAME_FIELD_TYPE ? "Android App Name" : "Bundle ID";
        case APP_PLATFORMS.ios:
            return type === NAME_FIELD_TYPE ? "iOS App Name" : "Bundle ID";
        case APP_PLATFORMS.omni:
            return type === NAME_FIELD_TYPE ? "Omni App Name" : "Bundle ID";
        default:
            return ""
    }
};

export default class CreateNewAppComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 0,
            platform: APP_PLATFORMS.android,
            name: "",
            bundle_id: ""
        };
        this.debounce = debounce((fn, data) => {
            fn(data)
        }, 300)
    }

    handleStateChange = (obj) => {
        this.setState({...obj})
    };

    render() {
        const {platform, name, bundle_id} = this.state;
        return (
            <div>
                <Typography>Select App Platform</Typography>
                <Grid container spacing={16} style={{marginBottom: 12, marginTop: 6}}>
                    {
                        Object.keys(APP_PLATFORMS).map(platformKey => {
                            return (<Grid item key={"select-card-" + platformKey}>
                                <ButtonBase
                                    style={{
                                        borderRadius: 10,
                                    }}
                                    disableRipple disableTouchRipple
                                    onClick={() => {
                                        this.handleStateChange({platform: platformKey, step: 1})
                                    }}
                                >
                                    <ClassicCard
                                        style={{minWidth: 64}}
                                        selected={platform === platformKey}
                                    >
                                        {PLATFORM_IMAGES(platformKey, platformKey === platform ? "#4177F6" : "#6f6f6f")}
                                    </ClassicCard>
                                </ButtonBase>
                            </Grid>)

                        })
                    }
                </Grid>
                <div>
                    <Typography>{getPlatformWiseString(platform, NAME_FIELD_TYPE)}</Typography>
                    <ActionsTextField
                        style={{width: "100%", marginTop: 4, marginBottom: 4}}
                        placeholder={getPlatformWiseString(platform, NAME_FIELD_TYPE)}
                        value={name}
                        onChange={(e) => this.handleStateChange({name: e.target.value})}
                    />
                    <Typography>{getPlatformWiseString(platform, null)}</Typography>
                    <ActionsTextField
                        style={{width: "100%", marginTop: 8}}
                        placeholder={getPlatformWiseString(platform, null)}
                        value={bundle_id}
                        onChange={(e) => this.handleStateChange({bundle_id: e.target.value})}
                    />
                </div>
                <div style={{display: "flex", justifyContent: "space-between", flexDirection: "row"}}>
                    <Button
                        color={"primary"}
                        onClick={this.props.handleCancel}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant={"outlined"}
                        color={"primary"}
                        disabled={platform === null || name === "" || bundle_id === ""}
                        onClick={() => {
                            this.props.handleSave({
                                app_name: name,
                                bundle_id,
                                platform,
                            })
                        }}
                    >
                        Create App
                    </Button>
                </div>
            </div>
        );
    }
}