/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading } from '../../Navigation/actions';
import {getCohorts} from "../Cohorts/actions";
import { PreprocessForm } from './components';
import {
    savePreprocess, resetPreprocess, getPreprocessConfigs
} from './actions';
import {getTopEvents} from "../EventAnalysis/actions";
import {getEventAttributes} from "../../../../../../components/reusable/QueryBuilder/actions";


function mapStateToProps(state) {
    return {
        session: state.auth,
        cohort: state.cohorts,
        router: state.routing,
        appState: state.app,
        filters: state.filters,
        meta: state.meta,
        preprocess: state.preprocess,
        eventAnalysis: state.eventAnalysis,
        queryBuilder: state.queryBuilder
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getCohorts: (appId) => dispatch(getCohorts(appId)),
        getPreprocessConfigs: (appId) => dispatch(getPreprocessConfigs(appId)),
        savePreprocess: (appId, config, cohortId) => dispatch(savePreprocess(appId, config, cohortId)),
        resetPreprocess: () => dispatch(resetPreprocess()),
        getTopEvents: (appId) => dispatch(getTopEvents(appId)),
        getEventAttributes: (appId, event) => dispatch(getEventAttributes(appId, event))
    };
}

class Preprocess extends Component {

    componentWillMount(){
        const {params: { appId }, updateHeading, getTopEvents, getPreprocessConfigs, getCohorts } = this.props;
        updateHeading("Behaviour Correlation Framework");
        getPreprocessConfigs(appId);
        getTopEvents(appId);
        getCohorts(appId);
    }

    componentWillUnmount(){
        this.props.resetPreprocess();
    }

    render() {
        const { eventAnalysis: { top_events = [] } } = this.props;
        return (
            <section className="content">
                {Array.isArray(top_events) && top_events.length > 0 && <PreprocessForm {...this.props}/> }
            </section>
        );
    }
}

Preprocess.propTypes = {

};

Preprocess.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Preprocess);