import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { getEventCount, getUsersForEvent } from '../actions';
import CountBox from "../../../../../../../components/reusable/MaterialUi/CountBox";
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import {TextField} from "@material-ui/core";
import Switch from "../../../../../../../components/reusable/Switch";
import {getPercent} from "../../../../../../../utils";
import UserListPopover from "./reusable/UserListPopover";

const SWITCH_DATA = [
    {
        name: "Event done count",
        value: "event_done_users"
    },
    {
        name: "Event not done count",
        value: "event_not_done_users"
    }
];

const USER_GROUPS = [
    {
        name: "All Users",
        value: "isAll"
    },
    {
        name: "New Installs",
        value: "isNewinstalled"
    },
    {
        name: "Uninstalls",
        value: "isUninstalled"
    }
];

const USER_GROUP_LABELS = {
    isNewinstalled: "New Installs",
    isUninstalled: "Uninstalls",
    isAll: "All Users"
};

/**
 * @deprecated
 */
export default class UserCount extends Component {

    extension = (group) => {
        const { event, sub_key } = this.state;
        return (group || this.state.group) + '_' + event + '_' + sub_key  + "_count";
    };

    getLabel = () => {
        const { event, sub_key, group } = this.state;
        return `${event} ${sub_key.split("_").slice(1).join(" ")} (${USER_GROUP_LABELS[group]})`;
    };

    usersExtension = "_users";

    constructor(props){
        super(props);
        this.state = {
            event: props.event || "",
            dataKey: props.dataKey || props.event,
            sub_key: props.sub_key || "event_done_users",
            customLabel: props.customLabel || "",
            old: props.old || false,
            group: props.group || "isAll",
            _id: props._id || "",
            negation: props.negation || false,
        };
    }

    componentWillReceiveProps(nextProps) {
        const { old, meta, selfParams } = nextProps;
        if(old){
            this.setState({...selfParams});
        }
        //reloading data for updated filters
        const { meta: { globalFiltersChanged }  } = this.props;
        if(meta.globalFiltersChanged && globalFiltersChanged !== meta.globalFiltersChanged){
            this.populateData();
        }
    }

    componentWillMount(){
        const { old, event } = this.state;
        if(old && event && event.length > 0){
            this.populateData();
        }
    }

    populateData = () => {
        const {old, event, dataKey, group} = this.state;
        if(old && event && event && event.length > 0) {
            const {dispatch, params: {appId}} = this.props;
            dispatch(getEventCount(appId, event, dataKey, { [group]: true }));
        }
    };

    handleClick = () => {
        const { event, dataKey, group, negation} = this.state;
        const {dispatch, params: {appId}} = this.props;
        dispatch(getUsersForEvent(appId, event, dataKey + this.usersExtension, { [group]: true, negation }));
    };

    renderComponents = () => {
        const { event, dataKey, sub_key, customLabel, old, group } = this.state;
        const { self, updateWidgetProps, appState: { appEvents = [] } } = this.props;
        if(event.length > 0 && self[dataKey] && old) {
            const heading = self[dataKey][sub_key];
            const percent = getPercent(heading, self[dataKey].active_users);
            return (
                <div style={{position: 'relative'}}>
                    {
                        heading > 0 && <div style={{
                            position: 'absolute',
                            zIndex: 3,
                            right: 0
                        }}><UserListPopover
                            {...this.props}
                            id={dataKey + this.usersExtension}
                            users={self[dataKey + this.usersExtension]}
                            handleClick={this.handleClick}
                            label="View Users"
                        /></div>
                    }
                    <CountBox subheading={`${customLabel} (${percent} %)`} heading={heading} />
                </div>
            )
        }else{
            return(
                <form onSubmit={e => {
                    e.preventDefault();
                }}>
                    <Card style={{width: '100%'}}>
                        <CardContent>
                            <MultiSelect single placeholder="Select Event" options={appEvents.map(o => ({label: o, value: o}))} value={event} handleChange={
                                event => this.setState({event}, () => this.setState({customLabel: this.getLabel()}))
                            } />
                            <Switch
                                data={USER_GROUPS}
                                value={group}
                                handleChange={group => this.setState({group}, () => this.setState({customLabel: this.getLabel()}))}
                            />
                            <Switch
                                data={SWITCH_DATA}
                                value={sub_key}
                                handleChange={sub_key => this.setState({
                                    sub_key, negation: sub_key !== SWITCH_DATA[0].value
                                }, () => this.setState({customLabel: this.getLabel()}))}
                            />
                            <TextField
                                label="Custom Label"
                                value={customLabel}
                                onChange={e => this.setState({customLabel: e.target.value})}
                                margin="dense"
                                fullWidth
                            />
                        </CardContent>
                        <CardActions>
                            <Button onClick={e => {
                                if(typeof updateWidgetProps === 'function') {
                                    this.setState({old: true, dataKey: this.extension()}, () => {
                                        this.populateData();
                                        updateWidgetProps({...this.state});
                                    });
                                }
                            }} variant="contained" style={{float: 'right'}} color="primary">Submit</Button>
                        </CardActions>
                    </Card>
                </form>
            );
        }
    };

    render(){
        return(
            <div style={{width: '100%'}}>
                {this.renderComponents()}
            </div>
        );
    }
}

UserCount.propTypes = {

};