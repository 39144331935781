import React, { Component, } from 'react';
import Typography from '@material-ui/core/Typography';
import { toTitleCase } from "../../../../../../../utils";
import SortableDataTable from "../../../../../../../components/reusable/SortableDataTable";
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import RemoveIcon from '@material-ui/icons/Remove';
import { Cell } from 'fixed-data-table-2';
import {COUNT_TYPE_ENUM} from "../../../../../../../constants";

export default class Explorer extends Component {

    constructor(props){
        super(props);
        this.state = {
            selectedDimensions: [],
            dimensionsUpdated: false
        };
    }

    populateData = (newDimensions = this.state.selectedDimensions, props = this.props) => {
        const {
            params: { appId },
            customReport: { metrics = [], dimensions= [], filters = [] },
            getExplorer, updateQuery
        } = props;
        if(newDimensions.length === 0){
            newDimensions = dimensions.slice(0, 1);
        }
        const query = {metrics, dimensions: newDimensions, filters};
        updateQuery(query);
        getExplorer(appId);
        this.setState({selectedDimensions: newDimensions, dimensionsUpdated: true});
    };

    componentWillMount(){
        const { customReport: { dimensions= [] } } = this.props;
        const currentDimensions = dimensions.slice(0, 1);
        this.populateData(currentDimensions);
    }

    handleDimensionWithValue = (value) => {
        const { customReport: { dimensions = [] } } = this.props;
        const { selectedDimensions } = this.state;
        const length = selectedDimensions.length;
        if(dimensions.length > length){
            const dimension = { ...selectedDimensions[length - 1] };
            dimension.value = value;
            const newSelectedDimensions = [...selectedDimensions.slice(0, -1), dimension, ...dimensions.slice(length, length + 1)];
            this.populateData(newSelectedDimensions);
        }
    };

    handleDimensionWithDimensionIndex = (index, isDimension = false) => {
        const { customReport: { dimensions = [] } } = this.props;
        const { selectedDimensions } = this.state;
        let newSelectedDimensions = [...selectedDimensions.slice(0, index + 1)];
        if(isDimension){
            newSelectedDimensions[index].value = undefined;
        }else{
            newSelectedDimensions = [...newSelectedDimensions, dimensions[index + 1] ];
        }
        this.populateData(newSelectedDimensions);
    };

    componentWillReceiveProps(nextProps) {
        const { meta,  needUpdate } = nextProps;
        //reloading data for updated filters
        const {
            meta: { globalFiltersChanged },
            needUpdate: prevNeedUpdate
        } = this.props;
        if(meta.globalFiltersChanged && globalFiltersChanged !== meta.globalFiltersChanged){
            this.populateData(undefined, nextProps);
        }
        if(needUpdate && prevNeedUpdate !== needUpdate){
            this.populateData([], nextProps);
            //this.setState({dimensionsUpdated: false});
        }
    }

    getBreadCrumbs = (selectedDimensions) => (
        <Typography>
            {
                selectedDimensions.map((dim, index) => {
                    if(index === selectedDimensions.length - 1){
                        return <span key={dim.name}>{dim.name}</span>;
                    }else{
                        return (
                            <span key={dim.name}>
                                            <a onClick={e => this.handleDimensionWithDimensionIndex(index, true)}>{dim.name}</a>
                                &nbsp;&gt;&nbsp;
                                {
                                    dim.value && <span>({dim.value})</span>
                                }
                                {
                                    dim.value && <span>&nbsp;&gt;&nbsp;</span>
                                }
                                </span>
                        )
                    }
                })
            }
        </Typography>
    );

    render(){
        const {
            customReport: {
                dimensions = []
            },
            needUpdate
        } = this.props;
        const { selectedDimensions = [] } = this.state;
        const isValueSelectable = selectedDimensions.length < dimensions.length;
        return(
            <div style={{width: '100%'}}>
                {
                    selectedDimensions.length > 0 && <ExplorerTable
                        isValueSelectable={isValueSelectable}
                        {...this.props}
                        boxProps={{
                            title: this.getBreadCrumbs(selectedDimensions)
                        }}
                        handleValueClick={this.handleDimensionWithValue}
                        needUpdate={needUpdate || this.state.dimensionsUpdated}
                    />
                }
            </div>
        )

    }

}

const UNKNOWN_KEY = "UnKnown";

function headerLabelFormatter(header) {
    if(header === COUNT_TYPE_ENUM.impressions)
        return "Total Events"
    return toTitleCase(header)
}

class ExplorerTable extends Component {

    render(){

        const {
            customReport: {
                metrics = [], explorer: {aggregations = {}, data = [] } = {}
            },
            isValueSelectable, handleValueClick
        } = this.props;
        return(
            <div style={{width: '100%'}}>
                {
                    data.length > 0 && <SortableDataTable
                        {...this.props}
                        data={data}
                        tableType={"explorer"}
                        headerLabels={[aggregations.name, ...metrics.map(m => {
                            if (["users", "sessions"].includes(m))
                                return headerLabelFormatter(m);
                            return `${headerLabelFormatter(m)} (${aggregations[m] || "NA"})`
                        })]}
                        keys={["name", ...metrics]}
                        TextCell={({rowIndex, data, col, ...props}) => {
                            const value = data[rowIndex][col];
                            return <Cell {...props}>
                                {
                                    col === "name" ? (isValueSelectable && value !== UNKNOWN_KEY ? <a style={{textDecoration: 'none'}} onClick={e => {
                                        e.preventDefault();
                                        handleValueClick(value);
                                    }}>{value}</a> : value) : value
                                }
                            </Cell>
                        }}
                        downloadReportTitle={`Explorer-${new Date().toDateString()}`}
                    />
                }
                {
                    data.length === 0 && <Placeholder withIcon icon={<RemoveIcon  color="error"/>}>No Data.</Placeholder>
                }
            </div>
        )

    }

}