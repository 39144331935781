/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

import { callApi } from '../../../../../../../api';
import { makeDefaultQueryString, withQueryStrings } from '../../../../../../../utils';
import { API_QUERY_PARAMS } from '../../../../../../../constants/EndPoints';

export function makeUserProfileApi(url, userId){
    return "users/" + userId + url;
}

export const STATIC_BASIC_INFO_API = "users/";
export const DYNAMIC_BASIC_INFO_API = "users/dynamic/";
export const SESSION_INFO_API = "session-info/";
export const CRASH_INFO_API = "crash-info/";
export const TRANSACTION_INFO_API = "/transaction-info"; //TODO
export const SESSION_TIME_SERIES_API = "sessions/";
export const SESSIONS_API = "session-infos";
export const USER_PROFILE_SESSION_TIMELINE_API = "session-timeline";

export function makeTimelineApi(sessionId) {
    return "sessions/" + sessionId;
}

/**
 *
 * @param auth
 * @param appId
 * @param userId
 * @param filters
 * @returns {Promise}
 */
export function getUserProfileStaticBasicInfoAPI(auth, appId, userId, filters) {
    const url = makeDefaultQueryString(STATIC_BASIC_INFO_API + userId, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param userId
 * @param filters
 * @returns {Promise}
 */
export function getUserProfileDynamicBasicInfoAPI(auth, appId, userId, filters) {
    const url = makeDefaultQueryString(DYNAMIC_BASIC_INFO_API + userId, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param userId
 * @param filters
 * @returns {Promise}
 */
export function getUserProfileSessionInfoAPI(auth, appId, userId, filters) {
    const url = makeDefaultQueryString(SESSION_INFO_API + userId, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json || {};
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param userId
 * @param filters
 * @returns {Promise}
 */
export function getUserProfileCrashInfoAPI(auth, appId, userId, filters) {
    const url = makeDefaultQueryString(CRASH_INFO_API + userId, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param userId
 * @param filters
 * @returns {Promise}
 */
export function getUserProfileTransactionInfoAPI(auth, appId, userId, filters) {
    const url = makeDefaultQueryString(makeUserProfileApi(TRANSACTION_INFO_API, userId), auth, appId);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param userId
 * @param filters
 * @returns {Promise}
 */
export function getUserProfileSessionTimeSeriesAPI(auth, appId, userId, filters) {
    const url = makeDefaultQueryString(SESSION_TIME_SERIES_API + userId, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return Array.isArray(json) ? json.sort((a, b) => a.key.localeCompare(b.key)).map(o => ({key: o.key, Sessions: o.value})) : [];
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param userId
 * @param uspType
 * @param filters
 * @returns {Promise}
 */
export function getUserProfileUSPValuesAPI(auth, appId, userId, uspType, filters) {
    const url = makeDefaultQueryString(`users/${userId}/SUP/${uspType}`, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param sessionId
 * @param filters
 * @returns {Promise}
 */
export function getUserProfileSessionTimelineAPI(auth, appId, sessionId, filters) {
    const url = makeDefaultQueryString(USER_PROFILE_SESSION_TIMELINE_API, auth, appId) + withQueryStrings({...filters, [API_QUERY_PARAMS.sessionId]: sessionId});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param userId
 * @param filters
 * @returns {Promise}
 */
export function getUserProfileSessionsAPI(auth, appId, userId, filters) {
    const url = makeDefaultQueryString(userId + '/' + SESSIONS_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}