import React, { Component, } from 'react';
import Typography from '@material-ui/core/Typography';
import { toTitleCase } from "../../../../../../../utils";
import SortableDataTable from "../../../../../../../components/reusable/SortableDataTable";
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import RemoveIcon from '@material-ui/icons/Remove';
import { Cell } from 'fixed-data-table-2';
import {COUNT_TYPE_ENUM} from "../../../../../../../constants";
import withSelfComponent from "./SelfComponent";
import {getExplorer} from "../actions";

class Explorer extends Component {

    hydrator = (props = this.props) => {
        this.populateData([], props);
    };

    constructor(props){
        super(props);
        this.state = {
            selectedDimensions: [],
            dimensionsUpdated: false
        };
    }

    populateData = (newDimensions = this.state.selectedDimensions, props = this.props) => {
        const {
            params: { appId },
            dispatch,
            selfParams:{
                extraFilters:{
                    metrics, dimensions, filters
                } = {},
                dataKey
            } = {}
        } = props;
        if(newDimensions.length === 0){
            newDimensions = dimensions.slice(0, 1);
        }
        const query = {metrics, dimensions: newDimensions, filters};
        dispatch(getExplorer(appId, query, dataKey));
        this.setState({selectedDimensions: newDimensions, dimensionsUpdated: true});
    };

    handleDimensionWithValue = (value) => {
        const { selfParams:{
            extraFilters:{
                dimensions = []
            } = {}
        } = {} } = this.props;
        const { selectedDimensions } = this.state;
        const length = selectedDimensions.length;
        if(dimensions.length > length){
            const dimension = { ...selectedDimensions[length - 1] };
            dimension.value = value;
            const newSelectedDimensions = [...selectedDimensions.slice(0, -1), dimension, ...dimensions.slice(length, length + 1)];
            this.populateData(newSelectedDimensions);
        }
    };

    handleDimensionWithDimensionIndex = (index, isDimension = false) => {
        const { selfParams:{
            extraFilters:{
                dimensions = []
            } = {}
        } = {} } = this.props;
        const { selectedDimensions } = this.state;
        let newSelectedDimensions = [...selectedDimensions.slice(0, index + 1)];
        if(isDimension){
            newSelectedDimensions[index].value = undefined;
        }else{
            newSelectedDimensions = [...newSelectedDimensions, dimensions[index + 1] ];
        }
        this.populateData(newSelectedDimensions);
    };

    getBreadCrumbs = (selectedDimensions) => (
        <Typography>
            {
                selectedDimensions.map((dim, index) => {
                    if(index === selectedDimensions.length - 1){
                        return <span key={dim.name}>{dim.name}</span>;
                    }else{
                        return (
                            <span key={dim.name}>
                                            <a onClick={e => this.handleDimensionWithDimensionIndex(index, true)}>{dim.name}</a>
                                &nbsp;&gt;&nbsp;
                                {
                                    dim.value && <span>({dim.value})</span>
                                }
                                {
                                    dim.value && <span>&nbsp;&gt;&nbsp;</span>
                                }
                                </span>
                        )
                    }
                })
            }
        </Typography>
    );

    render(){
        const {
            selfParams:{
                extraFilters:{
                    dimensions = [], metrics = []
                } = {},
                dataKey
            } = {},
            self
        } = this.props;
        const { selectedDimensions = [] } = this.state;
        const isValueSelectable = selectedDimensions.length < dimensions.length;
        return(
            <div style={{width: '100%'}}>
                {
                    selectedDimensions.length > 0 && <ExplorerTable
                        isValueSelectable={isValueSelectable}
                        {...this.props}
                        boxProps={{
                            title: this.getBreadCrumbs(selectedDimensions)
                        }}
                        selectedDimensions={selectedDimensions}
                        handleValueClick={this.handleDimensionWithValue}
                        needUpdate={this.state.dimensionsUpdated}
                        metrics = {metrics}
                        explorer = {self[dataKey] || {}}
                    />
                }
            </div>
        )

    }

}

const UNKNOWN_KEY = "UnKnown";

function headerLabelFormatter(header) {
    if(header === COUNT_TYPE_ENUM.impressions)
        return "Total Events"
    return toTitleCase(header)
}

class ExplorerTable extends Component {

    render(){

        const {
            metrics = [], explorer: {aggregations = {}, data = [] } = {},
            isValueSelectable, handleValueClick
        } = this.props;
        return(
            <div style={{width: '100%'}}>
                {
                    data.length > 0 && <SortableDataTable
                        {...this.props}
                        data={data}
                        tableType={"explorer"}
                        headerLabels={[aggregations.name, ...metrics.map(m => `${headerLabelFormatter(m)} (${aggregations[m] || "NA"})`)]}
                        keys={["name", ...metrics]}
                        TextCell={({rowIndex, data, col, ...props}) => {
                            const value = data[rowIndex][col];
                            return <Cell {...props}>
                                {
                                    col === "name" ? (isValueSelectable && value !== UNKNOWN_KEY ? <a style={{textDecoration: 'none'}} onClick={e => {
                                        e.preventDefault();
                                        handleValueClick(value);
                                    }}>{value}</a> : value) : value
                                }
                            </Cell>
                        }}
                        downloadReportTitle={`Explorer-${new Date().toDateString()}`}
                    />
                }
                {
                    data.length === 0 && <Placeholder withIcon icon={<RemoveIcon  color="error"/>}>No Data.</Placeholder>
                }
            </div>
        )

    }

}

export default withSelfComponent(Explorer);