
export const SELF_EVENT_DISTRIBUTION_PENDING = "SELF_EVENT_DISTRIBUTION_PENDING";
export const SELF_EVENT_DISTRIBUTION = "SELF_EVENT_DISTRIBUTION";
export const SELF_EVENT_DISTRIBUTION_FAILED = "SELF_EVENT_DISTRIBUTION_FAILED";

export const SELF_EVENT_COUNT_PENDING = "SELF_EVENT_COUNT_PENDING";
export const SELF_EVENT_COUNT = "SELF_EVENT_COUNT";
export const SELF_EVENT_COUNT_FAILED = "SELF_EVENT_COUNT_FAILED";

export const SELF_EVENT_HOUR_DISTRIBUTION_PENDING = "SELF_EVENT_HOUR_DISTRIBUTION_PENDING";
export const SELF_EVENT_HOUR_DISTRIBUTION = "SELF_EVENT_HOUR_DISTRIBUTION";
export const SELF_EVENT_HOUR_DISTRIBUTION_FAILED = "SELF_EVENT_HOUR_DISTRIBUTION_FAILED";

export const SELF_RETENTION_PENDING = "SELF_RETENTION_PENDING";
export const SELF_RETENTION = "SELF_RETENTION";
export const SELF_RETENTION_FAILED = "SELF_RETENTION_FAILED";

export const SELF_STATISTICS_PENDING = "SELF_STATISTICS_PENDING";
export const SELF_STATISTICS = "SELF_STATISTICS";
export const SELF_STATISTICS_FAILED = "SELF_STATISTICS_FAILED";

export const SELF_USERS_FOR_EVENT_PENDING = "SELF_USERS_FOR_EVENT_PENDING";
export const SELF_USERS_FOR_EVENT = "SELF_USERS_FOR_EVENT";
export const SELF_USERS_FOR_EVENT_FAILED = "SELF_USERS_FOR_EVENT_FAILED";

export const SELF_EVENT_RELATED_PENDING = "SELF_EVENT_RELATED_PENDING";
export const SELF_EVENT_RELATED = "SELF_EVENT_RELATED";
export const SELF_EVENT_RELATED_FAILED = "SELF_EVENT_RELATED_FAILED";

export const SELF_GLOBAL_RELATED_PENDING = "SELF_GLOBAL_RELATED_PENDING";
export const SELF_GLOBAL_RELATED = "SELF_GLOBAL_RELATED";
export const SELF_GLOBAL_RELATED_FAILED = "SELF_GLOBAL_RELATED_FAILED";

export const SELF_SEGMENT_RELATED_PENDING = "SELF_SEGMENT_RELATED_PENDING";
export const SELF_SEGMENT_RELATED = "SELF_SEGMENT_RELATED";
export const SELF_SEGMENT_RELATED_FAILED = "SELF_SEGMENT_RELATED_FAILED";