/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {updateHeading, resetHeading} from '../../Navigation/actions';
import {
    getContexts, updateLocalContext,
    resetContexts, deleteContext
} from './actions';
import {
    ContextsHolder
} from './components';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        segment: state.segment,
        filters: state.filters,
        meta: state.meta,
        contexts: state.contexts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetHeading: () => dispatch(resetHeading()),
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getContexts: (appId) => dispatch(getContexts(appId)),
        updateLocalContext: (appId, context) => dispatch(updateLocalContext(appId, context)),
        deleteContext: (appId, contextId) => dispatch(deleteContext(appId, contextId)),
        resetContexts: () => dispatch(resetContexts()),
    };
}

class Contexts extends Component {

    componentWillMount(){
        this.props.updateHeading("Contexts");
        const { params: { appId }, getContexts } = this.props;
        getContexts(appId);
    }

    componentWillUnmount(){
        this.props.resetContexts();
    }

    render() {
        return (
            <section className="content">
                <ContextsHolder {...this.props}/>
            </section>
        );
    }
}

Contexts.propTypes = {

};

Contexts.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Contexts);