/**
 * Created by Rakesh Peela
 * Date: 31-Dec-2019
 * Time: 4:42 PM
 */

import {Chip, Grid, Typography, withStyles} from "@material-ui/core";
import React, {Fragment} from 'react';
import classnames from 'classnames';
import {Treebeard, decorators} from 'react-treebeard';
import {includes} from 'lodash';
import BetaChip from "../../../../../../../../../../components/reusable/BetaChip";
import Loading from "../../../../../../../../../../components/reusable/Loading";
import {withQueryStrings} from "../../../../../../../../../../utils";
import ClassicCard from "../../ClassicCard";
import {ORIENTATION_ENUM} from './DesignPreview/utils';
import ModuleConfigSection from "./modules/ModuleConfigSection";

const SSE_API = window.SSE_ROOT + 'sse-layout?';

const getScaleFactors = (device_config, preview_config, orientation) => {
    const {image_width, image_height} = preview_config;
    const {device_width, device_height} = device_config;

    let w_scaleFactor = device_width / image_width,
        h_scaleFactor = device_height / image_height;

    return {
        w_scaleFactor: orientation === ORIENTATION_ENUM.PORTRAIT ? w_scaleFactor : h_scaleFactor,
        h_scaleFactor: orientation === ORIENTATION_ENUM.PORTRAIT ? h_scaleFactor : w_scaleFactor
    }
};

const resizeBounds = (bounds, ratio) => {
    const {top, bottom, left, right} = bounds;
    const {w_scaleFactor, h_scaleFactor} = ratio;
    let newBounds = {
        top: Math.round(top / h_scaleFactor),
        bottom: Math.round(bottom / h_scaleFactor),
        left: Math.round(left / w_scaleFactor),
        right: Math.round(right / w_scaleFactor),
    };

    return ({
        bounds: {
            ...newBounds,
            width: newBounds.right - newBounds.left,
            height: newBounds.bottom - newBounds.top
        }
    })
};

const layoutModifier = (layout, ratio) => {
    return layout.map(({bounds, views, ...others}) => ({
        ...others,
        ...resizeBounds(bounds, ratio),
        views: layoutModifier(views, ratio)
    }))
};

const styles = (theme) => ({
    layoutView: {
        border: "1px inset transparent",
        borderRadius: 4,
        '&:hover': {
            border: "1px inset red",
            backgroundColor: "#ff00031c"
        },
        cursor: "pointer"
    }
});

const WaitingForDevice = (props) => {
    const {withLoading, message} = props;
    return (
        <div>
            {withLoading && <Loading size={24}/>}
            <Typography variant={"subtitle2"}
                        color={"primary"}
                        style={{
                            marginTop: 16,
                            fontWeight: 600,
                            backgroundColor: "#3f50b5cc",
                            padding: "4px 12px",
                            borderRadius: 4,
                            color: "#ffffff"
                        }}>
                {message}
            </Typography>
        </div>
    )
};

const TreeView_Styles = (theme) => ({
    header: {
        padding: "3px 6px",
        color: "#66696b",
        display: "inline-block",
        borderRadius: "4px",
        margin: "2px 0px",
        transition: "all 0.1s ease-in-out",
        "&:hover": {
            backgroundColor: "#3f50b5",
            color: "white",
        }
    },
    header_active: {
        background: "rgba(17, 171, 58, 0.42)",
        "&:hover": {
            background: "rgba(17, 171, 58, 0.42)",
            color: "#66696b",
        }
    },
    faIcon: {
        marginRight: 6,
        fontSize: 16,
    }
})

class Header_TreeView extends React.Component {
    render() {
        const {onSelect, node, classes} = this.props;
        return (
            <div
                onClick={onSelect}
                className={classnames([classes.header, node.active && classes.header_active])}
            >
                {
                    !(node.children) &&
                    <i className={classnames([classes.faIcon, "fa fa-dot-circle-o"])}></i>
                }

                {
                    (node.children) &&
                    <i className={classnames([classes.faIcon, `fa fa-caret-${node.toggled ? "down" : "right"}`])}></i>
                }
                {node.name}
            </div>
        )
    }
}

class Toggle_TreeView extends React.Component {
    render() {
        return (
            <div/>
        )
    }
}

Header_TreeView = withStyles(TreeView_Styles)(Header_TreeView)

class SSEDevicePreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            img: null,
            orientation: ORIENTATION_ENUM.PORTRAIT,
            selectedHTMLView: null,
            layout: null,
            treeView: []
        };
        this.onToggle = this.onToggle.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.selectedDevice !== this.props.selectedDevice) {
            if (this.eventSource) {
                this.eventSource.close();
            }
            this.setState({
                img: null,
                selectedHTMLView: null,
                layout: null
            });
            if (window.hasOwnProperty("EventSource")) {
                this.initEventSource(nextProps.selectedDevice);
            } else {
                console.log("EventSource API not available");
            }
        }
    }

    initEventSource = (selectedDevice) => {
        const {appId, imageConfig: {width, height}} = this.props;
        this.eventSource = new EventSource(SSE_API + withQueryStrings({appId}));

        this.eventSource.onmessage = (event) => {
            this.setState({selectedHTMLView: null});

            try {
                const data = JSON.parse(event.data);
                if (data.hasOwnProperty("device_info")) {
                    if (data.device_info.id === selectedDevice) {
                        const {layout, device_info: {width: device_width, height: device_height}, screen: {orientation = ORIENTATION_ENUM.PORTRAIT}} = data;
                        const ratio = getScaleFactors({device_width, device_height}, {
                            image_width: width,
                            image_height: height
                        }, orientation);

                        let treeView = this.getTreeViewForLayout(layout);

                        this.setState({
                            img: "data:image/png;base64," + data.screen.image,
                            orientation: orientation,
                            layout: layoutModifier(layout, ratio),
                            treeView

                        }, () => {
                            this.props.handleChange(data);
                        })
                    }
                }
            } catch (error) {
                console.log(selectedDevice);
                console.error(error);
            }
        }
    };

    componentWillUnmount() {
        // console.log("EventSource connection closed");
        if (this.eventSource)
            this.eventSource.close();
    }

    getTreeViewForLayout = (views, level = 0) => views.map((view, index) => {
        let html_id = !view.path ? `${view.id}-${view.view}-${level}-${index}` : view.path;
        let name = view.id !== "" ? view.id : "View";
        let node = {
            name,
            html_id,
            sdk_id: view.id,
            sdk_path_id: view.path,
            view: view.view,
        };

        if (view.views && view.views.length === 0) {
            return node
        } else {
            return {
                ...node,
                children: this.getTreeViewForLayout(view.views, index),
            }
        }
    })

    onToggle = (node, toggled) => {
        const {cursor, data} = this.state;
        if (cursor) {
            cursor.active = false;
        }
        node.active = true;
        if (node.children) {
            node.toggled = toggled;
        }

        const {sdk_id, sdk_path_id} = node;
        this.setState({
            selectedHTMLView: node.html_id,
        })
        if (sdk_id === "") {
            this.props.handleViewSelect(undefined, sdk_path_id)
        } else {
            this.props.handleViewSelect(sdk_id, undefined)
        }

        this.setState(() => ({cursor: node, data: Object.assign({}, data)}));
    }

    onSelect = (node) => {
        const {cursor, data} = this.state;
        if (cursor) {
            this.setState(() => ({cursor, active: false}));
            if (!includes(cursor.children, node)) {
                cursor.toggled = false;
                cursor.selected = false;
            }
        }
        node.selected = true;
        this.setState(() => ({cursor: node, data: Object.assign({}, data)}));
    }

    render() {
        const {imageConfig: {width, height}, handleViewSelect, selectedDevice, classes} = this.props;
        const {img, layout = null, selectedHTMLView, treeView} = this.state;
        const waitingState = selectedDevice && selectedDevice !== "";
        const EventSourceError = (
            <Typography
                variant={"subtitle2"}
                color={"error"}
                style={{
                    fontWeight: 600,
                    backgroundColor: "#f0516566",
                    padding: "4px 12px",
                    borderRadius: 4
                }}
            >
                Your browser doesn't support this feature.
                <br/>
                Contact <a href={"mailto:support@apxor.com"}>support@apxor.com</a> for help.
            </Typography>
        );

        let computeStyles = (bounds, level, selected) => {
            const {width, height, left, top} = bounds;
            const selectedStyles = {
                border: "1px inset green",
                boxShadow: "0px 0px 5px 0px #545454",
                background: "#11ab3a6b"
            };
            const positionStyle = {
                cursor: "pointer",
                zIndex: level,
                position: "absolute",
                width, height, left, top,
            };
            return ({...positionStyle, ...(selected ? selectedStyles : {})})
        };

        let layoutGenerator = (views, level, parentBounds) => views.map((view, index) => {
            let HTML_ElementId = view.id + '-' + view.view + "-" + level + "-" + index;
            if (view.hasOwnProperty("path")) {
                HTML_ElementId = view.path
            }
            const HTML_ElementStyles = computeStyles({
                ...view.bounds,
                top: view.bounds.top - 1,
                bottom: view.bounds.bottom - 1,
                left: view.bounds.left - 1,
                right: view.bounds.right - 1
            }, level, HTML_ElementId === selectedHTMLView);

            return (
                <Fragment
                    key={HTML_ElementId}
                >
                    <div
                        id={HTML_ElementId}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.setState({
                                selectedHTMLView: HTML_ElementId === selectedHTMLView ? null : HTML_ElementId
                            }, () => {
                                if (view.hasOwnProperty("id") && view.id !== "") {
                                    handleViewSelect(view.id, undefined)
                                } else if (view.hasOwnProperty("path") && view.path !== "") {
                                    handleViewSelect(undefined, view.path)
                                }
                            })
                        }}
                        className={classes.layoutView}
                        style={{...HTML_ElementStyles}}
                    >
                    </div>
                    {(view.views.length > 0) && layoutGenerator(view.views, level + index + 1, view.bounds)}
                </Fragment>
            );
        });

        return (
            <Grid container spacing={16}>
                <Grid item md={6}>
                    <div style={{
                        margin: "auto",
                        backgroundColor: "#ffffff",
                        borderRadius: 16,
                        boxShadow: "0 3px 12px -6px rgba(0, 0, 0, 0.54)",
                        width,
                        height,
                    }}>
                        <div style={{position: "relative", overflow: "hidden"}}>
                            {img && <img style={{
                                borderRadius: 16,
                                width: width,
                                height: height
                            }} src={img} alt={"screen_preview"}/>}
                            {
                                !img && <div style={{
                                    borderRadius: 16,
                                    textAlign: "center",
                                    maxWidth: "80%",
                                    height: height,
                                    margin: 'auto',
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}>
                                    {
                                        window.hasOwnProperty("EventSource")
                                            ? <WaitingForDevice
                                                withLoading={waitingState}
                                                message={!waitingState ? "Please select a Test Device" : "Waiting for Device"}
                                            />
                                            : EventSourceError
                                    }
                                </div>
                            }
                            {
                                layout !== null && layoutGenerator(layout, 1, {top: 0, left: 0, bottom: 0, right: 0})
                            }
                        </div>
                    </div>
                </Grid>
                <Grid item md={6}>
                    <ClassicCard style={{
                        width: "100%",
                        maxHeight: 580
                    }}>
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <ModuleConfigSection
                                title={"Tree View"}
                                typographyStyleProps={{fontWeight: 600, fontSize: 16, marginBottom: 6}}
                            />
                            <BetaChip />
                        </div>
                        {
                            img && <div style={{maxHeight: 516, overflow: "auto"}}>
                                <Treebeard
                                    data={treeView}
                                    onToggle={this.onToggle}
                                    onSelect={this.onSelect}
                                    decorators={{
                                        ...decorators,
                                        Header: Header_TreeView,
                                        Toggle: Toggle_TreeView,
                                    }}
                                    style={{
                                        tree: {
                                            base: {
                                                backgroundColor: "transparent",
                                            },
                                            node: {
                                                activeLink: {
                                                    backgroundColor: "transparent",
                                                    background: "transparent",
                                                }
                                            }
                                        }
                                    }}
                                    customStyles={{
                                        container: {
                                            display: "flex",
                                            flexDirection: "row",
                                            background: "transparent",
                                        },
                                        header: {
                                            title: {
                                                color: 'red'
                                            }
                                        }
                                    }}
                                />
                            </div>
                        }
                        {
                            !img && <div style={{maxWidth: "50%", margin: "auto"}}>
                                <WaitingForDevice
                                    withLoading={waitingState}
                                    message={!waitingState ? "Please select a Test Device" : "Waiting for Device"}
                                />
                            </div>
                        }
                    </ClassicCard>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(SSEDevicePreview);