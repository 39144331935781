/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    APPROVE_APP,
    APPROVE_APP_FAILED,
    APPROVE_APP_PENDING, HIDE_CREATE_APP_DIALOG,
    RESET_APP_APPROVAL,
    RESET_CREATE_APP_DIALOG
} from "./actionTypes";
import * as Actions from './actionTypes';
import {getAppsAPI, addAppAPI, deleteAppAPI, approveAppAPI} from './api';
import {GET_APP} from "./App/actionTypes";
import {appFromList} from "../../../../../utils";

/**
 *
 * @returns {function(*, *)}
 */
export const getApps = (appId = null) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Actions.GET_APPS_PENDING,
                Actions.GET_APPS,
                Actions.GET_APPS_FAILED
            ],
            payload: {
                promise: getAppsAPI(getState().auth)
                .then((res) => {
                    return res || [];
                }),
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, apps = []) => {
                    if (appId) {
                        const app = appFromList(apps, appId);
                        if (app) {
                            dispatch({
                                type: GET_APP,
                                payload: app
                            });
                        } else {
                            //dispatch(getApp(appId)); Not calling since app does't belong to user
                        }
                    }
                }
            }
        });
    };
};

export const approveAppAction = (appId, approverId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                APPROVE_APP_PENDING,
                APPROVE_APP,
                APPROVE_APP_FAILED
            ],
            payload: {
                promise: approveAppAPI(getState().auth, appId, approverId)
                .then((res) => {
                    if (res) {
                        setTimeout(() => {
                            window.location = `/apps/${appId}/integration`;
                        }, 1200)
                    } else {
                        setTimeout(() => {
                            dispatch({
                                type: RESET_APP_APPROVAL,
                                payload: {},
                                meta: {}
                            })
                        }, 2000)
                    }
                })
            },
            meta: {}
        })
    }
};

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const deleteApp = (appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Actions.DELETING_APP,
                Actions.DELETE_APP,
                Actions.DELETE_FAILED
            ],
            payload: {
                promise: deleteAppAPI(getState().auth, appId)
                .then((res) => {
                    return res;
                }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successWillDispatch: (dispatch, res) => {
                    dispatch(getApps());
                }
            }
        });
    };
};

/**
 *
 * @param app
 * @returns {function(*, *)}
 */
export const addApp = (app) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Actions.ADDING_APP,
                Actions.ADD_APP,
                Actions.ADDING_APP_FAILED
            ],
            payload: {
                promise: addAppAPI(getState().auth, app)
                .then((res) => {
                    if (res && res.app_id) { //FIXME: not here actually
                        dispatch(getApps());
                        dispatch({
                            type: HIDE_CREATE_APP_DIALOG,
                            payload: {},
                            meta: {}
                        })
                    }
                    return res;
                }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 * @deprecated
 * @param appId
 * @returns {function(*, *)}
 */
export const enableMonitoring = (appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Actions.MONITORING_PENDING,
                Actions.ENABLE_MONITORING,
                Actions.MONITORING_FAILED
            ],
            payload: {
                promise: getApps(getState().auth)
                .then((res) => {
                    return res;
                }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 * @deprecated
 * @param appId
 * @returns {function(*, *)}
 */
export const disableMonitoring = (appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                Actions.MONITORING_PENDING,
                Actions.DISABLE_MONITORING,
                Actions.MONITORING_FAILED
            ],
            payload: {
                promise: getApps(getState().auth)
                .then((res) => {
                    return res;
                }),
            },
            meta: {
                //If Any
            }
        });
    };
};
