import React from 'react';
import PropTypes from 'prop-types';
import {
    Area, XAxis, YAxis,
    CartesianGrid, Tooltip, ResponsiveContainer,
    ComposedChart, Line, ReferenceDot, ReferenceLine,
    Bar, Brush, Cell,
} from 'recharts';
import moment from 'moment';
import {
    capitalizeEachWord, removeCharsWith,
    randomColorWithIndex, onlyAlphaNumericChars, formatNumber, sortArrayByKey, roundOffNumber
} from '../../../utils';
import Box from '../Box';
import Checkbox from "../MaterialUi/Checkbox";

const defaultXLabelFormatter = (key) => moment(key).format("MMM DD");

class CustomToolTip extends React.Component{
    render(){
        const {
            active, units,  payload, label,  valueLabel,
            xLabelFormatter, isXKeyDate = true, showAverage = false,
            colors
        } = this.props;
        if(active)
            return(
                <div className="recharts-default-tooltip" style={{margin: "0px", padding: "10px", border: "1px solid rgb(204, 204, 204)", whiteSpace: "nowrap"}}>
                    <p className="recharts-tooltip-label" style={{margin: "0px"}}>{isXKeyDate ? (xLabelFormatter ? xLabelFormatter(label) : defaultXLabelFormatter(label)) : label }</p>
                    <ul className="recharts-tooltip-item-list" style={{padding: "0px", margin: "0px"}}>
                        {
                            Array.isArray(payload) && sortArrayByKey(payload.map((o, i) => ({...o, color: randomColorWithIndex(i, colors)})), "value", true).map((item, index) => {
                                return(
                                    <li className="recharts-tooltip-item" key={`li${index}`} style={{display: "block", paddingTop: "4px", paddingBottom: "4px", color: item.color}}>
                                        <span className="recharts-tooltip-item-name">{ item.name  || (valueLabel || capitalizeEachWord(removeCharsWith(item.name, '_', ' ')))}</span>
                                        <span className="recharts-tooltip-item-separator"> : </span>
                                        <span className="recharts-tooltip-item-value">{ roundOffNumber(item.value) + ' ' + (showAverage ? `(${item.payload["avg_" + item.name]})` : '')}</span>
                                        <span className="recharts-tooltip-item-unit"> {units} {item.payload[`${item.name}-users`] && <string>({item.payload[`${item.name}-users`]} Users)</string>}</span>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            );
        else return <span/>
    }
}

class CustomLabel extends React.Component {
    render () {
        const {x, y, fill, value} = this.props;
        return <text
            x={x}
            y={y}
            dy={-4}
            fontFamily='sans-serif'
            fill={fill}
            textAnchor="middle">{value}</text>
    }
}

class ReChartComposedChart extends React.Component {

    state = {
        currentIndex: 0
    };

    componentWillReceiveProps(nextProps){
        const { data } = this.props;
        const { data: newData } = nextProps;
        let isDifferent = false;
        if(Array.isArray(data) && Array.isArray(newData)){
            isDifferent = newData.length !== data.length;
            if(!isDifferent && newData.length > 0){
                isDifferent = newData[0].value !== data[0].value;
            }
        }
        if(isDifferent){
            this.setState({currentIndex: 0});
        }
    }

    render () {
        const {
            layout = "horizontal", data, height, dataKey, yDataKey,
            lineDataKeys = [], barDataKeys = [], areaDataKeys = [],
            xLabelFormatter, dot = true, syncId = null,
            showBrush = false, connectNulls = false,
            xLabel = undefined, yLabel = undefined,
            showAggregations = false, stats = {},
            clickHandler, showLabels = false,
            xType, yType, colors, maxBrushLength = 9
        } = this.props;
        const { currentIndex } = this.state;
        const clickable = typeof clickHandler === 'function';
        return (
            <ResponsiveContainer height={height || 300}>
                <ComposedChart
                    layout={layout}
                    data={data}
                    margin={{top: 5, right: 10, bottom: 25, left: 10}}
                    syncId={syncId}
                    onClick={(payload, event) => {
                        /*if(payload && clickable) {
                            clickHandler(payload.activeLabel, event.currentTarget, payload);
                        }*/
                    }}
                >
                    <XAxis
                        dataKey={dataKey || "date"}
                        tickFormatter={xLabelFormatter || defaultXLabelFormatter}
                        label={xLabel ? { value: xLabel, position: "insideBottomRight", dy: -8} : undefined}
                        height={45}
                        type={xType}
                    />
                    <YAxis
                        width={100}
                        tickFormatter={yType === "number" ? number => formatNumber(number) : undefined}
                        label={yLabel ? { value: yLabel, position: "insideLeft", angle: -90,   dy: 40} : undefined}
                        type={yType}
                        dataKey={yDataKey}
                    />
                    <Tooltip content={<CustomToolTip {...this.props}/>}/>
                    {
                        showAggregations && [
                            <ReferenceLine key="avg" y={stats.avg} label={`Avg (${formatNumber(stats.avg)})`} stroke="#82ca9d" strokeDasharray="10 10" />,
                            <ReferenceDot key="max" x={stats.maxKey} y={stats.max} label={`Max (${formatNumber(stats.max)})`} stroke="#82ca9d" fill="#82ca9d"/>,
                            <ReferenceDot key="min" x={stats.minKey} y={stats.min} label={`Min (${formatNumber(stats.min)})`} stroke="#82ca9d" fill="#82ca9d"/>
                        ]
                    }
                    <defs>
                        {
                            areaDataKeys.map((key, index) =>
                                <linearGradient key={"gradient-" + key} id={onlyAlphaNumericChars(key)} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={randomColorWithIndex(index, colors)} stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor={randomColorWithIndex(index, colors)} stopOpacity={0}/>
                                </linearGradient>
                            )
                        }
                    </defs>
                    <defs>
                        {
                            barDataKeys.map((key, index) =>
                                <linearGradient key={"gradient-" + key} id={onlyAlphaNumericChars(key)}
                                                x1={layout === "vertical" ? 1 : 0}
                                                y1={0}
                                                x2={layout === "vertical" ? 0 : 0}
                                                y2={1}
                                >
                                    <stop offset="5%" stopColor={randomColorWithIndex(index, colors)} stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor={randomColorWithIndex(index, colors)} stopOpacity={0}/>
                                </linearGradient>
                            )
                        }
                    </defs>
                    <defs>
                        {
                            lineDataKeys.map((key, index) =>
                                <linearGradient key={"gradient-" + key} id={onlyAlphaNumericChars(key)} x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="5%" stopColor={randomColorWithIndex(index, colors)} stopOpacity={0.8}/>
                                    <stop offset="95%" stopColor={randomColorWithIndex(index, colors)} stopOpacity={0}/>
                                </linearGradient>
                            )
                        }
                    </defs>
                    <CartesianGrid stroke='#f5f5f5'/>
                    {
                        areaDataKeys.map((areaKey, index) => (
                            <Area
                                type='monotone'
                                key={ `area${index}` }
                                dataKey={areaKey}
                                fillOpacity={1}
                                fill={`url(#${onlyAlphaNumericChars(areaKey)})`}
                                stroke={randomColorWithIndex(index, colors)}
                                label={showLabels}
                            />
                        ))
                    }
                    {
                        barDataKeys.map((barKey, index) => (
                            <Bar dataKey={barKey} key={ `bar${index}` }
                                 fillOpacity={1}
                                 label={showLabels ? <CustomLabel/> : false}
                                 /*stroke={randomColorWithIndex(index, colors)}*/
                                 onClick={(d, index, event) => {
                                     if(clickable) {
                                         let updatedIndex = 0;
                                         let label = null;
                                         if(currentIndex !== index + 1){
                                            updatedIndex = index + 1;
                                             label = d[dataKey]
                                         }
                                         this.setState({currentIndex: updatedIndex});
                                         clickHandler(label, event.currentTarget, d);
                                     }
                                 }}
                            >
                                {
                                    data.map((entry, i) => (
                                        <Cell key={barKey + index + i} cursor={clickable ? "pointer" : "default"} fill={i === currentIndex - 1 ? randomColorWithIndex(index, colors) : `url(#${onlyAlphaNumericChars(barKey)})`}/>
                                    ))
                                }
                            </Bar>
                        ))
                    }
                    {
                        lineDataKeys.map((lineKey, index) => (
                            <Line
                                connectNulls={connectNulls}
                                type='monotone'
                                key={ `line${index}` }
                                dataKey={lineKey}
                                fillOpacity={1}
                                fill={randomColorWithIndex(index, colors)}
                                stroke={randomColorWithIndex(index, colors)}
                                dot={dot}
                                label={showLabels}
                                style={{strokeWidth: 2}}
                            />
                        ))
                    }
                    {showBrush && <ReferenceLine y={0} stroke='#000'/>}
                    {
                        showBrush && data.length > maxBrushLength && <Brush
                            dataKey={dataKey}
                            height={30}
                            stroke="#429ef4"
                            tickFormatter={xLabelFormatter}
                            endIndex={maxBrushLength + 1}
                        />
                    }
                </ComposedChart>
            </ResponsiveContainer>
        );
    }
}

export default class MultiUtilityChart extends React.Component {

    state = {
        showAggregations: false,
    };

    render () {
        const {
            withHeader = false, title, data = [], withoutAggregations = false,
            stats = {},
            icon, withPadding, headerWithBorder, collapsible, defaultExpanded = false
        } = this.props;
        const { showAggregations } = this.state;
        if(Array.isArray(data)) {
            if (withHeader) {
                return (
                    <Box
                        collapsible={collapsible}
                        headerWithBorder={headerWithBorder}
                        withPadding={withPadding}
                        title={title}
                        icon={icon}
                        defaultExpanded={defaultExpanded}
                        controls={
                            !withoutAggregations && data.length > 0 && <div style={{marginRight: 40}}>
                                <Checkbox
                                    checked={showAggregations}
                                    handleChange={(showAggregations) => this.setState({showAggregations})}
                                    value="showAggregations"
                                    label="Show Aggregations"
                                />
                            </div>
                        }
                    >
                        <ReChartComposedChart {...this.props} showAggregations={showAggregations} stats={stats}/>
                    </Box>
                )
            } else {
                return <ReChartComposedChart {...this.props}/>
            }
        }else{
            return <span />
        }
    }
}

MultiUtilityChart.propTypes = {
    layout: PropTypes.oneOf(["horizontal", "vertical"]),
    xType: PropTypes.oneOf(["category", "number"]),
    yType: PropTypes.oneOf(["category", "number"]),
    withHeader: PropTypes.bool,
    title: PropTypes.any,
    data: PropTypes.array,
    withoutAggregations: PropTypes.bool,
    lineDataKeys: PropTypes.array,
    barDataKeys: PropTypes.array,
    areaDataKeys: PropTypes.array,
    stats: PropTypes.object,
    height: PropTypes.number,
    dataKey: PropTypes.string,
    yDataKey: PropTypes.string,
    xLabelFormatter: PropTypes.func,
    dot: PropTypes.bool,
    syncId: PropTypes.any,
    showBrush: PropTypes.bool,
    connectNulls: PropTypes.bool,
    xLabel: PropTypes.any,
    yLabel: PropTypes.any,
    headerWithBorder: PropTypes.bool,
    withPadding: PropTypes.bool,
    collapsible: PropTypes.bool,
    clickHandler: PropTypes.func,
    defaultExpanded: PropTypes.bool,
    showAverage: PropTypes.bool,
    showLabels: PropTypes.bool,
    maxBrushLength: PropTypes.number
};