/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React, {Component} from 'react';
import Switch from '../../../../../../../components/reusable/Switch';
import {
     Tooltip
} from 'recharts';
import {Button, Divider, Grid, IconButton, Snackbar, Typography, TextField } from "@material-ui/core";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Loading from "../../../../../../../components/reusable/Loading";
import ToggleSwitch from "../../../../../../../components/reusable/MaterialUi/ToggleSwitch";
import Checkbox from "../../../../../../../components/reusable/MaterialUi/Checkbox";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";

export class CreateAlert extends Component {

    render(){
        const {
            _id, enabled
        } = this.props;
        return(
            <ExpansionPanel expanded={true} onChange={this.handleChange}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Grid container>
                        <Grid item xs>
                            <Typography style={{marginTop: 15}} variant="subtitle1"></Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography style={{marginTop: 15}} variant="caption"></Typography>
                        </Grid>
                        <Grid item xs={2} onClick={(e) => e.stopPropagation()}>
                            <ToggleSwitch
                                handleChange={
                                    enabled =>  this.setState({enabled}, this.handleEdit)}
                                label={`${enabled ? "Disable" : "Enable"} Message`}
                                checked={enabled}
                            />
                        </Grid>
                        <Grid item xs={1} onClick={(e) => e.stopPropagation()}>
                            <div>
                                <Tooltip id={`message-edit-${_id}`} title="Edit this Message" placement="bottom">
                                    <IconButton color="secondary" aria-label="Edit" onClick={() => {
                                        this.setState({inEditMode: false});
                                    }} style={{height: 24}}>
                                        { !true && <EditIcon /> }
                                        { false && <Loading size={30} /> }
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Grid>
                        <Grid item xs={1} onClick={(e) => e.stopPropagation()}>
                            <div>
                                { false && <Snackbar> Deleting UI Failed. Try Again</Snackbar> }
                                <Tooltip id={`message-delete-${_id}`} title="Delete this Message" placement="bottom">
                                    <IconButton color="secondary" aria-label="Delete" onClick={() => {
                                        //deleteMessage(appId, _id);
                                    }}>
                                        { !true && <DeleteIcon /> }
                                        { false && <Loading size={30} /> }
                                    </IconButton>
                                </Tooltip>
                            </div>
                        </Grid>
                    </Grid>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid item xs={12} md>
                            <Typography>Set alert for counts of the below property</Typography>
                            <Switch
                                data={["Segment", "Session", "Event"]}
                                handleChange={() => {}}
                                value={"Segment"}
                            />
                            <Typography>Select segment </Typography>
                            <MultiSelect options={[]}/>
                            <Typography>For the selected Segment, Send alert when count goes </Typography>
                            <Checkbox label="Above"/>
                            <TextField
                                style={{width: 80}}
                                type="number"
                                required
                                placeholder="1000"
                                value={0}
                                /*label="Time"*/
                                onChange={e => this.setState({above: Number(e.target.value)}, this.handleUpdate)}
                                margin="dense"
                            />
                            <br/>
                            <Checkbox label="Below"/>
                            <TextField
                                style={{width: 80}}
                                type="number"
                                required
                                placeholder="1000"
                                value={0}
                                /*label="Time"*/
                                onChange={e => this.setState({below: Number(e.target.value)}, this.handleUpdate)}
                                margin="dense"
                            />
                            <Typography>Frequency of alert </Typography>
                            <Checkbox label="Hourly"/>
                            <Checkbox label="Daily"/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography>Recipients </Typography>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
                <Divider/>
                <ExpansionPanelActions>
                    <Button size="small" variant="contained" color="primary" onClick={this.handleEdit}>Update</Button>
                </ExpansionPanelActions>
            </ExpansionPanel>
        )
    }

}