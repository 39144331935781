/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, {Component, Fragment,} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading} from '../../../Navigation/actions';
import { loadCurrentSettings, updateUninstallSettings } from './actions';
import {UninstallSettings} from './components';
import Grid from "@material-ui/core/Grid";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/ButtonBase';
import ContentCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from '@material-ui/core/Tooltip';
import {copyToClipboard} from "../../../../../../../utils";
import {APP_PLATFORMS, APXOR_APP_DOWNLOAD_LINK} from "../../../../../../../constants";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import AndroidIcon from '@material-ui/icons/Android';
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        sdkSettings: state.sdkSettings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        loadCurrentSettings: (appId) => dispatch(loadCurrentSettings(appId)),
        updateUninstallSettings: (appId, settings) => dispatch(updateUninstallSettings(appId, settings))
    };
}

class SDKSettings extends Component {

    state = {
        copied: false
    };

    componentWillMount(){
        const { appId } = this.props.params;
        this.props.loadCurrentSettings(appId);
        this.props.updateHeading("SDK Settings");
    }

    componentDidMount(){

    }

    componentWillReceiveProps(nextProps){
        if(nextProps.sdkSettings.uninstall_settings_updated){
            const { appId } = this.props.params;
            this.props.loadCurrentSettings(appId); //thu yedhava
        }
    }

    render() {
        const { appState: { app: { basic_info: { icon_url, app_name, platform } } }, params: { appId }} = this.props;
        const isNotWeb = platform !== APP_PLATFORMS.web;
        const isAndroid = platform === APP_PLATFORMS.android || platform === APP_PLATFORMS.omni;
        return (
            <section className="content">
                <Grid container spacing={16} justify="center">
                    <Grid item xs={12} md={isAndroid ? 6 : 8} >
                        <Card>
                            <CardHeader
                                avatar={
                                    <Avatar aria-label="App Icon" style={{background: 'transparent'}}>
                                        <img src={icon_url} alt={app_name} style={{width: '100%', height: '100%'}} />
                                    </Avatar>
                                }
                                action={
                                    <Tooltip id="appId-copy" title={this.state.copied ? 'Copied' : 'Copy AppId'}>
                                        <IconButton
                                            onClick={() => {
                                                this.setState({copied: true}, () => {
                                                    copyToClipboard(appId);
                                                    setTimeout(() => {
                                                        this.setState({copied: false});
                                                    }, 2000);
                                                });
                                            }}
                                            style={{margin: '16px 16px 0 0'}}
                                        >
                                            <ContentCopyIcon color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                }
                                title={appId}
                                subheader="App Id"
                            />
                        </Card>
                        {
                            isNotWeb && <Fragment>
                                <br />
                                <UninstallSettings {...this.props}/>
                            </Fragment>
                        }
                    </Grid>
                    {
                        isAndroid && <Grid item xs={12} md={4}>
                            <Card>
                                <CardHeader
                                    avatar={
                                        <Avatar aria-label="App Icon" style={{background: 'transparent'}}>
                                            <img src={icon_url} alt={app_name} style={{width: '100%', height: '100%'}} />
                                        </Avatar>
                                    }
                                    title="Apxor App"
                                    subheader=""
                                />
                                <CardContent style={{background: 'url(/assets/img/apxorapp.jpg) 100% 100% / 100% 100% no-repeat', padding: 0}}>
                                    <div style={{background: 'rgba(198, 204, 235, 0.9)', padding: 8, color: '#333333'}}>
                                        <ul>
                                            <li>
                                                <Typography variant="subtitle1" color="inherit">
                                                    Download the Apxor app to effortlessly test walkthroughs and in line messages on your own device.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="subtitle1" color="inherit">
                                                    With the Apxor app, add your device as a test device. Then enable the overlay to be shown in <strong>{app_name}</strong> app.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="subtitle1" color="inherit">
                                                    Open <strong>{app_name}</strong> app to see the Apxor overlay. Tap on it and choose the screen you want to create a message on. Your selected screen will reflect on the simulator on the Apxor dashboard.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="subtitle1" color="inherit">
                                                    Now create your design and click on preview to see how it looks like to your users on your own device.
                                                </Typography>
                                            </li>
                                            <li>
                                                <Typography variant="subtitle1" color="inherit">
                                                    You can now publish it and nudge your users in real time!
                                                </Typography>
                                            </li>
                                        </ul>
                                        <Divider style={{margin: '16px 0'}}/>
                                        <Grid container spacing={16}>
                                            <Grid item xs={9}>
                                                <Button variant="contained" color="primary" href={APXOR_APP_DOWNLOAD_LINK} target="_blank">
                                                    <AndroidIcon/>
                                                    &nbsp; Download APK
                                                </Button>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <Tooltip id="apxor-app-download-link-copy" title={this.state.copied ? 'Copied' : 'Copy Download Link'}>
                                                    <IconButton
                                                        onClick={() => {
                                                            this.setState({copied: true}, () => {
                                                                copyToClipboard(APXOR_APP_DOWNLOAD_LINK);
                                                                setTimeout(() => {
                                                                    this.setState({copied: false});
                                                                }, 2000);
                                                            });
                                                        }}
                                                        style={{margin: 8, float: 'right'}}
                                                    >
                                                        <ContentCopyIcon color="primary" />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Divider style={{margin: '16px 0'}}/>
                                        <iframe width="100%" height="240"
                                                src="https://www.youtube-nocookie.com/embed/sIovBCyKO9g?controls=0"
                                                frameBorder="0"
                                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                                allowFullScreen>
                                        </iframe>
                                    </div>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                </Grid>
            </section>
        );
    }
}

SDKSettings.propTypes = {

};

SDKSettings.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SDKSettings);