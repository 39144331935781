/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
export const RETENTION_ENUM = {
    FTU: 'FTU',
    ALL: 'ALL',
    RETURNING:'RETURNING'
};
/**
 * FIXME: separate actionTypes for days, months, granularity
 * @type {string}
 */
export const RETENTION_GRAPH_FTU_PENDING = "RETENTION_GRAPH_FTU_PENDING";
export const RETENTION_GRAPH_FTU = "RETENTION_GRAPH_FTU";
export const RETENTION_GRAPH_FTU_FAILED = "RETENTION_GRAPH_FTU_FAILED";

export const RETENTION_GRAPH_PENDING = "RETENTION_GRAPH_PENDING";
export const RETENTION_GRAPH = "RETENTION_GRAPH";
export const RETENTION_GRAPH_FAILED = "RETENTION_GRAPH_FAILED";

export const RETENTION_GRAPH_RETURN_PENDING = "RETENTION_GRAPH_RETURN_PENDING";
export const RETENTION_GRAPH_RETURN = "RETENTION_GRAPH_RETURN";
export const RETENTION_GRAPH_RETURN_FAILED = "RETENTION_GRAPH_RETURN_FAILED";

export const UNINSTALL_DISTRIBUTION_PENDING = "UNINSTALL_DISTRIBUTION_PENDING";
export const UNINSTALL_DISTRIBUTION = "UNINSTALL_DISTRIBUTION";
export const UNINSTALL_DISTRIBUTION_FAILED = "UNINSTALL_DISTRIBUTION_FAILED";

export const RETENTION_EVENTS_PENDING = "RETENTION_EVENTS_PENDING";
export const RETENTION_EVENTS = "RETENTION_EVENTS";
export const RETENTION_EVENTS_FAILED = "RETENTION_EVENTS_FAILED";

export const RETENTION_EVENT_ATTRIBUTES_PENDING = "RETENTION_EVENT_ATTRIBUTES_PENDING";
export const RETENTION_EVENT_ATTRIBUTES = "RETENTION_EVENT_ATTRIBUTES";
export const RETENTION_EVENT_ATTRIBUTES_FAILED = "RETENTION_EVENT_ATTRIBUTES_FAILED";

export const RETENTION_EVENT_ATTRIBUTE_VALUES_PENDING = "RETENTION_EVENT_ATTRIBUTE_VALUES_PENDING";
export const RETENTION_EVENT_ATTRIBUTE_VALUES = "RETENTION_EVENT_ATTRIBUTE_VALUES";
export const RETENTION_EVENT_ATTRIBUTE_VALUES_FAILED = "RETENTION_EVENT_ATTRIBUTE_VALUES_FAILED";

export const RESET_RETENTION = "RESET_RETENTION";

export const UPDATE_RETENTION_TYPE = "UPDATE_RETENTION_TYPE";
export const UPDATE_RETENTION_USAGE_TYPE = "UPDATE_RETENTION_USAGE_TYPE";

export const RETENTION_UPDATE_QUERY_FIRST_EVENT = "RETENTION_UPDATE_QUERY_FIRST_EVENT";
export const RETENTION_UPDATE_QUERY_NEXT_EVENT = "RETENTION_UPDATE_QUERY_NEXT_EVENT";

export const RETENTION_USAGE_PENDING = "RETENTION_USAGE_PENDING";
export const RETENTION_USAGE = "RETENTION_USAGE";
export const RETENTION_USAGE_FAILED = "RETENTION_USAGE_FAILED";

export const RETENTION_USAGE_COUNT_PENDING = "RETENTION_USAGE_COUNT_PENDING";
export const RETENTION_USAGE_COUNT = "RETENTION_USAGE_COUNT";
export const RETENTION_USAGE_COUNT_FAILED = "RETENTION_USAGE_COUNT_FAILED";

export const RETENTION_DAY_WINDOW = "RETENTION_DAY_WINDOW";