/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React, {Component, Fragment} from 'react';
import {getEventAttributes, getEventAttributeValues, getRetentionData} from "./actions";
import {QueryHolder} from "../../../../../../components/reusable/QueryBuilder/index";
import { GRAPH_DEFAULTS, APP_PLATFORMS, APXOR_SDK_CATEGORY_CONSTANTS} from "../../../../../../constants/index";
import {Button, Grid, Typography} from "@material-ui/core";
import Box from "../../../../../../components/reusable/Box";
import FilterIcon from '@material-ui/icons/FilterList';
import PeopleIcon from '@material-ui/icons/People';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import {RETENTION_ENUM} from "./actionTypes";
import Snackbar from "../../../../../../components/reusable/MaterialUi/Snackbar";
import Switch from "../../../../../../components/reusable/Switch";
import RetentionGraph from "../../../../../../components/plugins/RetentionGraph/index";
import MultiSelect from "../../../../../../components/reusable/MaterialUi/MultiSelect";
import AddToDashboard from '../CustomDashboards/AddToDashboard';
import { RETENTION, RETENTION_USAGE_WIDGET} from "../SelfComponents";
import SegmentConfig, { renderEvent } from "../Segments/SegmentConfig";
import MultiUtilityChart from "../../../../../../components/reusable/Recharts/MultiUtilityChart";
import {DAYS_ENUM_FOR_USAGE} from "../../../../../../constants";
import {formatRetentionUsage, segmentFilterWithoutCounts, getGroupNameFromList} from "../../../../../../utils";
import TextField from "@material-ui/core/TextField/TextField";
import {EventBuilder} from "../Segments/NewSegment/components";
import Apxor from 'apxor';

const SWITCH_DATA = [
    {
        name: "Habit Trend",
        value: "percent",
        help: "% Number of retained users using the selected feature. Feature usage across the user life cycle."
    },
    {
        name: "Habit Strength",
        value: "avg_events",
        help: "Number of times a selected feature is used in user life cycle. How strongly the given feature has become a habit"
    }
];


export class RetentionUsage extends React.Component {

    state = {
        current: SWITCH_DATA[0].value,
        q: ''
    };

    handleSwitch = (current) => {
        this.setState({current});
    };

    addDashboardConfig = () => {
        const {
            data,
            segmentBuilder: { query }
        }  = this.props;
        return {
            queryParams: {
                day: DAYS_ENUM_FOR_USAGE
            },
            extraFilters: segmentFilterWithoutCounts(query)
        }
    };

    render(){
        const {
            data,
            segmentBuilder: { query: {user = [], session = [] } = {}}
        }  = this.props;
        const { current, q } = this.state;
        const filteredData = formatRetentionUsage(data, current, DAYS_ENUM_FOR_USAGE).filter(o => o.event_name.toLowerCase().includes(q));
        return(
            <Box title="Habits"
                 icon={<InsertChartIcon/>}
                 withPadding
                 controls={
                     <div style={{minWidth: 360, display: 'flex', justifyContent: 'space-around'}}>
                         <TextField
                             style={{marginRight: 8}}
                             value={q}
                             onChange={e => this.setState({q: e.target.value})}
                             placeholder="event_name"
                             label="Search Events"
                         />
                         <Switch
                             containerStyles={{ marginTop: 10 }}
                             data={SWITCH_DATA}
                             handleChange={(value, event) => {
                                 Apxor.logEvent("HabitSelected", { type: value }, APXOR_SDK_CATEGORY_CONSTANTS.RETENTION);
                                 this.handleSwitch(value)
                             }}
                             value={current}
                         />
                         {/*<AddToDashboard {...this.props} componentId={RETENTION_USAGE_WIDGET} getComponentParams={this.addDashboardConfig}>*/}
                         {/*    {(user || session) && <SegmentConfig user={user} session={session} event={[]} />}*/}
                         {/*</AddToDashboard>*/}
                     </div>
                 }
            >
                <MultiUtilityChart
                    barDataKeys={DAYS_ENUM_FOR_USAGE.map(d => "Day " + d)}
                    data={filteredData}
                    dataKey="event_name"
                    xLabelFormatter={(e) => e}
                    showBrush
                    maxBrushLength={5}
                    showAverage
                />
            </Box>
        )
    }
}

const RETENTION_SWITCH_DATA = [
    { name: "New Installs", value: RETENTION_ENUM.FTU },
    { name: "All Users", value: RETENTION_ENUM.ALL},
    { name: "Returning Users", value: RETENTION_ENUM.RETURNING}
];

const RETENTION_WINDOWS = [
    {
        name: "Daily",
        value: 1
    },
    {
        name: "Weekly",
        value: 7
    },
    {
        name: "Monthly",
        value: 30
    },
    {
        name: "Custom",
        value: "custom"
    }
]

export class RetentionAnalysis extends React.Component {

    constructor (props) {
        super(props);
        this.appId = this.props.params.appId;
        this.state = {
            switchValue: GRAPH_DEFAULTS.switchValue,
            currentType: RETENTION_ENUM.FTU, //TODO: Fixed till requirements update for PLATFORMS
            retentionWindow: 1,
            dayWindow: 1,
            firstEvent: null,
            nextEvent: null,
            collapseNow: undefined,
            dayWindowText: 15
        };
    }

    // TODO: For now, FTU is fixed
    // handleSwitchValueChange = (switchValue) => {
    //     this.setState({currentType: switchValue});
    //     this.props.updateRetentionType(this.props.params.appId, switchValue);
    // };

    // handleDayWindowChange = (retentionWindow) => {
    //     if(retentionWindow !== "custom")
    //         this.setState({retentionWindow, dayWindow: "predefined"});
    //     else {
    //         this.setState({dayWindow: "custom"})
    //     }
    // };

    handleFirstQueryChange = (firstEvent) => {
        Apxor.logEvent("SelectD0Event", {event: firstEvent}, APXOR_SDK_CATEGORY_CONSTANTS.RETENTION);
        this.setState({firstEvent});
        this.props.updateFirstQuery(this.appId, firstEvent);
    };

    handleNextQueryChange = (nextEvent) => {
        Apxor.logEvent("SelectDNEvent", {event: nextEvent}, APXOR_SDK_CATEGORY_CONSTANTS.RETENTION);
        this.setState({nextEvent});
        this.props.updateNextQuery(this.appId, nextEvent);
    };

    render(){
        const {
            retention, filters, retention: {events, query: {first_event, next_event}},
            getRetentionData, updateFirstQuery, updateNextQuery, resetQuery,
            segmentBuilder: { query: {user = [], session = [] } = {}}, meta: {api_pending = false},
        } = this.props;
        const { currentType, retentionWindow, dayWindow, firstEvent, nextEvent, collapseNow, dayWindowText } = this.state;
        const filtersSelected = (firstEvent && firstEvent.name.length > 0) || (nextEvent && nextEvent.name.length > 0);
        return(
            <Box title="User Retention"
                 icon={<PeopleIcon/>}
                 withPadding
                //  controls={
                //      <Switch containerStyles={{minWidth: 480}} data={RETENTION_SWITCH_DATA} handleChange={this.handleSwitchValueChange} value={currentType}/>
                //  }
                 collapsible
                 defaultExpanded
            >
                {
                    currentType !== RETENTION_ENUM.FTU && (!firstEvent || firstEvent.name.length === 0) &&
                    <Snackbar>Need at least one Event</Snackbar>
                }

                {/* TODO: Limit this feature to only Veooz client */}
                {  <Grid container>
                        <Grid item xs={4}>
                            <Switch
                                disabled={api_pending}
                                data={RETENTION_WINDOWS}
                                handleChange={(retentionWindow) => {
                                    Apxor.logEvent("RetentionType", {type: retentionWindow}, APXOR_SDK_CATEGORY_CONSTANTS.RETENTION);
                                    this.setState({retentionWindow});
                                    if(retentionWindow !== "custom"){
                                        // console.log(retentionWindow);
                                        //Apxor.logEvent("CustomDateRangeSelected", {date_range: }, APXOR_SDK_CATEGORY_CONSTANTS.RETENTION),
                                        this.setState({dayWindow: retentionWindow});
                                        this.props.updateRetentionWindow(retentionWindow);
                                        getRetentionData(this.appId, undefined, this.state.dayWindow);
                                    }
                                }}
                                value={retentionWindow}
                            />
                        </Grid>
                            {
                                retentionWindow === "custom" && <Fragment>
                                    <Grid item xs={1}>
                                        <TextField
                                            value={dayWindowText}
                                            onChange={e => {
                                                const _dayWindow = Number(e.target.value);
                                                if(!isNaN(_dayWindow)){
                                                    this.setState({dayWindowText: _dayWindow});
                                                    this.props.updateRetentionWindow(_dayWindow);
                                                }
                                            }}
                                            fullWidth
                                            label="Day window"
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Button
                                            variant="contained" color="primary"
                                            onClick={e => {
                                                //Apxor.logEvent("ApplyCustomDayRetentionFilter", {date_range: dayWindow}, APXOR_SDK_CATEGORY_CONSTANTS.RETENTION);
                                                this.setState({dayWindow: this.state.dayWindowText})
                                                this.props.updateRetentionWindow(this.state.dayWindowText);
                                                getRetentionData(this.appId, undefined, this.state.dayWindowText);
                                            }}
                                            disabled={dayWindowText < 1 || dayWindowText >= 365 || this.state.dayWindowText === this.state.dayWindow}
                                        >Apply</Button>
                                    </Grid>
                                </Fragment>
                            }
                    </Grid>
                }

                <Box title="Filter by"
                     collapsible
                     icon={<FilterIcon/>}
                     defaultExpanded={currentType !== RETENTION_ENUM.FTU}
                     withPadding
                     collapseNow={collapseNow}
                >
                    <EventBuilder
                        {...this.props}
                        {...first_event}
                        handleUpdate={this.handleFirstQueryChange}
                        title={currentType === RETENTION_ENUM.FTU ? "Users who did" : "Select Event"}
                        clearable
                    />
                    {
                        currentType === RETENTION_ENUM.FTU &&
                        <EventBuilder
                            {...this.props}
                            {...next_event}
                            handleUpdate={this.handleNextQueryChange}
                            title="Came back and did"
                            clearable
                        />
                    }
                    <Grid container justify="flex-end">
                        <Grid item xs style={{display: 'flex', justifyContent: 'flex-end'}}>
                            <Button
                                size="small"
                                color="secondary"
                                onClick={() => {
                                    this.setState({firstEvent: "", nextEvent: ""});
                                    updateFirstQuery(this.appId, {});
                                    updateNextQuery(this.appId, {});
                                    getRetentionData(this.appId);
                                    this.setState({collapseNow: true}, () => this.setState({collapseNow: undefined})); //collapsing box for resetting self contained property filters
                                }}
                                style={{margin: '0 10px'}}
                            >
                                Reset
                            </Button>
                            <Button
                                size="small"
                                color="primary"
                                onClick={() => {
                                    Apxor.logEvent("ApplyRetentionDayFilters", {}, APXOR_SDK_CATEGORY_CONSTANTS.RETENTION);
                                    getRetentionData(this.appId);
                                }}
                            >
                                Apply
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                {/*<Grid container justify="flex-end">*/}
                {/*    <Grid item xs={1}>*/}
                {/*        <AddToDashboard {...this.props} componentId={RETENTION} getComponentParams={() => {*/}
                {/*            return {*/}
                {/*                queryParams: {},*/}
                {/*                extraFilters: {*/}
                {/*                    first_event,*/}
                {/*                    next_event,*/}
                {/*                    user,*/}
                {/*                    session*/}
                {/*                },*/}
                {/*                type: currentType*/}
                {/*            }*/}
                {/*        }}>*/}
                {/*            <SegmentConfig label={RETENTION_SWITCH_DATA[RETENTION_SWITCH_DATA.findIndex(o => o.value === currentType)].name} user={user} session={session} event={[]}/>*/}
                {/*            { filtersSelected &&*/}
                {/*            <span>*/}
                {/*                        <Typography variant="caption">who did event </Typography>*/}
                {/*                { renderEvent(first_event) }*/}
                {/*                {*/}
                {/*                    currentType === RETENTION_ENUM.FTU && <Typography variant="caption">and after first day who did event </Typography>*/}
                {/*                }*/}
                {/*                {*/}
                {/*                    currentType === RETENTION_ENUM.FTU && renderEvent(next_event)*/}
                {/*                }*/}
                {/*                </span>*/}
                {/*            }*/}
                {/*        </AddToDashboard>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}

                <RetentionGraph
                    pending={retention.retention_data_pending}
                    failed={retention.retention_data_failed}
                    data={retention.retention_data}
                    dateDisplayFormat={GRAPH_DEFAULTS.dateDisplayFormat}
                    title="Retention Cohort Analysis"
                    switchData={[]}
                    windowSize={dayWindow}
                    switchValue={this.state.switchValue}
                    dataDisplayFormat={GRAPH_DEFAULTS.dataDisplayFormat}
                    enableTooltip={false}
                    header={retention.retention_header}
                    // handleSwitchValueChange={this.handleSwitchValueChange}
                    filters={filters}
                    handleRetentionTrend={this.handleRetentionTrend}
                />
            </Box>
        )
    }
}