import React, { Component } from 'react';
import MultiUtilityChart from "../../../../../../../components/reusable/Recharts/MultiUtilityChart";
import {getSegmentsRelatedTimeSeries} from "../actions";
import withSelfComponent from './SelfComponent';
import {session} from "../../../../../../../utils/Storage";
import {addedWithAverageKeys, graphDataToCSVString} from "../../../../../../../utils";

class SegmentsTrend extends Component {

    hydrator = (props = this.props) => {
        const { dataKey, queryParams, extraFilters } = props.selfParams;
        const {dispatch, params: {appId}} = props;
        dispatch(getSegmentsRelatedTimeSeries(appId, dataKey, queryParams, extraFilters));
    };

    render(){
        const {
            self, selfParams: {  dataKey, customLabel },
            meta: { api_pending }, parentCompLabel
        } = this.props;
        let { data = [], segmentNames = [] } = self[dataKey] || {};
        if(!api_pending) {
            let obj = {};
            obj[customLabel] = graphDataToCSVString(customLabel, data);
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        data = addedWithAverageKeys(data, segmentNames);
        const stats = {};//minMaxAverageOf(data, trendDataKey);
        return <MultiUtilityChart
            {...this.props}
            title={customLabel}
            height={segmentNames.length > 5 ? 400 : 300}
            withHeader
            dataKey="key"
            yLabel="Users"
            xLabel="Date"
            data={ data }
            lineDataKeys={segmentNames}
            stats={stats}
            withoutAggregations
            fullWidth
            withPadding
            showAverage
        />
    };
}

SegmentsTrend.propTypes = {

};

export default withSelfComponent(SegmentsTrend);