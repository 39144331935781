/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React from 'react';
import ToggleSwitch from '../../../../../../../components/reusable/MaterialUi/ToggleSwitch';
import Dialog from '../../../../../../../components/reusable/MaterialUi/Dialog';
import Box from "../../../../../../../components/reusable/Box";
import SettingsIcon from '@material-ui/icons/Settings';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "@material-ui/core/Button";
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";

export class UninstallSettings extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            uninstall_settings: {},
            dialog: false,
            confirm: false,
            changed: false
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            uninstall_settings: nextProps.sdkSettings.uninstall_settings,
            changed: false
        });
    }

    areSettingsChanged = () => {
        const state = this.state.uninstall_settings;
        const props = this.props.sdkSettings.uninstall_settings;
        if(state.tracking !== props.tracking){
            return true;
        }
        for(let i = 0; i < state.versions.length; i++){
             if(state.versions[i].tracking !== props.versions[i].tracking){ //FIXME: assuming both are indexed as same
                 return true;
             }
        }
        return false;
    };

    handleToggle = (index, item) => {
        //const newItem = {...item};
        //newItem.tracking = !item.tracking;
        const versions = [...this.state.uninstall_settings.versions];
        versions[index].tracking = !item.tracking;
        this.setState({
            uninstall_settings : {
                ...this.state.uninstall_settings,
                versions//[...versions.slice(0, index), newItem, ...versions.slice(index+1)]
            }
        }, () => {
            this.setState({changed: this.areSettingsChanged()});
        });
    };

    handleMasterTrackingChange = (e) => {
        this.setState({
            uninstall_settings : {
                ...this.state.uninstall_settings,
                tracking : !this.state.uninstall_settings.tracking
            }
        }, () => {
            this.setState({changed: this.areSettingsChanged()});
        });
    };

    handleSubmit = (e) => {
        this.setState({dialog: true});
    };

    handleConfirm = (confirmed) => {
        this.setState({dialog: false}, () => {
            if(confirmed){
                this.props.updateUninstallSettings(this.props.params.appId, this.state.uninstall_settings);
            }else{ //FIXME: may be not required here resetting
                this.setState({
                    uninstall_settings: this.props.sdkSettings.uninstall_settings,
                    changed: false
                });
            }
        });
    };

    render(){
        const {uninstall_settings_updated, update_uninstall_settings_failed} = this.props.sdkSettings;
        const { uninstall_settings: { versions, tracking = false }, changed, dialog} = this.state;
        return(
            <Box icon={<SettingsIcon/>}
                 title="Uninstall Tracking"
                 controls={
                     <ToggleSwitch
                         handleChange={this.handleMasterTrackingChange}
                         value={"All"}
                         checked={tracking}
                     />
                 }
                 footer={
                     changed && <div>
                         <Button variant="contained" color="primary" onClick={this.handleSubmit}>Save Settings</Button>
                         <Dialog
                             title="This will change settings of Uninstall Tracking in your App. Are you sure ?"
                             status={dialog}
                             handleConfirm={this.handleConfirm}
                             handleClose={this.handleConfirm}
                             isConfirm={true}
                         />
                     </div>
                 }
            >
                {
                    uninstall_settings_updated && <Snackbar>
                        Settings saved successfully..!
                    </Snackbar>
                }
                {
                    update_uninstall_settings_failed && <Snackbar>
                        Saving settings failed. Try again..!
                    </Snackbar>
                }
                {
                    versions && versions.length > 0 &&
                    <List>
                        {
                            versions.map((item, index) => {
                                return (
                                    <ListItem key={item.version + index} divider>
                                        <ListItemText primary={<strong>{ item.version }</strong>} />
                                        <ListItemSecondaryAction>
                                            <ToggleSwitch
                                                handleChange={(e) => this.handleToggle(index, item)}
                                                value={item.version}
                                                checked={item.tracking && tracking}
                                                disabled={!this.state.uninstall_settings.tracking}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })
                        }
                    </List>
                }
            </Box>
        )

    }

}