/**
 * Created by kodanda_rama on 2/1/17.
 */
import React, {Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetPassword, resetAuthReducer } from '../actions';
import ResetPasswordForm from '../components/ResetPassword';

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetPassword: (email, password, token, host) => dispatch(resetPassword(email, password, token, host)),
        resetAuth : () => dispatch(resetAuthReducer())
    };
}

class ResetPassword extends Component {

    componentWillMount(){
        this.props.resetAuth();
        const { user, token } = this.props.location.query;
        if(!user || !token){
            this.context.router.push('/login'); //invalid link redirect to login
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.reset_password) {
            window.setTimeout(() => this.context.router.push('/login'), 3000); //sending to login after 3 seconds
        }
    }

    componentWillUnmount(){
        this.props.resetAuth();
    }

    handleResetPassword = (password) => {
        const { user, token } = this.props.location.query;
        this.props.resetPassword(user, password, token);
    };

    render(){
        return (
            <ResetPasswordForm handleResetPassword={ this.handleResetPassword } {...this.props.auth}/>
        );
    }
}

ResetPassword.contextTypes = {
    router: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);