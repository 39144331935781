/**
 * Created by Rakesh Peela
 * Date: 12-Aug-2019
 * Time: 6:22 PM
 */

import {Typography} from "@material-ui/core";
import React from 'react';
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import LockIcon from '@material-ui/icons/Lock';
import Tooltip from "@material-ui/core/Tooltip";
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from "@material-ui/core/CardHeader";
import CardActions from "@material-ui/core/CardActions";
import Button from '@material-ui/core/Button';
import {Link} from "react-router";
import {fetchPendingAppApprovalsAction} from "./actions";

export class ProfileSettings extends React.Component {
    constructor(props) {
        super(props);
        const {auth: {user: {name, email}}} = this.props;
        this.state = {
            defaultState: {
                name: name,
                email: email,
            },
            profileFields: {
                name: "",
                email: "",
            }
        }
    }

    componentWillMount() {
        const {auth: {user: {name, email}}} = this.props;
        this.setState({
            profileFields: {
                name: name,
                email: email,
            }
        })
    }

    handleUserFormChange = (event) => {
        const field = event.target.name;
        const value = event.target.value;
        if (field !== "email") {
            this.setState({
                profileFields: {
                    ...this.state.profileFields,
                    [field]: value
                }
            })
        }
    };

    checkProfileFormChanged = (defaultFormData, {email, name}) => {
        return !(email.trim() === defaultFormData.email.trim() && name.trim() !== defaultFormData.name.trim())
    };

    render() {
        const {updateUserProfileInfo} = this.props;
        const {profileFields: {name = "", email = ""} = {}, defaultState = {}, profileFields = {}} = this.state;
        const enableProfileUpdate = this.checkProfileFormChanged(defaultState, profileFields);
        return (
            <Card>
                <CardHeader title={"Profile Settings"}
                            titleTypographyProps={{
                                style: {
                                    fontSize: '1rem',
                                    fontWeight: "bolder",
                                    marginTop: 9,
                                    marginBottom: 9
                                }
                            }}
                            style={{borderBottom: '1px solid #e1e1e1'}}
                            action={<div/>}
                />
                <form noValidate autoComplete={"off"} onChange={this.handleUserFormChange}>
                    <CardContent>
                        <Grid container spacing={8}>
                            <Grid item xs={12}>
                                <Grid container spacing={8}>
                                    <Grid item xs={6}>
                                        <TextField variant="outlined"
                                                   label={"Name"}
                                                   name={"name"}
                                                   fullWidth
                                                   margin={"normal"}
                                                   defaultValue={name}/>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField variant="outlined"
                                                   label={"E-mail Address"}
                                                   fullWidth
                                                   margin={"normal"}
                                                   name={"email"}
                                                   disabled
                                                   defaultValue={email}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions style={{borderTop: '1px solid #e1e1e1'}}>
                        <Button variant="outlined"
                                color={"primary"}
                                style={{margin: 12}}
                                onClick={() => {
                                    updateUserProfileInfo({
                                        "name": name,
                                        "userId": email
                                    })
                                }}
                                disabled={enableProfileUpdate}>
                            Save Changes
                        </Button>
                    </CardActions>
                </form>
            </Card>
        )
    }
}

const DEFAULT_PASS_STATE = {
    showNewPassword: false,
    showOldPassword: false,
    newPassword: "",
    OldPassword: ""
};

export class PasswordEditSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_PASS_STATE,
            passChangeEnabled: false
        }
    }

    handlePasswordInputChange = (inputType, value) => {
        this.setState({
            [inputType]: value
        })
    };

    handleClickShowNewPassword = () => {
        this.setState({
            showNewPassword: !this.state.showNewPassword
        });
        setTimeout(() => this.setState({
            showNewPassword: !this.state.showNewPassword
        }), 1000);
    };

    handleClickShowOldPassword = () => {
        this.setState({
            showOldPassword: !this.state.showOldPassword
        });
        setTimeout(() => this.setState({
            showOldPassword: !this.state.showOldPassword
        }), 1000);
    };

    render() {
        const {showNewPassword, showOldPassword, newPassword, OldPassword, passChangeEnabled} = this.state;
        const {updatePassword, auth: {user: {email = ""}}} = this.props;
        return (
            <Card>
                <CardHeader title={"Change Password"}
                            titleTypographyProps={{style: {fontSize: '1rem', fontWeight: "bolder"}}}
                            action={
                                <Tooltip
                                    title={passChangeEnabled ? "Lock Section" : "Unlock Section"}
                                    aria-label={passChangeEnabled ? "Lock Section" : "Unlock Section"}>
                                    <IconButton onClick={() => {
                                        this.setState({
                                            passChangeEnabled: !this.state.passChangeEnabled
                                        })
                                    }}>
                                        {passChangeEnabled ? <LockOpenIcon color={"primary"}/> :
                                            <LockIcon color={"error"}/>}
                                    </IconButton>
                                </Tooltip>

                            }
                            style={{borderBottom: '1px solid #e1e1e1', fontSize: '1rem'}}/>
                <form noValidate autoComplete={"off"}>
                    <CardContent style={{backgroundColor: passChangeEnabled ? "" : "#e1e1e1"}}>
                        <Grid container spacing={8}>
                            <Grid item xs={6}>
                                <TextField variant="outlined" label={"Old Password"}
                                           type={showOldPassword ? "text" : "password"}
                                           value={OldPassword}
                                           disabled={!passChangeEnabled}
                                           onChange={(e) => this.handlePasswordInputChange("OldPassword", e.target.value)}
                                           InputProps={{
                                               endAdornment: <InputAdornment position="end">
                                                   {OldPassword !== "" && <IconButton
                                                       aria-label="Toggle password visibility"
                                                       onClick={this.handleClickShowOldPassword}
                                                   >
                                                       {showOldPassword ? <Visibility/> :
                                                           <VisibilityOff/>}
                                                   </IconButton>}
                                               </InputAdornment>
                                           }}
                                           fullWidth margin={"normal"}/>
                            </Grid>
                            <Grid item xs={6}>
                                <TextField variant="outlined" label={"New Password"}
                                           type={showNewPassword ? "text" : "password"}
                                           disabled={!passChangeEnabled}
                                           value={newPassword}
                                           onChange={(e) => this.handlePasswordInputChange("newPassword", e.target.value)}
                                           InputProps={{
                                               endAdornment: <InputAdornment position="end">
                                                   {newPassword !== "" && <IconButton
                                                       aria-label="Toggle password visibility"
                                                       onClick={this.handleClickShowNewPassword}
                                                   >
                                                       {showNewPassword ? <Visibility/> :
                                                           <VisibilityOff/>}
                                                   </IconButton>}
                                               </InputAdornment>
                                           }}
                                           fullWidth margin={"normal"}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions style={{borderTop: '1px solid #e1e1e1'}}>
                        <Button variant="outlined" color={"primary"} style={{margin: 12}}
                                disabled={(newPassword === "") || (OldPassword === "") || (newPassword === OldPassword)}
                                onClick={() => {
                                    updatePassword({
                                        "userId": email,
                                        "oldPassword": OldPassword,
                                        "newPassword": newPassword
                                    }).then(() => {
                                        this.setState({
                                            ...this.state,
                                            ...DEFAULT_PASS_STATE
                                        })
                                    })
                                }}
                        >
                            Update Password
                        </Button>
                    </CardActions>
                </form>
            </Card>
        )
    }
}

export class PendingAppsApproval extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const {userId} = this.props;
        this.props.boundDispatch(fetchPendingAppApprovalsAction(userId))
    }

    render() {
        const {pendingApps = []} = this.props;
        return (
            <div>
                <div style={{textAlign: "center"}}>
                    <Typography variant={"h5"} style={{fontWeight: 600, margin: 16}}>
                        Pending App Approvals
                    </Typography>
                </div>
                <Grid container spacing={32}>
                    {
                        pendingApps.map(app => {
                            const {
                                basic_info: {
                                    customers,
                                    icon_url,
                                    approved,
                                    approved_by,
                                    ...otherInfoKeys
                                },
                                basic_info
                            } = app;
                            return <Grid items xs={6}>
                                <Card key={app.app_id} style={{margin: 16}}>
                                    <CardContent>
                                        <Grid container spacing={32}>
                                            {
                                                Object.keys(otherInfoKeys).map((key, idx) => {
                                                    return <Grid item xs={6} key={idx} style={{padding: 16}}>
                                                        <div style={{fontWeight: 600, paddingRight: 12}}>
                                                            {key.replace("_", " ").toUpperCase()}
                                                        </div>
                                                        <div>
                                                            {basic_info[key]}
                                                        </div>
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    </CardContent>
                                    <CardActions style={{borderTop: "1px solid #cecece"}}>
                                        <div style={{display: "flex", justifyContent: "flex-end", width: "100%"}}>
                                            <Link to={`/apps/${app.app_id}/approve`}>
                                                <Button variant={"flat"} color={"primary"}>
                                                    Goto Approval Page >
                                                </Button>
                                            </Link>
                                        </div>
                                    </CardActions>
                                </Card>
                            </Grid>
                        })
                    }
                </Grid>
            </div>
        );
    }
}