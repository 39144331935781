/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading } from '../../Navigation/actions';
import {
    getSurveys, deleteSurvey, resetSurveys, publishSurvey, updateCurrentSurvey,
    updateSurvey
} from './actions';
import {SurveysHolder} from "./components";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        filters: state.filters,
        meta: state.meta,
        surveys: state.surveys
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getSurveys: (appId) => dispatch(getSurveys(appId)),
        deleteSurvey: (appId, surveyId) => dispatch(deleteSurvey(appId, surveyId)),
        publishSurvey: (appId, surveyId) => dispatch(publishSurvey(appId, surveyId)),
        updateSurvey: (appId, surveyId, survey) => dispatch(updateSurvey(appId, surveyId, survey)),
        updateCurrentSurvey: (survey) => dispatch(updateCurrentSurvey(survey)),
        resetSurveys: () => dispatch(resetSurveys())
    };
}

class Surveys extends Component {

    componentWillMount(){
        const { params: { appId }, updateHeading, getSurveys } = this.props;
        updateHeading("Surveys");
        getSurveys(appId);
    }

    componentWillUnmount(){
        //this.props.resetSurveys(); FIXME: to keep surveys hydrated
    }

    render() {
        return (
            <section className="content">
                <SurveysHolder {...this.props} context={this.context} />
            </section>
        );
    }
}

Surveys.propTypes = {

};

Surveys.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Surveys);