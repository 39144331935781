/**
 * Created by Rakesh Peela
 * Date: 01-Jan-2020
 * Time: 1:34 PM
 */
import {PUBLISH_FOR_PREVIEW, PUBLISH_FOR_PREVIEW_PENDING, PUBLISH_FOR_PREVIEW_SUCCESS} from "./actionTypes";

const INITIAL_STATE = {
    preview_failed: false,
    preview_pending: false,
    preview_success: true,
};

function designPreviewReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case PUBLISH_FOR_PREVIEW_PENDING: {
            return {
                ...state,
                preview_pending: true,
            }
        }
        case PUBLISH_FOR_PREVIEW_SUCCESS: {
            return {
                ...state,
                preview_success: true,
            }
        }
        case PUBLISH_FOR_PREVIEW:
            return {
                ...state,
            };
        default:
            return state;
    }
}

export default designPreviewReducer;