/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    USER_PROFILES_PENDING, USER_PROFILES, USER_PROFILES_FAILED,
    USER_SEARCH_ATTRIBUTES,UPDATE_USER_EXPLORER_GROUP, UPDATE_USER_EXPLORER_SEGMENT,
    UPDATE_USER_EXPLORER_FILTERS, UPDATE_USER_EXPLORER_SEARCH_FIELD, UPDATE_USER_EXPLORER_QUERY_TERM,
     USER_SEARCH_ATTRIBUTE_VALUES
} from './actionTypes';
import {RESET_ALL} from '../../../../../../../constants/ActionTypes';
import {USER_GROUP_ENUM} from "../../../../../../../constants";

const INITIAL_STATE = {
    group: USER_GROUP_ENUM.ALL_USERS,
    field: null,
    field_values: {},
    q: "",
    user: [],
    session: [],
    segmentId: null,
    user_profiles: [],
    user_profile_search_attributes: [],
};

const USER_PROFILES_FLAG_STATE = {
    user_profiles_pending: false,
    user_profiles_failed: false
};

function userExplorerReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case USER_PROFILES_PENDING:
            return {
                ...state,
                ...USER_PROFILES_FLAG_STATE,
                user_profiles_pending: true
            };

        case USER_PROFILES:
            return {
                ...state,
                ...USER_PROFILES_FLAG_STATE,
                user_profiles: action.payload
            };

        case USER_PROFILES_FAILED:
            return {
                ...state,
                ...USER_PROFILES_FLAG_STATE,
                user_profiles_failed: true
            };

        case USER_SEARCH_ATTRIBUTES:
            return {
                ...state,
                user_profile_search_attributes: action.payload
            };

        case UPDATE_USER_EXPLORER_QUERY_TERM:
            return {
                ...state,
                q: action.payload
            };

        case UPDATE_USER_EXPLORER_SEARCH_FIELD:
            return {
                ...state,
                field: action.payload
            };

        case UPDATE_USER_EXPLORER_GROUP:
            return {
                ...state,
                group: action.payload
            };

        case UPDATE_USER_EXPLORER_SEGMENT:
            return {
                ...state,
                segmentId: action.payload
            };

        case UPDATE_USER_EXPLORER_FILTERS:
            return {
                ...state,
                ...action.payload
            };

        case USER_SEARCH_ATTRIBUTE_VALUES:
            return {
                ...state,
                field_values: {
                    ...state.field_values,
                    [action.meta.field]: action.payload
                }
            };

        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default userExplorerReducer;