/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

export const UNINSTALL_SETTINGS_PENDING = "UNINSTALL_SETTINGS_PENDING";
export const UNINSTALL_SETTINGS = "UNINSTALL_SETTINGS";
export const UNINSTALL_SETTINGS_FAILED = "UNINSTALL_SETTINGS_FAILED";

export const UPDATE_UNINSTALL_SETTINGS_PENDING = "UPDATE_UNINSTALL_SETTINGS_PENDING";
export const UPDATE_UNINSTALL_SETTINGS = "UPDATE_UNINSTALL_SETTINGS";
export const UPDATE_UNINSTALL_SETTINGS_FAILED = "UPDATE_UNINSTALL_SETTINGS_FAILED";

export const RESET_UNINSTALL_SETTINGS = "RESET_UNINSTALL_SETTINGS";
