import React, { Component } from 'react';
import {getGlobalRelatedTimeSeries} from '../actions';
import {
    graphDataToCSVString, minMaxAverageOf, addedWithAverageKeys
} from "../../../../../../../utils";
import {CONDTIONAL_OPERATORS, COUNT_TYPE_ENUM} from "../../../../../../../constants";
import MultiUtilityChart from "../../../../../../../components/reusable/Recharts/MultiUtilityChart";
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import withSelfComponent from './SelfComponent';
import {session} from "../../../../../../../utils/Storage";
import {eventRelatedTimeSeriesAPI} from "../../../../../../../api";
import {LOGICAL_OPERATORS} from "../../Messages/actionTypes";

const DAYS = [1, 2, 3, 4, 5, 6, 7, 15, 30, 45, 60, 90].map(i => ({label: `Day ${i}`, value: i}));

class RetentionTrend extends Component {

    constructor(props){
        super(props);
        this.state = {
            groupBy: null,
            showAll: false,
            days: [ 1 ]
        };
    }

    daysChanged = false;

    hydrator = (props = this.props) => {
        const {
             dataKey,
            queryParams, extraFilters
        } = props.selfParams;
        const {dispatch, params: {appId}, auth} = props;
        const { days = [] } = this.state;
        const {  users } = COUNT_TYPE_ENUM;
        let extendedQueryParams = {...queryParams, of: users};
/*        if(groupBy){
            extendedQueryParams = {...extendedQueryParams, groupBy}
        }*/
        let userMap = days.map(eachDay => {
            return {name: "apx_day", value: eachDay, op: LOGICAL_OPERATORS.EQ}
        });

        const updatedExtraFilters = {
            ...extraFilters,
            user: userMap
        };
        dispatch(eventRelatedTimeSeriesAPI(appId, auth, extendedQueryParams, extraFilters));
    };

    render(){
        const {
            self, selfParams: { dataKey, customLabel},
            appState: { attributes: { user = [], session: sessionAttributes = [], event: eventAttributes = [] } = {} },
            meta: { api_pending }, parentCompLabel
        } = this.props;
        let trendDataKey = "value";
        let dataKeys = [ trendDataKey ];
        const properties = [ ...user, ...sessionAttributes, ...eventAttributes ];
        const yLabel = 'Retention Percent';
        const { groupBy, days } = this.state;
        let data = self[dataKey] || [];
        if(Array.isArray(data) && data.length > 0){
            dataKeys = Object.keys(data[0]).filter(o => o !== "key");
        }
        if(!api_pending){
            let obj = {};
            obj[customLabel] = graphDataToCSVString(customLabel, data);
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        data = addedWithAverageKeys(data, dataKeys);
        const stats = minMaxAverageOf(data, trendDataKey);
        return (
            <div style={{position: 'relative', width: '100%'}}>
                {days.length === 0 && <Snackbar>Need atleast one day.</Snackbar>}
                {
                    Array.isArray(data) && data.length > 0 && <div style={{
                        position: 'absolute',
                        zIndex: 3,
                        right: 250,
                        minWidth: 400,
                        display: 'flex'
                    }}>
                        <MultiSelect
                            fullWidth={false}
                            withCheckbox
                            options={DAYS}
                            handleChange={days => {
                                let tempGroupBy = groupBy;
                                if(days.length > 1){
                                    tempGroupBy = null;
                                }
                                this.daysChanged = true;
                                this.setState({days, groupBy: tempGroupBy});
                            }}
                            value={days}
                            label="Retained Day"
                            placeholder="Retained Day"
                            margin="dense"
                            valueRenderer={value => value.map(i => `D${i}`).join(', ')}
                            style={{marginRight: 8, width: '50%'}}
                            handleApply={days => { //FIXME: not sure with this
                                if(this.daysChanged){
                                    this.setState({days}, this.hydrator);
                                    this.daysChanged = false;
                                }
                            }}
                        />
                        {/*
                            (groupBy || days.length > 0) && <Checkbox
                                checked={showAll}
                                handleChange={(showAll) => this.setState({showAll})}
                                value="showAll"
                                label="All"
                            />
                        */}
                        {/*<MultiSelect
                            options={properties.map(o => ({label: o, value: o}))}
                            handleChange={groupBy => {
                                this.setState({groupBy}, this.hydrator);
                            }}
                            value={groupBy}
                            placeholder="Group By Property"
                            single
                            style={{width: '50%'}}
                            margin="normal"
                            disabled={days.length > 1}
                        />*/}
                    </div>
                }
                <MultiUtilityChart
                    {...this.props}
                    title={customLabel}
                    height={300}
                    withHeader
                    dataKey="key"
                    yLabel={yLabel}
                    xLabel="Date"
                    data={ data }
                    lineDataKeys={dataKeys}
                    stats={stats}
                    withoutAggregations={dataKeys.length > 1}
                    fullWidth
                    showAverage
                />
            </div>
        )
    };
}

RetentionTrend.propTypes = {

};

export default withSelfComponent(RetentionTrend);