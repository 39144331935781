/**
 * Created by Rakesh Peela
 * Date: 13-Nov-2019
 * Time: 11:30 AM
 */

import {ButtonBase, Grid, Radio} from "@material-ui/core";
import React from 'react';
import ClassicCard from "../../ClassicCard";
import FieldDescription from "./FieldDescription";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

class OptionSelect extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            multi,
            value,
            options,
            direction,
            cardStyles,
            noneSelectable,
            numPerRow,
            fieldTitleStyles,
            radioStyles,
            radioOptions: {
                hasCheckboxOrRadio,
                radioFirst
            },
            style
        } = this.props;
        return (
            <div style={{display: "inline-block", ...style}}>
                <Grid container spacing={8} alignItems={"stretch"}>
                    {
                        Array.isArray(options) && options.map((option, index) => {
                                return (
                                    <Grid
                                        key={"option_" + index}
                                        item xs={Math.round(12 / numPerRow)}
                                        style={{flex: "0 0 auto"}}
                                    >
                                        <ButtonBase
                                            style={{textAlign: 'initial'}}
                                            disabled={option.disabled}
                                            disableRipple disableTouchRipple
                                            onClick={() => this.props.handler(index === value ? (noneSelectable ? null : index) : index)}
                                        >
                                            <ClassicCard
                                                style={{margin: 0, ...cardStyles}}
                                                selected={index === value}
                                                disabled={option.disabled}
                                            >
                                                <div style={{
                                                    display: "flex",
                                                    flexDirection: direction === "vertical" ? "column" : "row",
                                                    alignItems: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    {
                                                        !radioFirst &&
                                                        <FieldDescription
                                                            title={option.title}
                                                            titleProps={{marginBottom: 4, ...fieldTitleStyles}}
                                                            description={option.description}
                                                        />
                                                    }
                                                    {
                                                        hasCheckboxOrRadio &&
                                                        <Radio
                                                            style={{width: 18, height: 18, ...radioStyles}}
                                                            disableRipple
                                                            color={"primary"}
                                                            checked={value === index}
                                                            icon={<RadioButtonUncheckedIcon fontSize="small"/>}
                                                            checkedIcon={<RadioButtonCheckedIcon fontSize="small"/>}
                                                        />
                                                    }
                                                    {
                                                        radioFirst &&
                                                        <FieldDescription
                                                            title={option.title}
                                                            titleProps={{...fieldTitleStyles}}
                                                            description={option.description}
                                                        />
                                                    }
                                                </div>
                                            </ClassicCard>
                                        </ButtonBase>
                                    </Grid>
                                )
                            }
                        )
                    }
                </Grid>
            </div>
        )
            ;
    }
}

export default OptionSelect;