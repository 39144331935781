/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React, {Component, Fragment} from 'react';
import InfoHelper from "./InfoHelper";

export default class TabLabel extends Component {
    render(){
        const { help, children } = this.props;
        return(
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <span style={{margin: 'auto'}}>{children}</span>
                <span style={{margin: 4}}>
                    <InfoHelper size="small">{help}</InfoHelper>
                </span>
            </div>
        );
    }
}