/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading } from '../../Navigation/actions';
import {

} from './components';
import {

} from './actions';
import { setDefaultFilters } from '../../Filters/actions';
import {CreateAlert} from "./components";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        filters: state.filters,
        meta: state.meta
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        loadData: (appId) => {
            dispatch(setDefaultFilters()); //updating defaults filters
        },
        resetData: () => dispatch()
    };
}

class Alerts extends Component {

    constructor (props) {
        super(props);
        this.appId = this.props.params.appId;
        this.state = {};
    }

    componentWillMount(){
        this.props.updateHeading("Alerts");
        this.props.loadData(this.appId);
    }

    componentWillUnmount(){
        //this.props.resetData();
    }

    render() {
        return (
            <section className="content">
                <CreateAlert {...this.props}/>
            </section>
        );
    }
}

Alerts.propTypes = {

};

Alerts.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Alerts);