/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import logger from '../../../utils/Logger';
import $ from 'jquery';

export function getLocationAPI(){
    const IP_API = "https://ipapi.co/json";
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return new Promise((resolve, reject) => {
        $.ajax(IP_API).done((response, statusText, xhr) => {
            logger.info(statusText, "Response for URL: " + IP_API, response);
            resolve(onSuccessCallback(response));
        }).fail(error => {
            logger.error("Error:::: ", error, "for URL: => " + IP_API);
            reject(onFailureCallback(error));
        });
    });
}
