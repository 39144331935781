/**
 * Created by jyothi on 1/11/17.
 */

import {
    SEGMENT_ATTRIBUTES_PENDING, SEGMENT_ATTRIBUTES, SEGMENT_ATTRIBUTES_FAILED,
    SEGMENT_ATTRIBUTE_VALUES_PENDING, SEGMENT_ATTRIBUTE_VALUES, SEGMENT_ATTRIBUTE_VALUES_FAILED,
    SEGMENT_SAVE_PENDING, SEGMENT_SAVE, SEGMENT_SAVE_FAILED,
    SEGMENT_EVENTS_PENDING, SEGMENT_EVENTS, SEGMENT_EVENTS_FAILED,
    SEGMENT_EVENT_ATTRIBUTES_PENDING, SEGMENT_EVENT_ATTRIBUTES, SEGMENT_EVENT_ATTRIBUTES_FAILED,
    SEGMENT_EVENT_ATTRIBUTE_VALUES_PENDING, SEGMENT_EVENT_ATTRIBUTE_VALUES, SEGMENT_EVENT_ATTRIBUTE_VALUES_FAILED,
    SEGMENT_COUNT_PENDING, SEGMENT_COUNT, SEGMENT_COUNT_FAILED,
    SEGMENT_UPDATE_QUERY, SEGMENT_RESET_QUERY,
    CUSTOM_EVENT_ENUM
} from './actionTypes';
import {
    getAttributesAPI, getAttributeValuesAPI,
    getEventsAPI, getEventAttributesAPI, getEventAttributeValuesAPI,
    getSegmentCountAPI, saveSegmentAPI
} from './api';
import {getSegmentList} from "../actions";
import {getAppSegments} from "../../../Apps/App/actions";

export const getAttributes = (appId) => {
    return (dispatch, getState) => {
        const of = getState().segmentBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of };
        return dispatch({
            types: [
                SEGMENT_ATTRIBUTES_PENDING,
                SEGMENT_ATTRIBUTES,
                SEGMENT_ATTRIBUTES_FAILED
            ],
            payload: {
                promise: getAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getAttributeValues = (appId, attribute, q) => {
    return (dispatch, getState) => {
        const of = getState().segmentBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of, q: q};
        return dispatch({
            types: [
                SEGMENT_ATTRIBUTE_VALUES_PENDING,
                SEGMENT_ATTRIBUTE_VALUES,
                SEGMENT_ATTRIBUTE_VALUES_FAILED,
            ],
            payload: {
                promise: getAttributeValuesAPI(getState().auth, appId, attribute, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute: attribute
            }
        });
    };
};

export const getEvents = (appId) => {
    return (dispatch, getState) => {
        const of = getState().segmentBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of};
        return dispatch({
            types: [
                SEGMENT_EVENTS_PENDING,
                SEGMENT_EVENTS,
                SEGMENT_EVENTS_FAILED
            ],
            payload: {
                promise: getEventsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, events) => {
                    /*if(Array.isArray(events) && events.length > 0) {
                     dispatch(updateCustomOverviewEvent(appId, events[0]));
                     dispatch(getCustomOverviewEventData(appId, events[0]));
                     }*/
                }
            }
        });
    };
};

export const getEventAttributes = (appId, event) => {
    return (dispatch, getState) => {
        const of = getState().segmentBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, event: event, of: of};
        return dispatch({
            types: [
                SEGMENT_EVENT_ATTRIBUTES_PENDING,
                SEGMENT_EVENT_ATTRIBUTES,
                SEGMENT_EVENT_ATTRIBUTES_FAILED,
            ],
            payload: {
                promise: getEventAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            },
            callbacks: {
                successDidDispatch: (dispatch, attributes) => {
                    if(Array.isArray(attributes)) { // not need as of now
                        //dispatch(getEventAttributeDistributions(appId, event, attributes));
                    }else{
                        console.error("No event attributes fetched..!")
                    }
                }
            }
        });
    };
};

export const getEventAttributeValues = (appId, event, attribute, q) => {
    return (dispatch, getState) => {
        const of = getState().segmentBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, event: event, of: of, attribute: attribute, q: q};
        return dispatch({
            types: [
                SEGMENT_EVENT_ATTRIBUTE_VALUES_PENDING,
                SEGMENT_EVENT_ATTRIBUTE_VALUES,
                SEGMENT_EVENT_ATTRIBUTE_VALUES_FAILED
            ],
            payload: {
                promise: getEventAttributeValuesAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute: attribute,
                event: event
            }
        });
    };
};

export const getSegmentCount = (appId) => {
    return (dispatch, getState) => {
        const of = getState().segmentBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of};
        const { query } = getState().segmentBuilder;
        return dispatch({
            types: [
                SEGMENT_COUNT_PENDING,
                SEGMENT_COUNT,
                SEGMENT_COUNT_FAILED
            ],
            payload: {
                promise: getSegmentCountAPI(getState().auth, appId, filters, query)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

export const saveSegment = (appId, name) => {
    return (dispatch, getState) => {
        const of = getState().segmentBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of};
        const { query } = getState().segmentBuilder;
        return dispatch({
            types: [
                SEGMENT_SAVE_PENDING,
                SEGMENT_SAVE,
                SEGMENT_SAVE_FAILED
            ],
            payload: {
                promise: saveSegmentAPI(getState().auth, appId, filters, query)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, segmentSaved) => {
                    if(segmentSaved) { //updating segment list
                        dispatch(getSegmentList(appId));
                        dispatch(getAppSegments(appId));
                    }
                }
            }
        });
    };
};

/**
 *
 * @param query
 * @returns {function(*, *)}
 */
export const updateQuery = (query) => {
    return (dispatch, getState) => {
        return dispatch({
            type: SEGMENT_UPDATE_QUERY,
            payload: query,
            meta: {
                //
            }
        });
    };
};

export const resetQuery = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: SEGMENT_RESET_QUERY,
            payload: null,
            meta: {
                //
            }
        });
    };
};