import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const withPrefix = (time) => time > 9 ? time : '0' + time;

export default class Timer extends Component {
    state = {
        startTime: Date.now(),
        time: 0
    };

    interval = null;

    componentDidMount(){
        this.interval = setInterval(() => {
            this.setState(({startTime}) => ({
                time: Date.now() - startTime
            }));
        }, 10);
    }

    getSeconds = time => withPrefix(Math.floor((time / 1000) % 60));

    getMillis = time => withPrefix(Math.floor((time % 1000) / 10));

    getMinutes = time => withPrefix(Math.floor(time / 60000));

    render(){
        const { variant = "caption", stopAt } = this.props;
        const { time } = this.state;
        if(stopAt && stopAt <= time){
            clearInterval(this.interval);
        }
        return (
            <Typography variant={variant}>
                {`${this.getMinutes(time)}:${this.getSeconds(time)}:${this.getMillis(time)}`}
            </Typography>
        );
    }
}

Timer.propTypes = {
    ...Typography.propTypes,
    stopAt: PropTypes.number
};