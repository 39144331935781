/**
 * React Dates custom styles
 * @param theme
 * @returns {styles}
 */
export default (theme) => ({
    '.CalendarMonthGrid, .DayPicker__horizontal, .DateRangePickerInput, .CalendarMonth, .DateInput_input': {
        background: theme.palette.background.paper
    },
    '.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover': {
        background: theme.palette.primary.dark + " !important"
    },
    '.CalendarDay__selected_span, .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover':{
        background: theme.palette.primary.light
    },
    '.DateInput__small':{
        width: 85
    },
    '.DateInput_input__small':{
        fontSize: 16,
        lineHeight: '20px',
        padding: '10px 8px 8px'
    },
    '.DateRangePicker_picker': {
        zIndex: 10000
    },
    '.DayPickerKeyboardShortcuts_show__bottomRight': {
        display: 'none'
    },
    '.SingleDatePicker_picker': {
        zIndex: 10000
    }
});