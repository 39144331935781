/**
 * Created by Rakesh Peela
 * Date: 14-Feb-2020
 * Time: 11:17 PM
 */
import {callApi} from "../../../../../../api";
import {makeDefaultQueryString} from "../../../../../../utils";

const DOWNLOADS_ENDPOINT = 'downloadables';

export function getDownloadableFilesAPI(appId, auth) {
    const url = makeDefaultQueryString(DOWNLOADS_ENDPOINT, auth, appId);
    const config = {
        method: "GET",
        auth: auth
    };

    const onSuccessCallback = json => {
        return json
    };

    const onFailureCallback = response => {
        return {}
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback)
}