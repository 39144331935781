/**
 * Created by jyothi on 14/1/17.
 */
import {
    SAVE_NOTIFICATION_FAILED, SAVE_NOTIFICATION_PENDING, SAVE_NOTIFICATION_SUCCESS,
    RESET_NOTIFICATIONS, 
    NOTIFICATIONS, NOTIFICATIONS_FAILED, NOTIFICATIONS_PENDING,
    DELETE_NOTIFICATION_SUCCESS, DELETE_NOTIFICATION_FAILED, DELETE_NOTIFICATION_PENDING,
    SEND_NOTIFICATION_PENDING, SEND_NOTIFICATION_SUCCESS, SEND_NOTIFICATION_FAILED
} from './actionTypes';
import {
    saveNotificationAPI, getNotificationsAPI, deleteNotificationAPI, updateNotificationAPI, sendNotificationAPI
} from './api';

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getNotifications = (appId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                NOTIFICATIONS_PENDING,
                NOTIFICATIONS,
                NOTIFICATIONS_FAILED
            ],
            payload: {
                promise: getNotificationsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param data
 * @returns {function(*, *)}
 */
export const saveNotification = (appId, data) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                SAVE_NOTIFICATION_PENDING,
                SAVE_NOTIFICATION_SUCCESS,
                SAVE_NOTIFICATION_FAILED
            ],
            payload: {
                promise: saveNotificationAPI(getState().auth, appId, filters, data)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //getting all messages after adding new
                    dispatch(getNotifications(appId));
                }
            }
        });
    };
};

/**
 *
 * @param appId
 * @param messageId
 * @param data
 * @returns {function(*, *)}
 */
export const updateNotification = (appId, messageId, data) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                SEND_NOTIFICATION_PENDING,
                SEND_NOTIFICATION_SUCCESS,
                SEND_NOTIFICATION_FAILED
            ],
            payload: {
                promise: updateNotificationAPI(getState().auth, appId, filters, messageId, data)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //getting all messages after updating
                    dispatch(getNotifications(appId));
                }
            }
        });
    };
};

export const sendNotification = (appId, notificationId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                SEND_NOTIFICATION_PENDING,
                SEND_NOTIFICATION_SUCCESS,
                SEND_NOTIFICATION_FAILED
            ],
            payload: {
                promise: sendNotificationAPI(getState().auth, appId, notificationId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //getting all messages after updating
                    //dispatch(getNotifications(appId));
                }
            }
        });
    };
};

/**
 *
 * @param appId
 * @param messageId
 * @returns {function(*, *)}
 */
export const deleteNotification = (appId, messageId) => {
    return (dispatch, getState) => {
        const filters = getState().filters;
        return dispatch({
            types: [
                DELETE_NOTIFICATION_PENDING,
                DELETE_NOTIFICATION_SUCCESS,
                DELETE_NOTIFICATION_FAILED,
            ],
            payload: {
                promise: deleteNotificationAPI(getState().auth, appId, filters,  messageId)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => { //getting all messages after adding new
                    dispatch(getNotifications(appId));
                }
            }
        });
    };
};

/**
 *
 * @returns {function(*, *)}
 */
export const resetNotifications = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_NOTIFICATIONS,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};