/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {getAttributes, getAttributeValues, resetQuery, updateQuery} from "../../../containers/Root/Application/RequireAuth/Dashboard/Segments/NewSegment/actions";
import { ActionCreatorHolder } from "./components";
import {saveContext} from "../../../containers/Root/Application/RequireAuth/Dashboard/Contexts/actions";

function mapStateToProps(state) {
    return {
        ...state,
        session: state.auth,
        router: state.routing,
        appState: state.app,
        overview: state.overview,
        meta: state.meta,
        segmentBuilder: state.segmentBuilder,
        iam: state.iam,
        contexts: state.contexts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getAttributes: (appId) => dispatch(getAttributes(appId)),
        getAttributeValues: (appId, attribute, q) => dispatch(getAttributeValues(appId, attribute, q)),
        handleQueryUpdate: (query) => dispatch(updateQuery(query)),
        resetQuery: () => dispatch(resetQuery()),
        saveContext: (appId, context) => dispatch(saveContext(appId, context)),
        bindedDispatch: dispatch
    };
}

class ActionCreator extends Component {

    componentWillMount(){
        //TODO:
    }

    componentWillReceiveProps(nextProps){

    }

    componentWillUnmount(){
        //this.props.resetData();
        this.props.resetQuery(); // clearing segment
    }

    handleUpdate = (action) => {
        const { handleActionUpdate = () => null } = this.props;
        handleActionUpdate(action);
        //TODO:
    };

    render() {
        return (
            <ActionCreatorHolder {...this.props} handleUpdate={this.handleUpdate}>
                {this.props.children}{/*UI Holder*/}
            </ActionCreatorHolder>
        );
    }
}

ActionCreator.propTypes = {
    action: PropTypes.object,
    handleActionUpdate: PropTypes.func.isRequired
};

ActionCreator.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ActionCreator);