/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../api';
import { makeDefaultQueryString, withQueryStrings} from '../../../../../../utils';


const SAVE_PREPROCESS_API = "preprocess/retention";

const PREPROCESS_CONFIGS_API = "preprocess/retention/calculated";

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param processConfig
 * @returns {Promise}
 */
export function savePreprocessAPI(auth, appId, filters, processConfig) {
    const url = makeDefaultQueryString(SAVE_PREPROCESS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(processConfig)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function preprocessConfigsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(PREPROCESS_CONFIGS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}