/**
 * Created by Rakesh Peela
 * Date: 14-Feb-2020
 * Time: 11:17 PM
 */
import {GET_DOWNLOADABLE, GET_DOWNLOADABLE_FAILED, GET_DOWNLOADABLE_PENDING} from "./actionTypes";
import {getDownloadableFilesAPI} from "./api";

export const getDownloadableFiles = (appId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                GET_DOWNLOADABLE_PENDING,
                GET_DOWNLOADABLE,
                GET_DOWNLOADABLE_FAILED
            ],
            payload: {
                promise: getDownloadableFilesAPI(appId, getState().auth)
                .then(res => {
                    return res || []
                })
            },
            meta: {}
        })
    }
};