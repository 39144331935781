/**
 * Created by Rakesh Peela
 * Date: 07-Dec-2019
 * Time: 11:38 AM
 */

import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        marginBottom: 8
    },
    tabsRoot: {
        borderBottom: '1px solid #e8e8e8',
    },
    tabsIndicator: {
        backgroundColor: '#3f50b5',
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing.unit * 4,
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            color: '#40a9ff',
            opacity: 1,
        },
        '&$tabSelected': {
            color: '#1890ff',
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&:focus': {
            color: '#40a9ff',
        },
    },
    tabSelected: {},
    typography: {
        padding: theme.spacing.unit
    },
});

class TabsPager extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabIndex: 0,
        }
    }

    render() {
        const {classes, tabsList} = this.props;
        const {tabIndex} = this.state;
        return (
            <div className={classes.root}>
                <Tabs
                    value={tabIndex}
                    onChange={(event, selectedTabIndex) => {
                        this.setState({
                            tabIndex: selectedTabIndex
                        });
                        this.props.handleChange(selectedTabIndex)
                    }}
                    classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
                >
                    {
                        tabsList.map(tabItem => (
                            <Tab
                                key={tabItem.id}
                                disableRipple
                                classes={{root: classes.tabRoot, selected: classes.tabSelected}}
                                label={tabItem.title}
                            />
                        ))
                    }
                </Tabs>
            </div>
        );
    }
}

TabsPager.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(TabsPager);