/**
 * Created by kodanda_rama on 1/27/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    bar: {

    },
    checked: {
        color: theme.palette.text.primary,
        '& + $bar': {
            backgroundColor: `${theme.palette.primary.main} !important`,
        },
        //height: 'auto'
    },
    disabled: {
        color: theme.palette.text.disabled,
        '& + $bar': {
            backgroundColor: `${theme.palette.text.disabled} !important`,
        },
    },
    root: {
        //height: 'auto'
    }
});

class ToggleSwitch extends React.Component{

    render(){
        const {label, disabled, styles, handleChange, checked = false, value, classes} = this.props;
        return(
            <FormControlLabel
                style={styles}
                control={
                    <Switch
                        checked={checked}
                        onChange={(e, checked) => {
                            //e.preventDefault();
                            handleChange(checked, e);
                        }}
                        aria-label={label}
                        disabled={disabled}
                        value={value}
                        style={{
                            cursor: disabled ? "not-allowed" : "pointer"
                        }}
                        classes={{
                            checked: classes.checked,
                            bar: classes.bar,
                            root: classes.root,
                            disabled: classes.disabled
                        }}
                    />
                }
                label={label}
            />
        )
    }

}

ToggleSwitch.propTypes = {
    label: PropTypes.string,
    handleChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
    checked: PropTypes.bool,
    styles: PropTypes.object
};

export default withStyles(styles)(ToggleSwitch);