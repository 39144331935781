/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

export const CUSTOM_OVERVIEW_CONFIG_PENDING = "CUSTOM_OVERVIEW_CONFIG_PENDING";
export const CUSTOM_OVERVIEW_CONFIG = "CUSTOM_OVERVIEW_CONFIG";
export const CUSTOM_OVERVIEW_CONFIG_FAILED = "CUSTOM_OVERVIEW_CONFIG_FAILED";

export const CUSTOM_OVERVIEW_UPDATE_CONFIG_PENDING = "CUSTOM_OVERVIEW_UPDATE_CONFIG_PENDING";
export const CUSTOM_OVERVIEW_UPDATE_CONFIG = "CUSTOM_OVERVIEW_UPDATE_CONFIG";
export const CUSTOM_OVERVIEW_UPDATE_CONFIG_FAILED = "CUSTOM_OVERVIEW_UPDATE_CONFIG_FAILED";

export const RESET_CUSTOM_OVERVIEW = "RESET_CUSTOM_OVERVIEW";

export const DASHBOARDS_PENDING = "DASHBOARDS_PENDING";
export const DASHBOARDS = "DASHBOARDS";
export const DASHBOARDS_FAILED = "DASHBOARDS_FAILED";

export const DASHBOARDS_ADD_PENDING = "DASHBOARDS_ADD_PENDING";
export const DASHBOARDS_ADD = "DASHBOARDS_ADD";
export const DASHBOARDS_ADD_FAILED = "DASHBOARDS_ADD_FAILED";

export const DASHBOARDS_UPDATE_PENDING = "DASHBOARDS_UPDATE_PENDING";
export const DASHBOARDS_UPDATE = "DASHBOARDS_UPDATE";
export const DASHBOARDS_UPDATE_FAILED = "DASHBOARDS_UPDATE_FAILED";

export const DASHBOARDS_DELETE_PENDING = "DASHBOARDS_DELETE_PENDING";
export const DASHBOARDS_DELETE = "DASHBOARDS_DELETE";
export const DASHBOARDS_DELETE_FAILED = "DASHBOARDS_DELETE_FAILED";

export const DASHBOARDS_UPDATE_GROUP = "DASHBOARDS_UPDATE_GROUP";