/**
 * Created by kodanda_rama on 2/8/17.
 */
import React, { Fragment }  from 'react';
import { findDOMNode } from 'react-dom';
import { SketchPicker } from 'react-color';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Palette from '@material-ui/icons/Palette';

class ColorPicker extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            color: props.color,
            open: false,
            anchorEl: null
        };
        this.id = 'color-picker-' + Math.random()
    }

    handleClick = () => {
        this.setState({open: true, anchorEl: findDOMNode(this.button)});
    };

    handleClose = () => {
        this.setState({open: false});
    };

    handleChange = (color) => {
        this.setState({ color: color.hex });
        this.props.handleChange(color.hex);
    };

    render() {
        const { disabled, color = "", label = "", margin, fullWidth = false } = this.props;
        const { open, anchorEl } = this.state;
        return (
            <Fragment>
                <FormControl fullWidth={fullWidth} disabled={disabled} margin={margin}>
                    <InputLabel htmlFor={this.id}>{label}</InputLabel>
                    <Input
                        id={this.id}
                        type="text"
                        value={color}
                        onChange={e => {
                            this.handleChange({hex: e.target.value})
                        }}
                        onClick={this.handleClick}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={disabled}
                                    ref={node => {
                                        this.button = node;
                                    }}
                                    aria-label="Toggle color picker"
                                    onClick={this.handleClick}
                                >
                                    <Palette nativeColor={color}/>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <Popover
                    open={open}
                    anchorEl={anchorEl}
                    anchorReference='anchorEl'
                    onClose={this.handleClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right'}}
                >
                    <SketchPicker color={ color } onChange={ this.handleChange } />
                </Popover>
            </Fragment>
        )
    }
}

export default ColorPicker

ColorPicker.propTypes = {
    handleChange: PropTypes.func.isRequired,
    color: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string
    ]).isRequired,
    disabled: PropTypes.bool,
    label: PropTypes.string,
    margin: PropTypes.string,
    fullWidth: PropTypes.bool
};