import logger from '../Logger';
class Storage {

    /**
     * @param window
     * @param storageType {window.localStorage || window.sessionStorage}
     * @param logger
     */
    constructor(window = {}, storageType, logger){
        this.storage = window[storageType] || {};
        this.storageType = storageType;
        this.logger = logger;
    }

    /**
     *
     * @returns {boolean}
     */
    isSupported = () => {
        let supported = true;
        try {
            let key = '_apx_n_', val = 'apx';
            if(this.storage){
                this.storage.set(key, val);
                if (this.storage.get(key) !== val) {
                    supported = false;
                }
                this.storage.remove(key);
            }else{
                supported = false;
            }
        } catch (err) {
            supported = false;
        }
        if (!supported) {
            this.logger.error('localStorage not supported..!');
        }
        return supported;
    };

    /**
     *
     * @param error
     */
    logError = (error) => {
        this.logger.error(`${this.storageType} Error: `, error);
    };

    /**
     *
     * @param key
     * @returns {*}
     */
    get = (key) => {
        try {
            return this.storage.getItem(key);
        } catch (err) {
            this.logError(err);
        }
        return null;
    };

    /**
     *
     * @param key
     * @returns {*}
     */
    getParsed = (key) => {
        try {
            return JSON.parse(this.storage.getItem(key)) || {};
        } catch (err) {
            this.logError(err);
        }
        return null;
    };

    /**
     *
     * @param key
     * @param value
     */
    set = (key, value) => {
        try {
            this.storage.setItem(key, value);
        } catch (err) {
            this.logError(err);
        }
    };

    /**
     *
     * @param key
     */
    remove = (key) => {
        try {
            this.storage.removeItem(key);
        } catch (err) {
            this.logError(err);
        }
    };

    clear = () => {
        try {
            this.storage.clear();
        } catch (err) {
            this.logError(err);
        }
    };

    has = (key) => {
        try {
            return this.storage.hasOwnProperty(key);
        } catch (err) {
            this.logError(err);
        }
        return false;
    }

}

export const local = new Storage(window, "localStorage", logger);
export const session = new Storage(window, "sessionStorage", logger);