/**
 * Created by Rakesh Peela
 * Date: 27-Dec-2019
 * Time: 3:37 PM
 */
import {ACTION_CLASSES} from "./AvailableOptions";

const GenNudgeJSON = (options) => {
    let json = {
        "name": "",
        "view_id": "",
        "activity": "",
        "bg_color": "#e40002",
        "animation": "none",
        "layout_type": "default",
        "has_ripple": false,
        "position": "right",
        "display_type": "passive_nudge",
        "delay": 0,
        "type": "IN_LINE",
    };
    return {
        ...json,
        action_class: ACTION_CLASSES.NUDGE
    };
};

export default GenNudgeJSON;