/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    CUSTOM_OVERVIEW_CONFIG,
    CUSTOM_OVERVIEW_UPDATE_CONFIG,
    RESET_CUSTOM_OVERVIEW,
    DASHBOARDS_PENDING, DASHBOARDS, DASHBOARDS_FAILED,
    DASHBOARDS_ADD_PENDING, DASHBOARDS_ADD, DASHBOARDS_ADD_FAILED,
    DASHBOARDS_DELETE,
    DASHBOARDS_UPDATE_PENDING, DASHBOARDS_UPDATE, DASHBOARDS_UPDATE_FAILED, DASHBOARDS_UPDATE_GROUP,
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';
import {ALL_USERS} from "../../../../../../constants";

const INITIAL_STATE = {
    config: {},
    dashboards: [],
    dashboards_loaded: false,
    group: ALL_USERS
};

const CONFIG_FLAG_STATE = {
    config_updated: false,
    config_pending: false,
    config_failed: false
};

const DASHBOARDS_FLAG_STATE = {
    dashboards_pending: false,
    dashboards_failed: false,
    dashboards_loaded: false
};

const ADD_DASHBOARD_FLAG_STATE = {
    add_dashboard_pending: false,
    add_dashboard_failed: false
};

function dashboardsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case CUSTOM_OVERVIEW_CONFIG:
            return {
                ...state,
                ...CONFIG_FLAG_STATE,
                config: action.payload
            };

        case CUSTOM_OVERVIEW_UPDATE_CONFIG:
            return {
                ...state,
                ...CONFIG_FLAG_STATE,
                config_updated: action.payload
            };

        case DASHBOARDS_ADD_PENDING:
            return {
                ...state,
                ...ADD_DASHBOARD_FLAG_STATE,
                add_dashboard_pending: true
            };

        case DASHBOARDS_ADD:
            return {
                ...state,
                ...ADD_DASHBOARD_FLAG_STATE,
                dashboard_added: true
            };

        case DASHBOARDS_ADD_FAILED:
            return {
                ...state,
                ...ADD_DASHBOARD_FLAG_STATE,
                add_dashboard_failed: true
            };

        case DASHBOARDS_PENDING:
            return {
                ...state,
                ...DASHBOARDS_FLAG_STATE,
                dashboards_pending: true
            };

        case DASHBOARDS:
            return {
                ...state,
                ...DASHBOARDS_FLAG_STATE,
                dashboards: action.payload,
                dashboards_loaded: true
            };

        case DASHBOARDS_FAILED:
            return {
                ...state,
                ...DASHBOARDS_FLAG_STATE,
                dashboards_failed: true
            };

        case DASHBOARDS_UPDATE_PENDING:
            return {
                ...state,
                ...ADD_DASHBOARD_FLAG_STATE,
                ...CONFIG_FLAG_STATE,
                config_pending: true
            };

        case DASHBOARDS_UPDATE:
            return {
                ...state,
                ...ADD_DASHBOARD_FLAG_STATE,
                ...CONFIG_FLAG_STATE,
                config_updated: true
            };

        case DASHBOARDS_UPDATE_FAILED:
            return {
                ...state,
                ...ADD_DASHBOARD_FLAG_STATE,
                ...CONFIG_FLAG_STATE,
                config_failed: true
            };

        case DASHBOARDS_DELETE:
            return {
                ...state,
                ...ADD_DASHBOARD_FLAG_STATE,
                dashboard_deleted: true
            };

        case DASHBOARDS_UPDATE_GROUP:
            return {
                ...state,
                group: action.payload || ALL_USERS
            };

        case RESET_CUSTOM_OVERVIEW:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default dashboardsReducer;
