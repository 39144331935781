import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";
import {
    allowedDashboards,
    displayTimeFromNow,
    formatTime,
    isDefined,
    randomColorWithIndex, shuffledArray
} from "../../../../../../../utils";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from '@material-ui/icons/Add';
import TextField from "@material-ui/core/TextField";
import Switch from "../../../../../../../components/reusable/Switch";
import Checkbox from "../../../../../../../components/reusable/MaterialUi/Checkbox";
import Typography from "@material-ui/core/Typography";
import {Tooltip} from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Clear";
import Box from "../../../../../../../components/reusable/Box";
import MobileSimulator from "../../../../../../../components/reusable/MobileSimulator";
import LinearProgress from '@material-ui/core/LinearProgress';
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import SentimentDissatisfied from '@material-ui/icons/SentimentDissatisfied';
import SentimentVeryDissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentSatisfied from '@material-ui/icons/SentimentSatisfied';
import SentimentVerySatisfied from '@material-ui/icons/SentimentVerySatisfied';
import Mood from '@material-ui/icons/Mood';
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace';
import {PALETTE} from "../../../../../../../styles";
import Paper from "@material-ui/core/Paper";
import Favorite from '@material-ui/icons/Favorite';
import ToggleSwitch from "../../../../../../../components/reusable/MaterialUi/ToggleSwitch";
import NumberField from "../../../../../../../components/reusable/MaterialUi/NumberField";


let qaId = 1001;
const getQAId = () => Math.ceil(Math.random() * 100000) + qaId++;

const BUTTON_TYPES = {
    circle: 'circle',
    rectangle: 'rectangle'
};

export const ANSWER_MODES = {
    single_choice: "single_choice",
    multi_choice: "multi_choice",
    rating: "rating",
    text: "text"
};

const EMPTY_CHOICE = (is_other = false) => ({
    id: getQAId(),
    value: is_other ? "Other" : "Choice " + (qaId - 1000),
    is_other,
});

const EMPTY_QUESTION = () => ({
    id: getQAId(),
    answer_mode: ANSWER_MODES.single_choice,
    title: 'Question ' + (qaId - 1000),
    is_required: false,
    randomize_choices: false,
    choices: []
});

const DEFAULT_TEXT_CONFIG = {
    "background_color": "#f1f0f0",
    "example": "eg : UI improvements",
    "example_text_color": "#000000",
    "maximum_characters": 150,
    "placeholder": "Enter your suggestions",
    "placeholder_text_color": "#000000",
    "text_color": "#000000",
    ...EMPTY_CHOICE(false),
}

const EMPTY_DIALOG_CONFIG = {
    button_type: BUTTON_TYPES.rectangle,
    yes_text: "Yes",
    no_text: "No",
    title: "",
    description: ""
};

const EMPTY_SURVEY_FORM = {
    delay: 500,
    show_success_message: false,
    success_message: "",
    dialog_config: null,
    questions: []
};

class FormWrapper extends Component {

    constructor(props) {
        super(props);
        const { handleUpdate, published, ui, ...others } = props;
        this.state = {
            ...others
        };
    }

    handleUpdate = (state = {}, questionIndex) => {
        this.setState(state, () => {
            const { handleUpdate } = this.props;
            handleUpdate(this.state, questionIndex);
        });
    };

    render(){
        return null;
    }

}

export class Choice extends FormWrapper {

    render(){
        const { id, value, is_other } = this.props;
        return(
            <Grid container>
                <Grid item xs={11}>
                    <TextField
                        fullWidth
                        required
                        type="text"
                        label="Value"
                        margin="dense"
                        value={value}
                        onChange={e => {
                            this.handleUpdate({value: e.target.value});
                        }}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={e => {
                        this.props.handleDelete(id);
                    }}>
                        <RemoveIcon/>
                    </IconButton>
                </Grid>
            </Grid>
        );
    }

}

Choice.propTypes = {
    id: PropTypes.number,
    value: PropTypes.any,
    handleUpdate: PropTypes.func
};

export class DialogConfig extends FormWrapper {


    render(){
        const { button_type, yes_text, no_text, title, description } = this.props;
        return(
            <Grid container spacing={16}>
                <Grid item xs={3}>
                    <Typography style={{lineHeight: '5em'}}>Button Type: </Typography>
                </Grid>
                <Grid item xs={9}>
                    <Switch
                        data={Object.keys(BUTTON_TYPES)}
                        value={button_type}
                        handleChange={button_type => this.handleUpdate({button_type})}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        type="text"
                        label="Title"
                        margin="dense"
                        value={title}
                        onChange={e => {
                            this.handleUpdate({title: e.target.value});
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        required
                        multiline
                        type="text"
                        label="Description"
                        margin="dense"
                        value={description}
                        onChange={e => {
                            this.handleUpdate({description: e.target.value});
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        required
                        type="text"
                        label="Yes Text"
                        margin="dense"
                        value={yes_text}
                        onChange={e => {
                            this.handleUpdate({yes_text: e.target.value});
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        required
                        type="text"
                        label="No Text"
                        margin="dense"
                        value={no_text}
                        onChange={e => {
                            this.handleUpdate({no_text: e.target.value});
                        }}
                    />
                </Grid>
            </Grid>
        );
    }

}

DialogConfig.propTypes = {
    button_type: PropTypes.oneOf(Object.keys(BUTTON_TYPES)),
    yes_text: PropTypes.string,
    no_text: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string
};

export class Question extends FormWrapper {

    render(){
        const { published, ui, id, answer_mode, title, is_required, randomize_choices = false, choices, text_config = DEFAULT_TEXT_CONFIG } = this.props;
        const hasOtherChoice = [].concat(...choices).filter(item => item.is_other).length;
        const showExtraOptions = (answer_mode !== ANSWER_MODES.rating && answer_mode !== ANSWER_MODES.text);
        return(
            <Box
                defaultExpanded
                title={
                    <Grid
                        onClick={e => {
                            e.stopPropagation()
                        }}
                        container
                        spacing={16}
                    >
                        <Grid item xs={9}>
                            <TextField
                                fullWidth
                                required
                                multiline
                                type="text"
                                label="Title"
                                margin="dense"
                                value={title}
                                onChange={e => {
                                    this.handleUpdate({title: e.target.value});
                                }}
                            />
                        </Grid>
                    </Grid>
                }
                collapsible
            >
                <Grid container spacing={16}>
                    {
                        answer_mode !== ANSWER_MODES.text
                        && <Grid item xs={12} md={6}>
                            <Checkbox
                                disableRipple
                                label="Is Required"
                                value="is_required"
                                checked={is_required}
                                handleChange={is_required => this.handleUpdate({is_required})}
                            />
                        </Grid>
                    }
                    {
                        showExtraOptions
                        && <Grid item xs={12} md={6}>
                            <Checkbox
                                disableRipple
                                label="Randomize Choices"
                                value="randomize_choices"
                                checked={randomize_choices}
                                handleChange={randomize_choices => this.handleUpdate({randomize_choices})}
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Switch
                            data={Object.keys(ANSWER_MODES)}
                            value={answer_mode}
                            handleChange={answer_mode => {
                                const text_config = answer_mode === ANSWER_MODES.text ? DEFAULT_TEXT_CONFIG : undefined;
                                this.handleUpdate({
                                    answer_mode,
                                    randomize_choices: showExtraOptions ? randomize_choices : false,
                                    choices: ([ANSWER_MODES.text, ANSWER_MODES.rating].includes(answer_mode)) ? [] : choices,
                                    text_config,
                                })
                            }}
                        />
                    </Grid>
                    {
                        showExtraOptions
                        && <Grid item xs={12}>
                            {
                                choices.map((choice, index) =>
                                    <div key={'choice' + '-' + index} style={{position: 'relative'}}>
                                        <div style={{
                                            position: 'absolute',
                                            zIndex: 3,
                                            right: 8,
                                            marginTop: 8
                                        }}>
                                            {
                                                !published || isChoiceAllowToEdit(ui, id, choice.id) &&
                                                <Tooltip id={"remove-step-" + 'choice' + '-' + index}
                                                         title="Remove this choice" placement="top">
                                                    <IconButton aria-label="Remove" onClick={() => {
                                                        this.handleUpdate({
                                                            choices: [...choices.slice(0, index), ...choices.slice(index + 1)]
                                                        });
                                                    }} style={{height: 'auto', width: 'auto', color: 'red'}}>
                                                        <RemoveIcon/>
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                        </div>
                                        <Choice
                                            {...choice}
                                            handleUpdate={updatedChoice => {
                                                this.handleUpdate({
                                                    choices: [
                                                        ...choices.slice(0, index),
                                                        updatedChoice,
                                                        ...choices.slice(index + 1)
                                                    ]
                                                });
                                            }}
                                            handleDelete={() => {
                                                if (index > -1) {
                                                    this.handleUpdate({
                                                        choices: [
                                                            ...choices.slice(index > 0 ? 0 : index, index),
                                                            ...choices.slice(index + 1)
                                                        ]
                                                    });
                                                }
                                            }}
                                        />
                                    </div>
                                )
                            }
                            <br/>
                            {
                                showExtraOptions
                                && <div style={{display: 'flex'}}>
                                    <Button
                                        variant="text"
                                        color="primary"
                                        onClick={e => {
                                            this.handleUpdate({choices: [...choices.slice(), EMPTY_CHOICE()]});
                                        }}
                                    >
                                        <AddIcon/> Add Choice
                                    </Button>
                                    {
                                        hasOtherChoice < 1
                                        && <Button
                                            variant="text"
                                            color="primary"
                                            onClick={e => {
                                                this.handleUpdate({choices: [...choices.slice(), EMPTY_CHOICE(true)]});
                                            }}
                                        >
                                            <AddIcon/> Add Other
                                        </Button>
                                    }
                                </div>
                            }
                        </Grid>
                    }

                    {
                        answer_mode === ANSWER_MODES.text
                        &&
                        <div style={{padding: 16}}>
                            <Grid container spacing={16}>
                                <Grid item xs={8}>
                                    <TextField
                                        fullWidth
                                        required
                                        type="text"
                                        label="Placeholder Value"
                                        margin="dense"
                                        value={text_config.placeholder}
                                        onChange={e => {
                                            this.handleUpdate({
                                                text_config: {
                                                    ...text_config,
                                                    placeholder: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        required
                                        type="text"
                                        label="Max Characters"
                                        margin="dense"
                                        value={text_config.maximum_characters}
                                        onChange={e => {
                                            this.handleUpdate({
                                                text_config: {
                                                    ...text_config,
                                                    maximum_characters: Number(e.target.value) || 0
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        fullWidth
                                        required
                                        type="text"
                                        label="Example Text"
                                        margin="dense"
                                        value={text_config.example}
                                        onChange={e => {
                                            this.handleUpdate({
                                                text_config: {
                                                    ...text_config,
                                                    example: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    }
                </Grid>
            </Box>
        );
    }

}

Question.propTypes = {
    id: PropTypes.number,
    answer_mode: PropTypes.oneOf(Object.keys(ANSWER_MODES)),
    title: PropTypes.string,
    is_required: PropTypes.bool,
    choices: PropTypes.array,
    handleUpdate: PropTypes.func
};

function isQuestionAllowToEdit(originalSurvey, questionId) {
    const { questions = [] } = originalSurvey;
    return !questions.some(question => question.id === questionId);
}

function isChoiceAllowToEdit(originalSurvey, questionId, choiceId) {
    const { questions = [] } = originalSurvey;
    const questionIndex = questions.findIndex(q => q.id === questionId);
    if(questionIndex > -1){
        return !questions[questionIndex].choices.some(choice => choice.id === choiceId);
    }
    return true;
}

export class SurveyForm extends FormWrapper {

    render(){
        const {
            delay, show_success_message, success_message, dialog_config, questions = [],
            published, ui
        } = this.props;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <Typography style={{lineHeight: '4.5em', display: 'flex'}} component="span" variant="subtitle1">
                        Delay showing the Survey by &nbsp;
                        <TextField
                            type="number"
                            label="Delay (ms)"
                            value={Number(delay)}
                            placeholder={500}
                            onChange={e => this.handleUpdate({delay: Number(e.target.value)})}
                        />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        collapsible
                        defaultExpanded={!!dialog_config}
                        collapseNow={!dialog_config}
                        title={
                            <ToggleSwitch
                                label="Enable Dialog Config"
                                value="has_dialog_config"
                                checked={!!dialog_config}
                                handleChange={has_dialog_config => {
                                    this.handleUpdate({dialog_config: has_dialog_config ? EMPTY_DIALOG_CONFIG : null});
                                }}
                            />
                        }
                    >
                        {
                            !!dialog_config && <DialogConfig
                                {...dialog_config}
                                handleUpdate={dialog_config => this.handleUpdate({dialog_config})}
                            />
                        }
                    </Box>
                </Grid>
                <Grid item xs={12}>
                    {
                        questions.map((question, index) =>
                            <Grid key={'question' + '-' + index} container spacing={16}>
                                <Grid item xs>
                                    <Question {...question} published={published} ui={ui} handleUpdate={updatedQuestion => {
                                        this.handleUpdate({
                                            questions: [
                                                ...questions.slice(0, index),
                                                updatedQuestion,
                                                ...questions.slice(index + 1)
                                            ]
                                        }, index);
                                    }}/>
                                </Grid>
                                { (!published || isQuestionAllowToEdit(ui, question.id)) &&
                                    <Grid item xs={2} md={1}>
                                        <Tooltip id={"remove-step-" + 'question' + '-' + index } title="Remove this question" placement="top">
                                            <IconButton aria-label="Remove" onClick={() => {
                                                this.handleUpdate({
                                                    questions: [...questions.slice(0, index), ...questions.slice(index + 1)]
                                                });
                                            }} style={{height: 'auto', width: 'auto', color: 'red'}}>
                                                <RemoveIcon fontSize="small"/>
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                }
                            </Grid>
                        )
                    }
                    <br />
                    <Button
                        variant="text"
                        color="primary"
                        onClick={e => {
                            this.handleUpdate({questions: [...questions, EMPTY_QUESTION()]}, questions.length);
                        }}
                    ><AddIcon/> Add Question</Button>
                </Grid>
                <Grid item xs={12}>
                    <Box
                        collapsible
                        defaultExpanded={show_success_message}
                        collapseNow={!show_success_message}
                        title={
                            <ToggleSwitch
                                label="Show Success Message"
                                value="show_success_message"
                                checked={show_success_message}
                                handleChange={show_success_message => this.handleUpdate({show_success_message})}
                            />
                        }
                    >
                        {
                            show_success_message && <TextField
                                fullWidth
                                required
                                multiline
                                type="text"
                                label="Success Message"
                                margin="dense"
                                value={success_message}
                                onChange={e => {
                                    this.handleUpdate({success_message: e.target.value});
                                }}
                            />
                        }
                    </Box>
                </Grid>
            </Grid>
        )
    }

}


/*
Styles
* */
const HEADER_ACTION_STYLES = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '8px auto'
};

const CHOICE_BUTTON_STYLES = (selected) => ({
    justifyContent: 'flex-start',
    textTransform: 'none',
    background: selected ? PALETTE.primary.main : undefined
});

const selectedColor = (selected, defaultColor = PALETTE.primary.main) => selected ? '#FFFFFF' : defaultColor;

const singleChoice = ({id, value, is_other}, selected = false) => (
    <Fragment>
        <Button style={CHOICE_BUTTON_STYLES(selected)} variant="outlined" fullWidth color={selected ? "primary" : undefined}>
            {selected ? <RadioButtonChecked nativeColor="#FFFFFF"/> : <RadioButtonUnchecked/> } &nbsp; <strong style={{color: selectedColor(selected)}}>{value}</strong>
        </Button>
        { is_other && selected && <TextField
            fullWidth
            type="text"
            margin="dense"
            autoFocus
        /> }
    </Fragment>
);

const multiChoice = ({id, value, is_other}, selected = false) => (
    <Fragment>
        <Button style={CHOICE_BUTTON_STYLES(selected)} variant="outlined" fullWidth color={selected ? "primary" : undefined}>
            <CheckCircleOutline nativeColor={selectedColor(selected, undefined)}/> &nbsp; <strong style={{color: selectedColor(selected)}}>{value}</strong>
        </Button>
        { is_other && selected && <TextField
            fullWidth
            type="text"
            margin="dense"
            autoFocus
        /> }
    </Fragment>
);

function finalChoices({choices = [], randomize_choices = false}) {
    if(randomize_choices){
        const withOtherChoice = choices.filter(o => o.is_other);
        const withoutOtherChoice = choices.filter(o => !o.is_other);
        return [...shuffledArray(withoutOtherChoice), ...withOtherChoice];
    }
    return choices;
}

class SingleChoiceSimulator extends Component {

    state = {
        selected: null
    };

    render(){
        const { handleAnswer } = this.props;
        const { selected } = this.state;
        return(
            <div>
                {
                    finalChoices(this.props).map(choice =>
                        <div key={choice.id} onClick={e => {
                            if(selected === choice.id){
                                this.setState({selected: null});
                                handleAnswer(false);
                            }else{
                                this.setState({selected: choice.id});
                                handleAnswer(true);
                            }
                        }} style={{margin: '8px 0'}}>
                            {singleChoice(choice, this.state.selected === choice.id)}
                        </div>
                    )
                }
            </div>
        )
    }
}

class MultiChoiceSimulator extends Component {

    state = {
        selected: []
    };

    render(){
        const { handleAnswer } = this.props;
        return(
            <Fragment>
                {
                    finalChoices(this.props).map(choice =>
                        <div key={choice.id} onClick={e => {
                            let selected = this.state.selected;
                            if(selected.includes(choice.id)){
                                const selectedIndex = selected.indexOf(choice.id);
                                selected = [...selected.slice(0, selectedIndex), ...selected.slice(selectedIndex + 1)];
                            }else{
                                selected = [...selected, choice.id];
                            }
                            this.setState({selected});
                            handleAnswer(selected.length > 0);
                        }} style={{margin: '8px 0'}}>
                            {multiChoice(choice, this.state.selected.includes(choice.id))}
                        </div>
                    )
                }
            </Fragment>
        )
    }
}

const ratingColor = (index) => {
    if(index === 0 || index === 1){
        return randomColorWithIndex(6);
    }else if(index === 2){
        return randomColorWithIndex(1);
    }else{
        return randomColorWithIndex(0);
    }
};

class RatingChoiceSimulator extends Component {

    state = {
        selected: null
    };

    render(){
        const { choices = [] } = this.props;
        const { selected } = this.state;
        const RatingButton = ({index, children}) => (
            <IconButton
                onClick={e => {
                    this.setState({selected: index});
                }}
                style={{background: selected === index ? ratingColor(index) : undefined}}
            >
                {children}
            </IconButton>
        );
        return(
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <RatingButton index={0}>
                    <SentimentVeryDissatisfied nativeColor={selectedColor(selected === 0)}/>
                </RatingButton>
                <RatingButton index={1}>
                    <SentimentDissatisfied nativeColor={selectedColor(selected === 1)}/>
                </RatingButton>
                <RatingButton index={2}>
                    <SentimentSatisfied nativeColor={selectedColor(selected === 2)}/>
                </RatingButton>
                <RatingButton index={3}>
                    <SentimentVerySatisfied nativeColor={selectedColor(selected === 3)}/>
                </RatingButton>
                <RatingButton index={4}>
                    <Mood nativeColor={selectedColor(selected === 4)}/>
                </RatingButton>
            </div>
        )
    }
}

class TextChoiceSimulator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            simValue: ""
        }
    }

    handleInputChange = (e) => {
        this.setState({
            simValue: e.target.value
        })
    }

    render() {
        const {simValue} = this.state;
        const {text_config: {example, placeholder, maximum_characters} = DEFAULT_TEXT_CONFIG} = this.props;
        return (
            <div>
                <Typography variant={"subtitle2"}>{example}</Typography>
                <TextField
                    multiline
                    rowsMax={4}
                    style={{width: "100%"}}
                    value={simValue}
                    onChange={this.handleInputChange}
                    margin="none"
                    placeholder={placeholder}
                    variant="outlined"
                />
                <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Typography style={{fontSize: 12}}>
                    {simValue.length}/{maximum_characters}
                </Typography>
                </div>
            </div>
        );
    }
}

class QuestionSimulator extends Component {
    render(){
        const { answer_mode, title } = this.props;
        return(
            <div style={{height: 324, overflowY: 'auto'}}>
                <Typography style={{fontSize: 18, fontWeight: 600}}>{title}</Typography>
                {
                    answer_mode === ANSWER_MODES.single_choice && <SingleChoiceSimulator {...this.props}/>
                }
                {
                    answer_mode === ANSWER_MODES.multi_choice && <MultiChoiceSimulator {...this.props}/>
                }
                {
                    answer_mode === ANSWER_MODES.rating && <RatingChoiceSimulator {...this.props}/>
                }
                {
                    answer_mode === ANSWER_MODES.text && <TextChoiceSimulator {...this.props} />
                }
            </div>
        )
    }
}

class DialogSimulator extends Component {

    render(){
        const { button_type, yes_text, no_text, title, description, handleAnswer } = this.props;
        return(
            <Paper style={{margin: 8, padding: 8, textAlign: 'center', position: 'absolute', bottom: 0, width: 279}}>
                <Favorite color="error"/>
                <Typography variant="subtitle1">{title}</Typography>
                <Typography variant="caption">{description}</Typography>
                <Button
                    variant="outlined"
                    style={{background: selectedColor(false, randomColorWithIndex(0)), margin: '8px 0'}}
                    fullWidth
                    onClick={e => handleAnswer(true)}
                >
                    <strong style={{textTransform: 'none', color: selectedColor(true)}}>{yes_text}</strong>
                </Button>
                <Button
                    variant="outlined"
                    style={{margin: '8px 0'}}
                    fullWidth
                    onClick={e => handleAnswer(false)}
                >
                    <strong style={{textTransform: 'none'}}>{no_text}</strong>
                </Button>
            </Paper>
        )
    }
}

const SIMULATOR_STYLES = {
    width: 294,
    height: 462
};

const SUBMIT_BUTTON_STYLES = {
    margin: '16px auto 8px auto',
    display: 'block',
    color: selectedColor(true)
};

const MESSAGE_STYLES = {
    position: 'absolute',
    bottom: 16,
    width: 278,
    background: '#444444',
    padding: '12px 32px',
    margin: 8,
    borderRadius: 8,
};

const INITIAL_STATE = {
    currentQuestionIndex: 0,
    answeredQuestions: [],
    showMessage: false,
    dialogDone: false,
    dialogOutput: false
};

export class SurveySimulator extends Component {

    state = {...INITIAL_STATE};

    componentWillReceiveProps(nextProps) {
        const { currentQuestionIndex, questions } = nextProps;
        //handles when question get deleted
        if(this.state.currentQuestionIndex !== 0 && this.state.currentQuestionIndex >= questions.length){
            this.setState({dialogDone: false, dialogOutput: false, currentQuestionIndex: 0});
        }
        if(isDefined(currentQuestionIndex, false)){
            if(currentQuestionIndex === 0 || currentQuestionIndex !== this.state.currentQuestionIndex){
                this.setState({dialogDone: true, dialogOutput: true, currentQuestionIndex});
            }
        }else { //Handling non question inputs
            this.setState({dialogDone: false, dialogOutput: false});
        }
        if(nextProps.reset && nextProps.reset !== this.props.reset){
            this.setState(INITIAL_STATE);
        }
    }

    render(){
        const { currentQuestionIndex, answeredQuestions, dialogDone, dialogOutput, showMessage } = this.state;
        const { questions = [], dialog_config, show_success_message, success_message } = this.props;
        const progress = currentQuestionIndex / questions.length * 100;
        const showQuestions = !showMessage && questions.length > 0 && (!dialog_config || (dialogDone && dialogOutput));
        const currentQuestion = questions[currentQuestionIndex];
        const disableSubmitButton = currentQuestion && currentQuestion.is_required && !answeredQuestions.includes(currentQuestionIndex);
        const lastQuestion = questions.length > 0 && currentQuestionIndex === questions.length - 1;
        return(
            <div style={SIMULATOR_STYLES}>
                {
                    dialog_config && !dialogDone && <DialogSimulator
                        {...dialog_config}
                        handleAnswer={dialogOutput => {
                            this.setState({dialogOutput, dialogDone: true, currentQuestionIndex: 0});
                        }}
                    />
                }
                {
                    showQuestions && <Fragment>
                        <LinearProgress value={progress} variant="determinate" />
                        <Paper elevation={0} style={{padding: 8}}>
                            <div style={HEADER_ACTION_STYLES}>
                                {currentQuestionIndex === 0 && <IconButton
                                    onClick={e => this.setState({
                                        currentQuestionIndex: 0,
                                        dialogDone: false,
                                        dialogOutput: false
                                    })}
                                >
                                    <RemoveIcon/>
                                </IconButton>}
                                {
                                    currentQuestionIndex > 0 && <IconButton
                                        onClick={e => this.setState({currentQuestionIndex: currentQuestionIndex - 1})}
                                    >
                                        <KeyboardBackspace/>
                                    </IconButton>
                                }
                                {
                                    !lastQuestion && <Button
                                        onClick={e => {
                                            if(currentQuestionIndex < questions.length - 1){
                                                this.setState({currentQuestionIndex: currentQuestionIndex + 1})
                                            }
                                        }}
                                    ><span style={{textTransform: 'none'}}>Skip</span></Button>
                                }
                            </div>
                            {
                                questions.length > 0 &&
                                <QuestionSimulator
                                    key={questions[currentQuestionIndex].id}
                                    {...questions[currentQuestionIndex]}
                                    handleAnswer={answered => {
                                        if(answered && !answeredQuestions.includes(currentQuestionIndex)){
                                            this.setState({answeredQuestions: [...answeredQuestions, currentQuestionIndex]});
                                        }
                                        if(!answered){
                                            const index = answeredQuestions.indexOf(currentQuestionIndex);
                                            this.setState({
                                                answeredQuestions: [
                                                    ...answeredQuestions.slice(0, index), ...answeredQuestions.slice(index + 1)
                                                ]
                                            });
                                        }
                                    }}
                                />
                            }
                            <Button
                                variant="contained"
                                disabled={disableSubmitButton}
                                onClick={e => {
                                    if(lastQuestion){
                                        this.setState({showMessage: true});
                                    }else if(currentQuestionIndex < questions.length - 1){
                                        this.setState({currentQuestionIndex: currentQuestionIndex + 1})
                                    }
                                }}
                                style={{
                                    ...SUBMIT_BUTTON_STYLES,
                                    background: disableSubmitButton ? undefined : randomColorWithIndex(0)
                                }}
                            >Submit and continue</Button>
                        </Paper>
                    </Fragment>
                }
                {
                    showMessage && show_success_message &&
                    <div style={MESSAGE_STYLES}>
                        <Typography style={{color: '#F1F1F1', textAlign: 'center'}}>{success_message}</Typography>
                    </div>
                }
            </div>
        )
    }

}

export class SurveyHolder extends Component {

    constructor(props){
        super(props);
        this.state = {
            survey: {
                ...EMPTY_SURVEY_FORM,
                ...props.ui
            },
            questionIndex: null,
            reset: false
        };
    }

    handleUpdate = (state = {}, questionIndex = null) => {
        this.setState({
            survey: {
                ...this.state.survey,
                ...state
            },
            questionIndex
        }, () => {
            const { handleUpdate = () => null } = this.props;
            handleUpdate({ui: this.state.survey});
        });
    };

    handleChoiceUpdate = (questionIndex) => (choice) => { //TODO:
        let questions = this.state.survey.questions;
        const question = questions[questionIndex];
        question.choices = [...question.choices.slice(0, question.choices.length - 1), choice];
        questions = [...questions.slice(0, questionIndex), question, ...questions.slice(questionIndex + 1)]
    };

    render(){
        const { survey, survey: { questions = [] }, questionIndex, reset } = this.state;
        const { surveys: { survey: { ui } = {} }, published } = this.props;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12} md={6} style={{maxHeight: '90vh', overflowY: 'auto'}}>
                    <SurveyForm {...survey} ui={ui} published={published} handleUpdate={this.handleUpdate}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button style={{float: 'right'}} variant="text" color="secondary" onClick={e => {
                        this.setState({reset: true});
                        setTimeout(() => {
                            this.setState({reset: false});
                        }, 100);
                    }}>Reset Simulator</Button>
                    <MobileSimulator>
                        <SurveySimulator {...survey} currentQuestionIndex={questionIndex} reset={reset}/>
                    </MobileSimulator>
                </Grid>
            </Grid>
        )
    }

}