/**
 * Created by Rakesh Peela
 * Date: 09-Nov-2019
 * Time: 10:39 AM
 */

import {callApi} from "../../../../../../../api";
import {makeDefaultQueryString, withQueryStrings} from "../../../../../../../utils";

const MESSAGES_API = "art-configs";
const PUBLISH_MESSAGE_API = "publish";

export function getMessagesListAPI(appId, auth, filters) {
    const url = makeDefaultQueryString(MESSAGES_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth
    };
    const onSuccessCallback = response => {
        return response;
    };
    const onFailureCallback = response => {
        return {}
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback)
}

export function deleteMessageAPI(appId, auth, messageId) {
    const url = makeDefaultQueryString(`${MESSAGES_API}/${messageId}`, auth, appId) + withQueryStrings({configType: "walkthrough"});
    const config = {
        method: "DELETE",
        auth,
    };

    const onSuccessCallback = response => {
        return response
    };

    const onFailureCallback = response => {
        return {}
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback)
}

export function saveMessageAPI(appId, auth, messageId, messageData) {
    const url = makeDefaultQueryString(`${MESSAGES_API}`, auth, appId) + withQueryStrings({configType: "walkthrough"});
    const config = {
        method: "POST",
        auth,
        body: JSON.stringify(messageData)
    };

    const onSuccessCallback = response => {
        return response
    };

    const onFailureCallback = response => {
        return {}
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback)
}

export function updateMessageAPI(appId, auth, messageId, messageData) {
    const url = makeDefaultQueryString(`${MESSAGES_API}/${messageId}`, auth, appId) + withQueryStrings({configType: "walkthrough"});

    const config = {
        method: "PUT",
        auth,
        body: JSON.stringify(messageData)
    };

    const onSuccessCallback = response => {
        return response
    };

    const onFailureCallback = response => {
        return {}
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback)
}

export function publishMessageAPI(appId, auth, messageId) {
    const url = makeDefaultQueryString(`${PUBLISH_MESSAGE_API}`, auth, appId)
        + withQueryStrings({
            configId: messageId,
            configType: "walkthrough"
        });

    const config = {
        method: "PATCH",
        auth,
        body: JSON.stringify({})
    };

    const onSuccessCallback = response => {
        return response
    };

    const onFailureCallback = response => {
        return {}
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback)
}

export function toggleMessageAPI(appId, auth, messageId, status) {
    const url = makeDefaultQueryString(`${MESSAGES_API}/${messageId}`, auth, appId)
        + withQueryStrings({
            configType: "walkthrough",
            enableStatus: status.toString()
        });
    const config = {
        method: "PATCH",
        auth,
        body: JSON.stringify({}),
    }

    const onSuccessCallback = response => {
        return response
    };

    const onFailureCallback = response => {
        return {}
    };

    return callApi(url, config, onSuccessCallback, onFailureCallback);
}