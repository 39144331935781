import {createStore, applyMiddleware, compose} from 'redux';
import {browserHistory} from 'react-router';
import {routerMiddleware} from 'react-router-redux';
import persistState from 'redux-localstorage';
import thunk from 'redux-thunk';
import promiseMiddleware from '../middleware/promiseMiddleware';
import authMiddleware from '../middleware/authMiddelware';
import refreshMiddleware from '../middleware/refreshMiddleware'
import logger from './logger';
import rootReducer from '../reducers';
import getSessionState from "./Session";

const SESSION_KEY = "_apx_n2_";

const __DEV__ = process.env.NODE_ENV !== "production";

const reduxRouterMiddleware = routerMiddleware(browserHistory);

const filtersStorageConfig = {
    key: 'filters',
    serialize: (store) => {
        return store && store.filters ?
            JSON.stringify(store.filters) : store;
    },
    deserialize: (state) => state ? {filters: JSON.parse(state)} : {}
};

const expiryStorageConfig = {
    key: 'accessExpiry',
    serialize: (store) => {
        return store && store.auth && store.auth.accessExpiry !== undefined ?
            JSON.stringify(store.auth.accessExpiry) : JSON.stringify(null);
    },
    deserialize: (state) => ({"accessExpiry": JSON.parse(state)})
};


function configureStoreDev(initialState) {
    try{
    }catch(e){}

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        rootReducer,
        {...initialState, ...getSessionState(SESSION_KEY)},
        composeEnhancers(
            __DEV__
                ? applyMiddleware(authMiddleware(SESSION_KEY), reduxRouterMiddleware, promiseMiddleware, refreshMiddleware, thunk, logger)
                : applyMiddleware(authMiddleware(SESSION_KEY), reduxRouterMiddleware, promiseMiddleware, refreshMiddleware, thunk),
            persistState('filters', filtersStorageConfig),
            persistState('auth', expiryStorageConfig)
        )
    );

    if (module.hot) {
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers');
            store.replaceReducer(nextRootReducer);
        });
    }

    // Required for replaying actions from devtools to work
    if (__DEV__) {
        //reduxRouterMiddleware.listenForReplays(store);
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ && window.devToolsExtension();
    }

    return store;
}


export default configureStoreDev;