/**
 * Created by Rakesh Peela
 * Date: 09-Nov-2019
 * Time: 10:39 AM
 */

export const ART_CONFIG_TYPES = {
    SURVEY: 'survey',
    RTA: 'rta',
    WALKTHROUGH: 'walkthrough'
};

export const RESET_NEW_MESSAGE_STATE = "RESET_NEW_MESSAGE_STATE";

export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_PENDING = "GET_MESSAGES_PENDING";
export const GET_MESSAGES_FAILED = "GET_MESSAGES_FAILED";

export const GET_CLIENT_EVENTS = "GET_CLIENT_EVENTS";
export const GET_CLIENT_EVENTS_PENDING = "GET_CLIENT_EVENTS_PENDING";
export const GET_CLIENT_EVENTS_FAILED = "GET_CLIENT_EVENTS_FAILED";

export const SET_NEW_MESSAGE_FROM_EDIT = "SET_NEW_MESSAGE_FROM_EDIT";

export const UPDATE_IN_FLOW = "UPDATE_IN_FLOW";

export const UPDATE_IN_NEW_UI = "UPDATE_IN_NEW_UI";

export const UPDATE_CAMPAIGN_STEP = "UPDATE_CAMPAIGN_STEP";
export const UPDATE_CURRENT_STEP_SELECT = "UPDATE_CURRENT_STEP_SELECT";

export const DELETE_STEP_FROM_CAMPAIGN = "DELETE_STEP_FROM_CAMPAIGN";
export const ADD_NEXT_STEP_TO_CAMPAIGN = "ADD_NEXT_STEP_TO_CAMPAIGN";
export const UPDATE_STEPS_ORDER = "UPDATE_STEPS_ORDER";

export const SEND_MESSAGE_TO_TEST_DEVICES = "SEND_MESSAGE_TO_TEST_DEVICES";
export const SEND_MESSAGE_TO_TEST_DEVICES_PENDING = "SEND_MESSAGE_TO_TEST_DEVICES_PENDING";
export const SEND_MESSAGE_TO_TEST_DEVICES_FAILED = "SEND_MESSAGE_TO_TEST_DEVICES_FAILED";

export const DELETE_MESSAGE2 = "DELETE_MESSAGE2";
export const DELETE_MESSAGE2_PENDING = "DELETE_MESSAGE2_PENDING";
export const DELETE_MESSAGE2_FAILED = "DELETE_MESSAGE2_FAILED";

export const RESUME_MESSAGE2 = "RESUME_MESSAGE2";
export const RESUME_MESSAGE2_PENDING = "RESUME_MESSAGE2_PENDING";
export const RESUME_MESSAGE2_FAILED = "RESUME_MESSAGE2_FAILED";

export const PAUSE_MESSAGE2 = "PAUSE_MESSAGE2";
export const PAUSE_MESSAGE2_PENDING = "PAUSE_MESSAGE2_PENDING";
export const PAUSE_MESSAGE2_FAILED = "PAUSE_MESSAGE2_FAILED";

export const PUBLISH_MESSAGE2 = "PUBLISH_MESSAGE2";
export const PUBLISH_MESSAGE2_PENDING = "PUBLISH_MESSAGE2_PENDING";
export const PUBLISH_MESSAGE2_FAILED = "PUBLISH_MESSAGE2_FAILED";

export const SAVE_MESSAGE2 = "SAVE_MESSAGE2";
export const SAVE_MESSAGE2_PENDING = "SAVE_MESSAGE2_PENDING";
export const SAVE_MESSAGE2_FAILED = "SAVE_MESSAGE2_FAILED";

export const SET_ACTING_ID = "SET_ACTING_ID";

export const RESET_STATUS_MESSAGE = "RESET_STATUS_MESSAGE";