/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

export const DAY_0_UNINSTALLS_PENDING = "DAY_0_UNINSTALLS_PENDING";
export const DAY_0_UNINSTALLS = "DAY_0_UNINSTALLS";
export const DAY_0_UNINSTALLS_FAILED = "DAY_0_UNINSTALLS_FAILED";

export const DAY_0_USER_TRENDS_PENDING = "DAY_0_USER_TRENDS_PENDING";
export const DAY_0_USER_TRENDS = "DAY_0_USER_TRENDS";
export const DAY_0_USER_TRENDS_FAILED = "DAY_0_USER_TRENDS_FAILED";

export const UNINSTALLS_PENDING = "UNINSTALLS_PENDING";
export const UNINSTALLS = "UNINSTALLS";
export const UNINSTALLS_FAILED = "UNINSTALLS_FAILED";

export const TOTAL_USER_TRENDS_PENDING = "TOTAL_USER_TRENDS_PENDING";
export const TOTAL_USER_TRENDS = "TOTAL_USER_TRENDS";
export const TOTAL_USER_TRENDS_FAILED = "TOTAL_USER_TRENDS_FAILED";

export const UNINSTALL_USER_DISTRIBUTION_PENDING = "UNINSTALL_USER_DISTRIBUTION_PENDING";
export const UNINSTALL_USER_DISTRIBUTION = "UNINSTALL_USER_DISTRIBUTION";
export const UNINSTALL_USER_DISTRIBUTION_FAILED = "UNINSTALL_USER_DISTRIBUTION_FAILED";

export const RESET_UNINSTALLS = "RESET_UNINSTALLS";

export const UPDATE_RANGE_OF_UNINSTALL_DISTRIBUTION = "UPDATE_RANGE_OF_UNINSTALL_DISTRIBUTION";

export const UNINSTALL_COUNT_PENDING = "UNINSTALL_COUNT_PENDING";
export const UNINSTALL_COUNT = "UNINSTALL_COUNT";
export const UNINSTALL_COUNT_FAILED = "UNINSTALL_COUNT_FAILED";

export const DAY0_UNINSTALL_COUNT_PENDING = "DAY0_UNINSTALL_COUNT_PENDING";
export const DAY0_UNINSTALL_COUNT = "DAY0_UNINSTALL_COUNT";
export const DAY0_UNINSTALL_COUNT_FAILED = "DAY0_UNINSTALL_COUNT_FAILED";

export const UNINSTALL_USAGE_PENDING = "UNINSTALL_USAGE_PENDING";
export const UNINSTALL_USAGE = "UNINSTALL_USAGE";
export const UNINSTALL_USAGE_FAILED = "UNINSTALL_USAGE_FAILED";

export const UNINSTALL_DAY_USAGE_PENDING = "UNINSTALL_DAY_USAGE_PENDING";
export const UNINSTALL_DAY_USAGE = "UNINSTALL_DAY_USAGE";
export const UNINSTALL_DAY_USAGE_FAILED = "UNINSTALL_DAY_USAGE_FAILED";