import React from "react";
import moment from "moment";
import MultiSelect from "../../../../../components/reusable/MaterialUi/MultiSelect";
import FormSelect from "../../../../../components/reusable/MaterialUi/FormSelect";
import { areArraysEqual } from "../../../../../utils";
import Button from "@material-ui/core/Button";
import Apxor from "apxor";
import { APXOR_SDK_CATEGORY_CONSTANTS } from "../../../../../constants";
import { Grid } from "@material-ui/core";

export class CohortFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCohort: null,
    };
  }

  handleCohortChange = (cohortObj) => {
    const {
      navigation: { heading },
    } = this.props;
    Apxor.logEvent(
      "SelectCohort",
      { cohort: cohortObj, navigation: heading },
      APXOR_SDK_CATEGORY_CONSTANTS.COHORT
    );
    this.setState({ currentCohort: cohortObj });
    this.props.updateCohortFilter(cohortObj);
    addParamToLocalStorage(this.props.params.appId, { cohort: cohortObj });
    this.props.applyGlobalFilter();
  };

  componentWillMount() {
    const { params, filters } = this.props;
    this.props.getCohortsData(params.appId, filters);
    let currentCohort = null;
    try {
      currentCohort =
        JSON.parse(window.localStorage.getItem(params.appId)).cohort || []; //reads from persistentStorage if not set initially
    } catch (e) {
      currentCohort = null;
    }
    this.props.updateCohortFilter(currentCohort);
    this.setState({ currentCohort });
  }

  render() {
    const {
      appState,
      cohorts: { cohorts },
    } = this.props;
    const { currentCohort } = this.state;
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div style={{ position: "absolute", top: "5em" }}> Select Cohort</div>
          {
            <MultiSelect
              clearable={true}
              handleChange={(e) => this.handleCohortChange(e)}
              options={cohorts.map((o) => ({
                value: o.cohort_id,
                label: o.name,
              }))}
              placeholder="Select Cohort"
              single
              style={{ marginTop: "3em" }}
              value={currentCohort}
            />
          }
        </Grid>
      </Grid>
    );
  }
}

function addParamToLocalStorage(appId, obj) {
  let existingStorageObj = {};
  try {
    existingStorageObj = JSON.parse(window.localStorage.getItem(appId));
    window.localStorage.setItem(
      appId,
      JSON.stringify({ ...existingStorageObj, ...obj })
    );
  } catch {
    window.localStorage.setItem(appId, JSON.stringify({ ...obj }));
  }
}
export class VersionFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentVersions: [],
      currentOperator: "EQ",
    };
  }

  handleVersionsUpdate = (newVersions, operator) => {
    const {
      params: { appId },
    } = this.props;
    const {
      navigation: { heading },
    } = this.props;
    Apxor.logEvent(
      "VersionsChanged",
      { navigation: heading, selected_versions: newVersions },
      APXOR_SDK_CATEGORY_CONSTANTS.VERSION
    );
    addParamToLocalStorage(this.props.params.appId, {
      app_version: newVersions,
    }); //FIXME: figureout good way here
    this.props.updateVersions(newVersions);
    this.props.updateOperator(operator);
    this.props.applyGlobalFilter();
  };

  componentWillMount() {
    const { params } = this.props;
    //FIXME: use persistentStorage
    let currentVersions = [];
    try {
      currentVersions =
        JSON.parse(window.localStorage.getItem(params.appId)).app_version || []; //reads from persistentStorage if not set initially
    } catch (e) {
      currentVersions = [];
    }
   
    this.props.updateVersions(currentVersions); //FIXME: list of all needed
    this.setState({ currentVersions });
  }

  handleChange = (versions) => {
    let versionArray = versions;
    if (!Array.isArray(versions)) {
      versionArray = [versions];
    }
    Apxor.logEvent("SelectVersion", { version: versionArray }, "");
    this.setState({ currentVersions: versionArray });
  };

  check = (x, y) => {
    const validate = !(x) || (x && y);
    return validate;
  }


  render() {
    const {
      appState,
      filters: { versions, operator },
    } = this.props;
    const allVersions = appState.app ? appState.appVersions : [];
    const { currentVersions } = this.state;
    const { currentOperator } = this.state;
    const changed = !areArraysEqual(versions, currentVersions);
    const operatorChanged = operator !== currentOperator;

    const operators = [{ symbol: "=", name: "EQ" }, { symbol: ">", name: "GT" }, { symbol: "<", name: "LT" }, { symbol: ">=", name: "GTE" }, { symbol: "<=", name: "LTE" }];
    const gridWidth = this.props.operatorShow ? 6 : 10;
    return (
      <>
        <div style={{ position: "absolute", top: "5em", marginLeft: "1em" }}>
          {" "}
          Select Versions
        </div>{" "}
        <Grid container spacing={0} style={{ alignItems: "flex-end" }}>
          <Grid item xs={4}>
            {this.props.operatorShow && <MultiSelect
                value={currentOperator}
                options={operators.map((o) => ({ value: o.name, label: o.symbol }))}
                placeholder="="
                label="Operator"
                handleChange={(operator) => {
                  Apxor.logEvent(
                    "SelectOperator", { operator: operator }, APXOR_SDK_CATEGORY_CONSTANTS.OPERATOR);
                  this.setState({ currentOperator: operator })
                }}
                single
                idKey="value"
              nameKey="label"
              style={{marginTop:"2em", marginLeft:"1em", marginRight:"1em"}}
              />}
            {/* </div> */}
          </Grid>
          <Grid item xs={gridWidth} style={{ marginTop: "2.2em" }}>
            {this.props.operatorShow && currentOperator !== "EQ" && (
              <MultiSelect
                clearable={true}
                value={Array.isArray(currentVersions) && currentVersions.length > 0 ? currentVersions[0] : currentVersions }
                options={allVersions.map((o) => ({ value: o, label: o }))}
                placeholder="Select Versions"
                label="Select Versions"
                handleChange={this.handleChange}
                single
                idKey="value"
                nameKey="label"
                
              />
            )}
            {this.check(this.props.operatorShow, currentOperator==="EQ")  && (
              <MultiSelect
                clearable={false}
                handleChange={this.handleChange}
                multiple
                options={allVersions.map((o) => ({ value: o, label: o }))}
                placeholder="Select Versions"
                value={currentVersions}
                valueRenderer={(value) =>
                  value.length === 0 ? "All Versions" : value.join(", ")
                }
                style={{ marginTop: "0.8em", marginLeft: (!this.props.operatorShow)?"0.5em":"0em" }}
              />
            )}
            
          </Grid>

          {!(!changed && !operatorChanged) &&
            <Grid item xs={2}>
              <Button
                style={{ marginTop: "3.5em", height:"1em",width:"0.5em"}}
                color="primary"
                variant="contained"
                onClick={(e) => {
                  this.handleVersionsUpdate(currentVersions, currentOperator);
                }}
                size="small"
              >
                Apply
              </Button>
            </Grid>
          }
        </Grid>
      </>
    );
  }
}

const COMPARABLE_DATE_FORMAT = "DDMMYYYY";

/**
 *
 * @param a [Moment]
 * @param b [Moment]
 * @returns {boolean}
 */
function areSame(a, b) {
  return a.format(COMPARABLE_DATE_FORMAT) === b.format(COMPARABLE_DATE_FORMAT);
}

function isValidChange(existing, changed, start, end) {
  return (
    !areSame(existing, changed) &&
    ((start && start.diff(changed) <= 0) || (end && end.diff(changed) >= 0))
  );
  /*if(!areSame(existing, changed)){
        return true;
    }else {
        if (start && start.diff(changed) <= 0) return true;
        if (end && end.diff(changed) >= 0) return true;
    }
    return false;*/
}

/**
 * @deprecated
 */
export class TimeFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minDate: moment(new Date("07-01-2017")),
      maxDate: moment(),
      startDate: moment().subtract(6, "days"),
      endDate: moment(),
    };
  }

  updateTime = (start, end) => {
    //normalized updation
    this.setState({ startDate: start, endDate: end }); //if came form props
    const normalizedStartTime = new Date(start.format("YYYY-MM-DD"));
    const normalizedEndTime = new Date(end.format("YYYY-MM-DD"));
    this.props.onFilterChange(normalizedStartTime, normalizedEndTime);
  };

  componentWillMount() {
    const { startDate, endDate } = this.state;
    const {
      since = startDate.toISOString(),
      till = endDate.toISOString(),
    } = this.props.filters;
    this.updateTime(moment(since), moment(till));
  }

  handleStartDateChange = (startDate) => {
    if (
      isValidChange(
        this.state.startDate,
        startDate,
        undefined,
        this.state.endDate
      )
    ) {
      this.updateTime(startDate, this.state.endDate);
      this.setState({ startDate }, this.props.applyGlobalFilter);
    }
  };

  handleEndDateChange = (endDate) => {
    if (
      isValidChange(
        this.state.endDate,
        endDate,
        this.state.startDate,
        undefined
      )
    ) {
      this.updateTime(this.state.startDate, endDate);
      this.setState({ endDate }, this.props.applyGlobalFilter);
    }
  };

  render() {
    return (
      <div style={{ display: "flex", maxWidth: 260 }}>
        {/*<DatePicker disabled={disabled} value={startDate} handleChange={this.handleStartDateChange} />
                &nbsp;
                <DatePicker disabled={disabled} value={endDate} handleChange={this.handleEndDateChange} />*/}
      </div>
    );
  }
}
