/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    RESET_RETENTION, RETENTION_GRAPH,
    RETENTION_GRAPH_PENDING, RETENTION_GRAPH_FAILED,
    UNINSTALL_DISTRIBUTION_PENDING, UNINSTALL_DISTRIBUTION,
    UNINSTALL_DISTRIBUTION_FAILED, RETENTION_GRAPH_FTU_PENDING, RETENTION_GRAPH_FTU_FAILED,
    RETENTION_GRAPH_FTU, RETENTION_GRAPH_RETURN, RETENTION_GRAPH_RETURN_PENDING, RETENTION_GRAPH_RETURN_FAILED,
    RETENTION_EVENTS, RETENTION_EVENT_ATTRIBUTES, RETENTION_EVENT_ATTRIBUTE_VALUES,
    RETENTION_UPDATE_QUERY_FIRST_EVENT, RETENTION_UPDATE_QUERY_NEXT_EVENT, UPDATE_RETENTION_TYPE,
    RETENTION_ENUM, UPDATE_RETENTION_USAGE_TYPE, RETENTION_USAGE, RETENTION_USAGE_COUNT, RETENTION_DAY_WINDOW
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';

const INITIAL_STATE = {
    retention_data: {},
    retention_data_pending: false,
    retention_data_failed: false,
    retention_data_ftu: {},
    retention_data_ftu_pending: false,
    retention_data_ftu_failed: false,
    retention_data_return: {},
    retention_data_return_pending: false,
    retention_data_return_failed: false,
    uninstall_distribution_data: [],
    uninstall_distribution_data_pending: false,
    uninstall_distribution_data_failed: false,
    event_attribute_values:[],
    events: [],
    event_attributes: {},
    query: {
        first_event: {},
        next_event:{}
    },
    selectedValues:[],
    retention_type: RETENTION_ENUM.FTU,
    retention_usage: [],
    retention_usage_count: 0,
    retention_usage_type: 1,
    retention_day_window: 1,
    retention_header: []
};

const INITIAL_FLAG_STATE = {
    retention_data_pending: false,
    retention_data_failed: false
};

const INITIAL_UNINSTALL_DISTRIBUTION_FLAG_STATE = {
    uninstall_distribution_data_pending: false,
    uninstall_distribution_data_failed: false
};

function retentionReducer(state = INITIAL_STATE, action = {}) {
    let query = state.query;
    switch (action.type) {

        case RETENTION_DAY_WINDOW:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                retention_day_window: action.payload
            })

        case RETENTION_GRAPH_PENDING:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                retention_data_pending: true
            });

        case RETENTION_GRAPH_FAILED:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                retention_data_failed: true
            });

        case RETENTION_GRAPH:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                retention_data: {
                    "days": action.payload.days,
                    "weeks": action.payload.weeks,
                    "months": action.payload.months
                },
                retention_header: action.payload.header,
            });

        case RETENTION_GRAPH_FTU_PENDING:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                retention_data_ftu_pending: true
            });

        case RETENTION_GRAPH_FTU_FAILED:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                retention_data_ftu_failed: true
            });

        case RETENTION_GRAPH_FTU:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                retention_data_ftu: action.payload
            });

        case RETENTION_GRAPH_RETURN_PENDING:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                retention_data_return_pending: true
            });

        case RETENTION_GRAPH_RETURN_FAILED:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                retention_data_return_failed: true
            });

        case RETENTION_GRAPH_RETURN:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                retention_data_return: action.payload
            });

        case UNINSTALL_DISTRIBUTION_PENDING:
            return Object.assign({}, state, INITIAL_UNINSTALL_DISTRIBUTION_FLAG_STATE, {
                uninstall_distribution_data_pending: true
            });

        case UNINSTALL_DISTRIBUTION_FAILED:
            return Object.assign({}, state, INITIAL_UNINSTALL_DISTRIBUTION_FLAG_STATE, {
                uninstall_distribution_data_failed: true
            });

        case UNINSTALL_DISTRIBUTION:
            return Object.assign({}, state, INITIAL_UNINSTALL_DISTRIBUTION_FLAG_STATE, {
                uninstall_distribution_data: action.payload
            });

        case RETENTION_EVENTS:
            return {
                ...state,
                events: action.payload,
                event_attributes: action.payload.reduce((a, b) => {a[b] = []; return a;}, {}),
                event_attribute_values: action.payload.reduce((a, b) => {a[b] = {}; return a;}, {}),
            };

        case RETENTION_EVENT_ATTRIBUTES:
            return {
                ...state,
                event_attributes: {
                    ...state.event_attributes,
                    [action.meta.event]: action.payload
                },
                event_attribute_values: {
                    ...state.event_attribute_values,
                    [action.meta.event]: action.payload.reduce((a, b) => {a[b] = []; return a;}, {})
                }
            };

        case RETENTION_EVENT_ATTRIBUTE_VALUES:
            const { event, attribute } = action.meta;
            let selectedValues = [];
            if(query.first_event.hasOwnProperty(event)) {
                selectedValues = query.first_event[event].hasOwnProperty(attribute) ? query.first_event[event][attribute] : query.next_event[event][attribute] || [];
            }
            return {
                ...state,
                event_attribute_values: {
                    ...state.event_attribute_values,
                    [event]: {
                        ...state.event_attribute_values[event],
                        [attribute]: [...selectedValues, ...action.payload]
                    }
                }
            };

        case RETENTION_UPDATE_QUERY_FIRST_EVENT:
            return {
                ...state,
                query: {
                    ...state.query,
                    first_event: action.payload || {}
                }
            };

        case RETENTION_UPDATE_QUERY_NEXT_EVENT:
            return {
                ...state,
                query: {
                    ...state.query,
                    next_event: action.payload || {}
                }
            };

        case UPDATE_RETENTION_TYPE:
            return {
                ...state,
                retention_type: action.payload
            };

        case UPDATE_RETENTION_USAGE_TYPE:
            return {
                ...state,
                retention_usage_type: action.payload
            };

        case RETENTION_USAGE:
            return {
                ...state,
                retention_usage: action.payload
            };

        case RETENTION_USAGE_COUNT:
            return {
                ...state,
                retention_usage_count: action.payload
            };


        case RESET_RETENTION:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default retentionReducer;