import {
    RESET_ALL, GLOBAL_FILTERS_CHANGED, DO_SAVE_STATE,
    NAVIGATION_LINK_CLICK
} from '../constants/ActionTypes';

/**
 *
 * @returns {function(*, *): *}
 */
export function resetAll(){
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_ALL,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @param applied
 * @returns {function(*, *): *}
 */
export function changeInGlobalFilters(applied = true){ //TODO: better haves names of filters changed
    return (dispatch, getState) => {
        return dispatch({
            type: GLOBAL_FILTERS_CHANGED,
            payload: applied,
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @returns {function(*, *): *}
 */
export function navigationClicked(){
    return (dispatch, getState) => {
        return dispatch({
            type: NAVIGATION_LINK_CLICK,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
}

/**
 *
 * @returns {function(*, *): *}
 */
export function doSaveState(){
    return (dispatch, getState) => {
        return dispatch({
            type: DO_SAVE_STATE,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
}

