/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
     DROP_OFF_BY_SCREENS,DROP_OFF_BY_EVENTS,
     DROP_OFF_SCREEN_EVENTS,DROP_OFF_SCREEN_LAST_EVENTS,
     UPDATE_DROP_OFF_GROUP, UPDATE_DROP_OFF_SEGMENT,
     UPDATE_DROP_OFF_FILTERS, UPDATE_DROP_OFF_SCREEN, UPDATE_DROP_OFF_CURRENT_ACTIVE,
     DROP_OFF_SESSIONS_COUNT, DROP_OFF_SCREENS_COUNT,
     DROP_OFF_EVENTS_COUNT , DROP_OFF_SCREEN_TIME_SPENT,
     DROP_OFF_ENUM, DROP_OFF_EVENT_ATTRIBUTES,
     DROP_OFF_SCREEN_EVENT_ATTRIBUTES, DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES,
     DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION, DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION,
     DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION, UPDATE_DROP_OFF_EVENT,
     UPDATE_DROP_OFF_SCREEN_EVENT, UPDATE_DROP_OFF_SCREEN_LAST_EVENT, UPDATE_DROP_TIME_SLOT,
     DROP_OFF_EVENT_USERS, DROP_OFF_SCREEN_USERS, DROP_OFF_SCREEN_EVENT_USERS, DROP_OFF_SCREEN_LAST_EVENT_USERS
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';
import {USER_GROUP_ENUM} from "../../../../../../constants";
import {makeDonutData} from "../../../../../../utils";

const { SCREEN} = DROP_OFF_ENUM;

const INITIAL_STATE = {
    current: SCREEN, //current active grouped by (for UI purpose)
    group: USER_GROUP_ENUM.ALL_USERS,
    user: [], //filters
    session: [], //filters,
    event: [],
    segmentId: null,
    session_count: 0,
    screen_count: 0,
    event_count: 0,
};

const INITIAL_FLAG_STATE = {
    by_screen: [],
    screen: null, //FIXME: change it to current_screen
    timeSlot: null,
    current_event: null,
    current_screen_event: null,
    current_screen_last_event: null,
    screen_time_spent: [],
    screen_events: [],
    screen_last_events: [],
    by_event: [],
    event_attributes: [],
    screen_event_attributes: [],
    screen_last_event_attributes: [],
    event_attribute_distribution: {},
    screen_event_attribute_distribution: {},
    screen_last_event_attribute_distribution: {},
    screen_users: [],
    screen_event_users: [],
    screen_last_event_users: [],
    event_users: []
};

function dropOffReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case DROP_OFF_SESSIONS_COUNT:
            return {
                ...state,
                session_count: action.payload
            };

        case DROP_OFF_SCREENS_COUNT:
            return {
                ...state,
                screen_count: action.payload
            };

        case DROP_OFF_EVENTS_COUNT:
            return {
                ...state,
                event_count: action.payload
            };

        case DROP_OFF_BY_SCREENS:
            return {
                ...state,
                by_screen: action.payload
            };

        case DROP_OFF_BY_EVENTS:
            return {
                ...state,
                by_event: action.payload
            };

        case DROP_OFF_SCREEN_EVENTS:
            return {
                ...state,
                screen_events: action.payload
            };

        case DROP_OFF_SCREEN_LAST_EVENTS:
            return {
                ...state,
                screen_last_events: action.payload
            };

        case DROP_OFF_SCREEN_TIME_SPENT:
            return {
                ...state,
                screen_time_spent: action.payload
            };

        case UPDATE_DROP_OFF_SCREEN:
            return {
                ...state,
                current_screen_event: null,
                current_screen_last_event: null,
                screen: action.payload,
                screen_event_attributes: [],
                screen_event_attribute_distribution: {},
                screen_last_event_attributes: [],
                screen_last_event_attribute_distribution: {}
            };

        case UPDATE_DROP_OFF_EVENT:
            return {
                ...state,
                current_event: action.payload
            };

        case UPDATE_DROP_OFF_SCREEN_EVENT:
            return {
                ...state,
                current_screen_event: action.payload
            };

        case UPDATE_DROP_OFF_SCREEN_LAST_EVENT:
            return {
                ...state,
                current_screen_last_event: action.payload
            };

        case UPDATE_DROP_OFF_GROUP:
            return {
                ...state,
                group: action.payload
            };

        case UPDATE_DROP_OFF_SEGMENT:
            return {
                ...state,
                segmentId: action.payload
            };

        case UPDATE_DROP_TIME_SLOT:
            return {
                ...state,
                timeSlot: action.payload
            };

        case UPDATE_DROP_OFF_FILTERS:
            return {
                ...state,
                ...action.payload
            };

        case UPDATE_DROP_OFF_CURRENT_ACTIVE:
            return {
                ...state,
                ...INITIAL_FLAG_STATE,
                current: action.payload
            };

        case DROP_OFF_EVENT_ATTRIBUTES:
            return {
                ...state,
                event_attributes: action.payload,
                event_attribute_distribution: {}
            };

        case DROP_OFF_SCREEN_EVENT_ATTRIBUTES:
            return {
                ...state,
                screen_event_attributes: action.payload,
                screen_event_attribute_distribution: {}
            };

        case DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES:
            return {
                ...state,
                screen_last_event_attributes: action.payload,
                screen_last_event_attribute_distribution: {}
            };

        case DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION:
            return {
                ...state,
                event_attribute_distribution: {
                    ...state.event_attribute_distribution,
                    [action.meta.attribute]: makeDonutData(action.payload)
                }
            };

        case DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION:
            return {
                ...state,
                screen_event_attribute_distribution: {
                    ...state.screen_event_attribute_distribution,
                    [action.meta.attribute]: makeDonutData(action.payload)
                }
            };

        case DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION:
            return {
                ...state,
                screen_last_event_attribute_distribution: {
                    ...state.screen_last_event_attribute_distribution,
                    [action.meta.attribute]: makeDonutData(action.payload)
                }
            };

        case DROP_OFF_SCREEN_USERS:
            return {
                ...state,
                screen_users: action.payload
            };
        case DROP_OFF_SCREEN_EVENT_USERS:
            return {
                ...state,
                screen_event_users: action.payload
            };

        case DROP_OFF_SCREEN_LAST_EVENT_USERS:
            return {
                ...state,
                screen_last_event_users: action.payload
            };

        case DROP_OFF_EVENT_USERS:
            return {
                ...state,
                event_users: action.payload
            };

        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default dropOffReducer;