import React, { Component } from 'react';
import {
    getConversionRate
} from '../../actions';
import CountBox from "../../../../../../../../components/reusable/MaterialUi/CountBox";
import withSelfComponent from '../SelfComponent';
import {session} from "../../../../../../../../utils/Storage";
import {formatNumber} from "../../../../../../../../utils";

class Count extends Component {

    hydrator = (props = {}) => {
        const {
            startEvent, endEvent, dataKey,
            queryParams, extraFilters
        } = props.selfParams || {};
        const {dispatch, params: {appId}} = props;
        const filters = {...extraFilters}; //FIXME: why this ?
        dispatch(getConversionRate(appId, dataKey, {startEvent, endEvent, ...queryParams}, filters));
    };

    render(){
        const {
            self, parentCompLabel, selfParams: { startEvent, endEvent, dataKey, customLabel } = {},
            meta: { api_pending }
        } = this.props;
        const data = self[dataKey] || {[startEvent]: 0, rate: 0, [endEvent]: 0};
        const title = `${endEvent} ${data[endEvent]} / ${startEvent} - ${data[startEvent]}`;
        if(!api_pending){
            let obj = {};
            obj[customLabel] = `${data.rate} , ${title}`;
            console.log(JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )))
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        return <CountBox subheading={`${customLabel}`} heading={
            <span title={title}>{formatNumber(data.rate, 1)}</span>
        } />;
    };
}

Count.propTypes = {

};

export default withSelfComponent(Count);