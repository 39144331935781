import React, { Component } from 'react';
import {
    getEventRelatedCountDistribution
} from '../actions';
import {
    addedWithAverageKeys, graphDataToCSVString, minMaxAverageOf, toTitleCase
} from "../../../../../../../utils";
import withSelfComponent from "./SelfComponent";
import {session} from "../../../../../../../utils/Storage";
import MultiUtilityChart from "../../../../../../../components/reusable/Recharts/MultiUtilityChart";

class DistributionOverEvent extends Component {

    state = {
        showAggregations: false
    };

    hydrator = (props = this.props) => {
        const {
            event, dataKey,
            queryParams, extraFilters, of
        } = props.selfParams;
        const {dispatch, params: {appId}} = props;
        dispatch(getEventRelatedCountDistribution(appId, event, dataKey, queryParams, extraFilters, of));
    };

    render(){
        const {
            self, selfParams: { dataKey, customLabel, event, of},
            meta: { api_pending }, parentCompLabel
        } = this.props;
        let data = self[dataKey] || [];
        if(!api_pending) {
            let obj = {};
            obj[customLabel] = graphDataToCSVString(customLabel, data, "Frequency");
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        data = addedWithAverageKeys(data, [of]);
        const stats = minMaxAverageOf(data, of);
        return(
            <MultiUtilityChart
                {...this.props}
                title={customLabel}
                height={300}
                withHeader
                dataKey="key"
                yLabel={toTitleCase(of)}
                xLabel={event + " Count"}
                data={ data }
                lineDataKeys={[of]}
                stats={stats}
                fullWidth
                showAverage
                showBrush
                xLabelFormatter={x => x}
            />
        )

    }
}

DistributionOverEvent.propTypes = {

};

export default withSelfComponent(DistributionOverEvent);