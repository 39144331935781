/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 2:44 PM
 */

import {Typography} from "@material-ui/core";
import React from 'react';
import PropTypes from 'prop-types'

class FieldDescription extends React.Component {
    render() {
        const {title, description, required, titleProps} = this.props;
        return (
            <div>
                <Typography variant={"h6"} style={{
                    fontWeight: 600,
                    lineHeight: 1,
                    marginBottom: description ? 4 : 8,
                    verticalAlign: "middle",
                    ...titleProps
                }}>
                    {title} {required &&
                <span style={{fontSize: 12, color: "#81838a", fontStyle: "italic"}}> (Required) </span>}
                </Typography>
                {description && <Typography variant={"subtitle1"} style={{lineHeight: "1.2em", color: "#7d7f87"}}>
                    {description}
                </Typography>}
            </div>
        );
    }
}

FieldDescription.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string
};

export default FieldDescription;