import {routerReducer as routing} from 'react-router-redux';
import {combineReducers} from 'redux';
import queryBuilderReducer from '../components/reusable/QueryBuilder/reducer';
import authReducer from '../containers/Root/Application/Auth/reducer';
import reportsReducer from "../containers/Root/Application/DailyReports/reducer";
import appReducer from '../containers/Root/Application/RequireAuth/Apps/App/reducer';
import appsReducer from '../containers/Root/Application/RequireAuth/Apps/reducer';
import messageAnalysisReducer
    from "../containers/Root/Application/RequireAuth/Dashboard/Campaigns/Messages2/Analysis/reducer";
import designPreviewReducer
    from "../containers/Root/Application/RequireAuth/Dashboard/Campaigns/Messages2/components/Flow/components/DesignPreview/reducer";
import messagesReducer2 from '../containers/Root/Application/RequireAuth/Dashboard/Campaigns/Messages2/reducer';
import cohortsReducer from "../containers/Root/Application/RequireAuth/Dashboard/Cohorts/reducer";

import contextsReducer from '../containers/Root/Application/RequireAuth/Dashboard/Contexts/reducer';
import customQueryBuilderReducer
    from '../containers/Root/Application/RequireAuth/Dashboard/CustomDashboards/CustomQuery/reducer';
import dashboardsReducer from '../containers/Root/Application/RequireAuth/Dashboard/CustomDashboards/reducer';
import customReportReducer from '../containers/Root/Application/RequireAuth/Dashboard/CustomReports/reducer';
import dropOffReducer from '../containers/Root/Application/RequireAuth/Dashboard/DropOff/reducer';
import eventAnalysisReducer from '../containers/Root/Application/RequireAuth/Dashboard/EventAnalysis/reducer';
import funnelsReducer from "../containers/Root/Application/RequireAuth/Dashboard/Funnels/reducer";
import messagesReducer from '../containers/Root/Application/RequireAuth/Dashboard/Messages/reducer';
import miscReducer from '../containers/Root/Application/RequireAuth/Dashboard/Misc/reducer';
import pathReducer from '../containers/Root/Application/RequireAuth/Dashboard/Path/reducer';
import preprocessReducer from '../containers/Root/Application/RequireAuth/Dashboard/Preprocess/reducer';
import pushReducer from '../containers/Root/Application/RequireAuth/Dashboard/Push/reducer';
import retentionReducer from '../containers/Root/Application/RequireAuth/Dashboard/Retention/reducer';
import segmentBuilderReducer from '../containers/Root/Application/RequireAuth/Dashboard/Segments/NewSegment/reducer';
import segmentReducer from '../containers/Root/Application/RequireAuth/Dashboard/Segments/reducer';
import selfReducer from '../containers/Root/Application/RequireAuth/Dashboard/SelfComponents/reducer';
import {eventsManagementReducer} from "../containers/Root/Application/RequireAuth/Dashboard/Settings/EventsManagement/reducer";
import generalSettingsReducer from "../containers/Root/Application/RequireAuth/Dashboard/Settings/General/reducer";
import iamReducer from '../containers/Root/Application/RequireAuth/Dashboard/Settings/IAM/reducer';
import sdkSettingsReducer from '../containers/Root/Application/RequireAuth/Dashboard/Settings/SDK/reducer';
import surveysReducer from '../containers/Root/Application/RequireAuth/Dashboard/Surveys/reducer';
import uninstallReducer from '../containers/Root/Application/RequireAuth/Dashboard/Uninstalls/reducer';
import settingsDataReducer from "../containers/Root/Application/RequireAuth/Dashboard/UserData/reducer";
import userReducer from '../containers/Root/Application/RequireAuth/Dashboard/Users/User/reducer';
import userExplorerReducer from '../containers/Root/Application/RequireAuth/Dashboard/Users/UserExplorer/reducer';
import filtersReducer from '../containers/Root/Application/RequireAuth/Filters/reducer';
import navigationReducer from '../containers/Root/Application/RequireAuth/Navigation/reducer';
import profileReducer from "../containers/Root/Application/RequireAuth/Profile/reducer";
import metaReducer from './metaReducer';

const rootReducer = combineReducers({
    app: appReducer,
    apps: appsReducer,
    appSettings: generalSettingsReducer,
    auth: authReducer,
    cohorts: cohortsReducer,
    contexts: contextsReducer,
    customerProfile: profileReducer,
    customQueryBuilder: customQueryBuilderReducer,
    customReport: customReportReducer,
    dailyReport: reportsReducer,
    dashboards: dashboardsReducer,
    designPreview: designPreviewReducer,
    dropOff: dropOffReducer,
    eventAnalysis: eventAnalysisReducer,
    eventsManagement: eventsManagementReducer,
    filters: filtersReducer,
    funnels: funnelsReducer,
    iam: iamReducer,
    messages: messagesReducer,
    messages2: messagesReducer2,
    messageAnalysis: messageAnalysisReducer,
    meta: metaReducer,
    misc: miscReducer,
    navigation: navigationReducer,
    path: pathReducer,
    preprocess: preprocessReducer,
    push: pushReducer,
    queryBuilder: queryBuilderReducer,
    retention: retentionReducer,
    routing,
    sdkSettings: sdkSettingsReducer,
    segment: segmentReducer,
    settingsData: settingsDataReducer,
    segmentBuilder: segmentBuilderReducer,
    self: selfReducer,
    surveys: surveysReducer,
    uninstalls: uninstallReducer,
    user: userReducer,
    userExplorer: userExplorerReducer,
});

export default rootReducer
