/**
 * Created by Ravi Teja
 * Date: 17-Jan-2020
 * Time: 12:30 PM
 */

export const MESSAGE_ANALYSIS_PENDING = "MESSAGE_ANALYSIS_PENDING";
export const MESSAGE_ANALYSIS_STEP_METRICS = "MESSAGE_ANALYSIS_STEP_METRICS";
export const MESSAGE_ANALYSIS_FAILED = "MESSAGE_ANALYSIS_FAILED";

export const MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES = "MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES";
export const MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_PENDING = "MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_PENDING";
export const MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_FAILED = "MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_FAILED";

export const MESSAGE_FUNNEL_ANALYSIS_PENDING = "MESSAGE_FUNNEL_ANALYSIS_PENDING";
export const MESSAGE_FUNNEL_ANALYSIS = "MESSAGE_FUNNEL_ANALYSIS";
export const MESSAGE_FUNNEL_ANALYSIS_FAILED = "MESSAGE_FUNNEL_ANALYSIS_FAILED";

export const MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_PENDING= "MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_PENDING";
export const MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES = "MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES";
export const MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_FAILED = "MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_FAILED";

export const RESET_MESSAGE_ANALYSIS = "RESET_MESSAGE_ANALYSIS";