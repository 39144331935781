/**
 * Created by jyothi on 12/10/17.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getAttributes, getAttributeValues,
    getEvents, getEventAttributes, getEventAttributeValues,
    updateQuery, saveSegment, getSegmentCount, resetQuery, updateGroup, updateSegment
} from './actions';
import {updateHeading, resetHeading } from '../../../Navigation/actions';
import {
    ComponentHolder,
    CustomQueryBuilder
} from './components';
import Grid from "@material-ui/core/Grid";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        meta: state.meta,
        filters: state.filters,
        customQueryBuilder: state.customQueryBuilder
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetHeading: () => dispatch(resetHeading()),
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getAttributes: (appId) => dispatch(getAttributes(appId)),
        getAttributeValues: (appId, attribute, q) => dispatch(getAttributeValues(appId, attribute, q)),
        getEvents: (appId) => dispatch(getEvents(appId)),
        getEventAttributes: (appId, event) => dispatch(getEventAttributes(appId, event)),
        getEventAttributeValues: (appId, event, attribute, q) => dispatch(getEventAttributeValues(appId, event, attribute, q)),
        handleQueryUpdate: (query) => dispatch(updateQuery(query)),
        handleGroupUpdate: (group) => dispatch(updateGroup(group)),
        handleSegmentUpdate: (segment) => dispatch(updateSegment(segment)),
        saveSegment: (appId) => dispatch(saveSegment(appId)),
        getSegmentCount: (appId) => dispatch(getSegmentCount(appId)),
        resetQuery: () => dispatch(resetQuery())
    };
}

class CustomQuery extends Component {

    constructor(props) {

        super(props);
        this.appId = this.props.params.appId;
        this.state = {
            showData: false
        }

    }

    componentWillMount(){
        const { getAttributes, getEvents, getSegmentCount } = this.props;
        //updateHeading("New Segment");
        getAttributes(this.appId);
        getEvents(this.appId);
        getSegmentCount(this.appId);
    }

    componentWillUnmount(){
        this.props.resetQuery();
    }



    render() {
        const {
            customQueryBuilder: { query }
        } = this.props;
        const { showData } = this.state;
        return (
            <Grid container>
                <Grid item xs>
                    <CustomQueryBuilder
                        {...this.props}
                        {...query}
                        handleFiltersChange={(filtersChanged) => this.setState({showData: !filtersChanged})}
                        readyToApply={!showData}
                    />
                    { showData && <ComponentHolder {...this.props} /> }
                </Grid>
            </Grid>
        );
    }
}

CustomQuery.propTypes = {

};

CustomQuery.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomQuery);