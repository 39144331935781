/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */


export const RESET_SEGMENT = "RESET_SEGMENT";

export const CORRELATIONS_SEGMENT_RETENTION_PENDING = "CORRELATIONS_SEGMENT_RETENTION_PENDING";
export const CORRELATIONS_SEGMENT_RETENTION = "CORRELATIONS_SEGMENT_RETENTION";
export const CORRELATIONS_SEGMENT_RETENTION_FAILED = "CORRELATIONS_SEGMENT_RETENTION_FAILED";

export const CORRELATIONS_RETENTION_PENDING = "CORRELATIONS_RETENTION_PENDING";
export const CORRELATIONS_RETENTION = "CORRELATIONS_RETENTION";
export const CORRELATIONS_RETENTION_FAILED = "CORRELATIONS_RETENTION_FAILED";

export const CORRELATIONS_DAY1_UNINSTALLS_PENDING = "CORRELATIONS_DAY1_UNINSTALLS_PENDING";
export const CORRELATIONS_DAY1_UNINSTALLS_FAILED = "CORRELATIONS_DAY1_UNINSTALLS_FAILED";
export const CORRELATIONS_DAY1_UNINSTALLS = "CORRELATIONS_DAY1_UNINSTALLS";

export const CORRELATIONS_TIMESERIES_PENDING = "CORRELATIONS_TIMESERIES_PENDING";
export const CORRELATIONS_TIMESERIES_FAILED = "CORRELATIONS_TIMESERIES_FAILED";
export const CORRELATIONS_TIMESERIES = "CORRELATIONS_TIMESERIES";

export const CORRELATION_SEGMENTLIST_PENDING = "CORRELATION_SEGMENTLIST_PENDING";
export const CORRELATION_SEGMENTLIST = "CORRELATION_SEGMENTLIST";
export const CORRELATION_SEGMENTLIST_FAILED = "CORRELATION_SEGMENTLIST_FAILED";

export const UPDATE_SEGMENTS = "UPDATE_SEGMENTS";
export const UPDATE_SEGMENTS_FAILED = "UPDATE_SEGMENTS_FAILED";
export const UPDATE_SEGMENTS_PENDING = "UPDATE_SEGMENTS_PENDING";

export const SEGMENT_COUNT_PENDING = "SEGMENT_COUNT_PENDING";
export const SEGMENT_COUNT_FAILED = "SEGMENT_COUNT_FAILED";
export const SEGMENT_COUNT = "SEGMENT_COUNT";

export const DELETE_SEGMENT_PENDING = "DELETE_SEGMENT_PENDING";
export const DELETE_SEGMENT = "DELETE_SEGMENT";
export const DELETE_SEGMENT_FAILED = "DELETE_SEGMENT_FAILED";

export const CORRELATIONS_UNINSTALL_TIMESERIES_PENDING = "CORRELATIONS_UNINSTALL_TIMESERIES_PENDING";
export const CORRELATIONS_UNINSTALL_TIMESERIES = "CORRELATIONS_UNINSTALL_TIMESERIES";
export const CORRELATIONS_UNINSTALL_TIMESERIES_FAILED = "CORRELATIONS_UNINSTALL_TIMESERIES_FAILED";

export const CORRELATIONS_SEGMENT_UNINSTALL_TIMESERIES_PENDING = "CORRELATIONS_SEGMENT_UNINSTALL_TIMESERIES_PENDING";
export const CORRELATIONS_SEGMENT_UNINSTALL_TIMESERIES = "CORRELATIONS_SEGMENT_UNINSTALL_TIMESERIES";
export const CORRELATIONS_SEGMENT_UNINSTALL_TIMESERIES_FAILED = "CORRELATIONS_SEGMENT_UNINSTALL_TIMESERIES_FAILED";


export const SEGMENT_PENDING = "SEGMENT_PENDING";
export const SEGMENT_FAILED = "SEGMENT_FAILED";

export const SEGMENT_USER_COUNT = "SEGMENT_USER_COUNT";
export const SEGMENT_SESSION_COUNT = "SEGMENT_SESSION_COUNT";
export const SEGMENT_USER_ATTRIBUTE_DISTRIBUTION = "SEGMENT_USER_ATTRIBUTE_DISTRIBUTION";
export const SEGMENT_SESSION_ATTRIBUTE_DISTRIBUTION = "SEGMENT_SESSION_ATTRIBUTE_DISTRIBUTION";
export const SEGMENT_CURRENT_EVENT_ATTRIBUTE_DISTRIBUTION = "SEGMENT_CURRENT_EVENT_ATTRIBUTE_DISTRIBUTION";
export const SEGMENT_TIME_SERIES = "SEGMENT_TIME_SERIES";

export const SEGMENT_RETENTION_PENDING = "SEGMENT_RETENTION_PENDING";
export const SEGMENT_RETENTION = "SEGMENT_RETENTION";
export const SEGMENT_RETENTION_FAILED = "SEGMENT_RETENTION_FAILED";

export const UPDATE_SEGMENT_USER_GROUP = "UPDATE_SEGMENT_USER_GROUP";

export const UPDATE_SEGMENT_ATTRIBUTE_FILTERS = "UPDATE_SEGMENT_ATTRIBUTE_FILTERS";

export const UPDATE_SEGMENT_GROUP_BY_ATTRIBUTE = "UPDATE_SEGMENT_GROUP_BY_ATTRIBUTE";

export const SEGMENT_GROUP_BY_ATTRIBUTE = "SEGMENT_GROUP_BY_ATTRIBUTE";

export const UPDATE_SEGMENT_CURRENT_EVENT = "UPDATE_SEGMENT_CURRENT_EVENT";

export const SEGMENT_CURRENT_EVENT_ATTRIBUTES = "SEGMENT_CURRENT_EVENT_ATTRIBUTES";

export const COHORT_UPLOAD_DONE = "COHORT_UPLOAD_DONE";
export const COHORT_UPLOAD_PENDING = "COHORT_UPLOAD_PENDING";
export const COHORT_UPLOAD_FAILED = "COHORT_UPLOAD_FAILED";
export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const OPEN_DIALOG = "OPEN_DIALOG";
export const ERROR_MESSAGE = "ERROR_MESSAGE";