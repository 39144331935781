import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from "@material-ui/core/styles";
import MobileSimulator from "../MobileSimulator";
import logger from "../../../utils/Logger";
import InLineMobileSimulator
    from "../../../containers/Root/Application/RequireAuth/Dashboard/Messages/components/InLineMobileSimulator";
import classNames from 'classnames';
import Typography from "@material-ui/core/Typography";
import CircularProgress from '@material-ui/core/CircularProgress';
import {withQueryStrings} from "../../../utils";
import Button from "@material-ui/core/Button";
import axios from 'axios';
import Snackbar from "../MaterialUi/Snackbar";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
    },
    img: {
        width: '100%',
        height: '100%',
        position: 'absolute'
    },
    view: {
        border: '1px solid #F1F1F1',
        '&:hover': {
            border: '1px solid #66FF66',
            boxShadow: '0px 0px 4px 4px #0ca169'
        },
        cursor: 'pointer'
    },
    preventPointer: {
        pointerEvents: 'none'
    },
    waiting: {
        textAlign: 'center',
        marginTop: '60%'
    }
});

const SSE_API = window.SSE_ROOT + 'sse-layout?';
const UI_CONFIG_API = window.SSE_ROOT + "ui-config?";

const WIDTH = 294;
const HEIGHT = 462;

const getRatio = (deviceInfo = {}) => {
    const { width, height } = deviceInfo;
    return {
      w: WIDTH / width,
      h: HEIGHT / height
    };
};

const updatedBounds = (bounds, ratio) => {
    const { w, h } = ratio;
    const { top, bottom, left, right } = bounds;
    const newBounds = {
        top: Math.round(top * h),
        bottom: Math.round(bottom * h),
        left: Math.round(left * w),
        right: Math.round(right * w)
    };
    return {
        ...newBounds,
        width: newBounds.right - newBounds.left,
        height: newBounds.bottom - newBounds.top
    }
};

const resizedLayout = (layout = [], ratio) => {
    return layout.map(({bounds, views, ...others}) => ({
        ...others,
        ...updatedBounds(bounds, ratio),
        views: resizedLayout(views, ratio)
    }));
};

const computedStyles = (view, index) => {
    const { width, height, left, top } = view;
    return {
        width, height, left, top,
        position: 'absolute',
        zIndex: index,
    };
};

const selectedStyles = {
    border: '1px solid #66FF66',
    boxShadow: '0px 0px 4px 4px #0ca169',
    zIndex: 999
};

const selectedImageStyles = (img = {}, {left, top}) => ({
    backgroundImage: `url(${img.src})`,
    backgroundSize: `${WIDTH}px ${HEIGHT}px`,
    backgroundPosition: `-${left}px -${top}px`,
    backgroundRepeat: 'no-repeat'
});

class WYSIWYGMobile extends React.Component{

    constructor(props){
        super(props);
        const {
            appId,
            config: { device_info = {}, screen = {}, layout = [], selected = null } = {},
            disabled = false
        } = this.props;
        this.state = {
            device_info, screen, layout, selected,
            img: {}, //for image ref
            previewSuccess: false,
            previewPending: false,
            previewFailed: false
        };
        this.queryStrings = withQueryStrings({appId});
        if(!disabled){
            this.initEventSource();
        }
    }

    componentWillReceiveProps(nextProps){
        const { layout: existingLayout = [] } = this.state;
        if(nextProps.needUpdate || Array.isArray(existingLayout) && existingLayout.length === 0){ //updating only if don't have in state
            const {
                config: { device_info = {}, screen = {}, layout = [], selected = null } = {},
            } = nextProps;
            this.setState({ device_info, screen, layout, selected }, () => {
                this.setScreenImage(screen.image);
            });
        }
    }

    initEventSource = () => {
        this.eventSource = new EventSource(SSE_API + this.queryStrings);
        this.eventSource.onmessage = e => {
            this.setState({selected: null});
            try{
                const data = JSON.parse(e.data);
                logger.info(data);
                this.updateLayout(data);
            } catch (err) {
                logger.error("Error in SSE", err);
            }
        };
    };

    updateLayout = (data) => {
        const { device_info, layout } = data;
        const ratio = getRatio(device_info);
        const syncedLayout = resizedLayout(layout, ratio); //Better name please
        logger.info(syncedLayout);
        this.hydrate({...data, layout: syncedLayout});
    };

    setScreenImage = (image) => {
        if(image){
            this.img.src = `data:image/png;base64,${image}`;
        }
    };

    hydrate = (data) => {
        this.setState({...data});
        const { screen: { image = "", navigation } = {} } = data;
        this.setScreenImage(image);
        const { handleViewIdUpdate = () => null, handleNavigationUpdate = () => null } = this.props;
        handleViewIdUpdate("");
        handleNavigationUpdate(navigation);
        this.setState({img: this.img});
    };

    handleClick = (e, view, id) => {
        e.stopPropagation();
        const { selected } = this.state;
        const haveToClear = selected === id;
        const { handleViewIdUpdate = () => null, handleConfigUpdate = () => null } = this.props;
        handleViewIdUpdate(haveToClear ? "" : view.id);
        this.setState({selected: haveToClear ? null : id}, () => {
            const {
                root, img, previewSuccess, previewPending, previewFailed,
                ...others
            } = this.state;
            handleConfigUpdate(others);
        });
        logger.info(view);
    };

    componentDidMount(){
        this.setState({img: this.img}, () => {
            const { screen: { image } = {} } = this.state;
            this.setScreenImage(image);
        });
    }

    handlePreview = () => {
        const { selected } = this.state;
        if(selected && selected.length > 0){
            const { simulatorProps: { config = {} } = {}, appId } = this.props;
            const { ui: { extra_config, ...uiConfig } = {}, type } = config;
            this.setState({previewPending: true});
            axios.post(UI_CONFIG_API + this.queryStrings, {
                type,
                ui: uiConfig
            }).then((response) => {
                this.setState({previewSuccess: true});
            }).catch((error) => {
                this.setState({previewFailed: true});
                logger.error(error);
            }).finally(() => {
                this.setState({previewPending: false});
                setTimeout(() => {
                    this.setState({previewSuccess: false, previewFailed: false});
                }, 5000);
            });
        }
    };

    componentWillUnmount(){
        if(this.eventSource){
            this.eventSource.close(); //closing event source if open
        }
    }

    render(){
        const {
            classes, simulatorProps, disabled,
            simulatorProps: { config = {} }
        } = this.props;
        const {
            device_info, screen: { image = "", navigation }, layout = [],
            selected,
            previewSuccess, previewPending, previewFailed
        } = this.state;
        const readyToPreview = !disabled && selected && selected.length > 0 && config && Object.keys(config).length > 0;
        const layoutHTML = (views, baseIndex) => views.map((view, index) => {
            const id = view.id + view.view + index;
            const styles = {...computedStyles(view, baseIndex + index), ...(selected === id) && selectedStyles};
            const showInline = selected === id && view.id.length > 0;
            return(
                <Fragment key={id}>
                    {
                        showInline && <InLineMobileSimulator {...simulatorProps} view={view}>
                            <div
                                className={classNames({[classes.view]: !disabled, [classes.preventPointer]: disabled})}
                                onClick={e => this.handleClick(e, view, id)}
                                style={{...styles, ...selectedImageStyles(this.state.img, view)}}
                            >
                            </div>
                        </InLineMobileSimulator>
                    }
                    {
                        !showInline && <div
                            className={classNames({[classes.view]: !disabled, [classes.preventPointer]: disabled})}
                            onClick={e => this.handleClick(e, view, id)}
                            style={styles}
                            title={view.id}
                        >
                        </div>
                    }
                    {console.log("Level", baseIndex + index + 1)}
                    {layoutHTML(view.views, baseIndex + index + 1)}
                </Fragment>
            )
        });
        const hasImage = image && image.length > 0;
        return(
            <MobileSimulator
                deviceInfo={device_info}
                controls={
                    readyToPreview && <Tooltip title="Keep your App open with selected screen.">
                        <Button onClick={this.handlePreview} variant="contained" color="primary">
                            {previewPending ? <CircularProgress size={20}/> : 'Preview'}
                        </Button>
                    </Tooltip>
                }
            >
                {!disabled && !hasImage &&
                <div className={classes.waiting}>
                    <Typography gutterBottom>Waiting for device...</Typography>
                    <CircularProgress/>
                </div>
                }
                <img className={classes.img} ref={ref => this.img = ref} alt={navigation}/>
                <div ref={ref => this.root = ref} className={classes.root}>
                    {layoutHTML(layout, 1)}
                </div>
                {previewFailed && <Snackbar>Preview failed, try again.</Snackbar>}
                {previewSuccess && <Snackbar>Check you connected mobile for preview.</Snackbar>}
            </MobileSimulator>
        )
    }

}

WYSIWYGMobile.propTypes = {
    handleViewIdUpdate: PropTypes.func,
    handleNavigationUpdate: PropTypes.func,
    handleConfigUpdate: PropTypes.func,
    config: PropTypes.object,
    disabled: PropTypes.bool
};


export default withStyles(styles)(WYSIWYGMobile);