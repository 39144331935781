/**
 * Created by Rakesh Peela
 * Date: 14-Feb-2020
 * Time: 10:49 PM
 */

import {withStyles} from "@material-ui/core";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React from "react";
import {connect} from "react-redux";
import {updateHeading} from "../../Navigation/actions";
import FileDownloads from "./components";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        meta: state.meta,
        appState: state.app,
        data: state.settingsData
    }
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        boundDispatch: dispatch
    }
}

const styles = (theme) => ({
    content: {
        padding: '25px 15%',
        height: 'auto'
    },
    tabsRoot: {
        background: "#fff",
        paddingTop: 48,
        flex: "1 0 auto"
    },
    tabsIndicator: {
        height: 3,
        borderTopRightRadius: 8,
        borderTopLeftRadius: 8
    },
    tabRoot: {
        textTransform: 'initial',
        minWidth: 72,
        fontSize: 14,
        fontWeight: "500 !important",
        marginRight: theme.spacing.unit * 4,
        '&:focus': {
            // color: '#40a9ff',
        },
    },
});

const DATA_SECTIONS = ["Downloads"];

class UserData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSection: 0
        }
    }

    componentDidMount() {
        const {updateHeading} = this.props;
        updateHeading("Data");
    }

    handleDataTabsChange = (event, value) => {
        // let listOfSections = Object.keys(DATA_SECTIONS;
        // Apxor.logEvent("CampaignTabSelected", {tab: listOfSections[value]}, APXOR_SDK_CATEGORY_CONSTANTS.ACTIONS);
        this.setState({currentSection: value});
    };

    render() {
        const {classes, data: {downloadableFiles = []}, boundDispatch, appState: {app: {app_id}}, meta} = this.props;
        const {currentSection} = this.state;
        return (
            <>
                <section style={{
                    display: "flex",
                    flexDirection: "row",
                    marginBottom: 12,
                    background: "#fff",
                    borderBottom: '1px solid #e8e8e8',
                    padding: "0 15%",
                }}>
                    <Tabs
                        classes={{root: classes.tabsRoot, indicator: classes.tabsIndicator}}
                        value={currentSection}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.handleDataTabsChange}
                    >
                        {
                            Object.keys(DATA_SECTIONS).map(eachSection =>
                                <Tab
                                    disableRipple
                                    key={eachSection}
                                    classes={{root: classes.tabRoot}}
                                    label={DATA_SECTIONS[eachSection]}
                                />
                            )
                        }
                    </Tabs>
                </section>
                <section className={classes.content} style={{paddingTop: 0, paddingBottom: 96}}>
                    {
                        currentSection === 0
                        && <FileDownloads
                            dashboardMeta={meta}
                            appId={app_id}
                            dispatch={boundDispatch}
                            downloadableFiles={downloadableFiles}
                        />
                    }
                </section>
            </>
        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(UserData));