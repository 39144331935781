/**
 * Created by Rakesh Peela
 * Date: 15-Feb-2020
 * Time: 2:50 PM
 */

import React from 'react';
import Typography from "@material-ui/core/Typography";

class StatusIdentifier extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {statusType, palette, disabled} = this.props;
        return (
            <div style={{display: "inline-block"}}>
                <Typography
                    variant={"subtitle1"}
                    style={{
                        fontSize: 10,
                        fontWeight: 800,
                        borderRadius: 6,
                        padding: "0px 8px",
                        backgroundColor: (palette !== undefined && !disabled) ? palette[statusType].color : "#d4d4d4",
                        color: "#fff",
                        textTransform: "uppercase"
                    }}
                >
                    {statusType}
                </Typography>
            </div>
        )
    }
}

export default StatusIdentifier;