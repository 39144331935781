import React from 'react';
import LineChartIcon from '@material-ui/icons/ShowChart';
import PeopleIcon from '@material-ui/icons/People';
import BubbleChartIcon from '@material-ui/icons/BubbleChart';
import TableIcon from '@material-ui/icons/ViewList';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DistributionOverEvent from './components/DistributionOverEvent';
import UserCount from './components/UserCount';
import HourlyEventDistribution from './components/HourlyEventDistribution';
import Retention from "./components/Retention";
import MetricsTable from "./components/MetricsTable";
import Count from "./components/Count";
import Trend from "./components/Trend";
import AggregatedTrend from "./components/AggregatedTrend";
import TimeSpent from "./components/TimeSpent";
import SegmentsTrend from "./components/SegmentsTrend";
import RetentionTrend from "./components/RetentionTrend";
import TabbedWidget from "./components/TabbedWidget";
import Funnel from "./components/Funnel";
import Path from "./components/Path";
import RetentionUsage from "./components/RetentionUsage";
import VOVTimeSpent from "./components/VOVTimeSpent";
import VOVDAUPercent from "./components/VOVDAUPercent";
import VOVEventsPerDay from "./components/VOVEventsPerDay";
import Stickiness from './components/Stickiness';

import ConversionRateCount from './components/ConversionRate/Count';
import ConversionRateTrend from './components/ConversionRate/Trend';
import ConversionRateTabbedWidget from './components/ConversionRate/TabbedWidget';
import CustomReportsTable from "./components/CustomReportsTable";

export const COMPONENT_IDS = {
    USER_COUNT: "user-count",
    DISTRIBUTION_OVER_EVENT: "distribution-over-event",
    HOURLY_EVENT_DISTRIBUTION: "hourly-event-distribution",
    RETENTION: "retention",
    METRICS_TABLE: "metrics-table",
    COUNT: "count",
    TREND: "trend",
    AGGREGATED_TREND: "aggregated-trend",
    TIME_SPENT: "time-spent",
    SEGMENTS_TREND: "segments-trend",
    RETENTION_TREND: "retention-trend",
    TABBED_WIDGET: 'tabbed-widget',
    FUNNEL_WIDGET: 'funnel-widget',
    PATH_WIDGET: 'path-widget',
    RETENTION_USAGE_WIDGET: 'retention-usage-widget',
    VOV_TIME_SPENT: 'vov-time-spent',
    VOV_DAU_PERCENT: 'vov-dau-percent',
    VOV_AVG_EVENTS_PER_DAY: 'vov-avg-events-per-day',
    EVENT_STICKINESS: 'event-stickiness',
    CONVERSION_RATE_COUNT: 'conversion-rate-count',
    CONVERSION_RATE_TREND: 'conversion-rate-trend',
    CONVERSION_RATE_TABBED_WIDGET: 'conversion-rate-tabbed-widget',
    CUSTOM_REPORTS_TABLE: 'custom-reports-table',
};

export const {
    USER_COUNT, DISTRIBUTION_OVER_EVENT, HOURLY_EVENT_DISTRIBUTION,
    RETENTION, METRICS_TABLE, COUNT, TREND, TIME_SPENT, SEGMENTS_TREND,
    AGGREGATED_TREND, RETENTION_TREND, TABBED_WIDGET, FUNNEL_WIDGET, PATH_WIDGET,
    RETENTION_USAGE_WIDGET, VOV_AVG_EVENTS_PER_DAY, VOV_DAU_PERCENT, VOV_TIME_SPENT,
    EVENT_STICKINESS,
    CONVERSION_RATE_COUNT, CONVERSION_RATE_TREND, CONVERSION_RATE_TABBED_WIDGET,
    CUSTOM_REPORTS_TABLE
} = COMPONENT_IDS;

export const FREQUENCY_ENUM = {
    day: 'day',
    week: 'week',
    month: 'month',
    aggregate: 'aggregate' //groupBy, Add, Average
};

const Components = {
    widgets: [
        {
            componentId: USER_COUNT,
            component: UserCount,
            label: "User Count",
            icon: <PeopleIcon />,
            isUnique: false,
            size: 6,
            isSelectable: false,
            isObservable: false
        },
        {
            componentId: DISTRIBUTION_OVER_EVENT,
            component: DistributionOverEvent,
            label: "Distribution of Event",
            icon: <LineChartIcon />,
            isUnique: false, //see for uniqueness of component
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: HOURLY_EVENT_DISTRIBUTION,
            component: HourlyEventDistribution,
            label: "Hourly Event Distribution",
            icon: <BubbleChartIcon />,
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: RETENTION,
            component: Retention,
            label: "Retention",
            icon: <FavoriteIcon />,
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: false
        },
        {
            componentId: METRICS_TABLE,
            component: MetricsTable,
            label: "Metrics",
            icon: <TableIcon />,
            isUnique: false,
            size: 12,
            isSelectable: true,
            isObservable: false,
            help: "Tabular view of all important metrics across different user segments, app versions"
        },
        {
            componentId: COUNT,
            component: Count,
            label: "Count",
            icon: "#",
            isUnique: false,
            size: 6,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: TREND,
            component: Trend,
            label: "Trend",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: AGGREGATED_TREND,
            component: AggregatedTrend,
            label: "Aggregated Trend",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: TIME_SPENT,
            component: TimeSpent,
            label: "TimeSpent",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: SEGMENTS_TREND,
            component: SegmentsTrend,
            label: "Segments Trend",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: RETENTION_TREND,
            component: RetentionTrend,
            label: "Retention Trend",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: TABBED_WIDGET,
            component: TabbedWidget,
            label: "Tabbed Widget",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: FUNNEL_WIDGET,
            component: Funnel,
            label: "Funnel Widget",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: PATH_WIDGET,
            component: Path,
            label: "Path Widget",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: RETENTION_USAGE_WIDGET,
            component: RetentionUsage,
            label: "Retention Usage Widget",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: VOV_TIME_SPENT,
            component: VOVTimeSpent,
            label: "VOV Time Spent Widget",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: VOV_DAU_PERCENT,
            component: VOVDAUPercent,
            label: "VOV DAU Percent Widget",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: VOV_AVG_EVENTS_PER_DAY,
            component: VOVEventsPerDay,
            label: "VOV Avg Events Per Day Widget",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: EVENT_STICKINESS,
            component: Stickiness,
            label: "Event Stickiness Widget",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: CONVERSION_RATE_COUNT,
            component: ConversionRateCount,
            label: "Conversion Rate Count",
            icon: "#",
            isUnique: false,
            size: 6,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: CONVERSION_RATE_TREND,
            component: ConversionRateTrend,
            label: "Conversion Rate Trend",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: CONVERSION_RATE_TABBED_WIDGET,
            component: ConversionRateTabbedWidget,
            label: "Conversion Rate",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        },
        {
            componentId: CUSTOM_REPORTS_TABLE,
            component: CustomReportsTable,
            label: "Custom Report",
            icon: "#",
            isUnique: false,
            size: 12,
            isSelectable: false,
            isObservable: true
        }
    ]
};

Components.get = (id) => {
    const index = Components.widgets.findIndex(o => o.componentId === id);
    return index > -1 ? Components.widgets[index] : {};
};

export default Components;