/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading } from '../../Navigation/actions';
import { RetentionUsage, RetentionAnalysis} from './components';
import {
    loadRetentionData, resetRetention, getRetentionData,
    updateFirstQuery, updateNextQuery, updateRetentionType, getRetentionUsage, updateRetentionWindow
} from './actions';
import { updateSessionGroupId, updateUserGroupId, setDefaultFilters } from '../../Filters/actions';
import { GRAPH_DEFAULTS } from '../../../../../../constants';
import {RETENTION_ENUM} from "./actionTypes";
import {
    getAttributes,
    getAttributeValues,
    getEventAttributes, getEventAttributeValues,
    resetQuery,
    updateQuery,
    getEvents
} from "../Segments/NewSegment/actions";
import {UserAndSessionProperties} from "../Segments/NewSegment/components";
import {Button, Grid} from "@material-ui/core";
import {arePropertyFiltersSame} from "../../../../../../utils";


function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        users: state.users,
        retention: state.retention,
        filters: state.filters,
        meta: state.meta,
        queryBuilder: state.queryBuilder,
        segmentBuilder: state.segmentBuilder
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        loadData: (appId) => {
            dispatch(setDefaultFilters()); //updating defaults filters
            dispatch(loadRetentionData(appId));
        },
        updateUserSelection: (groupId) => {
            dispatch(updateUserGroupId(groupId));
            dispatch(loadRetentionData());
        },
        updateSessionSelection: (groupId) => {
            dispatch(updateSessionGroupId(groupId));
            dispatch(loadRetentionData());
        },
        getRetentionUsage: (appId, days) => dispatch(getRetentionUsage(appId, days)),
        resetData: () => dispatch(resetRetention()),
        updateFirstQuery: (appId, query) => {
            dispatch(updateFirstQuery(appId, query));
            //dispatch(getRetentionData(appId));
        },
        updateNextQuery: (appId, query) => {
            dispatch(updateNextQuery(appId, query));
            //dispatch(getRetentionData(appId));
        },
        updateRetentionWindow: (dayWindow) => dispatch(updateRetentionWindow(dayWindow)),
        getRetentionData: (appId, retentionType, dayWindow) => dispatch(getRetentionData(appId, retentionType, dayWindow)),
        getAttributes: (appId) => dispatch(getAttributes(appId)),
        getAttributeValues: (appId, attribute, q) => dispatch(getAttributeValues(appId, attribute, q)),
        getEvents: (appId) => dispatch(getEvents(appId)),
        getEventAttributes: (appId, event) => dispatch(getEventAttributes(appId, event)),
        getEventAttributeValues: (appId, event, attribute, q) => dispatch(getEventAttributeValues(appId, event, attribute, q)),
        handleQueryUpdate: (query) => dispatch(updateQuery(query)),
        resetQuery: () => dispatch(resetQuery()),
        updateRetentionType: (...args) => dispatch(updateRetentionType(...args)),
        bindedDispatch: dispatch
    };
}

class Retention extends Component {

    constructor (props) {
        super(props);
        this.appId = this.props.params.appId;
        this.state = {
            switchValue: GRAPH_DEFAULTS.switchValue,
            currentType: RETENTION_ENUM.FTU,
            firstEvent: null,
            nextEvent: null,
            propertiesApplied: true,
            user: [],
            session: []
        };
    }

    componentWillMount(){
        const {
            updateHeading, getEvents, getAttributes,
            loadData
        } = this.props;
        updateHeading("Retention");
        getAttributes(this.appId);
        loadData(this.appId);
        getEvents(this.appId);
    }

    componentWillReceiveProps(nextProps){
        if(this.state.propertiesApplied){
            const { segmentBuilder: { query: { user = [], session = [] } } }  = nextProps;
            const { user: sUser = [], session: sSession = [] }  = this.state;
            if(!arePropertyFiltersSame(user, sUser) || !arePropertyFiltersSame(session, sSession)){
                this.setState({propertiesApplied: false});
            }
        }
    }

    componentWillUnmount(){
        this.props.resetData();
        this.props.resetQuery();
    }

    handleUserSelection(groupId){
        this.props.updateUserSelection(this.appId, groupId);
    }

    render() {
        const { params: { appId }, retention, resetQuery, getRetentionData, getRetentionUsage } = this.props;
        const { segmentBuilder: { query: { user = [], session = [] } } }  = this.props;
        const { propertiesApplied } = this.state;
        return (
            <section className="content">
                <UserAndSessionProperties {...this.props} withoutBox />
                <Button
                    disabled={propertiesApplied}
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => {
                        getRetentionData(appId);
                        getRetentionUsage(appId);
                        this.setState({propertiesApplied: true, user, session});
                    }}
                    style={{display: 'block', margin: "10px 0 10px auto"}}
                >
                    Apply
                </Button>
                <RetentionAnalysis {...this.props} />
                <RetentionUsage {...this.props} data={retention.retention_usage} />
            </section>
        );
    }
}

Retention.propTypes = {

};

Retention.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Retention);