import React from 'react';
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';

import 'brace/mode/html';
import 'brace/theme/github';

export default class HTMLEditor extends React.Component {

    componentDidMount(){

    }

    render(){
        const { value = "", handleChange = () => null, config = {}, height = "500px" } = this.props;
        return(
            <AceEditor
                value={value}
                width="100%"
                height={height}
                mode="html"
                theme="github"
                onChange={handleChange}
                name="ace-editor"
                enableBasicAutocompletion={true}
                enableLiveAutocompletion={true}
                editorProps={{
                    $blockScrolling: true,
                    ...config
                }}
                fontSize={14}
                wrapEnabled={true}
            />
        )
    }
}

HTMLEditor.propTypes = {
    value: PropTypes.string.isRequired,
    handleChange: PropTypes.func,
    config: PropTypes.object
};