/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../../api';
import { makeDefaultQueryString} from '../../../../../../../utils';

const UNINSTALL_SETTINGS_API = "uninstall";
const UPDATE_UNINSTALL_SETTINGS_API = "uninstall";

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getUninstallSettingsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(UNINSTALL_SETTINGS_API, auth, appId);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function updateUninstallSettingsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(UPDATE_UNINSTALL_SETTINGS_API, auth, appId);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}