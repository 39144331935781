/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../api';
import { makeDefaultQueryString, withQueryStrings} from '../../../../../../utils';

const DASHBOARDS_API = "custom-overview-configs";

/**
 * @deprecated
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getCustomOverviewConfigAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(DASHBOARDS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth: auth,
        //body: JSON.stringify({})
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 * @deprecated
 * @param auth
 * @param appId
 * @param filters
 * @param body
 * @returns {Promise}
 */
export function updateCustomOverviewConfigAPI(auth, appId, filters, body) {
    const url = makeDefaultQueryString(DASHBOARDS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function dashboardsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(DASHBOARDS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth: auth,
        //body: JSON.stringify({})
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param dashboardId
 * @param filters
 * @returns {Promise}
 */
export function dashboardAPI(auth, appId, dashboardId, filters) {
    const url = makeDefaultQueryString(DASHBOARDS_API + '/' + dashboardId, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth: auth,
        //body: JSON.stringify({})
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param dashboardId
 * @param filters
 * @param dashboard
 * @returns {Promise}
 */
export function updateDashboardAPI(auth, appId, dashboardId, filters, dashboard) {
    //FIXME: take dashboardId as queryParam not id
    const url = makeDefaultQueryString(DASHBOARDS_API + '/' + dashboardId, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "PUT",
        auth: auth,
        body: JSON.stringify(dashboard)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param dashboard
 * @returns {Promise}
 */
export function saveDashboardAPI(auth, appId, filters, dashboard) {
    const url = makeDefaultQueryString(DASHBOARDS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(dashboard)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param dashboardId
 * @param filters
 * @returns {Promise}
 */
export function deleteDashboardAPI(auth, appId, dashboardId, filters) {
    const url = makeDefaultQueryString(DASHBOARDS_API + '/' + dashboardId, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "DELETE",
        auth: auth,
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}