import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ItemTypes } from '../constants';
import { DragSource, DropTarget } from 'react-dnd';
import Tooltip from '@material-ui/core/Tooltip';
import RemoveIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/ButtonBase";
import DragHandleIcon from '@material-ui/icons/MoreVert';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';

const dimensionSource = {
    beginDrag(props) {
        return {
            ...props,
            id: props.id,
            originalIndex: props.findDimension(props.id).index
        }
    },

    endDrag(props, monitor) {
        const { id: droppedId, originalIndex } = monitor.getItem();
        const didDrop = monitor.didDrop();

        if (!didDrop) {
            props.moveDimension(droppedId, originalIndex)
        }
    },
};

const dimensionTarget = {
    canDrop() {
        return false;
    },

    hover(props, monitor) {
        const { id: draggedId } = monitor.getItem();
        const { id: overId } = props;

        if (draggedId !== overId) {
            const { index: overIndex } = props.findDimension(overId);
            props.moveDimension(draggedId, overIndex);
        }
    },
};

function sourceCollect(connect, monitor) {
    return {
        connectDragSource: connect.dragSource(),
        isDragging: monitor.isDragging()
    }
}

function targetCollect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget()
    }
}

class Dimension extends Component {

    static propTypes = {
        connectDragSource: PropTypes.func.isRequired,
        connectDropTarget: PropTypes.func.isRequired,
        index: PropTypes.number.isRequired,
        isDragging: PropTypes.bool.isRequired,
        id: PropTypes.any.isRequired,
        text: PropTypes.string.isRequired,
    };

    constructor(props){
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const {
            connectDragSource, connectDropTarget
        } = this.props;
        const styles = {cursor: 'move'};
        return connectDragSource(
            connectDropTarget(
                <div style={{...styles}}>
                    <DimensionWrapper {...this.props}/>
                </div>
            )
        )
    }

}


class DimensionWrapper extends Component {

    render(){
        const {
            handleRemove, id, dimension, classes
        } = this.props;
        return(
            <div style={{pointerEvents: 'auto', width:'100%', position: 'relative', padding: 0, margin: 0, overflow: 'hidden'}}>
                <ListItem dense disableGutters className={classes.root}>
                    <ListItemIcon>
                        <DragHandleIcon className={classes.listIcon}/>
                    </ListItemIcon>
                    <ListItemText primary={dimension.name} />
                    <ListItemSecondaryAction>
                        <Tooltip id={"dimension-remove-tooltip-" + id} title="Remove" placement="top">
                            <IconButton aria-label="Remove" onClick={handleRemove} style={{height: 'auto', width: 'auto'}}>
                                <RemoveIcon color="action"/>
                            </IconButton>
                        </Tooltip>
                    </ListItemSecondaryAction>
                </ListItem>
            </div>
        );
    }

}

DimensionWrapper.propTypes = {
    component: PropTypes.object,
    id: PropTypes.string
};

Dimension.propTypes = {
    connectDragSource: PropTypes.func.isRequired,
    isDragging: PropTypes.bool.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    id: PropTypes.any.isRequired,
    moveDimension: PropTypes.func.isRequired,
    findDimension: PropTypes.func.isRequired,
};

export default DropTarget(ItemTypes.DIMENSION, dimensionTarget, targetCollect)(DragSource(ItemTypes.DIMENSION, dimensionSource, sourceCollect)(Dimension));