/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    UNINSTALL_USER_DISTRIBUTION, DAY_0_UNINSTALLS, DAY_0_USER_TRENDS, TOTAL_USER_TRENDS,
    UNINSTALLS,  RESET_UNINSTALLS, UPDATE_RANGE_OF_UNINSTALL_DISTRIBUTION,
    UNINSTALL_COUNT,DAY0_UNINSTALL_COUNT, UNINSTALL_USAGE,UNINSTALL_DAY_USAGE
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';

const INITIAL_STATE = {
    trends: [],
    uninstall_users: [],
    day0_uninstall_users: [],
    user_trends: [],
    event_attributes: {},
    user_attributes: {},
    day0_user_trends: [],
    uninstall_user_distributions: {},
    range: {
        min: 7,
        max: 15
    },
    distributions: [],
    count: 0,
    day0_count: 0,
    uninstall_usage: {},
    uninstall_day_usage: {}
};

function mergeTimeSeries(all, newTrend, trendName) {
    if(!Array.isArray(all) || !Array.isArray(newTrend)) return [];
    if(all.length === 0 || all.length !== newTrend.length) return newTrend.map(o => ({date: o.key, [trendName]: o.value}));
    return all.map((item, index) => {
        item[trendName] = newTrend[index].value;
        return item;
    });
}

function makeUninstallDistributions(state, distributions) {
    const getLabel = (start, end) => `Day ${start} - Day ${end}`;
    const { min, max } = state.range;
    return Object.keys(distributions).map((o, i) => {
        let label = i === 0 ? getLabel(0, min - 1) : (i === 1 ? getLabel(min, max -1) : getLabel(max, 90));
        return {key: label, Uninstalls: distributions[o]};
    });
}

function uninstallReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case UNINSTALLS:
            return {
                ...state,
                uninstall_users: action.payload,
                trends: mergeTimeSeries(state.trends, action.payload, "Uninstalls")
            };

        case DAY_0_UNINSTALLS:
            return {
                ...state,
                day0_uninstall_users: action.payload,
                trends: mergeTimeSeries(state.trends, action.payload, "Day 0 Uninstalls")
            };

        case TOTAL_USER_TRENDS:
            return {
                ...state,
                user_trends: action.payload,
                trends: mergeTimeSeries(state.trends, action.payload, "Active Users")
            };

        case DAY_0_USER_TRENDS:
            return {
                ...state,
                day0_user_trends: action.payload,
                trends: mergeTimeSeries(state.trends, action.payload, "New Installs")
            };

        case UNINSTALL_USER_DISTRIBUTION:
            const distributions = {
                ...state.uninstall_user_distributions,
                [action.meta.key]: action.payload
            };
            return {
                ...state,
                uninstall_user_distributions: distributions,
                distributions: makeUninstallDistributions(state, distributions)
            };

        case UPDATE_RANGE_OF_UNINSTALL_DISTRIBUTION:
            return {
                ...state,
                range: action.payload
            };

        case UNINSTALL_COUNT:
            return {
                ...state,
                count: action.payload
            };

        case DAY0_UNINSTALL_COUNT:
            return {
                ...state,
                day0_count: action.payload
            };

        case UNINSTALL_USAGE:
            return {
                ...state,
                uninstall_usage: {
                    ...state.uninstall_usage,
                    [action.meta.key]: action.payload
                }
            };

        case UNINSTALL_DAY_USAGE:
            return {
                ...state,
                uninstall_day_usage: {
                    ...state.uninstall_day_usage,
                    [action.meta.key]: action.payload
                }
            };

        case RESET_UNINSTALLS:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default uninstallReducer;