import {getCohorts} from "../Cohorts/actions";
import {
    FUNNEL_GROUPED_BY_DOWNLOAD,
    FUNNEL_GROUPED_BY_DOWNLOAD_FAILED,
    FUNNEL_GROUPED_BY_DOWNLOAD_PENDING,
    FUNNEL_DOWNLOAD,
    FUNNEL_DOWNLOAD_FAILED,
    FUNNEL_DOWNLOAD_PENDING,
    FUNNEL_GRAPH_PENDING,
    FUNNEL_GRAPH,
    FUNNEL_GRAPH_FAILED,
    FUNNEL_OPPORTUNITY_PENDING,
    FUNNEL_OPPORTUNITY,
    FUNNEL_OPPORTUNITY_FAILED,
    FUNNEL_RESET_QUERY,
    FUNNEL_UPDATE_QUERY,
    CUSTOM_EVENT_ENUM,
    FUNNEL_SAVE,
    FUNNEL_SAVE_FAILED,
    FUNNEL_SAVE_PENDING,
    FUNNEL_GROUPED_BY,
    FUNNEL_GROUPED_BY_FAILED,
    FUNNEL_GROUPED_BY_PENDING,
    CORRELATION_FUNNELLIST,
    CORRELATION_FUNNELLIST_FAILED,
    CORRELATION_FUNNELLIST_PENDING,
    DELETE_FUNNEL_PENDING,
    DELETE_FUNNEL,
    DELETE_FUNNEL_FAILED,
    FUNNEL_LIST,
    FUNNEL_LIST_FAILED,
    FUNNEL_LIST_PENDING,
    UPDATE_CORRELATIONS_FUNNELS_LIST,
    FUNNEL_TIMESERIES_PENDING,
    FUNNEL_TIMESERIES,
    FUNNEL_TIMESERIES_FAILED,
    CREATE_COHORT_FUNNEL_PENDING,
    CREATE_COHORT_FUNNEL,
    CREATE_COHORT_FUNNEL_FAILED, TOGGLE_FUNNEL_DIALOG, SHOW_FUNNEL_SNACKBAR, HIDE_FUNNEL_SNACKBAR
} from "./actionTypes";

import {
    getFunnelAPI,
    saveFunnelAPI,
    getFunnelListAPI,
    getFunnelGroupedByAPI,
    getFunnelsListAPI,
    getFunnelTimeseriesAPI,
    deleteFunnelAPI,
    createCohortFromFunnelAPI,
    getFunnelDownloadAPI,
    getFunnelGroupedByDownloadAPI
} from "./api";

import {getFunnelOpportunityAPI} from "../../../../../../api";
import {getCorrelationsTimeSeriesData} from "../Segments/actions";
import {getGroupNameFromList} from "../../../../../../utils";

export const getFunnel = (appId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        const funnelFilters = getState().funnels.query;
        return dispatch({
            types: [
                FUNNEL_GRAPH_PENDING,
                FUNNEL_GRAPH,
                FUNNEL_GRAPH_FAILED
            ],
            payload: {
                promise: getFunnelAPI(appId, getState().auth, filters, funnelFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getFunnelsList = (appId) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters};
        return dispatch({
            types: [
                FUNNEL_LIST_PENDING,
                FUNNEL_LIST,
                FUNNEL_LIST_FAILED
            ],
            payload: {
                promise: getFunnelsListAPI(appId, getState().auth, filters)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {
                // if any;
            }
        })
    }
};

export const getFunnelGroupedBy = (appId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        const funnelFilters = getState().funnels.query;
        return dispatch({
            types: [
                FUNNEL_GROUPED_BY_PENDING,
                FUNNEL_GROUPED_BY,
                FUNNEL_GROUPED_BY_FAILED
            ],
            payload: {
                promise: getFunnelGroupedByAPI(appId, getState().auth, filters, funnelFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getFunnelOpportunity = (appId, attributes) => {
    return (dispatch, getState) => {
        const queryParams = { ...getState().filters, attribute: attributes };
        const {events, time, group_by = [] } = getState().funnels.query;
        const funnelFilters = {events, time, group_by: group_by.slice(-1)};
        return dispatch({
            types: [
                FUNNEL_OPPORTUNITY_PENDING,
                FUNNEL_OPPORTUNITY,
                FUNNEL_OPPORTUNITY_FAILED
            ],
            payload: {
                promise: getFunnelOpportunityAPI(appId, getState().auth, queryParams, funnelFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getFunnelList = (appId, funnelId = null) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                CORRELATION_FUNNELLIST_PENDING,
                CORRELATION_FUNNELLIST,
                CORRELATION_FUNNELLIST_FAILED
            ],
            payload: {
                promise: getFunnelListAPI(appId, getState().auth, getState().filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, data) => {
                    if(funnelId) {
                        dispatch(getCorrelationsTimeSeriesData(appId, funnelId, getGroupNameFromList(data, funnelId)));
                    }
                }
            }
        });
    };
};

export const deleteFunnelAction = (appId, funnelId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                DELETE_FUNNEL_PENDING,
                DELETE_FUNNEL,
                DELETE_FUNNEL_FAILED
            ],
            payload: {
                promise: deleteFunnelAPI(appId, getState().auth, getState().filters, funnelId)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successWillDispatch: (dispatch, data) => {
                    if (data) {
                        dispatch(resetQuery());
                        dispatch(updateFunnelQuery({
                            events: [],
                            time: 300
                        }));
                        dispatch(getFunnelsList(appId));
                    }
                }
            }
        });
    };
};

/*
export const updateFunnels = (appId, funnelList, forceAll = false) => {
    return (dispatch, getState) => {
        const existingFunnels = Object.keys(getState().funnel.selected_funnels) || [];
        if(forceAll){
            if(Array.isArray(funnelList) && funnelList.length > 0){
                const funnelSet = new Set([...funnelList, ...existingFunnels]); //to remove duplicates
                funnelList = [...funnelSet];
            }else{
                funnelList = existingFunnels;
            }
        }
        funnelList.forEach(funnelId => { //FIXME: calling all funnels every time for sanity in data and selected funnels
            const funnelName = getGroupNameFromList(getState().funnel.funnels_list, funnelId);
            if(funnelName) {
                dispatch(getCorrelationsTimeSeriesData(appId, funnelId, funnelName));
            }
        });

        return dispatch({
            type: UPDATE_FUNNELS,
            payload: funnelList,
            meta: {
                //If Any
            }
        });
    };
};
 */

export const saveFunnel = (appId, name) => {
    return (dispatch, getState) => {
        const of = getState().funnels.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of};
        const { query } = getState().funnels;
        return dispatch({
            types: [
                FUNNEL_SAVE_PENDING,
                FUNNEL_SAVE,
                FUNNEL_SAVE_FAILED
            ],
            payload: {
                promise: saveFunnelAPI(getState().auth, appId, filters, {...query, name})
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            },
            callbacks: {
                successDidDispatch: (dispatch, funnelSaved) => {
                    if(funnelSaved) { //updating funnel list
                        dispatch(getFunnelsList(appId));
                    }
                }
            }
        });
    };
};

export const getFunnelTimeseries = (appId, funnelId) => {
    return (dispatch, getState) => {
        const {funnels_list} = getState().funnels;
        const filters = {...getState().filters};
        return dispatch({
            types: [
                FUNNEL_TIMESERIES_PENDING,
                FUNNEL_TIMESERIES,
                FUNNEL_TIMESERIES_FAILED
            ],
            payload: {
                promise: getFunnelTimeseriesAPI(getState().auth, appId, filters, funnelId).then((res) => {
                    return res;
                }),
            },
            meta: {
                funnelId: funnelId,
                funnelName: getGroupNameFromList(funnels_list, funnelId)
            }
        })
    }
};

export const updateComparingFunnels = (appId, funnelList, force = false) => {
    return (dispatch, getState) => {
        const {comparing_funnels} = getState().funnels;
        funnelList.filter(funnelId => force || !comparing_funnels.includes(funnelId)).forEach(funnelId => {
            dispatch(getFunnelTimeseries(appId, funnelId))
        });
        return dispatch({
            type: UPDATE_CORRELATIONS_FUNNELS_LIST,
            payload: funnelList,
            meta: {}
        })
    }
};

export const createCohortFromFunnel = (appId, funnelConfig) => {
    return (dispatch, getState) => {
        const {auth, filters, funnels: {query}} = getState();
        const config = {
            funnelConfig,
            body: query
        };
        return dispatch({
            types: [CREATE_COHORT_FUNNEL_PENDING, CREATE_COHORT_FUNNEL, CREATE_COHORT_FUNNEL_FAILED],
            payload: {
                promise: createCohortFromFunnelAPI(appId, auth, filters, config)
                .then(res => {
                    if (res) {
                        dispatch(getCohorts(appId, filters));
                        dispatch(toggleDialog());
                        dispatch(toggleFunnelSnackbar(true, "Cohort Created Successfully (You can find it in Cohort Dropdown)"));
                    } else {
                        dispatch(toggleFunnelSnackbar(true, "Cohort Creation Failed"));
                    }
                })
            },
            meta: {}
        })
    }
};

export const toggleDialog = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: TOGGLE_FUNNEL_DIALOG,
            payload:{},
            meta:{}
        })
    }
};

export const toggleFunnelSnackbar = (status, message) => {
    return (dispatch, getState) => {
        return dispatch({
            type: status ? SHOW_FUNNEL_SNACKBAR : HIDE_FUNNEL_SNACKBAR,
            payload: {message},
            meta: {}
        })
    }
};

/**
 *
 * @param query
 * @returns {function(*, *)}
 */
export const updateFunnelQuery = (query) => {
    return (dispatch, getState) => {
        return dispatch({
            type: FUNNEL_UPDATE_QUERY,
            payload: query,
            meta: {
                //
            }
        });
    };
};

export const resetQuery = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: FUNNEL_RESET_QUERY,
            payload: null,
            meta: {
                //
            }
        });
    };
};

export const getFunnelDownload = (appId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        const funnelFilters = getState().funnels.query;
        return dispatch({
            types: [
                FUNNEL_DOWNLOAD_PENDING,
                FUNNEL_DOWNLOAD,
                FUNNEL_DOWNLOAD_FAILED
            ],
            payload: {
                promise: getFunnelDownloadAPI(appId, getState().auth, filters, funnelFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getFunnelGroupedByDownload = (appId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        const funnelFilters = getState().funnels.query;
        return dispatch({
            types: [
                FUNNEL_GROUPED_BY_DOWNLOAD_PENDING,
                FUNNEL_GROUPED_BY_DOWNLOAD,
                FUNNEL_GROUPED_BY_DOWNLOAD_FAILED
            ],
            payload: {
                promise: getFunnelGroupedByDownloadAPI(appId, getState().auth, filters, funnelFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};