import React, { Component } from 'react';
import { DropTarget } from 'react-dnd';
import Grid from '@material-ui/core/Grid';
import PropTypes from "prop-types";
import {withStyles} from "@material-ui/core/styles";
import Dimension from "./Dimension";
import {DIMENSION_TYPE, ItemTypes} from "../constants";
import {randomColorWithIndex } from "../../../../../../../utils/index";
import List from '@material-ui/core/List';
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import withDragDropContext from "../../../../../../../components/plugins/withDragDropContext";

const styles = theme => ({
    paper: {
        padding: 5
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    details: {
        display: 'flex',
        flexWrap: 'wrap',
        paddingTop: 10
    },
    root: {
        margin: '5px 2px',
        background: theme.palette.primary.light,
        '&:hover': {
            background: theme.palette.primary.main,
        },
        '&:focus': {
            background: theme.palette.primary.main,
        },
        color: theme.palette.primary.contrastText,
        '& span': {
            color: theme.palette.primary.contrastText,
        }
    },
    colorDefault: {
        /*color: theme.palette.secondary.dark,
        background: theme.palette.primary.main*/
    },
    listIcon: {
        color: theme.palette.primary.contrastText
    }
});

const dimensionTarget = {
    drop(props, monitor) {

    }
};

function collect(connect, monitor) {
    return {
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
        currentDimension: monitor.getItem()
    };
}

const DIMENSION_WIDTH = 240;

const styleForDimension = (index) => {
    return {
        width: DIMENSION_WIDTH,
        maxWidth: 240,
        margin: 5,
        marginLeft: index * 20,
        //background //: randomColorWithIndex(1)
    };
};

class DimensionsHolder extends Component {

    render(){

        const {
            connectDropTarget,
            appState: { attributes: { user = [], session = [], event = [] } = {} },
            dimensions,
            handleAddDimension, handleRemoveDimension, needAddMore = true
        } = this.props;

        const attributes = [...user, ...session, ...event];

        const finalAttributes = attributes.filter(a => !dimensions.some(o => o.name === a))
                                            .map(o => ({label: o, value: o}))
                                            .concat({label: "Event", value: "apx_event_name"})
                                            .concat({label: "Date", value: "apx_date"})
                                            .concat({label: "apx_user_id", value: "apx_user_id"});

        return connectDropTarget(
            <div style={{width: '100%', minHeight: 50}}>
                <Grid container>
                    <Grid item xs>
                        <List>
                            {
                                dimensions.map((dim, index) =>
                                    <div key={dim.id} style={styleForDimension(index)}>
                                        <Dimension key={dim.id} {...this.props} dimension={dim} id={dim.id} handleRemove={() => handleRemoveDimension(index)}/>
                                    </div>
                                )
                            }
                        </List>
                        {
                            needAddMore && dimensions.length < 10 &&
                            <MultiSelect
                                single
                                value={null}
                                handleChange={handleAddDimension}
                                options={finalAttributes}
                                style={{margin: 5, marginLeft: dimensions.length * 20, maxWidth: DIMENSION_WIDTH}}
                                placeholder="Select Dimension"
                            />
                        }
                    </Grid>
                </Grid>
            </div>
        );

    }

}

DimensionsHolder.propTypes = {
    connectDropTarget: PropTypes.func.isRequired,
    isOver: PropTypes.bool.isRequired,
    dimensions: PropTypes.array.isRequired,
    onDrop: PropTypes.func.isRequired,
};

const DimensionsHolderWrapper = withDragDropContext(DropTarget(ItemTypes.DIMENSION, dimensionTarget, collect)(DimensionsHolder));

const { attribute, event } = DIMENSION_TYPE;

class DimensionSelector extends Component {

    constructor(props){
        super(props);
        const { dimensions = [] }  = props;
        this.state = {
            dimensions
        }
    }

    componentWillReceiveProps(nextProps){
        const { customReport: { report: { _id } = {} } } = this.props;
        const { customReport: { report: { _id: nextId } = {} }, dimensions } = nextProps;
        if(_id !== nextId){
            this.setState({dimensions: dimensions.map(o => ({id: o.name, ...o}))});
        }
    }

    handleDrop = (dimension) => {
        //TODO:
    };

    findDimension = (id) => {
        const index = this.state.dimensions.findIndex(o => o.id === id);
        return {
            dimension: this.state.dimensions[index],
            index
        };
    };

    handleChange = () => {
        this.props.handleChange(this.state.dimensions);
    };

    moveDimension = (id, atIndex) => {
        const { dimension, index } = this.findDimension(id);
        const dimensions = [...this.state.dimensions.slice(0, index), ...this.state.dimensions.slice(index + 1)];
        this.setState({dimensions: [ ...dimensions.slice(0, atIndex), dimension, ...dimensions.slice(atIndex) ]}, this.handleChange);
    };

    handleAddDimension = (dimension) => {
        if (dimension) {
            const dimObj = {
                id: dimension,
                name: dimension,
                dimension_type: dimension === "apx_event_name" ? event : attribute
            };
            this.setState({dimensions: [...this.state.dimensions, dimObj]}, this.handleChange);
        }
    };

    handleRemoveDimension = (index) => {
        const { dimensions } = this.state;
        this.setState({dimensions: [...dimensions.slice(0, index), ...dimensions.slice(index + 1)]}, this.handleChange);
    };

    render(){
        const { dimensions } = this.state;
        return (
            <DimensionsHolderWrapper
                {...this.props}
                onDrop={this.handleDrop}
                dimensions={dimensions}
                findDimension={this.findDimension}
                moveDimension={this.moveDimension}
                handleAddDimension={this.handleAddDimension}
                handleRemoveDimension={this.handleRemoveDimension}
            />
        );

    }

}

export default withStyles(styles)(DimensionSelector);