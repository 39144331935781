import './index.css';
/**
 * Created by Araja Jyothi Babu on 14-Nov-16.
 */
import React, {} from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Loading from '../Loading';
import Placeholder from '../Placeholder';
import Switch from '../Switch';
import {roundOffNumber, formatWithAddedTime, formatTime, removeCharsWith, goToByScroll} from '../../../utils';
import {EVENT_TYPE_ENUM} from '../../../constants';
import Box from "../Box";
import Grid from "@material-ui/core/Grid";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/ButtonBase';
import { Input, InputAdornment } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import SessionSimulator from "./SessionSimulator";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Timer from "../Timer";


const defaultInteractionIcon = "fa fa-hand-o-up bg-green-gradient";
const defaultErrorIcon = "fa fa-exclamation-triangle bg-red-gradient";

const INTERACTION_ICONS = {
    Fling: "fa fa-arrows-v bg-light-blue-gradient",
    Tap: "fa fa-hand-o-up bg-green-gradient",
    Scroll: "fa fa-arrows-v bg-blue-gradient",
    key_tap: "fa fa-keyboard-o bg-purple-gradient",
    "Double Tap": "fa fa-hand-o-up bg-maroon-gradient",
};

function getInteractionIcon(interaction) {
    if(INTERACTION_ICONS.hasOwnProperty(interaction)) return INTERACTION_ICONS[interaction];
    return defaultInteractionIcon;
}

class TimelineEvent extends React.Component{

    render(){
        const {timestamp, item} = this.props;
        const {name, time, additional_info, item_type} = item; //TODO: proper event info
        const isIssueItem  = item_type === EVENT_TYPE_ENUM.ISSUE_ITEM;
        const iconClass = isIssueItem ? defaultErrorIcon : getInteractionIcon(name);
        return(
            <li>
                <i className={iconClass} style={{color: 'white'}}></i>
                <div className={`timeline-item ${isIssueItem && 'bg-red-gradient'}`}>
                    <span className={`time ${isIssueItem && 'text-white'}`}><i className="fa fa-clock-o"></i> {formatWithAddedTime(time, timestamp)}</span>
                    <h3 className={`timeline-header ${isIssueItem && 'text-white'}`}> <i style={{textTransform: 'capitalize'}}>{removeCharsWith(name, '_', ' ')} </i>{ `${isIssueItem ? '' : 'on '}` } <i className="text-purple">{ additional_info.event_view }</i></h3>
                </div>
            </li>
        )

    }

}

class TimelineNavigation extends React.Component {

    state = {
        toggle: false
    };

    render(){
        const {timestamp, item, currentEvents, query } = this.props;
        const { name, time, duration,  events, issue} = item;
        //const uniqueId = `${time}-${Math.round(Math.random() * 1000000)}`;
        const filteredEvents = events && events.filter(event => currentEvents.indexOf(event.item_type) > -1 && event.name.toLocaleLowerCase().includes(query.toLocaleLowerCase()));
        const containEvents = filteredEvents && filteredEvents.length > 0;
        const highLight = query.length > 0 && containEvents;
        return(
            <li>
                {/*<img className="fa" src='/assets/img/user.png' alt={name} />*/}
                <i className="fa fa-mobile bg-aqua-gradient" style={{color: 'white'}}></i>
                <div className="timeline-item" style={highLight ? {boxShadow: '1px 1px 9px 0 #8888EE'} : {}}>
                    <span className="time"  onClick={() => {
                        containEvents && this.setState({toggle: !this.state.toggle});
                    }}>
                        { issue && <i data-toggle="tooltip" title={issue.name} className="text-red cursor-pointer fa fa-fw fa-exclamation-triangle" style={{marginRight: '10px'}}></i> }
                        { containEvents && <i className={`fa fa-fw ${this.state.toggle ? 'fa-angle-up' : 'fa-angle-down'}`} style={{marginRight: '10px'}}></i> }
                        <i className="fa fa-clock-o"></i> {formatWithAddedTime(time, timestamp, "")}
                    </span>
                    <div className={`timeline-header ${ containEvents && "cursor-pointer"}`}  onClick={() => {
                        containEvents && this.setState({toggle: !this.state.toggle});
                    }}>
                        { duration && `Spent ${roundOffNumber(duration)} secs on ` } <i className="text-purple">{ name }</i>
                    </div>
                    <div className={`timeline-body collapse ${containEvents && this.state.toggle && 'in'}`} style={{background: "azure"}}>
                        <ul className="timeline">
                            {
                                containEvents && filteredEvents.map((event, i) =>
                                    event.item_type === EVENT_TYPE_ENUM.APP_EVENT_ITEM ?
                                        <TimelineAppEvent key={ "key" + i } item={event} timestamp={timestamp}/>
                                        : <TimelineEvent key={ "key" + i } item={event} timestamp={timestamp}/>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </li>
        )

    }

}

class TimelineAppEvent extends React.Component{

    render(){
        const {timestamp, item } = this.props;
        const { name, time, additional_info} = item; //TODO: proper event info
        const dataAvailable = additional_info && Object.keys(additional_info).length > 0;
        return(
            <li>
                <i className="fa fa-tasks bg-aqua-gradient" style={{color: 'white'}}></i>
                <div className="timeline-item">
                    <span className="time"><i className="fa fa-clock-o"></i> {formatWithAddedTime(time, timestamp)}</span>
                    <h3 className="timeline-header"> { name }</h3>
                    { dataAvailable &&
                    <div className="timeline-body">
                        <List>
                            {
                                Object.keys(additional_info).map((item, i) =>
                                    <ListItem key={item + i}>
                                        <ListItemText secondary={
                                            <span>{item} <span className="pull-right text-primary">{ additional_info[item] }</span></span>
                                        }/>
                                    </ListItem>
                                )
                            }
                        </List>
                    </div>
                    }
                </div>
            </li>
        )

    }

}

class TimelineItem extends React.Component{

    render(){
        const {item_type} = this.props.item;
        return item_type === EVENT_TYPE_ENUM.NAVIGATION_ITEM ? <TimelineNavigation {...this.props}/> :(item_type === EVENT_TYPE_ENUM.EVENT_ITEM ? <TimelineEvent {...this.props}/>: <TimelineAppEvent {...this.props}/>)
    }

}

const timelineSwitch = [
    {name: "App Events", value: EVENT_TYPE_ENUM.APP_EVENT_ITEM},
    {name: "User Events", value: EVENT_TYPE_ENUM.EVENT_ITEM},
    {name: "Issues", value: EVENT_TYPE_ENUM.ISSUE_ITEM},
    {name: "All", value: "ALL"}
];

const ALL_EVENTS = [EVENT_TYPE_ENUM.APP_EVENT_ITEM, EVENT_TYPE_ENUM.EVENT_ITEM, EVENT_TYPE_ENUM.ISSUE_ITEM];

const TIMELINE_ELEMENT = "timeline";

class SessionTimeline extends React.Component{

    state = {
        currentEvents: ALL_EVENTS, //default all
        value: "ALL", //FIXME: get rid of this ALL. use ENUM instead
        query: "",
        currentTab: 0
    };

    componentWillMount(){
    }

    componentDidMount(){
        goToByScroll(TIMELINE_ELEMENT); //scroll to time line
    }

    componentWillReceiveProps(nextProps) {
        if(this.props.timestamp !== nextProps.timestamp){
            goToByScroll(TIMELINE_ELEMENT); //scroll to time line
        }
    }

    handleSwitch = (value) => {
        this.setState({value: value, currentEvents: value === "ALL" ? ALL_EVENTS: [value]});
    };

    handleChange = (event, currentTab) => {
        this.setState({ currentTab });
    };

    render(){
        const { duration = 0, timestamp, timeline, pending, failed, actual_timeline } = this.props;
        const { currentEvents, value, query, currentTab } = this.state;
        const startTime = moment(timestamp).format("DD MMM YYYY");
        return (
            <Grid container id={TIMELINE_ELEMENT}>
                <Grid item xs>
                    <AppBar position="static">
                        <Tabs value={currentTab} onChange={this.handleChange}>
                            <Tab label="Detailed" />
                            <Tab label="Simulated" />
                        </Tabs>
                    </AppBar>
                    <Box
                        withPadding
                        title={<span>Session at { formatTime(timestamp) }</span>}
                        controls={
                            currentTab === 0 ? <Grid spacing={16} container justify="flex-end" style={{maxWidth: 800}}>
                                <Grid item xs={12} md>
                                    <Switch
                                        data={timelineSwitch}
                                        handleChange={this.handleSwitch}
                                        value={value}
                                        containerStyles={{maxWidth: 450, float: 'right', margin: 0}}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <FormControl>
                                        <Input
                                            id="timeline-search"
                                            type="search"
                                            value={query}
                                            placeholder="Search event.."
                                            onChange={e => this.setState({query: e.target.value})}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton>
                                                        <SearchIcon/>
                                                    </IconButton>
                                                </InputAdornment>
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid> : (!pending && <Timer stopAt={duration * 1000}/>)
                        }
                    >
                        {pending && <Loading/>}
                        {failed && <Placeholder height={300}/>}
                        {
                            !pending && startTime && currentTab === 0 &&
                            <Grid container>
                                <Grid item xs>
                                    <ul className="timeline">
                                        <li className="time-label">
                                            <span className="bg-red">{ startTime }</span>
                                        </li>
                                        {
                                            timeline && timeline.map((item, i) => {
                                                return (
                                                    <TimelineNavigation key={ "key" + i } item={item} timestamp={timestamp} currentEvents={currentEvents} query={query} />
                                                )
                                            })
                                        }
                                    </ul>
                                </Grid>
                            </Grid>
                        }
                        { !pending && startTime && currentTab === 1 && <SessionSimulator data={actual_timeline} duration={duration}/> }
                    </Box>
                </Grid>
            </Grid>
        )
    }

}

SessionTimeline.propTypes = {
    timeline: PropTypes.array.isRequired,
    pending: PropTypes.bool.isRequired,
    failed: PropTypes.bool.isRequired
};

TimelineItem.propTypes = {
    item: PropTypes.object.isRequired,
};

TimelineAppEvent.propTypes = {
    item: PropTypes.object.isRequired,
};

TimelineNavigation.propTypes = {
    item: PropTypes.object.isRequired,
    currentEvents: PropTypes.array.isRequired,
};

export default SessionTimeline;