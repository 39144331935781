import React from 'react';
import PropTypes from 'prop-types';
import {TextField, InputAdornment} from "@material-ui/core";
import AccessTime from '@material-ui/icons/AccessTime';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const TYPES = {
    Ms: "ms",
    Min: "min",
    Sec: "sec",
    Hour: "hour"
};

const { Ms, Min, Sec, Normal } = TYPES;

const TIME_TYPES = ["ms", "min", "sec"];

export default class NumberField extends React.Component {

    constructor(props){
        super(props);
        const { value, metricType } = props;
        this.state = {
            type: TYPES.Sec,
            value: this.getValue(value, metricType, TYPES.Sec)
        };
    }

    handleTypeChange = (e) => {
        const type = e.target.value;
        this.setState({type}, () => {
            this.updateValueToParent();
        });
    };

    getValue = (value, type, metric) => {
        if(metric === Normal) return value;
        switch (true) {
            case type === metric: return value;
            case type === Ms && metric === Min: return value / (60 * 1000);
            case type === Ms && metric === Sec: return value / 1000;
            case type === Min && metric === Ms: return value * 60 * 1000;
            case type === Min && metric === Sec: return value * 60;
            case type === Sec && metric === Ms: return value * 1000;
            case type === Sec && metric === Min: return value / 60;
            default: return value;
        }
    };

    updateValueToParent = (value = this.state.value) => {
        const { type } = this.state;
        const { handleChange = () => null, metricType } = this.props;
        handleChange(this.getValue(value, type, metricType));
    };

    handleChange = (value) => {
        this.setState({value});
        this.updateValueToParent(value);
    };

    render(){
        const {
           handleChange, metricType, ...others
        } = this.props;
        const { value } = this.state;
        return(
            <div style={{display: 'flex'}}>
                <TextField
                    {...others}
                    type="number"
                    required
                    placeholder="10"
                    value={value}
                    onChange={e => {
                        this.handleChange(Number(e.target.value))
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end"><AccessTime/></InputAdornment>
                    }}
                />
                <FormControl {...others} required={false} style={{marginLeft: 8}}>
                    <InputLabel htmlFor="units">Units</InputLabel>
                    <Select
                        value={this.state.type}
                        onChange={this.handleTypeChange}
                    >
                        {TIME_TYPES.map(o => <MenuItem key={o} value={o}>{o}</MenuItem>)}
                    </Select>
                </FormControl>
            </div>
        )
    }

}

NumberField.propTypes = {
    ...TextField.propTypes,
    handleChange: PropTypes.func.isRequired,
    metricType: PropTypes.oneOf(["ms", "min", "sec"])
};