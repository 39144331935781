/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

import {
  login,
  signUp,
  forgotPasswordAPI,
  resetPasswordAPI,
  userExistenceApi,
  logOut,
  loginWithOTP,
} from "./api";
import {
  LOGIN_USER_PENDING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  SIGN_UP_USER_SUCCESS,
  SIGN_UP_USER_ERROR,
  SIGN_UP_USER_PENDING,
  FORGOT_PASSWORD_PENDING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  INITIALIZE_FORM,
  AUTH_REDUCER_RESET,
  HOST_REQUEST_PENDING,
  HOST_REQUEST_FAILED,
  HOST_REQUEST_SUCCESS,
  SET_USER_PROFILE,
  LOGOUT_USER_PENDING,
  LOGOUT_USER_SUCCESS,
  LOGOUT_USER_FAILED,
  SET_ACCESS_EXPIRY,
  LOGIN_WITH_OTP_SUCCESS,
  LOGIN_WITH_OTP_ERROR,
  LOGIN_WITH_OTP_PENDING,
} from "./actionTypes";
import { SERVER_IDENTIFIERS } from "../../../../constants/EndPoints";
import { push } from "react-router-redux";
import logger from "../../../../utils/Logger";
/**
 *
 * @returns {function(*, *)}
 */
export function loginUser(userName, password) {
  return (dispatch, getState) => {
    return dispatch({
      types: [LOGIN_USER_PENDING, LOGIN_USER_SUCCESS, LOGIN_USER_ERROR],
      payload: {
        promise: login(userName, password, getState().auth).then((res) => {
          return res;
        }),
      },
      callbacks: {
        successDidDispatch: (dispatch, data) => {
          data.accessExpiry
            ? dispatch(setAccessExpiry(data.accessExpiry))
            : dispatch(setAccessExpiry(null));
        },
      },
      meta: {
        // transition: (nextState, action) => ({
        //     pathname: `/apps` //FIXME: Not working
        // })
      },
    });
  };
}

/**
 *
 * @param user
 * @param host
 * @returns {function(*, *)}
 */
export function checkUserExistence(user, host = SERVER_IDENTIFIERS.google) {
  return (dispatch, getState) => {
    const auth = {
      //constructing auth object
      user: {
        email: user,
      },
    };
    return dispatch({
      types: [HOST_REQUEST_PENDING, HOST_REQUEST_SUCCESS, HOST_REQUEST_FAILED],
      payload: {
        promise: userExistenceApi(auth, host).then((res) => {
          return res;
        }),
      },
      meta: {
        host: host,
      },
      callbacks: {
        successDidDispatch: (dispatch, data) => {
          if (!data) {
            //if response is negative
            host === SERVER_IDENTIFIERS.azure
              ? dispatch({ type: HOST_REQUEST_FAILED })
              : dispatch(checkUserExistence(user, SERVER_IDENTIFIERS.azure));
          }
        },
      },
    });
  };
}

/**
 *
 * @param email
 * @returns {function(*, *)}
 */
export function forgotPassword(email) {
  return (dispatch, getState) => {
    return dispatch({
      types: [
        FORGOT_PASSWORD_PENDING,
        FORGOT_PASSWORD_SUCCESS,
        FORGOT_PASSWORD_ERROR,
      ],
      payload: {
        promise: forgotPasswordAPI(email, getState().auth).then((res) => {
          return res;
        }),
      },
      meta: {
        transition: (nextState, action) => ({
          pathname: `/apps`, //FIXME: Not working
        }),
      },
      callbacks: {
        successDidDispatch: (dispatch, data) => {
          setTimeout(() => {
            dispatch({
              type: FORGOT_PASSWORD_SUCCESS,
              payload: false,
            });
          }, 5000);
        },
      },
    });
  };
}

/**
 *
 * @param email
 * @param password
 * @param token
 * @param host
 * @returns {function(*, *)}
 */
export function resetPassword(email, password, token, host) {
  return (dispatch, getState) => {
    host &&
      dispatch({
        //updating host if exists
        type: HOST_REQUEST_SUCCESS,
        payload: true,
        meta: {
          host: host,
        },
      });
    return dispatch({
      types: [
        RESET_PASSWORD_PENDING,
        RESET_PASSWORD_SUCCESS,
        RESET_PASSWORD_ERROR,
      ],
      payload: {
        promise: resetPasswordAPI(email, password, token, getState().auth).then(
          (res) => {
            return res;
          }
        ),
      },
      meta: {
        transition: (nextState, action) => ({
          pathname: `/apps`, //FIXME: Not working
        }),
      },
    });
  };
}

/**
 *
 * @returns {function(*, *)}
 */
export function signUpUser(host, name, email, password) {
  return (dispatch, getState) => {
    host &&
      dispatch({
        //updating host if exists
        type: HOST_REQUEST_SUCCESS,
        payload: true,
        meta: {
          host: host,
        },
      });
    return dispatch({
      types: [SIGN_UP_USER_PENDING, SIGN_UP_USER_SUCCESS, SIGN_UP_USER_ERROR],
      payload: {
        promise: signUp(name, email, password, getState().auth).then((res) => {
          return res;
        }),
      },
      meta: {
        //meta
      },
      callbacks: {
        successWillDispatch: (dispatch, data) => {
          //success callback
          !host && dispatch(checkUserExistence(email));
        },
      },
    });
  };
}

export function setAccessExpiry(expiry) {
  return {
    type: SET_ACCESS_EXPIRY,
    payload: {
      expiry: expiry,
    },
  };
}

/**
 *
 * @returns {{type}}
 */
export function logoutUser() {
  let action = (dispatch, getState) => {
    dispatch(resetAuthReducer()); //clearing auth reducer
    return dispatch({
      types: [LOGOUT_USER_PENDING, LOGOUT_USER_SUCCESS, LOGOUT_USER_FAILED],
      payload: {
        promise: logOut(),
      },
      callbacks: {
        successWillDispatch: () => {
          // Instead of pushing the router to login page, forefully
          // reloading the page to avoid dirty cookies handling
          window.open("/login", "_self", true);
        },
      },
    });
  };
  action.isLogout = true; // needed for refreshMiddleware
  return action;
}

/**
 *
 * @returns {function(*, *)}
 */
export function resetAuthReducer() {
  return (dispatch, getState) => {
    return dispatch({
      type: AUTH_REDUCER_RESET,
      payload: {},
    });
  };
}

/**
 *
 * @param data
 * @returns {function(*, *)}
 */
export function initialiseForm(data) {
  return (dispatch, getState) => {
    return dispatch({
      type: INITIALIZE_FORM,
      payload: data,
    });
  };
}

/**
 * 2FA Auth
 */
export function loginUserWithOTP(data) {
  return (dispatch, getState) => {
    return dispatch({
      types: [
        LOGIN_WITH_OTP_PENDING,
        LOGIN_WITH_OTP_SUCCESS,
        LOGIN_WITH_OTP_ERROR,
      ],
      payload: {
        promise: loginWithOTP(data, getState().auth).then((res) => {
          return res;
        }),
      },
      callbacks: {
        successDidDispatch: (dispatch, data) => {
          dispatch(setAccessExpiry(data.accessExpiry));
        },
      },
      meta: {
        // transition: (nextState, action) => ({
        //     pathname: `/apps` //FIXME: Not working
        // })
      },
    });
  };
}
