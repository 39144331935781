import React from 'react';
import {
    LOGICAL_OPERATORS,
    CONDTIONAL_OPERATORS,  COUNT_TYPE_ENUM
} from '../../../../../../../constants';
import MultiSelect from '../../../../../../../components/reusable/MaterialUi/MultiSelect';
import Box from "../../../../../../../components/reusable/Box";
import Grid from '@material-ui/core/Grid';
import CreatorHolder from "../components/CreatorHolder";
import Count from "../../SelfComponents/components/ConversionRate/Count";
import Trend from "../../SelfComponents/components/ConversionRate/Trend";
import {uniqueKeyFromFilters } from "../../../../../../../utils";
import Components, { COMPONENT_IDS } from '../../SelfComponents';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabLabel from "../../../../../../../components/reusable/TabLabel";

const countWidget = Components.get(COMPONENT_IDS.CONVERSION_RATE_COUNT);
const trendWidget = Components.get(COMPONENT_IDS.CONVERSION_RATE_TREND);
const tabbedWidget = Components.get(COMPONENT_IDS.CONVERSION_RATE_TABBED_WIDGET);

export class DummyComponent extends React.Component {
    render(){
        return this.props.children;
    }
}

export default class ConversionRate extends React.Component {

    state = {
        currentEvent: 0
    };

    getDataKey = (props) => {
        const { name, queryParams, extraFilters, extension  } = props;
        const keyFromQueryParams = Object.keys(queryParams).map(o => [o, queryParams[o]].join("_")).join("_");
        return [ name, keyFromQueryParams, uniqueKeyFromFilters(extraFilters), extension ].filter(o => o.length > 0).join("_");
    };

    getCustomLabel = (props) => {
        const { name, extension  } = props;
        return `${name} ${extension}`;
    };

    render(){
        const {
            customQueryBuilder: {
                group, query
            },
            appState: { appEvents = [] },
            handleAdd
        } = this.props;
        const { currentEvent, startEvent, endEvent } = this.state;
        const queryParams = { startEvent, endEvent, group };
        const extraFilters = query;
        const events = appEvents.map(o => ({label: o, value: o}));
        const hasEvents = startEvent && startEvent.length > 0 && endEvent && endEvent.length > 0;
        return(
            <Grid container>
                <Grid item xs>
                    <Grid container spacing={16} justify="space-between">
                        <Grid item xs={12} md={4}>
                            <MultiSelect
                                value={endEvent}
                                handleChange={endEvent => {
                                    this.setState({endEvent: null}, () => {
                                        this.setState({endEvent});
                                    });
                                }}
                                options={events}
                                label="Goal Event (E2)"
                                margin="normal"
                                placeholder="Select Goal Event"
                                single
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <MultiSelect
                                value={startEvent}
                                handleChange={startEvent => {
                                    this.setState({startEvent: null}, () => {
                                        this.setState({startEvent});
                                    });
                                }}
                                options={events}
                                label="Source Event (E1)"
                                margin="normal"
                                placeholder="Select Source Event"
                                single
                            />
                        </Grid>
                    </Grid>
                    {
                        hasEvents && <CreatorHolder
                            handleAdd={handleAdd}
                            widget={tabbedWidget}
                            selfParams={{
                                startEvent, endEvent,
                                queryParams,
                                extraFilters: query,
                                customLabel: `Conversion Rate of ${startEvent} to ${endEvent}`
                            }}
                        >
                            <DummyComponent>
                                <Box title={`Conversion Rate: % of ${endEvent} / ${startEvent}`}>
                                    <Grid container spacing={16}>
                                        <Grid item xs>
                                            <AppBar position="static" color="default">
                                                <Tabs
                                                    value={currentEvent}
                                                    onChange={(e, currentEvent) => this.setState({currentEvent})}
                                                    indicatorColor="primary"
                                                    textColor="primary"
                                                    fullWidth
                                                    variant="scrollable"
                                                    scrollButtons="auto"
                                                >
                                                    <Tab label="Metrics" />
                                                    <Tab label="Events Rate Trend" />
                                                    <Tab label="Users Rate Trend" />
                                                </Tabs>
                                            </AppBar>
                                            {currentEvent === 0 && <div>
                                                <Grid container spacing={16}>
                                                    <Grid item xs={12} md={6}>
                                                        <CreatorHolder
                                                            {...this.props}
                                                            selfParams={{
                                                                startEvent, endEvent,
                                                                queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                                extraFilters,
                                                                dataKey: this.getDataKey({
                                                                    name: startEvent + ' - ' + endEvent,
                                                                    queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                                    extraFilters,
                                                                    extension: "Conversion Rate"
                                                                }),
                                                                customLabel: this.getCustomLabel({
                                                                    name: `% of ${endEvent} / ${startEvent} - Events Rate`,
                                                                    extension: ''
                                                                }),
                                                                of: COUNT_TYPE_ENUM.event
                                                            }}
                                                            widget={countWidget}
                                                        >
                                                            <Count />
                                                        </CreatorHolder>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <CreatorHolder
                                                            {...this.props}
                                                            selfParams={{
                                                                startEvent, endEvent,
                                                                queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                                                extraFilters,
                                                                dataKey: this.getDataKey({
                                                                    name: startEvent + ' - ' + endEvent,
                                                                    queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                                                    extraFilters,
                                                                    extension: "Users"
                                                                }),
                                                                customLabel: this.getCustomLabel({
                                                                    name: `% of ${endEvent} / ${startEvent} - Users Rate`,
                                                                    extension: ''
                                                                }),
                                                                of: COUNT_TYPE_ENUM.users
                                                            }}
                                                            widget={countWidget}
                                                        >
                                                            <Count/>
                                                        </CreatorHolder>
                                                    </Grid>
                                                </Grid>
                                            </div>}
                                            {currentEvent === 1 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        startEvent, endEvent,
                                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                        extraFilters,
                                                        dataKey: this.getDataKey({
                                                            name: startEvent + ' - ' + endEvent,
                                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                            extraFilters,
                                                            extension: "Trend"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: `% of ${endEvent} / ${startEvent} - Events Rate Trend`,
                                                            extension: ''
                                                        }),
                                                        of: COUNT_TYPE_ENUM.event
                                                    }}
                                                    widget={trendWidget}
                                                >
                                                    <Trend/>
                                                </CreatorHolder>
                                            </div>}
                                            {currentEvent === 2 && <div>
                                                <CreatorHolder
                                                    {...this.props}
                                                    selfParams={{
                                                        startEvent, endEvent,
                                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                                        extraFilters,
                                                        dataKey: this.getDataKey({
                                                            name: startEvent + ' - ' + endEvent,
                                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                                            extraFilters,
                                                            extension: "users_trend"
                                                        }),
                                                        customLabel: this.getCustomLabel({
                                                            name: `% of ${endEvent} / ${startEvent} - Users Rate Trend`,
                                                            extension: ''
                                                        }),
                                                        of: COUNT_TYPE_ENUM.users
                                                    }}
                                                    widget={trendWidget}
                                                >
                                                    <Trend/>
                                                </CreatorHolder>
                                            </div>}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </DummyComponent>
                        </CreatorHolder>
                    }
                </Grid>
            </Grid>
        )
    }

}