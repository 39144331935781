/**
 * Created by Araja Jyothi Babu on 13-Oct-16.
 */

import React, {Component, } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import { ApxorAppInfo } from "../../../../config";
import { darkTheme } from "../../../../styles";

function mapStateToProps(state) {
    return {
        auth: state.auth,
        router: state.routing,
        app: state.app.app,
        navigation: state.navigation
    };
}

function mapDispatchToProps(dispatch) {
    return {

    };
}

const styles = theme => ({
    root: {
        width: '100%',
        height: '100%',
        //marginTop: apxTheme.spacing.unit * 3,
        zIndex: 1,
        overflow: 'hidden',
    },
    appFrame: {
        position: 'relative',
        display: 'flex',
        width: '100%',
        height: '100%',
    }
});

class Auth extends Component{

    componentWillMount(){

    }

    componentWillReceiveProps(nextProps){

    }

    componentDidMount(){

    }

    render() {
        const {
            children
        } = this.props;

        return (
            <Grid container spacing={0} style={{minHeight: '100vh'}}>
                <Grid item xs={3} style={{background: darkTheme.palette.background.default}}>
                    <img src={ApxorAppInfo.whiteLogo} alt="Apxor" style={{margin: 20, width: '50%'}}/>
                    <img src={ApxorAppInfo.users} alt="Apxor" style={{width: '70%', margin: '0 auto', display: 'block'}}/>
                </Grid>
                <Grid item xs>
                    <div style={{display: 'table', width: `100%`, minHeight: '100vh'}}>
                        <div style={{display: 'table-cell', width: '100%', verticalAlign: 'middle'}}>
                            <Grid container justify="center">
                                <Grid item xs={12} sm={10} md={6} lg={5}>{ children }</Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        )
    }
}

Auth.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withStyles(styles)(Auth));