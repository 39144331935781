/**
 * Created by Rakesh Peela
 * Date: 25-Jan-2020
 * Time: 8:45 PM
 */

import generateShortID from "../../../utils";
import {ACTION_CLASSES} from "./AvailableOptions";
import {
    CONSTANT_CONFIGS, CONTAINER_CONFIG,
    MESSAGE_COACHMARK_LAYOUT_TYPES, TEXT_CONFIG
} from "./common";

const GetDefaultCoachmarkJSON = (type) => {
    let json = {
        "name": "",
        "activity": "",
        "delay": 1000,
        "timeout": 30000,
        "find_interval": 2000,
        "display_type": "coach_mark",
        "type": "IN_LINE",
        "coach_mark_details": {
            "arrow_color": "#F1F1F1",                                                                                                                                   
            "arrow_stroke_width": 10,
            "has_arrow": false,
            "has_ripple": true,
            "type": "circle"
        },
        "dim_background": true,
        "text_config": {
            ...TEXT_CONFIG,
            text: "Your text goes here.",
            __id: generateShortID()
        },
        "buttons_config": [],
        ...CONTAINER_CONFIG,
        ...CONSTANT_CONFIGS,
        bg_color: "#03A9B6",
        opacity: 0.8,
        action_class: ACTION_CLASSES.COACHMARK,
        layout_type: type,
        _id: generateShortID()
    };

    switch (type) {
        case MESSAGE_COACHMARK_LAYOUT_TYPES.DEFAULT:
            return {
                ...json,
                window_attributes: {
                    ...json.window_attributes,
                    position: "TOP"
                },
                layout_type: MESSAGE_COACHMARK_LAYOUT_TYPES.DEFAULT
            };
        default:
            return json;
    }
};
export default GetDefaultCoachmarkJSON;