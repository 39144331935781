/**
 * Created by Rakesh Peela
 * Date: 01-Jan-2020
 * Time: 1:28 PM
 */

import {Button, Divider, Grid, Typography, withStyles} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Radio from '@material-ui/core/Radio';
import OfflineBoltIcon from '@material-ui/icons/OfflineBolt';
import debounce from "lodash/debounce";
import React from 'react';
import {connect} from 'react-redux/src';
import {APP_PLATFORMS} from "../../../../../../../../../../../constants";
import ClassicCard from "../../../ClassicCard";
import DummyInsetContainer from "../DummyInsetContainer";
import ModuleConfigSection from "../modules/ModuleConfigSection";
import TargetConfiguration from "../modules/TargetConfiguration";
import SSEDevicePreview from "../SSEDevicePreview";
import {publishDesignForPreview_action} from './actions';
import {ORIENTATION_ENUM} from "./utils";

function mapStateToProps(state) {
    return {
        appState: state.app,
        previewState: state.previewState
    }
}

function mapDispatchToProps(dispatch) {
    return {
        triggerPreviewOnDevice: (data, appId) => dispatch(publishDesignForPreview_action(data, appId)),
        bindedDispatch: dispatch
    }
}

// OnePlus 7's screen size scaled down
const preview_config = {
    height: 585,
    width: 270,
    orientation: "portrait"
};

class DeviceInfo extends React.Component {
    render() {
        const {id, hardware_model, os_version, width, height} = this.props.info;
        return (
            <div>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <ModuleConfigSection title={"Hardware Model"} typographyStyleProps={{fontWeight: 600}}>
                            <Typography variant={"subheading"}>
                                {hardware_model}
                            </Typography>
                        </ModuleConfigSection>
                    </Grid>
                    <Grid item xs={6}>
                        <ModuleConfigSection title={"OS Version"} typographyStyleProps={{fontWeight: 600}}>
                            <Typography variant={"subheading"}>
                                {os_version}
                            </Typography>
                        </ModuleConfigSection>
                    </Grid>
                </Grid>
                <Divider style={{margin: "8px 0"}}/>
                <Grid container spacing={16}>
                    <Grid item xs={6}>
                        <ModuleConfigSection title={"Device ID"} typographyStyleProps={{fontWeight: 600}}>
                            <Typography variant={"subheading"}>
                                {id}
                            </Typography>
                        </ModuleConfigSection>
                    </Grid>
                    <Grid item xs={6}>
                        <ModuleConfigSection title={"Dimensions"} typographyStyleProps={{fontWeight: 600}}>
                            <Typography variant={"subheading"}>
                                <span style={{fontWeight: 600}}>Width:</span> {width}px <br/>
                                <span style={{fontWeight: 600}}>Height:</span> {height}px
                            </Typography>
                        </ModuleConfigSection>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

const pageStyle = (theme) => ({
    deviceListItem: {
        marginTop: 4,
        marginBottom: 4,
        borderRadius: 4,
        padding: 0
    },
    deviceListItem_checkBox: {
        padding: 4
    },
    deviceListItem_text: {
        padding: "2px 12px"
    },
    dialogActionsBar: {
        background: "rgba(63, 80, 181, 0.8)",
        padding: 8,
        margin: 0,
    }
});

class DesignPreview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sseData: {
                screen: {
                    orientation: ORIENTATION_ENUM.PORTRAIT
                }
            },
            preview_width: preview_config.width,
            preview_height: preview_config.height,
            activity: this.props.ui.activity,
            view_id: this.props.ui.view_id,
            designMessage: "No View ID to select",
            selectedDevice: null
        };

        this.debounce = debounce((fn, data, stepIndex) => {
            fn(data, stepIndex)
        }, 300);
    }

    componentWillUnmount() {
        this.setState({
            sseData: {}
        }, () => {
            this.setState({
                sseData: {
                    screen: {
                        orientation: ORIENTATION_ENUM.PORTRAIT
                    }
                }
            });
        });
    }

    handleSSEDataReceive = (data) => {
        const {updateInNewUI, ui, currentStep} = this.props;
        const {device_info: {width: device_width, height: device_height}, screen: {navigation, orientation}} = data;
        this.setState({
            sseData: data,
            preview_width: this.scaleToRatio({device_width, device_height}, preview_config, orientation).width,
            preview_height: this.scaleToRatio({device_width, device_height}, preview_config, orientation).height,
            activity: navigation,
        }, () => {
            if (data.screen.navigation) {
                this.debounce(updateInNewUI, {...ui, activity: navigation}, currentStep)
            }
        })
    };

    scaleToRatio = (device_config, preview_config, orientation) => {
        const {width, height} = preview_config;
        const {device_width, device_height} = device_config;

        let w_scaleFactor = device_width / width,
            h_scaleFactor = device_height / height;
        let calc_width = Math.round(device_width / w_scaleFactor),
            calc_height = Math.round(device_height / h_scaleFactor);

        return {
            width: orientation === ORIENTATION_ENUM.PORTRAIT ? calc_width : calc_height,
            height: orientation === ORIENTATION_ENUM.PORTRAIT ? calc_height : calc_width
        }
    };

    render() {
        const {
            appState: {appScreenNames = [], appTestDevices = [], app: {app_id, basic_info: {platform}}},
            updateInNewUI, showPreviewDialog, triggerPreviewOnDevice, ui, currentStep,
            appPlatform, classes
        } = this.props;
        const {selectedDevice, preview_width, preview_height, sseData: {screen: {orientation, navigation,}, device_info}} = this.state;
        return (
            <Dialog
                maxWidth={"xl"}
                fullScreen
                open={showPreviewDialog}
                onClose={() => this.props.handleCloseDialog()}
                disableBackdropClick
                disableEscapeKeyDown
            >
                <DialogTitle>Design Preview</DialogTitle>
                <DialogContent>
                    <DummyInsetContainer>
                        <Grid container spacing={16} style={{
                            minHeight: orientation === ORIENTATION_ENUM.PORTRAIT ? 540 : 540 / 2
                        }}>
                            <Grid item xs={8} sm={8} md={8} lg={8} style={{
                                minWidth: preview_config.width,
                                padding: 24,
                                alignItems: "center",
                            }}>
                                <SSEDevicePreview
                                    appId={app_id}
                                    handleChange={this.handleSSEDataReceive}
                                    imageConfig={{
                                        width: preview_width,
                                        height: preview_height
                                    }}
                                    selectedDevice={selectedDevice}
                                    handleViewSelect={(view_id, path) => {
                                        if (view_id && view_id !== "") {
                                            const {search_type, ...others} = ui;
                                            this.setState({
                                                view_id: view_id,
                                                designMessage: "Selected View ID"
                                            });
                                            this.debounce(updateInNewUI, {...others, view_id}, currentStep);
                                        } else if (path && path !== "") {
                                            this.setState({
                                                view_id: path,
                                                designMessage: "Selected View Path"
                                            });
                                            this.debounce(updateInNewUI, {
                                                ...ui,
                                                view_id: path,
                                                search_type: "p"
                                            }, currentStep);
                                        } else {
                                            this.setState({
                                                designMessage: "No View ID to select"
                                            });
                                            console.log("No View ID Available");
                                        }
                                    }}
                                />
                            </Grid>
                            <Grid item xs={4} sm={4} md={4} lg={4} style={{padding: 24}}>
                                {
                                    appPlatform === APP_PLATFORMS.ios
                                    && <Typography style={{
                                        color: "#463815",
                                        backgroundColor: "#ecc86c",
                                        borderRadius: 4,
                                        padding: "4px 12px",
                                        margin: 4,
                                        marginBottom: 8
                                    }}>
                                        ⚠️ If you're running your application through <b>XCode</b>, make sure you
                                        have <b>Accessibility
                                        Inspector</b> running, before you click on "Select Screen" using <b>Apxor</b>’s
                                        Helper Bubble in your Application.
                                    </Typography>
                                }
                                <ClassicCard>
                                    <TargetConfiguration
                                        withTargetButton={false}
                                        key={platform + "--target--config"}
                                        appScreenNames={appScreenNames}
                                        disableActivitySelector
                                        platform={platform}
                                        activity={this.state.activity}
                                        view_id={this.state.view_id}
                                        handleOnValueChange={(data, key) => this.setState({
                                            [key]: data
                                        }, () => {
                                            this.debounce(updateInNewUI, {...ui, [key]: data}, currentStep);
                                        })}
                                    />
                                    {/*<div style={{*/}
                                    {/*    display: "flex",*/}
                                    {/*    color: "#929292",*/}
                                    {/*    alignItems: "center",*/}
                                    {/*    marginTop: 6*/}
                                    {/*}}>*/}
                                    {/*    <InfoIcon style={{color: "#929292", marginRight: 6}}/>*/}
                                    {/*    <Typography style={{color: "#929292"}}>*/}
                                    {/*        {designMessage}*/}
                                    {/*    </Typography>*/}
                                    {/*</div>*/}
                                    <Button
                                        variant={"contained"} color={"primary"}
                                        style={{marginTop: 8}}
                                        disabled={
                                            ui.view_id === ""
                                            || ui.view_id === null
                                            || ui.activity === ""
                                            || ui.activity === null
                                        }
                                        onClick={() => {
                                            if (ui.view_id === "") {
                                                console.error("No View_id");
                                            } else if (ui.activity === "") {
                                                console.error("No Activity");
                                            } else {
                                                triggerPreviewOnDevice({
                                                    type: "IN_LINE", //TODO: fixme
                                                    ui
                                                }, app_id);
                                            }
                                        }}
                                    >
                                        <OfflineBoltIcon style={{marginRight: 8}}/> Preview on Device
                                    </Button>
                                </ClassicCard>
                                {
                                    appTestDevices.length >= 0 &&
                                    <ClassicCard style={{marginTop: 12}}>
                                        <ModuleConfigSection
                                            title={"Select Your Test Device"}
                                            typographyStyleProps={{fontWeight: 600}}
                                        >
                                            <List
                                                style={{
                                                    maxHeight: 180,
                                                    overflowY: "scroll",
                                                    overflowX: "hidden",
                                                    padding: 0,
                                                    paddingRight: 8
                                                }}>
                                                {
                                                    appTestDevices.map((testDevice, index) => {
                                                        const isSelected = selectedDevice === testDevice.id;
                                                        return (
                                                            <ListItem
                                                                key={testDevice.id + "-" + index}
                                                                role={undefined} dense button
                                                                className={classes.deviceListItem}
                                                                style={{background: isSelected ? "#ced5ff70" : "transparent"}}
                                                                onClick={() => this.setState({selectedDevice: testDevice.id})}
                                                            >
                                                                <Radio
                                                                    color={"primary"}
                                                                    className={classes.deviceListItem_checkBox}
                                                                    checked={isSelected}
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                />
                                                                <ListItemText
                                                                    className={classes.deviceListItem_text}
                                                                    primary={`${testDevice.hasOwnProperty("nick_name") ? testDevice.nick_name : testDevice.model}`}
                                                                    secondary={`${testDevice.id}` + (testDevice.hasOwnProperty("nick_name") ? " · " + testDevice.model : "")}
                                                                />
                                                            </ListItem>
                                                        )
                                                    })
                                                }
                                            </List>
                                        </ModuleConfigSection>
                                    </ClassicCard>
                                }
                                {
                                    selectedDevice
                                    && device_info
                                    && selectedDevice === device_info.id
                                    && <Typography style={{padding: 4, marginTop: 12}}>
                                        Showing Device Preview from <b>{device_info.hardware_model}</b>
                                    </Typography>
                                }
                                {/*{*/}
                                {/*    device_info && <ClassicCard style={{marginTop: 12}}>*/}
                                {/*        <DeviceInfo info={device_info}/>*/}
                                {/*    </ClassicCard>*/}
                                {/*}*/}
                            </Grid>
                        </Grid>
                    </DummyInsetContainer>
                </DialogContent>
                <DialogActions className={classes.dialogActionsBar}>
                    <Button onClick={() => this.props.handleDialogCancel()} color="secondary" style={{color: "white"}}>
                        Done
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(pageStyle)(DesignPreview));
