/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React, { Component } from 'react';
import NumberBox from '../../../../../../components/reusable/MaterialUi/NumberBox';
import Grid from '@material-ui/core/Grid';
import PeopleIcon from '@material-ui/icons/People';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import Box from "../../../../../../components/reusable/Box";
import {
    AreaChart, Area, XAxis, YAxis, Legend,
    CartesianGrid, Tooltip, ResponsiveContainer,
    ComposedChart, Line, ReferenceDot, ReferenceLine,
    BarChart, Bar, Brush
} from 'recharts';
import Switch from "../../../../../../components/reusable/Switch";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';
import {Paper, Typography} from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {getMiscDistributionOfEvent} from "./actions";

export class UserCounts extends Component {
    render(){
        return(
            <Grid container spacing={16}>
                <Grid item xs={4} xl={4} lg={4} md={4} sm={4} >
                    <Paper style={{minHeight: 315}}>
                        <NumberBox heading={123445} subheading="All Users" icon={<PeopleIcon/>}/>
                    </Paper>
                </Grid>
                <Grid item xs xl lg md sm>
                    <Grid container>
                        <Grid item xs xl lg md sm>
                            <NumberBox heading={123445} subheading="All Users" icon={<PeopleIcon style={{color: '#FF8943'}}/>}/>
                        </Grid>
                        <Grid item xs xl lg md sm>
                            <NumberBox heading={7300} subheading="New Installs" icon={<PersonAddIcon style={{color: "#322BD9"}}/>}/>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs xl lg md sm>
                            <NumberBox heading={63000} subheading="Returning Users" icon={<PeopleIcon style={{color: "#3DD2B2"}}/>}/>
                        </Grid>
                        <Grid item xs xl lg md sm>
                            <NumberBox heading={38000} subheading="Uninstalls" icon={<ExitToAppIcon style={{color: "#EA8A8B"}}/>}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

export class VersionTrends extends Component {

    data = [
        {name: 'Jan 1st', "v6.1": 3000, "v6.4": 400, "v6.8": 0},
        {name: 'Jan 2nd', "v6.1": 2000, "v6.4": 998, "v6.8": 0},
        {name: 'Jan 3rd', "v6.1": 1000, "v6.4": 1300, "v6.8": 0},
        {name: 'Jan 4th', "v6.1": 780, "v6.4": 1608, "v6.8": 100},
        {name: 'Jan 5th', "v6.1": 490, "v6.4": 1100, "v6.8": 1181},
        {name: 'Jan 6th', "v6.1": 100, "v6.4": 1800, "v6.8": 2100},
        {name: 'Jan 7th', "v6.1": 30, "v6.4": 800, "v6.8": 2300},
    ];

    render(){
        return(
            <Box
                title="Version Trends"
                icon={<PeopleIcon/>}
            >
                <ResponsiveContainer height={300}>
                    <AreaChart data={this.data}
                               margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip/>
                        <Area type='monotone' dataKey='v6.1' stackId="1" stroke='#8884d8' fill='#8884d8' />
                        <Area type='monotone' dataKey='v6.4' stackId="1" stroke='#82ca9d' fill='#82ca9d' />
                        <Area type='monotone' dataKey='v6.8' stackId="1" stroke='#ffc658' fill='#ffc658' />
                    </AreaChart>
                </ResponsiveContainer>
            </Box>
        )
    }
}

const SWITCH_DATA = [ "Day", "Week", "Month" ];

function minMaxAverageOf(arr = [], key) {
    const obj = {};
    obj.avg = arr.reduce((a, b) => a + b[key], 0) / arr.length;
    obj.max = arr.reduce((a, b) => Math.max(a, b[key]), 0);
    obj.min = arr.reduce((a, b) => Math.min(a, b[key]), Infinity);
    obj.minKey = arr[arr.findIndex(o => o[key] === obj.min)].name;
    obj.maxKey = arr[arr.findIndex(o => o[key] === obj.max)].name;
    return obj;
}

export class ActiveUsers extends Component {

    data = [
        {name: 'Jan 1st', "All": 3000, "Day 1-7": 900, "Previous All": 2300, "Previous Day 1-7": 800},
        {name: 'Jan 2nd', "All": 2000, "Day 1-7": 998, "Previous All": 2100, "Previous Day 1-7": 880},
        {name: 'Jan 3rd', "All": 3000, "Day 1-7": 1300, "Previous All": 2900, "Previous Day 1-7": 1400},
        {name: 'Jan 4th', "All": 5080, "Day 1-7": 1608, "Previous All": 2100, "Previous Day 1-7": 1800},
        {name: 'Jan 5th', "All": 4900, "Day 1-7": 1100, "Previous All": 2181, "Previous Day 1-7": 1500},
        {name: 'Jan 6th', "All": 4100, "Day 1-7": 1800, "Previous All": 2100, "Previous Day 1-7": 1400},
        {name: 'Jan 7th', "All": 4230, "Day 1-7": 1800, "Previous All": 2300, "Previous Day 1-7": 1500},
    ];

    constructor(props) {
        super(props);
        this.state = {
            showPrevious: false,
            showAggregations: false,
            value: { min: 1, max: 7 },
        }
    }

    render(){
        const { showPrevious, showAggregations, value } = this.state;
        const allStats = minMaxAverageOf(this.data, "All");
        const dayStats = minMaxAverageOf(this.data, "Day 1-7");
        return(
            <Box
                title="Active Users"
                icon={<PeopleIcon/>}
                controls={
                    <Grid container style={{maxWidth: '60%'}} spacing={4}>
                        <Grid item xs xl lg md sm>
                            <Switch value={"Day"} data={SWITCH_DATA} handleChange={() => null} />
                        </Grid>
                        <Grid item xs={3} xl={3} lg={3} md={3} sm={3} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showAggregations}
                                        onChange={(e) => this.setState({showAggregations: !showAggregations})}
                                        value="showAggregations"
                                    />
                                }
                                label="Show Aggregations"
                            />
                        </Grid>
                        <Grid item xs={3} xl={3} lg={3} md={3} sm={3} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showPrevious}
                                        onChange={(e) => this.setState({showPrevious: !showPrevious})}
                                        value="showPrevious"
                                    />
                                }
                                label="Show Previous"
                            />
                        </Grid>
                    </Grid>
                }
                footer={
                    <div style={{minHeight: 40, marginTop: 20}}>
                    <InputRange
                        maxValue={90}
                        minValue={0}
                        value={value}
                        onChange={value => this.setState({ value })}
                        onChangeComplete={value => console.info(value)}
                        draggableTrack
                        allowSameValues
                    />
                    </div>
                }
            >
                <ResponsiveContainer height={300}>
                    <ComposedChart data={this.data}
                               margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                        <defs>
                            <linearGradient id="all" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="rest" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        {/*<CartesianGrid strokeDasharray="3 3"/>*/}
                        <Tooltip/>
                        <Legend />
                        <Area type='monotone' dataKey='All' stroke='#8884d8' fillOpacity={1} fill="url(#all)" />
                        {
                            showPrevious && <Line type='monotone' strokeDasharray="3 3" dataKey='Previous All' stroke='#8884d8' fill="#8884d8" />
                        }
                        {
                            showAggregations && [
                                <ReferenceLine y={allStats.avg} label="All Avg" stroke="#8884d8" strokeDasharray="10 10" />,
                                <ReferenceDot x={allStats.maxKey} y={allStats.max} r={10} label="Max" stroke="#8884d8" fill="#8884d8"/>,
                                <ReferenceDot x={allStats.minKey} y={allStats.min} r={10} label="Min" stroke="#8884d8" fill="#8884d8"/>
                            ]
                        }
                        <Area type='monotone' dataKey='Day 1-7' stroke='#82ca9d' fillOpacity={1} fill="url(#rest)" />
                        {
                            showAggregations && [
                                <ReferenceLine y={dayStats.avg} label="Day Avg" stroke="#82ca9d" strokeDasharray="10 10" />,
                                <ReferenceDot x={dayStats.maxKey} y={dayStats.max} label="Max" stroke="#82ca9d" fill="#82ca9d"/>,
                                <ReferenceDot x={dayStats.minKey} y={dayStats.min} label="Min" stroke="#82ca9d" fill="#82ca9d"/>
                            ]
                        }
                        {
                            showPrevious && <Line type='monotone' strokeDasharray="3 3" dataKey='Previous Day 1-7' stroke='#82ca9d' fill="#82ca9d" />
                        }
                    </ComposedChart>
                </ResponsiveContainer>
            </Box>
        )
    }
}

export class UninstallUsers extends Component {

    data = [
        {name: 'Jan 1st', "All": 3000, "Day 1-7": 900, "Previous All": 2300, "Previous Day 1-7": 800},
        {name: 'Jan 2nd', "All": 2000, "Day 1-7": 998, "Previous All": 2100, "Previous Day 1-7": 880},
        {name: 'Jan 3rd', "All": 3000, "Day 1-7": 1300, "Previous All": 2900, "Previous Day 1-7": 1400},
        {name: 'Jan 4th', "All": 5080, "Day 1-7": 1608, "Previous All": 2100, "Previous Day 1-7": 1800},
        {name: 'Jan 5th', "All": 4900, "Day 1-7": 1100, "Previous All": 2181, "Previous Day 1-7": 1500},
        {name: 'Jan 6th', "All": 4100, "Day 1-7": 1800, "Previous All": 2100, "Previous Day 1-7": 1400},
        {name: 'Jan 7th', "All": 4230, "Day 1-7": 1800, "Previous All": 2300, "Previous Day 1-7": 1500},
    ];

    constructor(props) {
        super(props);
        this.state = {
            showPrevious: false,
            showAggregations: false,
            value: { min: 1, max: 7 },
        }
    }

    render(){
        const { showPrevious, showAggregations, value } = this.state;
        const allStats = minMaxAverageOf(this.data, "All");
        const dayStats = minMaxAverageOf(this.data, "Day 1-7");
        return(
            <Box
                title={<Typography variant="subtitle1" style={{marginLeft: 5}}>Uninstalls (<strong>23 </strong> %)</Typography>}
                icon={<ExitToAppIcon/>}
                controls={
                    <Grid container style={{maxWidth: '60%'}} spacing={4}>
                        <Grid item xs xl lg md sm>
                            <Switch value={"Day"} data={SWITCH_DATA} handleChange={() => null} />
                        </Grid>
                        <Grid item xs={3} xl={3} lg={3} md={3} sm={3} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showAggregations}
                                        onChange={(e) => this.setState({showAggregations: !showAggregations})}
                                        value="showAggregations"
                                    />
                                }
                                label="Show Aggregations"
                            />
                        </Grid>
                        <Grid item xs={3} xl={3} lg={3} md={3} sm={3} >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showPrevious}
                                        onChange={(e) => this.setState({showPrevious: !showPrevious})}
                                        value="showPrevious"
                                    />
                                }
                                label="Show Previous"
                            />
                        </Grid>
                    </Grid>
                }
                footer={
                    <div style={{minHeight: 40, marginTop: 20}}>
                        <InputRange
                            maxValue={90}
                            minValue={0}
                            value={value}
                            onChange={value => this.setState({ value })}
                            onChangeComplete={value => console.info(value)}
                            draggableTrack
                            allowSameValues
                        />
                    </div>
                }
            >
                <ResponsiveContainer height={300}>
                    <ComposedChart data={this.data}
                                   margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                        <defs>
                            <linearGradient id="all" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#8884d8" stopOpacity={0}/>
                            </linearGradient>
                            <linearGradient id="rest" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                                <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                            </linearGradient>
                        </defs>
                        <XAxis dataKey="name"/>
                        <YAxis/>
                        {/*<CartesianGrid strokeDasharray="3 3"/>*/}
                        <Tooltip/>
                        <Legend />
                        <Area type='monotone' dataKey='All' stroke='#8884d8' fillOpacity={1} fill="url(#all)" />
                        {
                            showPrevious && <Line type='monotone' strokeDasharray="3 3" dataKey='Previous All' stroke='#8884d8' fill="#8884d8" />
                        }
                        {
                            showAggregations && [
                                <ReferenceLine y={allStats.avg} label="All Avg" stroke="#8884d8" strokeDasharray="10 10" />,
                                <ReferenceDot x={allStats.maxKey} y={allStats.max} r={10} label="Max" stroke="#8884d8" fill="#8884d8"/>,
                                <ReferenceDot x={allStats.minKey} y={allStats.min} r={10} label="Min" stroke="#8884d8" fill="#8884d8"/>
                            ]
                        }
                        <Area type='monotone' dataKey='Day 1-7' stroke='#82ca9d' fillOpacity={1} fill="url(#rest)" />
                        {
                            showAggregations && [
                                <ReferenceLine y={dayStats.avg} label="Day Avg" stroke="#82ca9d" strokeDasharray="10 10" />,
                                <ReferenceDot x={dayStats.maxKey} y={dayStats.max} label="Max" stroke="#82ca9d" fill="#82ca9d"/>,
                                <ReferenceDot x={dayStats.minKey} y={dayStats.min} label="Min" stroke="#82ca9d" fill="#82ca9d"/>
                            ]
                        }
                        {
                            showPrevious && <Line type='monotone' strokeDasharray="3 3" dataKey='Previous Day 1-7' stroke='#82ca9d' fill="#82ca9d" />
                        }
                    </ComposedChart>
                </ResponsiveContainer>
            </Box>
        )
    }
}

export class SessionsDistribution extends Component {
    data = [
        {date: 'Jan 1st', name: '0 Sec', "Sessions": 300, "Sessions / User": 400, "Session Length / User": 60},
        {date: 'Jan 2nd', name: '1-10 Sec', "Sessions": 2000, "Sessions / User": 998, "Session Length / User": 120},
        {date: 'Jan 3rd', name: '11-30 Sec', "Sessions": 1000, "Sessions / User": 1300, "Session Length / User": 130},
        {date: 'Jan 4th', name: '31-60 Sec', "Sessions": 780, "Sessions / User": 1608, "Session Length / User": 100},
        {date: 'Jan 5th', name: '61-180 Sec', "Sessions": 490, "Sessions / User": 1100, "Session Length / User": 181},
        {date: 'Jan 6th', name: '180-300 Sec', "Sessions": 100, "Sessions / User": 1800, "Session Length / User": 100},
        {date: 'Jan 7th', name: '> 300 Sec', "Sessions": 30, "Sessions / User": 800, "Session Length / User": 300},
    ];
    render(){
        return(
            <Grid container>
                <Grid item xs xl lg md sm>
                    <Box
                        title="Session Length Distribution"
                        icon={<AccessTimeIcon/>}
                        controls={
                            <Typography>Avg. Session Length: <strong>64 </strong>Sec.</Typography>
                        }
                    >
                        <ResponsiveContainer height={300}>
                            <BarChart data={this.data}
                                       margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                <XAxis dataKey="name"/>
                                <YAxis/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip/>
                                <Bar type='monotone' dataKey='Sessions' stackId="1" stroke='#8884d8' fill='#8884d8' />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
                <Grid item xs xl lg md sm>
                    <Box
                        title="Session Time Series"
                        icon={<AccessTimeIcon/>}
                    >
                        <ResponsiveContainer height={300}>
                            <BarChart data={this.data}
                                      margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                                <XAxis dataKey="date"/>
                                <YAxis/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Tooltip/>
                                <Bar type='monotone' dataKey='Sessions / User' stroke='#8884d8' fill='#8884d8' />
                                <Bar type='monotone' dataKey='Session Length / User' stroke='#82ca9d' fill='#82ca9d' />
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
            </Grid>
        )
    }
}

export class VersionOnVersionStats extends Component {
    data = {
        "v6.0": {
            crashes: 20,
            hangs: 230,
            functional_errors: 320,
            dau: 1257,
            mau: 23145,
            uninstalls: 35,
            session_length_user: 45,
            session_length_session: 140,
            stickiness: 23
        },
        "v6.2": {
            crashes: 20,
            hangs: 230,
            functional_errors: 320,
            dau: 1257,
            mau: 23145,
            uninstalls: 35,
            session_length_user: 45,
            session_length_session: 140,
            stickiness: 23
        },
        "v6.4": {
            crashes: 20,
            hangs: 230,
            functional_errors: 320,
            dau: 1257,
            mau: 23145,
            uninstalls: 35,
            session_length_user: 45,
            session_length_session: 140,
            stickiness: 23
        },
        "v6.8": {
            crashes: 20,
            hangs: 230,
            functional_errors: 320,
            dau: 1257,
            mau: 23145,
            uninstalls: 35,
            session_length_user: 45,
            session_length_session: 140,
            stickiness: 23
        },
        "v6.9": {
            crashes: 20,
            hangs: 230,
            functional_errors: 320,
            dau: 1257,
            mau: 23145,
            uninstalls: 35,
            session_length_user: 45,
            session_length_session: 140,
            stickiness: 23
        }
    };
    render(){
        return(
            <Box
                title="Version on Version Stats"
                icon={<NewReleasesIcon/>}
            >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Version</TableCell>
                            <TableCell numeric>Crashes</TableCell>
                            <TableCell numeric>Hangs</TableCell>
                            <TableCell numeric>Functional Errors</TableCell>
                            <TableCell numeric>DAU</TableCell>
                            <TableCell numeric>MAU</TableCell>
                            <TableCell numeric>Uninstalls %</TableCell>
                            <TableCell numeric>Session Length / User / Day (Sec)</TableCell>
                            <TableCell numeric>Session Length / Session (Sec)</TableCell>
                            <TableCell numeric>Stickiness</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Object.keys(this.data).map(version => {
                            const item = this.data[version];
                            return (
                                <TableRow key={version}>
                                    <TableCell>{version}</TableCell>
                                    <TableCell numeric>{item.crashes}</TableCell>
                                    <TableCell numeric>{item.hangs}</TableCell>
                                    <TableCell numeric>{item.functional_errors}</TableCell>
                                    <TableCell numeric>{item.dau}</TableCell>
                                    <TableCell numeric>{item.mau}</TableCell>
                                    <TableCell numeric>{item.uninstalls}</TableCell>
                                    <TableCell numeric>{item.session_length_user}</TableCell>
                                    <TableCell numeric>{item.session_length_session}</TableCell>
                                    <TableCell numeric>{item.stickiness}</TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Box>
        )
    }
}

export class UserDistributionOverEvent extends Component {

    constructor(props){
        super(props);
        this.state = {
            event: props.event
        }
    }

    componentWillMount(){
        const { params: {appId}, dispatch } = this.props;
        dispatch(getMiscDistributionOfEvent(appId, this.state.event));
    }

    render(){
        const {
            misc: {
                follow_distribution = [],
            } = {}
        } = this.props;
        return(
            <Box title="User distribution for No. of follows">
                <ResponsiveContainer height={250}>
                    <ComposedChart data={follow_distribution}
                                   margin={{top: 20, right: 20, bottom: 20, left: 20}}>
                        <XAxis dataKey="key" label={{ value: "Follow Count", position: "insideBottomRight", dy: -8}} height={45}/>
                        <YAxis label={{ value: "Users", position: "insideLeft", angle: -90,   dy: 30}}/>
                        <Tooltip/>
                        <ReferenceLine y={0} stroke='#000'/>
                        {follow_distribution && follow_distribution.length > 0 && <Brush
                            dataKey='key'
                            height={30}
                            stroke="#429ef4"
                            /*tickFormatter={label => getVeoozLabel(label)}*/
                            /*endIndex={10}*/
                        />
                        }
                        <CartesianGrid stroke='#f5f5f5'/>
                        <Line type='monotone' dataKey='Users' stroke='#ff7300'/>
                    </ComposedChart>
                </ResponsiveContainer>
            </Box>
        )
    }
}