/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Navigation from '../Navigation';
import { enterDashboard, exitDashboard,
    getMetaDataOfApp
} from '../Apps/App/actions';
import Loading from '../../../../../components/reusable/Loading';
import {DASHBOARD_ENDPOINTS} from "../../../../../constants/EndPoints";
import {Helmet} from "react-helmet";
import NotListedLocation from "@material-ui/icons/NotListedLocation";
import Placeholder from "../../../../../components/reusable/Placeholder";
import Grid from '@material-ui/core/Grid';
import {updateGlobalDateTime} from "../Filters/actions";
import {DEMO_APP_ID, DEMO_END_DATE, DEMO_START_DATE} from "../../../../../config";
import moment from 'moment';
import {normalizedDate} from "../../../../../utils";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        appsState: state.apps,
        filters: state.filters,
        navigation: state.navigation,
        meta: state.meta
    };
}

function mapDispatchToProps(dispatch) {
    return {
        /**
         * This loads all APP META DATA like FILTERS, PATH_IDS, SESSION_GROUP_IDS, USER_GROUP_IDS
         * @param appId
         */
        hydrateApp: (appId) => {
            dispatch(enterDashboard(appId));
            dispatch(getMetaDataOfApp(appId));
        },
        deHydrateApp: () => dispatch(exitDashboard()),//TODO: dehydrate all
        updateDateTime: (startTime, endTime) => dispatch(updateGlobalDateTime(startTime, endTime)),
    };
}


/**
 * Checks whether current app belongs to logged in user
 * @param appId
 * @param apps
 * @returns {boolean}
 */
function isAuthorised(appId, apps = []) {
    return apps.some(app => app.app_id === appId);
}

class Dashboard extends Component {

    componentWillMount() {
        const { session, params: { appId } , hydrateApp, appState } = this.props;
        const { router } = this.context;
        // If this page is restricted, go to loginPage first.
        // (But pass on this page's path in order to redirect back upon login)
        if (!session.token) {
            const path = this.props.location.pathname;
            router.push(`/${DASHBOARD_ENDPOINTS.LOGIN}?redirect=${path}`);
        } else {
            const {attributes = {}} = appState;
            // if (Object.keys(attributes).length === 0) { //for direct loading from browser window
            hydrateApp(appId);
            // }
        }
    }

    componentWillUnmount(){
        this.props.deHydrateApp();
    }

    componentWillReceiveProps(nextProps){
        const { params: { appId }, appsState: { apps = [], appsLoaded } } = nextProps;
        const { router } = this.context;
        if(appsLoaded && !isAuthorised(appId, apps)){
            router.push(`/${DASHBOARD_ENDPOINTS.UNAUTHORISED}`);
        }
    }

    componentDidMount(){
        const { updateDateTime, params: { appId } } = this.props;
        if(appId === DEMO_APP_ID){
            const since = normalizedDate(moment(DEMO_START_DATE));
            const till = normalizedDate(moment(DEMO_END_DATE));
            updateDateTime(since, till);
        }
    }

    render() {
        const {
            params, appState, children,
            navigation: { heading },
            meta: { api_pending }
        } = this.props;
        const {app, attributes} = appState;
        const isAppIntegrated = app && app.app_versions && Object.keys(app.app_versions).length > 0;
        const isHydrated  = app && attributes && Object.keys(attributes).length > 1;
        if (isAppIntegrated && isHydrated) {
            return (
                <Navigation appId={params.appId} {...this.props}>
                    <Helmet>
                        <title>{`${heading} | Apxor Dashboard`}</title>
                    </Helmet>
                    { children }
                </Navigation>
            );
        }else if(isHydrated && !isAppIntegrated){
            return children;
        } else{ //TODO: Need error message here
            return (
                <Grid container justify="center" style={{marginTop: '10%'}}>
                    <Grid item xs={10} md={8}>
                        {api_pending && <Loading size={120}/>}
                        {
                            !api_pending &&
                            <Placeholder
                                text="Seems something went wrong. Please contact us."
                                withIcon
                                icon={<NotListedLocation style={{width: 100, height: 100, opacity: 0.2}}/>}
                            />
                        }
                    </Grid>
                </Grid>
            )
        }
    }
}

Dashboard.propTypes = {
    children: PropTypes.object,
    location: PropTypes.object,
    hydrateApp: PropTypes.func.isRequired,
    deHydrateApp: PropTypes.func.isRequired
};

Dashboard.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);