/**
 * Created by Rakesh Peela
 * Date: 13-Aug-2019
 * Time: 11:48 AM
 */

export const PASSWORD_UPDATE = "PASSWORD_UPDATE";
export const PASSWORD_UPDATE_FAILED = "PASSWORD_UPDATE_FAILED";
export const PASSWORD_UPDATE_PENDING = "PASSWORD_UPDATE_PENDING";

export const PROFILE_DATA_UPDATE = "PROFILE_DATA_UPDATE";
export const PROFILE_DATA_UPDATE_FAILED = "PROFILE_DATA_UPDATE_FAILED";
export const PROFILE_DATA_UPDATE_PENDING = "PROFILE_DATA_UPDATE_PENDING";

export const PROFILE_PHOTO_UPDATE = "PROFILE_PHOTO_UPDATE";
export const PROFILE_PHOTO_UPDATE_FAILED = "PROFILE_PHOTO_UPDATE_FAILED";
export const PROFILE_PHOTO_UPDATE_PENDING = "PROFILE_PHOTO_UPDATE_PENDING";

export const PROFILE_PHOTO_DELETE = "PROFILE_PHOTO_DELETE";
export const PROFILE_PHOTO_DELETE_FAILED = "PROFILE_PHOTO_DELETE_FAILED";
export const PROFILE_PHOTO_DELETE_PENDING = "PROFILE_PHOTO_DELETE_PENDING";

export const RESET_STATE = "RESET_STATE";

export const GET_USER_ROLE = "GET_USER_ROLE";
export const GET_USER_ROLE_PENDING = "GET_USER_ROLE_PENDING";
export const GET_USER_ROLE_FAILED = "GET_USER_ROLE_FAILED";

export const FETCH_PENDING_APP_APPROVALS = "FETCH_PENDING_APP_APPROVALS";
export const FETCH_PENDING_APP_APPROVALS_PENDING =
  "FETCH_PENDING_APP_APPROVALS_PENDING";
export const FETCH_PENDING_APP_APPROVALS_FAILED =
  "FETCH_PENDING_APP_APPROVALS_FAILED";

export const START_2FA_SETTINGS = "START_2FA_SETTINGS";
export const START_2FA_SETTINGS_PENDING = "START_2FA_SETTINGS_PENDING";
export const START_2FA_SETTINGS_FAILED = "START_2FA_SETTINGS_FAILED";

export const ENABLE_2FA_SETTINGS = "ENABLE_2FA_SETTINGS";
export const ENABLE_2FA_SETTINGS_PENDING = "ENABLE_2FA_SETTINGS_PENDING";
export const ENABLE_2FA_SETTINGS_FAILED = "ENABLE_2FA_SETTINGS_FAILED";

export const DISABLE_2FA_SETTINGS = "DISABLE_2FA_SETTINGS";
export const DISABLE_2FA_SETTINGS_PENDING = "DISABLE_2FA_SETTINGS_PENDING";
export const DISABLE_2FA_SETTINGS_FAILED = "DISABLE_2FA_SETTINGS_FAILED";

export const GET_2FA_STATUS = "GET_2FA_STATUS";
export const GET_2FA_STATUS_PENDING = "GET_2FA_STATUS_PENDING";
export const GET_2FA_STATUS_FAILED = "GET_2FA_STATUS_FAILED";
