import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Button, Typography} from "@material-ui/core";
import Dialog from "./MaterialUi/Dialog";

export default class ARTPublish extends React.Component {

    state = {
       publishTriggered: false
    };

    render(){
        const { published = false, publishAction } = this.props;
        const { publishTriggered  } = this.state;
        return(
            <Fragment>
                <Button
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.setState({publishTriggered: true});
                    }}
                    variant="text"
                    disabled={published}
                    style={{marginTop: '0.6em'}}
                >{published ? "Published" : "Publish"}</Button>
                <Dialog
                    status={publishTriggered}
                    handleConfirm={() => {
                        publishAction();
                        this.setState({publishTriggered: false});
                    }}
                    title="Publish Confirmation"
                    handleClose={() => this.setState({publishTriggered: false})}
                    confirmLabel="Confirm"
                    allowCancelLabel="Cancel"
                >
                    <Typography variant="subtitle1">Are you sure want to publish ? </Typography>
                </Dialog>
            </Fragment>
        )
    }

}

ARTPublish.propTypes = {
    published: PropTypes.bool,
    publishAction: PropTypes.func.isRequired
};