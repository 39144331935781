/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../api';
import { makeDefaultQueryString, withQueryStrings } from '../../../../../../utils';
import {} from "./actionTypes";

const CUSTOM_REPORTS_API = "custom-reports";

const EXPLORER_API = "custom-reports/explorer";
const FLAT_TABLE_API = "custom-reports/flat-table";


export function getExplorerAPI(auth, appId, queryParams, extraFilters) {
    const url = makeDefaultQueryString(EXPLORER_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(extraFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getFlatTableAPI(auth, appId, queryParams, extraFilters) {
    const url = makeDefaultQueryString(FLAT_TABLE_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(extraFilters)
    };
    const onSuccessCallback = json => {
        const { aggregations, data = [] } = json || {};
        return { aggregations, data: data.map(({dimensions, ...o}) => ({...dimensions, ...o}))};
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getFlatTableForIdAPI(auth, appId, queryParams) {
    const url = makeDefaultQueryString(FLAT_TABLE_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function saveCustomReportAPI(auth, appId, queryParams, extraFilters) {
    const url = makeDefaultQueryString(CUSTOM_REPORTS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(extraFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getCustomReportsAPI(auth, appId, queryParams) {
    const url = makeDefaultQueryString(CUSTOM_REPORTS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getCustomReportAPI(auth, appId, reportId, queryParams) {
    const url = makeDefaultQueryString(CUSTOM_REPORTS_API + "/" + reportId, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function deleteCustomReportAPI(auth, appId, reportId, queryParams) {
    const url = makeDefaultQueryString(CUSTOM_REPORTS_API + "/" + reportId, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "DELETE",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}