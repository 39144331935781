
export const UPDATE_CUSTOM_REPORT_USER_GROUP = "UPDATE_CUSTOM_REPORT_USER_GROUP";

export const CUSTOM_REPORT_EXPLORER_PENDING = "CUSTOM_REPORT_EXPLORER_PENDING";
export const CUSTOM_REPORT_EXPLORER = "CUSTOM_REPORT_EXPLORER";
export const CUSTOM_REPORT_EXPLORER_FAILED = "CUSTOM_REPORT_EXPLORER_FAILED";

export const CUSTOM_REPORT_FLAT_TABLE_PENDING = "CUSTOM_REPORT_FLAT_TABLE_PENDING";
export const CUSTOM_REPORT_FLAT_TABLE = "CUSTOM_REPORT_FLAT_TABLE";
export const CUSTOM_REPORT_FLAT_TABLE_FAILED = "CUSTOM_REPORT_FLAT_TABLE_FAILED";

export const CUSTOM_REPORTS_PENDING = "CUSTOM_REPORTS_PENDING";
export const CUSTOM_REPORTS = "CUSTOM_REPORTS";
export const CUSTOM_REPORTS_FAILED = "CUSTOM_REPORTS_FAILED";

export const CUSTOM_REPORT_PENDING = "CUSTOM_REPORT_PENDING";
export const CUSTOM_REPORT = "CUSTOM_REPORT";
export const CUSTOM_REPORT_FAILED = "CUSTOM_REPORT_FAILED";

export const CUSTOM_REPORT_CREATE_PENDING = "CUSTOM_REPORT_CREATE_PENDING";
export const CUSTOM_REPORT_CREATE = "CUSTOM_REPORT_CREATE";
export const CUSTOM_REPORT_CREATE_FAILED = "CUSTOM_REPORT_CREATE_FAILED";

export const CUSTOM_REPORT_DELETE_PENDING = "CUSTOM_REPORT_DELETE_PENDING";
export const CUSTOM_REPORT_DELETE = "CUSTOM_REPORT_DELETE";
export const CUSTOM_REPORT_DELETE_FAILED = "CUSTOM_REPORT_DELETE_FAILED";

export const UPDATE_EXPLORER_LIMIT = "UPDATE_EXPLORER_LIMIT";

export const UPDATE_CUSTOM_REPORT_METRICS = "UPDATE_CUSTOM_REPORT_METRICS";

export const UPDATE_CUSTOM_REPORT_DIMENSIONS = "UPDATE_CUSTOM_REPORT_DIMENSIONS";

export const UPDATE_CUSTOM_REPORT_FILTERS = "UPDATE_CUSTOM_REPORT_FILTERS";

export const UPDATE_CUSTOM_REPORT_EXPLORER_QUERY = "UPDATE_CUSTOM_REPORT_EXPLORER_QUERY";

export const RESET_CUSTOM_REPORT = "RESET_CUSTOM_REPORT";