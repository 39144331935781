/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    PATH_PENDING, PATH, PATH_FAILED,
    RESET_PATH
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';

const INITIAL_PATH_STATE = {
    path: [],
    path_pending: false,
    path_failed: false
};

const INITIAL_STATE = {
    ...INITIAL_PATH_STATE
};

function pathReducer(state = INITIAL_STATE, action = {}) {
    const { type, payload } = action;
    switch (type) {

        case PATH_PENDING:
            return {
                ...state,
                ...INITIAL_PATH_STATE,
                path_pending: true
            };

        case PATH:
            return {
                ...state,
                ...INITIAL_PATH_STATE,
                path: payload
            };

        case PATH_FAILED:
            return {
                ...state,
                ...INITIAL_PATH_STATE,
                path_failed: true
            };

        case RESET_PATH:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default pathReducer;