/**
 * Created by jyothi on 14/1/17.
 */
import { callApi } from '../../../../../../api';
import { makeDefaultQueryString, withQueryStrings} from '../../../../../../utils';

const NOTIFICATIONS_API = "notifications";

const SEND_NOTIFICATION_API = (notificationId) => "notifications/" + notificationId + "/send";

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getNotificationsAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(NOTIFICATIONS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth: auth,
        //body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json || [];
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param notification
 * @returns {Promise}
 */
export function saveNotificationAPI(auth, appId, filters, notification) {
    const url = makeDefaultQueryString(NOTIFICATIONS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(notification)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function sendNotificationAPI(auth, appId, notificationId, filters) {
    const url = makeDefaultQueryString(SEND_NOTIFICATION_API(notificationId), auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param notificationId
 * @param notification
 * @returns {Promise}
 */
export function updateNotificationAPI(auth, appId, filters, notificationId, notification) {
    const url = makeDefaultQueryString(NOTIFICATIONS_API + '/' + notificationId, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "PUT",
        auth: auth,
        body: JSON.stringify(notification)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param notificationId
 * @param notification
 * @returns {Promise}
 */
export function deleteNotificationAPI(auth, appId, filters, notificationId, notification) {
    const url = makeDefaultQueryString(NOTIFICATIONS_API + '/' + notificationId, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "DELETE",
        auth: auth,
        //body: JSON.stringify(notification)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}