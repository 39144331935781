/**
 * Created by Araja Jyothi Babu on 13-Oct-16.
 */

/**
 * action when user logs out
 * @type {string}
 */
export const RESET_ALL = "RESET_ALL";

/**
 * Navigation Click Events
 * @type {string}
 */
export const NAVIGATION_LINK_CLICK = 'NAVIGATION_LINK_CLICK';
export const INVALIDATE_NAVIGATION_LINK_CLICK = 'INVALIDATE_NAVIGATION_LINK_CLICK';

/**
 * Global Constants for USP values
 * @type {string}
 */
export const USP_STABILITY = "OVERVIEW_USP_STABILITY";
export const USP_USABILITY = "OVERVIEW_USP_USABILITY";
export const USP_PERFORMANCE = "OVERVIEW_USP_PERFORMANCE";


export const GLOBAL_FILTERS_CHANGED = "GLOBAL_FILTERS_CHANGED";

export const ROUTER_LOCATION_CHANGE = "@@router/LOCATION_CHANGE"; //FIXME: may change

export const DO_SAVE_STATE = "DO_SAVE_STATE";
export const DO_NOT_SAVE_STATE = "DO_NOT_SAVE_STATE";

export const DASHBOARD_EVENTS = {
    SDK_MONITORING: {
        event: "SDK Monitoring",
        message: (status, user, location) => `SDK **${status}**`
    },
    UNINSTALL_TRACKING: {
        event: "Uninstall Tracking",
        message: (body, user, location) => `Uninstall Tracking changed with body \n\`${body}\`\n`
    },
    APP_META: {
        event: (type) => `${type} Events`,
        message: (name, status, user, location) => `**${name}** is **${status}**`
    },
    PATH: "Path",
    USER_GROUP: "User Group",
    SESSION_GROUP: "Session Group"
};

export const API_PENDING = "API_PENDING";
export const API_FAILED = "API_FAILED";
export const API_SUCCESS = "API_SUCCESS";