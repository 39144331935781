/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../api';
import {makeDefaultQueryString} from '../../../../../../utils';
import {formatTime, withQueryStrings} from "../../../../../../utils/index";

const CORRELATIONS_TIMESERIES_API = "time-series";
const CORRELATIONS_RETENTION_API = "total-user-retention";
const CORRELATIONS_UNINSTALL_API = "total-uninstall-timeseries";
const CORRELATIONS_LIST_API = "segments";
const COHORT_UPLOAD = "upload/cohort";

function getSegmentURL(segmentId, property){
    return "segmentation/"+ segmentId + "/" + property;
}

function makeRetentionAPI(segmentId){
    return "segmentation/" + segmentId + "/retention";
}

function getCountAPI(segmentId){
    return "segmentation/" + segmentId + "/count";
}

function getUninstallAPI(segmentId){
    return "segmentation/" + segmentId + "/uninstall";
}

function getDeleteSegmentAPI(segmentId){
    return "segments/" + segmentId;
}

const COUNT_API = "count";
const TIME_SERIES_API = "time-series";
const DISTRIBUTION_API = "distribution";

const beautifyRetentionBarModel = d => {
    for(let key in d){
        if(d.hasOwnProperty(key)){
            if(key !== "date"){
                d[key.replace("day", "Day ")] = d[key];
                delete d[key];
            }else{
                d[key] = formatTime(d[key], "MMM Do");
            }
        }
    }
    return d;
};

/**
 *
 * @param appId
 * @param auth
 * @param segmentId
 * @param filters
 * @param body
 * @returns {Promise}
 */
export function getSegmentTimeSeriesAPI(appId, auth, segmentId, filters, body) {
    const url = makeDefaultQueryString(getSegmentURL(segmentId, CORRELATIONS_TIMESERIES_API), auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId
 * @param auth
 * @param segmentId
 * @param filters
 * @param body
 * @returns {Promise}
 */
export function getSegmentRetentionAPI(appId, auth , segmentId, filters, body) {
    const url = makeDefaultQueryString(makeRetentionAPI(segmentId), auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;//json.sort((a, b) => a.date.localeCompare(b.date)).map(beautifyRetentionBarModel);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId
 * @param auth
 * @param filters
 * @param body
 * @returns {Promise}
 */
export function getCorrelationsRetentionAPI(appId, auth, filters, body) {
    const url = makeDefaultQueryString(CORRELATIONS_RETENTION_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json.sort((a, b) => a.date.localeCompare(b.date)).map(beautifyRetentionBarModel);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getCorrelationsUninstallAPI(appId, auth, filters, body) {
    const url = makeDefaultQueryString(CORRELATIONS_UNINSTALL_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json.sort((a, b) => a.date.localeCompare(b.date)).map(beautifyRetentionBarModel);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId
 * @param auth
 * @param segmentId
 * @param filters
 * @param body
 * @returns {Promise}
 */
export function getCorrelationsSegmentUninstallAPI(appId, auth, segmentId, filters, body) {
    const url = makeDefaultQueryString(getUninstallAPI(segmentId), auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json.sort((a, b) => a.date.localeCompare(b.date)).map(beautifyRetentionBarModel);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId
 * @param auth
 * @param filters
 * @returns {Promise}
 */
export function getSegmentListAPI(appId, auth, filters){
    const url = makeDefaultQueryString(CORRELATIONS_LIST_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return Array.isArray(json) ? json.reverse() : [];
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getSegmentCountAPI(appId, auth, segmentId, filters, body){
    const url = makeDefaultQueryString(getCountAPI(segmentId), auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function deleteSegmentAPI(appId, auth, filters, segmentId){
    const url = makeDefaultQueryString(getDeleteSegmentAPI(segmentId), auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "DELETE",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 * New APIs
 */

export function segmentCountAPI(appId, auth, segmentId, filters, body) {
    const url = makeDefaultQueryString(getSegmentURL(segmentId, COUNT_API), auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function segmentTimeSeriesAPI(appId, auth, segmentId, filters, body) {
    const url = makeDefaultQueryString(getSegmentURL(segmentId, TIME_SERIES_API), auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function segmentAttributeDistributionAPI(appId, auth, segmentId, filters, body) {
    const url = makeDefaultQueryString(getSegmentURL(segmentId, DISTRIBUTION_API), auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function uploadCohortAPI(appId, auth, cohortDetails) {
    const {cohortName, cohortDescription, cohortFile} = cohortDetails;
    const url = makeDefaultQueryString(COHORT_UPLOAD, auth, appId) + withQueryStrings({
        cohortName,
        description: cohortDescription
    });
    let formData = new FormData();
    formData.append("cohort", cohortFile, cohortFile.name);
    const config = {
        method: "POST",
        auth: auth,
        body: formData,
        headers: {'Content-Type': 'multipart/form-data' }
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return response;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}