/**
 * Created by jyothi on 22/11/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SnackBar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const styles = theme => ({
    close: {
        padding: theme.spacing.unit / 2
    },
});

class Snackbar extends React.Component {

    state = {
        open: true,
    };

    _id = 'message-id' + Math.random();

    handleClick = () => {
        this.setState({ open: true });
    };

    handleRequestClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false });
    };

    render() {
        const {
            classes, vertical = "bottom", horizontal = "center",
            children, autoHideDuration = 4000,
            action
        } = this.props;
        return (
            <SnackBar
                anchorOrigin={{vertical, horizontal}}
                open={this.state.open}
                autoHideDuration={autoHideDuration}
                onClose={this.handleRequestClose}
                ContentProps={{
                    'aria-describedby': this._id,
                }}
                message={<span id={this._id}>{ children }</span>}
                action={[ action,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleRequestClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            />
        );
    }
}

Snackbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Snackbar);