/**
 * Created by jyothi on 14/1/17.
 */
import React, {Component, Fragment} from 'react';
import {
    formatNumber,
    keyToTitle,
    randomColorWithIndex
} from '../../../../../../../utils';
import Switch from '../../../../../../../components/reusable/Switch';
import moment from 'moment';
import {ALL_USERS} from "../../../../../../../constants/index";
import {Grid, IconButton, TextField, Tooltip} from "@material-ui/core";
import Snackbar from "../../../../../../../components/reusable/MaterialUi/Snackbar";
import ToggleSwitch from "../../../../../../../components/reusable/MaterialUi/ToggleSwitch";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelActions from '@material-ui/core/ExpansionPanelActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import RemoveIcon from '@material-ui/icons/HighlightOff';
import NotificationIcon from '@material-ui/icons/Notifications';
import AddIcon from '@material-ui/icons/Add';
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import CheckboxGroup from "../../../../../../../components/reusable/MaterialUi/CheckboxGroup";
import DateRangePicker from '../../../../../../../components/reusable/DateRangePicker';
import { Simple, BigText, BigImage } from "./Simulators";
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import MobileSimulator from "../../../../../../../components/reusable/MobileSimulator";
import FormSelect from "../../../../../../../components/reusable/MaterialUi/FormSelect";
import DateTimePicker from "../../../../../../../components/reusable/MaterialUi/DateTimePicker";
import DeleteAction from "../../../../../../../components/reusable/DeleteAction";

export class AudienceSelector extends Component{

    constructor(props){
        super(props);
        const momentInstance = moment();
        const _start_time = momentInstance.subtract(14, "d").toJSON();
        const _end_time = momentInstance.toJSON();
        const {
            group = ALL_USERS,
            segment_id = null,
            start_time = _start_time,
            end_time = _end_time,
            versions = []
        } = props;
        this.state = { group, segment_id, start_time, end_time, versions };
    }

    componentWillMount(){
        const { isNew } = this.props;
        if(isNew){
            this.handleUpdate();
        }
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const { inEditMode, appState: { appSegments = [], app: { app_versions = [] } = {} } = {} } = this.props;
        const {
            segment_id,
            start_time, end_time, versions
        } = this.state;
        const disabled = !inEditMode && this.props.disabled;
        return(
            <Grid container spacing={16}>
                <Grid item xs>
                    {/*<Switch
                        data={USER_GROUPS}
                        value={group}
                        handleChange={group => this.setState({group}, this.handleUpdate)}
                    />*/}
                    <MultiSelect
                        multiple
                        options={app_versions.map(o => ({value: o, label: o}))}
                        value={versions}
                        handleChange={versions => this.setState({versions}, this.handleUpdate)}
                        placeholder="Default All Versions"
                        style={{maxWidth: 340, margin: '5px 0'}}
                        disabled={disabled}
                    />
                    <MultiSelect
                        options={appSegments.map(o => ({label: o.name, value: o._id}))}
                        value={segment_id}
                        handleChange={segment_id => this.setState({segment_id}, this.handleUpdate)}
                        placeholder="Select Segment"
                        single
                        style={{maxWidth: 340, margin: '5px 0'}}
                        disabled={disabled}
                    />
                    <br />
                    <Typography component="span" type="subheading">
                        Who visited between &nbsp;
                        <div style={{display: 'inline-block'}}>
                            <DateRangePicker
                                single
                                small
                                disabled={disabled}
                                disableAfter
                                anchorDirection="left"
                                date={moment(start_time)}
                                handleChange={start_time => this.setState({start_time}, this.handleUpdate)} disableFuture={false}
                            />
                        </div>
                        &nbsp;&nbsp;
                        and
                        &nbsp;&nbsp;
                        <div style={{display: 'inline-block'}}>
                            <DateRangePicker
                                single
                                small
                                disabled={disabled}
                                disableAfter
                                date={moment(end_time)}
                                handleChange={end_time => this.setState({end_time}, this.handleUpdate)} disableFuture={false} />
                        </div>
                    </Typography>
                    {/*<br />
                    <UserAndSessionProperties
                        {...this.props}
                        handleQueryUpdate={properties => {
                            this.props.handleQueryUpdate(properties);
                            this.setState({properties}, this.handleUpdate);
                        }}
                        noReset
                        withoutBox
                    />*/}
                </Grid>
            </Grid>
        )

    }

}

const NOTIFICATION_ENUM = {
    simple: 'simple',
    big_text: 'big_text',
    big_image: 'big_image'
};

export class DesignSelector extends Component{

    constructor(props){
        super(props);
        const { payload } = props;
        this.state = { ...payload };
    }

    componentWillMount(){

    }

    handleUpdate = () => {
        this.props.handleUpdate({payload: this.state});
    };


    render(){
        const { inEditMode} = this.props;
        const { notification_type, is_silent = false } = this.state;
        const disabled = !inEditMode && this.props.disabled;
        return(
            <Grid container>
                <Grid item xs={3} md={2}>
                    <ToggleSwitch
                        value={is_silent}
                        checked={is_silent}
                        handleChange={is_silent => this.setState({is_silent}, this.handleUpdate)}
                        label="Silent"
                    />
                </Grid>
                {
                    !is_silent && <Grid item xs>
                        <Switch
                            handleChange={notification_type =>  this.setState({notification_type}, this.handleUpdate)}
                            value={notification_type}
                            data={Object.keys(NOTIFICATION_ENUM).map(o => ({name: keyToTitle(o), value: o}))}
                            disabled={disabled}
                        />
                    </Grid>
                }
                <Grid item xs={12}>
                    <Grid container spacing={16}>
                        <Grid item xs={12} md={4}>
                            <DefaultPayload {...this.props} {...this.state} handleUpdate={defaultPayload => {
                                this.setState(defaultPayload, this.handleUpdate);
                            }}/>
                        </Grid>
                        {
                            !is_silent && <Grid item xs={12} md={8}>
                                <SelectedPayload {...this.props} {...this.state} handleUpdate={selectedPayload => {
                                    this.setState(selectedPayload, this.handleUpdate);
                                }}/>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
        )

    }

}

export class TestingSelector extends Component{

    constructor(props){
        super(props);
        const { appState: { appTestDevices = [] } } = props;
        const { enabled = false, devices = appTestDevices } = props.testing || {};
        this.state = {
            testing: {
                enabled: enabled,
                devices: devices
            }
        }
    }

    componentWillMount(){

    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };


    render(){
        const { inEditMode,appState: { appTestDevices = [] } } = this.props;
        const { testing: { enabled, devices }, testing } = this.state;
        const disabled = !inEditMode && this.props.disabled;
        return(
            <Grid container>
                <Grid item xs={12} md={4}>
                    <ExpansionPanel defaultExpanded={enabled} expanded={enabled}>
                        <ExpansionPanelSummary>
                            <ToggleSwitch
                                handleChange={(enabled) =>  this.setState({testing: {...testing, enabled }}, this.handleUpdate)}
                                label="Enable Testing"
                                checked={enabled}
                                disabled={disabled}
                            />
                        </ExpansionPanelSummary>
                        {
                            enabled &&
                            <ExpansionPanelDetails style={{flexDirection: 'column'}}>
                                <Typography variant="subtitle1">Select Devices</Typography>
                                <CheckboxGroup
                                    options={appTestDevices.map(o => ({label: o.model, value: o.id}))}
                                    value={devices}
                                    row={false}
                                    handleChange={(devices) =>  this.setState({testing: {...testing, devices }}, this.handleUpdate)}
                                    disabled={disabled}
                                />
                            </ExpansionPanelDetails>
                        }
                    </ExpansionPanel>
                </Grid>
            </Grid>
        )

    }

}

const DEFAULT_ICON_CONFIG = {
    icon_path: null,
    large_icon_path: null,
    large_icon_url: null
};

export class DefaultPayload extends Component{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount(){
        this.syncWithProps(this.props);
    }

    componentWillReceiveProps(nextProps){
        if(!nextProps.isNew){
            this.syncWithProps(nextProps);
        }
    }

    syncWithProps = (props) => {
        const {
            notification_title = "", notification_body = "",  activity_name = "",
            deep_link_uri = "", custom_keys = [], channel_id = ""
        } = props;
        this.setState({
            notification_title, notification_body, activity_name,
            deep_link_uri, custom_keys, channel_id
        });
    };

    handleUpdate = () => {
        const { handleUpdate } = this.props;
        if(typeof handleUpdate === 'function'){
            handleUpdate(this.state);
        }
    };

    handleKeysUpdate = (index) => (data) => {
        const { custom_keys } = this.state;
        const updatedKeys = [...custom_keys.slice(0, index), data, ...custom_keys.slice(index + 1)];
        this.setState({custom_keys: updatedKeys}, this.handleUpdate);
    };

    handleKeysDelete = (index) => () => {
        const { custom_keys } = this.state;
        const updatedKeys = [...custom_keys.slice(0, index), ...custom_keys.slice(index + 1)];
        this.setState({custom_keys: updatedKeys}, this.handleUpdate);
    };

    render(){
        const {
            notification_title, notification_body, activity_name,
            deep_link_uri, custom_keys = [], channel_id
        } = this.state;
        const {
            inEditMode, appState: { appScreenNames = [] },
            notification_type, is_silent
        } = this.props;
        const disabled = !inEditMode && this.props.disabled;
        return(
            <Grid container spacing={8}>
                <Grid item xs>
                    {
                        !is_silent && <Fragment>
                            <TextField
                                type="text"
                                required
                                placeholder="Notification Title"
                                value={notification_title}
                                label="Title"
                                onChange={e => this.setState({notification_title: e.target.value}, this.handleUpdate)}
                                fullWidth
                                disabled={disabled}
                                margin="normal"
                            />
                            <TextField
                                type="text"
                                placeholder="Notification Body"
                                value={notification_body || ""}
                                label="Notification Body"
                                onChange={e => this.setState({notification_body: e.target.value}, this.handleUpdate)}
                                fullWidth
                                disabled={disabled}
                                margin="normal"
                                required={notification_type === NOTIFICATION_ENUM.simple}
                                multiline
                            />
                            <MultiSelect
                                options={appScreenNames.map(o => ({label: o, value: o}))}
                                value={activity_name}
                                placeholder="Select Activity"
                                handleChange={activity_name => this.setState({activity_name}, this.handleUpdate)}
                                disabled={disabled}
                                single
                                fullWidth
                            />
                            <TextField
                                type="text"
                                placeholder="scheme://host"
                                value={deep_link_uri || ""}
                                label="Deep Link URI"
                                onChange={e => this.setState({deep_link_uri: e.target.value}, this.handleUpdate)}
                                fullWidth
                                disabled={disabled}
                                margin="normal"
                            />
                        </Fragment>
                    }
                    <TextField
                        type="text"
                        placeholder="channel_cart"
                        value={channel_id}
                        label="Channel (for Android O and above)"
                        onChange={e => this.setState({channel_id: e.target.value}, this.handleUpdate)}
                        fullWidth
                        disabled={disabled}
                        required={true}
                        margin="normal"
                    />
                    <br />
                    <br />
                    {
                        custom_keys.map((keyPair, index) =>
                            <CustomKeysConfig
                                key={"config" + index}
                                handleUpdate={this.handleKeysUpdate(index)}
                                handleDelete={this.handleKeysDelete(index)}
                                dataKey={keyPair.key}
                                value={keyPair.value}
                                type={keyPair.type}
                                disabled={disabled}
                            />
                        )
                    }
                    {
                        !disabled &&
                        <Button color="primary" size="small" disabled={disabled} onClick={e => this.handleKeysUpdate(custom_keys.length)({type: TYPE_ENUM.string, key: "", value: ""})}><AddIcon/> Add Custom Keys</Button>
                    }
                </Grid>
            </Grid>
        )
    }

}

const TYPE_ENUM = {
    int: 'int',
    boolean: 'boolean',
    long: 'long',
    float: 'float',
    double: 'double',
    string: 'string'
};

class CustomKeysConfig extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    componentWillMount(){
        this.syncProps(this.props);
    }

    componentWillReceiveProps(nextProps){
        if(!nextProps.isNew){
            this.syncProps(nextProps);
        }
    }

    syncProps = (props) => {
        const { type = TYPE_ENUM.string, dataKey, value  } = props;
        this.setState({ type, key: dataKey, value});
    };

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const { type = TYPE_ENUM.string, key = "", value } = this.state;
        const { disabled, handleDelete } = this.props;
        return(
            <Grid container spacing={8}>
                <Grid item xs={4}>
                    <TextField
                        type="text"
                        placeholder="Key"
                        value={key}
                        label="Key"
                        onChange={e => this.setState({key: e.target.value}, this.handleUpdate)}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={disabled ? 4 : 3}>
                    <FormSelect
                        options={Object.keys(TYPE_ENUM)}
                        value={type}
                        label="Data Type"
                        handleUpdate={type => this.setState({type}, this.handleUpdate)}
                        disabled={disabled}
                        single
                        fullWidth
                        margin="normal"
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        type="text"
                        placeholder="Value"
                        value={value}
                        label="Value"
                        onChange={e => {
                            this.setState({value: e.target.value}, this.handleUpdate);
                        }}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                    />
                </Grid>
                {
                    !disabled && <Grid item xs={1}>
                        <Tooltip id={`button-delete-${key}`} title="Remove this Key Value Pair" placement="bottom">
                            <IconButton style={{marginTop: 16}} color="secondary" aria-label="Edit" onClick={handleDelete}>
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </Grid>
        )
    }

}

export class SelectedPayload extends Component{

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillMount(){
        this.syncWithProps(this.props);
    }

    syncWithProps = (props) => {
        const {
            long_text = "", big_content_title = "", image_path = "",
            image_url = "", icon_config = null, buttons_config = []
        } = props;
        this.setState({
            long_text, big_content_title, image_path,
            image_url, icon_config, buttons_config
        });
    };

    componentWillReceiveProps(nextProps){
        this.syncWithProps(nextProps);
    }

    handleUpdate = () => {
        const { handleUpdate } = this.props;
        if(typeof handleUpdate === 'function'){
            handleUpdate(this.state);
        }
    };

    handleButtonsUpdate = (index) => (button) => {
        const { buttons_config } = this.state;
        const updatedButtons = [...buttons_config.slice(0, index), button, ...buttons_config.slice(index + 1)];
        this.setState({buttons_config: updatedButtons}, this.handleUpdate);
    };

    handleButtonsDelete = (index) => () => {
        const { buttons_config } = this.state;
        const updatedButtons = [...buttons_config.slice(0, index), ...buttons_config.slice(index + 1)];
        this.setState({buttons_config: updatedButtons}, this.handleUpdate);
    };

    render(){
        const {
            long_text, big_content_title, image_path,
            image_url, icon_config = null, buttons_config = []
        } = this.state;
        const {
            notification_type, inEditMode
        } = this.props;
        const disabled = !inEditMode && this.props.disabled;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12} md>
                    {
                        notification_type === NOTIFICATION_ENUM.big_image &&
                        <TextField
                            type="text"
                            placeholder="../drawable/.."
                            value={image_path || ""}
                            label="Image Path"
                            onChange={e => this.setState({image_path: e.target.value}, this.handleUpdate)}
                            fullWidth
                            disabled={disabled}
                            margin="normal"
                        />
                    }
                    {
                        notification_type === NOTIFICATION_ENUM.big_image &&
                        <TextField
                            type="text"
                            placeholder="https://cdn.com/image.webm"
                            value={image_url || ""}
                            label="Image URL"
                            onChange={e => this.setState({image_url: e.target.value}, this.handleUpdate)}
                            fullWidth
                            disabled={disabled}
                            margin="normal"
                        />
                    }
                    {
                        notification_type === NOTIFICATION_ENUM.big_image &&
                        <TextField
                            type="text"
                            placeholder="Big Content Title"
                            value={big_content_title}
                            label="Big Content Title"
                            onChange={e => this.setState({big_content_title: e.target.value}, this.handleUpdate)}
                            fullWidth
                            disabled={disabled}
                            margin="normal"
                        />
                    }
                    {
                        notification_type === NOTIFICATION_ENUM.big_text &&
                        <TextField
                            type="text"
                            required
                            placeholder="Long Text"
                            value={long_text}
                            label="Long Text"
                            onChange={e => this.setState({long_text: e.target.value}, this.handleUpdate)}
                            fullWidth
                            disabled={disabled}
                            margin="normal"
                            multiline
                        />
                    }
                    {notification_type !== NOTIFICATION_ENUM.simple && <br />}
                    <ToggleSwitch
                        handleChange={
                            (hasIconOrImage) =>  this.setState({icon_config: hasIconOrImage ? DEFAULT_ICON_CONFIG : null}, this.handleUpdate)}
                        label="Icon or Image"
                        checked={!!icon_config}
                        disabled={disabled}
                    />
                    {
                        icon_config && <IconConfig {...this.props} {...icon_config} handleUpdate={icon_config => this.setState({icon_config}, this.handleUpdate)}/>
                    }
                    {
                        buttons_config.map((button, index) =>
                            <ButtonConfig
                                key={index}
                                {...this.props}
                                handleUpdate={this.handleButtonsUpdate(index)}
                                handleDelete={this.handleButtonsDelete(index)}
                                {...button}
                            />
                        )
                    }
                    {
                        !disabled && buttons_config.length < 3 &&
                        <Button color="primary" size="small" disabled={disabled} onClick={e => this.handleButtonsUpdate(buttons_config.length)({name: "", activity_name: ""})}><AddIcon/> Add Button</Button>
                    }
                </Grid>
                <Grid item xs={12} md>
                    <MobileSimulator>
                        { notification_type === NOTIFICATION_ENUM.simple && <Simple {...this.props}/> }
                        { notification_type === NOTIFICATION_ENUM.big_text && <BigText {...this.props}/> }
                        { notification_type === NOTIFICATION_ENUM.big_image && <BigImage {...this.props}/> }
                    </MobileSimulator>
                </Grid>
            </Grid>
        )
    }

}


class ButtonConfig extends Component {

    constructor(props){
        super(props);
        this.state = {};
    }

    componentWillMount(){
        this.syncWithProps(this.props);
    }

    componentWillReceiveProps(nextProps){
        if(!nextProps.isNew){
            this.syncWithProps(nextProps);
        }
    }

    syncWithProps = (props) => {
        const { name = "", activity_name = "" } = props;
        this.setState({ name, activity_name });
    };

    handleUpdate = () => {
        this.props.handleUpdate({...this.state});
    };

    render(){
        const { disabled = false, appState: { appScreenNames = [] }, handleDelete } = this.props;
        const { name, activity_name } = this.state;
        return(
            <Grid container spacing={16}>
                <Grid item xs>
                    <TextField
                        type="text"
                        required
                        placeholder="Button Label"
                        value={name}
                        label="Button Label"
                        onChange={e => this.setState({name: e.target.value}, this.handleUpdate)}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                    />
                    <MultiSelect
                        options={appScreenNames.map(o => ({label: o, value: o}))}
                        value={activity_name}
                        placeholder="Select Activity"
                        handleChange={
                            activity_name => this.setState({activity_name}, this.handleUpdate)
                        }
                        disabled={disabled}
                        single
                        fullWidth
                    />
                    <br />
                </Grid>
                {
                    !disabled && <Grid item xs={1}>
                        <Tooltip id={`button-delete-${name}`} title="Remove this button" placement="bottom">
                            <IconButton disabled={disabled} style={{marginTop: 60}} color="secondary" aria-label="Edit" onClick={handleDelete}>
                                <RemoveIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                }
            </Grid>
        );
    }

}

class IconConfig extends Component {

    constructor(props){
        super(props);
        const { icon_path = null, large_icon_path = null, large_icon_url = null } = props;
        this.state = { icon_path, large_icon_path, large_icon_url };
    }

    componentWillMount(){
        //
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const { disabled = false } = this.props;
        const { icon_path, large_icon_path, large_icon_url } = this.state;
        return(
            <Grid container>
                <Grid item xs>
                    <TextField
                        type="text"
                        placeholder="../drawable/.."
                        value={icon_path || ""}
                        label="Icon Path"
                        onChange={e => this.setState({icon_path: e.target.value}, this.handleUpdate)}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                    />
                    <TextField
                        type="text"
                        placeholder="../drawable/.."
                        value={large_icon_path || ""}
                        label="Large Icon Path"
                        onChange={e => this.setState({large_icon_path: e.target.value}, this.handleUpdate)}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                    />
                    <TextField
                        type="text"
                        placeholder="https://cdn.com/image.webm"
                        value={large_icon_url || ""}
                        label="Large Icon URL"
                        onChange={e => this.setState({large_icon_url: e.target.value}, this.handleUpdate)}
                        fullWidth
                        disabled={disabled}
                        margin="normal"
                    />
                    <br />
                </Grid>
            </Grid>
        );
    }

}

export class MetaSelector extends Component{

    constructor(props){
        super(props);
        const { name = "", description = "" } = props;
        this.state = { name, description };
    }

    componentWillMount(){
        //this.handleUpdate();
    }

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const { inEditMode } = this.props;
        const { name, description } = this.state;
        const disabled = !inEditMode && this.props.disabled;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12} md={4}>
                    <TextField
                        type="text"
                        required
                        placeholder=""
                        value={name}
                        label="Name"
                        onChange={e => this.setState({name: e.target.value}, this.handleUpdate)}
                        margin="normal"
                        fullWidth
                        disabled={disabled}
                    />
                    <TextField
                        type="text"
                        placeholder="Description (Optional)"
                        value={description}
                        label="Description"
                        onChange={e => this.setState({description: e.target.value}, this.handleUpdate)}
                        margin="normal"
                        fullWidth
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        )

    }

}

const INITIAL_NOTIFICATION = {
    name: "",
    group: ALL_USERS,
    segment_id: null,
    properties: {user: [], session: []},
    versions: [],
    start_time: moment().subtract(14, "d").toJSON(),
    end_time: moment().toJSON(),
    payload: {
        notification_type: NOTIFICATION_ENUM.simple,
        notification_title: "Title",
        activity_name: "",
        deep_link_uri: "",
        custom_keys: [],
        buttons_config: [],
        notification_body: "Body..",
        long_text: "Long Text...",
        image_path: "",
        image_url: "https://..",
        is_silent: false,
        priority: "0",
        ttl: moment().add(1, 'day').valueOf()
    }
};

export class NotificationCreator extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            ...INITIAL_NOTIFICATION
        };
    }

    isReadyToSave = () => {
        const {
            name,
            group,
            payload: {
                notification_type,
                notification_title,
                activity_name,
                deep_link_uri,
                buttons_config,
                notification_body,
                long_text,
                image_path,
                image_url,
                ttl,
                channel_id,
                is_silent
            } = {}
        } = this.state; //FIXME: Need proper validator
        const nameGiven = name && name.trim().length > 0;
        const hasMinimumKeys = Object.keys(this.state).length > 2; //FIXME: May change in future
        const hasGroup = group && group.length > 0; //TODO: Check for valid Groups
        if(is_silent){
            return nameGiven && hasMinimumKeys;
        }
        const hasBasicAction = (activity_name && activity_name.length > 0) || (deep_link_uri && deep_link_uri.length > 0);
        const hasTTL = ttl && !isNaN(ttl);
        let payloadHasBasicData = notification_title && notification_title.trim().length > 0 &&
            hasTTL && hasBasicAction && Array.isArray(buttons_config);
        if(notification_type === NOTIFICATION_ENUM.simple){
            payloadHasBasicData = payloadHasBasicData && notification_body && notification_body.trim().length > 0;
        }
        if(notification_type === NOTIFICATION_ENUM.big_text){
            payloadHasBasicData = payloadHasBasicData && long_text && long_text.trim().length > 0;
        }
        if(notification_type === NOTIFICATION_ENUM.big_image){
            payloadHasBasicData = payloadHasBasicData && ((image_path && image_path.trim().length > 0) || (image_url && image_url.trim().length > 0));
        }
        return nameGiven && hasMinimumKeys && hasGroup && payloadHasBasicData && Boolean(channel_id);
    };

    getValidNotificationData = (notification = this.state) => {
        const {
            name,
            description,
            group,
            segment_id,
            versions,
            start_time, end_time,
            payload: {
                notification_type,
                notification_title,
                activity_name,
                buttons_config = [],
                icon_config = null,
                notification_body,
                long_text,
                image_path,
                image_url,
                big_content_title,
                deep_link_uri,
                custom_keys = [],
                is_silent,
                priority,
                ttl,
                channel_id
            }
        } = notification;
        let obj = { name, description, group, segment_id, versions, start_time, end_time };
        let payload = {
            notification_title, activity_name, buttons_config,
            icon_config , notification_body, notification_type,
            is_silent, ttl, custom_keys, deep_link_uri, channel_id,
            priority: Number(priority)
        };
        if(notification_type === NOTIFICATION_ENUM.big_text){
            payload = {...payload, long_text};
        }
        if(notification_type === NOTIFICATION_ENUM.big_image){
            payload = { ...payload, image_path, image_url, big_content_title };
        }
        return {
            ...obj,
            payload
        };
    };

    handleSave = () => {
        const { saveNotification, params: { appId } } = this.props;
        saveNotification(appId, this.getValidNotificationData(this.state));
        this.setState({
            ...INITIAL_NOTIFICATION
        });
    };

    handleUpdate = (body) => {
        this.setState(body);
    };

    render(){
        const {
            expanded, handleChange,
        } = this.props;
        return(
            <Grid container>
                <Grid item xs>
                    <ExpansionPanel expanded={expanded} onChange={handleChange}>
                        {
                            expanded && <ExpansionPanelDetails>
                                <NotificationHolder {...this.props} notification={this.state} handleUpdate={this.handleUpdate} isNew />
                            </ExpansionPanelDetails>
                        }
                        <Divider />
                        <ExpansionPanelActions>
                            <Button size="small" onClick={handleChange}>Discard</Button>
                            <Button size="small" variant="contained" color="primary" disabled={!this.isReadyToSave()} onClick={this.handleSave}>Save</Button>
                        </ExpansionPanelActions>
                    </ExpansionPanel>
                </Grid>
            </Grid>
        )

    }

}

export class NotificationsHolder extends Component{

    NEW_NOTIFICATION_KEY = "new-notification";

    constructor(props){
        super(props);
        this.state = {
            notifications: [],
            expanded: null,
        };
    }

    componentWillMount(){

    }

    componentDidMount(){
        //
    }

    componentWillReceiveProps(nextProps){

    }

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleEdit = (notificationId, body) => {
        const { updateNotification, params: { appId } } = this.props;
        updateNotification(appId, notificationId, body);
    };

    render(){
        const { expanded } = this.state;
        const {
            push: {
                notifications = [],
                save_notification_failed, save_notification_success,
                notification_sent, send_notification_failed
            },
        } = this.props;
        return(
            <Grid container>
                <Grid item xs>
                    {save_notification_success && <Snackbar>Notification saved successfully.</Snackbar>}
                    {save_notification_failed && <Snackbar>Notification saving failed, try again.</Snackbar>}
                    { notification_sent && <Snackbar> Notification will be sent.</Snackbar> }
                    { send_notification_failed && <Snackbar>Failed sending notification, try again.</Snackbar> }
                    <Grid container justify="flex-end">
                        <Grid item xs={3}>
                            {
                                expanded !== this.NEW_NOTIFICATION_KEY &&
                                <Button onClick={e => this.handleChange(this.NEW_NOTIFICATION_KEY)(e, true)}
                                        variant="contained"
                                        color="primary"
                                        style={{margin: '20px 0 20px auto', display: 'block'}}
                                >Create New Notification</Button>
                            }
                        </Grid>
                    </Grid>
                    {
                        expanded === this.NEW_NOTIFICATION_KEY &&
                        <NotificationCreator
                            {...this.props}
                            expanded={expanded === this.NEW_NOTIFICATION_KEY}
                            handleChange={(e, isExpanded) => {
                                if(expanded !== this.NEW_NOTIFICATION_KEY){
                                    this.handleChange(this.NEW_NOTIFICATION_KEY)(e, true);
                                }else{
                                    this.handleChange(this.NEW_NOTIFICATION_KEY)(e, false);
                                }
                            }}
                        />
                    }
                    {
                        notifications.map(notification =>
                            <NotificationHolderWithControls
                                key={notification._id}
                                {...this.props}
                                {...notification}
                                notification={notification}
                                disabled
                                handleChange={this.handleChange(notification._id)}
                                expanded={expanded}
                            />
                        )
                    }
                    {
                        !this.props.meta.api_pending && (!Array.isArray(notifications) || notifications.length === 0) &&
                        <Placeholder
                            withIcon
                            icon={<NotificationIcon style={{opacity: 0.2, width: 150, height: 150}} />}
                            text="To create your first notification, click on Create New Notification button."
                        />
                    }
                </Grid>
            </Grid>
        )

    }

}

const NOTIFICATION_CONFIGURATIONS = {
    GENERAL: "General",
    AUDIENCE: "Audience",
    CONFIG: "Configuration",
    PAYLOAD: "Design & Data",
    //Testing: "Testing"
};

const PRIORITY_ENUM = {
    MIN: -2,
    LOW: -1,
    DEFAULT: "0", //FIXME: 0 may not be a value
    HIGH: 1,
    MAX: 2
};

export class ConfigSelector extends Component{

    constructor(props){
        super(props);
        this.state = {};
    }

    componentWillMount(){
        this.syncWithProps(this.props);
    }

    syncWithProps = (props) => {
        const { ttl = moment().add(1, 'day').valueOf(), priority = "0" } = props;
        this.setState({
            ttl,
            priority
        });
    };

    componentWillReceiveProps(nextProps){
        this.syncWithProps(nextProps);
    }

    handleDateChange = (dateTime) => {
        this.setState({ttl: +dateTime}, this.handleUpdate); //moment to millies
    };

    handleUpdate = () => {
        this.props.handleUpdate(this.state);
    };

    render(){
        const { ttl, priority } = this.state;
        const { disabled } = this.props;
        return(
            <Grid container>
                <Grid item xs={6} >
                    <Typography>Time To Live (TTL)</Typography>
                    <DateTimePicker
                        value={moment(ttl)}
                        handleChange={this.handleDateChange}
                        disablePast
                        disabled={disabled}
                    />
                    <br />
                    <br />
                    <Typography>Priority</Typography>
                    <Switch
                        data={Object.keys(PRIORITY_ENUM).map(o => ({name: o, value: PRIORITY_ENUM[o]}))}
                        handleChange={priority => this.setState({priority}, this.handleUpdate)}
                        value={priority === 0 ? priority.toString() : priority}
                        disabled={disabled}
                    />
                </Grid>
            </Grid>
        )

    }

}

export class NotificationHolder extends Component{

    _id = Math.random();

    _steps = Object.keys(NOTIFICATION_CONFIGURATIONS);

    constructor(props){
        super(props);
        this.completed = new Set();
        this.state = {
            ...props.notification,
            activeStep: 0
        }
    }


    componentWillMount(){

    }

    handleUpdate = (state) => {
        this.setState(state, () => {
            const {...notification} = this.state;
            this.props.handleUpdate(notification);
        });
    };

    handleConfigUpdate = (state) => {
        this.setState({payload: {...this.state.payload, ...state }}, () => {
            const { ...notification } = this.state;
            this.props.handleUpdate(notification);
        });
    };

    handleChange = panel => (event) => {
        this.setState({
            expanded: panel,
            activeStep: this._steps.indexOf(panel)
        });
    };

    handleNext = () => {
        this.completed.add(this.state.activeStep);
        this.setState({
            expanded: this._steps[this.state.activeStep + 1],
            activeStep: this.state.activeStep + 1
        });
    };

    handleBack = () => {
        this.setState({
            expanded: this._steps[this.state.activeStep - 1],
            activeStep: this.state.activeStep - 1
        });
    };

    handleStep = (index) => () => {
        this.setState({
            expanded: this._steps[index],
            activeStep: index
        });
    };

    render(){
        const {  activeStep } = this.state;
        const {
            inEditMode
        } = this.props;
        const disabled = !inEditMode && this.props.disabled;
        return(
            <div style={{width: '100%'}}>
                <Stepper nonLinear activeStep={activeStep}>
                    {
                        Object.values(NOTIFICATION_CONFIGURATIONS).map((label, index) =>
                            <Step key={label}>
                                <StepButton
                                    completed={disabled}
                                    onClick={this.handleStep(index)}
                                >{label}</StepButton>
                            </Step>
                        )
                    }
                </Stepper>
                <div style={{padding: 30}}>
                    {activeStep === 0 && <MetaSelector {...this.props} {...this.state} handleUpdate={this.handleUpdate}/>}
                    {activeStep === 1 && <AudienceSelector {...this.props} {...this.state} handleUpdate={this.handleUpdate}/>}
                    {activeStep === 2 && <ConfigSelector {...this.props} {...this.state.payload} handleUpdate={this.handleConfigUpdate}/>}
                    {activeStep === 3 && <DesignSelector {...this.props} {...this.state} handleUpdate={this.handleUpdate}/>}
                    {/*{activeStep === 4 && <TestingSelector {...this.props} {...this.state} handleUpdate={this.handleUpdate}/>}*/}
                </div>
                <div style={{ display: 'flex', marginTop: 20}}>
                    <Button disabled={activeStep === 0} onClick={this.handleBack}>Back</Button>
                    <Button disabled={activeStep === 3} variant="contained" color="primary" onClick={this.handleNext}>Next</Button>
                </div>
            </div>
        )

    }

}

export class NotificationHolderWithControls extends Component{


    _steps = Object.keys(NOTIFICATION_CONFIGURATIONS);

    constructor(props){
        super(props);
        this.state = {
            inEditMode: false,
            ...props.notification
        }
    }

    componentWillReceiveProps(nextProps){
        const { expanded, _id } = nextProps;
        if(expanded !== _id){
            this.setState({inEditMode: false}); //remove from editMode when closed
        }
    }

    handleUpdate = (state) => {
        this.setState(state, () => {
            const { inEditMode, ...notification } = this.state;
            const { handleUpdate = () => null } = this.props;
            handleUpdate(notification);
        });
    };

    handleChange = (event, expanded) => {
        this.props.handleChange(event, expanded);
    };

    render(){
        const {inEditMode } = this.state;
        const {
            expanded,
            deleteNotification, params: { appId },
            push: {
                delete_notification_failed
            },
             _id, name, payload: { notification_type } = {}, results: { sent, received, app_opens } = { sent: "NA", received: "NA", app_opens: "NA"},
            sendNotification
        } = this.props;
        return(
            <div style={{width: '100%'}}>
                <ExpansionPanel expanded={expanded === _id} onChange={this.handleChange}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container>
                            <Grid item xs>
                                <Typography style={{marginTop: 15}} variant="subtitle1">{name}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{marginTop: 10}} variant="caption" title="Sent">{sent} <DoneIcon style={{width: '0.6em'}} nativeColor={randomColorWithIndex(9)}/></Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{marginTop: 10}} variant="caption" title="Received">{received} <DoneAllIcon style={{width: '0.6em'}} nativeColor={randomColorWithIndex(4)}/></Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography style={{marginTop: 10}} variant="caption" title="App Opens">{app_opens} <OpenInBrowserIcon style={{width: '0.6em'}} nativeColor={randomColorWithIndex(0)}/></Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography style={{marginTop: 15}} variant="caption">{notification_type}</Typography>
                            </Grid>
                            <Grid item xs={1} onClick={(e) => e.stopPropagation()}>
                                <DeleteAction
                                    deleteAction={() => {
                                        deleteNotification(appId, _id);
                                    }}
                                    deleteFailed={delete_notification_failed}
                                />
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    {
                        expanded === _id && <ExpansionPanelDetails>
                            <NotificationHolder {...this.props} handleUpdate={this.handleUpdate} inEditMode={inEditMode} isNew={false}/>
                        </ExpansionPanelDetails>
                    }
                    <Divider/>
                    <ExpansionPanelActions>
                        <Button size="small" variant="contained" color="primary" onClick={() => {
                            sendNotification(appId, _id);
                        }}>Send</Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </div>
        )

    }

}

const aggregateResults = (notifications = []) => {
    return notifications.reduce((a, b) => {
        const { sent = 0, received = 0, app_opens = 0 } = b.results || {};
        a.sent += sent;
        a.received += received;
        a.app_opens += app_opens;
        return a;
    }, {sent: 0, received: 0, app_opens: 0});
};

class Summary extends Component {

    renderComponent = (label, value, icon, color) => {
        return(
            <Grid item xs style={{textAlign: 'center'}}>
                {React.cloneElement(icon, {style: {width: '4em', height: '4em'}, nativeColor: color})}
                <Typography variant="caption" title={label}>{label}</Typography>
                <Typography variant="h5" title={value}>{formatNumber(value)}</Typography>
            </Grid>
        )
    };

    render(){
        const { notifications = [] } = this.props;
        const { sent = "NA", received = "NA", app_opens = "NA" } = aggregateResults(notifications) || {};
        return(
            <Grid container justify="center">
                {this.renderComponent("Sent", sent, <DoneIcon />, randomColorWithIndex(9))}
                {this.renderComponent("Received", received, <DoneAllIcon />, randomColorWithIndex(4))}
                {this.renderComponent("App Opens", app_opens, <OpenInBrowserIcon />, randomColorWithIndex(0))}
            </Grid>
        );
    }

}

export class NotificationsWrapper extends Component {
    constructor(props){
        super(props);
        this.state = {
            value: 0,
        };
    }

    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { push: { notifications = [] } } = this.props;
        return (
            <div>
                {/*<ButtonConfig {...this.props}/>*/}
                <Summary notifications={notifications}/>
                {/*<AppBar position="static">
                    <Tabs value={value} onChange={this.handleChange}>
                        <Tab label="Notifications" href="#notifications" />
                    </Tabs>
                </AppBar>
                {value === 0 &&
                    <NotificationsHolder {...this.props}/>
                }*/}
                <NotificationsHolder {...this.props}/>
            </div>
        );
    }
}