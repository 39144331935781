/**
 * Created by Rakesh Peela
 * Date: 19-Dec-2019
 * Time: 11:32 PM
 */
import {APP_PLATFORMS} from "../../../../../../../../../../constants";
import GetDefaultCoachmarkJSON from "./Coachmark";
import {
    INAPP_LAYOUT_TYPES,
    MESSAGE_COACHMARK_LAYOUT_TYPES,
    MESSAGE_NUDGE_LAYOUT_TYPES,
    MESSAGE_TOOLTIP_LAYOUT_TYPES
} from "./common";
import GetDefaultInAppJSON from "./InApp";
import GenNudgeJSON from "./Nudge";
import GenTooltipJSON from "./Tooltip";

export const ACTION_CLASSES = {
    TOOLTIP: "tooltip",
    NUDGE: "nudge",
    COACHMARK: "coachmark",
    INAPP: "inapp"
};

// Move tooltip with coachmark to Spotlights!

export const ACTION_OPTIONS = () => (
    {
        [ACTION_CLASSES.TOOLTIP]: [
            {
                type: MESSAGE_TOOLTIP_LAYOUT_TYPES.DEFAULT,
                available: true,
                display_text: "Basic Text",
                data: {
                    ...GenTooltipJSON(MESSAGE_TOOLTIP_LAYOUT_TYPES.DEFAULT)
                },
                requisites: [
                    {
                        platform: APP_PLATFORMS.android,
                        minSDK: 258
                    },
                    {
                        platform: APP_PLATFORMS.ios,
                        minSDK: 258
                    }
                ]
            },
            {
                type: MESSAGE_TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS,
                available: true,
                display_text: "Short Menu",
                data: {
                    ...GenTooltipJSON(MESSAGE_TOOLTIP_LAYOUT_TYPES.ACTION_BUTTONS)
                },
                requisites: [
                    {
                        platform: APP_PLATFORMS.android,
                        minSDK: 258
                    }
                ]
            },
            {
                type: MESSAGE_TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS,
                available: true,
                display_text: "Basic Text with Image",
                data: {
                    ...GenTooltipJSON(MESSAGE_TOOLTIP_LAYOUT_TYPES.IMAGE_TEXT_BUTTONS)
                },
                requisites: [
                    {
                        platform: APP_PLATFORMS.android,
                        minSDK: 258
                    },
                    {
                        platform: APP_PLATFORMS.ios,
                        minSDK: 258
                    }
                ]
            },
            // {
            //     type: MESSAGE_TOOLTIP_LAYOUT_TYPES.TOOLTIP_WITH_COACHMARK,
            //     available: true,
            //     display_text: "Tooltip with Spotlight",
            //     data: {
            //         ...GenTooltipJSON(MESSAGE_TOOLTIP_LAYOUT_TYPES.TOOLTIP_WITH_COACHMARK)
            //     },
            //     requisites: [
            //         {
            //             platform: APP_PLATFORMS.android,
            //             minSDK: 258
            //         },
            //         {
            //             platform: APP_PLATFORMS.ios,
            //             minSDK: 258
            //         }
            //     ]
            // },
            // {
            //     type: MESSAGE_TOOLTIP_LAYOUT_TYPES.INLINE_WITH_DOT,
            //     available: false,
            //     data: {
            //         ...GenTooltipJSON(MESSAGE_TOOLTIP_LAYOUT_TYPES.INLINE_WITH_DOT)
            //     },
            //     requisites: [{
            //         platform: APP_PLATFORMS.android,
            //         minSDK: 258
            //     }]
            // },
            // {
            //     type: MESSAGE_TOOLTIP_LAYOUT_TYPES.TEXT_WITH_FIXED_BUTTONS,
            //     available: false,
            //     data: {},
            //     requisites: [{
            //         platform: APP_PLATFORMS.android,
            //         minSDK: 258
            //     }]
            // }
        ],
        [ACTION_CLASSES.COACHMARK]: [
            {
                type: MESSAGE_COACHMARK_LAYOUT_TYPES.DEFAULT,
                available: true,
                display_text: "Informative Spotlight",
                data: {
                    ...GetDefaultCoachmarkJSON(MESSAGE_COACHMARK_LAYOUT_TYPES.DEFAULT)
                },
                requisites: [
                    {
                        platform: APP_PLATFORMS.android,
                        minSDK: 258
                    },
                    {
                        platform: APP_PLATFORMS.ios,
                        minSDK: 258
                    }
                ]
            },
        ],
        [ACTION_CLASSES.INAPP]: [
            {
                type: INAPP_LAYOUT_TYPES.HEADER,
                available: true,
                display_text: "Header",
                data: {
                    ...GetDefaultInAppJSON(INAPP_LAYOUT_TYPES.HEADER)
                },
                requisites: [
                    {
                        platform: APP_PLATFORMS.android,
                        minSDK: 258
                    },
                    {
                        platform: APP_PLATFORMS.ios,
                        minSDK: 258
                    }
                ]
            },
            {
                type: INAPP_LAYOUT_TYPES.FOOTER,
                available: true,
                display_text: "Footer",
                data: {
                    ...GetDefaultInAppJSON(INAPP_LAYOUT_TYPES.FOOTER)
                },
                requisites: [
                    {
                        platform: APP_PLATFORMS.android,
                        minSDK: 258
                    },
                    {
                        platform: APP_PLATFORMS.ios,
                        minSDK: 258
                    }
                ]
            },
            {
                type: INAPP_LAYOUT_TYPES.POPOUT_MODAL,
                available: true,
                display_text: "Modal Popup",
                data: {
                    ...GetDefaultInAppJSON(INAPP_LAYOUT_TYPES.POPOUT_MODAL)
                },
                requisites: [
                    {
                        platform: APP_PLATFORMS.android,
                        minSDK: 258
                    },
                    {
                        platform: APP_PLATFORMS.ios,
                        minSDK: 258
                    }
                ]
            },
            {
                type: INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL,
                available: true,
                display_text: "Fullscreen Modal",
                data: {
                    ...GetDefaultInAppJSON(INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL)
                },
                requisites: [
                    {
                        platform: APP_PLATFORMS.android,
                        minSDK: 258
                    },
                    {
                        platform: APP_PLATFORMS.ios,
                        minSDK: 258
                    }
                ]
            },
            {
                type: INAPP_LAYOUT_TYPES.CUSTOM_HTML,
                available: true,
                display_text: "Custom",
                data: {
                    ...GetDefaultInAppJSON(INAPP_LAYOUT_TYPES.CUSTOM_HTML)
                },
                requisites: [
                    {
                        platform: APP_PLATFORMS.android,
                        minSDK: 258
                    },
                    {
                        platform: APP_PLATFORMS.ios,
                        minSDK: 258
                    }
                ]
            },
        ],
        [ACTION_CLASSES.NUDGE]: [
            {
                type: MESSAGE_NUDGE_LAYOUT_TYPES.DEFAULT,
                available: true,
                display_text: "Basic Notification",
                data: {
                    ...GenNudgeJSON()
                },
                requisites: [
                    {
                        platform: APP_PLATFORMS.android,
                        minSDK: 258
                    },
                    {
                        platform: APP_PLATFORMS.ios,
                        minSDK: 258
                    }
                ]
            }
        ]
    }
);
