/**
 * Created by Uma Sankar Yedida
 * Date: 04-Jan-2020
 * Time: 3:13 PM
 */

import React from "react";
import withStyles from "@material-ui/core/es/styles/withStyles";
import {INAPP_LAYOUT_TYPES} from "../../models/common";
import {Grid} from "@material-ui/core";
import {ButtonsHolder, ImageHolder, TextHolder, TooltipButton} from "./utils";
import {MODULE_TYPES} from "../modules/moduleTypes";
import {replaceMacrosWithDefault} from "../../../../../../../../../../../utils";

const styles = () => ({});

const isCustomHTML = (layout_type) => layout_type === INAPP_LAYOUT_TYPES.CUSTOM_HTML;

const isHeader = (layout_type) => layout_type === INAPP_LAYOUT_TYPES.HEADER;

const isFooter = (layout_type) => layout_type === INAPP_LAYOUT_TYPES.FOOTER;

const isPopoutModal = (layout_type) => layout_type === INAPP_LAYOUT_TYPES.POPOUT_MODAL;

const isFullScreenModal = (layout_type) => layout_type === INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL;

class InAppSimulator extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            containerConfigs,
            config: {
                image, text_config, title_config, buttons_config,
                layout_type, bg_color, only_image, bg_image, border_radius,
                image_size, window_attributes: {dimmed_background},
                html_config: {height, data}, orientation,
            },
            config,
            handleModuleSelection,
            selectedModule,
        } = this.props;

        let defaultSimulatorWidth = orientation === "Portrait" ? 270 : 585;
        let defaultSimulatorHeight = orientation === "Portrait" ? 585 : 270;

        return (<div style={{width: '100%', height: '100%'}}>
            { /* Header and Footer logic goes here */ }
            {
                (isHeader(layout_type) || isFooter(layout_type))
                && <div style={{
                    overflow: "hidden",
                    width: defaultSimulatorWidth,
                    height: defaultSimulatorHeight,
                    borderRadius: 16,
                    backgroundColor: dimmed_background ? 'rgb(51, 51, 51, 0.8)' : 'rgb(255, 255, 255, 1)',
                    justifyContent: isFooter(layout_type) ? 'flex-end' : 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundImage: 'linear-gradient(45deg, rgb(128, 128, 128) 25%, transparent 25%), linear-gradient(-45deg, rgb(128, 128, 128) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgb(128, 128, 128) 75%), linear-gradient(-45deg, transparent 75%, rgb(128, 128, 128) 75%)',
                    backgroundSize: '20px 20px',
                    backgroundPosition: '0px 0px, 0px 10px, 10px -10px, -10px 0px',
                }}>
                    <div id="InApp" style={{
                        width: '100%', //height: height + "%",
                        backgroundColor: !only_image ? (bg_color || '#FFF') : '',
                        backgroundSize: image_size === 'Stretch' ? '100% 100%' : image_size.toLowerCase(),
                        backgroundImage: 'url(' + bg_image + ')', backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        borderBottomRightRadius: isHeader(layout_type) ? border_radius : 0,
                        borderBottomLeftRadius: isHeader(layout_type) ? border_radius : 0,
                        borderTopRightRadius: isFooter(layout_type) ? border_radius : 0,
                        borderTopLeftRadius: isFooter(layout_type) ? border_radius : 0,
                    }}>
                        { /* Icon, Title and Description will be shown only if `only_image` flag is FALSE */ }
                        {
                            !only_image
                            && <Grid container spacing={8} alignItems={"stretch"} justify={"center"}>
                                { /* Icon on the left side */ }
                                {
                                    (image || image === '')
                                    && <Grid item style={{marginLeft: '10px', marginTop: '10px'}}>
                                        <ImageHolder
                                            handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.IMAGE_MODULE, image)}
                                            selected={selectedModule === MODULE_TYPES.IMAGE_MODULE}
                                            config={image}
                                            type={"URL"}
                                        />
                                    </Grid>
                                }
                                <Grid item xs sm md style={{alignSelf: 'center'}}>
                                    { /* Title */ }
                                    {
                                        title_config
                                        && <TextHolder
                                            handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.TITLE_MODULE, title_config)}
                                            selected={selectedModule === MODULE_TYPES.TITLE_MODULE}
                                            bgColor={containerConfigs.bg_color}
                                            config={title_config}
                                            style={{paddingTop: '5px'}}/>
                                    }
                                    { /* Description */ }
                                    {
                                        text_config
                                        && <TextHolder
                                            handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.DESCRIPTION_MODULE, text_config)}
                                            selected={selectedModule === MODULE_TYPES.DESCRIPTION_MODULE}
                                            bgColor={containerConfigs.bg_color}
                                            config={text_config}
                                            style={{paddingTop: '5px'}}/>
                                    }
                                </Grid>
                            </Grid>
                        }
                        { /* Buttons will be shown only if `only_image` flag is FALSE */ }
                        {
                            (layout_type === INAPP_LAYOUT_TYPES.HEADER || layout_type === INAPP_LAYOUT_TYPES.FOOTER)
                            && !only_image
                            && buttons_config
                            && buttons_config.length > 0
                            && <div style={{marginTop: '8px', marginBottom: '8px'}}>
                                <ButtonsHolder
                                    style={{textAlign: 'center', width: '100%', padding: '6px'}}
                                    handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.BUTTONS_MODULE, buttons_config)}
                                    selected={selectedModule === MODULE_TYPES.BUTTONS_MODULE}
                                    bgColor={containerConfigs.bg_color}
                                    config={buttons_config}/>
                            </div>
                        }
                    </div>
                </div>
            }
            { /* Custom HTML template logic goes here */ }
            {
                isCustomHTML(layout_type)
                && <div style={{
                    overflow: "hidden",
                    width: defaultSimulatorWidth,
                    height: defaultSimulatorHeight,
                    borderRadius: 16,
                    backgroundColor: dimmed_background ? 'rgb(51, 51, 51, 0.8)' : 'rgb(255, 255, 255, 1)',
                    justifyContent: isFooter(layout_type) ? 'flex-end' : 'flex-start',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundImage: 'linear-gradient(45deg, rgb(128, 128, 128) 25%, transparent 25%), linear-gradient(-45deg, rgb(128, 128, 128) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgb(128, 128, 128) 75%), linear-gradient(-45deg, transparent 75%, rgb(128, 128, 128) 75%)',
                    backgroundSize: '20px 20px',
                    backgroundPosition: '0px 0px, 0px 10px, 10px -10px, -10px 0px',
                }}>
                    <div style={{width: '100%', height: '585px'}} dangerouslySetInnerHTML={{
                        __html: replaceMacrosWithDefault(data)
                    }}/>
                </div>
            }
            { /* Popout Modal logic goes here */ }
            {
                isPopoutModal(layout_type)
                && <div style={{
                    overflow: "hidden",
                    width: defaultSimulatorWidth,
                    height: defaultSimulatorHeight,
                    borderRadius: 16,
                    backgroundColor: dimmed_background ? 'rgb(51, 51, 51, 0.8)' : 'rgb(255, 255, 255, 1)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundImage: 'linear-gradient(45deg, rgb(128, 128, 128) 25%, transparent 25%), linear-gradient(-45deg, rgb(128, 128, 128) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, rgb(128, 128, 128) 75%), linear-gradient(-45deg, transparent 75%, rgb(128, 128, 128) 75%)',
                    backgroundSize: '20px 20px',
                    backgroundPosition: '0px 0px, 0px 10px, 10px -10px, -10px 0px',
                    position: 'relative'
                }}>
                    <div id="InApp" style={{
                        width: '85%', maxHeight: '99%',
                        minHeight: !only_image ? '' : '50%',
                        display: 'flex',
                        flexDirection: 'column',
                        backgroundColor: !only_image ? (bg_color || '#FFF') : '',
                        backgroundSize: image_size === 'Stretch' ? '100% 100%' : image_size.toLowerCase(),
                        backgroundImage: 'url(' + bg_image + ')', backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        borderRadius: 8
                    }}>
                        <a href={"apxor://xclose"}
                           style={{
                               margin: "-8px 12px",
                               right: orientation === "Portrait" ? 0 : 24,
                               fontSize: 16,
                               lineHeight: "12px",
                               position: "absolute",
                               width: 18,
                               height: 18,
                               textAlign: "center",
                               background: "#ffffff",
                               borderRadius: 36,
                               color: "#ffffff",
                               border: "2px solid white",
                               backgroundImage: "url('https://res.cloudinary.com/apxor/image/upload/c_scale,h_64/v1586432138/close-icon_nirvzq.png')",
                               backgroundSize: "cover",
                               backgroundRepeat: "no-repeat"
                           }} />
                        {
                            !only_image
                            && <Grid container spacing={8} alignItems={"stretch"} justify={"center"}
                                     style={{marginTop: 15}}>
                                {
                                    (image || image === '')
                                    && <Grid item>
                                        <ImageHolder
                                            handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.IMAGE_MODULE, image)}
                                            selected={selectedModule === MODULE_TYPES.IMAGE_MODULE}
                                            config={image}
                                            type={"URL"}
                                            style={{width: 160, height: 120}}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        }
                        {
                            !only_image
                            && <Grid container spacing={8} alignItems={"stretch"} justify={"center"}>
                                <Grid item xs sm md style={{alignSelf: 'center', marginTop: 5}}>
                                    { /* Title */ }
                                    {
                                        title_config
                                        && <TextHolder
                                            handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.TITLE_MODULE, title_config)}
                                            selected={selectedModule === MODULE_TYPES.TITLE_MODULE}
                                            bgColor={containerConfigs.bg_color}
                                            config={title_config}
                                            style={{textAlign: 'center', marginLeft: 5, marginRight: 5}}/>
                                    }
                                    { /* Description */}
                                    {
                                        text_config
                                        && <TextHolder
                                            handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.DESCRIPTION_MODULE, text_config)}
                                            selected={selectedModule === MODULE_TYPES.DESCRIPTION_MODULE}
                                            bgColor={containerConfigs.bg_color}
                                            config={text_config}
                                            style={{textAlign: 'center', marginLeft: 5, marginRight: 5}}/>
                                    }
                                </Grid>
                            </Grid>
                        }
                        {
                            !only_image
                            && buttons_config
                            && buttons_config.length > 0
                            && <Grid container>
                                <Grid item xs sm md>
                                    <ButtonsHolder
                                        style={{textAlign: 'center', width: '100%', padding: '6px', margin: 8}}
                                        handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.BUTTONS_MODULE, buttons_config)}
                                        selected={selectedModule === MODULE_TYPES.BUTTONS_MODULE}
                                        bgColor={containerConfigs.bg_color}
                                        config={buttons_config}/>
                                </Grid>
                            </Grid>
                        }
                    </div>
                </div>
            }
            { /* Full Screen Modal */ }
            {
                isFullScreenModal(layout_type)
                && <div style={{
                    overflow: 'hidden',
                    width: defaultSimulatorWidth,
                    height: defaultSimulatorHeight,
                    borderRadius: 16,
                    backgroundColor: !only_image ? (bg_color || 'rgb(51, 51, 51, 0.8)') : '',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                    backgroundSize: image_size === 'Stretch' ? '100% 100%' : image_size.toLowerCase(),
                    backgroundImage: 'url(' + bg_image + ')', backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                }}>
                    <a style={{
                        top: "3%",
                        right: "2%",
                        fontSize: 16,
                        lineHeight: "12px",
                        position: "absolute",
                        width: 18,
                        height: 18,
                        textAlign: "center",
                        background: "#ffffff",
                        borderRadius: 36,
                        color: "#ffffff",
                        border: "2px solid white",
                        backgroundImage: "url('https://res.cloudinary.com/apxor/image/upload/c_scale,h_64/v1586432138/close-icon_nirvzq.png')",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                    }} href={"apxor://xclose"} />

                    {
                        !only_image
                        && <Grid container style={{
                                flexDirection: "column",
                                justifyContent: "center",
                                height: "100%",
                                textAlign: "center",
                                padding: 12
                            }}>
                                <Grid item style={{marginBottom: 6}}>
                                    {
                                        !only_image
                                        && <TextHolder
                                            handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.TITLE_MODULE, title_config)}
                                            selected={selectedModule === MODULE_TYPES.TITLE_MODULE}
                                            bgColor={containerConfigs.bg_color}
                                            config={title_config}
                                            style={{
                                                paddingTop: '5px',
                                                display: '-webkit-box',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                WebkitLineClamp: 2,
                                                WebkitBoxOrient: 'vertical',
                                                alignSelf: 'center'
                                            }} />
                                    }
                                </Grid>

                                {
                                    (image || image === '')
                                    && <Grid item style={{maxHeight: "70%", minHeight: "70%", overflow: 'hidden', borderTopLeftRadius: 8, borderTopRightRadius: 8}}>
                                        <ImageHolder
                                            handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.IMAGE_MODULE, image)}
                                            selected={selectedModule === MODULE_TYPES.IMAGE_MODULE}
                                            config={image}
                                            type={"URL"}
                                            style={{width: '100%', height: '100%', border: 0}}
                                            isPure={false}
                                            newUI={config}
                                            useImgTag={false}
                                        />
                                    </Grid>
                                }
                                {
                                    <Grid item style={{overflow: 'hidden', borderBottomLeftRadius: 8, borderBottomRightRadius: 8, marginTop: '-2px'}}>
                                        {
                                            !only_image
                                            && buttons_config
                                            && buttons_config.length > 0
                                            && <ButtonsHolder
                                                buttonHolderStyle={{padding: 0, border: 0}}
                                                style={{
                                                    textAlign: 'center',
                                                    padding: '6px',
                                                    borderRadius: 0,
                                                    width: "100%"
                                                }}
                                                handleModuleSelection={() => handleModuleSelection(MODULE_TYPES.BUTTONS_MODULE, buttons_config)}
                                                selected={selectedModule === MODULE_TYPES.BUTTONS_MODULE}
                                                bgColor={containerConfigs.bg_color}
                                                config={buttons_config}
                                            />
                                        }
                                    </Grid>
                                }
                        </Grid>
                    }
                </div>
            }
        </div>);
    }
}

export default withStyles(styles)(InAppSimulator);