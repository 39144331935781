/**
 * Created by Ravi Teja
 * Date: 09-Dec-2020
 */
import BrokenImageIcon from "@material-ui/icons/BrokenImage";
import React from 'react';
import {connect} from 'react-redux';
import Placeholder from "../../../../../../../../components/reusable/Placeholder";
import {updateHeading} from "../../../../Navigation/actions";
import {getMessagesList} from "../actions";
import AnalysisContent from "./components";
import {getEvents} from "../../../Segments/NewSegment/actions";
import {Button, Grid} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import MultiSelect from "../../../../../../../../components/reusable/MaterialUi/MultiSelect";
import {
    getMessageFunnelAnalysisMetrics,
    getMessageFunnelAnalysisTimeSeries, getStepsMetrics,
    getStepsTimeSeriesMetrics, resetMessageAnalysis
} from "./actions";
import {COUNT_TYPE_ENUM} from "../../../../../../../../constants";
import {Link} from 'react-router';

function mapStateToProps(state) {
    return {
        appState: state.app,
        analysis: state.messageAnalysis,
        messages2: state.messages2,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getEvents: (appId) => dispatch(getEvents(appId)),
        getMessagesList: (appId) => dispatch(getMessagesList(appId)),

        getStepsMetrics: (appId, validity, stepsMap, stepType, of) => dispatch(getStepsMetrics(appId, validity, stepsMap, stepType, of)),
        getMetrics: (appId, validity, stepsMap, of) => {
            dispatch(getStepsMetrics(appId, validity, stepsMap, STEP_ENUM.IN_LINE, of));
            dispatch(getStepsMetrics(appId, validity, stepsMap, STEP_ENUM.IN_APP, of));
        },

        getStepsTimeSeriesMetrics: (appId, validity, stepsMap, stepType, of) => dispatch(getStepsTimeSeriesMetrics(appId, validity, stepsMap, stepType, of)),
        getTimeSeriesMetrics: (appId, validity, stepsMap, of) => {
            dispatch(getStepsTimeSeriesMetrics(appId, validity, stepsMap, STEP_ENUM.IN_LINE, of));
            dispatch(getStepsTimeSeriesMetrics(appId, validity, stepsMap, STEP_ENUM.IN_APP, of));
        },

        getMessageFunnelAnalysisMetrics: (appId, messageId, goalEvent, time) => dispatch(getMessageFunnelAnalysisMetrics(appId, messageId, goalEvent, time)),
        getMessageFunnelAnalysisTimeSeries: (appId, messageId, goalEvent, time) => dispatch(getMessageFunnelAnalysisTimeSeries(appId, messageId, goalEvent, time)),

        resetMessageAnalysis: () => dispatch(resetMessageAnalysis()),
        boundDispatch: dispatch
    };
}

const TIME_SLOTS = [
    {
        label: "5 Mins",
        value: 5 * 60
    },
    {
        label: "15 Mins",
        value: 15 * 60
    },
    {
        label: "30 Mins",
        value: 30 * 60
    },
    {
        label: "1 Hour",
        value: 60 * 60
    },
    {
        label: "1 Day",
        value: 24 * 60 * 60
    },
    {
        label: "1 Week",
        value: 7 * 24 * 60 * 60
    },
    {
        label: "2 Weeks",
        value: 14 * 24 * 60 * 60
    },
    {
        label: "3 Weeks",
        value: 21 * 24 * 60 * 60
    },
    {
        label: "30 Days",
        value: 30 * 24 * 60 * 60
    },
    {
        label: "60 Days",
        value: 60 * 24 * 60 * 60
    },
    {
        label: "90 Days",
        value: 90 * 24 * 60 * 60
    }
];

const STEP_ENUM = {
    IN_APP: "inapp_shown",
    IN_LINE: "inline_shown"
};

class MessageAnalysis extends React.Component {
    constructor(props) {
        super(props);
        const {appState: {app: {app_id: appId}}, location: {state: message}} = this.props;
        this.state = {
            goalEvent: message && message.goal_event || null,
            time: 300
        };
        this.appId = appId || "";
        this.message = message || {};
    }

    componentDidMount() {
        if (this.props.location.hasOwnProperty("state") && this.props.location.state) {
            const {location: {state: {meta: {name: messageName}}}} = this.props;
            const {appState: {app: {app_id: appId}}} = this.props;
            this.props.updateHeading("Message > " + messageName);
            this.props.getEvents(appId);
        }
    }

    componentWillMount() {
        // Get the analysis metrics for Users/Events.
        if (this.props.location.hasOwnProperty("state") && this.props.location.state) {
            const of = COUNT_TYPE_ENUM.users;
            const {time: windowTime} = this.state;
            const {appState: {app: {app_id: appId}}, location: {state: message}} = this.props;
            const stepsMap = {}, steps = message.uis;
            const validity = {
                since: message.validity.start_date,
                till: message.validity.end_date
            };
            for (let i = 0; i < steps.length; i++) {
                if (!stepsMap.hasOwnProperty(steps[i].type))
                    stepsMap[steps[i].type] = [];
                const stepId = message._id + "-" + (i + 1);
                stepsMap[steps[i].type].push(stepId);
            }

            if (Object.keys(stepsMap).length > 1) {
                /*
                If the stepsMap has different type of Messages like (IN_APP, IN_LINE etc.)
                 */
                this.props.getMetrics(appId, validity, stepsMap, of);
                this.props.getTimeSeriesMetrics(appId, validity, stepsMap, of);
            } else {
                const stepType = STEP_ENUM[Object.keys(stepsMap)[0]];
                this.props.getStepsMetrics(appId, validity, stepsMap, stepType, of);
                this.props.getStepsTimeSeriesMetrics(appId, validity, stepsMap, stepType, of);
            }

            const messageId = message._id;
            const goalEvent = message.goal_event || "";
            this.props.getMessageFunnelAnalysisMetrics(appId, messageId, goalEvent, windowTime);
            this.props.getMessageFunnelAnalysisTimeSeries(appId, messageId, goalEvent, windowTime)
        }
    }

    updateMessageData = (messages_list, messageId) => {
        let message = null;
        if (messages_list && messages_list !== []) {
            for (let i = 0; i < messages_list.length; i++) {
                if (messages_list[i]._id === messageId) {
                    message = messages_list[i];
                    this.setState({
                        message: messages_list[i]
                    })
                }
            }
        }
        this.props.updateHeading(message && message.hasOwnProperty("meta") && message.meta["name"] || "Unknown Message");
    };

    handleMultiSelectUpdate = (obj) => {
        if (Object.keys(obj)[0] === "goalEvent") {
            this.props.getMessageFunnelAnalysisMetrics(this.appId, this.message._id, obj["goalEvent"], this.state.time);
            this.props.getMessageFunnelAnalysisTimeSeries(this.appId, this.message._id, obj["goalEvent"], this.state.time);
        } else if (Object.keys(obj)[0] === "time") {
            this.props.getMessageFunnelAnalysisMetrics(this.appId, this.message._id, this.state.goalEvent, obj["time"]);
            this.props.getMessageFunnelAnalysisTimeSeries(this.appId, this.message._id, this.state.goalEvent, obj["time"]);
        }
        this.setState(obj);
    };

    render() {
        const message = this.message;
        if (this.props.location.state) {
            const {analysis: {stepsMetrics, stepsMetricsTimeSeries, messageFunnelAnalysisMetrics, messageFunnelAnalysisTimeSeries}} = this.props;
            const {appState: {appEvents = []}} = this.props;
            const {validity: {start_date: start_date, end_date: end_date}, statusType: statusType} = message;
            const createdAt = message.hasOwnProperty("track") ? (message.track.hasOwnProperty("created_at") ? message.track.created_at : moment().format("MMM Do YY")) : moment().format("MMM Do YY");
            const {goalEvent, time, isReady} = this.state;
            return (
                <div>
                    <Grid container>
                        <Grid item xs={12}>
                            <Card style={{
                                display: "flex",
                                flexDirection: "column",
                                marginBottom: 12,
                                paddingLeft: 48,
                                paddingRight: 48,
                                paddingTop: 16,
                                paddingBottom: 32
                            }}>
                                <div style={{marginBottom: 12}}>
                                    <Link to={`/apps/${this.appId}/walkthroughs`}>
                                        {"< Back"}
                                    </Link>
                                </div>
                                <Grid container spacing={16}>
                                    <Grid item xs={2} style={{paddingTop: 8}}>
                                        <Typography gutterBottom variant="h6" noWrap style={{
                                            textTransform: 'uppercase',
                                            fontWeight: 600,
                                            color: "#a2a2a2"
                                        }}> Created Date </Typography>
                                        <Typography gutterBottom variant="subtitle2" style={{fontSize: 24}}>
                                            {moment(createdAt).format("MMM D, YYYY")}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3} style={{paddingTop: 8}}>
                                        <Typography gutterBottom variant="h6" noWrap style={{
                                            textTransform: 'uppercase',
                                            fontWeight: 600,
                                            color: "#a2a2a2"
                                        }}> Start Date - End Date </Typography>
                                        <Typography gutterBottom variant="subtitle2" style={{fontSize: 24}}>
                                            {`${moment(start_date).format("MMM D, YYYY")} - ${moment(end_date).format("MMM D, YYYY")}`}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} style={{paddingTop: 8}}>
                                        <Typography gutterBottom variant="h6" noWrap style={{
                                            textTransform: 'uppercase',
                                            fontWeight: 600,
                                            color: "#a2a2a2"
                                        }}> Message Status </Typography>
                                        <Typography gutterBottom variant="subtitle2" style={{fontSize: 24}}>
                                            {statusType}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} style={{paddingTop: 8}}>
                                        <Typography gutterBottom variant="h6" noWrap style={{
                                            textTransform: 'uppercase',
                                            fontWeight: 600,
                                            color: "#a2a2a2"
                                        }}> Select Goal Event </Typography>
                                        <Typography gutterBottom variant="subtitle2" style={{fontSize: 24}}>
                                            <MultiSelect
                                                clearable
                                                handleChange={goalEvent => this.setState({goalEvent}, () => this.handleMultiSelectUpdate({goalEvent}))}
                                                margin="dense"
                                                options={appEvents.map(event => {
                                                    return {label: event, value: event}
                                                })}
                                                placeholder="Select Goal Event"
                                                single
                                                style={{width: 200, marginLeft: 12}}
                                                value={goalEvent}
                                            />
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2} style={{paddingTop: 8}}>
                                        <Typography gutterBottom variant="h6" noWrap style={{
                                            textTransform: 'uppercase',
                                            fontWeight: 600,
                                            color: "#a2a2a2"
                                        }}> Select Time Window </Typography>
                                        <Typography gutterBottom variant="subtitle2" style={{fontSize: 24}}>
                                            <MultiSelect
                                                placeholder="Time Window"
                                                value={time}
                                                options={TIME_SLOTS}
                                                handleChange={time => this.setState({time}, () => this.handleMultiSelectUpdate({time}))}
                                                style={{width: 200, marginLeft: 12}}
                                                single
                                                margin="dense"
                                            />
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                    <section className="content">
                        {
                            message && <AnalysisContent
                                message={message}
                                stepsMetrics={stepsMetrics}
                                stepsMetricsTimeSeries={stepsMetricsTimeSeries}
                                messageFunnelAnalysisMetrics={messageFunnelAnalysisMetrics}
                                messageFunnelAnalysisTimeSeries={messageFunnelAnalysisTimeSeries}
                                goalEvent={goalEvent}
                                windowTime={time}
                            />
                        }
                    </section>
                </div>
            )
        } else {
            return (
                <section className="content">
                    <Placeholder
                        withIcon
                        icon={<BrokenImageIcon style={{opacity: 0.2, width: 120, height: 120}}/>}
                        text="Try coming from Walkthroughs listing page."
                        typographyStyles={{fontSize: 18}}
                    >
                        <Link to={`/apps/${this.appId}/walkthroughs`}>
                            <Button
                                style={{marginTop: 12}}
                                variant={"outlined"}
                                color={"primary"}
                            >
                                Take Me there
                            </Button>
                        </Link>
                    </Placeholder>
                </section>
            )
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MessageAnalysis);