/**
 * Created by jyothi on 16/1/17.
 */
import React  from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CheckIcon from '@material-ui/icons/Check';
import RadioButtonUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedOutlined from '@material-ui/icons/RadioButtonCheckedOutlined';
import classNames from 'classnames';
import InfoHelper from "./InfoHelper";

const styles = theme => ({
    parent: {
        height: 29,
        background: 'transparent',
        justifyContent: 'initial',
        marginTop: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2
    },
    root: {
        display: 'flex !important',
        background: theme.palette.background.default,
        //borderTop: `2px ridge ${theme.palette.background.paper}`,
        //borderBottom: `2px ridge ${theme.palette.background.paper}`,
        margin: `0 ${theme.spacing.unit / 2}px`,
        border: '1px solid #CBCBCB',
        borderRadius: 2,
        paddingTop: theme.spacing.unit * 3 / 4,
        //width: '100%',
        '&:first-child': {
            marginLeft: 0
        },
        '&:last-child': {
            marginRight: 0
        },
        flex: 'inherit !important',
        maxWidth: '100%'
    },
    selected: {
        boxShadow: `1px 3px 7px 0px rgba(123, 135, 209, 0.4)`,//theme.custom.shadow,
        background: theme.palette.background.paper,
        border: `1px solid rgba(123, 135, 209, 0.2)`,
        margin: `0 ${theme.spacing.unit / 2}px`,
        paddingTop: `${theme.spacing.unit * 3 / 4}px !important`,
        '&:first-child': {
            marginLeft: 0
        },
        '&:last-child': {
            marginRight: 0
        }
    },
    formControl: {
        display: 'flex',
        //margin: apxTheme.spacing.unit * 3,
    },
    group: {
        display: 'flex',
        margin: `${theme.spacing.unit / 10}px 0`,
        flexDirection: 'row'
    },
    label:{
        textTransform: 'capitalize',
        fontWeight: 600,
        lineHeight: '1.4em',
        color: "rgba(126, 127, 135, 1)",
        //width: '100%'
    },
    labelSelected: {
        color: theme.palette.secondary.main,
        //paddingTop: `${theme.spacing.unit / 2 * 3}px`
    },
    labelAndIcon: {
        display: 'flex',
        //justifyContent: 'space-around'
    },
    wrapper: {
        display: 'flex',
        alignItems: 'initial'
    },
    icon: {
        width: '1.2em',
        height: '1.2em'
    },
    labelText: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        marginLeft: theme.spacing.unit / 2
    }
});

class Switch extends React.Component {

    render() {
        const {
            theme, classes, data, handleChange, value,
            containerStyles = {}, groupStyles = {},
            disabled = false
        } = this.props;
        const {parent, root, labelSelected, selected } = classes;
        return (
            <BottomNavigation
                value={value}
                onChange={(event, value) => handleChange(value, event)}
                showLabels
                disabled={disabled}
                classes={{root: parent}}
                style={{
                    pointerEvents: disabled ? 'none' : 'auto',
                    ...containerStyles
                }}
            >
                {
                    data.map((item, index) => {
                        const label = item.name || item;
                        const thisValue = item.value || item;
                        const isThisIsValue = value === thisValue;
                        const help = item.help;
                        return(
                            <BottomNavigationAction
                                className={
                                    classNames({
                                        [selected]: isThisIsValue,
                                        [labelSelected]: isThisIsValue,
                                    }, classes.label, classes.root)
                                }
                                classes={{
                                    wrapper: classes.wrapper
                                }}
                                key={label}
                                label={
                                    <div className={classes.labelAndIcon}>
                                        {isThisIsValue && <RadioButtonCheckedOutlined className={classes.icon} style={{fontSize: 'inherit'}}/>}
                                        { !isThisIsValue && <RadioButtonUnchecked className={classes.icon} style={{fontSize: 'inherit'}}/>}
                                        <span className={classes.labelText}>{label}</span>
                                        {
                                            help && <span style={{marginLeft: 4}}>
                                                <InfoHelper size="very-small">{help}</InfoHelper>
                                            </span>
                                        }
                                    </div>
                                }
                                value={thisValue}
                                icon={<span />}
                                title={label}
                                style={{...groupStyles}}
                            />
                        )
                    })
                }
            </BottomNavigation>
        );
    }
}

Switch.propTypes = {
    classes: PropTypes.object,
    handleChange: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    value: PropTypes.any,
    containerStyles: PropTypes.object,
    groupStyles: PropTypes.object,
    disabled: PropTypes.bool
};

export default withStyles(styles, {withTheme: true})(Switch);