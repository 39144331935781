import {
    ADD_CLIENT_EVENT,
    ADD_CLIENT_EVENT_FAILED,
    ADD_CLIENT_EVENT_PENDING,
    DELETE_CLIENT_EVENT,
    DELETE_CLIENT_EVENT_FAILED,
    DELETE_CLIENT_EVENT_PENDING,
    EDIT_CLIENT_EVENT,
    EDIT_CLIENT_EVENT_FAILED,
    EDIT_CLIENT_EVENT_PENDING,
    EM_GET_CLIENT_EVENTS,
    EM_GET_CLIENT_EVENTS_FAILED,
    EM_GET_CLIENT_EVENTS_PENDING,
    FILTERS_CHANGED,
    MODAL_STATE_CHANGE,
    PAGINATION_ACTION,
    UPDATE_PAGE_COUNTER,
    UPSERT_EVENT,
    UPSERT_EVENT_FAILED,
    UPSERT_EVENT_PENDING,
} from "./actionTypes";
import {
    addClientEventAPI,
    deleteClientEventAPI,
    editClientEventAPI,
    getEventRowsAPI,
    upsertClientEventAPI
} from "./api";

export function getEventRowsAction(appId, filters, after, before, limit) {
    return (dispatch, getState) => {
        const {filters,} = getState().eventsManagement;
        return dispatch({
            types: [
                EM_GET_CLIENT_EVENTS_PENDING,
                EM_GET_CLIENT_EVENTS,
                EM_GET_CLIENT_EVENTS_FAILED
            ],
            payload: {
                promise: getEventRowsAPI(appId, filters, after, before, limit, getState().auth)
                .then((response) => {
                    if (response) {
                        const {rows, total, has_prev, has_next} = response;
                        return {
                            after: rows.length > 0 ? rows[rows.length - 1]["_id"]["$oid"] : "",
                            before: rows.length > 0 ? rows[0]["_id"]["$oid"] : "",
                            rows: rows,
                            has_next,
                            has_prev,
                            total,
                        }
                    } else {
                        return {
                            rows: [],
                            after: "",
                            before: "",
                            has_next: false,
                            has_prev: false,
                            total: 0,
                        }
                    }
                })
            }
        });
    };
}

export function handleRowsPerPageChangeAction(key, value) {
    return (dispatch, getState) => {
        dispatch({
            type: PAGINATION_ACTION,
            payload: {key, value},
            meta: {}
        })

        const {
            app: {app: {app_id: appId}},
            eventsManagement: {filters, pagination: {limit}},
        } = getState();

        dispatch(
            getEventRowsAction(appId,
                filters,
                null,
                null,
                limit
            )
        )
    }
}

export function handleFiltersChangeAction(key, value) {
    return (dispatch, getState) => {
        dispatch({
            type: FILTERS_CHANGED,
            payload: {key, value},
            meta: {}
        })

        const {
            app: {app: {app_id: appId}},
            eventsManagement: {filters, pagination: {limit}},
        } = getState();

        dispatch(
            getEventRowsAction(appId,
                filters,
                null,
                null,
                limit
            )
        )
    }
}

export function updatePageCounterAction(direction) {
    return (dispatch, getState) => {
        let {pagination: {pageCounter}} = getState().eventsManagement;
        return dispatch({
            type: UPDATE_PAGE_COUNTER,
            payload: {
                pageCounter: direction > 0 ? pageCounter + 1 : pageCounter - 1
            },
            meta: {}
        })
    }
}

export function addClientEventAction(appId, appVersion, platform, event, auth) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                UPSERT_EVENT_PENDING,
                UPSERT_EVENT,
                UPSERT_EVENT_FAILED
            ],
            payload: {
                promise: upsertClientEventAPI(appId, appVersion, platform, event, auth)
            }
        });
    };
}

export function deleteClientEventAction(appId, id, auth) {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                DELETE_CLIENT_EVENT_PENDING,
                DELETE_CLIENT_EVENT,
                DELETE_CLIENT_EVENT_FAILED
            ],
            payload: {
                promise: deleteClientEventAPI(appId, id, auth)
            }
        });
    };
}

export function handleDialogStateAction(open, type, reset_pagination = false) {
    return (dispatch, getState) => {
        return dispatch({
            type: MODAL_STATE_CHANGE,
            payload: {
                open,
                type,
            },
            meta: {
                reset_pagination
            }
        })
    }
}

export function handleEditAction(appId, event) {
    return (dispatch, getState) => {
        const {filters, pagination: {limit}} = getState().eventsManagement;
        return dispatch({
            types: [
                EDIT_CLIENT_EVENT_PENDING,
                EDIT_CLIENT_EVENT,
                EDIT_CLIENT_EVENT_FAILED,
            ],
            payload: {
                promise: editClientEventAPI(appId, event, getState().auth)
                .then((res) => {
                    if (res) {
                        dispatch(handleDialogStateAction(false, null, true));
                        dispatch(getEventRowsAction(appId, filters, null, null, limit))
                    }
                })
            },
            meta: {}
        })
    }
}

export function handleAddAction(appId, event) {
    return (dispatch, getState) => {
        const {filters, pagination: {limit}} = getState().eventsManagement;
        return dispatch({
            types: [
                ADD_CLIENT_EVENT_PENDING,
                ADD_CLIENT_EVENT,
                ADD_CLIENT_EVENT_FAILED,
            ],
            payload: {
                promise: addClientEventAPI(appId, event, getState().auth)
                .then((res) => {
                    if (res) {
                        dispatch(handleDialogStateAction(false, null, true));
                        dispatch(getEventRowsAction(appId, filters, null, null, limit))
                    }
                })
            },
            meta: {}
        })
    }
}

export function handleConvertAction(open, type) {
    return (dispatch, getState) => {
        return dispatch({
            type: "",
            payload: {},
            meta: {}
        })
    }
}

export function handleDeleteAction(appId, eventId) {
    return (dispatch, getState) => {
        const {filters, pagination: {limit}} = getState().eventsManagement;
        return dispatch({
            types: [
                DELETE_CLIENT_EVENT_PENDING,
                DELETE_CLIENT_EVENT,
                DELETE_CLIENT_EVENT_FAILED,
            ],
            payload: {
                promise: deleteClientEventAPI(appId, eventId, getState().auth)
                .then((res) => {
                    if (res) {
                        dispatch(handleDialogStateAction(false, null, true));
                        dispatch(getEventRowsAction(appId, filters, null, null, limit))
                    }
                })
            },
            meta: {}
        })
    }
}
