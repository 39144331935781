
import React, {Component, Fragment} from 'react';
import {withStyles} from "@material-ui/core";
import PropTypes from "prop-types";
import AccessTime from '@material-ui/icons/AccessTime';
import People from '@material-ui/icons/People';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import TextField from "@material-ui/core/TextField/TextField";
import Paper from "@material-ui/core/Paper";
import List from "@material-ui/core/List";

const styles = (theme) => ({
    paper: {
        maxHeight: 5 * 46,
        overflow: 'hidden',
        border: '1px solid rgba(229, 221, 243, 0.9)'
    },
    list: {
        padding: 0,
    }
});

const USER = "user";
const SESSION = "session";

const Entry = (props) => {
    const {
        mention,
        handleClick
    } = props;

    return (
        <ListItem button onClick={handleClick}>
            <ListItemIcon>
                { mention.type === USER ? <People color="primary"/> : <AccessTime color="primary"/> }
            </ListItemIcon>
            <ListItemText inset primary={mention.name}/>
        </ListItem>
    );
};

const REGEX = /(?<=\[).*/;

const hasMention = /\[.*\]/;

const hasMentionTrigger = /\[[\w\s]*$/;

class RichTextField extends Component{

    constructor(props){
        super(props);
        const { mentionTrigger = "[", value = '' } = this.props;
        this.state = {
            value,
            suggestions: [],
            active: false,
            startIndex: 0
        };
    }

    componentWillReceiveProps(nextProps) {
        const { value } = nextProps;
        const { value: v } = this.state;
        if(value !== v){
            this.setState({value});
        }
    }

    onChange = (e) => {
        const { value } = e.target;
        const { active, startIndex } = this.state;
        const { mentionTrigger = "[", suggestions } = this.props;
        this.setState({ value }, this.handleUpdate);
        if(value.slice(-1) === mentionTrigger){
            this.setState({active: true, suggestions, startIndex: value.length});
        }
        if(active && value.includes(mentionTrigger)){
            this.onSearchChange(value.slice(startIndex));
        }
        if(!hasMentionTrigger.test(value)){
            this.setState({active: false, suggestions: [], startIndex: value.length});
        }
    };

    onSearchChange = (q = '') => {
        const { suggestions } = this.props;
        this.setState({
            suggestions: suggestions.filter(o => o.name.toLowerCase().includes(q.toLowerCase())),
        });
    };

    handleUpdate = () => {
        this.props.handleChange(this.state.value);
    };

    focus = () => {
        this.editor.focus();
    };

    handleMentionClick = (mention) => () => {
        this.setState({active: false}, () => {
            const { type, name } = mention;
            const { value, startIndex } = this.state;
            const text = value.slice(0, startIndex) + type + '_' + name + '(ENTER_DEFAULT_VALUE)]'; //TODO: make it generic
            this.setState({active: false, value: text, startIndex: 0}, this.handleUpdate);
        });
    };

    render(){
        const {
            classes, margin = "normal",
            label, placeholder = "'[' for macros]",
            handleChange, ...others
        } = this.props;
        const { value, suggestions = [], active } = this.state;
        return(
            <Fragment>
                <TextField
                    {...others}
                    value={value}
                    onChange={this.onChange}
                    margin={margin}
                    label={label}
                    placeholder={placeholder}
                    fullWidth
                />
                {
                    active &&
                        <Paper className={classes.paper}>
                            <List className={classes.list}>
                                {
                                    suggestions.map((o, i) =>
                                        <Entry key={o.name + i} mention={o} handleClick={this.handleMentionClick(o)}/>
                                    )
                                }
                            </List>
                        </Paper>
                }
            </Fragment>
        )
    }
}

RichTextField.propTypes = {
    suggestions: PropTypes.array.isRequired,
    handleChange: PropTypes.func,
    ...TextField.propTypes
};

export default withStyles(styles)(RichTextField);


