/**
 * Created By Uma Sankar Yedida
 * Date: 18-Jan-2020
 */

import {Grid, IconButton, Tooltip} from "@material-ui/core";
import React from "react";
import MultiSelect from "../../../../../../../../../../../components/reusable/MaterialUi/MultiSelect";
import {APP_PLATFORMS} from "../../../../../../../../../../../constants";
import ActionsTextField from "../ActionsTextField";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import ModuleConfigSection from "./ModuleConfigSection";

export default class TargetConfiguration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activity: this.props.activity,
            view_id: this.props.view_id
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {activity, view_id} = nextProps;
        const {activity: currentActivityValue, view_id: currentViewId} = this.state;

        if (activity !== currentActivityValue || view_id !== currentViewId) {
            this.setState({
                activity,
                view_id
            });
        }
    }

    handleOnChange = (data, key) => {
        this.setState({
            [key]: data
        });
        this.props.handleOnValueChange(data, key);
    };

    render() {
        const {platform, appScreenNames, disableActivitySelector, withTargetButton = true} = this.props;

        const {activity, view_id} = this.state;

        return (
            <div>
                <ModuleConfigSection
                    title={"Screen"}
                    style={{marginBottom: 8}}
                >
                    <MultiSelect
                        options={appScreenNames.map(screenName => ({
                            label: screenName,
                            value: screenName
                        }))}
                        disabled={disableActivitySelector}
                        placeholder={withTargetButton ? "Select Screen" : "Select Screen on your Device"}
                        single
                        value={activity}
                        handleChange={(activityValue) => this.handleOnChange(activityValue, "activity")}
                    />
                </ModuleConfigSection>
                <ModuleConfigSection title={"View ID"} style={{marginTop: 8}}>
                    <Grid container alignItems={"center"}>
                        <Grid item>
                            <ActionsTextField
                                style={{marginBottom: 0}}
                                placeholder={"Enter view ID"}
                                value={view_id}
                                onChange={(e) => this.handleOnChange(e.target.value, "view_id")}
                            />
                        </Grid>
                        {withTargetButton && <Grid item>
                            <Tooltip title={"Pick from Preview"}>
                                <IconButton
                                    onClick={() => this.props.showDialog()}>
                                    <LocationSearchingIcon
                                        color={"primary"}
                                        fontSize={"small"}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>}
                    </Grid>
                </ModuleConfigSection>
            </div>
        );
    }

}