import React, {Component, Fragment} from 'react';
import {
    getEventRelatedStickiness
} from '../actions';
import {
    graphDataToCSVString
} from "../../../../../../../utils";
import MultiUtilityChart from "../../../../../../../components/reusable/Recharts/MultiUtilityChart";
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import withSelfComponent from "./SelfComponent";
import {session} from "../../../../../../../utils/Storage";
import Switch from "../../../../../../../components/reusable/Switch";
import TextField from "@material-ui/core/TextField/TextField";
import debounce from 'debounce';
import Typography from "@material-ui/core/Typography/Typography";

const formatData = (data = [], groupBy = false) => {
    let series = [];
    if(Array.isArray(data) && data.length > 0){
        const baseObject = data[0];
        if(groupBy && Array.isArray(baseObject.data)){
            series = baseObject.data.slice(1).map(o => ({day: o.day, [baseObject.property]: o.percent, [`avg_${baseObject.property}`]: o.count }));
            data.slice(1).forEach(obj => {
                obj.data.slice(1).forEach(({percent, count}, index) => {
                    series[index][obj.property] = percent;
                    series[index][`avg_${obj.property}`] = count;
                });
            });
        }else{
            series = data.slice(1).map(o => ({...o, avg_percent: o.count}));
        }
    }
    return series;
};

const WINDOW_ENUM = {
    week: 'week',
    month: 'month'
};

const WINDOW_SWITCH_DATA = [
    { name: "Weekly", value: WINDOW_ENUM.week },
    { name: "Monthly", value: WINDOW_ENUM.month }
];

class Stickiness extends Component {

    constructor(props){
        super(props);
        const {
            selfParams: {
                queryParams: {
                    groupBy = null,
                    window = WINDOW_ENUM.week,
                    windowsLimit = 3
                } = {}
            } = {}
        } = props;
        this.state = {
            groupBy,
            window,
            windowsLimit
        };
    }

    componentWillMount(){
        this.doLocalUpdate();
    }

    doLocalUpdate = () => {
        const {handleLocalUpdate } = this.props;
        if(typeof handleLocalUpdate === 'function'){
            handleLocalUpdate(this.state);
        }
    };


    hydrator = (props = this.props) => {
        const {
            event, dataKey,
            queryParams, extraFilters
        } = props.selfParams;
        const {dispatch, params: {appId}, raw = false } = props;
        this.doLocalUpdate();
        const finalQueryParams = { ...queryParams, ...(raw && this.state) };
        if(event && event.length > 0){
            dispatch(getEventRelatedStickiness(appId, dataKey, finalQueryParams, extraFilters));
        }
    };

    render(){
        const {
            self, selfParams: {
                dataKey, customLabel, event
            },
            appState: { attributes: { user = [], session: sessionAttributes = [], event: eventAttributes = [] } = {} },
            meta: { api_pending }, parentCompLabel,
            raw = false
        } = this.props;
        const properties = [ ...user, ...sessionAttributes, ...eventAttributes ];
        const yLabel = "Percent";
        const data = self[dataKey] || [];
        const { groupBy, window, windowsLimit } = this.state;
        const dataKeys = groupBy ? data.map(o => o.property) : ["percent"];
        const isWeekly = window === WINDOW_ENUM.week;
        const formattedData = formatData(data, !!groupBy);
        if(!api_pending){
            let obj = {};
            obj[customLabel] = graphDataToCSVString(customLabel, formattedData);
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        return (
            <div style={{position: 'relative', width: '100%'}}>
                <div style={{
                    position: 'absolute',
                    zIndex: 3,
                    right: 60,
                    minWidth: 600,
                    display: 'flex',
                    marginBottom: 8,
                    justifyContent: 'flex-end'
                }}>
                    {
                        !raw && <Typography style={{margin: 16}}>{`Last ${windowsLimit} ${window}s`}</Typography>
                    }
                    {raw &&
                    <Fragment>
                        <MultiSelect
                            options={properties.map(o => ({label: o, value: o}))}
                            handleChange={groupBy => {
                                this.setState({groupBy: groupBy.length > 0 ? groupBy : null}, this.hydrator);
                            }}
                            style={{marginRight: 8, width: '50%'}}
                            value={groupBy}
                            placeholder="Group By Property"
                            single
                            margin="normal"
                        />
                        <Switch
                            data={WINDOW_SWITCH_DATA}
                            handleChange={window => this.setState({window}, this.hydrator)}
                            value={window}
                        />
                        <TextField
                            style={{marginLeft: 8}}
                            value={windowsLimit}
                            onChange={e => {
                                const windowsLimit = Number(e.target.value);
                                if(windowsLimit < 1 || isWeekly && windowsLimit > 52 || windowsLimit > 12) {
                                    return;
                                }
                                this.setState({windowsLimit}, debounce(this.hydrator, 500));
                            }}
                            margin="dense"
                            type="number"
                            label={`Last (n) ${isWeekly ? "Weeks" : "Months"}`}
                        />
                    </Fragment>
                    }
                </div>
                <MultiUtilityChart
                    {...this.props}
                    title={customLabel}
                    height={300}
                    withHeader
                    dataKey="day"
                    yLabel={yLabel}
                    xLabel="Days"
                    data={ formattedData }
                    lineDataKeys={dataKeys}
                    stats={{}}
                    fullWidth
                    showAverage
                    xLabelFormatter={day => `${day} days`}
                    withoutAggregations
                />
            </div>
        )
    };
}

Stickiness.propTypes = {

};

export default withSelfComponent(Stickiness)