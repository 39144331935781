import React from 'react'
import {Route, IndexRedirect} from 'react-router';
import {DASHBOARD_ENDPOINTS} from '../src/constants/EndPoints';
import Application from './containers/Root/Application'
import Auth from './containers/Root/Application/Auth';
import Login from './containers/Root/Application/Auth/Login';
import SignUp from './containers/Root/Application/Auth/SignUp';
import ResetPassword from './containers/Root/Application/Auth/ResetPassword';
import Logout from './containers/Root/Application/Auth/Logout';
import DailyReport from "./containers/Root/Application/DailyReports/Reports";
import RequireAuth from './containers/Root/Application/RequireAuth';
import AppApproval from "./containers/Root/Application/RequireAuth/Apps/Approval";
import DashBoard from './containers/Root/Application/RequireAuth/Dashboard';
import Apps from './containers/Root/Application/RequireAuth/Apps';
import NewMessageFlow
    from "./containers/Root/Application/RequireAuth/Dashboard/Campaigns/Messages2/components/Flow/NewMessageFlow";
import EventsMgmt from "./containers/Root/Application/RequireAuth/Dashboard/Settings/EventsManagement";
import UserData from "./containers/Root/Application/RequireAuth/Dashboard/UserData";
import Integration from './containers/Root/Application/RequireAuth/Integration';
import UserExplorer from './containers/Root/Application/RequireAuth/Dashboard/Users/UserExplorer';
import Retention from './containers/Root/Application/RequireAuth/Dashboard/Retention';
import Uninstalls from './containers/Root/Application/RequireAuth/Dashboard/Uninstalls';
import EventAnalysis from './containers/Root/Application/RequireAuth/Dashboard/EventAnalysis';
import SDKSettings from './containers/Root/Application/RequireAuth/Dashboard/Settings/SDK';
import TestDeviceSettings from './containers/Root/Application/RequireAuth/Dashboard/Settings/TestDevices';
import IAMSettings from './containers/Root/Application/RequireAuth/Dashboard/Settings/IAM';
import GeneralSettings from './containers/Root/Application/RequireAuth/Dashboard/Settings/General';
import User from './containers/Root/Application/RequireAuth/Dashboard/Users/User';
import WIPMessages from './containers/Root/Application/RequireAuth/Dashboard/Messages';
import Error404 from './containers/Root/Application/Error/404';
import Error401 from './containers/Root/Application/Error/401';
import Misc from './containers/Root/Application/RequireAuth/Dashboard/Misc';
import Segments from './containers/Root/Application/RequireAuth/Dashboard/Segments';
import Segment from './containers/Root/Application/RequireAuth/Dashboard/Segments/Segment';
import NewSegment from './containers/Root/Application/RequireAuth/Dashboard/Segments/NewSegment';
import CustomDashboards from './containers/Root/Application/RequireAuth/Dashboard/CustomDashboards';
import CustomDashboard from './containers/Root/Application/RequireAuth/Dashboard/CustomDashboards/CustomDashboard';
import DropOff from './containers/Root/Application/RequireAuth/Dashboard/DropOff';
import Alerts from './containers/Root/Application/RequireAuth/Dashboard/Alerts';
import Funnels from './containers/Root/Application/RequireAuth/Dashboard/Funnels';
import Push from './containers/Root/Application/RequireAuth/Dashboard/Push';
import CustomReports from './containers/Root/Application/RequireAuth/Dashboard/CustomReports';
import Path from './containers/Root/Application/RequireAuth/Dashboard/Path';
import Profile from './containers/Root/Application/RequireAuth/Profile';

import Surveys from './containers/Root/Application/RequireAuth/Dashboard/Surveys';
import Survey from './containers/Root/Application/RequireAuth/Dashboard/Surveys/Survey';
import SurveyResults from './containers/Root/Application/RequireAuth/Dashboard/Surveys/Results';

import Contexts from './containers/Root/Application/RequireAuth/Dashboard/Contexts';
import NewContext from './containers/Root/Application/RequireAuth/Dashboard/Contexts/NewContext';
import Context from './containers/Root/Application/RequireAuth/Dashboard/Contexts/Context';

import Preprocess from './containers/Root/Application/RequireAuth/Dashboard/Preprocess';

import MessageResults from './containers/Root/Application/RequireAuth/Dashboard/Messages/Results';

import Messages_v2 from './containers/Root/Application/RequireAuth/Dashboard/Campaigns/Messages2';

import MessageAnalysis from "./containers/Root/Application/RequireAuth/Dashboard/Campaigns/Messages2/Analysis";

const router =
    <Route path="/" component={Application}>

        <IndexRedirect to="/login" />

        <Route component={Auth}>
            <Route path="/login" component={Login}/>
            <Route path="/login:userName" component={Login}/>
            <Route path="/sign_up" component={SignUp}/>
            <Route path="/logout" component={Logout}/>
            <Route path="/reset-password" component={ResetPassword}/>
        </Route>

        <Route component={RequireAuth}>

            <Route path="/apps" component={Apps}/>

            <Route path="/apps/:appId" component={ DashBoard }>

                <Route path={DASHBOARD_ENDPOINTS.APPROVAL} component={AppApproval}/>
                <Route path={DASHBOARD_ENDPOINTS.INTEGRATION} component={Integration}/>
                <Route path={DASHBOARD_ENDPOINTS.USER_EXPLORER} component={UserExplorer}/>
                <Route path={DASHBOARD_ENDPOINTS.EVENT_ANALYSIS} component={EventAnalysis}/>
                {/*Users Routes*/}
                <Route path={DASHBOARD_ENDPOINTS.RETENTION} component={Retention}/>
                <Route path={DASHBOARD_ENDPOINTS.UNINSTALLS} component={Uninstalls}/>

                <Route path={`${DASHBOARD_ENDPOINTS.SEGMENTS}/new`} component={NewSegment}/>
                <Route path={`${DASHBOARD_ENDPOINTS.SEGMENTS}/:segmentId`} component={Segment}/>
                <Route path={`${DASHBOARD_ENDPOINTS.FUNNELS}(/:funnelId)`} component={Funnels}/>
                <Route path={DASHBOARD_ENDPOINTS.SEGMENTS} component={Segments}/>

                <Route path={DASHBOARD_ENDPOINTS.USERS}>
                    <Route path=":userId" component={User}/>
                </Route>

                <Route path={DASHBOARD_ENDPOINTS.USER}>
                    <Route path=":userId" component={User}/>
                </Route>

                <Route path={DASHBOARD_ENDPOINTS.SETTINGS}>
                    <Route path="sdk" component={SDKSettings}/>
                    <Route path="test-devices" component={TestDeviceSettings}/>
                    <Route path="iam" component={IAMSettings}/>
                    <Route path="general" component={GeneralSettings}/>
                </Route>

                <Route path={DASHBOARD_ENDPOINTS.DATA_MANGEMENT}>
                    <Route path="events" component={EventsMgmt}/>
                    <Route path="downloads" component={UserData} />
                </Route>

                <Route path={`${DASHBOARD_ENDPOINTS.MESSAGES2}`} component={Messages_v2} />
                <Route path={`${DASHBOARD_ENDPOINTS.MESSAGES2}/new`} component={NewMessageFlow} />
                <Route path={`${DASHBOARD_ENDPOINTS.MESSAGES2}/:messageId/edit`} component={NewMessageFlow} />
                <Route path={`${DASHBOARD_ENDPOINTS.MESSAGES2}/:messageId/analysis`} component={MessageAnalysis} />

                <Route path={`${DASHBOARD_ENDPOINTS.MESSAGES}(/:messageId)`} component={WIPMessages}/>

                <Route path={`${DASHBOARD_ENDPOINTS.MESSAGES}/:messageId/results`} component={MessageResults}/>

                <Route path={DASHBOARD_ENDPOINTS.DROP_OFF} component={DropOff}/>

                <Route path={DASHBOARD_ENDPOINTS.ALERTS} component={Alerts}/>

                <Route path="wip-custom-overview" component={Misc}/>

                <Route path={`${DASHBOARD_ENDPOINTS.DASHBOARDS}/:dashboardId`} component={CustomDashboard} />
                <Route path={DASHBOARD_ENDPOINTS.DASHBOARDS} component={CustomDashboards} />

                <Route path={DASHBOARD_ENDPOINTS.PUSH} component={Push} />

                <Route path={DASHBOARD_ENDPOINTS.CUSTOM_REPORTS} component={CustomReports} />

                <Route path={DASHBOARD_ENDPOINTS.PATH} component={Path} />

                <Route path={`${DASHBOARD_ENDPOINTS.SURVEYS}/new`} component={Survey} />

                <Route path={`${DASHBOARD_ENDPOINTS.SURVEYS}/:surveyId`} component={Survey} />

                <Route path={`${DASHBOARD_ENDPOINTS.SURVEYS}/:surveyId/results`} component={SurveyResults} />

                <Route path={DASHBOARD_ENDPOINTS.SURVEYS} component={Surveys} />

                <Route path={`${DASHBOARD_ENDPOINTS.CONTEXTS}/new`} component={NewContext} />

                <Route path={`${DASHBOARD_ENDPOINTS.CONTEXTS}/:contextId`} component={Context} />

                <Route path={DASHBOARD_ENDPOINTS.CONTEXTS} component={Contexts} />

                <Route path={DASHBOARD_ENDPOINTS.PREPROCESS} component={Preprocess} />

            </Route>

            <Route path="/profile" component={Profile}/>

        </Route>

        <Route path={`${DASHBOARD_ENDPOINTS.REPORTS}/:appId/:reportId`} component={DailyReport}/>

        <Route path={DASHBOARD_ENDPOINTS.UNAUTHORISED} component={Error401} />

        <Route path='*' component={Error404} />

    </Route>

export default router;