import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Chip from '@material-ui/core/Chip';
import InfoHelper from "../../../../../../../components/reusable/InfoHelper";

class Widgets extends Component {

    render() {
        const {
            icon, label, inEditMode, onAdd, classes, help
        } = this.props;
        const styles = inEditMode ? {pointerEvents: 'none', cursor: 'not-allowed'} : {};
        const chipStyles = inEditMode ? {} : {cursor: 'pointer'};
        const deleteAction = help ? () => null : undefined;
        return (
            <div style={styles}>
                <Chip
                    clickable
                    avatar={
                        <Avatar className={classes.colorDefault}>
                            {icon}
                        </Avatar>
                    }
                    onDelete={deleteAction}
                    label={label}
                    style={{marginLeft: 5, ...chipStyles}}
                    onClick={onAdd}
                    className={classes.root}
                    deleteIcon={<InfoHelper>{help}</InfoHelper>}
                />
            </div>
        );
    }

}

Widgets.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.any,
    onAdd: PropTypes.func,
    classes: PropTypes.object.isRequired,
};

export default Widgets;