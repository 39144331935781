/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React from 'react';
import {Button, Grid, Typography} from "@material-ui/core";
import {Sunburst, LabelSeries } from 'react-vis';
import "react-vis/dist/style.css";
import {formatNumber, getPercent, randomColorWithIndex, roundOffNumber, toTimeSpent} from '../../utils';
import PropTypes from 'prop-types';
import ArrowedLabel from "./ArrowedLabel";

const PATH_ENUM = {
    EVENT: "Event",
    SCREEN: "Screen"
};

const LABEL_STYLE = {
    fontSize: '8px',
    textAnchor: 'middle'
};

/**
 * Recursively work backwards from highlighted node to find path of valud nodes
 * @param {Object} node - the current node being considered
 * @returns {Array} an array of strings describing the key route to the current node
 */
function getKeyPath(node) {
    if (!node.parent) {
        return [];
    }
    const d = (node.data && node.data) || node;
    //const pathItem = d.name + "(" + d.count + ")";
    const pathItem = {name: d.name, count: d.count, id: d.id, hasChildren: d.children.length > 0};
    return [pathItem].concat(
        getKeyPath(node.parent)
    );
}

/**
 * Recursively modify data depending on whether or not each cell has been selected by the hover/highlight
 * @param {Object} data - the current node being considered
 * @param {Object|Boolean} keyPath - a map of keys that are in the highlight path
 * if this is false then all nodes are marked as selected
 * @returns {Object} Updated tree structure
 */

const getNodeId = () => {
    return Math.random() * 1000000;
};

function updateData(data, keyPath, getColor) {
    if(!data.id){
        data.id = getNodeId();
    }
    if (data.children) {
        data.children.map(child => updateData(child, keyPath, getColor));
        if(data.children.length === 0){
            data.size = data.count;
        }
    }else{
        data.size = data.count;
    }
    // add a fill to all the uncolored cells
    if (!data.hex) {
        const name = data.name || "";
        data.style = {
            fill: getColor(name)
        };
    }
    data.style = {
        ...data.style,
        //fillOpacity: keyPath && !keyPath[data.name + "(" + data.count + ")"] ? 0.2 : 1
        fillOpacity: keyPath && !keyPath[data.id] ? 0.2 : 1
    };

    return data;
}

const renderLabel = (name, count, path, index) => (
    <span style={{maxWidth: 100, display: 'inline-block', fontSize: 9}}>
        <span
            style={{
                maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden',
                whiteSpace: 'nowrap', display: 'block', fontWeight: 600
            }} title={name}
        >{name}</span>
        <b title={count}>{formatNumber(count)} ({getPercent(count, path[0].count)} %)</b>
    </span>
);

class DisplayPaths extends React.Component {

    render(){
        const { path, reverse = false, start, end, size, getColor } = this.props;
        const isBetween = start && start.length > 0 && end && end.length > 0;
        const lastIndex = isBetween ? size + 1 : size;
        return(
            <div style={{minHeight: 30}}>
                {path.map(({name, count, hasChildren}, i) =>
                    <ArrowedLabel
                        key={name+count+i}
                        color={getColor(name)}
                        withoutBefore={i === 0}
                        withoutAfter={i === lastIndex || (isBetween && name === end && path.length === i + 1) || !hasChildren }
                        label={renderLabel(name, count, path)}
                        reverse={end && end.length > 0 && (!start || start.length === 0)}
                    />
                )}
            </div>
        )
    }

}

export default class PathGraph extends React.Component {

    constructor(props){
        super(props);
        const { data = [] } = this.props;
        this.colors = {};
        this.state = {
            path: [],
            data: updateData({children: data}, false, this.getColor),
            finalValue: false,
            clicked: false
        };
    }

    componentWillReceiveProps(nextProps){
        const { data = [] } = nextProps;
        this.setState({data: updateData({children: data}, false, this.getColor)});
    }

    getKeyPath = (node, start, end) => {
        return getKeyPath(node).reverse();
    };

    getColor = (name) => {
        if(this.colors.hasOwnProperty(name)){
            return this.colors[name];
        }else{
            const color = randomColorWithIndex(Object.keys(this.colors).length);
            this.colors[name] = color;
            return color;
        }
    };

    render(){
        const { start, end, size, sample } = this.props;
        const {clicked, data, finalValue, path} = this.state;
        return(
            <div style={{position: 'relative', minHeight: 500}}>
                {sample < 100 && <Typography variant="caption" style={{textAlign: 'right'}}>Sampled: {roundOffNumber(sample)} % Users</Typography>}
                <DisplayPaths
                    path={path}
                    start={start}
                    end={end}
                    size={size}
                    getColor={this.getColor}
                />
                <Sunburst
                    animation
                    className="item-center"
                    hideRootNode
                    onValueMouseOver={node => {
                        if (clicked) {
                            return;
                        }
                        const path = this.getKeyPath(node, start, end);
                        const pathAsMap = path.reduce((res, row) => {
                            res[row.id] = true;
                            return res;
                        }, {});
                        const finalNode = path[path.length - 1];
                        this.setState({
                            path: path,
                            finalValue: finalNode.name + '(' + finalNode.count + ')',
                            data: updateData(data, pathAsMap, this.getColor)
                        });
                    }}
                    onValueMouseOut={() =>
                        clicked
                            ? () => {}
                            : this.setState({
                                path: [],
                                finalValue: false,
                                data: updateData(data, false, this.getColor)
                            })
                    }
                    onValueClick={() => this.setState({clicked: !clicked})}
                    style={{
                        stroke: '#ddd',
                        strokeOpacity: 0.3,
                        strokeWidth: '0.5'
                    }}
                    colorType="literal"
                    getSize={d => d.size}
                    getColor={d => d.hex}
                    data={data}
                    height={480}
                    width={480}
                >
                    {finalValue && (
                        <LabelSeries
                            data={[{x: 0, y: 0, label: finalValue, style: LABEL_STYLE}]}
                        />
                    )}
                </Sunburst>
            </div>
        )
    }
}

PathGraph.propTypes = {
    data: PropTypes.array.isRequired,
    start: PropTypes.string,
    end: PropTypes.string,
    size: PropTypes.number
};