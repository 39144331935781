/**
 * Created by jyothi on 1/11/17.
 */


export const CUSTOM_EVENT_ENUM = {
    event: "event",
    sessions: "sessions",
    users: "users"
};

export const SEGMENT_ATTRIBUTES_PENDING = "SEGMENT_ATTRIBUTES_PENDING";
export const SEGMENT_ATTRIBUTES = "SEGMENT_ATTRIBUTES";
export const SEGMENT_ATTRIBUTES_FAILED = "SEGMENT_ATTRIBUTES_FAILED";

export const SEGMENT_ATTRIBUTE_VALUES_PENDING = "SEGMENT_ATTRIBUTE_VALUES_PENDING";
export const SEGMENT_ATTRIBUTE_VALUES = "SEGMENT_ATTRIBUTE_VALUES";
export const SEGMENT_ATTRIBUTE_VALUES_FAILED = "SEGMENT_ATTRIBUTE_VALUES_FAILED";

export const SEGMENT_SAVE_PENDING = "SEGMENT_SAVE_PENDING";
export const SEGMENT_SAVE = "SEGMENT_SAVE";
export const SEGMENT_SAVE_FAILED = "SEGMENT_SAVE_FAILED";

export const SEGMENT_COUNT_PENDING = "SEGMENT_COUNT_PENDING";
export const SEGMENT_COUNT = "SEGMENT_COUNT";
export const SEGMENT_COUNT_FAILED = "SEGMENT_COUNT_FAILED";

export const SEGMENT_EVENTS_PENDING = "SEGMENT_EVENTS_PENDING";
export const SEGMENT_EVENTS = "SEGMENT_EVENTS";
export const SEGMENT_EVENTS_FAILED = "SEGMENT_EVENTS_FAILED";

export const SEGMENT_EVENT_ATTRIBUTES_PENDING = "SEGMENT_EVENT_ATTRIBUTES_PENDING";
export const SEGMENT_EVENT_ATTRIBUTES = "SEGMENT_EVENT_ATTRIBUTES";
export const SEGMENT_EVENT_ATTRIBUTES_FAILED = "SEGMENT_EVENT_ATTRIBUTES_FAILED";

export const SEGMENT_EVENT_ATTRIBUTE_VALUES_PENDING = "SEGMENT_EVENT_ATTRIBUTE_VALUES_PENDING";
export const SEGMENT_EVENT_ATTRIBUTE_VALUES = "SEGMENT_EVENT_ATTRIBUTE_VALUES";
export const SEGMENT_EVENT_ATTRIBUTE_VALUES_FAILED = "SEGMENT_EVENT_ATTRIBUTE_VALUES_FAILED";

export const SEGMENT_UPDATE_QUERY = "SEGMENT_UPDATE_QUERY";

export const SEGMENT_RESET_QUERY = "SEGMENT_RESET_QUERY";

export const SEGMENT_UPDATE_QUERY_TYPE = "SEGMENT_UPDATE_QUERY_TYPE"; //users, sessions