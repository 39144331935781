/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

export const IAM_ADD_CUSTOMER_PENDING = "IAM_ADD_CUSTOMER_PENDING";
export const IAM_ADD_CUSTOMER = "IAM_ADD_CUSTOMER";
export const IAM_ADD_CUSTOMER_FAILED = "IAM_ADD_CUSTOMER_FAILED";

export const IAM_REMOVE_CUSTOMER_PENDING = "IAM_REMOVE_CUSTOMER_PENDING";
export const IAM_REMOVE_CUSTOMER = "IAM_REMOVE_CUSTOMER";
export const IAM_REMOVE_CUSTOMER_FAILED = "IAM_REMOVE_CUSTOMER_FAILED";

export const IAM_ADD_DASHBOARD_TO_CUSTOMER_PENDING = "IAM_ADD_DASHBOARD_TO_CUSTOMER_PENDING";
export const IAM_ADD_DASHBOARD_TO_CUSTOMER = "IAM_ADD_DASHBOARD_TO_CUSTOMER";
export const IAM_ADD_DASHBOARD_TO_CUSTOMER_FAILED = "IAM_ADD_DASHBOARD_TO_CUSTOMER_FAILED";

export const IAM_REMOVE_DASHBOARD_TO_CUSTOMER_PENDING = "IAM_REMOVE_DASHBOARD_TO_CUSTOMER_PENDING";
export const IAM_REMOVE_DASHBOARD_TO_CUSTOMER = "IAM_REMOVE_DASHBOARD_TO_CUSTOMER";
export const IAM_REMOVE_DASHBOARD_TO_CUSTOMER_FAILED = "IAM_REMOVE_DASHBOARD_TO_CUSTOMER_FAILED";

export const IAM_TRANSFER_APP_PENDING = "IAM_TRANSFER_APP_PENDING";
export const IAM_TRANSFER_APP = "IAM_TRANSFER_APP";
export const IAM_TRANSFER_APP_FAILED = "IAM_TRANSFER_APP_FAILED";

export const RESET_IAM = "RESET_IAM";