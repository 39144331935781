/**
 * Created by Rakesh Peela
 * Date: 09-Dec-2019
 * Time: 8:17 PM
 */

import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {InputBase} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

const CustomSelectInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing.unit * 3,
        },
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: "#ffffff",
        border: '1px solid #ced4da',
        fontSize: 16,
        width: 'auto',
        padding: "8px 26px 8px 8px",
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            borderRadius: 4,
            borderColor: '#3e50b5',
        },
    },
}))(InputBase);

class CustomNativeSelect extends React.Component {
    render() {
        const {defaultValue, handleChange, options, itemName, disabled, style} = this.props;
        return (
            <FormControl disabled={disabled} variant="outlined" style={{...style}}>
                <Select
                    native
                    value={defaultValue}
                    onChange={handleChange}
                    input={
                        <CustomSelectInput
                            name={itemName}
                            id={"outlined-" + itemName}
                        />
                    }
                >
                    {
                        options.map((x, index) => (
                            <option key={index + "__" + x.key} value={x.key}>{x.label}</option>
                        ))
                    }
                </Select>
            </FormControl>
        );
    }
}

CustomNativeSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        label: PropTypes.string,
    })),
    // defaultValue: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    itemName: PropTypes.string,
    handleChange: PropTypes.func,
    defaultValue: PropTypes.any,
    disabled: PropTypes.bool,
};

export default CustomNativeSelect;