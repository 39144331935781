/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    RESET_PATH,
    PATH_PENDING, PATH, PATH_FAILED
} from './actionTypes';
import {
    getEventPathAPI, getScreenPathAPI
} from '../../../../../../api';

export const getEventPath = (appId, startEvent, endEvent, size, limit, days, uninstall, filters = {}) => {
    return (dispatch, getState) => {
        let queryParams = {...getState().filters, day: days, startEvent, endEvent, size, limit, uninstall};
        return dispatch({
            types: [
                PATH_PENDING, PATH, PATH_FAILED
            ],
            payload: {
                promise: getEventPathAPI(appId, getState().auth, queryParams, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getScreenPath = (appId, startScreen, endScreen, size, limit, days, uninstall, filters = {}) => {
    return (dispatch, getState) => {
        let queryParams = {...getState().filters, day: days, startScreen, endScreen, size, limit, uninstall};
        return dispatch({
            types: [
                PATH_PENDING, PATH, PATH_FAILED
            ],
            payload: {
                promise: getScreenPathAPI(appId, getState().auth, queryParams, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @returns {function(*, *)}
 */
export const resetPath = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_PATH,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};