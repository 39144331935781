/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    RESET_CONTEXTS,
    CONTEXTS_PENDING, CONTEXTS, CONTEXTS_FAILED,
    CONTEXT_PENDING, CONTEXT, CONTEXT_FAILED,
    SAVE_CONTEXT_PENDING, SAVE_CONTEXT, SAVE_CONTEXT_FAILED,
    UPDATE_CONTEXT_PENDING, UPDATE_CONTEXT, UPDATE_CONTEXT_FAILED,
    DELETE_CONTEXT_PENDING, DELETE_CONTEXT, DELETE_CONTEXT_FAILED, UPDATE_LOCAL_CONTEXT
} from './actionTypes';
import {
    getContextsAPI, getContextAPI, saveContextAPI,
    updateContextAPI, deleteContextAPI
} from './api';
import {APP_CONTEXTS} from "../../Apps/App/actionTypes";

export const getContexts = (appId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        return dispatch({
            types: [
                CONTEXTS_PENDING, CONTEXTS, CONTEXTS_FAILED
            ],
            payload: {
                promise: getContextsAPI(appId, getState().auth, filters)
                    .then((res) => {
                        dispatch({type: APP_CONTEXTS, payload: res}); //Updating to global app state
                        return res;
                    })
            },
            meta: {

            }
        });
    };
};

export const getContext = (appId, contextId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        return dispatch({
            types: [
                CONTEXT_PENDING, CONTEXT, CONTEXT_FAILED
            ],
            payload: {
                promise: getContextAPI(appId, getState().auth, contextId, filters)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {

            }
        });
    };
};

export const saveContext = (appId, context) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        return dispatch({
            types: [
                SAVE_CONTEXT_PENDING, SAVE_CONTEXT, SAVE_CONTEXT_FAILED
            ],
            payload: {
                promise: saveContextAPI(appId, getState().auth, filters, context)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {

            },
            callbacks: {
                successWillDispatch: (dispatch, data) => {
                    dispatch(getContexts(appId));
                }
            }
        });
    };
};

export const updateContext = (appId, contextId, context) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        return dispatch({
            types: [
                UPDATE_CONTEXT_PENDING, UPDATE_CONTEXT, UPDATE_CONTEXT_FAILED
            ],
            payload: {
                promise: updateContextAPI(appId, getState().auth, contextId, filters, context)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {

            },
            callbacks: {
                successWillDispatch: (dispatch, data) => {
                    dispatch(getContexts(appId));
                }
            }
        });
    };
};

export const deleteContext = (appId, contextId) => {
    return (dispatch, getState) => {
        const filters = { ...getState().filters };
        return dispatch({
            types: [
                DELETE_CONTEXT_PENDING, DELETE_CONTEXT, DELETE_CONTEXT_FAILED
            ],
            payload: {
                promise: deleteContextAPI(appId, getState().auth, contextId, filters)
                    .then((res) => {
                        return res;
                    })
            },
            meta: {

            },
            callbacks: {
                successWillDispatch: (dispatch, data) => {
                    dispatch(getContexts(appId));
                }
            }
        });
    };
};


/**
 *
 * @returns {function(*, *)}
 */
export const resetContexts = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_CONTEXTS,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};

export const updateLocalContext = (context) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_LOCAL_CONTEXT,
            payload: context,
            meta: {
                //If Any
            }
        });
    };
};

