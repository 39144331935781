import React, { Component } from 'react';
import {
    getScreenPath, getEventPath
} from '../actions';
import withSelfComponent from './SelfComponent';
import {session} from "../../../../../../../utils/Storage";
import Box from "../../../../../../../components/reusable/Box";
import PathGraph from "../../../../../../../components/reusable/Path";
import {PATH_ENUM} from "../../../../../../../constants";
import Grid from "@material-ui/core/Grid";
import TransferWithinAStation from "@material-ui/icons/TransferWithinAStation";

class Path extends Component {

    hydrator = (props = {}) => {
        const {
            dataKey, extraFilters, pathType, queryParams
        } = props.selfParams || {};
        const {dispatch, params: {appId}} = props;
        const getPath = pathType === PATH_ENUM.EVENT ? getEventPath : getScreenPath;
        dispatch(getPath(appId, dataKey, extraFilters, queryParams));
    };

    render(){
        const {
            self, selfParams: { dataKey, customLabel, queryParams: { start, end, size } } = {},
            meta: { api_pending }, parentCompLabel
        } = this.props;
        if(!api_pending){
            let obj = {};
            obj[customLabel] = `${self[dataKey]}`;
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        const { data = [], sample=100 } = self[dataKey] || {};
        return (
            <Box icon={<TransferWithinAStation/>} title={customLabel}>
                <PathGraph data={data} sample={sample} start={start} end={end} size={size} />
            </Box>
        )
    };
}

Path.propTypes = {

};

export default withSelfComponent(Path);