/**
 * Created by jyothi on 12/10/17.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    getAttributes, getAttributeValues,
    getEvents, getEventAttributes, getEventAttributeValues,
    updateQuery, saveSegment, getSegmentCount, resetQuery
} from './actions';
import {updateHeading, resetHeading } from '../../../Navigation/actions';
import {
    SegmentBuilder,
    PropertiesFilter
} from './components';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        meta: state.meta,
        filters: state.filters,
        segmentBuilder: state.segmentBuilder
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetHeading: () => dispatch(resetHeading()),
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        getAttributes: (appId) => dispatch(getAttributes(appId)),
        getAttributeValues: (appId, attribute, q) => dispatch(getAttributeValues(appId, attribute, q)),
        getEvents: (appId) => dispatch(getEvents(appId)),
        getEventAttributes: (appId, event) => dispatch(getEventAttributes(appId, event)),
        getEventAttributeValues: (appId, event, attribute, q) => dispatch(getEventAttributeValues(appId, event, attribute, q)),
        handleQueryUpdate: (query) => dispatch(updateQuery(query)),
        saveSegment: (appId) => dispatch(saveSegment(appId)),
        getSegmentCount: (appId) => dispatch(getSegmentCount(appId)),
        resetQuery: () => dispatch(resetQuery())
    };
}

class Segment extends Component {

    constructor(props) {

        super(props);
        this.appId = this.props.params.appId;
        this.state = {

        }

    }

    componentWillMount(){
        const {
            forFunnels, onlyProperties,
            updateHeading, getAttributes, getEvents, getSegmentCount
        } = this.props;
        getAttributes(this.appId); //user and session attributes
        if(!forFunnels){
            updateHeading("New Segment");
            getSegmentCount(this.appId);
        }
        if(!onlyProperties){
            getEvents(this.appId);
        }
    }

    componentWillUnmount(){
        const {
            forFunnels = false
        } = this.props;
        if(!forFunnels){
            this.props.resetQuery();
        }
    }

    render() {
        const {
            segmentBuilder: { query }, forFunnels = false, onlyProperties = false
        } = this.props;
        if(forFunnels && onlyProperties){
            return <PropertiesFilter {...this.props}  {...query}/>;
        }
        else{
            return (
                <section className="content">
                    <SegmentBuilder {...this.props} {...query}/>
                </section>
            );
        }
    }
}

Segment.propTypes = {
    forFunnels: PropTypes.bool,
    onlyProperties: PropTypes.bool
};

Segment.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Segment);