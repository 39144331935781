/**
 * Created by jyothi on 12/10/17.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    updateMetrics, updateDimensions, updateUserGroup,
    getExplorer, resetCustomReport, updateQuery,
    getFlatTable, getFlatTableForId,
    saveCustomReport, deleteCustomReport,
    getCustomReport, getCustomReports, updateCustomReport, updateFilters
} from './actions';
import { updateHeading } from '../../Navigation/actions';
import { CustomReportHolder }  from "./components.js";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        overview: state.overview,
        customOverview: state.eventAnalysis,
        queryBuilder: state.queryBuilder,
        meta: state.meta,
        filters: state.filters,
        customReport: state.customReport
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        updateUserGroup: (group) => dispatch(updateUserGroup(group)),
        updateMetrics: (metrics) => dispatch(updateMetrics(metrics)),
        updateDimensions: (dimensions) => dispatch(updateDimensions(dimensions)),
        updateFilters: (filters) => dispatch(updateFilters(filters)),
        updateQuery: (query) => dispatch(updateQuery(query)),
        getExplorer: (appId) => dispatch(getExplorer(appId)),
        getFlatTable: (appId) => dispatch(getFlatTable(appId)),
        getFlatTableForId: (appId, reportId) => dispatch(getFlatTableForId(appId, reportId)),
        saveCustomReport: (appId, name) => dispatch(saveCustomReport(appId, name)),
        deleteCustomReport: (appId, reportId) => dispatch(deleteCustomReport(appId, reportId)),
        getCustomReport: (appId, reportId) => dispatch(getCustomReport(appId, reportId)),
        getCustomReports: (appId) => dispatch(getCustomReports(appId)),
        updateCustomReport: (reportId) => dispatch(updateCustomReport(reportId)),
        resetData: () => dispatch(resetCustomReport())
    };
}

class CustomReports extends Component {

    componentWillMount(){
        const { params: { appId }, updateHeading, getCustomReports } = this.props;
        updateHeading("Custom Reports");
        getCustomReports(appId);
    }

    componentWillUnmount(){
        this.props.resetData();
    }

    render() {
        return (
            <section className="content">
                <CustomReportHolder {...this.props}/>
            </section>
        );
    }
}

CustomReports.propTypes = {

};

CustomReports.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomReports);