import * as EVENTS_MGMT_A_T from "./actionTypes";
import {CLIENT_EVENT} from "./constants";

const GET_CLIENT_EVENTS_FLAG_STATE = {
    get_client_events_failed: false,
    get_client_events_pending: false
}

const EDIT_CLIENT_EVENTS_FLAG_STATE = {
    edit_client_events_failed: false,
    edit_client_events_pending: false
}
const ADD_CLIENT_EVENTS_FLAG_STATE = {
    add_client_events_failed: false,
    add_client_events_pending: false
}

const DELETE_CLIENT_EVENTS_FLAG_STATE = {
    delete_client_events_failed: false,
    delete_client_events_pending: false
}

const INITIAL_STATE = {
    pagination: {
        after: null,
        before: null,
        hasNext: false,
        hasPrev: false,
        limit: 10,
        pageCounter: 0,
        totalResults: 0,
    },
    filters: {
        event_type: CLIENT_EVENT,
        event_name: undefined,
        attr_name: undefined,
        attr_type: undefined,
        app_version: undefined,
        platform: undefined,
        source: undefined,
    },
    rows: [],
    dialog: {
        open: false,
        dialog_type: undefined,
    },
    ...GET_CLIENT_EVENTS_FLAG_STATE,
    ...ADD_CLIENT_EVENTS_FLAG_STATE,
    ...EDIT_CLIENT_EVENTS_FLAG_STATE,
    ...DELETE_CLIENT_EVENTS_FLAG_STATE,
};

export const eventsManagementReducer = (state = INITIAL_STATE, action = {}) => {
    let {payload, meta} = action;
    switch (action.type) {
        //    Get
        case EVENTS_MGMT_A_T.EM_GET_CLIENT_EVENTS:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    after: payload.after,
                    before: payload.before,
                    hasNext: payload.has_next,
                    hasPrev: payload.has_prev,
                    totalResults: payload.total,
                },
                rows: payload.rows,
                get_client_events_pending: false,
                get_client_events_failed: false,
            }
        case EVENTS_MGMT_A_T.EM_GET_CLIENT_EVENTS_PENDING:
            return {
                ...state,
                get_client_events_pending: true,
                get_client_events_failed: false,
            }
        case EVENTS_MGMT_A_T.EM_GET_CLIENT_EVENTS_FAILED:
            return {
                ...state,
                get_client_events_pending: false,
                get_client_events_failed: true,
            }

        case EVENTS_MGMT_A_T.PAGINATION_ACTION:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    [payload.key]: payload.value,
                    pageCounter: 0
                }
            }
        case EVENTS_MGMT_A_T.UPDATE_PAGE_COUNTER:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pageCounter: payload.pageCounter,
                }
            }
        case EVENTS_MGMT_A_T.FILTERS_CHANGED:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pageCounter: 0,
                },
                filters: {
                    ...state.filters,
                    [payload.key]: payload.value,
                }
            }
        case EVENTS_MGMT_A_T.MODAL_STATE_CHANGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    pageCounter: meta.reset_pagination ? 0 : state.pagination.pageCounter,
                },
                dialog: {
                    open: payload.open,
                    dialog_type: payload.type,
                }
            }

        //    Edit
        case EVENTS_MGMT_A_T.EDIT_CLIENT_EVENT_FAILED:
            return {
                ...state,
                edit_client_events_failed: true,
                edit_client_events_pending: false,
            };
        case EVENTS_MGMT_A_T.EDIT_CLIENT_EVENT:
            return {
                ...state,
                edit_client_events_pending: false,
                edit_client_events_failed: false,
            };
        case EVENTS_MGMT_A_T.EDIT_CLIENT_EVENT_PENDING:
            return {
                ...state,
                edit_client_events_failed: false,
                edit_client_events_pending: true,
            };

        //    Add
        case EVENTS_MGMT_A_T.ADD_CLIENT_EVENT_FAILED:
            return {
                ...state,
                add_client_events_failed: true,
                add_client_events_pending: false,
            };
        case EVENTS_MGMT_A_T.ADD_CLIENT_EVENT:
            return {
                ...state,
                add_client_events_pending: false,
                add_client_events_failed: false,
            };
        case EVENTS_MGMT_A_T.ADD_CLIENT_EVENT_PENDING:
            return {
                ...state,
                add_client_events_failed: false,
                add_client_events_pending: true,
            };


        //    Delete
        case EVENTS_MGMT_A_T.DELETE_CLIENT_EVENT_FAILED:
            return {
                ...state,
                delete_client_events_failed: true,
                delete_client_events_pending: false,
            };
        case EVENTS_MGMT_A_T.DELETE_CLIENT_EVENT:
            return {
                ...state,
                delete_client_events_pending: false,
                delete_client_events_failed: false,
            };
        case EVENTS_MGMT_A_T.DELETE_CLIENT_EVENT_PENDING:
            return {
                ...state,
                delete_client_events_failed: false,
                delete_client_events_pending: true,
            };

        case EVENTS_MGMT_A_T.RESET_EV_STATE:
            return {
                ...INITIAL_STATE,
            }
        default:
            return state;
    }
}