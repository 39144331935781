import React, { Fragment } from "react";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  BarChart,
  Bar,
  Tooltip,
  LabelList,
} from "recharts";
import Typography from "@material-ui/core/Typography";
import Box from "../../../../../../components/reusable/Box";
import Grid from "@material-ui/core/Grid";
import {
  areArraysEqual,
  getGroupFromList,
  getGroupNameFromList,
  goToByScroll,
  minMaxAverageOf,
  randomColorWithIndex,
  roundOffNumber,
  toTimeSpent,
} from "../../../../../../utils";
import NewSegment from "../Segments/NewSegment";
import Switch from "../../../../../../components/reusable/Switch";
import SortableDataTable from "../../../../../../components/reusable/SortableDataTable";
import AddToDashboard from "../CustomDashboards/AddToDashboard";
import { FUNNEL_WIDGET } from "../SelfComponents";
import SegmentConfig from "../Segments/SegmentConfig";
import MultiSelect from "../../../../../../components/reusable/MaterialUi/MultiSelect";
import Button from "@material-ui/core/Button/Button";
import Snackbar from "../../../../../../components/reusable/MaterialUi/Snackbar";
import { Snackbar as MaterialSnackbar } from "@material-ui/core";
import Loading from "../../../../../../components/reusable/Loading";
import {
  CONDTIONAL_OPERATORS,
  LOGICAL_OPERATORS,
  APXOR_SDK_CATEGORY_CONSTANTS,
} from "../../../../../../constants";
import FilterIcon from "@material-ui/icons/FilterList";
import DeleteIcon from "@material-ui/icons/Delete";
import SaveIcon from "@material-ui/icons/Save";
import { default as MaterialTooltip } from "@material-ui/core/Tooltip";
import RemoveIcon from "@material-ui/icons/Clear";
import {
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { EventBuilder } from "../Segments/NewSegment/components";
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import DoneIcon from "@material-ui/icons/Done";
import TextField from "@material-ui/core/TextField";
import Dialog from "../../../../../../components/reusable/MaterialUi/Dialog";
import Placeholder from "../../../../../../components/reusable/Placeholder";
import MultiUtilityChart from "../../../../../../components/reusable/Recharts/MultiUtilityChart";
import Apxor from "apxor";
import HighCharts from "highcharts";
import patternFill from "highcharts/modules/pattern-fill";
import HighChartsReact from "highcharts-react-official";
import {
  createCohortFromFunnel,
  toggleDialog,
  toggleFunnelSnackbar,
} from "./actions";
import DownloadIcon from "@material-ui/icons/CloudDownload";

const FUNNEL_GRAPH_ID = "funnel-graph";

const OVERVIEW_KEY = "Overview";
const renderCustomizedLabel = (props) => {
  const { index, x, y, value } = props;
  const xR = 40,
    yR = 20;
  if (index) {
    return (
      <g>
        <ellipse
          cx={x - xR}
          cy={y - yR}
          rx={xR}
          ry={yR}
          fill={randomColorWithIndex(7)}
        />
        <text
          x={x - xR}
          y={y - yR}
          fill="#000000"
          textAnchor="middle"
          dominantBaseline="middle"
        >
          {roundOffNumber(value, 1)} % &#10095;
        </text>
      </g>
    );
  }
};

const StripedBar = (props) => {
  const { x: oX, y: oY, width: oWidth, height: oHeight, value, fill } = props;

  let x = oX;
  let y = oHeight < 0 ? oY + oHeight : oY;
  let width = oWidth;
  let height = Math.abs(oHeight);

  return (
    <rect
      fill={fill}
      mask="url(#mask-stripe)"
      x={x}
      y={y}
      width={width}
      height={height}
    />
  );
};

const funnelBehavior_ENUM = {
  DID: "did",
  DROPPED: "dropped",
};

const transformToHighCharts = (data, setContextElement) => {
  const { categories, did, dropped, eventNames } = data.reduce(
    (result, item) => {
      result["categories"] = result["categories"].concat(item.name);
      result[funnelBehavior_ENUM.DID] = result[funnelBehavior_ENUM.DID].concat(
        item.count === 0 ? null : item.count
      );
      result[funnelBehavior_ENUM.DROPPED] = result[
        funnelBehavior_ENUM.DROPPED
      ].concat(
        item[funnelBehavior_ENUM.DROPPED] === 0
          ? null
          : item[funnelBehavior_ENUM.DROPPED]
      );
      result["eventNames"] = result["eventNames"].concat(item.name);
      return result;
    },
    { categories: [], dropped: [], did: [], eventNames: [] }
  );

  return {
    chart: {
      type: "column",
    },
    title: {
      text: eventNames.join(" > "),
    },
    xAxis: {
      categories,
    },
    yAxis: {
      title: "Users",
    },
    plotOptions: {
      column: {
        cursor: "pointer",
        stacking: "normal",
        dataLabels: { enabled: true },
      },
    },
    legend: {
      enabled: true,
    },
    series: [
      {
        y: 1,
        color: {
          pattern: {
            path: {
              d: "M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11",
              strokeWidth: 4,
            },
            width: 10,
            height: 10,
            opacity: 0.4,
            color: randomColorWithIndex(0),
          },
        },
        name: funnelBehavior_ENUM.DROPPED,
        type: "column",
        data: dropped,
        cursor: "pointer",
        events: {
          click: (event) => {
            const stepIndex = event.point.index;
            setContextElement(
              event.target,
              funnelBehavior_ENUM.DROPPED,
              stepIndex
            );
          },
        },
      },
      {
        name: funnelBehavior_ENUM.DID,
        data: did,
        type: "column",
        cursor: "pointer",
        events: {
          click: (event) => {
            const stepIndex = event.point.index;
            setContextElement(event.target, funnelBehavior_ENUM.DID, stepIndex);
          },
        },
      },
    ],
  };
};

const getStepIndex = (behaviorType, level) => {
  return (behaviorType === funnelBehavior_ENUM.DROPPED ? -1 : 1) * (level + 1);
};

export class FunnelGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showGraphContextMenu: false,
      graphContextEl: null,
      funnelBehaviorType: funnelBehavior_ENUM.DROPPED,
      showCohortCreationModal: false,
      cohortName: "",
      funnelSelectedStep: null,
    };
    this.bindedDispatch = this.props.bindedDispatch;
  }

  componentDidMount() {
    goToByScroll(FUNNEL_GRAPH_ID);
  }

  showContextMenu = (element, behavior, stepIndex) => {
    if (stepIndex !== 0) {
      this.setState({
        graphContextEl: element,
        funnelBehaviorType: behavior,
        funnelSelectedStep: stepIndex,
      });
    }
  };

  handleClose = () => {
    this.setState({
      graphContextEl: null,
      cohortName: "",
    });
  };

  handleMenuItemClick = () => {
    this.setState(
      {
        graphContextEl: null,
        showCohortCreationModal: true,
        cohortName: "",
      },
      () => {
        this.bindedDispatch(toggleDialog());
      }
    );
  };

  dismissModal = () => {
    this.setState(
      {
        showCohortCreationModal: false,
        cohortName: "",
      },
      () => {
        this.bindedDispatch(toggleDialog());
      }
    );
  };

  render() {
    const {
      funnels: {
        graph: { funnels = [], conversion_rate } = {},
        progress: {
          create_cohort_pending,
          create_cohort_failed,
          show_funnel_dialog,
          show_funnel_snackbar,
          snackbar_message,
        },
      },
      handleCurrentGroupByUpdate,
      currentGroupBy,
      bindedDispatch,
    } = this.props;
    const {
      params: { appId },
    } = this.props;
    const chartOptions = transformToHighCharts(funnels, this.showContextMenu);
    const {
      graphContextEl,
      showCohortCreationModal,
      funnelBehaviorType,
      cohortName,
      funnelSelectedStep,
    } = this.state;

    return (
      <div id={FUNNEL_GRAPH_ID}>
        <Box
          title="Funnel"
          footer={
            <Switch
              data={[OVERVIEW_KEY, ...funnels.map((o) => o.name)]}
              handleChange={handleCurrentGroupByUpdate}
              value={currentGroupBy}
            />
          }
          controls={
            <div style={{ display: "flex" }}>
              <Typography component="span" variant="subheading">
                <Typography variant="caption">
                  Overall Conversion Rate{" "}
                </Typography>
                {roundOffNumber(conversion_rate)}
              </Typography>
            </div>
          }
        >
          <HighChartsReact
            highcharts={patternFill(HighCharts)}
            options={chartOptions}
          />
          <Menu
            id="graph-context-menu"
            anchorEl={graphContextEl}
            open={Boolean(graphContextEl)}
            onClose={this.handleClose}
          >
            <MenuItem onClick={this.handleMenuItemClick}>
              Create Cohort
            </MenuItem>
          </Menu>

          {show_funnel_dialog && (
            <MaterialDialog
              disableEscapeKeyDown={false}
              disableBackdropClick={false}
              open={show_funnel_dialog}
              scroll={"paper"}
              onClose={() => {
                this.setState({ showCohortCreationDialog: false }, () => {
                  this.bindedDispatch(toggleDialog());
                });
              }}
            >
              <DialogTitle>Create Cohort from Funnel</DialogTitle>
              <DialogContent>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="funnel behavior type"
                    name="funnel_behavior_type"
                    value={funnelBehaviorType}
                    onChange={(event) => {
                      this.setState({
                        funnelBehaviorType: event.target.value,
                      });
                    }}
                  >
                    <FormControlLabel
                      value={funnelBehavior_ENUM.DID}
                      control={<Radio color={"primary"} />}
                      label={funnelBehavior_ENUM.DID}
                    />
                    <FormControlLabel
                      value={funnelBehavior_ENUM.DROPPED}
                      control={<Radio color={"primary"} />}
                      label={funnelBehavior_ENUM.DROPPED}
                    />
                  </RadioGroup>
                </FormControl>
                <TextField
                  label={"Cohort Name"}
                  fullWidth={true}
                  value={cohortName}
                  onChange={(e) => {
                    this.setState({ cohortName: e.target.value });
                  }}
                />
              </DialogContent>
              <DialogActions style={{ justifyContent: "space-between" }}>
                <Button onClick={this.dismissModal}>Cancel</Button>
                <Button
                  variant={"outlined"}
                  disabled={create_cohort_pending}
                  onClick={() => {
                    const funnelConfig = {
                      name: cohortName,
                      level: getStepIndex(
                        funnelBehaviorType,
                        funnelSelectedStep
                      ),
                    };
                    bindedDispatch(createCohortFromFunnel(appId, funnelConfig));
                  }}
                >
                  {create_cohort_pending && (
                    <Loading size={12} style={{ marginRight: 12 }} />
                  )}{" "}
                  Create Cohort
                </Button>
              </DialogActions>
            </MaterialDialog>
          )}

          {show_funnel_snackbar && (
            <MaterialSnackbar
              open={show_funnel_snackbar}
              autoHideDuration={3000}
              onClose={() => {
                this.bindedDispatch(toggleFunnelSnackbar(false));
              }}
              message={
                snackbar_message !== ""
                  ? snackbar_message
                  : "Cohort Creation Success"
              }
            />
          )}
        </Box>
      </div>
    );
  }
}

const makeSortableTableData = (funnels = [], groupByData = []) => {
  let keys = [],
    data = [];
  if (funnels.length > 0 && groupByData.length > 0) {
    keys = ["attribute", ...groupByData[0].funnels.map((o) => o.name)];
    data = groupByData.map((item) => {
      const obj = { attribute: item.attribute };
      item.funnels.forEach((el) => {
        obj[el.name] = `${el.count} (${roundOffNumber(el.percent)} %)`;
      });
      return obj;
    });
  }
  return { keys, data };
};

const comparator = (a, b, isASC = false) => {
  const valueA = Number(a.replace(/\(.*\)/, "").trim());
  const valueB = Number(b.replace(/\(.*\)/, "").trim());
  let sortVal = 0;
  if (valueA > valueB) {
    sortVal = 1;
  }
  if (valueA < valueB) {
    sortVal = -1;
  }
  if (sortVal !== 0 && isASC) {
    sortVal = sortVal * -1;
  }
  return sortVal;
};

const getPercent = (str) => {
  const matcher = str.match(/\(.+ %/);
  if (matcher) {
    return Number(matcher[0].slice(1, -1).trim());
  } else return 0;
};

const percentComparator = (a, b, isASC = false) => {
  const valueA = getPercent(a);
  const valueB = getPercent(b);
  let sortVal = 0;
  if (valueA > valueB) {
    sortVal = 1;
  }
  if (valueA < valueB) {
    sortVal = -1;
  }
  if (sortVal !== 0 && isASC) {
    sortVal = sortVal * -1;
  }
  return sortVal;
};

export class FunnelsGroupedByOpportunityTable extends React.PureComponent {
  state = {
    attributes: [],
    needUpdate: false,
  };

  attributes = [];

  componentWillReceiveProps(nextProps) {
    const { meta } = nextProps;
    //reloading data for updated filters
    const {
      params: { appId },
      meta: { globalFiltersChanged },
      getFunnelOpportunity,
    } = this.props;
    if (
      meta.globalFiltersChanged &&
      globalFiltersChanged !== meta.globalFiltersChanged
    ) {
      getFunnelOpportunity(appId, this.state.attributes);
    }
    if (!this.state.needUpdate) {
      const { opportunity = [] } = this.props;
      if (opportunity.length !== nextProps.opportunity.length) {
        this.setState({ needUpdate: true });
      } else {
        this.setState({ needUpdate: false });
      }
    }
  }

  handleApply = () => {
    const {
      params: { appId },
      getFunnelOpportunity,
    } = this.props;
    const { attributes } = this.state;

    getFunnelOpportunity(appId, attributes);
    this.attributes = attributes;
  };

  render() {
    const {
      funnels: {
        graph: { funnels = [] } = {},
        query: { group_by = [] } = {},
        opportunity,
        opportunity_pending,
        opportunity_failed,
      },
      properties,
    } = this.props;
    const groupedByProperty = "Property (Value)";
    const { keys = [], data = [] } = makeSortableTableData(
      funnels,
      opportunity
    );
    const headerLabels = funnels.map((o) => o.name);
    const comparators = headerLabels.reduce((a, b) => {
      a[b] = percentComparator;
      return a;
    }, {});
    const { attributes } = this.state;
    return (
      <Box title="Quick View">
        <Grid container spacing={16}>
          <Grid item xs={12} md={10}>
            <MultiSelect
              options={properties.map((o) => ({ label: o, value: o }))}
              handleChange={(attributes) => {
                this.setState({ attributes });
              }}
              value={attributes}
              margin="normal"
              fullWidth
            />
          </Grid>
          <Grid item xs={4} md={2}>
            <Button
              variant="contained"
              onClick={() => {
                Apxor.logEvent(
                  "QuickViewApplied",
                  { attribute: attributes },
                  "Funnels"
                );
                this.handleApply();
              }}
              disabled={areArraysEqual(this.attributes, attributes)}
              style={{ margin: 16 }}
              color="primary"
            >
              Apply
            </Button>
          </Grid>
          <Grid item xs={12}>
            {opportunity_pending && <Loading size={100} />}
            {opportunity_pending && (
              <Typography style={{ textAlign: "center" }} variant="caption">
                This may take a while.
              </Typography>
            )}
            {opportunity_failed && (
              <Snackbar>Opportunity failed loading.</Snackbar>
            )}
            {!opportunity_failed && !opportunity_pending && data.length > 0 && (
              <SortableDataTable
                {...this.props}
                data={data}
                headerLabels={[groupedByProperty, ...headerLabels]}
                keys={keys}
                withoutBox
                withoutSerialNumbers
                needUpdate={this.state.needUpdate}
                comparators={comparators}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export class FunnelsGroupedByTable extends React.PureComponent {
  state = {
    needUpdate: false,
  };

  componentWillReceiveProps(nextProps) {
    if (!this.state.needUpdate) {
      const { groupByData = [] } = this.props;
      if (groupByData.length !== nextProps.groupByData.length) {
        this.setState({ needUpdate: true });
      } else {
        this.setState({ needUpdate: false });
      }
    }
  }

  render() {
    const {
      funnels: { graph: { funnels = [] } = {}, query: { group_by = [] } = {} },
      groupByData = [],
    } = this.props;
    let groupedByProperty = "";
    if (group_by && group_by.length > 0) {
      groupedByProperty = group_by[group_by.length - 1].name;
    }
    const { keys = [], data = [] } = makeSortableTableData(
      funnels,
      groupByData
    );
    const headerLabels = funnels.map((o) => o.name);
    const comparators = headerLabels.reduce((a, b) => {
      a[b] = comparator;
      return a;
    }, {});
    return (
      <Grid container spacing={16}>
        <Grid item xs>
          <SortableDataTable
            {...this.props}
            data={data}
            headerLabels={[groupedByProperty, ...headerLabels]}
            keys={keys}
            withoutBox
            withoutSerialNumbers
            needUpdate={this.state.needUpdate}
            comparators={comparators}
          />
        </Grid>
      </Grid>
    );
  }
}

export class FunnelGroupedBy extends React.Component {
  render() {
    const {
      params: { appId },
      getFunnelGroupedBy,
      funnels: { graph: { funnels = [] } = {}, group_by: groupedBy = [] },
      updateFunnelQuery,
      currentGroupBy,
      segmentBuilder: {
        attribute_values,
        event_attributes = {},
        event_attribute_values = {},
      },
      getAttributeValues,
      getEventAttributeValues,
      properties,
      getFunnelDownload,
      getFunnelGroupedByDownload
    } = this.props;
    const groupByData =
      groupedBy.length > 0
        ? groupedBy
        : [{ attribute: "Overall", funnels: funnels }];
    const propertyValues =
      currentGroupBy === OVERVIEW_KEY
        ? attribute_values
            : event_attribute_values[currentGroupBy];
            
    const handleDownload = () => {
      //console.log(group_by);
      console.log(groupedBy);
      console.log(groupByData);
      if (groupedBy.length > 0) {
        getFunnelGroupedByDownload(appId);
      }else{
        getFunnelDownload(appId);
      }
    }
      
    return (
      <Box title={currentGroupBy}>
        {
          <Button
            style={{ marginTop: 8, float: "right" }}
            size="small"
            variant="contained"
            color="primary"
            onClick={handleDownload}
          >
            <DownloadIcon />
             Download Funnel
          </Button>
        }
        <NewSegment
          {...this.props}
          forFunnels
          onlyProperties
          onQueryUpdate={(query) => {
            Apxor.logEvent(
              "GroupByFilterClicked",
              {},
              APXOR_SDK_CATEGORY_CONSTANTS.FUNNELS
            );
            updateFunnelQuery(query);
            if (Array.isArray(query.group_by) && query.group_by.length > 0) {
              getFunnelGroupedBy(appId);
            }
          }}
          properties={properties}
          propertyValues={propertyValues}
          getRecommendations={(attribute, q) => {
            if (currentGroupBy === OVERVIEW_KEY) {
              getAttributeValues(appId, attribute, q);
            } else {
              getEventAttributeValues(appId, currentGroupBy, attribute, q);
            }
          }}
        />
        <FunnelsGroupedByTable {...this.props} groupByData={groupByData} />
      </Box>
    );
  }
}

const TIME_SLOTS = [
  {
    label: "1 Min",
    value: 60,
  },
  {
    label: "5 Mins",
    value: 5 * 60,
  },
  {
    label: "15 Mins",
    value: 15 * 60,
  },
  {
    label: "30 Mins",
    value: 30 * 60,
  },
  {
    label: "1 Hour",
    value: 60 * 60,
  },
  {
    label: "1 Day",
    value: 24 * 60 * 60,
  },
  {
    label: "3 Days",
    value: 3 * 24 * 60 * 60,
  },
  {
    label: "1 Week",
    value: 7 * 24 * 60 * 60,
  },
  {
    label: "2 Weeks",
    value: 14 * 24 * 60 * 60,
  },
  {
    label: "3 Weeks",
    value: 21 * 24 * 60 * 60,
  },
  {
    label: "30 Days",
    value: 30 * 24 * 60 * 60,
  },
  {
    label: "60 Days",
    value: 60 * 24 * 60 * 60,
  },
  {
    label: "90 Days",
    value: 90 * 24 * 60 * 60,
  },
];

export class FunnelBuilder extends React.Component {
  constructor(props) {
    super(props);
    const {
      event = [],
      condition = CONDTIONAL_OPERATORS.AND,
      params: { appId },
      funnels: { funnels_list = [] },
      selectedFunnel = null,
    } = props;
    this.state = {
      time: 5 * 60,
      steps: event,
      saveClicked: false,
      saveFunnelTriggered: false,
      deleteClicked: false,
      deleteFunnelTriggered: false,
      funnelName: "",
      title: "New Funnel",
      condition,
      funnels_list,
      selectedFunnel,
    };
    this.appId = appId;
  }

  handleUpdate = () => {
    const { steps, time } = this.state;
    const { handleQueryUpdate, updateFunnelQuery } = this.props;
    handleQueryUpdate({ event: steps });
    updateFunnelQuery({ events: steps, time });
    this.setState({ saveClicked: false });
  };

  addEvent = (countValue = 1) => {
    const newStep = {
      name: "",
      count: {
        value: countValue,
        operator:
          countValue === 1 ? LOGICAL_OPERATORS.GTE : LOGICAL_OPERATORS.EQ,
      },
      attributes: [],
    };
    Apxor.logEvent("AddStepClicked", {}, APXOR_SDK_CATEGORY_CONSTANTS.FUNNELS);
    this.setState({ steps: [...this.state.steps, newStep] });
  };

  handleStepUpdate = (index) => (step) => {
    const { steps } = this.state;
    Apxor.logEvent("EnterEventStep", {}, APXOR_SDK_CATEGORY_CONSTANTS.FUNNELS);
    this.setState(
      { steps: [...steps.slice(0, index), step, ...steps.slice(index + 1)] },
      this.handleUpdate
    );
  };

  handleStepDelete = (index) => () => {
    const { steps } = this.state;
    this.setState(
      { steps: [...steps.slice(0, index), ...steps.slice(index + 1)] },
      this.handleUpdate
    );
  };

  handleAddStepAfter = (index) => () => {
    const { steps } = this.state;
    const newStep = {
      name: "",
      count: {
        value: index + 1,
        operator: index === 1 ? LOGICAL_OPERATORS.GTE : LOGICAL_OPERATORS.EQ,
      },
      attributes: [],
    };
    this.setState(
      {
        steps: [
          ...steps.slice(0, index + 1),
          newStep,
          ...steps.slice(index + 1),
        ],
      },
      this.handleUpdate
    );
  };

  handleSave = () => {
    Apxor.logEvent(
      "GetFunnelClicked",
      {},
      APXOR_SDK_CATEGORY_CONSTANTS.FUNNELS
    );
    this.setState({ saveClicked: true });
    const { handleSave = () => null } = this.props;
    handleSave();
    const {
      updateFunnelComparables,
      funnels: { funnels_list },
    } = this.props;
    const { selectedFunnel } = this.state;
    selectedFunnel && updateFunnelComparables(this.appId, [selectedFunnel]);
  };

  handleFunnelSaveButtonClicked = () => {
    this.setState({ saveFunnelTriggered: true });
  };

  handleFunnelSave = () => {
    const { saveFunnel } = this.props;
    const { funnelName = "" } = this.state;
    saveFunnel(this.appId, funnelName);
    Apxor.logEvent(
      "SaveFunnelClicked",
      {},
      APXOR_SDK_CATEGORY_CONSTANTS.FUNNELS
    );
    this.setState({ saveFunnelTriggered: false, funnelName: "" });
  };

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.funnels.query.hasOwnProperty("events") &&
      this.props.funnels.query.hasOwnProperty("events") &&
      nextProps.funnels.query.events.length === 0 &&
      this.state.selectedFunnel
    ) {
      this.setState(
        {
          selectedFunnel: null,
          time: 300,
          steps: [],
          saveClicked: false,
          saveFunnelTriggered: false,
          funnels_list: nextProps.funnels.funnels_list,
          funnelName: "",
          title: "New Funnel",
        },
        () => {
          const { updateFunnelQuery } = this.props;
          updateFunnelQuery({ events: [], time: 300 });
        }
      );
    }
  }

  render() {
    const {
      time,
      steps,
      selectedFunnel,
      title,
      saveClicked,
      funnelName,
      saveFunnelTriggered,
      deleteFunnelTriggered,
      deleteClicked,
    } = this.state;
    const {
      funnels: { funnels_list = [] },
    } = this.props;
    return (
      <Box
        collapsible
        title={title}
        defaultExpanded
        controls={
          <Fragment>
            {selectedFunnel && (
              <IconButton
                disabled={deleteClicked}
                style={{ marginRight: 8 }}
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.setState({ deleteFunnelTriggered: true });
                }}
              >
                <DeleteIcon color="error" />
              </IconButton>
            )}
            <MultiSelect
              value={selectedFunnel}
              options={funnels_list.map((o) => {
                return { label: o.name, value: o._id };
              })}
              placeholder="Select saved Funnel"
              single
              handleChange={(funnelId) => {
                const { events = [], name = "New Funnel", time = 5 * 60 } =
                  getGroupFromList(funnels_list, funnelId) || {};
                if (funnelId) {
                  Apxor.logEvent(
                    "SelectSavedFunnel",
                    {
                      funnelName: funnels_list.filter(
                        (x) => x._id === funnelId
                      )[0].name,
                    },
                    APXOR_SDK_CATEGORY_CONSTANTS.FUNNELS
                  );
                }
                this.setState(
                  {
                    steps: events,
                    title: name,
                    time,
                    selectedFunnel: funnelId,
                    saveClicked: false,
                  },
                  () => {
                    const { updateFunnelQuery } = this.props;
                    updateFunnelQuery({ events, time });
                  }
                );
              }}
              clearable
              style={{ maxWidth: 460 }}
            />

            <Dialog
              status={deleteFunnelTriggered}
              handleConfirm={() => {
                if (funnels_list.map((o) => o.id).includes(selectedFunnel)) {
                  this.props.deleteFunnel(this.appId, selectedFunnel);
                }
                this.setState({ deleteFunnelTriggered: false });
              }}
              title="Delete Funnel"
              handleClose={() =>
                this.setState({ deleteFunnelTriggered: false })
              }
              confirmLabel="Delete"
              allowCancelLabel="Cancel"
            >
              <Typography variant="body2">
                Delete funnel - <b> {title} </b> ?
              </Typography>
            </Dialog>
          </Fragment>
        }
        icon={<FilterIcon color={"primary"} />}
        footer={
          <Grid container spacing={16} justify="flex-end">
            <Grid item xs={6} md>
              <MultiSelect
                placeholder="Time to complete Funnel"
                value={time}
                options={TIME_SLOTS}
                handleChange={(time) => {
                  Apxor.logEvent(
                    "TimePeriodSelected",
                    { duration: time / 3600 },
                    APXOR_SDK_CATEGORY_CONSTANTS.FUNNELS
                  );
                  this.setState({ time }, this.handleUpdate);
                }}
                style={{ width: 200, marginLeft: 12 }}
                single
                margin="dense"
              />
            </Grid>
            <Grid item xs={5} md={4} lg={3}>
              {
                <Button
                  style={{ marginTop: 8, float: "right" }}
                  size="small"
                  disabled={
                    saveFunnelTriggered ||
                    !(
                      time &&
                      steps.length > 1 &&
                      steps[1].name &&
                      steps[1].name.length > 0
                    )
                  }
                  variant="contained"
                  color="primary"
                  onClick={this.handleFunnelSaveButtonClicked}
                >
                  <SaveIcon />
                  Save Funnel
                </Button>
              }
              <Button
                style={{ marginTop: 8, marginRight: 8, float: "right" }}
                size="small"
                disabled={
                  saveClicked ||
                  !(
                    time &&
                    steps.length > 1 &&
                    steps[1].name &&
                    steps[1].name.length > 0
                  )
                }
                variant="contained"
                color="primary"
                onClick={this.handleSave}
              >
                <DoneIcon />
                Get Funnel
              </Button>
            </Grid>
            <Dialog
              status={saveFunnelTriggered}
              handleConfirm={() => {
                if (funnelName && funnelName.trim().length > 0) {
                  this.handleFunnelSave();
                }
              }}
              title="Saving Funnel"
              handleClose={() => this.setState({ saveFunnelTriggered: false })}
              confirmLabel="Save"
              allowCancelLabel="Cancel"
            >
              <TextField
                value={funnelName || ""}
                fullWidth
                onChange={(e) => {
                  this.setState({ funnelName: e.target.value || "" });
                }}
                label="Funnel Name"
                placeholder="Funnel Name"
                type="text"
                required
              />
            </Dialog>
          </Grid>
        }
      >
        {steps.map((step, index) => (
          <Grid container key={step.name + index}>
            <Grid item xs style={{ paddingLeft: 12 }}>
              <EventBuilder
                {...this.props}
                {...step}
                title={`Step ${index + 1}`}
                handleUpdate={this.handleStepUpdate(index)}
              />
            </Grid>
            <Grid item xs={1}>
              <IconButton
                style={{ display: "block", margin: "0 auto" }}
                onClick={this.handleStepDelete(index)}
              >
                <RemoveIcon color="error" />
              </IconButton>
              {index >= 0 && index + 1 !== steps.length && (
                <MaterialTooltip
                  title={"Add New Step Below"}
                  placement={"bottom"}
                >
                  <IconButton
                    style={{ display: "block", margin: "0 auto" }}
                    onClick={this.handleAddStepAfter(index)}
                  >
                    <PlaylistAddIcon color="primary" />
                  </IconButton>
                </MaterialTooltip>
              )}
            </Grid>
          </Grid>
        ))}
        <Button
          color="primary"
          style={{ marginLeft: 12 }}
          onClick={() => this.addEvent(1)}
        >
          Add Step {steps.length + 1}
        </Button>
      </Box>
    );
  }
}

export class FunnelComparision extends React.Component {
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps(nextProps) {
    const { meta } = nextProps;
    const {
      params: { appId },
      funnels: { comparing_funnels },
      meta: { globalFiltersChanged },
      updateFunnelComparables,
    } = this.props;
    if (
      meta.globalFiltersChanged &&
      globalFiltersChanged !== meta.globalFiltersChanged
    ) {
      updateFunnelComparables(appId, comparing_funnels);
    }
  }

  componentWillMount() {
    const {
      updateFunnelComparables,
      funnels: { comparing_funnels },
      params: { appId },
    } = this.props;
    updateFunnelComparables(appId, comparing_funnels);
  }

  componentWillUnmount() {
    const {
      updateFunnelComparables,
      params: { appId },
    } = this.props;
    updateFunnelComparables(appId, []);
  }

  render() {
    const {
      funnels: { funnels_list, comparing_funnels, time_series },
      params: { appId },
    } = this.props;
    const selectedFunnels =
      comparing_funnels.map((o) => getGroupNameFromList(funnels_list, o)) || [];
    return (
      <Box
        controls={
          <MultiSelect
            clearable
            handleChange={(selected) => {
              let namesOfSelected = [];
              for (let i = 0; i < selected.length; i++) {
                namesOfSelected += getGroupNameFromList(
                  funnels_list,
                  selected[i]
                );
              }
              Apxor.logEvent(
                "ConversionOverTimeClicked",
                { funnel_selected: namesOfSelected },
                APXOR_SDK_CATEGORY_CONSTANTS.FUNNELS
              );
              this.props.updateFunnelComparables(appId, selected);
            }}
            options={funnels_list.map((o) => {
              return { label: o.name, value: o._id };
            })}
            placeholder="Select saved Funnel"
            style={{ maxWidth: 640 }}
            value={comparing_funnels}
          />
        }
        collapsible
        defaultExpanded={selectedFunnels.length > 0}
        icon={<FilterIcon />}
        title={"Conversion Over Time"}
        withPadding
      >
        {(!this.props.meta.api_pending &&
          (!Array.isArray(time_series) || time_series.length === 0)) ||
          selectedFunnels.length > 0 ||
          (funnels_list.length === 0 && <Placeholder />)}
        {Array.isArray(selectedFunnels) && Array.isArray(time_series) && (
          <MultiUtilityChart
            {...this.props}
            data={time_series}
            dataKey={"name"}
            lineDataKeys={selectedFunnels}
            withHeader
            withoutAggregations
            yLabel={"Conversion %"}
          />
        )}
      </Box>
    );
  }
}

export class FunnelHolder extends React.Component {
  state = {
    currentGroupBy: OVERVIEW_KEY,
  };

  handleCurrentGroupByUpdate = (currentGroupBy) => {
    const {
      params: { appId },
      getEventAttributes,
      updateFunnelQuery,
      handleQueryUpdate,
    } = this.props;
    if (currentGroupBy !== OVERVIEW_KEY) {
      getEventAttributes(appId, currentGroupBy);
    }
    this.setState({ currentGroupBy });
    updateFunnelQuery({ group_by: [] });
    handleQueryUpdate({ group_by: [] });
  };

  render() {
    const {
      params: { appId },
      funnels: {
        query: { events = [] } = {},
        graph: { funnels = [] } = {},
        opportunity,
      },
      getFunnel,
      saveFunnel,
    } = this.props;
    const {
      segmentBuilder: {
        user_attributes,
        session_attributes,
        attribute_values,
        event_attributes = {},
        event_attribute_values = {},
        query,
      },
      getAttributeValues,
      getEventAttributeValues,
    } = this.props;
    const funnelsSelected = events.length > 0;
    const funnelsLoaded = funnelsSelected && funnels.length > 0;
    const { currentGroupBy } = this.state;
    const properties =
      currentGroupBy === OVERVIEW_KEY
        ? [...user_attributes, ...session_attributes]
        : event_attributes[currentGroupBy];
    return (
      <Grid container>
        <Grid item xs>
          <FunnelBuilder
            {...this.props}
            {...query}
            handleSave={() => {
              getFunnel(appId);
            }}
            handleFunnelSave={(appId, funnelConfig) => {
              saveFunnel(appId, funnelConfig.funnelName);
            }}
          />
          {funnelsLoaded && (
            <FunnelGraph
              {...this.props}
              currentGroupBy={currentGroupBy}
              handleCurrentGroupByUpdate={this.handleCurrentGroupByUpdate}
            />
          )}
          {funnelsLoaded && <FunnelComparision {...this.props} />}
          {funnelsLoaded && (
            <FunnelGroupedBy
              properties={properties}
              {...this.props}
              currentGroupBy={currentGroupBy}
            />
          )}
          {funnelsLoaded && (
            <FunnelsGroupedByOpportunityTable
              properties={properties}
              {...this.props}
              currentGroupBy={currentGroupBy}
              opportunity={opportunity}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}
