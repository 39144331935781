import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading } from '../../../Navigation/actions';
import { UserSearch } from './components';
import Grid from "@material-ui/core/Grid";
import {
    getUserProfiles, getUserProfileSearchAttributes, getUserProfileSearchFieldValues,
    updateFilters, updateGroup, updateSearchField, updateSearchQueryTerm, updateSegment
} from './actions';
import {getAttributes, getAttributeValues, resetQuery, updateQuery} from "../../Segments/NewSegment/actions";
import {getSegmentList} from "../../Segments/actions";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        userExplorer: state.userExplorer,
        segmentBuilder: state.segmentBuilder,
        segment: state.segment,
        meta: state.meta,
        auth: state.auth,
        filters: state.filters
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        handleSearch: (appId, limit) => dispatch(getUserProfiles(appId, limit)),
        getUserProfileSearchAttributes: (appId) => dispatch(getUserProfileSearchAttributes(appId)),
        getAttributes: (appId) => dispatch(getAttributes(appId)),
        getAttributeValues: (appId, attribute, q) => dispatch(getAttributeValues(appId, attribute, q)),
        getSegments: (appId) => dispatch(getSegmentList(appId)),
        updateFilters: (filters) => {
            dispatch(updateFilters(filters));
        },
        updateGroup: (group) => {
            dispatch(updateGroup(group))
        },
        updateSearchField: (field) => {
            dispatch(updateSearchField(field))
        },
        updateSearchQueryTerm: (term) => {
            dispatch(updateSearchQueryTerm(term))
        },
        getRecommendations: (appId, field, q) => dispatch(getUserProfileSearchFieldValues(appId, field, q)),
        updateSegment: (segmentId) => {
            dispatch(updateSegment(segmentId))
        },
        handleQueryUpdate: (query) => dispatch(updateQuery(query)),
        resetQuery: () => dispatch(resetQuery())
    };
}

class UserExplorer extends Component {

    constructor(props){
        super(props);
        this.state = {
            userId : null,
            obtainedUserIds: [],
            loading: false,
            failed: false,
            isRequested: false
       };
        this.appId = props.params.appId;
    }
    componentWillMount(){
        const { updateHeading, getAttributes, getSegments } = this.props;
        getAttributes(this.appId);
        getSegments(this.appId);
        updateHeading("User Explorer");
    }

    componentDidMount(){
        const { handleSearch } = this.props;
        handleSearch(this.appId);
    }

    handleChange = (event) => {
        this.setState({
            userId: event.target.value,
        });
    };

    handleSubmit = () => {
    };

    componentWillUnmount(){
        this.props.resetQuery(); //resetting user and session properties
    }

    render() {
        return (
            <section className="content">
                <Grid container justify="center">
                    <Grid item xs={12} md={8}>
                        <UserSearch {...this.props}/>
                    </Grid>
                </Grid>
            </section>
        );
    }
}

UserExplorer.propTypes = {

};

UserExplorer.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserExplorer);