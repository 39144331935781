/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../api';
import { makeDefaultQueryString, withQueryStrings, makeDonutData} from '../../../../../../utils';
import { SESSION_PARAMS, GRANULARITY_ENUM } from '../../../../../../constants';
import { API_QUERY_PARAMS } from '../../../../../../constants/EndPoints';
import {} from "./actionTypes";

const SESSION_TIME_SERIES_API = "time-series";
const USERS_TIME_SERIES_API = "time-series";
const SESSION_LENGTH_TIME_SERIES_API = `sessions/${SESSION_PARAMS.sessionLength}/distribution`;

const EVENTS_API = "events";
const EVENT_ATTRIBUTES_API = "events/attributes";
const EVENT_ATTRIBUTE_VALUES_API = "events/attributes/values";

const EVENT_COUNT_API = "events/count";
const EVENT_TIME_SERIES_API = "events/time-series";
const EVENT_DISTRIBUTION_API = "events/distribution";

const ALL_USERS_COUNT_API = "count";
const ALL_SESSIONS_COUNT_API = "count";

const ATTRIBUTES_API = "attributes";
const ATTRIBUTE_DISTRIBUTION_API = "distribution";

const RETENTION_API = "retention";
const EVENT_RETENTION_API = "retention";
const EVENT_REPEATED_RETENTION_API = "event-repeat-usage";
const KPIS_API = "kpis";

const UNINSTALL_COUNT_API = "uninstall/count";

const TIME_SPENT_API = "session-length/time-series";
const NORMALIZED_TIME_SPENT_API = "normalized-session-length/time-series";

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getSessionTimeSeriesAPI(auth, appId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(SESSION_TIME_SERIES_API, auth, appId) + withQueryStrings({...queryParams, [API_QUERY_PARAMS.granularity] : GRANULARITY_ENUM.DAY });
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}
/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function getUsersTimeSeriesAPI(auth, appId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(USERS_TIME_SERIES_API, auth, appId) + withQueryStrings({...queryParams, [API_QUERY_PARAMS.granularity] : GRANULARITY_ENUM.DAY });
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param groupId
 * @param filters
 * @returns {Promise}
 */
export function getSessionLengthTimeSeriesAPI(auth, appId, groupId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(SESSION_LENGTH_TIME_SERIES_API, auth, appId) + withQueryStrings({...queryParams, [API_QUERY_PARAMS.granularity] : GRANULARITY_ENUM.DAY });
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventsAPI(auth, appId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(EVENTS_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json.sort((a, b) => a.localeCompare(b)); //default sorting on strings
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventAttributesAPI(auth, appId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(EVENT_ATTRIBUTES_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventAttributeValuesAPI(auth, appId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(EVENT_ATTRIBUTE_VALUES_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventRelatedCount(auth, appId, filters, event) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(EVENT_COUNT_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

//FIXME: Temporary despicable 'HACK'
export function getEventKPIsAPI(auth, appId, filters, event) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(KPIS_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return []; //FIXME: Temporary despicable 'HACK'
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventRelatedDistributions(auth, appId, filters, event) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(EVENT_DISTRIBUTION_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return makeDonutData(json);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventRelatedTimeSeries(auth, appId, filters, event) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(EVENT_TIME_SERIES_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getAllUsersCountAPI(auth, appId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(ALL_USERS_COUNT_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getUninstallCountAPI(auth, appId, filters, body) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(UNINSTALL_COUNT_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getAllSessionsCountAPI(auth, appId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(ALL_SESSIONS_COUNT_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getAttributesAPI(auth, appId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(ATTRIBUTES_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getAttributeDistributionAPI(auth, appId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(ATTRIBUTE_DISTRIBUTION_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return makeDonutData(json);
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getRetentionAPI(auth, appId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(RETENTION_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventRetentionAPI(auth, appId, filters, event) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(EVENT_RETENTION_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getEventRepeatedRetentionAPI(auth, appId, filters, event) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(EVENT_REPEATED_RETENTION_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getTopEventsAPI(auth, appId, filters) {
    const { filters: postBody, ...queryParams } = filters;
    const url = makeDefaultQueryString(ATTRIBUTE_DISTRIBUTION_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json || [];
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getTimeSpentAPI(auth, appId, filters, normalized = false) {
    const {filters: postBody, ...queryParams} = filters;
    const url = makeDefaultQueryString(normalized ? NORMALIZED_TIME_SPENT_API : TIME_SPENT_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json || [];
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}
