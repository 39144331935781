/**
 * Created by Rakesh Peela
 * Date: 11-Nov-2019
 * Time: 1:42 PM
 */
import {Chip, Grid, IconButton, Typography, withStyles} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import moment from "moment";
import PropTypes from 'prop-types';
import React from 'react';
import {conditionalUtterance, LOGICAL_OPERATORS} from "../../../../../../../../../../constants";
import SegmentConfig from "../../../../../Segments/SegmentConfig";
import {getPlatformBasedTimelimitKey} from "../../../utils";
import CampaignStepCard from "../components/CampaignStepCard";
import DummyInsetContainer from "../components/DummyInsetContainer";
import ExpansionModuleConfig from "../components/ExpansionModuleConfig";
import FieldDescription from "../components/FieldDescription";
import {AUDIENCE_TYPES, PLATFORM_IMAGES} from "../utils";

const summary_styles = (theme) => ({});

const constructStringFromCondition = (condition, index, length, sequence_enabled) => {
    const {details: {name, additional_info}, count_config: {operator, count}} = condition;
    let action = "";
    switch (condition.event_type) {
        case "activity_event":
            action = "Visits";
            break;
        default:
            action = "Does";
    }

    if (condition.hasOwnProperty("dummy_event_type") && condition.dummy_event_type === "nth") {
        return <div style={{width: "100%"}} key={index + "-" + condition._id}>
            <Typography component={"h3"}>
                <span> {action} </span>
                <strong>{name}</strong> for <strong>{Number(additional_info["apx_lt_count"]) + 1}</strong> time(s).
            </Typography>
        </div>
    }

    return (
        <div style={{width: '100%'}} key={index + "-" + condition._id}>
            <Typography component="h3">
                <span>{action} </span><strong> {name}</strong>
                {
                    count > 0 &&
                    <span>
                        {conditionalUtterance(operator)}
                        {count === 1 ? <strong> once </strong> : <span><strong> {count} </strong> times</span>}
                        {Object.keys(additional_info).length > 0 && <span>with attributes </span>}
                    </span>
                }
            </Typography>
            {
                Object.keys(additional_info).length > 0 && <div>
                    {
                        Object.keys(additional_info).map(key => additional_info.hasOwnProperty(key)
                            && getPropertyString(key, additional_info[key]))
                    }
                </div>
            }
            {index + 1 < length &&
            <div><br/><FieldDescription description={sequence_enabled ? "AND THEN" : condition.combine_operator}
                                        title={""}/><br/></div>}
        </div>
    );
};

const getPropertyString = (key, value) => {
    let val = value;
    let operator = LOGICAL_OPERATORS.EQ;
    if (value.hasOwnProperty('op') && value.hasOwnProperty('val')) {
        operator = value.op;
        val = value.val;
    }

    return (
        <Typography style={{marginLeft: 40}} key={key} component="h3">
            <strong>{key} </strong>{conditionalUtterance(operator)}<strong> {val}</strong>
        </Typography>
    );
};

export const getCampaignDurations = (validity) => {
    const startDate =
        moment(validity.start_date).format("LLL");

    const endDate = (
        validity.run_forever
            ? "Till end of the world"
            : moment(validity.end_date).format("LLL"));

    return startDate + " - " + endDate;
};

const getCohortsInfo = (cohortId, cohorts) => {
    return cohorts.filter(x => x.cohort_id === cohortId).map(x => x.name)
}

class CampaignSummary extends React.Component {

    getPropertiesOfSegment = (segment_id, category) => {
        const {appState, segments} = this.props;
        const segment = segments !== undefined
            ? segments.filter(o => o._id === segment_id)
            : appState && appState.appSegments && appState.appSegments.filter(o => o._id === segment_id) || [];
        return segment[0] && segment[0][category];
    };

    getTestDeviceNames = (devices, testDevicesData) => {
        return testDevicesData.filter(x => devices.includes(x.id)).map(x => {
            if (x.hasOwnProperty("nick_name") && x.nick_name !== "")
                return {label: x.nick_name, id: x.id};
            else if (x.hasOwnProperty("model") && x.model !== "")
                return {label: x.model, id: x.id};
            else
                return {label: x.id, id: x.id};
        })
    }

    render() {
        const {hidden} = this.props;
        const {
            jumpToStep, messages2: {
                new_message: {
                    meta, conditions, audience, testing,
                    scheduling, overall_cfg, validity,
                    sequence_enabled, uis, goal_event, time_limits, prio = undefined,
                }, new_message,
            }, messages2, testDevices,
            cohorts: {cohorts = []}
        } = this.props;
        const {withEdit: viewOnly} = this.props;
        let testDevicesLabelList = this.getTestDeviceNames(testing.devices, testDevices);

        return (
            <div>
                {!hidden && <DummyInsetContainer>
                    <Grid container spacing={16}>
                        <Grid item xs={12} sm={12} md={8} lg={8}>
                            <Grid container spacing={16} style={{marginBottom: 4}}>
                                <Grid item xs={6} md={6} sm={6} lg={6}>
                                    <ExpansionModuleConfig title="Information" defaultExpanded>
                                        <Grid container spacing={16}>
                                            <Grid item xs={12} sm={12} md={3} lg={3}>
                                                <FieldDescription title="Platform"/>
                                                {
                                                    PLATFORM_IMAGES(meta.platform)
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={7} lg={7}>
                                                <FieldDescription title="Name"/>
                                                <Typography>
                                                    {meta.name}
                                                </Typography>
                                                <br/>
                                                <FieldDescription title="Description"/>
                                                <Typography>
                                                    {meta.description}
                                                </Typography>
                                            </Grid>
                                            {
                                                viewOnly &&
                                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                                    <IconButton onClick={() => {
                                                        jumpToStep(0)
                                                    }}>
                                                        <EditIcon fontSize={"small"}/>
                                                    </IconButton>
                                                </Grid>
                                            }
                                        </Grid>
                                    </ExpansionModuleConfig>
                                </Grid>
                                <Grid item xs={6} md={6} sm={6} lg={6}>
                                    <ExpansionModuleConfig
                                        defaultExpanded
                                        title={testDevicesLabelList.length > 0 ? `Test Devices (${testDevicesLabelList.length})` : "Testing"}
                                        style={{height: "100%", minHeight: 150.5}}
                                    >
                                        <Grid container spacing={16}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                {
                                                    (testing.devices.length <= 0 || testDevicesLabelList.length === 0)
                                                    && <div style={{textAlign: "center"}}>
                                                        <Typography>
                                                            No Test Devices Selected
                                                        </Typography>
                                                    </div>
                                                }
                                                {
                                                    testing.devices.length > 0
                                                    && <div style={{display: "inline-block"}}>
                                                        {
                                                            testDevicesLabelList
                                                            .map(x => (
                                                                <Chip
                                                                    label={x.label} key={x.id}
                                                                    style={{margin: 4, height: 28, borderRadius: 4}}
                                                                    variant={"outlined"}
                                                                />
                                                            ))
                                                        }
                                                    </div>
                                                }
                                            </Grid>
                                        </Grid>
                                    </ExpansionModuleConfig>
                                </Grid>
                            </Grid>
                            <ExpansionModuleConfig title="Context" defaultExpanded>
                                {
                                    prio !== undefined && prio >= 0 &&
                                    <Chip
                                        style={{
                                            padding: "8px 12px",
                                            marginBottom: "16px",
                                            height: "24px",
                                            fontWeight: 600,
                                        }}
                                        label={"Priority: " + prio}
                                    />
                                }
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={11} lg={11}>
                                        <div style={{width: '100%'}}>
                                            {conditions.map((condition, index) => constructStringFromCondition(condition, index, conditions.length, sequence_enabled))}
                                        </div>
                                    </Grid>
                                    {
                                        viewOnly &&
                                        <Grid item xs={1} sm={1} md={1} lg={1}>
                                            <IconButton onClick={() => {
                                                jumpToStep(2)
                                            }}>
                                                <EditIcon fontSize={"small"}/>
                                            </IconButton>
                                        </Grid>
                                    }
                                </Grid>
                            </ExpansionModuleConfig>
                            <ExpansionModuleConfig title="Strategy" defaultExpanded>
                                <Grid container spacing={16}>
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <FieldDescription
                                            title={audience.audience_type}/>
                                        <Typography>
                                            {audience.audience_type === AUDIENCE_TYPES[0].key ? "Campaign will work for all users" : ""}
                                        </Typography>
                                        {
                                            audience.audience_type === AUDIENCE_TYPES[2].key
                                            && audience.cohort_id !== "" && audience.cohort_id !== null
                                            && <Typography>
                                                {getCohortsInfo(audience.cohort_id, cohorts)}
                                            </Typography>
                                        }
                                        {
                                            audience.audience_type === AUDIENCE_TYPES[1].key
                                            && audience.segment_id !== ""
                                            && <>
                                                <DummyInsetContainer style={{marginTop: 12, marginBottom: 0}}>
                                                    <SegmentConfig
                                                        label=""
                                                        user={this.getPropertiesOfSegment(audience.segment_id, "user")}
                                                        session={this.getPropertiesOfSegment(audience.segment_id, "session")}
                                                        event={this.getPropertiesOfSegment(audience.segment_id, "event")}
                                                    />
                                                </DummyInsetContainer>
                                                {
                                                    !scheduling.rollSegment
                                                    && audience.hasOwnProperty("start_time")
                                                    && audience.hasOwnProperty("end_time")
                                                    && audience.start_time
                                                    && audience.end_time
                                                    && <Typography style={{paddingTop: 6}}>
                                                        between
                                                        <b style={{padding: "0px 6px"}}>{moment(audience.start_time).format("LL")} UTC</b>
                                                        and
                                                        <b style={{padding: "0px 6px"}}>{moment(audience.end_time).format("LL")} UTC</b>
                                                    </Typography>
                                                }
                                                {
                                                    scheduling.isRecurEnabled
                                                    && scheduling.rollSegment
                                                    && <Typography style={{paddingTop: 6}}>
                                                        for the previous
                                                        <b style={{padding: "0px 6px"}}>{scheduling.rollInterval}</b>
                                                        {scheduling.rollInterval > 1 ? "day(s)" : "day"}
                                                    </Typography>
                                                }
                                            </>
                                        }
                                        {
                                            overall_cfg.hasOwnProperty("events")
                                            && overall_cfg.events.length > 0
                                            && <div>
                                                <br/>
                                                <FieldDescription
                                                    title="And who didn't do events"
                                                    description={
                                                        overall_cfg.events
                                                        .map(x => x.name && x.name)
                                                        .join(", ")
                                                    }
                                                />
                                            </div>
                                        }
                                        {
                                            overall_cfg.hasOwnProperty("ret_day")
                                            && overall_cfg.toggleRetDay
                                            && overall_cfg.ret_day.hasOwnProperty("from")
                                            && overall_cfg.ret_day.hasOwnProperty("to")
                                            && ((overall_cfg.ret_day.from < overall_cfg.ret_day.to)
                                                ? <div>
                                                    <br/>
                                                    <FieldDescription title="And whose Retention day falls between"
                                                                      description={"D" + overall_cfg.ret_day.from + "-D" + overall_cfg.ret_day.to}/>
                                                </div>
                                                : <div>
                                                    <br/>
                                                    <FieldDescription title="And whose Retention day is"
                                                                      description={"D" + overall_cfg.ret_day.from}/>
                                                </div>)
                                        }
                                    </Grid>
                                    <Grid item xs={11} sm={11} md={5} lg={5}>
                                        <FieldDescription
                                            title="Campaign duration"/>
                                        <Typography>
                                            {getCampaignDurations(validity)}
                                        </Typography>
                                        {
                                            new_message[getPlatformBasedTimelimitKey(messages2)] && <Typography>
                                                Between {time_limits.start_time} and {time_limits.end_time}
                                            </Typography>
                                        }
                                    </Grid>
                                    {goal_event && <Grid item xs={11} sm={11} md={5} lg={5}>
                                        <FieldDescription
                                            title="Goal Event"
                                            description={
                                                <Typography>{goal_event}</Typography>
                                            }
                                        />
                                    </Grid>}
                                    {
                                        viewOnly &&
                                        <Grid item xs={1} sm={1} md={1} lg={1}>
                                            <IconButton onClick={() => {
                                                jumpToStep(3)
                                            }}>
                                                <EditIcon fontSize={"small"}/>
                                            </IconButton>
                                        </Grid>
                                    }
                                </Grid>
                            </ExpansionModuleConfig>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <ExpansionModuleConfig
                                title={'Design' + (uis.length > 0 ? ` (${uis.length} Steps)` : "")}
                                defaultExpanded
                            >
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                    {
                                        uis.length <= 0 &&
                                        <Typography variant={"subtitle2"}> No Walkthrough Steps Configured</Typography>
                                    }
                                    {
                                        uis.length > 0 &&
                                        <DummyInsetContainer style={{maxHeight: 480, overflowY: "scroll"}}>
                                            {
                                                uis.map((uiItem, index) => {
                                                    const {action_class, layout_type} = uiItem;
                                                    return (
                                                        <CampaignStepCard
                                                            style={{width: "100%"}}
                                                            key={"key_card_" + index}
                                                            itemIndex={index}
                                                            ui={uiItem}
                                                            editable={false}
                                                        />
                                                    )
                                                })
                                            }
                                        </DummyInsetContainer>
                                    }
                                </div>
                            </ExpansionModuleConfig>
                        </Grid>
                    </Grid>
                </DummyInsetContainer>
                }
            </div>
        );
    }
}

CampaignSummary.propTypes = {
    hidden: PropTypes.bool.isRequired,
    jumpToStep: PropTypes.func,
    messages2: PropTypes.object,
    withEdit: PropTypes.bool.isRequired,
}

export default withStyles(summary_styles)(CampaignSummary);