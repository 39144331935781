import React, { Component, } from 'react';
import { toTitleCase } from "../../../../../../../utils";
import SortableDataTable from "../../../../../../../components/reusable/SortableDataTable";
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import RemoveIcon from '@material-ui/icons/Remove';

export default class FlatTable extends Component {

    componentWillMount(){
        this.populateData();
    }

    componentWillReceiveProps(nextProps) {
        const { meta, needUpdate } = nextProps;
        //reloading data for updated filters
        const {
            meta: { globalFiltersChanged },
            needUpdate: prevNeedUpdate
        } = this.props;
        if(meta.globalFiltersChanged && globalFiltersChanged !== meta.globalFiltersChanged){
            this.populateData(nextProps);
        }
        if(prevNeedUpdate !== needUpdate && needUpdate){
            this.populateData(nextProps);
        }
    }

    populateData = (props = this.props) => {
        const {
            params: { appId },
            customReport: { metrics = [], dimensions= [], filters = [] },
            getFlatTable, updateQuery
        } = props;
        const query = {metrics, dimensions, filters};
        updateQuery(query);
        getFlatTable(appId);
    };

    render(){

        const {
            customReport: {
                dimensions = [], metrics = [], flat_table: {aggregations = {}, data = [] } = {}
            }
        } = this.props;
        const headerLabels = [
            ...dimensions.map(o => o.name),
            ...metrics.map(m => {
                if (["users", "sessions"].includes(m))
                    return toTitleCase(m);
                return `${toTitleCase(m)} (${aggregations[m] || "NA"})`
            })
        ];
        let keys = [...dimensions.map(o => o.name), ...metrics];
        return(
            <div style={{width: '100%'}}>
                {
                    data.length > 0 && <SortableDataTable
                        {...this.props}
                        boxProps={{
                            title: "Flat Table"
                        }}
                        data={data}
                        headerLabels={headerLabels}
                        keys={keys}
                        tableType={"flat-table"}
                        downloadReportTitle={`FlatTable-${new Date().toDateString()}`}
                    />
                }
                {
                    data.length === 0 && <Placeholder withIcon icon={<RemoveIcon  color="error"/>}>No Data.</Placeholder>
                }
            </div>
        )

    }

}