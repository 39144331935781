/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    RESET_PREPROCESS,
    PREPROCESS_PENDING, PREPROCESS, PREPROCESS_FAILED,
    PREPROCESS_CONFIGS_PENDING, PREPROCESS_CONFIGS, PREPROCESS_CONFIGS_FAILED
} from './actionTypes';
import {
    preprocessConfigsAPI,
    savePreprocessAPI
} from './api';

export const savePreprocess = (appId, config, cohortId) => {
    return (dispatch, getState) => {
        let {cohort, ...otherFilters} = getState().filters;
        let filters = {...otherFilters};
        if (cohortId !== null && cohortId !== "") {
            filters["cohort"] = cohortId;
        }
        return dispatch({
            types: [
                PREPROCESS_PENDING, PREPROCESS, PREPROCESS_FAILED
            ],
            payload: {
                promise: savePreprocessAPI(getState().auth, appId, filters, config)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getPreprocessConfigs = (appId) => {
    return (dispatch, getState) => {
        let filters = {...getState().filters};
        return dispatch({
            types: [
                PREPROCESS_CONFIGS_PENDING, PREPROCESS_CONFIGS, PREPROCESS_CONFIGS_FAILED
            ],
            payload: {
                promise: preprocessConfigsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @returns {function(*, *)}
 */
export const resetPreprocess = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_PREPROCESS,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};