import React, { Component } from 'react';
import {
    getRetentionUsage
} from '../actions';
import withSelfComponent from './SelfComponent';
import {session} from "../../../../../../../utils/Storage";
import Box from "../../../../../../../components/reusable/Box";
import {DAYS_ENUM_FOR_USAGE} from "../../../../../../../constants";
import {formatRetentionUsage} from "../../../../../../../utils";
import MultiUtilityChart from "../../../../../../../components/reusable/Recharts/MultiUtilityChart";
import InsertChartIcon from '@material-ui/icons/InsertChart';
import Switch from "../../../../../../../components/reusable/Switch";
import TextField from "@material-ui/core/TextField";

const SWITCH_DATA = [
    {
        name: "Habit Trend",
        value: "percent",
        help: "% Number of retained users using the selected feature. Feature usage across the user life cycle."
    },
    {
        name: "Habit Strength",
        value: "avg_events",
        help: "Number of times a selected feature is used in user life cycle. How strongly the given feature has become a habit"
    }
];

class RetentionUsage extends Component {

    state = {
        current: SWITCH_DATA[0].value,
        q: ''
    };

    handleSwitch = (current) => {
        this.setState({current});
    };

    hydrator = (props = {}) => {
        const {
            dataKey, extraFilters, queryParams
        } = props.selfParams || {};
        const {dispatch, params: {appId}} = props;
        dispatch(getRetentionUsage(appId, dataKey, queryParams, extraFilters));
    };

    render(){
        const {
            self, selfParams: { dataKey, customLabel, queryParams: { day = DAYS_ENUM_FOR_USAGE } } = {},
            meta: { api_pending }, parentCompLabel
        } = this.props;
        if(!api_pending){
            let obj = {};
            obj[customLabel] = `${self[dataKey]}`;
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        const { current, q } = this.state;
        const data = formatRetentionUsage(self[dataKey], current, day);
        const filteredData = data.filter(o => o.event_name.toLowerCase().includes(q));
        return (
            <Box
                icon={<InsertChartIcon/>} title={customLabel}
                controls={
                    <div style={{display: 'flex'}}>
                        <TextField
                            style={{marginRight: 8}}
                            value={q}
                            onChange={e => this.setState({q: e.target.value})}
                            placeholder="event_name"
                            label="Search Events"
                        />
                        <Switch containerStyles={{marginTop: 10}} data={SWITCH_DATA} handleChange={this.handleSwitch} value={current}/>
                    </div>
                }
            >
                <MultiUtilityChart
                    barDataKeys={day.map(d => "Day " + d)}
                    data={filteredData}
                    dataKey="event_name"
                    xLabelFormatter={(e) => e}
                    showBrush
                    maxBrushLength={5}
                    showAverage
                />
            </Box>
        )
    };
}

RetentionUsage.propTypes = {

};

export default withSelfComponent(RetentionUsage);