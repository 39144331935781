/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    GET_LOCATION_PENDING, GET_LOCATION, GET_LOCATION_FAILED
} from './actionTypes';
import { getLocationAPI } from './api';

export function getLocation() {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                GET_LOCATION_PENDING,
                GET_LOCATION,
                GET_LOCATION_FAILED
            ],
            payload: {
                promise: getLocationAPI()
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
}