/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
/**
 * Actions of Apps Page
 * @type {string}
 */
export const ADD_APP = "ADD_APP";
export const ADDING_APP = "ADDING_APP";
export const ADDING_APP_FAILED = "ADDING_APP_PENDING";
export const DELETE_APP = "DELETE_APP";
export const DELETING_APP = "DELETING_APP";
export const DELETE_FAILED = "DELETE_FAILED";
export const GET_APPS = "GET_APPS";
export const GET_APPS_PENDING = "GET_APPS_PENDING";
export const GET_APPS_FAILED = "GET_APPS_FAILED";
export const CLICK_APP = "CLICK_APP";
export const ENABLE_MONITORING = "ENABLE_MONITORING";
export const DISABLE_MONITORING = "DISABLE_MONITORING";
export const MONITORING_PENDING = "MONITORING_PENDING";
export const MONITORING_FAILED = "MONITORING_FAILED";

export const APPROVE_APP = "APPROVE_APP";
export const APPROVE_APP_PENDING = "APPROVE_APP_PENDING";
export const APPROVE_APP_FAILED = "APPROVE_APP_FAILED";
export const RESET_APP_APPROVAL = "RESET_APP_APPROVAL";

export const RESET_CREATE_APP_DIALOG = "RESET_CREATE_APP_DIALOG";
export const HIDE_CREATE_APP_DIALOG = "HIDE_CREATE_APP_DIALOG";
export const SHOW_CREATE_APP_DIALOG = "SHOW_CREATE_APP_DIALOG";