/**
 * Created by Rakesh Peela
 * Date: 13-Dec-2019
 * Time: 11:58 AM
 */

import {ButtonBase, IconButton, Tooltip, Typography, withStyles} from "@material-ui/core";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import Apxor from 'apxor';
import React from "react";
import Loading from "../../../../../../../../../../components/reusable/Loading";
import {APXOR_SDK_CATEGORY_CONSTANTS} from '../../../../../../../../../../constants';
import ClassicCard from "../../ClassicCard";
import {ACTION_CLASSES} from "../models/AvailableOptions";
import {getActionClassLogo} from "../utils";

const styles = (theme) => ({
    buttonBaseStyle: {
        border: 0,
        // cursor: "pointer",
        display: "inline-flex",
        outline: "none",
        padding: 0,
        position: "relative",
        alignItems: "center",
        userSelect: "none",
        verticalAlign: "middle",
        justifyContent: "center",
        textDecoration: "none",
        backgroundColor: "transparent",
        textAlign: "initial",
        borderRadius: 12,
        minWidth: 280,
        minHeight: (props) => props.editable ? 168 : "auto",
        margin: 12,
        transition: "all 0.2s ease-in-out",
        "&:hover $overlayDiv": {
            transition: "all 0.2s ease-in-out",
            display: "inline-block",
            position: "absolute",
            top: -24,
            right: -24
        }
    },
    classicCardActions: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    buttonIcon: {
        marginRight: 12
    },
    actionButtons: {
        background: "#ffffff",
        margin: 6,
        lineHeight: "16px",
        boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",
        "&:hover": {
            backgroundColor: "#dcdcdc"
        }
    },
    actionButtonDisabled: {
        margin: 6,
        pointerEvents: "none",
        cursor: "default"
    },
    overlayDiv: {
        display: "none",
        transition: "all 0.2s ease-in-out",
    },
    classicStepCardStyle: {
        width: "100%",
        height: "100%",
        padding: 8,
    }
});

class CampaignStepCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDeleting: false,
        }
    }

    render() {
        const {
            classes,
            createNew = false,
            editable = true,
            itemIndex = 0,
            totalSteps = 1,
            selected = false,
            ui = {},
            deleteHandler = (index) => null,
            handler = () => null,
            showDialog = () => null,
            moveStep,
        } = this.props;
        const {isDeleting} = this.state;
        const {layout_type, action_class, display_text} = ui;
        let hasNext = (totalSteps - 1) !== itemIndex;
        let hasPrev = itemIndex !== 0;
        return (
            <div>
                {
                    !createNew &&
                    (<div style={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <div
                            className={classes.buttonBaseStyle}
                        >
                            <ClassicCard
                                style={{
                                    minWidth: 280,
                                    minHeight: editable ? 168 : "auto",
                                    padding: "8px 8px",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-between",
                                    width: editable ? "100%" : 480
                                }}
                                selected={selected}
                            >
                                <div style={{display: "flex", flexDirection: "row", alignItems: "flex-start"}}>
                                    <div style={{
                                        background: "rgb(183, 191, 196)",
                                        borderRadius: 12,
                                        width: 24,
                                        height: 24,
                                        padding: 4,
                                        color: "white",
                                        textAlign: "center",
                                        marginRight: 6,
                                    }}>
                                        {itemIndex + 1}
                                    </div>
                                    <div style={{
                                        minWidth: 96,
                                    }}>
                                        {getActionClassLogo(action_class, layout_type, true)}
                                    </div>
                                    <div>
                                        <Typography variant={"body2"} style={{fontWeight: 600}}>
                                            {display_text}
                                        </Typography>
                                        <Typography variant={"body1"}>
                                            {action_class}
                                        </Typography>
                                    </div>
                                </div>
                                {
                                    editable
                                    && <div style={{
                                        marginTop: 6,
                                        paddingTop: 6,
                                        borderTop: "1px solid #e8e8e8",
                                        width: "100%"
                                    }}>
                                        <div className={classes.classicCardActions}>
                                            <div>
                                                {
                                                    hasPrev
                                                    && <Tooltip title={"Move Step Left"}>
                                                        <IconButton disabled={!hasPrev}
                                                                    onClick={() => moveStep(itemIndex, -1)}>
                                                            <ChevronLeftIcon fontSize={"small"}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {
                                                    hasNext
                                                    && <Tooltip title={"Move Step Right"}>
                                                        <IconButton disabled={!hasNext}
                                                                    onClick={() => moveStep(itemIndex, 1)}>
                                                            <ChevronRightIcon fontSize={"small"}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </div>
                                            <div>
                                                {
                                                    action_class !== ACTION_CLASSES.INAPP
                                                    && selected
                                                    && <Tooltip title={"Preview"}>
                                                        <IconButton
                                                            onClick={() => showDialog()}
                                                        >
                                                            <RemoveRedEyeIcon
                                                                fontSize={"small"}
                                                                color={"action"}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                                {
                                                    !selected
                                                    && <Tooltip title={"Edit"}>
                                                        <IconButton
                                                            onClick={() => handler(itemIndex)}
                                                        >
                                                            <EditIcon
                                                                fontSize={"small"}
                                                                color={"action"}
                                                            />
                                                        </IconButton>
                                                    </Tooltip>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </ClassicCard>
                            {
                                editable
                                && <div className={classes.overlayDiv}>
                                    <Tooltip title={"Delete Step"}>
                                        <IconButton
                                            classes={{
                                                root: classes.actionButtons
                                            }}
                                            onClick={() => {
                                                this.setState({
                                                    isDeleting: true
                                                });
                                                setTimeout(() => {
                                                    deleteHandler(itemIndex)
                                                }, 1000)
                                            }}
                                        >
                                            {isDeleting ? <Loading size={20}/> :
                                                <DeleteIcon fontSize={"small"} color={"error"}/>}
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            }
                        </div>
                        {
                            editable
                            && <IconButton disabled>
                                <ArrowForwardIcon fontSize={"small"}/>
                            </IconButton>
                        }
                    </div>)
                }
                {
                    createNew && <div>
                        <ButtonBase
                            style={{
                                textAlign: "initial",
                                margin: 12,
                                borderRadius: 12,
                                minWidth: 280,
                                minHeight: 168
                            }}
                            disabled={false}
                            onClick={() => {
                                Apxor.logEvent("AddNewStep", {}, APXOR_SDK_CATEGORY_CONSTANTS.CAMPAIGNS);
                                handler(itemIndex)
                            }}
                        >
                            <ClassicCard placeholderType style={{
                                minWidth: 280,
                                minHeight: 168,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-around",
                                textAlign: "center"
                            }}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "space-around"
                                }}>
                                    <AddCircleOutlinedIcon color={"primary"} fontSize={"large"}
                                                           style={{margin: "16px auto"}}/>
                                    <Typography style={{marginBottom: 24}}>
                                        Add New Step
                                    </Typography>
                                </div>
                            </ClassicCard>
                        </ButtonBase>
                    </div>
                }
            </div>
        );
    }

}

export default withStyles(styles)(CampaignStepCard)