import React, { Component } from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import {getGroupStatistics, getUsersForEvent, getVersionStatistics} from '../actions';
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import {TextField} from "@material-ui/core";
import Switch from "../../../../../../../components/reusable/Switch";
import { USER_GROUP_ENUM } from "../../../../../../../constants";
import NewReleasesIcon from '@material-ui/icons/NewReleases';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import Box from "../../../../../../../components/reusable/Box";
import {QueryBuilder} from "../../../../../../../components/reusable/QueryBuilder";
import UserListPopover from "./reusable/UserListPopover";

const GROUPS = [
    {
        label: "All Users",
        value: USER_GROUP_ENUM.ALL_USERS
    },
    {
        label: "New Installs",
        value: USER_GROUP_ENUM.INSTALL_USERS
    },
    {
        label: "Uninstalls",
        value: USER_GROUP_ENUM.UNINSTALL_USERS
    },
    ...[...Array(91).keys()].slice(1).map(o => ({label: `Day ${o} Users`, value: o}))
];

const GROUPS_MAPPER = GROUPS.reduce((a, b) => {
    a[b.value] = b.label;
    return a;
}, {});

const getGroupedByLabel = (value) => {
    if(GROUPS_MAPPER.hasOwnProperty(value)){
        return GROUPS_MAPPER[value];
    }
    return "v"+ value; //returning version
};

const getGroupTypeModel = (groupValue) => {
    if(isNaN(groupValue)){
        return { groupType: groupValue };
    }else{
        return { groupType: USER_GROUP_ENUM.RETAINED_USERS, day: Number(groupValue) };
    }
};

const METRICS = [
    {
        label: "DAU",
        value: "DAU"
    },
    {
        label: "Crashes",
        value: "crashes"
    },
    {
        label: "Hangs",
        value: "hangs"
    },
    {
        label: "Uninstalls",
        value: "uninstalls"
    },
    {
        label: "Sessions",
        value: "sessions"
    },
    {
        label: "Avg. Session Length",
        value: "avg_session_length"
    },
    {
        label: "Unique Users",
        value: "unique_users"
    }
];

const METRIC_TYPES = {
    VERSIONS: "Versions",
    USERS: "Users"
};

export default class MetricsTable extends Component {

    extension = "_metrics";

    usersExtension = "_users";

    constructor(props){
        super(props);
        this.state = {
            type: props.type || METRIC_TYPES.VERSIONS,
            dataKey: props.dataKey,
            versions: props.versions || [],
            metrics: props.metrics || [],
            events: props.events || {},
            groups: props.groups || [],
            customLabel: props.customLabel || "Metrics ",
            old: props.old || false,
            _id: props._id || ""
        };
    }

    componentWillReceiveProps(nextProps) {
        const { old, meta, selfParams } = nextProps;
        if(old){
            this.setState({...selfParams});
        }
        //reloading data for updated filters
        const { meta: { globalFiltersChanged }  } = this.props;
        if(meta.globalFiltersChanged && globalFiltersChanged !== meta.globalFiltersChanged){
            this.populateData();
        }
    }

    componentWillMount(){
        const { type, old } = this.state;
        if(old && type && type.length > 0){
            this.populateData();
        }
    }

    populateData = () => {
        const {type, dataKey, versions, metrics, events, groups} = this.state;
        if(type === METRIC_TYPES.USERS){
        }
        if(type && type.length > 0) {
            const {dispatch, params: {appId}} = this.props;
            if(type === METRIC_TYPES.VERSIONS){
                versions.forEach(version => {
                    dispatch(getVersionStatistics(appId, dataKey, version, { version }, {metrics, events}));
                });
            }else{
                groups.forEach(group => {
                    dispatch(getGroupStatistics(appId, dataKey, group, getGroupTypeModel(group), { metrics, events }));
                });
            }
        }
    };

    handleClick = (usersTarget) => {
        this.setState({usersTarget});
        const { dataKey } = this.state;
        const {dispatch, params: {appId}, self, appState: { appEvents = [] }} = this.props;
        const users = self[dataKey + this.usersExtension] || [];
        if(users.length === 0){
            dispatch(getUsersForEvent(appId, appEvents[0], dataKey + this.usersExtension));
        }
    };

    render(){

        const {
            type, dataKey,
            customLabel, old, groups,
            versions, metrics, events
        } = this.state;
        const {
            self, updateWidgetProps, appState: { app: { app_versions } }, dispatch
        } = this.props;
        const appVersions = app_versions.map(o => ({label: 'v'+ o, value: o}));
        const data = self[dataKey] || {};
        if(old && type.length > 0 && data && Object.keys(data).length > 0) {
            let finalMetrics = METRICS.filter(o => metrics.indexOf(o.value) > -1);
            const eventKeys = Object.keys(events);
            return(
                <Box
                    title={customLabel}
                    icon={<NewReleasesIcon/>}
                    headerWithBorder={false}
                    withPadding
                >
                    <Table>
                        <TableHead>
                            <TableRow>
                                { type === METRIC_TYPES.VERSIONS && <TableCell>Versions</TableCell> }
                                { type === METRIC_TYPES.USERS && <TableCell>User Groups</TableCell> }
                                {
                                    finalMetrics.map(metric =>
                                        <TableCell key={metric.value} numeric>{metric.label}</TableCell>
                                    )
                                }
                                {
                                    eventKeys.map(event =>
                                        <TableCell key={event} numeric>{event}</TableCell>
                                    )
                                }
                        </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(data).map(key => {
                                const item = data[key];
                                return (
                                    <TableRow key={key}>
                                        <TableCell>{getGroupedByLabel(key)}</TableCell>
                                        {
                                            finalMetrics.map(metric =>
                                                <TableCell key={metric.label} numeric>
                                                    { item[metric.value] > 0 ?
                                                        <UserListPopover
                                                            {...this.props}
                                                            id={dataKey + this.usersExtension}
                                                            users={self[dataKey + this.usersExtension]}
                                                            handleClick={this.handleClick}
                                                            label={item[metric.value]}
                                                        /> : 0
                                                    }
                                                </TableCell>
                                            )
                                        }
                                        {
                                            eventKeys.map(event =>
                                                <TableCell key={event} numeric>
                                                    { item[event] > 0 ?
                                                        <UserListPopover
                                                            {...this.props}
                                                            id={dataKey + this.usersExtension}
                                                            users={self[dataKey + this.usersExtension]}
                                                            handleClick={this.handleClick}
                                                            label={item[event]}
                                                        /> : 0
                                                    }
                                                </TableCell>
                                            )
                                        }
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Box>
            )
        }else{
            return(
                <form onSubmit={e => {
                    e.preventDefault();
                    return false;
                }}>
                    <Card style={{width: '100%'}}>
                        <CardContent>
                            <Switch
                                data={Object.values(METRIC_TYPES)}
                                value={type}
                                handleChange={type => this.setState({type, dataKey: type + this.extension})}
                            />
                            {
                                type === METRIC_TYPES.VERSIONS && <MultiSelect
                                    placeholder="Select Versions"
                                    options={appVersions}
                                    value={versions}
                                    handleChange={
                                        versions => this.setState({versions, customLabel: "Version Stats for " + versions.join(", ")})
                                    }
                                />
                            }
                            {
                                type === METRIC_TYPES.USERS && <MultiSelect
                                    placeholder="Select Groups"
                                    options={GROUPS}
                                    value={groups}
                                    handleChange={
                                        groups => this.setState({groups, customLabel: "User Group Stats for " + groups.join(", ")})
                                    }
                                />
                            }
                            <br/>
                            <MultiSelect
                                placeholder="Select Metrics"
                                options={METRICS}
                                value={metrics}
                                handleChange={metrics => this.setState({metrics})}
                            />
                            <br/>
                            <QueryBuilder
                                {...this.props}
                                onlyEventAttributes
                                onQueryUpdate={(query) => {
                                    this.setState({events: query.event});
                                }}
                                bindedDispatch={dispatch}
                                handleNegationForEvent={() => null}
                                withoutCount
                            />
                            <br/>
                            <TextField
                                label="Custom Label"
                                value={customLabel}
                                onChange={e => this.setState({customLabel: e.target.value})}
                                margin="dense"
                                fullWidth
                            />
                        </CardContent>
                        <CardActions>
                            <Button onClick={e => {
                                if(typeof updateWidgetProps === 'function') {
                                    this.setState({old: true}, () => {
                                        updateWidgetProps(this.state);
                                        this.populateData();
                                    });
                                }
                            }} variant="contained" style={{float: 'right'}} color="primary" type="submit">Submit</Button>
                        </CardActions>
                    </Card>
                </form>
            );
        }

    }
}

MetricsTable.propTypes = {

};