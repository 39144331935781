/**
 * Created by Araja Jyothi Babu on 20-Oct-16.
 */
import {API_ROOT, API_BASE} from '../config';
import {appendEmptyValueForSequence, isDefined, makeDefaultQueryString, withQueryStrings} from '../utils';
import axios from 'axios';
import logger from '../utils/Logger';

export const HEADERS = { 'Accept': 'application/json', 'Content-Type': 'application/json' };

const defaultConfig = {
    headers: HEADERS
};

const HTTP_METHODS = {
    GET: "GET",
    POST: "POST",
    DELETE: "DELETE",
    PUT: "PUT",
    PATCH: "PATCH"
};

const { GET, POST, DELETE, PATCH } = HTTP_METHODS;

const makeConfig = (config) => {
    const {host, token} = config.auth || {};
    const {customHeaders} = config;
    return {
        headers: {
            ...defaultConfig.headers,
            ...customHeaders,
            "X-Apx-Host": host, //|| SERVER_IDENTIFIERS.google, //selects server api
            "X-Auth-Token": token, //TODO: make use of this
            "Access-Control-Allow-Credentials": true
        },
        data: config.body,
        method: config.method,
        withCredentials: true,
    };
};

/**
 *
 * @param resolve
 * @param callback
 * @param result
 * @returns {*}
 */
const handleSuccess = (resolve, callback, result) => {
    if(isDefined(callback) && typeof callback === 'function') {
        return resolve(callback(result));
    }else{
        return resolve(result);
    }
};

/**
 *
 * @param reject
 * @param callback
 * @param error
 * @returns {*}
 */
const handleFailure = (reject, callback, error) => {
    if(isDefined(callback) && typeof callback === 'function') {
        return reject(callback(error));
    }else{
        return reject(error);
    }
};

const axiosInstance = axios.create({
    baseURL: API_BASE,
    /*headers: {
        ...defaultConfig.headers,
        "X-Apx-Host": host, //|| SERVER_IDENTIFIERS.google, //selects server api
        "X-Auth-Token": token //TODO: make use of this
    }*/
});

axiosInstance.interceptors.response.use(
    response => {
        return response
    },
    error => {
        logger.error("Error:::: ", error, error.response.data);
        return Promise.reject(error.response.data);
    }
);

export function getServerAPI(url, customRoot) {
    const appIdREGEX = /appId=(.*?)($|&)/;
    let matches = appIdREGEX.exec(url);
    let fullURL = customRoot && customRoot !== "" ? customRoot + url : API_ROOT + url;
    return fullURL;
}

/**
 * WebAPI to make Asynchronous requests
 * @param url
 * @param config
 * @param onSuccess
 * @param onFailure
 * @param customRoot
 * @returns {Promise}
 */
export const callApi = (url, config, onSuccess, onFailure, customRoot) => {
    const fullURL = getServerAPI(url, customRoot);
    return new Promise((resolve, reject) => {
        let options = makeConfig(config);
        logger.info("Came to make Ajax request with options for URL: ", fullURL, " are : =>", options);
        axiosInstance.request({
            url: fullURL,
            ...options
        }).then(({data, status, statusText}) => {
            logger.info(statusText, "Response for URL: " + fullURL, data);
            handleSuccess(resolve, onSuccess, data);
        }).catch(error => {
            logger.error("Error Response: ", error, "for URL: => " + fullURL, " with Request Options: => ", options);
            handleFailure(reject, onFailure, error);
        }).then(() => { //Always
            //If Any
        });
    });
};

/**
 * @deprecated
 * @param url
 * @param config
 * @param onSuccess
 * @param onFailure
 */
export const oldCallApi = (url, config, onSuccess, onFailure) => {
    return new Promise((resolve, reject) => {
        const fullUrl = API_ROOT + url;
        const options = makeConfig(config);
        logger.info("Came to make Ajax request with options for URL: ", url, " are : =>", options);
        fetch(fullUrl, options).then(response => {
            logger.info("Response for URL: " + fullUrl, response, response.body);
            response.json().then(json => {
                logger.log("json", json);
                if (!response.ok) {
                    logger.warn("Warning:::: ", json, "for URL: => " + fullUrl, " with Request Options: => ", options);
                    handleFailure(reject, onFailure, response);
                }
                handleSuccess(resolve, onSuccess, json);
            })
        }).catch(err => {
            logger.error("Error:::: ", err, "for URL: => " + fullUrl, " with Request Options: => ", options);
            handleFailure(reject, onFailure, err);
        });
    });
};

/**********************************************************************************************************************************
 **********************************************************************************************************************************
 * Dashboard re usable APIs
 **********************************************************************************************************************************
 **********************************************************************************************************************************
 * */

/**********************************************************************************************************************************
 * App Meta APIs
 */

const TEST_DEVICES_API = "test-devices";

/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @returns {Promise}
 */
export function testDevicesAPI(appId, auth, queryParams) {
    const url = makeDefaultQueryString(TEST_DEVICES_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: GET,
        auth: auth,
    };
    const onSuccessCallback = json => {
        return Array.isArray(json) ? json : [];
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function addTestDeviceAPI(appId, auth, queryParams, testDevice) {
    const url = makeDefaultQueryString(TEST_DEVICES_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: POST,
        auth: auth,
        body: JSON.stringify(testDevice)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return false;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function removeTestDeviceAPI(appId, auth, deviceId, queryParams) {
    const url = makeDefaultQueryString(`${TEST_DEVICES_API}/${deviceId}`, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: DELETE,
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return false;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


export function updateTestDeviceAPI(appId, auth, deviceId, queryParams, testDevicePatch) {
    const url = makeDefaultQueryString(`${TEST_DEVICES_API}/${deviceId}`, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: PATCH,
        auth: auth,
        body: JSON.stringify(testDevicePatch)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return false;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


/**********************************************************************************************************************************
 * App Authorisation APIs
 */

const ADDITIONAL_CUSTOMERS_API = "additional-customers";
const ADDITIONAL_CUSTOMER_DASHBOARD_API = "additional-customers/dashboards";
const TRANSFER_APP_API = "apps/transfer/";


export function addAdditionalCustomerAPI(appId, auth, queryParams, customerBody) {
    const url = makeDefaultQueryString(ADDITIONAL_CUSTOMERS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: POST,
        auth: auth,
        body: JSON.stringify(customerBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return false;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function transferAppAPI(appId, auth, body) {
    const url = makeDefaultQueryString(TRANSFER_APP_API + appId, auth, appId);
    const config = {
        method: POST,
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return response || {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback)
}

export function removeAdditionalCustomerAPI(appId, auth, queryParams, customerId) {
    const url = makeDefaultQueryString(ADDITIONAL_CUSTOMERS_API, auth, appId) + withQueryStrings({...queryParams, customerId});
    const config = {
        method: DELETE,
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return false;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function addDashboardToAdditionalCustomerAPI(appId, auth, queryParams) {
    const url = makeDefaultQueryString(ADDITIONAL_CUSTOMER_DASHBOARD_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: POST,
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return false;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function removeDashboardToAdditionalCustomerAPI(appId, auth, queryParams) {
    const url = makeDefaultQueryString(ADDITIONAL_CUSTOMER_DASHBOARD_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: DELETE,
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return false;
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


/**********************************************************************************************************************************
 * High Level APIs
 */

const COUNT_API = "count";
const TIME_SERIES_API = "time-series";
const MULTIPLE_TIME_SERIES_API = "multiple-time-series";
const TIME_SPENT_API = "time-spent";

/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @param filters
 * @returns {Promise}
 */
export function countAPI(appId, auth, queryParams, filters) {
    const url = makeDefaultQueryString(COUNT_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return "NA";
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @param filters
 * @returns {Promise}
 */
export function timeSeriesAPI(appId, auth, queryParams, filters) {
    const url = makeDefaultQueryString(TIME_SERIES_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return Array.isArray(json) ? json : [];
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @param filters
 * @returns {Promise}
 */
export function timeSpentAPI(appId, auth, queryParams, filters) {
    const url = makeDefaultQueryString(TIME_SPENT_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return Array.isArray(json) ? json : [];
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}



/**********************************************************************************************************************************
 * Segments
 */

const SEGMENTS_API = "segments"; //FIXME: may be another place
const SEGMENTATION_API = "segmentation";

/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @returns {Promise}
 */
export function segmentsAPI(appId, auth, queryParams){
    const url = makeDefaultQueryString(SEGMENTS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return Array.isArray(json) ? json.reverse() : [];
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId
 * @param auth
 * @param segmentId
 * @param queryParams
 * @param filters
 * @returns {Promise}
 */
export function segmentRelatedCountAPI(appId, auth, segmentId, queryParams, filters) {
    const url = makeDefaultQueryString(`${SEGMENTATION_API}/${segmentId}/${COUNT_API}`, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return "NA";
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId
 * @param auth
 * @param segmentId
 * @param queryParams
 * @param filters
 * @returns {Promise}
 */
export function segmentTimeSeriesAPI(appId, auth, segmentId, queryParams, filters) {
    const url = makeDefaultQueryString(`${SEGMENTATION_API}/${segmentId}/${TIME_SERIES_API}`, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return Array.isArray(json) ? json : [];
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function segmentsTimeSeriesAPI(appId, auth, queryParams, filters) {
    const url = makeDefaultQueryString(`${SEGMENTATION_API}/${MULTIPLE_TIME_SERIES_API}`, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return Array.isArray(json) ? json : [];
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


/**********************************************************************************************************************************
 * Events
 */

const EVENT_COUNT_API = "events/count";
const EVENT_TIME_SERIES_API = "events/time-series";
const EVENT_DISTRIBUTION_API = "events/distribution";
const EVENT_COUNT_DISTRIBUTION_API = "events/distribution/count";
const ACTIVE_EVENT_HOURS_API = "events/distribution/hour";
const EVENT_STICKINESS_API = "stickiness";

/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @param filters
 * @returns {Promise}
 */
export function eventRelatedCountAPI(appId, auth, queryParams, filters) {
    const url = makeDefaultQueryString(EVENT_COUNT_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param queryParams
 * @param filters
 * @returns {Promise}
 */
export function eventRelatedTimeSeriesAPI(appId, auth, queryParams, filters) {
    const url = makeDefaultQueryString(EVENT_DISTRIBUTION_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function eventRelatedTimeSeriesforRetentionAPI(appId, auth, queryParams, filters) {
    const url = makeDefaultQueryString(EVENT_DISTRIBUTION_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function eventRelatedCountDistributionAPI(appId, auth, queryParams, filters, type) {
    const url = makeDefaultQueryString(EVENT_COUNT_DISTRIBUTION_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return appendEmptyValueForSequence(json, "key", type, 0);
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function eventRelatedActiveHourDistributionAPI(appId, auth, queryParams, filters) {
    const url = makeDefaultQueryString(ACTIVE_EVENT_HOURS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        let formattedResponse = json.map(each => ({...each, key: Number(each.key)})).sort((a,b) => a.key < b.key);
        return formattedResponse.map(o => ({hour: o.key, "Event Count": o.value, index: 1}));
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function eventRelatedStickinessAPI(appId, auth, queryParams, filters) {
    const url = makeDefaultQueryString(EVENT_STICKINESS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return Array.isArray(json) ? json : [];
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


/**
 * ART APIS
 */
const ART_CONFIGS_API = "art-configs";
const ART_PUBLISH_API = "publish";
/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @returns {Promise}
 */

export function getArtConfigsAPI(appId, auth, queryParams) {
    const url = makeDefaultQueryString(ART_CONFIGS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


export function getArtConfigAPI(appId, auth, artConfigId, queryParams) {
    const url = makeDefaultQueryString(ART_CONFIGS_API + '/' + artConfigId, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function saveArtConfigAPI(appId, auth, queryParams, artConfig) {
    const url = makeDefaultQueryString(ART_CONFIGS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(artConfig)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function updateArtConfigAPI(appId, auth, artConfigId, queryParams, artConfig) {
    const url = makeDefaultQueryString(ART_CONFIGS_API + '/' + artConfigId, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "PUT",
        auth: auth,
        body: JSON.stringify(artConfig)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function deleteArtConfigAPI(appId, auth, artConfigId, queryParams) {
    const url = makeDefaultQueryString(ART_CONFIGS_API + '/' + artConfigId, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "DELETE",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function publishArtConfigAPI(appId, auth, queryParams) {
    const url = makeDefaultQueryString(ART_PUBLISH_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "PATCH",
        auth: auth,
        body: JSON.stringify({}) //FIXME: remove it after fix from server
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


/**
 * Tools APIs
 */

const FUNNELS_API = "funnels";
const FUNNELS_OPPORTUNITY_API = "funnels/groupby/opportunities";

export function getFunnelAPI(appId, auth, filters, body) {
    const url = makeDefaultQueryString(FUNNELS_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getFunnelOpportunityAPI(appId, auth, queryParams, body) {
    const url = makeDefaultQueryString(FUNNELS_OPPORTUNITY_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


/**
 * Paths APIs
 */

const EVENT_PATH_API = "graph/paths/events";
const SCREEN_PATH_API = "graph/paths/screens";

/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @param filters
 * @returns {Promise}
 */
export function getEventPathAPI(appId, auth, queryParams, filters) {
    const url = makeDefaultQueryString(EVENT_PATH_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @param filters
 * @returns {Promise}
 */
export function getScreenPathAPI(appId, auth, queryParams, filters) {
    const url = makeDefaultQueryString(SCREEN_PATH_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 * Retention APIs
 */

const RETENTION_USAGE_API = "retention-usage";


export function getRetentionUsageAPI(appId, auth, filters, extraFilters) {
    const url = makeDefaultQueryString(RETENTION_USAGE_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(extraFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


/**
 * Version on Version
 */

const VOV_TIME_SPENT_API = "version-on-version/time-spent";
const VOV_DAU_PERCENT = "version-on-version/dau-percent";
const VOV_AVG_EVENTS_PER_DAY = "version-on-version/events-per-day";

export function getVOVWeightedAverageTimeSpentPerDayAPI(appId, auth, filters, extraFilters) {
    const url = makeDefaultQueryString(VOV_TIME_SPENT_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(extraFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getVOVDAUPercentAPI(appId, auth, filters, extraFilters) {
    const url = makeDefaultQueryString(VOV_DAU_PERCENT, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(extraFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getVOVAverageEventsPerDayAPI(appId, auth, filters, extraFilters) {
    const url = makeDefaultQueryString(VOV_AVG_EVENTS_PER_DAY, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(extraFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


/**
 * Distributions
 */

const ATTRIBUTE_DISTRIBUTION_API = "distribution";

export function getAttributeDistributionsAPI(appId, auth, queryParams, filters = {user: [], session: [], event: []}) {
    const url = makeDefaultQueryString(ATTRIBUTE_DISTRIBUTION_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return Array.isArray(json) ? json : [];
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


/**
 * Conversion Rate APIS
 */

const CONVERSION_RATE_API = "conversion-rate/count";
const CONVERSION_RATE_TREND_API = "conversion-rate/time-series";
const CONVERSION_RATE_HOURLY_DISTRIBUTION_API = "conversion-rate/hourly-distribution";

export function getConversionRateAPI(appId, auth, queryParams, filters = {user: [], session: [], event: []}) {
    const url = makeDefaultQueryString(CONVERSION_RATE_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getConversionRateTrendAPI(appId, auth, queryParams, filters = {user: [], session: [], event: []}) {
    const url = makeDefaultQueryString(CONVERSION_RATE_TREND_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getConversionRateHourlyDistributionAPI(appId, auth, queryParams, filters = {user: [], session: [], event: []}) {
    const url = makeDefaultQueryString(CONVERSION_RATE_HOURLY_DISTRIBUTION_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}