import React, { Component } from 'react';
import {
    getFunnel
} from '../actions';
import withSelfComponent from './SelfComponent';
import {session} from "../../../../../../../utils/Storage";
import Typography from "@material-ui/core/Typography/Typography";
import {randomColorWithIndex, roundOffNumber} from "../../../../../../../utils";
import {Bar, BarChart, CartesianGrid, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import Box from "../../../../../../../components/reusable/Box";

const renderCustomizedLabel = (props) => {
    const { index, x, y, value } = props;
    const xR = 40, yR = 20;
    if(index){
        return (
            <g>
                <ellipse cx={x - xR} cy={y - yR} rx={xR} ry={yR} fill={randomColorWithIndex(7)} />
                <text x={x - xR} y={y - yR} fill="#000000" textAnchor="middle" dominantBaseline="middle">
                    {roundOffNumber(value, 1)} % &#10095;
                </text>
            </g>
        );
    }
};

class Funnel extends Component {

    hydrator = (props = {}) => {
        const {
            dataKey, extraFilters
        } = props.selfParams || {};
        const {dispatch, params: {appId}} = props;
        dispatch(getFunnel(appId, extraFilters, dataKey));
    };

    render(){
        const {
            self, selfParams: { dataKey, customLabel } = {},
            meta: { api_pending }, parentCompLabel
        } = this.props;
        if(!api_pending){
            let obj = {};
            obj[customLabel] = `${JSON.stringify(self[dataKey])}`;
            if(parentCompLabel !== undefined) {
                session.set(parentCompLabel, JSON.stringify(Object.assign({}, session.getParsed(parentCompLabel), obj )));
            }
            else {
                session.set(customLabel, JSON.stringify(obj));
            }
        }
        const { funnels = [], conversion_rate } = self[dataKey] || {};
        return (
            <Box
                title={customLabel}
                controls={
                    <Typography component="span" variant="subheading"><Typography variant="caption">Conversion Rate </Typography>{roundOffNumber(conversion_rate)}</Typography>
                }
            >
                <ResponsiveContainer height={300}>
                    <BarChart data={funnels}
                              margin={{top: 40, right: 10, left: 30, bottom: 5}}
                              color= "#519674" >
                        <XAxis dataKey="name"/>
                        <YAxis width={90} label={{ value: "Users", position: "insideLeft", angle: -90,   dy: 40}}/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip/>
                        <Bar type='monotone' dataKey="count" fill={randomColorWithIndex(0)}>
                            <LabelList content={renderCustomizedLabel} dataKey="percent" formatter={roundOffNumber}/>
                        </Bar>
                    </BarChart>
                </ResponsiveContainer>
            </Box>
        )
    };
}

Funnel.propTypes = {

};

export default withSelfComponent(Funnel);