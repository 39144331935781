import React, { Component, } from 'react';
import { toTitleCase } from "../../../../../../../utils";
import SortableDataTable from "../../../../../../../components/reusable/SortableDataTable";
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import RemoveIcon from '@material-ui/icons/Remove';
import withSelfComponent from "./SelfComponent";
import {getFlatTable} from "../actions";

class FlatTable extends Component {

    hydrator = (props = this.props) => {
        this.populateData(props);
    };

    populateData = (props = this.props) => {
        const {
            params: { appId },
            dispatch,
            selfParams:{
                extraFilters:{
                    metrics, dimensions, filters
                } = {},
                dataKey
            } = {}
        } = props;
        const query = {metrics, dimensions, filters};
        dispatch(getFlatTable(appId, query, dataKey))
    };

    render(){
        const {
            selfParams:{
                extraFilters:{
                    dimensions = [], metrics = []
                } = {},
                dataKey
            } = {},
            self,
        } = this.props;
        const { aggregations = {}, data = [] } = self[dataKey] || {};

        const headerLabels = [ ...dimensions.map(o => o.name), ...metrics.map(m => `${toTitleCase(m)} (${aggregations[m] || "NA"})`) ];
        let keys = [...dimensions.map(o => o.name), ...metrics];
        return(
            <div style={{width: '100%'}}>
                {
                    data.length > 0 && <SortableDataTable
                        {...this.props}
                        boxProps={{
                            title: "Flat Table"
                        }}
                        data={data}
                        headerLabels={headerLabels}
                        keys={keys}
                        tableType={"flat-table"}
                        downloadReportTitle={`FlatTable-${new Date().toDateString()}`}
                        needUpdate
                    />
                }
                {
                    data.length === 0 && <Placeholder withIcon icon={<RemoveIcon  color="error"/>}>No Data.</Placeholder>
                }
            </div>
        )
    }
}

export default withSelfComponent(FlatTable);