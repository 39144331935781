/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    RESET_RETENTION, RETENTION_GRAPH,
    RETENTION_GRAPH_PENDING, RETENTION_GRAPH_FAILED, RETENTION_ENUM,
    RETENTION_EVENTS_PENDING, RETENTION_EVENTS, RETENTION_EVENTS_FAILED,
    RETENTION_EVENT_ATTRIBUTES_PENDING, RETENTION_EVENT_ATTRIBUTES, RETENTION_EVENT_ATTRIBUTES_FAILED,
    RETENTION_EVENT_ATTRIBUTE_VALUES_PENDING, RETENTION_EVENT_ATTRIBUTE_VALUES, RETENTION_EVENT_ATTRIBUTE_VALUES_FAILED,
    RETENTION_UPDATE_QUERY_FIRST_EVENT, RETENTION_UPDATE_QUERY_NEXT_EVENT, UPDATE_RETENTION_TYPE,
    RETENTION_USAGE_PENDING, RETENTION_USAGE, RETENTION_USAGE_FAILED, UPDATE_RETENTION_USAGE_TYPE,
    RETENTION_USAGE_COUNT_PENDING, RETENTION_USAGE_COUNT, RETENTION_USAGE_COUNT_FAILED, RETENTION_DAY_WINDOW
} from './actionTypes';
import {COUNT_TYPE_ENUM, DAYS_ENUM_FOR_USAGE} from '../../../../../../constants';
import {
    getEventAttributesAPI, getEventAttributeValuesAPI, getEventsAPI, getRetentionDataAPI,
    getRetentionUsageCountAPI
} from './api';
import {getRetentionUsageAPI} from "../../../../../../api";
import {segmentFilterWithoutCounts} from "../../../../../../utils";

function normalizedQuery(queryObj) {
    return queryObj.hasOwnProperty("name") ? queryObj : null;
}

/**
 *
 * @param appId
 * @param retentionType
 * @returns {function(*, *)}
 */
export const getRetentionData = (appId, retentionType) => {
    return (dispatch, getState) => {
        let filters = {...getState().filters};
        const query = {...getState().retention.query};
        const dayWindow = getState().retention.retention_day_window;
        const propertyFilters = getState().segmentBuilder.query;
        retentionType = retentionType || getState().retention.retention_type;
        let compatibleQuery = {
            first_event: normalizedQuery(query.first_event),
            next_event: normalizedQuery(query.next_event),
            ...propertyFilters
        };
        if(retentionType !== RETENTION_ENUM.FTU){
            compatibleQuery = {first_event: compatibleQuery.first_event, ...propertyFilters}; //What contract is this ? :(
        }
        return dispatch({
            types: [
                RETENTION_GRAPH_PENDING,
                RETENTION_GRAPH,
                RETENTION_GRAPH_FAILED,
                RETENTION_DAY_WINDOW
            ],
            payload: {
                promise: getRetentionDataAPI(getState().auth, appId, filters, compatibleQuery, retentionType, dayWindow || 1)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @returns {function(*, *)}
 */
export const resetRetention = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_RETENTION,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @param userGroups
 * @returns {function(*, *)}
 */
/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const loadRetentionData = (appId) => {

    return (dispatch, getState) => {
        const { retention_type, retention_usage_type } = getState().retention;
        dispatch(getRetentionData(appId, retention_type));
        dispatch(getRetentionUsage(appId));
    };

};

export const getEventAttributes = (appId, event) => {
    return (dispatch, getState) => {
        const of = getState().queryBuilder.of || COUNT_TYPE_ENUM.users;
        const filters = {...getState().filters, event: event, of: of};
        return dispatch({
            types: [
                RETENTION_EVENT_ATTRIBUTES_PENDING,
                RETENTION_EVENT_ATTRIBUTES,
                RETENTION_EVENT_ATTRIBUTES_FAILED,
            ],
            payload: {
                promise: getEventAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            },
            callbacks: {
                successDidDispatch: (dispatch, attributes) => {
                    if(Array.isArray(attributes)) { // not need as of now
                        //dispatch(getEventAttributeDistributions(appId, event, attributes));
                    }else{
                        console.error("No event attributes fetched..!")
                    }
                }
            }
        });
    };
};

export const getEventAttributeValues = (appId, event, attribute, q) => {
    return (dispatch, getState) => {
        const of = getState().queryBuilder.of || COUNT_TYPE_ENUM.users;
        const filters = {...getState().filters, event: event, of: of, attribute: attribute, q: q};
        return dispatch({
            types: [
                RETENTION_EVENT_ATTRIBUTE_VALUES_PENDING,
                RETENTION_EVENT_ATTRIBUTE_VALUES,
                RETENTION_EVENT_ATTRIBUTE_VALUES_FAILED
            ],
            payload: {
                promise: getEventAttributeValuesAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute: attribute,
                event: event
            }
        });
    };
};

export const updateRetentionWindow = (dayWindow) => {
    return (dispatch, getState) => {
        return dispatch({
            type: RETENTION_DAY_WINDOW,
            payload: dayWindow,
            meta: {
                //
            }
        })
    }
}

export const updateFirstQuery = (appId, query = {}) => {
    return (dispatch, getState) => {
        return dispatch({
            type: RETENTION_UPDATE_QUERY_FIRST_EVENT,
            payload: query.name && query.name.length > 0 ? query : {},
            meta: {
                //
            }
        });
    };
};

export const updateNextQuery = (appId, query = {}) => {
    return (dispatch, getState) => {
        return dispatch({
            type: RETENTION_UPDATE_QUERY_NEXT_EVENT,
            payload: query.name && query.name.length > 0 ? query : {},
            meta: {
                //
            }
        });
    };
};

export const updateRetentionType = (appId, retentionType) => {
    return (dispatch, getState) => {
        dispatch(getRetentionData(appId, retentionType));
        return dispatch({
            type: UPDATE_RETENTION_TYPE,
            payload: retentionType,
            meta: {
                //
            }
        });
    };
};

/**
 *
 * @param appId
 * @param days
 * @returns {function(*, *)}
 */
export const getRetentionUsage = (appId, days = DAYS_ENUM_FOR_USAGE) => {
    return (dispatch, getState) => {
        const filters = {...getState().filters, day: days};
        const query = getState().segmentBuilder.query;
        return dispatch({
            types: [
                RETENTION_USAGE_PENDING,
                RETENTION_USAGE,
                RETENTION_USAGE_FAILED
            ],
            payload: {
                promise: getRetentionUsageAPI(appId, getState().auth, filters, query)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};