/**
 * Created by Rakesh Peela
 * Date: 20-Mar-2020
 * Time: 11:08 PM
 */
import * as APP_SETTINGS_A_T from "./actionTypes";

const INITIAL_STATE = {
    settings: {},
    progress: {
        get_settings_pending: false,
        get_settings_failed: false,
        update_settings_failed: false,
        update_settings_pending: false
    }
};

function generalSettingsReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case APP_SETTINGS_A_T.GET_APP_SETTINGS:
            return {
                ...state,
                settings: {
                    ...action.payload
                },
                progress: {
                    ...state.progress,
                    get_settings_pending: false,
                    get_settings_failed: false,
                }
            };
        case APP_SETTINGS_A_T.GET_APP_SETTINGS_FAILED: {
            return {
                ...state,
                progress: {
                    ...state.progress,
                    get_settings_failed: true
                }
            }
        }
        case APP_SETTINGS_A_T.GET_APP_SETTINGS_PENDING: {
            return {
                ...state,
                progress: {
                    ...state.progress,
                    get_settings_pending: true
                }
            }
        }

        case APP_SETTINGS_A_T.UPDATE_APP_SETTINGS:
            return {
                ...state,
                progress: {
                    ...state.progress,
                    update_settings_pending: false,
                    update_settings_failed: false,
                }
            };
        case APP_SETTINGS_A_T.UPDATE_APP_SETTINGS_FAILED: {
            return {
                ...state,
                progress: {
                    ...state.progress,
                    update_settings_failed: true
                }
            }
        }
        case APP_SETTINGS_A_T.UPDATE_APP_SETTINGS_PENDING: {
            return {
                ...state,
                progress: {
                    ...state.progress,
                    update_settings_pending: true
                }
            }
        }

        case APP_SETTINGS_A_T.RESET_GENERAL_SETTINGS_STATE:
            return {
                ...INITIAL_STATE
            };

        default:
            return {
                ...state
            };

    }
}

export default generalSettingsReducer;