/**
 * Created by Rakesh Peela
 * Date: 05-Jan-2020
 * Time: 3:40 PM
 */

import {Divider} from "@material-ui/core";
import React from "react";

export default class ModuleDivider extends React.Component {
    render() {
        return (
            <Divider
                style={{
                    background: "1px solid #e4eaec",
                    marginBottom: 8,
                    marginTop: 8
                }}
            />
        );
    }
}
