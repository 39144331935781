import React from 'react';
import Box from '../Box';
import DonutChart from '../Recharts/Donut';
import Placeholder from '../Placeholder';
import Button from '@material-ui/core/Button';
import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from "prop-types";
import PersonIcon from '@material-ui/icons/Person';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ClearIcon from '@material-ui/icons/Clear';
import MultiSelect from "../MaterialUi/MultiSelect";

export class Distributions extends React.Component {

    render(){
        const {
            data, isForUninstall =  false, stats = {},
            handleSelect, selections = {}, messageForNoData = "",
            notSelectable = false
        } = this.props || {};
        const isPending = this.props.meta.api_pending;
        return(
            <div style={{padding: 10, display: 'flex', flexWrap: "wrap", textAlign: 'center', alignContent: "space-between", alignItems: "center",justifyContent: "space-between"}}>
                {
                    Object.keys(data).map(item =>
                        <div key={"distributions" + item} style={{minHeight:'180px', minWidth: '200px', maxWidth: '250px'}}>
                            <DonutChart
                                onClickHandler={
                                    notSelectable ? null : (values) => handleSelect(item, values)
                                }
                                data={data[item]}
                                height={210}
                                isForUninstall={isForUninstall}
                                totalUsers={stats.users}
                                innerRadius={50}
                                outerRadius={75}
                                title={item}
                                selected={selections[item] || []}
                                isPending={isPending}
                            />
                        </div>
                    )
                }
                { !isPending && Object.keys(data).length === 0 && <Placeholder height="auto" text={messageForNoData}/> }
            </div>
        )
    }

}

export default class AttributeDistributions extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            selectedUserAttributes: [],
            selectedSessionAttributes: [],
            //attributes
            ...this.emptyAttributes(props)
        };
        this.count = 0;
        this.show = false;
        this.filtersApplied = false;
    }

    emptyAttributes = (props) => {
        let { attributes = [], userAttributes = [], sessionAttributes = [] } = props;
        if(attributes.length === 0){
            attributes = [...userAttributes, ...sessionAttributes];
        }
        return {...attributes.reduce((a, b) => {a[b] = []; return a;}, {})};
    };

    updateFilters = () => {
        this.show = true;
        this.filtersApplied = !this.filtersApplied;
        const { updateFilters } = this.props;
        if(typeof updateFilters === 'function'){
            const { selectedUserAttributes, selectedSessionAttributes, ...filters } = this.state;
            this.props.updateFilters(filters);
            //this.setState(USER_GROUP_FILTERS); //use it later
        }
    };

    componentWillReceiveProps(nextProps){
        const { selectedUserAttributes = [], selectedSessionAttributes = [] } = this.state;
        if(selectedUserAttributes.length === 0 && selectedSessionAttributes.length === 0){
            const emptyAttributes = this.emptyAttributes(nextProps);
            this.setState({...emptyAttributes});
        }
    }

    selectHandler  = (type, data) => {
        let key = data.name || data.key;
        let array = this.state[type];
        if(array.indexOf(key) > -1){
            array.splice(array.indexOf(key), 1);
            this.count--;
        } else{
            array.push(key);
            this.count++;
        }
        this.show = true;
        this.filtersApplied = false;
        this.setState({[type]: array });
    };

    cancelFilters = () => {
        const emptyAttributes = this.emptyAttributes(this.props);
        this.setState({...emptyAttributes});
        this.filtersApplied = false;
        this.show = false;
        this.count = 0;
        const { updateFilters = () => null } = this.props;
        updateFilters(emptyAttributes);
    };

    handleRequestDelete = (data) => { //handles delete on chip
        for(let type in this.state) { //FIXME: based on key if 2 keys are same it's a dodgy code.
            if(this.state.hasOwnProperty(type) && type !== "open") {
                let array = this.state[type];
                if (array.indexOf(data) > -1) {
                    array.splice(array.indexOf(data), 1);
                    this.count--;
                    this.setState({[type]: array}, () => {
                        if(this.count === 0){
                            this.filtersApplied = false;
                            this.show = false;
                            const { updateFilters = () => null } = this.props;
                            updateFilters({});//resetting filters
                        }
                    });
                    return;
                }
            }
        }
    };

    handleUserAttributeChange = (selectedAttributes = []) => {
        const { selectedUserAttributes = [] } = this.state;
        const { loadAttributeData } = this.props;
        if(selectedAttributes.length > selectedUserAttributes.length){
            selectedAttributes.forEach(attribute => {
                if(!selectedUserAttributes.includes(attribute)){
                    loadAttributeData(attribute);
                }
            });
        }
        if(!selectedAttributes.length){
            this.cancelFilters();
        }
        this.setState({selectedUserAttributes: selectedAttributes});
    };

    handleSessionAttributeChange = (selectedAttributes = []) => {
        const { loadAttributeData } = this.props;
        const { selectedSessionAttributes = [] } = this.state;
        if(selectedAttributes.length > selectedSessionAttributes.length){
            selectedAttributes.forEach(attribute => {
                if(!selectedSessionAttributes.includes(attribute)){
                    loadAttributeData(attribute);
                }
            });
        }
        if(!selectedAttributes.length){
            this.cancelFilters();
        }
        this.setState({selectedSessionAttributes: selectedAttributes});
    };

    render(){
        const {
            title, icon, data,
            withoutBox = false, userAndSessionAttributes = false,
            userAttributes = [], sessionAttributes = [], attributes = [],
            withoutAttributeSelect = false, boxCollapsible = false
        } = this.props;
        const { selectedUserAttributes = [], selectedSessionAttributes = [] } = this.state;
        if(userAndSessionAttributes){
            const userDistributions = selectedUserAttributes.reduce((a, b) => {a[b] = data[b]; return a;}, {});
            const sessionDistributions = selectedSessionAttributes.reduce((a, b) => {a[b] = data[b]; return a;}, {});
            return(
                <Box
                    withPadding
                    title={title}
                    icon={icon}
                    collapsible
                    controls={
                        this.count > 0 && this.show &&
                        <Button
                            variant="contained"
                            color={ this.filtersApplied ? "secondary" : "primary" }
                            onClick={(e) => {
                                e.stopPropagation();
                                if(this.filtersApplied){
                                    this.cancelFilters();
                                }else{
                                    this.updateFilters();
                                }
                            }}
                        >
                            { this.filtersApplied ? "Cancel " : "Apply " } &nbsp;
                            { this.filtersApplied ? <ClearIcon/> : <FilterListIcon /> }
                        </Button>
                    }
                >
                    { this.props.children }
                    <Box
                        withPadding
                        title="User Properties"
                        icon={<PersonIcon/>}
                        controls={
                            <MultiSelect
                                placeholder="Select Properties"
                                value={selectedUserAttributes}
                                options={userAttributes.map(item => ({label: item, value: item}))}
                                handleChange={this.handleUserAttributeChange}
                                style={{maxWidth: 600}}
                            />
                        }
                    >
                        <Distributions
                            {...this.props}
                            messageForNoData="No User Properties Selected"
                            data={userDistributions} handleSelect={this.selectHandler}
                            selections={this.state}
                        />
                    </Box>
                    <Box
                        withPadding
                        title="Session Properties"
                        icon={<AccessTimeIcon/>}
                        controls={
                            <MultiSelect
                                placeholder="Select Properties"
                                value={selectedSessionAttributes}
                                options={sessionAttributes.map(item => ({label: item, value: item}))}
                                handleChange={this.handleSessionAttributeChange}
                                style={{maxWidth: 600}}
                            />
                        }
                    >
                        <Distributions
                            {...this.props}
                            messageForNoData="No Session Properties Selected"
                            data={sessionDistributions} handleSelect={this.selectHandler}
                            selections={this.state}
                        />
                    </Box>

                </Box>
            )
        }
        if(withoutBox){
            return(
                <Distributions
                    {...this.props}
                    data={data} handleSelect={this.selectHandler}
                    selections={this.state}
                />
            )
        }else if(withoutAttributeSelect){
            return(
                <Box
                    withPadding
                    title={title}
                    icon={icon}
                    controls={
                        this.count > 0 && this.show &&
                        <Button
                            variant="contained"
                            color={ this.filtersApplied ? "secondary" : "primary" }
                            onClick={this.filtersApplied ? this.cancelFilters : this.updateFilters}
                        >
                            { this.filtersApplied ? "Cancel " : "Apply " } &nbsp;
                            { this.filtersApplied ? <ClearIcon/> : <FilterListIcon /> }
                        </Button>
                    }
                    collapsible={boxCollapsible}
                >
                    <Distributions
                        {...this.props}
                        data={data} handleSelect={this.selectHandler}
                        selections={this.state}
                        messageForNoData="No Properties Selected"
                    />
                </Box>
            )
        }else{
            const distributionsData = selectedUserAttributes.reduce((a, b) => {a[b] = data[b]; return a;}, {});
            return(
                <Box
                    collapsible
                    withPadding
                    title={title}
                    icon={icon}
                    controls={
                        <div style={{display: 'flex', minWidth: 500}}>
                            <MultiSelect
                                placeholder="Select Properties"
                                value={selectedUserAttributes}
                                options={attributes.map(item => ({label: item, value: item}))}
                                handleChange={this.handleUserAttributeChange}
                                style={{maxWidth: 600}}
                            />
                            {this.count > 0 && this.show &&
                                <Button
                                    variant="contained"
                                    color={ this.filtersApplied ? "secondary" : "primary" }
                                    onClick={this.filtersApplied ? this.cancelFilters : this.updateFilters}
                                >
                                    { this.filtersApplied ? "Cancel " : "Apply " } &nbsp;
                                    { this.filtersApplied ? <ClearIcon/> : <FilterListIcon /> }
                                </Button>
                            }
                        </div>
                    }
                >
                    <Distributions
                        messageForNoData="No Properties Selected"
                        {...this.props}
                        data={distributionsData} handleSelect={this.selectHandler}
                        selections={this.state}
                    />
                </Box>
            )
        }
    }

}

AttributeDistributions.propTypes = {
    data: PropTypes.object.isRequired,
    attributes: PropTypes.array,
    updateFilters: PropTypes.func,
    messageForNoData: PropTypes.any,
    userAndSessionAttributes: PropTypes.bool,
    notSelectable: PropTypes.bool,
    withoutAttributeSelect: PropTypes.bool
};