
export const DROP_OFF_ENUM = {
    SCREEN: "screens",
    EVENT: "events",
    SESSION: "sessions"
};

export const DROP_OFF_SESSIONS_COUNT_PENDING = "DROP_OFF_SESSIONS_COUNT_PENDING";
export const DROP_OFF_SESSIONS_COUNT = "DROP_OFF_SESSIONS_COUNT";
export const DROP_OFF_SESSIONS_COUNT_FAILED = "DROP_OFF_SESSIONS_COUNT_FAILED";

export const DROP_OFF_SCREENS_COUNT_PENDING = "DROP_OFF_SCREENS_COUNT_PENDING";
export const DROP_OFF_SCREENS_COUNT = "DROP_OFF_SCREENS_COUNT";
export const DROP_OFF_SCREENS_COUNT_FAILED = "DROP_OFF_SCREENS_COUNT_FAILED";

export const DROP_OFF_EVENTS_COUNT_PENDING = "DROP_OFF_EVENTS_COUNT_PENDING";
export const DROP_OFF_EVENTS_COUNT = "DROP_OFF_EVENTS_COUNT";
export const DROP_OFF_EVENTS_COUNT_FAILED = "DROP_OFF_EVENTS_COUNT_FAILED";

export const DROP_OFF_BY_SCREENS_PENDING = "DROP_OFF_BY_SCREENS_PENDING";
export const DROP_OFF_BY_SCREENS = "DROP_OFF_BY_SCREENS";
export const DROP_OFF_BY_SCREENS_FAILED = "DROP_OFF_BY_SCREENS_FAILED";

export const DROP_OFF_BY_EVENTS_PENDING = "DROP_OFF_BY_EVENTS_PENDING";
export const DROP_OFF_BY_EVENTS = "DROP_OFF_BY_EVENTS";
export const DROP_OFF_BY_EVENTS_FAILED = "DROP_OFF_BY_EVENTS_FAILED";

export const DROP_OFF_SCREEN_TIME_SPENT_PENDING = "DROP_OFF_SCREEN_TIME_SPENT_PENDING";
export const DROP_OFF_SCREEN_TIME_SPENT = "DROP_OFF_SCREEN_TIME_SPENT";
export const DROP_OFF_SCREEN_TIME_SPENT_FAILED = "DROP_OFF_SCREEN_TIME_SPENT_FAILED";

export const DROP_OFF_SCREEN_EVENTS_PENDING = "DROP_OFF_SCREEN_EVENTS_PENDING";
export const DROP_OFF_SCREEN_EVENTS = "DROP_OFF_SCREEN_EVENTS";
export const DROP_OFF_SCREEN_EVENTS_FAILED = "DROP_OFF_SCREEN_EVENTS_FAILED";

export const DROP_OFF_SCREEN_LAST_EVENTS_PENDING = "DROP_OFF_SCREEN_LAST_EVENTS_PENDING";
export const DROP_OFF_SCREEN_LAST_EVENTS = "DROP_OFF_SCREEN_LAST_EVENTS";
export const DROP_OFF_SCREEN_LAST_EVENTS_FAILED = "DROP_OFF_SCREEN_LAST_EVENTS_FAILED";

export const UPDATE_DROP_OFF_CURRENT_ACTIVE = "UPDATE_DROP_OFF_CURRENT_ACTIVE";

export const UPDATE_DROP_OFF_GROUP = "UPDATE_DROP_OFF_GROUP";

export const UPDATE_DROP_OFF_SEGMENT = "UPDATE_DROP_OFF_SEGMENT";

export const UPDATE_DROP_OFF_FILTERS = "UPDATE_DROP_OFF_FILTERS";

export const UPDATE_DROP_OFF_SCREEN = "UPDATE_DROP_OFF_SCREEN";

export const UPDATE_DROP_OFF_EVENT = "UPDATE_DROP_OFF_EVENT";

export const UPDATE_DROP_TIME_SLOT = "UPDATE_DROP_TIME_SLOT";

export const DROP_OFF_SCREEN_EVENT_ATTRIBUTES_PENDING = "DROP_OFF_SCREEN_EVENT_ATTRIBUTES_PENDING";
export const DROP_OFF_SCREEN_EVENT_ATTRIBUTES = "DROP_OFF_SCREEN_EVENT_ATTRIBUTES";
export const DROP_OFF_SCREEN_EVENT_ATTRIBUTES_FAILED = "DROP_OFF_SCREEN_EVENT_ATTRIBUTES_FAILED";

export const UPDATE_DROP_OFF_SCREEN_EVENT = "UPDATE_DROP_OFF_SCREEN_EVENT";

export const DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES_PENDING = "DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES_PENDING";
export const DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES = "DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES";
export const DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES_FAILED = "DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES_FAILED";

export const UPDATE_DROP_OFF_SCREEN_LAST_EVENT = "UPDATE_DROP_OFF_SCREEN_LAST_EVENT";

export const DROP_OFF_EVENT_ATTRIBUTES_PENDING = "DROP_OFF_EVENT_ATTRIBUTES_PENDING";
export const DROP_OFF_EVENT_ATTRIBUTES = "DROP_OFF_EVENT_ATTRIBUTES";
export const DROP_OFF_EVENT_ATTRIBUTES_FAILED = "DROP_OFF_EVENT_ATTRIBUTES_FAILED";

export const DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING = "DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING";
export const DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION = "DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION";
export const DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED = "DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED";

export const DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING = "DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING";
export const DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION = "DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION";
export const DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED = "DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED";

export const DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING = "DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING";
export const DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION = "DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION";
export const DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED = "DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED";

export const DROP_OFF_SCREEN_USERS_PENDING = "DROP_OFF_SCREEN_USERS_PENDING";
export const DROP_OFF_SCREEN_USERS = "DROP_OFF_SCREEN_USERS";
export const DROP_OFF_SCREEN_USERS_FAILED = "DROP_OFF_SCREEN_USERS_FAILED";

export const DROP_OFF_SCREEN_EVENT_USERS_PENDING = "DROP_OFF_SCREEN_EVENT_USERS_PENDING";
export const DROP_OFF_SCREEN_EVENT_USERS = "DROP_OFF_SCREEN_EVENT_USERS";
export const DROP_OFF_SCREEN_EVENT_USERS_FAILED = "DROP_OFF_SCREEN_EVENT_USERS_FAILED";

export const DROP_OFF_SCREEN_LAST_EVENT_USERS_PENDING = "DROP_OFF_SCREEN_LAST_EVENT_USERS_PENDING";
export const DROP_OFF_SCREEN_LAST_EVENT_USERS = "DROP_OFF_SCREEN_LAST_EVENT_USERS";
export const DROP_OFF_SCREEN_LAST_EVENT_USERS_FAILED = "DROP_OFF_SCREEN_LAST_EVENT_USERS_FAILED";

export const DROP_OFF_EVENT_USERS_PENDING = "DROP_OFF_EVENT_USERS_PENDING";
export const DROP_OFF_EVENT_USERS = "DROP_OFF_EVENT_USERS";
export const DROP_OFF_EVENT_USERS_FAILED = "DROP_OFF_EVENT_USERS_FAILED";