import React, { Component } from 'react';
import {Button, Grid } from "@material-ui/core";
import Switch from "../../../../../../components/reusable/Switch";
import {USER_GROUPS} from "../../../../../../constants";
import Typography from "@material-ui/core/Typography";
import {UserAndSessionProperties} from "../Segments/NewSegment/components";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import FilterListIcon from '@material-ui/icons/FilterList';
import EventIcon from '@material-ui/icons/Event';
import SmartPhoneIcon from '@material-ui/icons/Smartphone';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import PieChartIcon from '@material-ui/icons/PieChart';
import {
    formatNumber, goToByScroll
} from "../../../../../../utils";
import Box from "../../../../../../components/reusable/Box";
import NumberBox from "../../../../../../components/reusable/MaterialUi/NumberBox";
import MultiUtilityChart from "../../../../../../components/reusable/Recharts/MultiUtilityChart";
import {DROP_OFF_ENUM} from "./actionTypes";
import AttributeDistributions from "../../../../../../components/reusable/AttributeDistributions";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import UsersList from "../../../../../../components/reusable/UsersList";

export class Filters extends Component {

    constructor(props){
        super(props);
        this.state = {
            user: [],
            session: [],
            minSessionLength: 0,
            maxSessionLength: 60
        }
    }

    render(){
        const {
            params: { appId },

            dropOff: {
                group, user = [], session = [],
            },
            updateFilters, updateGroup, loadData
        } = this.props;
        return(
            <Grid container style={{marginBottom: 10}} justify="flex-end">
                <Grid item xs={12} sm={6} style={{marginBottom: 10}}>
                    <Switch
                        data={USER_GROUPS}
                        value={group}
                        handleChange={(group) => {
                            updateGroup(group);
                            loadData(appId);
                        }}
                        containerStyles={{margin: 0}}
                    />
                </Grid>
                {/*<Grid item xs={12} sm={4}>
                        <MultiSelect
                            options={segment_list.map(o => ({label: o.name, value: o._id}))}
                            value={segmentId}
                            handleChange={updateSegment}
                            placeholder={"Select Segment"}
                            single
                        />
                    </Grid>*/}
                <Grid item xs={12}>
                    <Box
                        icon={<FilterListIcon/>}
                        title="Apply Filters"
                        collapsible
                        withPadding
                    >
                        <UserAndSessionProperties
                            {...this.props}
                            handleQueryUpdate={filters => {
                                updateFilters(filters);
                                this.setState(filters);
                            }}
                            user={user}
                            session={session}
                            withoutBox
                        />
                        <Button style={{marginLeft: 'auto', display: 'block'}} variant="contained" color="primary" onClick={e => loadData(appId)}>Apply</Button>
                    </Box>
                </Grid>
            </Grid>
        )
    }

}

export class Counts extends Component {

    render(){
        const { dropOff: { session_count = 0, screen_count = 0, event_count = 0 } = { } } = this.props;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12} sm>
                    <NumberBox heading={formatNumber(session_count)} subheading="Sessions" icon={<AccessTimeIcon/>}/>
                </Grid>
                <Grid item xs={12} sm>
                    <NumberBox heading={formatNumber(screen_count)} subheading="Screens" icon={<SmartPhoneIcon/>}/>
                </Grid>
                <Grid item xs={12} sm>
                    <NumberBox heading={formatNumber(event_count)} subheading="Events" icon={<EventIcon/>}/>
                </Grid>
            </Grid>
        );
    }

}

const { SCREEN, EVENT } = DROP_OFF_ENUM;

const SCREEN_DETAILS_ID = "screen-details";

const SCREEN_EVENTS_ENUM = {
    ALL: "All Events",
    LAST: "Last Events",
};

export class DropOffDetails extends Component {

    state = {
        currentScreenEvents: SCREEN_EVENTS_ENUM.ALL
    };

    componentWillReceiveProps(nextProps){
        const {dropOff: { screen } = { }} = this.props;
        if(nextProps.dropOff.screen !== screen){
            setTimeout(() => {
                goToByScroll(SCREEN_DETAILS_ID);
            }, 100);
        }
    }

    render(){
        const {
            params: { appId },
            dropOff: {
                current, by_screen = [], screen, screen_time_spent = [],
                screen_events = [], screen_last_events = [],
                current_event, current_screen_event, current_screen_last_event,
                by_event = [],
                event_attribute_distribution, screen_event_attribute_distribution, screen_last_event_attribute_distribution,
                event_attributes = [], screen_event_attributes = [], screen_last_event_attributes = [],
                screen_users = [], screen_event_users = [], screen_last_event_users = [], event_users = []
            } = { },
            getScreenData, updateCurrentActive, updateTimeSlot,
            updateEvent, updateScreenEvent, updateScreenLastEvent,
            getEventAttributeDistribution, getScreenEventAttributeDistribution, getScreenLastEventAttributeDistribution,
            getScreenUsers, getScreenEventUsers, getScreenLastEventUsers, getEventUsers
        } = this.props;
        const { currentScreenEvents } = this.state;
        return(
            <Grid container spacing={16}>
                <Grid item xs={12}>
                    <div style={{display: 'flex', padding: 5}}>
                        <Typography variant="subtitle1" style={{marginTop: '0.7em'}}>
                            Analyse drop offs by &nbsp;
                        </Typography>
                        <Switch
                            data={[SCREEN, EVENT]}
                            handleChange={current => updateCurrentActive(appId, current)}
                            value={current}
                            containerStyles={{margin: 0}}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    {
                        current === SCREEN &&
                        <Box
                            icon={<InsertChartIcon/>}
                            title="Drop Offs by Screen"
                            withPadding
                            collapsible
                            defaultExpanded
                            footer={
                                screen && <Grid container id={SCREEN_DETAILS_ID}>
                                    <Grid item xs>
                                        <Box
                                            icon={<AccessTimeIcon/>}
                                            title="Time Spent in Screen"
                                            withPadding
                                            collapsible
                                            defaultExpanded
                                            footer={
                                                <ShowUsers data={screen_users} handleRequest={() => getScreenUsers(appId, screen)} appId={appId}/>
                                            }
                                        >
                                            <MultiUtilityChart
                                                data={screen_time_spent}
                                                barDataKeys={["Sessions", "Users"]}
                                                dataKey="key"
                                                xLabel="Seconds"
                                                yLabel="Sessions"
                                                xLabelFormatter={e => e}
                                                showBrush
                                                clickHandler={(timeSlot, event, payload) => {
                                                    updateTimeSlot(timeSlot);
                                                    getScreenData(appId, screen);
                                                    getScreenUsers(appId, screen); //calling for users by default
                                                }}
                                            />
                                        </Box>
                                        <Switch
                                            data={[SCREEN_EVENTS_ENUM.ALL, SCREEN_EVENTS_ENUM.LAST]}
                                            handleChange={currentScreenEvents => {
                                                this.setState({currentScreenEvents});
                                                //FIXME: Just hack, need proper solution, avoid network call by any means
                                                updateScreenEvent(appId, screen, null);
                                                updateScreenLastEvent(appId, screen, null);
                                            }}
                                            value={currentScreenEvents}
                                        />
                                        {
                                            currentScreenEvents === SCREEN_EVENTS_ENUM.ALL &&
                                            <Box
                                                icon={<EventIcon/>}
                                                title="All Events in Screen"
                                                withPadding
                                                collapsible
                                                defaultExpanded
                                                footer={
                                                    current_screen_event && <div>
                                                        <ShowUsers data={screen_event_users} handleRequest={() => getScreenEventUsers(appId, screen, current_screen_event)} appId={appId}/>
                                                        <br />
                                                        <AttributeDistributions
                                                            {...this.props}
                                                            title={`${current_screen_event} Property Distributions`}
                                                            icon={<PieChartIcon/>}
                                                            data={screen_event_attribute_distribution}
                                                            stats={{}}
                                                            resetFilters={false}
                                                            attributes={screen_event_attributes}
                                                            notSelectable
                                                            loadAttributeData={attribute => getScreenEventAttributeDistribution(appId, screen, current_screen_event, attribute)}
                                                        />
                                                    </div>
                                                }
                                            >
                                                <MultiUtilityChart
                                                    clickHandler={(eventName, event, payload) => {
                                                        updateScreenEvent(appId, screen, eventName);
                                                        getScreenEventUsers(appId, screen, eventName);
                                                    }}
                                                    data={screen_events}
                                                    barDataKeys={["Occurrences", "Users"]}
                                                    dataKey="key"
                                                    xLabel="Events"
                                                    yLabel="Occurrences"
                                                    xLabelFormatter={e => e}
                                                    showBrush
                                                />
                                            </Box>
                                        }
                                        {
                                            currentScreenEvents === SCREEN_EVENTS_ENUM.LAST &&
                                            <Box
                                                icon={<EventIcon/>}
                                                title="Last Events in Screen"
                                                withPadding
                                                collapsible
                                                defaultExpanded
                                                footer={
                                                    current_screen_last_event &&
                                                    <div>
                                                        <ShowUsers data={screen_last_event_users} handleRequest={() => getScreenLastEventUsers(appId, screen, current_screen_last_event)} appId={appId}/>
                                                        <br />
                                                        <AttributeDistributions
                                                            {...this.props}
                                                            title={`${current_screen_last_event} Property Distributions`}
                                                            icon={<PieChartIcon/>}
                                                            data={screen_last_event_attribute_distribution}
                                                            stats={{}}
                                                            resetFilters={false}
                                                            attributes={screen_last_event_attributes}
                                                            notSelectable
                                                            loadAttributeData={attribute => getScreenLastEventAttributeDistribution(appId, screen, current_screen_last_event, attribute)}
                                                        />
                                                    </div>
                                                }
                                            >
                                                <MultiUtilityChart
                                                    clickHandler={(eventName, event, payload) => {
                                                        updateScreenLastEvent(appId, screen, eventName);
                                                        getScreenLastEventUsers(appId, screen, eventName);
                                                    }}
                                                    data={screen_last_events}
                                                    barDataKeys={["Occurrences", "Users"]}
                                                    dataKey="key"
                                                    xLabel="Events"
                                                    yLabel="Occurrences"
                                                    xLabelFormatter={e => e}
                                                    showBrush
                                                />
                                            </Box>
                                        }
                                    </Grid>
                                </Grid>
                            }
                        >
                            <MultiUtilityChart
                                data={by_screen}
                                clickHandler={(label, event, payload) => {
                                    getScreenData(appId, label);
                                }}
                                barDataKeys={["Sessions", "Users"]}
                                dataKey="Screen"
                                xLabel="Screens"
                                yLabel="Drop Off Sessions"
                                xLabelFormatter={e => e}
                                showBrush
                            />
                        </Box>
                    }
                    {
                        current === EVENT &&
                        <Box
                            icon={<InsertChartIcon/>}
                            title="Drop Offs by Event"
                            withPadding
                            collapsible
                            defaultExpanded
                            footer={
                                current_event &&
                                <div>
                                    <ShowUsers data={event_users} handleRequest={() => getEventUsers(appId, current_event)} appId={appId}/>
                                    <br />
                                    <AttributeDistributions
                                        {...this.props}
                                        title={`${current_event} Property Distributions`}
                                        icon={<PieChartIcon/>}
                                        data={event_attribute_distribution}
                                        stats={{}}
                                        resetFilters={false}
                                        attributes={event_attributes}
                                        notSelectable
                                        loadAttributeData={attribute => getEventAttributeDistribution(appId, current_event, attribute)}
                                    />
                                </div>
                            }
                        >
                            <MultiUtilityChart
                                data={by_event}
                                clickHandler={(eventName, event, payload) => {
                                    updateEvent(appId, eventName);
                                    getEventUsers(appId, eventName);
                                }}
                                barDataKeys={["Sessions", "Users"]}
                                dataKey="Event"
                                xLabel="Events"
                                yLabel="Drop Off Sessions"
                                xLabelFormatter={e => e}
                                showBrush
                            />
                        </Box>
                    }
                </Grid>
            </Grid>
        );
    }

}

class ShowUsers extends Component {

    render(){
        const { handleRequest, data = [], appId } = this.props;
        return(
            <ExpansionPanel onChange={(event, expanded) => {
                if(expanded){
                    handleRequest();
                }
            }}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Show Sample Users</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Grid container>
                        <Grid item xs>
                            <UsersList data={data} appId={appId}/>
                        </Grid>
                    </Grid>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        )
    }

}