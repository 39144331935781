import React from 'react';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router';
import PropTypes from 'prop-types';
import Fade from '@material-ui/core/Fade';

class UserListPopover extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            anchorEl: props.anchorEl || null,
        };
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    componentWillReceiveProps(nextProps){
        const { anchorEl, hideButton } = nextProps;
        if(hideButton){
            this.setState({anchorEl});
        }
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { anchorEl } = this.state;
        const {
            params: { appId }, id = 'fade-menu', handleClick,
            users = [], label = "View Users", hideButton = false,
            handleClose
        } = this.props;
        const strippedUsers = users.slice(0, 5);
        return (
            <div>
                { !hideButton &&
                    <Button
                        aria-owns={anchorEl ? id : null}
                        aria-haspopup="true"
                        onClick={(e) => {
                            this.handleClick(e);
                            if (strippedUsers.length === 0) {
                                handleClick(e);
                            }
                        }}
                    >
                        {label}
                    </Button>
                }
                {
                    strippedUsers.length > 0 && <Menu
                        id={id}
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={(e) => {
                            e.stopPropagation(); //preventing click for onClose
                            this.handleClose();
                            if(typeof handleClose === 'function'){
                                handleClose();
                            }
                        }}
                        transition={Fade}
                    >
                        {
                            strippedUsers.map((userId, index) =>
                                <MenuItem key={userId}><Link target="_blank" to={`/apps/${appId}/user/${userId}`}>{`User ${index + 1}`}</Link></MenuItem>
                            )
                        }
                        <MenuItem key="more-key">
                            <Link target="_blank" to={`/apps/${appId}/segments`}>More</Link>
                        </MenuItem>
                    </Menu>
                }
            </div>
        );
    }
}

UserListPopover.propTypes = {
    id: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    users: PropTypes.array,
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node
    ]),
    anchorEl: PropTypes.any,
    hideButton: PropTypes.bool,
    handleClose: PropTypes.func,
};

export default UserListPopover;