/**
 * Created by jyothi on 14/1/17.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {updateHeading} from '../../../Navigation/actions';
import ResultsWrapper from './components';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        meta: state.meta,
        filters: state.filters,
        messages: state.messages
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        loadData: (appId, actionId) => {

        }
    };
}

class MessageResults extends Component {

    constructor(props){
        super(props);
        this.state = {

        };
    }

    componentWillMount(){
        const {appId} = this.props.params;
        this.props.loadData(appId); //for Configurator
        this.props.updateHeading("WIP: Message Results");
    }

    componentWillReceiveProps(nextProps){

    }

    componentWillUnmount(){

    }

    render() {
        return (
            <section className="content">
                <ResultsWrapper {...this.props}/>
            </section>
        );
    }
}

MessageResults.propTypes = {

};

MessageResults.contextTypes = {
    router: PropTypes.object.isRequired
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MessageResults);
