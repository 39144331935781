import React from 'react';
import {
    COUNT_TYPE_ENUM
} from '../../../../../../../../constants/index';
import Box from "../../../../../../../../components/reusable/Box";
import Grid from '@material-ui/core/Grid';
import {uniqueKeyFromFilters, getGroupNameFromList } from "../../../../../../../../utils/index";
import Count from "../ConversionRate/Count";
import Trend from "../ConversionRate/Trend";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import withSelfComponent from '../SelfComponent';
import TabLabel from "../../../../../../../../components/reusable/TabLabel";

class TabbedWidget extends React.Component { //FIXME: need better name

    state = {
        currentEvent: 0,
        widgetLocalData: {}
    };

    getDataKey = (props) => {
        const { name, queryParams, extraFilters, extension  } = props;
        const keyFromQueryParams = Object.keys(queryParams).map(o => [o, queryParams[o]].join("_")).join("_");
        return [ name, keyFromQueryParams, uniqueKeyFromFilters(extraFilters), extension ].filter(o => o.length > 0).join("_");
    };

    getCustomLabel = (props) => {
        const { name, extension  } = props;
        return `${name} ${extension}`;
    };

    render(){
        const {
            customQueryBuilder: { group },
            appState: { appSegments = [] },
            selfParams: { segment, startEvent, endEvent, extraFilters, queryParams, customLabel }
        } = this.props;
        let segmentName = null;
        if(segment){
            segmentName = getGroupNameFromList(appSegments, segment);
        }
        let groupName = null;
        groupName = ""; //readableUserGroupName(group);FIXME: remove this seamlessly
        const { currentEvent } = this.state;
        return(
            <Box
                title={customLabel}
            >
                <Grid container spacing={16}>
                    <Grid item xs>
                        <AppBar position="static" color="default">
                            <Tabs
                                value={currentEvent}
                                onChange={(e, currentEvent) => this.setState({currentEvent})}
                                indicatorColor="primary"
                                textColor="primary"
                                fullWidth
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab label="Metrics" />
                                <Tab label="Events Rate Trend" />
                                <Tab label="Users Rate Trend" />
                            </Tabs>
                        </AppBar>
                        {currentEvent === 0 && <div>
                            <Grid container spacing={16}>
                                <Grid item xs={12} md={6}>
                                    <Count
                                        {...this.props}
                                        parentCompLabel={customLabel}
                                        selfParams={{
                                            startEvent, endEvent,
                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                            extraFilters,
                                            dataKey: this.getDataKey({
                                                name: startEvent + ' - ' + endEvent,
                                                queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                                extraFilters,
                                                extension: "Conversion Rate"
                                            }),
                                            customLabel: this.getCustomLabel({
                                                name: `% of ${endEvent} / ${startEvent} - Events Rate`,
                                                extension: ''
                                            }),
                                            of: COUNT_TYPE_ENUM.event
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Count
                                        {...this.props}
                                        parentCompLabel={customLabel}
                                        selfParams={{
                                            startEvent, endEvent,
                                            queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                            extraFilters,
                                            dataKey: this.getDataKey({
                                                name: startEvent + ' - ' + endEvent,
                                                queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                                extraFilters,
                                                extension: "Users"
                                            }),
                                            customLabel: this.getCustomLabel({
                                                name: `% of ${endEvent} / ${startEvent} - Users Rate`,
                                                extension: ''
                                            }),
                                            of: COUNT_TYPE_ENUM.users
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>}
                        {currentEvent === 1 && <div>
                            <Trend
                                {...this.props}
                                selfParams={{
                                    startEvent, endEvent,
                                    queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                    extraFilters,
                                    dataKey: this.getDataKey({
                                        name: startEvent + ' - ' + endEvent,
                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.event},
                                        extraFilters,
                                        extension: "Trend"
                                    }),
                                    customLabel: this.getCustomLabel({
                                        name: `% of ${endEvent} / ${startEvent} - Events Rate Trend`,
                                        extension: ''
                                    }),
                                    of: COUNT_TYPE_ENUM.event
                                }}
                            />
                        </div>}
                        {currentEvent === 2 && <div>
                            <Trend
                                {...this.props}
                                selfParams={{
                                    startEvent, endEvent,
                                    queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                    extraFilters,
                                    dataKey: this.getDataKey({
                                        name: startEvent + ' - ' + endEvent,
                                        queryParams: {...queryParams, of: COUNT_TYPE_ENUM.users},
                                        extraFilters,
                                        extension: "users_trend"
                                    }),
                                    customLabel: this.getCustomLabel({
                                        name: `% of ${endEvent} / ${startEvent} - Users Rate Trend`,
                                        extension: ''
                                    }),
                                    of: COUNT_TYPE_ENUM.users
                                }}
                            />
                        </div>}
                    </Grid>
                </Grid>
            </Box>
        )
    }

}

export default withSelfComponent(TabbedWidget);