import {Chip} from "@material-ui/core";
import React from "react";

export default class BetaChip extends React.Component {
    render() {
        const {withGutter} = this.props;
        return (
            <Chip label={"Beta"}
                  style={{
                      background: '#ffb300',
                      height: 22,
                      color: "#424242",
                      fontWeight: 700,
                      margin: withGutter ? 8 : 0
                  }}
            />
        );
    }
}
