/**
 * Created by jyothi on 4/12/17.
 */
import React, { Component } from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import DateRange from '@material-ui/icons/DateRange';
import AccessTime from '@material-ui/icons/AccessTime';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import { IconButton, InputAdornment } from '@material-ui/core';
import MomentUtils from '@date-io/moment';


export default class CustomDateTimePicker extends Component {

    render() {
        const { handleChange, value, withoutIcon = false, ...restProps } = this.props;

        return (
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <DateTimePicker
                    format={ "MMM Do - HH:mm" }
                    onChange={handleChange}
                    autoOk
                    leftArrowIcon={<ArrowLeft />}
                    rightArrowIcon={<ArrowRight/>}
                    {...restProps}
                    timeIcon={<AccessTime/>}
                    dateRangeIcon={<DateRange/>}
                    value={value}
                    ampm={false}
                    InputProps={ !withoutIcon && {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton disabled={this.props.disabled} style={{width: 24, fontSize: '1.4em'}}><AddAlarmIcon/></IconButton>
                            </InputAdornment>
                        )
                    }}
                />
            </MuiPickersUtilsProvider>
        );
    }
}