import React, {Component, Fragment} from 'react';
import Grid from '@material-ui/core/Grid';
import {withStyles} from "@material-ui/core/styles";
import MultiSelect from "../../../../../../../components/reusable/MaterialUi/MultiSelect";
import FormSelect from "../../../../../../../components/reusable/MaterialUi/FormSelect";
import Button from "@material-ui/core/Button";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Clear';
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";

const styles = theme => ({

});

const Include = "Include";
const Exclude = "Exclude";
const Exact = "Exact";
const Regex = "Regex";

const INCLUDE_OPTIONS = {
    [Include]: false,
    [Exclude]: true
};

const REGEX_OPTIONS = {
    [Exact]: false,
    [Regex]: true
};

class FilterHolder extends Component {

    constructor(props){
        super(props);
        const { without = false, property = "", query = "", regex = false } = props;
        this.state = {
            without, property, query, regex
        };
    }

    handleUpdate = (key) => (value) => {
        this.setState({[key]: value}, () => {
            this.props.handleUpdate(this.state);
        });
    };

    render(){

        const {
            attributes = [],
            without = false, property = "", query = "", regex = false,
            handleRemove
        } = this.props;

        return(
            <Fragment>
                <Grid container spacing={16}>
                    <Grid item xs={2} md={1}>
                        <FormSelect
                            options={Object.keys(INCLUDE_OPTIONS)}
                            value={without ? Exclude : Include}
                            margin="dense"
                            handleUpdate={flag => this.handleUpdate("without")(INCLUDE_OPTIONS[flag])}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <MultiSelect
                            options={attributes}
                            single
                            handleChange={this.handleUpdate("property")}
                            value={property}
                        />
                    </Grid>
                    <Grid item xs={2} md={1}>
                        <FormSelect
                            options={Object.keys(REGEX_OPTIONS)}
                            value={regex ? Regex : Exact}
                            margin="dense"
                            handleUpdate={flag => this.handleUpdate("regex")(REGEX_OPTIONS[flag])}
                        />
                    </Grid>
                    <Grid item xs={4} md={3}>
                        <TextField
                            value={query}
                            onChange={e => this.handleUpdate("query")(e.target.value)}
                            placeholder={"Property Value"}
                            margin="dense"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton onClick={handleRemove}>
                            <RemoveIcon color="error"/>
                        </IconButton>
                    </Grid>
                </Grid>
                <Typography variant="caption">AND</Typography>
            </Fragment>
        );

    }

}

class Filters extends Component {

    constructor(props){
        super(props);
        const { filters = [] }  = props;
        this.state = {
            filters
        };
    }

    componentWillReceiveProps(nextProps){
        const { customReport: { report: { _id } = {} } } = this.props;
        const { customReport: { report: { _id: nextId } = {} }, filters } = nextProps;
        if(_id !== nextId){
            this.setState({filters});
        }
    }

    handleChange = (index) => (obj) => {
        const { filters = [], handleUpdate } = this.props;
        const updatedFilters = [...filters.slice(0, index), obj, ...filters.slice(index + 1)];
        handleUpdate(updatedFilters);
    };

    handleAddFilter = (dimension) => {
        const { filters = [], handleUpdate } = this.props;
        const updatedFilters = [...filters, {without: false, property: "", query: "", regex: false}];
        handleUpdate(updatedFilters);
    };

    handleRemoveFilter = (index) => () => {
        const { filters = [], handleUpdate } = this.props;
        const updatedFilters = [...filters.slice(0, index), ...filters.slice(index + 1)];
        handleUpdate(updatedFilters);
    };

    render(){
        const {
            filters = [],
            appState: { attributes: { user = [], session = [], event = [] } = {} },
        } = this.props;
        const attributes = [...user, ...session, ...event]
                                .map(o => ({label: o, value: o}))
                                .concat({label: "Event", value: "apx_event_name"});
                                // .concat({label: "Date", value: "apx_date"});
        return (
            <div style={{margin: '16px 0'}}>
                {
                    filters.map((filter, index) =>
                        <FilterHolder key={filter.property+index} attributes={attributes} {...filter} handleUpdate={this.handleChange(index)} handleRemove={this.handleRemoveFilter(index)}/>
                    )
                }
                <Button variant="text" onClick={this.handleAddFilter}>
                    <AddIcon/>
                    &nbsp;Add Filter
                </Button>
            </div>
        );

    }

}

export default withStyles(styles)(Filters);