/**
 * Created by Ravi Teja
 * Date: 17-Jan-2020
 * Time: 12:42 PM
 */

import {makeDefaultQueryString, withQueryStrings} from "../../../../../../../../utils";
import {callApi} from "../../../../../../../../api";
import {STEP_ENUM} from "./actions";

const EVENT_DISTRIBUTION_API = "art-configs/analysis/distribution";
const MESSAGE_ANALYSIS_API = "art-configs/analysis";
const MESSAGE_ANALYSIS_TIME_SERIES_API = "art-configs/analysis/time-series";

export function getStepsMetricsAPI(auth, appId, validity, stepType, group, of, attribute, postBody) {
    const queryParams = {
        since: validity.since,
        till: validity.till,
        event: stepType,
        attribute,
        of,
        group
    };
    const url = makeDefaultQueryString(EVENT_DISTRIBUTION_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        json = json.map((item) => {
            return {key: item.key, users: item.value, stepType: STEP_ENUM[stepType]}
        });
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getStepsTimeSeriesMetricsAPI(auth, appId, validity, stepType, group, of, attribute, postBody) {
    const queryParams = {
        since: validity.since,
        till: validity.till,
        event: stepType,
        attribute: ["apx_date", attribute],
        of,
        group
    };
    const url = makeDefaultQueryString(EVENT_DISTRIBUTION_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(postBody)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);

}

export function getMessageFunnelAnalysisAPI(auth, appId, messageId, goalEvent, time) {
    let queryParams = {
        "messageId": messageId,
        "time": time
    };
    if (goalEvent !== "")
        queryParams.goalEvent = goalEvent;
    const url = makeDefaultQueryString(MESSAGE_ANALYSIS_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function getMessageFunnelAnalysisTimeSeriesAPI(auth, appId, messageId, goalEvent, time) {
    let queryParams = {
        "messageId": messageId,
        "time": time
    };
    if (goalEvent !== "")
        queryParams.goalEvent = goalEvent;
    const url = makeDefaultQueryString(MESSAGE_ANALYSIS_TIME_SERIES_API, auth, appId) + withQueryStrings(queryParams);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}