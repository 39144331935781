/**
 * Created by jyothi on 1/11/17.
 */

import {
    CUSTOM_QUERY_ATTRIBUTES_PENDING, CUSTOM_QUERY_ATTRIBUTES, CUSTOM_QUERY_ATTRIBUTES_FAILED,
    CUSTOM_QUERY_ATTRIBUTE_VALUES_PENDING, CUSTOM_QUERY_ATTRIBUTE_VALUES, CUSTOM_QUERY_ATTRIBUTE_VALUES_FAILED,
    CUSTOM_QUERY_SAVE_PENDING, CUSTOM_QUERY_SAVE, CUSTOM_QUERY_SAVE_FAILED,
    CUSTOM_QUERY_EVENTS_PENDING, CUSTOM_QUERY_EVENTS, CUSTOM_QUERY_EVENTS_FAILED,
    CUSTOM_QUERY_EVENT_ATTRIBUTES_PENDING, CUSTOM_QUERY_EVENT_ATTRIBUTES, CUSTOM_QUERY_EVENT_ATTRIBUTES_FAILED,
    CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES_PENDING, CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES,
    CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES_FAILED,
    CUSTOM_QUERY_COUNT_PENDING, CUSTOM_QUERY_COUNT, CUSTOM_QUERY_COUNT_FAILED,
    CUSTOM_QUERY_UPDATE_QUERY, CUSTOM_QUERY_RESET_QUERY,
    CUSTOM_EVENT_ENUM, CUSTOM_QUERY_UPDATE_GROUP, CUSTOM_QUERY_UPDATE_SEGMENT
} from './actionTypes';
import {
    getAttributesAPI, getAttributeValuesAPI,
    getEventsAPI, getEventAttributesAPI, getEventAttributeValuesAPI,
    getSegmentCountAPI, saveSegmentAPI
} from './api';

export const getAttributes = (appId) => {
    return (dispatch, getState) => {
        const of = getState().customQueryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of };
        return dispatch({
            types: [
                CUSTOM_QUERY_ATTRIBUTES_PENDING,
                CUSTOM_QUERY_ATTRIBUTES,
                CUSTOM_QUERY_ATTRIBUTES_FAILED
            ],
            payload: {
                promise: getAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getAttributeValues = (appId, attribute, q) => {
    return (dispatch, getState) => {
        const of = getState().customQueryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of, q: q};
        return dispatch({
            types: [
                CUSTOM_QUERY_ATTRIBUTE_VALUES_PENDING,
                CUSTOM_QUERY_ATTRIBUTE_VALUES,
                CUSTOM_QUERY_ATTRIBUTE_VALUES_FAILED,
            ],
            payload: {
                promise: getAttributeValuesAPI(getState().auth, appId, attribute, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute: attribute
            }
        });
    };
};

export const getEvents = (appId) => {
    return (dispatch, getState) => {
        const of = getState().customQueryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of};
        return dispatch({
            types: [
                CUSTOM_QUERY_EVENTS_PENDING,
                CUSTOM_QUERY_EVENTS,
                CUSTOM_QUERY_EVENTS_FAILED
            ],
            payload: {
                promise: getEventsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, events) => {
                    /*if(Array.isArray(events) && events.length > 0) {
                     dispatch(updateCustomOverviewEvent(appId, events[0]));
                     dispatch(getCustomOverviewEventData(appId, events[0]));
                     }*/
                }
            }
        });
    };
};

export const getEventAttributes = (appId, event) => {
    return (dispatch, getState) => {
        const of = getState().customQueryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, event: event, of: of};
        return dispatch({
            types: [
                CUSTOM_QUERY_EVENT_ATTRIBUTES_PENDING,
                CUSTOM_QUERY_EVENT_ATTRIBUTES,
                CUSTOM_QUERY_EVENT_ATTRIBUTES_FAILED,
            ],
            payload: {
                promise: getEventAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            },
            callbacks: {
                successDidDispatch: (dispatch, attributes) => {
                    if(Array.isArray(attributes)) { // not need as of now
                        //dispatch(getEventAttributeDistributions(appId, event, attributes));
                    }else{
                        console.error("No event attributes fetched..!")
                    }
                }
            }
        });
    };
};

export const getEventAttributeValues = (appId, event, attribute, q) => {
    return (dispatch, getState) => {
        const of = getState().customQueryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, event: event, of: of, attribute: attribute, q: q};
        return dispatch({
            types: [
                CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES_PENDING,
                CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES,
                CUSTOM_QUERY_EVENT_ATTRIBUTE_VALUES_FAILED
            ],
            payload: {
                promise: getEventAttributeValuesAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute: attribute,
                event: event
            }
        });
    };
};

export const getSegmentCount = (appId) => {
    return (dispatch, getState) => {
        const of = getState().customQueryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of};
        const { query } = getState().customQueryBuilder;
        return dispatch({
            types: [
                CUSTOM_QUERY_COUNT_PENDING,
                CUSTOM_QUERY_COUNT,
                CUSTOM_QUERY_COUNT_FAILED
            ],
            payload: {
                promise: getSegmentCountAPI(getState().auth, appId, filters, query)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

export const saveSegment = (appId, name) => {
    return (dispatch, getState) => {
        const of = getState().customQueryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of};
        const { query } = getState().customQueryBuilder;
        return dispatch({
            types: [
                CUSTOM_QUERY_SAVE_PENDING,
                CUSTOM_QUERY_SAVE,
                CUSTOM_QUERY_SAVE_FAILED
            ],
            payload: {
                promise: saveSegmentAPI(getState().auth, appId, filters, query)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

/**
 *
 * @param query
 * @returns {function(*, *)}
 */
export const updateQuery = (query) => {
    return (dispatch, getState) => {
        return dispatch({
            type: CUSTOM_QUERY_UPDATE_QUERY,
            payload: query,
            meta: {
                //
            }
        });
    };
};

export const updateGroup = (group) => {
    return (dispatch, getState) => {
        return dispatch({
            type: CUSTOM_QUERY_UPDATE_GROUP,
            payload: group,
            meta: {
                //
            }
        });
    };
};

export const updateSegment = (segment) => {
    return (dispatch, getState) => {
        return dispatch({
            type: CUSTOM_QUERY_UPDATE_SEGMENT,
            payload: segment,
            meta: {
                //
            }
        });
    };
};

export const resetQuery = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: CUSTOM_QUERY_RESET_QUERY,
            payload: null,
            meta: {
                //
            }
        });
    };
};