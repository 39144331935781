/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../api';
import {
    appendEmptyValueForSequence, makeDefaultQueryString,
    withQueryStrings
} from '../../../../../../utils';

const UNINSTALLS_PER_SESSION_API = "session-count";
const DAU_WITH_EVENT_API = "dau";
const ACTIVE_EVENT_HOURS = "events/distribution/hour";
const EVENT_DISTRIBUTION_API = "events/distribution/count";
const DROP_OFF_API = "drop-off";
const TIME_SPENT_API = "activity/time";


/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function miscUninstallsPerSessionAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(UNINSTALLS_PER_SESSION_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify({})
    };
    const onSuccessCallback = json => {
        return json.map(o => ({key: o.key, "Users": o.value}));
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @returns {Promise}
 */
export function miscUninstallsPerVideosWatchedAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(EVENT_DISTRIBUTION_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify({})
    };
    const onSuccessCallback = json => {
        return json.map(o => ({key: o.key, "Users": o.value}));
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param event
 * @returns {Promise}
 */
export function miscDAUWithEventAPI(auth, appId, filters, event) {
    const url = makeDefaultQueryString(DAU_WITH_EVENT_API, auth, appId) + withQueryStrings({...filters, event});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify({})
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


export function miscActiveEventHoursAPI(auth, appId, filters, event) {
    const url = makeDefaultQueryString(ACTIVE_EVENT_HOURS, auth, appId) + withQueryStrings({...filters, event});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify({})
    };
    const onSuccessCallback = json => {
        return json.map(o => ({key: o.key, "Watch Count": o.value}));
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function miscDistributionOfEventAPI(auth, appId, filters, event) {
    const url = makeDefaultQueryString(EVENT_DISTRIBUTION_API, auth, appId) + withQueryStrings({...filters, event});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify({})
    };
    const onSuccessCallback = json => {
        return appendEmptyValueForSequence(json, "key", "value", 0);
    };
    const onFailureCallback = response => {
        return [];
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function miscUsersFormedRelationshipAPI(auth, appId, filters, event) {
    const url = makeDefaultQueryString(DAU_WITH_EVENT_API, auth, appId) + withQueryStrings({...filters, event});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify({})
    };
    const onSuccessCallback = json => {
        return json || {};
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function miscNewInstallsFormedRelationshipAPI(auth, appId, filters, event) {
    const url = makeDefaultQueryString(DAU_WITH_EVENT_API, auth, appId) + withQueryStrings({...filters, event});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify({})
    };
    const onSuccessCallback = json => {
        return json || {};
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function miscDropInActivityAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(DROP_OFF_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json || {};
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function miscTimeSpentAPI(auth, appId, filters) {
    const url = makeDefaultQueryString(TIME_SPENT_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "GET",
        auth: auth,
        //body: JSON.stringify({})
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}