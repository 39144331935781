/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { updateHeading } from '../../../Navigation/actions';
import Customers from './components';
import {
    addCustomer, removeCustomer,
    removeDashboardToCustomer, transferAppOwnership
} from "./actions";
import {Typography} from "@material-ui/core";
import Dialog from "../../../../../../../components/reusable/MaterialUi/Dialog";
import CircularProgress from '@material-ui/core/CircularProgress';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        sdkSettings: state.sdkSettings,
        dashboards: state.dashboards,
        iam: state.iam
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        addCustomer: (appId, data) => dispatch(addCustomer(appId, data)),
        removeCustomer: (appId, customerId) => dispatch(removeCustomer(appId, customerId)),
        transferAppOwnership: (appId, body) => dispatch(transferAppOwnership(appId, body)),
        //addDashboardToCustomer: (appId, deviceId) => dispatch(addDashboardToCustomer(appId, deviceId)),
        removeDashboardToCustomer: (appId, customerId, dashboardId) => dispatch(removeDashboardToCustomer(appId, customerId, dashboardId))
    };
}

class IAMSettings extends Component {

    componentWillMount(){
        this.props.updateHeading("Access Management");
    }

    componentDidMount(){

    }

    render() {
        const {iam: {transfer_app_ownership}} = this.props;
        if (transfer_app_ownership) {
            setTimeout(()=>{
                window.location = "/apps"
            }, Math.random()*2500)
        }
        return (
            <section className="content">
                <Customers {...this.props}/>
                <Dialog
                    noActions={true}
                    status={transfer_app_ownership}
                    title="Redirection Warning"
                >
                    <Typography>Transfer App Ownership completed <strong>successfully</strong>.</Typography>
                    <Typography>Redirecting you to Apps List</Typography>
                    <div style={{textAlign: "center"}}>
                        <CircularProgress   />
                    </div>
                </Dialog>
            </section>
        );
    }
}

IAMSettings.propTypes = {

};

IAMSettings.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IAMSettings);