/**
 * Created by Rakesh Peela
 * Date: 13-Aug-2019
 * Time: 11:49 AM
 */
import { APXOR_ROLES } from "../../../../../constants";
import {
  FETCH_PENDING_APP_APPROVALS,
  FETCH_PENDING_APP_APPROVALS_FAILED,
  FETCH_PENDING_APP_APPROVALS_PENDING,
  GET_USER_ROLE,
  GET_USER_ROLE_FAILED,
  GET_USER_ROLE_PENDING,
  PASSWORD_UPDATE,
  PASSWORD_UPDATE_FAILED,
  PASSWORD_UPDATE_PENDING,
  PROFILE_DATA_UPDATE,
  PROFILE_DATA_UPDATE_FAILED,
  PROFILE_DATA_UPDATE_PENDING,
  RESET_STATE,
  GET_2FA_STATUS,
  START_2FA_SETTINGS,
  ENABLE_2FA_SETTINGS,
  DISABLE_2FA_SETTINGS,
  ENABLE_2FA_SETTINGS_FAILED,
  DISABLE_2FA_SETTINGS_FAILED,
} from "./actionTypes";

const PASSWORD_UPDATE_DEFAULT_STATE = {
  update_password_pending: false,
  update_password_failed: false,
  update_password_success: false,
};

const PROFILE_UPDATE_DEFAULT_STATE = {
  update_profile_pending: false,
  update_profile_failed: false,
  update_profile_success: false,
};

const INITIAL_STATE = {
  role: APXOR_ROLES.REGULAR_USER,
  get_user_role_pending: false,
  get_user_role_failed: false,
  pending_app_approvals: [],
  fetch_pending_app_approvals_pending: false,
  fetch_pending_app_approvals_failed: false,
  sfa: false,
  sfa_qr: "",
  recovery: [],
  enabled_2fa: false,
  disabled_2fa: true,
  ...PASSWORD_UPDATE_DEFAULT_STATE,
  ...PROFILE_UPDATE_DEFAULT_STATE,
};

function profileReducer(state = INITIAL_STATE, action = {}) {
  switch (action.type) {
    case PASSWORD_UPDATE_PENDING: {
      return {
        ...state,
        ...PASSWORD_UPDATE_DEFAULT_STATE,
        update_password_pending: true,
      };
    }
    case PASSWORD_UPDATE_FAILED: {
      return {
        ...state,
        ...PASSWORD_UPDATE_DEFAULT_STATE,
        update_password_failed: action.payload.message || true,
      };
    }
    case PASSWORD_UPDATE: {
      return {
        ...state,
        ...PASSWORD_UPDATE_DEFAULT_STATE,
        update_password_success: action.payload.message,
      };
    }

    case PROFILE_DATA_UPDATE_PENDING: {
      return {
        ...state,
        ...PASSWORD_UPDATE_DEFAULT_STATE,
        update_profile_pending: true,
      };
    }
    case PROFILE_DATA_UPDATE_FAILED: {
      return {
        ...state,
        ...PASSWORD_UPDATE_DEFAULT_STATE,
        update_profile_failed: true,
      };
    }
    case PROFILE_DATA_UPDATE: {
      return {
        ...state,
        ...PASSWORD_UPDATE_DEFAULT_STATE,
        update_profile_success: true,
      };
    }
    case RESET_STATE:
      return { ...INITIAL_STATE };

    case GET_USER_ROLE_PENDING:
      return {
        ...state,
        get_user_role_pending: true,
      };
    case GET_USER_ROLE:
      return {
        ...state,
        role: action.payload && action.payload.ROLE,
        get_user_role_pending: false,
        get_user_role_failed: false,
      };
    case GET_USER_ROLE_FAILED:
      return {
        ...state,
        fetch_pending_app_approvals_failed: true,
      };

    case FETCH_PENDING_APP_APPROVALS_PENDING:
      return {
        ...state,
        fetch_pending_app_approvals_pending: true,
      };
    case FETCH_PENDING_APP_APPROVALS:
      return {
        ...state,
        pending_app_approvals: action.payload,
        fetch_pending_app_approvals_pending: false,
        fetch_pending_app_approvals_failed: false,
      };
    case FETCH_PENDING_APP_APPROVALS_FAILED:
      return {
        ...state,
        fetch_pending_app_approvals_failed: true,
      };

    case GET_2FA_STATUS:
      return {
        ...state,
        sfa: action.payload,
        disabled_2fa: !action.payload
      };
    case START_2FA_SETTINGS:
      return {
        ...state,
        sfa_qr: action.payload.qr,
      };
    case ENABLE_2FA_SETTINGS:
      return {
        ...state,
        sfa:true,
        enabled_2fa: true,
        recovery: action.payload.recovery_codes,
        sfa_error_message: "",
        disabled_2fa: false
    };
    case ENABLE_2FA_SETTINGS_FAILED:
      return {
        ...state,
        enabled_2fa: false,
        sfa_error_message: "Check OTP"
      };
    case DISABLE_2FA_SETTINGS:
      return {
        ...state,
        sfa: !action.payload,
        recovery:[],
        disabled_2fa: true,
        sfa_error_message: "",
      };
      case DISABLE_2FA_SETTINGS_FAILED:
        return {
          ...state,
          recovery:[],
          disabled_2fa:false,
          sfa_error_message:"Please enter correct Password"
        };
    default:
      return { ...state };
  }
}

export default profileReducer;
