import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {IconButton, Typography} from "@material-ui/core";
import Dialog from "./MaterialUi/Dialog";
import Snackbar from "./MaterialUi/Snackbar";
import DeleteIcon from "@material-ui/icons/Delete";

export default class DeleteAction extends React.Component {

    state = {
        deleteTriggered: false
    };

    render(){
        const { deleteAction, deleteFailed = false } = this.props;
        const { deleteTriggered  } = this.state;
        return(
            <Fragment>
                <IconButton
                    color="secondary"
                    aria-label="Delete"
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.setState({deleteTriggered: true});
                    }}>
                    <DeleteIcon color="error" />
                </IconButton>
                <Dialog
                    status={deleteTriggered}
                    handleConfirm={() => {
                        deleteAction();
                        this.setState({deleteTriggered: false});
                    }}
                    title="Delete Confirmation"
                    handleClose={() => this.setState({deleteTriggered: false})}
                    confirmLabel="Confirm"
                    allowCancelLabel="Cancel"
                >
                    <Typography variant="subtitle1">Are you sure want to delete ? </Typography>
                </Dialog>
                { deleteFailed && <Snackbar> Deleting Failed. Try Again..!</Snackbar> }
            </Fragment>
        )
    }

}

DeleteAction.propTypes = {
    deleteAction: PropTypes.func.isRequired,
    deleteFailed: PropTypes.bool
};