/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import { callApi } from '../../../../../../api';
import {makeDefaultQueryString} from '../../../../../../utils';
import {formatTime, withQueryStrings} from "../../../../../../utils/index";

const SURVEYS_API = "messages";

const SURVEYS_RESULTS_API = (surveyId) => `surveys/${surveyId}/results`;

const SURVEYS_RESULT_DISTRIBUTIONS_API = (surveyId) => `surveys/${surveyId}/results/distributions`;

/**
 *
 * @param appId
 * @param auth
 * @param queryParams
 * @returns {Promise}
 */
export function getSurveysAPI(appId, auth, queryParams) {
    const url = makeDefaultQueryString(SURVEYS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}


export function getSurveyAPI(appId, auth, surveyId, queryParams) {
    const url = makeDefaultQueryString(SURVEYS_API + '/' + surveyId, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function saveSurveyAPI(appId, auth, queryParams, survey) {
    const url = makeDefaultQueryString(SURVEYS_API, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(survey)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function updateSurveyAPI(appId, auth, surveyId, queryParams, survey) {
    const url = makeDefaultQueryString(SURVEYS_API + '/' + surveyId, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "PUT",
        auth: auth,
        body: JSON.stringify(survey)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function deleteSurveyAPI(appId, auth, surveyId, queryParams) {
    const url = makeDefaultQueryString(SURVEYS_API + '/' + surveyId, auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "DELETE",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function surveyResultsAPI(appId, auth, surveyId, queryParams, filters) {
    const url = makeDefaultQueryString(SURVEYS_RESULTS_API(surveyId), auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function surveyResultForOtherOptionAPI(appId, auth, surveyId, queryParams, filters) {
    const url = makeDefaultQueryString(SURVEYS_RESULTS_API(surveyId) + "/others", auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(filters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function surveyResultDistributionsAPI(appId, auth, surveyId, queryParams) {
    const url = makeDefaultQueryString(SURVEYS_RESULT_DISTRIBUTIONS_API(surveyId), auth, appId) + withQueryStrings({...queryParams});
    const config = {
        method: "GET",
        auth: auth
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}