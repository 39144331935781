/**
 * Created by Araja Jyothi Babu on 25-Oct-16.
 */
import React, {Component,} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {updateHeading} from '../../../Navigation/actions';
import {TestDevices} from './components';
import Grid from "@material-ui/core/Grid";
import {removeAsTestDevice, updateTestDevice} from "../../Users/User/actions";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        sdkSettings: state.sdkSettings
    };
}

function mapDispatchToProps(dispatch) {
    return {
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        removeAsTestDevice: (appId, deviceId) => dispatch(removeAsTestDevice(appId, deviceId)),
        updateTestDeviceNick: (appId, deviceId, nickName) => dispatch(updateTestDevice(appId, deviceId, {nick_name: nickName}))
    };
}

class TestDeviceSettings extends Component {

    componentWillMount() {
        this.props.updateHeading("Test Device Settings");
    }

    componentDidMount() {

    }

    render() {
        return (
            <section className="content">
                <Grid container justify="center">
                    <Grid item xs={12} md={8}>
                        <TestDevices {...this.props}/>
                    </Grid>
                </Grid>
            </section>
        );
    }
}

TestDeviceSettings.propTypes = {};

TestDeviceSettings.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TestDeviceSettings);