/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    RESET_SEGMENT,
    SEGMENT_PENDING,
    SEGMENT_FAILED,
    CORRELATIONS_TIMESERIES,
    CORRELATIONS_TIMESERIES_FAILED,
    CORRELATIONS_TIMESERIES_PENDING,
    CORRELATION_SEGMENTLIST,
    UPDATE_SEGMENTS,
    SEGMENT_COUNT,
    DELETE_SEGMENT_PENDING,
    DELETE_SEGMENT,
    DELETE_SEGMENT_FAILED,
    SEGMENT_RETENTION,
    UPDATE_SEGMENT_ATTRIBUTE_FILTERS,
    SEGMENT_USER_ATTRIBUTE_DISTRIBUTION,
    SEGMENT_SESSION_ATTRIBUTE_DISTRIBUTION,
    UPDATE_SEGMENT_USER_GROUP,
    UPDATE_SEGMENT_GROUP_BY_ATTRIBUTE,
    SEGMENT_GROUP_BY_ATTRIBUTE,
    UPDATE_SEGMENT_CURRENT_EVENT,
    SEGMENT_CURRENT_EVENT_ATTRIBUTES,
    SEGMENT_CURRENT_EVENT_ATTRIBUTE_DISTRIBUTION,
    COHORT_UPLOAD_PENDING, COHORT_UPLOAD_DONE, COHORT_UPLOAD_FAILED, CLOSE_DIALOG, OPEN_DIALOG, ERROR_MESSAGE
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';
import {makeDonutData} from "../../../../../../utils";
const INITIAL_STATE = {
    count: 0,
    group: "all_users",
    pending: false,
    failed: false,
    correlation: {},
    time_series: [],
    selected_segments: {},
    segment_list: [],
    retention: [],
    segment_retention: [],
    uninstalls: [],
    segment_uninstalls: [],
    filters: { user: [], session: [] },
    user_attribute_distributions: {},
    session_attribute_distributions: {},
    event_attribute_distributions: {},
    group_by: null,
    segments_group_by_attribute: {},
    current_event: null,
    current_event_attributes: [],
    cohort_upload_status: {
        pending: false,
        failed: false,
        error_message: null
    },
    close_dialog: true
};

const INITIAL_FLAG_STATE = {
    pending: false,
    failed: false
};

const INITIAL_DELETE_SEGMENT_FLAG_STATE = {
    segment_delete_pending: false,
    segment_delete_failed: false,
    segment_delete: false
};

function mergeTimeSeries(all, segment, segmentName) {
    if(!Array.isArray(all) || !Array.isArray(segment)) return [];
    if(all.length === 0 || all.length !== segment.length) return segment.map(o => ({name: o.key, [segmentName]: o.value}));
    return all.map((item, index) => {
        item[segmentName] = segment[index].value;
        return item;
    });
}

function segmentReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {

        case SEGMENT_FAILED:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                ...INITIAL_DELETE_SEGMENT_FLAG_STATE,
                failed: true
            });

        case SEGMENT_PENDING:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                ...INITIAL_DELETE_SEGMENT_FLAG_STATE,
                pending: true,
            });

        case CORRELATIONS_TIMESERIES:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                ...INITIAL_DELETE_SEGMENT_FLAG_STATE,
                time_series: mergeTimeSeries(state.time_series, action.payload, action.meta.segmentName),
                selected_segments: {
                    ...state.selected_segments,
                    [action.meta.segmentId]: action.payload
                },
                pending: false,
                failed: false
            });

        case CORRELATION_SEGMENTLIST:
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                ...INITIAL_DELETE_SEGMENT_FLAG_STATE,
                segment_list: action.payload
            });

        case UPDATE_SEGMENTS:
            let selectedSegments = state.selected_segments;
            for(let key in selectedSegments){
                if(selectedSegments.hasOwnProperty(key)){
                    if(action.payload.indexOf(key) < 0){
                        delete selectedSegments[key];
                    }
                }
            }
            return Object.assign({}, state, INITIAL_FLAG_STATE, {
                updated_segments: action.payload,
                selected_segments: selectedSegments,
                ...INITIAL_DELETE_SEGMENT_FLAG_STATE
            });

        case SEGMENT_COUNT:
            return {
                ...state,
                ...INITIAL_DELETE_SEGMENT_FLAG_STATE,
                count: action.payload
            };

        case CORRELATIONS_TIMESERIES_PENDING:
            return {
                ...state,
                ...INITIAL_DELETE_SEGMENT_FLAG_STATE,
                pending: true,
                failed: false
            };

        case CORRELATIONS_TIMESERIES_FAILED:
            return {
                ...state,
                ...INITIAL_DELETE_SEGMENT_FLAG_STATE,
                failed: true,
                pending: false
            };

        case DELETE_SEGMENT_PENDING:
            return {
                ...state,
                ...INITIAL_DELETE_SEGMENT_FLAG_STATE,
                segment_delete_pending: true
            };

        case DELETE_SEGMENT:
            return {
                ...state,
                ...INITIAL_DELETE_SEGMENT_FLAG_STATE,
                segment_delete: true
            };

        case DELETE_SEGMENT_FAILED:
            return {
                ...state,
                ...INITIAL_DELETE_SEGMENT_FLAG_STATE,
                segment_delete_failed: true
            };

        case UPDATE_SEGMENT_ATTRIBUTE_FILTERS:
            return {
                ...state,
                filters: action.payload
            };

        case SEGMENT_USER_ATTRIBUTE_DISTRIBUTION:
            return {
                ...state,
                user_attribute_distributions: {
                    ...state.user_attribute_distributions,
                    [action.meta.attribute]: makeDonutData(action.payload)
                }
            };

        case SEGMENT_SESSION_ATTRIBUTE_DISTRIBUTION:
            return {
                ...state,
                session_attribute_distributions: {
                    ...state.session_attribute_distributions,
                    [action.meta.attribute]: makeDonutData(action.payload)
                }
            };

        case SEGMENT_CURRENT_EVENT_ATTRIBUTE_DISTRIBUTION:
            return {
                ...state,
                event_attribute_distributions: {
                    ...state.event_attribute_distributions,
                    [action.meta.attribute]: makeDonutData(action.payload)
                }
            };

        case UPDATE_SEGMENT_USER_GROUP:
            return {
                ...state,
                group: action.payload
            };

        case UPDATE_SEGMENT_GROUP_BY_ATTRIBUTE:
            return {
                ...state,
                group_by: action.payload,
                segments_group_by_attribute: {}
            };

        case SEGMENT_GROUP_BY_ATTRIBUTE:
            return {
                ...state,
                segments_group_by_attribute: {
                    ...state.segments_group_by_attribute,
                    [action.meta.segmentId]: action.payload
                }
            };

        case UPDATE_SEGMENT_CURRENT_EVENT:
            return {
                ...state,
                current_event: action.payload
            };

        case SEGMENT_CURRENT_EVENT_ATTRIBUTES:
            return {
                ...state,
                current_event_attributes: action.payload
            };

        case SEGMENT_RETENTION:
            return {
                ...state,
                retention: action.payload
            };
        case CLOSE_DIALOG:
            return {
                ...state,
                close_dialog: true
            };
        case OPEN_DIALOG:
            return {
                ...state,
                close_dialog: false
            };

        case ERROR_MESSAGE:
            return {
                ...state,
                cohort_upload_status: {
                    ...state.cohort_upload_status,
                    error_message: action.payload.message
                }
            };

        case COHORT_UPLOAD_PENDING:
            return {
                ...state,
                cohort_upload_status: {
                    ...state.cohort_upload_status,
                    pending: true,
                    failed: false,
                },
            };

        case COHORT_UPLOAD_DONE:
            return {
                ...state,
                cohort_upload_status: {
                    ...state.cohort_upload_status,
                    pending: false,
                    failed: false,
                },
            };

        case COHORT_UPLOAD_FAILED:
            return {
                ...state,
                cohort_upload_status: {
                    ...state.cohort_upload_status,
                    pending: false,
                    failed: true,
                },
            };

        case RESET_SEGMENT:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default segmentReducer;