/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

import { callApi } from '../../../../../../api';
import {makeDefaultQueryString, withQueryStrings} from '../../../../../../utils';

export const DROP_OFF_API = "drop-off";

export const DROP_OFF_COUNT_API = "drop-off/count";

export const DROP_OFF_SCREENS_API = "drop-off/screens";

export const DROP_OFF_EVENTS_API = "drop-off/events";

export const DROP_OFF_SCREEN_TIME_SPENT_API = (screen) => `${DROP_OFF_SCREENS_API}/${screen}/time-spent`;

export const DROP_OFF_SCREEN_EVENTS_API = (screen) => `${DROP_OFF_SCREENS_API}/${screen}/events`;

const DISTRIBUTION_API = "events/distribution";

/**
 *
 * @param auth
 * @param appId
 * @param filters
 * @param searchFilters
 * @returns {Promise}
 */
export function dropOffCountByAPI(auth, appId, filters, searchFilters) {
    const url = makeDefaultQueryString(DROP_OFF_COUNT_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(searchFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function dropOffsByScreensAPI(auth, appId, filters, searchFilters) {
    const url = makeDefaultQueryString(DROP_OFF_SCREENS_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(searchFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function dropOffsByEventsAPI(auth, appId, filters, searchFilters) {
    const url = makeDefaultQueryString(DROP_OFF_EVENTS_API, auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(searchFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function dropOffEventsByScreenAPI(auth, appId, screen, filters, searchFilters) {
    const url = makeDefaultQueryString(DROP_OFF_SCREEN_EVENTS_API(screen), auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(searchFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function dropOffTimeSpentOnScreen(auth, appId, screen, filters, searchFilters) {
    const url = makeDefaultQueryString(DROP_OFF_SCREEN_TIME_SPENT_API(screen), auth, appId) + withQueryStrings(filters);
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(searchFilters)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}

export function dropOffEventAttributeDistributionAPI(appId, auth, filters, body) {
    const url = makeDefaultQueryString(DISTRIBUTION_API, auth, appId) + withQueryStrings({...filters});
    const config = {
        method: "POST",
        auth: auth,
        body: JSON.stringify(body)
    };
    const onSuccessCallback = json => {
        return json;
    };
    const onFailureCallback = response => {
        return {};
    };
    return callApi(url, config, onSuccessCallback, onFailureCallback);
}