/**
 * Created by kodanda_rama on 1/15/17.
 */
import React from 'react';
import {MESSAGE_POSITION_ENUM} from '../actionTypes';
import Button from "@material-ui/core/Button";
import {replaceMacrosWithDefault} from "../../../../../../../utils";

export function spacingCSS(spacingObj = {}) {
    if(spacingObj === null) return null;
    if(Object.keys(spacingObj).length === 4){
        const { top, right, bottom , left } = spacingObj;
        return `${top}px ${right}px ${bottom}px ${left}px`;
    }else{
        return null;
    }
}

export const TEXT_STYLES_MAP = {
    normal: "normal",
    bold: "bold",
    bold_italic: "bold_italic",
    italic: "italic"
};

export const MOBILE_IMAGE_URL = "/assets/img/mobile-mockup.png";

const { CENTER, BOTTOM, TOP } = MESSAGE_POSITION_ENUM;

export const positionCSS = (position, full_width  = false) => {
    switch (position){
        case TOP:
            return {
                margin: full_width ? '0 auto' : '16px auto 0',
            };
        case CENTER:
            return {
                margin: '0 auto',
                position: 'relative',
                top: '50%',
                transform: 'translateY(-50%)'
            };
        case BOTTOM:
            return {
                margin: '0 auto',
                position: 'relative',
                top: `calc(100% - ${full_width ? 0 : 16}px)`,
                transform: 'translateY(-100%)'
            };
        default:
            return {
                margin: full_width ? '0 auto' : '16px auto 0',
            };
    }
};

export const visibilityMapper = (visibility) => {
    switch(visibility){
        case 1: return "visible";
        case -1: return "hidden";
        case 0: return "hidden";
        default: return "visible";
    }
};

export const renderButton = (props) => {
    const {
        id, color, visibility,
        text_config = {}, borders = {},
        margin_config = {}, padding_config = {}
    } = props;
    const { radius = 0, stroke_width = 0, stroke_color = "#FFFFFF" } = borders;
    return(
        <Button size="small" id={id} style={{
            background: color,
            visibility: visibilityMapper(visibility),
            padding: spacingCSS(padding_config),
            margin: spacingCSS(margin_config),
            border: `${stroke_width}px solid ${stroke_color}`,
            borderRadius: radius
        }}>
            { renderText(text_config) }
        </Button>
    );
};

const { bold, italic, bold_italic, normal } = TEXT_STYLES_MAP;

export const fontStyles = (style) => {
    switch (style) {
        case bold:
            return {
                fontWeight: bold
            };
        case italic:
            return {
                fontStyle: italic
            };
        case bold_italic:
            return {
                fontWeight: bold,
                fontStyle: italic
            };
        case normal:
        default:
            return {

            };
    }
};

export const renderText = (props, withoutSpacing = false) => {
    const { font_family, size, style, color, text, margin_config, padding_config } = props;
    return(
        <div style={!withoutSpacing ? {
            padding: spacingCSS(padding_config),
            margin: spacingCSS(margin_config)
        } : {}}>
            <span style={{
                fontFamily: font_family,
                fontSize: size,
                color: color,
                padding: spacingCSS(padding_config),
                margin: spacingCSS(margin_config),
                ...fontStyles(style)
            }}>{ replaceMacrosWithDefault(text) }</span>
        </div>
    );
};

export const renderImage = (props) => {
    const {  url, description, visibility } = props;
    return(
        <img
            src={url}
            alt={description}
            title={description}
            visibility={visibilityMapper(visibility)}
        />
    );
};