/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    RESET_CONTEXTS,
    CONTEXTS_PENDING, CONTEXTS, CONTEXTS_FAILED,
    CONTEXT_PENDING, CONTEXT, CONTEXT_FAILED,
    SAVE_CONTEXT_PENDING, SAVE_CONTEXT, SAVE_CONTEXT_FAILED,
    UPDATE_CONTEXT_PENDING, UPDATE_CONTEXT, UPDATE_CONTEXT_FAILED,
    DELETE_CONTEXT_PENDING, DELETE_CONTEXT, DELETE_CONTEXT_FAILED, UPDATE_LOCAL_CONTEXT
} from './actionTypes';
import {RESET_ALL} from '../../../../../../constants/ActionTypes';

const INITIAL_CONTEXTS_FLAG_STATE = {
    contexts_pending: false,
    contexts_failed: false,
};

const INITIAL_CONTEXT_FLAG_STATE = {
    context_pending: false,
    context_failed: false,
};

const INITIAL_SAVE_CONTEXT_FLAG_STATE = {
    save_context_pending: false,
    save_context_failed: false,
    save_context: false
};

const INITIAL_UPDATE_CONTEXT_FLAG_STATE = {
    update_context_pending: false,
    update_context_failed: false,
    update_context: false
};

const INITIAL_DELETE_CONTEXT_FLAG_STATE = {
    delete_context_pending: false,
    delete_context_failed: false,
};

const INITIAL_STATE = {
    contexts: [],
    context: {},
    ...INITIAL_CONTEXTS_FLAG_STATE,
    ...INITIAL_CONTEXT_FLAG_STATE,
    ...INITIAL_SAVE_CONTEXT_FLAG_STATE,
    ...INITIAL_UPDATE_CONTEXT_FLAG_STATE,
    ...INITIAL_DELETE_CONTEXT_FLAG_STATE
};

function contextsReducer(state = INITIAL_STATE, action = {}) {
    const { type, payload } = action;
    switch (type) {

        case CONTEXTS_PENDING:
            return {
                ...state,
                ...INITIAL_CONTEXTS_FLAG_STATE,
                contexts_pending: true
            };

        case CONTEXTS:
            return {
                ...state,
                ...INITIAL_CONTEXTS_FLAG_STATE,
                contexts: payload
            };

        case CONTEXTS_FAILED:
            return {
                ...state,
                ...INITIAL_CONTEXTS_FLAG_STATE,
                contexts_failed: true
            };

        case CONTEXT_PENDING:
            return {
                ...state,
                ...INITIAL_CONTEXT_FLAG_STATE,
                context_pending: true
            };

        case CONTEXT:
            return {
                ...state,
                ...INITIAL_CONTEXT_FLAG_STATE,
                context: payload
            };

        case CONTEXT_FAILED:
            return {
                ...state,
                ...INITIAL_CONTEXT_FLAG_STATE,
                context_failed: true
            };

        case SAVE_CONTEXT_PENDING:
            return {
                ...state,
                ...INITIAL_SAVE_CONTEXT_FLAG_STATE,
                save_context_pending: true
            };

        case SAVE_CONTEXT:
            return {
                ...state,
                ...INITIAL_SAVE_CONTEXT_FLAG_STATE,
                save_context: payload
            };

        case SAVE_CONTEXT_FAILED:
            return {
                ...state,
                ...INITIAL_SAVE_CONTEXT_FLAG_STATE,
                save_context_failed: true
            };

        case UPDATE_CONTEXT_PENDING:
            return {
                ...state,
                ...INITIAL_UPDATE_CONTEXT_FLAG_STATE,
                update_context_pending: true
            };

        case UPDATE_CONTEXT:
            return {
                ...state,
                ...INITIAL_UPDATE_CONTEXT_FLAG_STATE,
                update_context: payload
            };

        case UPDATE_CONTEXT_FAILED:
            return {
                ...state,
                ...INITIAL_UPDATE_CONTEXT_FLAG_STATE,
                update_context_failed: true
            };

        case DELETE_CONTEXT_PENDING:
            return {
                ...state,
                ...INITIAL_DELETE_CONTEXT_FLAG_STATE,
                delete_context_pending: true
            };

        case DELETE_CONTEXT:
            return {
                ...state,
                ...INITIAL_DELETE_CONTEXT_FLAG_STATE,
                delete_context: payload
            };

        case DELETE_CONTEXT_FAILED:
            return {
                ...state,
                ...INITIAL_DELETE_CONTEXT_FLAG_STATE,
                delete_context_failed: true
            };

        case UPDATE_LOCAL_CONTEXT:
            return {
                ...state,
                context: {
                    ...state.context,
                    ...payload
                }
            };

        case RESET_CONTEXTS:
        case RESET_ALL:
            return INITIAL_STATE;

        default:
            return state;
    }
}

export default contextsReducer;