/**
 * Created by Rakesh Peela
 * Date: 29-Nov-2019
 * Time: 11:59 PM
 */

import {Grid} from "@material-ui/core";
import React from 'react';
import MultiUtilityChart from '../../../../../components/reusable/Recharts/MultiUtilityChart';

class Report_TimeseriesTrend extends React.Component {
    render() {
        const {widgetData} = this.props;
        const {config: {query_params: {of, group_by}}, data} = widgetData;
        let lineDataKeys = [];
        if (group_by !== "") {
            Object.keys(data[0]).filter(o => o !== "all" && o !== "key").forEach(o => {
                lineDataKeys.push(o)
            });
        } else {
            lineDataKeys.push("value")
        }
        return (
            <Grid container spacing={32}>
                <Grid item xs={12} md={12}>
                    <MultiUtilityChart
                        title={"Line Chart"}
                        height={300}
                        withHeader={false}
                        dataKey={"key"}
                        yLabel={of.slice(0, 1).toUpperCase() + of.slice(1, of.length).toLowerCase()}
                        xLabel={"Date"}
                        data={data}
                        lineDataKeys={lineDataKeys}
                        withoutAggregations={true}
                        fullWidth
                        showAverage={false}
                    />
                </Grid>
            </Grid>
        );
    }
}

export default Report_TimeseriesTrend;