/**
 * Created by jyothi on 1/11/17.
 */

import {
    QUERY_BUILDER_ATTRIBUTES_PENDING, QUERY_BUILDER_ATTRIBUTES, QUERY_BUILDER_ATTRIBUTES_FAILED,
    QUERY_BUILDER_ATTRIBUTE_VALUES_PENDING, QUERY_BUILDER_ATTRIBUTE_VALUES, QUERY_BUILDER_ATTRIBUTE_VALUES_FAILED,
    QUERY_BUILDER_SAVE_PENDING, QUERY_BUILDER_SAVE, QUERY_BUILDER_SAVE_FAILED,
    QUERY_BUILDER_EVENTS_PENDING, QUERY_BUILDER_EVENTS, QUERY_BUILDER_EVENTS_FAILED,
    QUERY_BUILDER_EVENT_ATTRIBUTES_PENDING, QUERY_BUILDER_EVENT_ATTRIBUTES, QUERY_BUILDER_EVENT_ATTRIBUTES_FAILED,
    QUERY_BUILDER_EVENT_ATTRIBUTE_VALUES_PENDING, QUERY_BUILDER_EVENT_ATTRIBUTE_VALUES, QUERY_BUILDER_EVENT_ATTRIBUTE_VALUES_FAILED,
    QUERY_BUILDER_COUNT_PENDING, QUERY_BUILDER_COUNT, QUERY_BUILDER_COUNT_FAILED,
    QUERY_BUILDER_UPDATE_QUERY, QUERY_BUILDER_RESET_QUERY,
    CUSTOM_EVENT_ENUM
} from './actionTypes';
import {
    getAttributesAPI, getAttributeValuesAPI,
    getEventsAPI, getEventAttributesAPI, getEventAttributeValuesAPI,
    getSegmentCountAPI, saveSegmentAPI
} from './api';

export const getAttributes = (appId) => {
    return (dispatch, getState) => {
        const of = getState().queryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of };
        return dispatch({
            types: [
                QUERY_BUILDER_ATTRIBUTES_PENDING,
                QUERY_BUILDER_ATTRIBUTES,
                QUERY_BUILDER_ATTRIBUTES_FAILED
            ],
            payload: {
                promise: getAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getAttributeValues = (appId, attribute, q) => {
    return (dispatch, getState) => {
        const of = getState().queryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of, q: q};
        return dispatch({
            types: [
                QUERY_BUILDER_ATTRIBUTE_VALUES_PENDING,
                QUERY_BUILDER_ATTRIBUTE_VALUES,
                QUERY_BUILDER_ATTRIBUTE_VALUES_FAILED,
            ],
            payload: {
                promise: getAttributeValuesAPI(getState().auth, appId, attribute, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute: attribute
            }
        });
    };
};

export const getEvents = (appId) => {
    return (dispatch, getState) => {
        const of = getState().queryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of};
        return dispatch({
            types: [
                QUERY_BUILDER_EVENTS_PENDING,
                QUERY_BUILDER_EVENTS,
                QUERY_BUILDER_EVENTS_FAILED
            ],
            payload: {
                promise: getEventsAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            },
            callbacks: {
                successDidDispatch: (dispatch, events) => {
                    /*if(Array.isArray(events) && events.length > 0) {
                     dispatch(updateCustomOverviewEvent(appId, events[0]));
                     dispatch(getCustomOverviewEventData(appId, events[0]));
                     }*/
                }
            }
        });
    };
};

export const getEventAttributes = (appId, event) => {
    return (dispatch, getState) => {
        const of = getState().queryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, event: event, of: of};
        return dispatch({
            types: [
                QUERY_BUILDER_EVENT_ATTRIBUTES_PENDING,
                QUERY_BUILDER_EVENT_ATTRIBUTES,
                QUERY_BUILDER_EVENT_ATTRIBUTES_FAILED,
            ],
            payload: {
                promise: getEventAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event: event
            },
            callbacks: {
                successDidDispatch: (dispatch, attributes) => {
                    if(Array.isArray(attributes)) { // not need as of now
                        //dispatch(getEventAttributeDistributions(appId, event, attributes));
                    }else{
                        console.error("No event attributes fetched..!")
                    }
                }
            }
        });
    };
};

export const getEventAttributeValues = (appId, event, attribute, q) => {
    return (dispatch, getState) => {
        const of = getState().queryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, event: event, of: of, attribute: attribute, q: q};
        return dispatch({
            types: [
                QUERY_BUILDER_EVENT_ATTRIBUTE_VALUES_PENDING,
                QUERY_BUILDER_EVENT_ATTRIBUTE_VALUES,
                QUERY_BUILDER_EVENT_ATTRIBUTE_VALUES_FAILED
            ],
            payload: {
                promise: getEventAttributeValuesAPI(getState().auth, appId, filters, event)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute: attribute,
                event: event
            }
        });
    };
};

const makeSegmentModel = (filters, query, name = "Temporary Segment") => {
    const { since, till, versions, modes } = filters;
    const { user, session, event, condition } = query;
    return {_id: String,
        name: name,
        since: since,
        till: till,
        versions: versions,
        modes: modes,
        user: user,
        session: session,
        events: event,
        condition: condition
    };
};

export const getSegmentCount = (appId) => {
    return (dispatch, getState) => {
        const of = getState().queryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of};
        const { query } = getState().queryBuilder;
        return dispatch({
            types: [
                QUERY_BUILDER_COUNT_PENDING,
                QUERY_BUILDER_COUNT,
                QUERY_BUILDER_COUNT_FAILED
            ],
            payload: {
                promise: getSegmentCountAPI(getState().auth, appId, filters, makeSegmentModel(filters, query))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

export const saveSegment = (appId, name) => {
    return (dispatch, getState) => {
        const of = getState().queryBuilder.of || CUSTOM_EVENT_ENUM.users;
        const filters = {...getState().filters, of: of};
        const { query } = getState().queryBuilder;
        return dispatch({
            types: [
                QUERY_BUILDER_SAVE_PENDING,
                QUERY_BUILDER_SAVE,
                QUERY_BUILDER_SAVE_FAILED
            ],
            payload: {
                promise: saveSegmentAPI(getState().auth, appId, filters, makeSegmentModel(filters, query, name))
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

export const updateQuery = (appId, query) => {
    return (dispatch, getState) => {
        return dispatch({
            type: QUERY_BUILDER_UPDATE_QUERY,
            payload: query,
            meta: {
                //
            }
        });
    };
};

export const resetQuery = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: QUERY_BUILDER_RESET_QUERY,
            payload: null,
            meta: {
                //
            }
        });
    };
};