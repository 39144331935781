/**
 * Created by Rakesh Peela
 * Date: 28-Nov-2019
 * Time: 11:07 AM
 */
import {REPORT_FAILED, REPORT_PENDING, REPORT_SUCCESS} from "./actionTypes";
import {getReportAPI} from "./api";

export const getReport = (appId, reportId, customerId) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                REPORT_PENDING,
                REPORT_SUCCESS,
                REPORT_FAILED
            ],
            payload: {
                promise: getReportAPI(appId, reportId, customerId)
                    .then(res => {
                        return res;
                    })
            },
            meta: {
                // If any
            }
        })
    }
};