/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import {updateHeading, resetHeading} from '../../../Navigation/actions';
import {
    loadSegmentData, resetCorrelations,
    updateSegments, deleteSegment, updateSegmentAttributeFilters,
    updateSegmentUserGroup, getSegmentUserAttributeDistribution, getSegmentSessionAttributeDistribution,
    updateSegmentGroupByAttribute, getSegmentEventAttributeDistribution,
    getSegmentCurrentEventAttributes, updateSegmentCurrentEvent, getSegmentList
} from '../actions';
import {
    ToolBar, SegmentTimeSeries, EventAttributeDistributions, SegmentRetention
} from '../components';
import AttributeDistributions from "../../../../../../../components/reusable/AttributeDistributions";
import PersonIcon from '@material-ui/icons/Person';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import {DASHBOARD_ENDPOINTS} from "../../../../../../../constants/EndPoints";
import {getGroupFromList, getGroupNameFromList} from "../../../../../../../utils";
import Placeholder from "../../../../../../../components/reusable/Placeholder";
import BrokenImageIcon from '@material-ui/icons/BrokenImage';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        segment: state.segment,
        filters: state.filters,
        meta: state.meta,
        queryBuilder: state.queryBuilder
    };
}

function mapDispatchToProps(dispatch) {
    return {
        resetHeading: () => dispatch(resetHeading()),
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        loadData: (appId, segmentId, segmentName, isThroughFilters = false) => dispatch(loadSegmentData(appId, segmentId, segmentName, isThroughFilters)),
        getSegments: (appId, segmentId) => dispatch(getSegmentList(appId, segmentId)),
        deleteSegment: (appId, segmentId) => dispatch(deleteSegment(appId, segmentId)),
        resetData: () => dispatch(resetCorrelations()),
        updateSegmentList: (appId) => (list) => dispatch(updateSegments(appId, list)),
        updateSegmentAttributeFilters: (filters) => dispatch(updateSegmentAttributeFilters(filters)),
        getSegmentUserAttributeDistribution: (appId, segmentId, attribute) =>
            dispatch(getSegmentUserAttributeDistribution(appId, segmentId, attribute)),
        getSegmentSessionAttributeDistribution: (appId, segmentId, attribute) =>
            dispatch(getSegmentSessionAttributeDistribution(appId, segmentId, attribute)),
        getSegmentEventAttributeDistribution: (appId, segmentId, attribute) =>
            dispatch(getSegmentEventAttributeDistribution(appId, segmentId, attribute)),
        updateSegmentUserGroup: (appId, segmentId, group) => {
            dispatch(updateSegmentUserGroup(group));
            dispatch(loadSegmentData(appId, segmentId, null, true));
        },
        updateSegmentGroupByAttribute: (appId, attribute) => {
            dispatch(updateSegmentGroupByAttribute(appId, attribute));
        },
        updateSegmentCurrentEvent: (appId, attribute) => {
            dispatch(updateSegmentCurrentEvent(appId, attribute));
        },
        getSegmentCurrentEventAttributes: (appId, event) => dispatch(getSegmentCurrentEventAttributes(appId, event)),
        bindedDispatch: dispatch
    };
}

class Segment extends Component {

    constructor(props){
        super(props);
        this.appId = this.props.params.appId;
        this.state = {
            segmentEvents: []
        };
    }

    componentWillMount(){
        const { params: { appId, segmentId }, loadData, getSegments, segment: { segment_list } } = this.props;
        getSegments(appId, segmentId);
        if(segmentId){
            loadData(this.appId, segmentId);
        }
        this.updateSegmentEvents(segmentId);
        this.updateSegmentName(segment_list, segmentId);
    }

    componentWillReceiveProps(nextProps){
        const oldSegmentList = this.props.segment.segment_list || [];
        const segmentList = nextProps.segment.segment_list || [];
        const { appId, segmentId } = nextProps.params;
        if(oldSegmentList.length !== segmentList.length){
            this.updateSegmentName(segmentList, segmentId);
        }
        this.updateSegmentEvents(segmentId);
        if(((!segmentId || segmentId.length === 0) && segmentList.length > 0) || (oldSegmentList.length > segmentList.length)){ //expecting a delete
            if(segmentList.length > 0){
                const segmentId = segmentList[0]._id;
                this.context.router.push(`/apps/${appId}/${DASHBOARD_ENDPOINTS.SEGMENTS}/${segmentId}`);
                this.props.updateHeading(segmentList[0].name);
                this.props.resetData();
                this.props.loadData(this.appId, segmentId, null, true);
            }
        }
    }

    componentWillUnmount(){
        this.props.resetData();
    }

    updateSegmentName = (segmentList, segmentId) => {
        this.props.updateHeading(getGroupNameFromList(segmentList, segmentId) || "Unknown Segment");
    };

    updateSegmentSelection = (segmentId) => {
        this.context.router.push(`/apps/${this.appId}/${DASHBOARD_ENDPOINTS.SEGMENTS}/${segmentId}`);
        if(segmentId) {
            this.props.loadData(this.appId, segmentId, null, true);
            this.updateSegmentName(this.props.segment.segment_list, segmentId);
            this.updateSegmentEvents(segmentId);
        }
    };

    updateSegmentEvents = (segmentId) => {
        const segment = getGroupFromList(this.props.segment.segment_list, segmentId);
        if(segment && Array.isArray(segment.event)){
            const segmentEvents = segment.event.filter(event => event.count.value !== 0).map(event => event.name);
            this.setState({segmentEvents});
        }
    };

    handleDeleteSegment = () => {
        this.props.deleteSegment(this.appId, this.props.params.segmentId);
    };

    handleAttributeFilters = (filters) => {
        const filtersObject = {...filters};
        let keyValuePairs = "";
        for(let key in filtersObject){
            if(filtersObject.hasOwnProperty(key) && filtersObject[key].length === 0){
                delete filtersObject[key];
            }else{
                keyValuePairs += ", " + key + " : [ " + filtersObject[key].join(",") + " ]";
            }
        }
        this.props.updateSegmentAttributeFilters(filtersObject);
        this.props.loadData(this.props.appState.userGroupIds, this.props.params.appId, null, true);
        ReactGA.event({
            category: 'User Group Filters',
            action: 'Property Filters',
            label: "{ " + keyValuePairs.trim().slice(2) + " }",
            value: Object.keys(filtersObject).length
        });
    };

    render() {
        const {
            appState: { attributes = {user: [], session: []} }, params: { appId, segmentId }, segment,
            getSegmentUserAttributeDistribution, getSegmentSessionAttributeDistribution
        } = this.props;
        const {
            segment_list = [],
            user_attribute_distributions, session_attribute_distributions
        } = segment;
        const segments = segment_list.map(o => ({value: o._id, label: o.name}));
        const isValidSegment = segment_list.findIndex(o => o._id === segmentId) > -1;
        if(!isValidSegment){
            return !this.props.meta.api_pending ?
                <section className="content">
                    <Placeholder
                        withIcon
                        icon={<BrokenImageIcon style={{opacity: 0.2, width: 250, height: 250}} />}
                        text="Not a valid Segment. Try coming from segments page."
                    />
                </section> : <span/>;
        }else{
            return (
                <section className="content">
                    <ToolBar
                        {...this.props}
                        placeholder="Select Segment"
                        options={segments}
                        value={segmentId || ""}
                        handleChange={this.updateSegmentSelection}
                        handleDeleteSegment={this.handleDeleteSegment}
                        single
                    />
                    <AttributeDistributions
                        {...this.props}
                        title="User Properties"
                        icon={<PersonIcon/>}
                        data={user_attribute_distributions}
                        stats={{}}
                        resetFilters={false}
                        attributes={attributes.user}
                        notSelectable
                        loadAttributeData={attribute => getSegmentUserAttributeDistribution(appId, segmentId, attribute)}
                    />
                    <AttributeDistributions
                        {...this.props}
                        title="Session Properties"
                        icon={<AccessTimeIcon/>}
                        data={session_attribute_distributions}
                        stats={{}}
                        resetFilters={false}
                        attributes={attributes.session}
                        notSelectable
                        loadAttributeData={attribute => getSegmentSessionAttributeDistribution(appId, segmentId, attribute)}
                    />

                    <SegmentTimeSeries {...this.props} {...this.context} />
                </section>
            );
        }
    }
}

Segment.propTypes = {

};

Segment.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Segment);