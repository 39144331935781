import React, { Component } from 'react';

export default function withSelfComponent(WrappedComponent) {
    return class extends Component {

        constructor(props){
            super(props);
            this.hydrator = null;
            this.state = {
                showControls: false,
                persisted: false
            }
        }

        componentWillReceiveProps(nextProps) {
            const { meta, updateNow } = nextProps;
            //reloading data for updated filters
            const { meta: { globalFiltersChanged }, updateNow: preUpdateNow  } = this.props;
            if((updateNow !== preUpdateNow && updateNow) || meta.globalFiltersChanged && globalFiltersChanged !== meta.globalFiltersChanged){
                this.hydrate(nextProps);
            }
        }

        componentWillMount(){
            //this.hydrate(this.props); No Ref here
        }

        componentDidMount(){
            this.hydrate(this.props);
        }

        hydrate = (props) => {
            if (typeof this.hydrator === 'function'){
                this.hydrator(props);
            }
        };

        getHydrator = (instance) => {
            if(instance){
                this.hydrator = instance.hydrator;
            }
        };

        handlePersistence = (persisted) => {
            this.setState({persisted});
        };

        render(){
            const props = {...this.props, ref: this.getHydrator};
            const { showControls } = this.state;
            return (
                <div style={{width: '100%'}} onMouseEnter={e => this.setState({showControls: true})} onMouseLeave={e => this.setState({showControls: false})}>
                    { showControls && <div style={{
                        position: 'absolute',
                        zIndex: 4,
                        right: 20,
                        width: 100,
                        display: 'flex'
                    }}>
                    </div> }
                    <WrappedComponent {...props} handlePersistence={this.handlePersistence}/>
                </div>
            )
        };
    }
}