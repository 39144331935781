/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
  LOGIN_USER_PENDING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  SIGN_UP_USER_SUCCESS,
  SIGN_UP_USER_ERROR,
  SIGN_UP_USER_PENDING,
  FORGOT_PASSWORD_PENDING,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD_PENDING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  INITIALIZE_FORM,
  AUTH_REDUCER_RESET,
  HOST_REQUEST_PENDING,
  HOST_REQUEST_FAILED,
  HOST_REQUEST_SUCCESS,
  SET_USER_PROFILE,
  LOGOUT_USER_SUCCESS,
  SET_ACCESS_EXPIRY,
  UNSET_ACCESS_EXPIRY,
  LOGIN_WITH_OTP_SUCCESS,
  LOGIN_WITH_OTP_ERROR,
} from "./actionTypes";

const INITIAL_LOGIN_FLAG_STATE = {
  login_pending: false,
  login_failed: false,
  auth_error_message: "",
};

const INITIAL_HOST_FLAG_STATE = {
  host_pending: false,
  host_failed: false,
  auth_error_message: "",
};

const FORGOT_PASSWORD_INITIAL_FLAG = {
  forgot_password_pending: false,
  forgot_password_failed: false,
  auth_error_message: "",
};

const RESET_PASSWORD_INITIAL_FLAG = {
  reset_password_pending: false,
  reset_password_failed: false,
  auth_error_message: "",
};

export const INITIAL_AUTH_FLAG_STATE = {
  ...INITIAL_LOGIN_FLAG_STATE,
  ...FORGOT_PASSWORD_INITIAL_FLAG,
  ...RESET_PASSWORD_INITIAL_FLAG,
  ...INITIAL_HOST_FLAG_STATE,
};

const INITIAL_STATE = {
  ...{
    host: null,
    token: null,
    user: {},
    forgot_password: false,
    reset_password: false,
    form_initial_data: null,
    accessExpiry: null,
    login_err: "",
  },
  ...INITIAL_AUTH_FLAG_STATE,
};

function authReducer(state = INITIAL_STATE, action = { payload: {} }) {
  let message = "";
  if (action.hasOwnProperty("payload") && action.payload) {
    message = action.payload.message || "";
  }
  switch (action.type) {
    case SIGN_UP_USER_PENDING:
    case LOGIN_USER_PENDING:
      return Object.assign({}, state, INITIAL_LOGIN_FLAG_STATE, {
        login_pending: true,
      });

    case SIGN_UP_USER_SUCCESS:
    case LOGIN_USER_SUCCESS:
      const { getHost } = action.meta;
      getHost && getHost();
      return Object.assign({}, state, INITIAL_LOGIN_FLAG_STATE, {
        token: action.payload.token,
        user: action.payload.profile,
      });
    case SET_ACCESS_EXPIRY:
      console.log(action);
      return {
        ...state,
        accessExpiry: action.payload.expiry,
      };
    case UNSET_ACCESS_EXPIRY:
      return {
        ...state,
        accessExpiry: null,
      };

    case SET_USER_PROFILE:
      return {
        ...state,
        user: action.payload.profile,
      };

    case SIGN_UP_USER_ERROR:
    case LOGIN_USER_ERROR:
      return Object.assign({}, state, INITIAL_LOGIN_FLAG_STATE, {
        login_failed: true,
        auth_error_message: message,
      });

    case LOGOUT_USER_SUCCESS:
      return Object.assign({}, state, INITIAL_STATE);

    case FORGOT_PASSWORD_PENDING:
      return Object.assign({}, state, FORGOT_PASSWORD_INITIAL_FLAG, {
        forgot_password_pending: true,
      });

    case FORGOT_PASSWORD_SUCCESS:
      return Object.assign({}, state, FORGOT_PASSWORD_INITIAL_FLAG, {
        forgot_password: action.payload,
      });

    case FORGOT_PASSWORD_ERROR:
      return Object.assign({}, state, FORGOT_PASSWORD_INITIAL_FLAG, {
        forgot_password_failed: true,
        auth_error_message: message,
      });

    case RESET_PASSWORD_PENDING:
      return Object.assign({}, state, RESET_PASSWORD_INITIAL_FLAG, {
        reset_password_pending: true,
      });

    case RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, RESET_PASSWORD_INITIAL_FLAG, {
        reset_password: action.payload,
      });

    case RESET_PASSWORD_ERROR:
      return Object.assign({}, state, RESET_PASSWORD_INITIAL_FLAG, {
        reset_password_failed: true,
        auth_error_message: message,
      });

    case AUTH_REDUCER_RESET: //to keep user profile if any
      return Object.assign({}, INITIAL_STATE, {
        token: state.token,
        user: state.user,
        host: state.host,
      });

    case INITIALIZE_FORM:
      return Object.assign({}, state, {
        form_initial_data: action.payload,
      });

    case HOST_REQUEST_PENDING:
      return {
        ...state,
        ...INITIAL_HOST_FLAG_STATE,
        host_pending: true,
      };

    case HOST_REQUEST_SUCCESS:
      return {
        ...state,
        ...INITIAL_HOST_FLAG_STATE,
        host: action.payload ? action.meta.host : null,
      };

    case HOST_REQUEST_FAILED:
      return {
        ...state,
        ...INITIAL_HOST_FLAG_STATE,
        host_failed: true,
        auth_error_message: message,
      };
    case LOGIN_WITH_OTP_ERROR:
      return {
        ...state,
        login_err: "Invalid OTP or Recovery",
      };
    case LOGIN_WITH_OTP_SUCCESS:
      return {
        ...state,
        login_err: "",
      };

    default:
      return state;
  }
}

export default authReducer;
