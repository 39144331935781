/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

export const MISC_PENDING = "MISC_PENDING";
export const MISC_FAILED = "MISC_FAILED";
export const RESET_MISC = "RESET_MISC";

/**
 uninstalls_per_session = [],
 uninstalls_per_videos_watched = [],
 active_video_watching_hours = [],
 dau_with_video = {},
 unfollows = {},
 uninstalled_follows = {},
 follow_distribution = [],
 users_formed_relationship = {},
 new_installs_formed_relationship = {},
 social_trends = []
 */

export const MISC_UNINSTALLS_PER_SESSION = "MISC_UNINSTALLS_PER_SESSION";

export const MISC_UNINSTALLS_PER_VIDEOS_WATCHED = "MISC_UNINSTALLS_PER_VIDEOS_WATCHED";

export const MISC_ACTIVE_VIDEO_WATCHING_HOURS = "MISC_ACTIVE_VIDEO_WATCHING_HOURS";

export const MISC_DAU_WITH_VIDEO = "MISC_DAU_WITH_VIDEO";

export const MISC_DAU_WITH_AUDIO = "MISC_DAU_WITH_AUDIO";

export const MISC_DAU_WITH_GIF = "MISC_DAU_WITH_GIF";

export const MISC_UNFOLLOWS = "MISC_UNFOLLOWS";

export const MISC_UNINSTALLED_FOLLOWS = "MISC_UNINSTALLED_FOLLOWS";

export const MISC_FOLLOW_DISTRIBUTION = "MISC_FOLLOW_DISTRIBUTION";

export const MISC_USERS_FORMED_RELATIONSHIP = "MISC_USERS_FORMED_RELATIONSHIP";

export const MISC_NEW_INSTALLS_FORMED_RELATIONSHIP = "MISC_NEW_INSTALLS_FORMED_RELATIONSHIP";

export const MISC_SOCIAL_TRENDS = "MISC_SOCIAL_TRENDS";

export const MISC_DROP_OFF_IN_ACTIVITY = "MISC_DROP_OFF_IN_ACTIVITY";

export const SOCIAL_EVENTS = [
    "SHARE_AUDIO_V3",
    "ACTION_HEART_CLICK_V3"
];

export const MISC_TIME_SPENT_ON_VIDEOS = "MISC_TIME_SPENT_ON_VIDEOS";

export const MISC_TIME_SPENT_ON_OTHERS = "MISC_TIME_SPENT_ON_OTHERS";