/**
 * Created by Araja Jyothi Babu on 24-Oct-16.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
    ContextCreator
} from './components';
import { withTheme } from '@material-ui/core/styles';

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        segment: state.segment,
        filters: state.filters,
        meta: state.meta,
        queryBuilder: state.queryBuilder,
        messages: state.messages,
        contexts: state.contexts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        bindedDispatch: dispatch
    };
}

class Context extends Component {

    render() {
        return (
            <ContextCreator {...this.props}/>
        );
    }
}

Context.propTypes = {
    handleUpdate: PropTypes.func.isRequired,
    conditions: PropTypes.array
};

Context.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTheme()(Context));