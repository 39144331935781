/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React, {Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { signUpUser, resetAuthReducer } from '../actions';
import SignUpForm from '../components/SignUpForm';
import {getHost} from '../../../../../utils';

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        signUp: (host, name, email, password) => dispatch(signUpUser(host, name, email, password)),
        resetAuth : () => dispatch(resetAuthReducer())
    };
}

class SignUp extends Component {
    static propTypes = {
        children: PropTypes.object,
        location: PropTypes.object,
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.auth.token) {
            try {
                const redirect = this.props.location.query.redirect;
                this.context.router.replace(redirect);
            } catch (err) {
                this.context.router.replace('/apps');
            }
        }
    }
    componentWillUnmount(){
        //this.props.resetAuth();
    }

    signUpHandler = (name, email, password) => {
        const host = getHost(window.location.hostname); //host = google if server.apxor.com | azure if serveraz.apxor.com
        this.props.signUp(host, name, email, password);
    };

    render(){
        const { auth: { login_failed, login_pending, auth_error_message } } = this.props;
        return (
            <SignUpForm
                handleSubmit={this.signUpHandler}
                hasError={login_failed}
                isPending={login_pending}
                error={auth_error_message}
            />
        );
    }
}

SignUp.contextTypes = {
    router: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SignUp);