/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */

import React, {Component, Fragment,} from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from "react-router";
import {PLATFORM_IMAGES} from "../Dashboard/Campaigns/Messages2/components/Flow/utils";
import {HIDE_CREATE_APP_DIALOG, RESET_CREATE_APP_DIALOG, SHOW_CREATE_APP_DIALOG} from "./actionTypes";
import {SDK_MONITORING_API} from './api';
import {API_ROOT, API_BASE, ApxorAppInfo} from '../../../../../config';
import {APP_PLATFORMS} from '../../../../../constants';
import { DASHBOARD_EVENTS } from '../../../../../constants/ActionTypes';
import {isUserRestricted, makeDefaultQueryString, sendEventAsMessage} from '../../../../../utils';
import logger from '../../../../../utils/Logger';
import axios from 'axios';
import Dialog from '../../../../../components/reusable/MaterialUi/Dialog';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import {Dialog as ModalDialog, DialogContent, DialogTitle, Tooltip} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineOutlined from '@material-ui/icons/AddCircleOutlineOutlined';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ToggleSwitch from "../../../../../components/reusable/MaterialUi/ToggleSwitch";
import Switch from "../../../../../components/reusable/Switch";
import Snackbar from "../../../../../components/reusable/MaterialUi/Snackbar";
import CreateNewAppComponent from "./CreateNewAppComponent";

class AddAppComponent extends Component {
    state = {
        app_name : "",
        bundle_id : "",
        platform : undefined,
        submitText : "Add",
        collapse : "collapse"
    };

    handleAppNameChange = (e) => {
        this.setState({app_name: e.target.value});
    };

    handleBundleIdChange = (e) => {
        this.setState({bundle_id: e.target.value});
    };

    handlePlatform = (platform) => {
        this.setState({platform});
    };

    handleAddApp = (e) => {
        e.preventDefault();
        let appName = this.state.app_name.trim();
        let bundleId = this.state.bundle_id.trim();
        let platform = this.state.platform.trim();
        if ((appName === "" || bundleId === "" || platform === null || platform === undefined)){
            return;
        }
        this.props.addApp({app_name : appName, bundle_id : bundleId, platform : platform});
        this.setState({app_name : "", bundle_id : "", platform : undefined}); //emptying app object
    };

    isFormValidated = () => {
        const { app_name, bundle_id, platform } = this.state;
        return app_name && app_name.trim().length > 0 &&
            bundle_id && bundle_id.trim().length > 0 &&
            platform && (APP_PLATFORMS.hasOwnProperty(platform));
    };

    render() {
        let note = this.props.size ? "New App / Site" : "Add your first App / Site";
        const { app_name, bundle_id, platform } = this.state;
        const { classes, appState } = this.props;
        const {addingFailed, addingSuccess, addingApp } = appState;
        const { android, ios, web, omni } = APP_PLATFORMS;
        const isWeb = platform === web;
        return (
            <form  onSubmit={this.handleAddApp}>
                <Grid container justify="center">
                    <Grid item xs={12}>
                        {addingSuccess && <Snackbar type="alert-success"><strong>{isWeb ? 'Site ' : 'App '} added successfully..!</strong></Snackbar>}
                        {addingFailed && <Snackbar type="alert-danger"><strong>{isWeb ? 'Site ' : 'App '} Failed to Add..! Try again..!</strong></Snackbar>}
                        <Typography variant="h6">
                            {note}
                        </Typography>
                        <Switch value={platform} data={Object.keys(APP_PLATFORMS)} handleChange={this.handlePlatform}
                        />
                        <TextField
                            value={app_name}
                            fullWidth
                            placeholder={isWeb ? 'Site Name' : 'App Name'}
                            onChange={this.handleAppNameChange}
                            label={isWeb ? 'Site Name' : 'App Name'}
                            type="text"
                            className={classes.textField}
                            required
                            margin="normal"
                        />
                        <TextField
                            value={bundle_id}
                            fullWidth
                            placeholder={isWeb ? 'www.example.com' : 'com.example.app'}
                            onChange={this.handleBundleIdChange}
                            label={isWeb ? 'Domain' : 'Bundle Id'}
                            type="text"
                            className={classes.textField}
                            required
                            margin="normal"
                        />
                        <Button style={{display: 'block', margin: '16px 0 0 auto'}} disabled={!this.isFormValidated()} variant="contained" color="primary" type="submit" onClick={this.handleAddApp}>
                            { addingApp && <CircularProgress color="secondary" size={30}/> }
                            { !addingApp && this.state.submitText }
                        </Button>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

export class MonitoringStatus extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            pending : false,
            status: {},
            On : {title : "Monitoring On, Click to Off", className : "label label-info", text : "ON"},
            Off : {title : "Monitoring Off, Click to On", className : "label label-default", text : "OFF"},
            monitoringChangeTriggered: false,
            enabled: false
        }
    }

    componentDidMount(){
        const { monitoring } = this.props.app;
        const status = monitoring ? this.state.On : this.state.Off;
        this.setState({ status : status, enabled: monitoring });
    }

    handleMonitoringState = (flag) => {
        //e.stopPropagation();
        if(!flag) return;
        this.setState({pending : true});
        const { status, On, Off, enabled } = this.state;
        const jsonObject = status === On ? {monitoring: false} : {monitoring: true};
        /**
         * FIXME: Need to handle this by forming a proper state container. Have to remove this Ajax call
         */
        const { auth, meta } = this.props;
        const {app_id, basic_info: {app_name}} = this.props.app;
        axios({
            method: "patch",
            url: makeDefaultQueryString((API_BASE + API_ROOT) + SDK_MONITORING_API, auth, app_id),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "X-Apx-Host": auth.host, //|| SERVER_IDENTIFIERS.google, //selects server api
                "X-Auth-Token": auth.token //TODO: make use of this
            },
            data: JSON.stringify(jsonObject),
            withCredentials: true,
        }).then(({data}) => {
            const statusFeature = status === On ? Off : On;
            this.setState({
                pending : false,
                status : statusFeature,
                monitoringChangeTriggered: false,
                enabled: !enabled
            });
            const {event, message} = DASHBOARD_EVENTS.SDK_MONITORING;
            sendEventAsMessage(event, message(statusFeature.text), app_id, app_name, auth.user.email, meta.location);
        }).catch(error => {
            this.setState({monitoring : ""});
            logger.error("monitoring status", status, error, jsonObject);
        });
    };

    handleMonitoringClick = (checked, e) => {
        e.stopPropagation();
        e.preventDefault();
        this.setState({ monitoringChangeTriggered: true});
    };

    handleDialogClose = () => {
        this.setState({monitoringChangeTriggered: false});
    };

    render() {
        const {status: {text}, pending, monitoringChangeTriggered, enabled} = this.state;
        const { app: {basic_info: {approved = true}}} = this.props;
        //const { app: { monitoring } } = this.props;
        const confirmFlag = enabled ? "DISABLE" : "ENABLE";
        return (
            <span title={"Monitoring"}>
                <ToggleSwitch
                    styles={{margin: 0}}
                    checked={!approved ? false : enabled}
                    disabled={!approved}
                    handleChange={this.handleMonitoringClick}
                />
                <Dialog
                    status={monitoringChangeTriggered}
                    handleConfirm={this.handleMonitoringState}
                    title="Monitoring Confirmation"
                    handleClose={this.handleDialogClose}
                    confirmLabel="Confirm"
                    allowCancelLabel="Cancel"
                >
                    <p>Are you sure want to <strong>{confirmFlag}</strong> SDK monitoring</p>
                    {
                        pending && <CircularProgress
                            size={30}
                        />
                    }
                </Dialog>
            </span>
        );
    }
}

const appComponentStyle = theme => ({
    root: {
        width: "100%",
        borderRadius: 10,
        transition: "all 0.2s ease-in-out",
        padding: 16,
        boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
        '&:hover': {
            // border: "1px solid #4177F6"
        }
    },
    root_pending: {
        width: "100%",
        borderRadius: 10,
        transition: "all 0.2s ease-in-out",
        padding: 16,
        boxShadow: "0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)",
        backgroundImage: "linear-gradient(135deg, #f6f7ff 25%, #ededf0 25%, #ededf0 50%, #f6f7ff 50%, #f6f7ff 75%, #ededf0 75%, #ededf0 100%)",
        backgroundSize: "11.31px 11.31px",
        pointerEvents: "none",
        '&:hover': {
            // border: "1px solid #4177F6"
        }
    },
    cardContent: {
        padding: 0,
    },
    logoAvatar: {
        marginRight: 12,
        width: 48,
        height: 48
    },
    appTitle: {
        fontWeight: 600,
        maxWidth: 260,
        width: "100%",
        textOverflow: "ellipsis",
        fontSize: 20,
        color: "#326185",
    },
    appDesc: {
        fontSize: 16,
        color: "#8a8a8a",
    },
    adminAvatar: {
        border: "2px solid white",
        fontSize: 16,
        width: 26,
        height: 26,
        '&:hover': {
            zIndex: 5,
            transform: "scale(1.1)"
        }
    },
    cardActions: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 12
    }
});

const colorList = ["#311B92", "#304FFE", "#0091EA", "#1B5E20", "#D50000", "#C51162", "#82B1FF", "#AA00FF",];

const ContributorsList = (customers = [], classes, email) => {
    return customers.slice(0,3).map((customer, index) => {
        const {customer_id,} = customer;
        return <Tooltip title={customer_id + (customer_id === email ? " (You)" : "")} key={index + "_" + customer_id}>
            <Avatar
                className={classes.adminAvatar}
                style={{backgroundColor: colorList[index], marginLeft: -6}}
            >
                {customer_id.slice(0, 1).toUpperCase()}
            </Avatar>
        </Tooltip>
    });
};

export const getPlatformLogoColor = (platform) => {
    switch (platform) {
        case APP_PLATFORMS.web: return "#4177F6";
        case APP_PLATFORMS.android: return "#3DDC84";
        case APP_PLATFORMS.ios: return "#A3AAAE";
        default: return null
    }
};

class AppComponent extends Component {

    state = {
        deleteTriggered: false
    };

    static propTypes = {
        //title: PropTypes.string.isRequired
    };

    handleNavigation = () => {
        const { app, handleNavigation } = this.props;
        handleNavigation(app);
    };

    handleDeleteClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
        console.error(e);
        this.setState({deleteTriggered: true});
    };

    render(){
        const {
            deleteApp,
            app: {
                app_id, basic_info: {
                    app_name, bundle_id, icon_url,
                    app_versions = [],
                    customers = [], platform,
                    customer_id, approved = true
                }
            },
            classes = {},
            auth: { user: { email } },
            location: { query: { deletable } }
        } = this.props;
        const { deleteTriggered } = this.state;
        return (
            <Card className={approved ? classes.root : classes.root_pending}>
                <CardContent className={classes.cardContent}>
                    <div style={{display: "flex", flexDirection: "row", marginBottom: 12, alignItems: "center"}}>
                        <Avatar
                            className={classes.logoAvatar}
                            alt={app_name} src={icon_url}
                            imgProps={{
                                onError: (e) => {
                                    e.target.src = window.apxorAppInfo.favicon
                                }
                            }}
                        />
                        <div>
                            <Typography
                                className={classes.appTitle}
                                title={app_name}
                                variant="body2"
                                noWrap
                            >
                                {app_name}
                            </Typography>
                            <Typography
                                className={classes.appDesc}
                                title={bundle_id}
                                variant="caption"
                                noWrap
                            >
                                {bundle_id}
                            </Typography>
                        </div>
                    </div>
                    <Grid container spacing={32} alignItems={"center"}>
                        <Grid item md={3}>
                            <div style={{paddingLeft: 16, paddingRight: 0, textAlign: "center"}}>
                                <Typography style={{marginBottom: 6, fontWeight: 300}}>
                                    Platform
                                </Typography>
                                {PLATFORM_IMAGES(platform, getPlatformLogoColor(platform), 24)}
                            </div>
                        </Grid>
                        <Grid item md={9}>
                            <div style={{
                                display: "flex",
                                padding: 16,
                                borderRadius: 8,
                                background: approved && "rgba(206, 213, 255, 0.2)"
                            }}>
                                <Grid container spacing={8} direction={"row"}>
                                    <Grid item>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            paddingRight: 16,
                                            alignItems: "center",
                                            borderRight: approved && '1px solid rgba(206, 213, 255, 0.8)'
                                        }}>
                                            <Typography style={{marginBottom: 6, fontWeight: 300}}> Owner </Typography>
                                            <div style={{display: "flex"}}>
                                            <Tooltip title={customer_id + (customer_id === email ? " (You)" : "")}>
                                                <Avatar
                                                    className={classes.adminAvatar}
                                                    style={{backgroundColor: colorList[customer_id.length % 3]}}
                                                >
                                                    {customer_id.slice(0, 1).toUpperCase()}
                                                </Avatar>
                                            </Tooltip>
                                                {!approved && <Typography style={{marginLeft: 8}}>{customer_id}</Typography>}
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid item>
                                        <div style={{paddingLeft: 16,}}>
                                            {
                                                customers
                                                && customers.length > 0
                                                && <Typography style={{marginBottom: 6, fontWeight: 300}}>
                                                    Collaborators
                                                </Typography>
                                            }
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: "row",
                                                alignItems: "center",
                                                marginLeft: 6
                                            }}>
                                                {
                                                    ContributorsList(customers, classes, email)
                                                }
                                                {
                                                    customers.length > 3
                                                    && <Typography
                                                        variant={"subtitle2"}
                                                        style={{marginLeft: 4}}
                                                    > + {customers.length - 3}
                                                    </Typography>
                                                }
                                            </div>
                                            {
                                                approved && email === customer_id && app_versions.length > 0
                                                && <div>
                                                    <Link to={`/apps/${app_id}/settings/iam`}>
                                                        <Tooltip title={"Add Collaborators"}>
                                                            <IconButton>
                                                                <PersonAddIcon
                                                                    fontSize={"small"}
                                                                    color={"primary"}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Link>
                                                </div>
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </CardContent>
                <div className={classes.cardActions}>
                    {!isUserRestricted(customers, email) ? <MonitoringStatus {...this.props}/> : <div/>}
                    {
                        approved && <Button
                            onClick={this.handleNavigation}
                            color={"primary"}
                            variant={"outlined"}
                            size={"small"}
                        >
                            Analyse <ArrowForwardIcon style={{marginLeft: 8}}/>
                        </Button>
                    }
                    {
                        !approved && <div style={{
                            fontSize: 12,
                            fontWeight: 800,
                            border: "1px solid #ffbe42",
                            borderRadius: 6,
                            background: "#F6F0CF",
                            padding: "4px 8px",
                            color: "#545454",
                        }}>
                            Approval Pending
                        </div>
                    }
                </div>
            </Card>
        );
    }
}

const StyledAppComponent = withStyles(appComponentStyle)(AppComponent);

export {StyledAppComponent};

const styles = theme => ({
    card: {
        width: '100%',
        maxWidth: 300,
        cursor: 'pointer'
    },
    root: {
        padding: 0,
        '&:last-child': {
            paddingBottom: 0,
        }
    },
    content: {
        padding: theme.spacing.unit * 4,
        '&:hover': {
            boxShadow: theme.custom.shadow
        }
    },
    actions: {
        justifyContent: 'flex-end',
        padding: 0
    },
    expand: {
        transform: 'rotate(0deg)',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        margin: `${theme.spacing.unit}px auto`,
    },
    flexGrow: {
        flex: '1 1 auto',
    },
    textField: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
    },
    addIcon: {
        width: 64,
        height: 64
    },
    addNewButton: {
        margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 4}px auto`,
        display: 'block'
    },
    divider: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)'
    }
});

class AppsComponent extends React.Component {

    state = {
        expanded: false,
        query: ''
    };

    handleOpen = () => {
        this.props.boundDispatch({
            type: SHOW_CREATE_APP_DIALOG,
            payload: {},
            meta: {}
        })
    };

    handleClose = () => {
        this.props.boundDispatch({
            type: HIDE_CREATE_APP_DIALOG,
            payload: {},
            meta: {}
        })
    };

    handleQuery = (e) => {
        this.setState({query: '' + e.target.value});
    };

    render() {
        const {
            apps = [], appsState: { appsLoading }, auth: { user: { email } }, addApp
        } = this.props;
        const {appsState: {showAddAppDialog}} = this.props;
        const {classes, ...otherProps} = this.props;
        const { expanded, query } = this.state;
        const filteredApps = apps.filter(o => o.basic_info.app_name.toLowerCase().includes(query.toLowerCase()));
        return (
            <Fragment>
                { !appsLoading && apps.length === 0 &&
                    <div style={{textAlign: 'center', marginTop: 50}}>
                        <IconButton onClick={this.handleOpen}>
                            <AddCircleOutlineOutlined color="primary" className={classes.addIcon}/>
                        </IconButton>
                        <Typography variant="caption" style={{fontSize: 32}}>No Apps Found</Typography>
                        <Typography variant="body2">Add new by pressing over "+"</Typography>
                    </div>
                }
                {
                    showAddAppDialog
                    && <ModalDialog
                        open={showAddAppDialog}
                        onClose={this.handleClose}
                        disableBackdropClick
                        disableEscapeKeyDown
                        scroll={"body"}
                    >
                        <DialogTitle style={{padding: 12, borderBottom: "1px solid #cecece", marginBottom: 8}}>
                            Create New App
                        </DialogTitle>
                        <DialogContent>
                            <CreateNewAppComponent handleCancel={this.handleClose} handleSave={addApp}/>
                        </DialogContent>
                    </ModalDialog>
                }
                {/*<Dialog*/}
                {/*    status={expanded}*/}
                {/*    handleClose={this.handleClose}*/}
                {/*    noActions*/}
                {/*>*/}
                {/*    <CreateNewAppComponent />*/}
                {/*    /!*<AddAppComponent {...this.props}/>*!/*/}
                {/*</Dialog>*/}
                {
                    apps.length > 0 &&
                    <Fragment>
                        <Grid container justify="space-between">
                            <Grid item xs={6} md={4}>
                                {apps.length > 2 &&
                                    <TextField
                                        label="Search"
                                        value={query}
                                        margin="normal"
                                        onChange={this.handleQuery}
                                        fullWidth
                                    />
                                }
                            </Grid>
                            <Grid item xs={3} md={2}>
                                <Button className={classes.addNewButton} color="primary" variant="contained" onClick={this.handleOpen}>Add New</Button>
                            </Grid>
                        </Grid>
                        <Grid container spacing={16} justify="center">
                            {
                                filteredApps.map((app, index) =>
                                    <Grid key={index + app.app_id} item xs={12} sm={6} md={4}>
                                        <StyledAppComponent {...otherProps} app={app}/>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Fragment>
                }
            </Fragment>
        );
    }
}

AppsComponent.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppsComponent);