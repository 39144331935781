/**
 * Created by Rakesh Peela
 * Date: 08-Sep-2019
 * Time: 10:37 AM
 */
export const GET_COHORTS = "GET_COHORTS";
export const GET_COHORTS_PENDING = "GET_COHORTS_PENDING";
export const GET_COHORTS_FAILED = "GET_COHORTS_FAILED";

export const CREATE_COHORTS = "CREATE_COHORTS";
export const CREATE_COHORTS_PENDING = "CREATE_COHORTS_PENDING";
export const CREATE_COHORTS_FAILED = "CREATE_COHORTS_FAILED";

export const UPDATING_COHORT = "UPDATING_COHORT";
export const UPDATING_COHORT_PENDING = "UPDATING_COHORT_PENDING";
export const UPDATING_COHORT_FAILED = "UPDATING_COHORT_FAILED";

//TODO: Add more actionTypes
