/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React from 'react';
import { Link } from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Placeholder from "../../../../../../components/reusable/Placeholder";
import {Button, Grid, Typography, Fab} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import QueryBuilder from '@material-ui/icons/QueryBuilder';
import Dialog from "../../../../../../components/reusable/MaterialUi/Dialog";
import Snackbar from "../../../../../../components/reusable/MaterialUi/Snackbar";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ToolTip from '@material-ui/core/Tooltip';
import CardActions from '@material-ui/core/CardActions';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';

export class ContextsHolder extends React.Component{

    constructor(props){
        super(props);
        const { contexts = [] } = props.contexts;
        this.state = {
            expanded: null,
            contexts,
            query: ""
        };
    }

    componentWillMount(){

    }

    componentWillReceiveProps(nextProps){
        const { contexts = [] } = nextProps.contexts;
        const oldContextList = this.props.contexts.contexts;
        if(contexts.length !== oldContextList.length){
            this.updateContextsWithQuery(contexts);
        }
    }

    updateContextsWithQuery = (contexts = []) => {
        const query = this.state.query.toLowerCase();
        const updatedContexts = contexts.filter(({name = "", conditions = []}) => {
            const inName = name.toLowerCase().includes(query);
            const inConditions = conditions.some(({activity = "", details, trigger}) => {
                const inActivity = activity.toLowerCase().includes(query);
                const inName = details.name.toLowerCase().includes(query);
                const inTrigger = trigger.details.name.toLowerCase().includes(query);
                return inActivity || inName || inTrigger;
            });
            return inName || inConditions;
        });
        this.setState({contexts: updatedContexts});
    };

    handleChange = panel => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false,
        });
    };

    handleQueryChange = (event) => {
        const { context_list } = this.props.context;
        this.setState({query: event.target.value}, () => this.updateContextsWithQuery(context_list));
    };


    render(){
        const { expanded, contexts = [] } = this.state;
        const {
             params: { appId }, contexts: { contexts: propContexts = [] }
        } = this.props;
        return(
            <Grid container justify="center" spacing={24}>
                <Grid item xs={12} md={8}>
                    <Grid container justify="flex-end" spacing={16}>
                        <Grid item xs={12} sm={3} style={{marginBottom: 20}}>
                            <TextField
                                placeholder="Search Contexts"
                                id="query-adornment"
                                fullWidth
                                InputProps={{
                                    startAdornment: <InputAdornment position="end" style={{padding: '20px 0'}}><SearchIcon/></InputAdornment>
                                }}
                                onChange={this.handleQueryChange}
                            />
                        </Grid>
                        <Grid item xs={6} sm={1} style={{marginBottom: 20}}>
                            <Link to={`/apps/${appId}/contexts/new`} style={{spacing:10}} >
                                <ToolTip id="tooltip-fab-add" title="Create New Context" placement="bottom">
                                    <Fab size="small" color="primary" aria-label="Create New Context">
                                        <AddIcon />
                                    </Fab>
                                </ToolTip>
                            </Link>
                        </Grid>
                    </Grid>
                    {
                        contexts.map(context =>
                            <ContextHolder
                                key={context._id}
                                {...this.props}
                                {...context}
                                handleChange={this.handleChange(context._id)}
                                expanded={expanded}
                            />
                        )
                    }
                    {
                        !this.props.meta.api_pending && (!Array.isArray(propContexts) || propContexts.length === 0) &&
                        <Placeholder
                            withIcon
                            icon={<QueryBuilder style={{opacity: 0.2, width: 150, height: 150}} />}
                            text="To create your first context, tap the plus on the top right of the dashboard."
                        />
                    }
                </Grid>
            </Grid>
        )

    }

}

export class ContextHolder extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            deleteTriggered: false
        };
    }

    handleChange = (event, expanded) => {
        this.props.handleChange(event, expanded);
    };

    render(){
        const {
            expanded, params: { appId },
            contexts: {
                context_delete_failed
            },
            deleteContext,
            name, _id
        } = this.props;
        const { deleteTriggered } = this.state;
        return(
            <div style={{width: '100%'}}>
                <ExpansionPanel expanded={expanded === _id} onChange={this.handleChange}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Grid container spacing={24}>
                            <Grid item xs>
                                <Typography variant="subtitle1" style={{marginTop: '0.8em'}}>{name}</Typography>
                            </Grid>
                            <Grid item xs={1} onClick={(e) => e.stopPropagation()}>
                                { context_delete_failed && <Snackbar>Deleting Context Failed. Try Again..!</Snackbar> }
                                <ToolTip id="tooltip-fab" title="Delete this Context" placement="bottom">
                                    <IconButton color="secondary" aria-label="Delete"
                                                onClick={() => this.setState({deleteTriggered: true})}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </ToolTip>
                                <Dialog
                                    status={deleteTriggered}
                                    handleConfirm={() => {
                                        deleteContext(appId, _id);
                                        this.setState({deleteTriggered: false});
                                    }}
                                    title="Delete Context Confirmation"
                                    handleClose={() => this.setState({deleteTriggered: false})}
                                    confirmLabel="Confirm"
                                    allowCancelLabel="Cancel"
                                >
                                    <Typography variant="subtitle1">Are you sure want to delete the context <strong>{name}</strong> ? </Typography>
                                </Dialog>
                            </Grid>
                        </Grid>
                    </ExpansionPanelSummary>
                    {
                        expanded === _id && <ExpansionPanelDetails>
                            <Context {...this.props}/>
                        </ExpansionPanelDetails>
                    }
                </ExpansionPanel>
            </div>
        )

    }

}

export class Context extends React.Component {

    render(){
        const {
            params: { appId },
            _id, conditions,
            updateLocalContext
        } = this.props;
        return(
            <Card style={{width: '100%'}}>
                <CardContent>
                    {/*<ContextConfig {...this.props}/>*/}
                </CardContent>
                <CardActions>
                    <Link to={`/apps/${appId}/contexts/${_id}`}>
                        <Button size="small" variant="contained" color="primary">
                            View
                        </Button>
                    </Link>
                    <Link to={`/apps/${appId}/contexts/new`}>
                        <Button size="small" color="secondary" onClick={() => {
                            //update context create new
                            updateLocalContext({name: "", conditions});
                        }}>
                            Create A new context from this
                        </Button>
                    </Link>
                </CardActions>
            </Card>
        )

    }

}