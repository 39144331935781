/**
 * Created by Rakesh Peela
 * Date: 13-Nov-2019
 * Time: 10:52 AM
 */

import React from 'react';
import {Card, withStyles} from '@material-ui/core';
import PropTypes from 'prop-types';

const styles = (theme) => {
    return {
        root: {
            width: "100%",
            borderRadius: 10,
            border: "1px solid #e4eaec",
            padding: 16,
            // margin: "8px 0",
        },
        rootSelected: {
            width: "100%",
            borderRadius: 10,
            border: "1px solid #4177F6",
            boxShadow: "0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24)",
            padding: 16,
            // margin: "8px 0",
        },
        rootDisabled: {
            width: "100%",
            borderRadius: 10,
            padding: 16,
            background: "#f2f2f2",
            border: "1px solid transparent",
            // margin: "8px 0",
        },
        rootPlaceholderType: {
            border: "2px dashed #4177F6",
            background: "transparent",
            // margin: "8px 0",
            width: "100%",
            borderRadius: 10,
        }
    }
};

const getClass = (type, classes) => {
    switch (type) {
        case "selected":
            return classes["rootSelected"];
        case "disabled":
            return classes["rootDisabled"];
        case "placeholderType":
            return classes["rootPlaceholderType"];
        default:
            return classes["root"];
    }
};

class ClassicCard extends React.Component {
    render() {
        const {classes, selected, disabled, style, placeholderType} = this.props;
        return (
            <Card style={{transition: "all 0.1s ease-in-out", ...style}}
                  className={getClass(selected ? "selected" : (disabled ? "disabled" : (placeholderType ? "placeholderType" : "root")), classes)}
                  elevation={0}>
                {this.props.children}
            </Card>
        )
    }
}

ClassicCard.propTypes = {
    selected: PropTypes.bool,
    disabled: PropTypes.bool
};

export default withStyles(styles)(ClassicCard);