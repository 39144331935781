/**
 * Created by kodanda_rama on 2/1/17.
 */
import React, {Component} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Snackbar from '../../../../../components/reusable/MaterialUi/Snackbar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Loading from "../../../../../components/reusable/Loading";

export default class ResetPasswordForm extends Component {
    constructor(props){
        super(props);
        this.state = {
            password : '',
            repassword: ''
        }
    }

    handlePasswordChange = (e) => {
        this.setState({password: e.target.value.trim()});
    };

    handleRePasswordChange = (e) => {
        this.setState({repassword: e.target.value.trim()});
    };

    render(){
        const { password, repassword } = this.state;
        const { reset_password, reset_password_pending, reset_password_failed, auth_error_message } = this.props;
        const disabled = password !== repassword || password.length < 6;
        return (
            <Card elevation={0}>
                <CardHeader
                    title="Reset Password"
                />
                <CardContent>
                    <form name="login" onSubmit={(e) => {
                        document.activeElement.blur();
                        e.preventDefault();
                        this.props.handleResetPassword(password);
                    }}>
                        { reset_password_failed && <Snackbar><strong>{auth_error_message}</strong></Snackbar> }
                        { reset_password &&
                        <Snackbar type="alert-success">
                            <strong>Successful..! Login with new Password.</strong>
                        </Snackbar>
                        }
                        <TextField
                            id="password"
                            value={password}
                            fullWidth
                            placeholder="P@$$w0rd"
                            onChange={this.handlePasswordChange}
                            label="Password"
                            type="password"
                        />
                        <TextField
                            id="repassword"
                            value={repassword}
                            fullWidth
                            placeholder="P@$$w0rd"
                            onChange={this.handleRePasswordChange}
                            label="Re-Enter Password"
                            type="password"
                        />
                        <Grid container style={{marginTop: 20}} justify="flex-end">
                            <Grid item xs={4}>
                                <Button variant="contained" color="primary" type="submit" disabled={disabled}>
                                    { reset_password_pending && <Loading /> }
                                    { !reset_password_pending && <span>Submit</span>}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        )
    }
}