/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import {
    DROP_OFF_BY_SCREENS_PENDING, DROP_OFF_BY_SCREENS, DROP_OFF_BY_SCREENS_FAILED,
    DROP_OFF_BY_EVENTS_PENDING, DROP_OFF_BY_EVENTS, DROP_OFF_BY_EVENTS_FAILED,
    DROP_OFF_SCREEN_EVENTS_PENDING, DROP_OFF_SCREEN_EVENTS, DROP_OFF_SCREEN_EVENTS_FAILED,
    DROP_OFF_SCREEN_LAST_EVENTS_PENDING, DROP_OFF_SCREEN_LAST_EVENTS, DROP_OFF_SCREEN_LAST_EVENTS_FAILED,
    UPDATE_DROP_OFF_GROUP, UPDATE_DROP_OFF_SEGMENT, UPDATE_DROP_OFF_FILTERS, UPDATE_DROP_OFF_SCREEN,
    DROP_OFF_SESSIONS_COUNT_PENDING, DROP_OFF_SESSIONS_COUNT, DROP_OFF_SESSIONS_COUNT_FAILED,
    DROP_OFF_SCREENS_COUNT_PENDING, DROP_OFF_SCREENS_COUNT, DROP_OFF_SCREENS_COUNT_FAILED,
    DROP_OFF_EVENTS_COUNT_PENDING, DROP_OFF_EVENTS_COUNT, DROP_OFF_EVENTS_COUNT_FAILED,
    DROP_OFF_SCREEN_TIME_SPENT_PENDING, DROP_OFF_SCREEN_TIME_SPENT, DROP_OFF_SCREEN_TIME_SPENT_FAILED,
    DROP_OFF_ENUM, UPDATE_DROP_OFF_CURRENT_ACTIVE, UPDATE_DROP_TIME_SLOT,
    DROP_OFF_EVENT_ATTRIBUTES_PENDING, DROP_OFF_EVENT_ATTRIBUTES, DROP_OFF_EVENT_ATTRIBUTES_FAILED,
    DROP_OFF_SCREEN_EVENT_ATTRIBUTES_PENDING, DROP_OFF_SCREEN_EVENT_ATTRIBUTES, DROP_OFF_SCREEN_EVENT_ATTRIBUTES_FAILED,
    DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES_PENDING, DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES, DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES_FAILED,
    DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING, DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION, DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED,
    DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING, DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION, DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED,
    DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING, DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION, DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED,
    UPDATE_DROP_OFF_EVENT, UPDATE_DROP_OFF_SCREEN_EVENT, UPDATE_DROP_OFF_SCREEN_LAST_EVENT,
    DROP_OFF_EVENT_USERS_PENDING, DROP_OFF_EVENT_USERS, DROP_OFF_EVENT_USERS_FAILED,
    DROP_OFF_SCREEN_USERS_PENDING, DROP_OFF_SCREEN_USERS, DROP_OFF_SCREEN_USERS_FAILED,
    DROP_OFF_SCREEN_EVENT_USERS_PENDING, DROP_OFF_SCREEN_EVENT_USERS, DROP_OFF_SCREEN_EVENT_USERS_FAILED,
    DROP_OFF_SCREEN_LAST_EVENT_USERS_PENDING, DROP_OFF_SCREEN_LAST_EVENT_USERS, DROP_OFF_SCREEN_LAST_EVENT_USERS_FAILED
} from './actionTypes';
import {
    dropOffEventsByScreenAPI, dropOffCountByAPI, dropOffTimeSpentOnScreen,
    dropOffsByEventsAPI, dropOffsByScreensAPI, dropOffEventAttributeDistributionAPI
} from "./api";
import {getEventAttributesAPI} from "../Segments/NewSegment/api";
import {searchUserProfiles} from "../Users/UserExplorer/api";

const { SESSION, SCREEN, EVENT } = DROP_OFF_ENUM;

export const getDropOffSessionsCount = (appId) => {
    return (dispatch, getState) => {
        const { filters, searchFilters } = makeFilters(getState(), null, {of: SESSION});
        return dispatch({
            types: [
                DROP_OFF_SESSIONS_COUNT_PENDING, DROP_OFF_SESSIONS_COUNT, DROP_OFF_SESSIONS_COUNT_FAILED,
            ],
            payload: {
                promise: dropOffCountByAPI(getState().auth, appId, filters, searchFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getDropOffScreensCount = (appId) => {
    return (dispatch, getState) => {
        const { filters, searchFilters } = makeFilters(getState(), null, {of: SCREEN});
        return dispatch({
            types: [
                DROP_OFF_SCREENS_COUNT_PENDING, DROP_OFF_SCREENS_COUNT, DROP_OFF_SCREENS_COUNT_FAILED,
            ],
            payload: {
                promise: dropOffCountByAPI(getState().auth, appId, filters, searchFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getDropOffEventsCount = (appId) => {
    return (dispatch, getState) => {
        const { filters, searchFilters } = makeFilters(getState(), null, {of: EVENT});
        return dispatch({
            types: [
                DROP_OFF_EVENTS_COUNT_PENDING, DROP_OFF_EVENTS_COUNT, DROP_OFF_EVENTS_COUNT_FAILED,
            ],
            payload: {
                promise: dropOffCountByAPI(getState().auth, appId, filters, searchFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

/**
 *
 * @param appId
 * @returns {function(*, *)}
 */
export const getDropOffsByScreens = (appId) => {
    return (dispatch, getState) => {
        const { filters, searchFilters } = makeFilters(getState(), null, {by: DROP_OFF_ENUM.SCREEN});
        return dispatch({
            types: [
                DROP_OFF_BY_SCREENS_PENDING, DROP_OFF_BY_SCREENS, DROP_OFF_BY_SCREENS_FAILED
            ],
            payload: {
                promise: dropOffsByScreensAPI(getState().auth, appId, filters, searchFilters)
                    .then((res) => {
                        return Array.isArray(res) ? res.map(o => ({Screen: o.key, Sessions: o.count, Users: o.users})) : [];
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getDropOffsByEvents = (appId) => {
    return (dispatch, getState) => {
        const { filters, searchFilters } = makeFilters(getState(), null, {by: DROP_OFF_ENUM.EVENT});
        return dispatch({
            types: [
                DROP_OFF_BY_EVENTS_PENDING, DROP_OFF_BY_EVENTS, DROP_OFF_BY_EVENTS_FAILED,
            ],
            payload: {
                promise: dropOffsByEventsAPI(getState().auth, appId, filters, searchFilters)
                    .then((res) => {
                        return Array.isArray(res) ? res.map(o => ({Event: o.key, Sessions: o.count, Users: o.users})) : [];
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getDropOffScreenTimeSpent = (appId, screen) => {
    return (dispatch, getState) => {
        const { filters, searchFilters } = makeFilters(getState(), screen);
        return dispatch({
            types: [
                DROP_OFF_SCREEN_TIME_SPENT_PENDING, DROP_OFF_SCREEN_TIME_SPENT, DROP_OFF_SCREEN_TIME_SPENT_FAILED,
            ],
            payload: {
                promise: dropOffTimeSpentOnScreen(getState().auth, appId, screen, filters, searchFilters)
                    .then((res) => {
                        return Array.isArray(res) ? res.map(o => ({key: o.key, Sessions: o.count, Users: o.users})) : [];
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getDropOffScreenEvents = (appId, screen) => {
    return (dispatch, getState) => {
        const { timeSlot } = getState().dropOff;
        const { filters, searchFilters } = makeFilters(getState(), screen, {timeSlot});
        return dispatch({
            types: [
                DROP_OFF_SCREEN_EVENTS_PENDING, DROP_OFF_SCREEN_EVENTS, DROP_OFF_SCREEN_EVENTS_FAILED,
            ],
            payload: {
                promise: dropOffEventsByScreenAPI(getState().auth, appId, screen, filters, searchFilters)
                    .then((res) => {
                        return Array.isArray(res) ? res.map(o => ({key: o.key, Occurrences: o.count, Users: o.users})) : [];
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

function makeFilters(state, screen, otherFilters) {
    const { group, segmentId, user, session, event, screen : updatedScreen } = state.dropOff;
    let segment = segmentId && segmentId.length > 0 ? segmentId : null;
    const actualScreen = screen || updatedScreen;
    const filters = {...state.filters, group, segmentId: segment, screen: actualScreen, ...otherFilters };
    const searchFilters = { user, session, event };
    return { filters, searchFilters }
}

export const getDropOffScreenLastEvents = (appId, screen) => {
    return (dispatch, getState) => {
        const { timeSlot } = getState().dropOff;
        const { filters, searchFilters } = makeFilters(getState(), screen, {isLast: true, timeSlot});
        return dispatch({
            types: [
                DROP_OFF_SCREEN_LAST_EVENTS_PENDING, DROP_OFF_SCREEN_LAST_EVENTS, DROP_OFF_SCREEN_LAST_EVENTS_FAILED
            ],
            payload: {
                promise: dropOffEventsByScreenAPI(getState().auth, appId, screen, filters, searchFilters)
                    .then((res) => {
                        return Array.isArray(res) ? res.map(o => ({key: o.key, Occurrences: o.count, Users: o.users})) : [];
                    }),
            },
            meta: {
                //If Any
            }
        });
    };
};

export const getEventAttributes = (appId, event) => {
    return (dispatch, getState) => {
        const { filters } = makeFilters(getState(), null, {event});
        return dispatch({
            types: [
                DROP_OFF_EVENT_ATTRIBUTES_PENDING, DROP_OFF_EVENT_ATTRIBUTES, DROP_OFF_EVENT_ATTRIBUTES_FAILED,
            ],
            payload: {
                promise: getEventAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event
            },
            callbacks: {
                successDidDispatch: (dispatch, attributes) => {
                    /*if(Array.isArray(attributes)){
                        attributes.forEach(attribute => {
                            dispatch(getEventAttributeDistribution(appId, event, attribute));
                        });
                    }*/
                }
            }
        });
    };
};

export const getEventAttributeDistribution = (appId, event, attribute) => {
    return (dispatch, getState) => {
        const { filters, searchFilters } = makeFilters(getState(), null, {event, attribute, dropOff: true, of: SESSION});
        return dispatch({
            types: [
                DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING, DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION, DROP_OFF_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED,
            ],
            payload: {
                promise: dropOffEventAttributeDistributionAPI(appId, getState().auth, filters, searchFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute
            }
        });
    };
};

export const getScreenEventAttributes = (appId, screen, event) => {
    return (dispatch, getState) => {
        const { timeSlot } = getState().dropOff;
        const { filters} = makeFilters(getState(), screen, {event, timeSlot});
        return dispatch({
            types: [
                DROP_OFF_SCREEN_EVENT_ATTRIBUTES_PENDING, DROP_OFF_SCREEN_EVENT_ATTRIBUTES, DROP_OFF_SCREEN_EVENT_ATTRIBUTES_FAILED,
            ],
            payload: {
                promise: getEventAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event
            },
            callbacks: {
                successDidDispatch: (dispatch, attributes) => {
                    /*if(Array.isArray(attributes)){
                        attributes.forEach(attribute => {
                            dispatch(getScreenEventAttributeDistribution(appId, screen, event, attribute));
                        });
                    }*/
                }
            }
        });
    };
};

export const getScreenEventAttributeDistribution = (appId, screen, event, attribute) => {
    return (dispatch, getState) => {
        const { timeSlot } = getState().dropOff;
        const { filters, searchFilters } = makeFilters(getState(), screen, {screenId: screen, event, attribute, dropOff: true, of: SESSION, timeSlot});
        return dispatch({
            types: [
                DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING, DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION, DROP_OFF_SCREEN_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED,
            ],
            payload: {
                promise: dropOffEventAttributeDistributionAPI(appId, getState().auth, filters, searchFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute
            }
        });
    };
};

export const getScreenLastEventAttributes = (appId, screen, event) => {
    return (dispatch, getState) => {
        const { timeSlot } = getState().dropOff;
        const { filters } = makeFilters(getState(), screen, {event, timeSlot});
        return dispatch({
            types: [
                DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES_PENDING, DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES, DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTES_FAILED,
            ],
            payload: {
                promise: getEventAttributesAPI(getState().auth, appId, filters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                event
            },
            callbacks: {
                successDidDispatch: (dispatch, attributes) => {
                    /*if(Array.isArray(attributes)){
                        attributes.forEach(attribute => {
                            dispatch(getScreenLastEventAttributeDistribution(appId, screen, event, attribute));
                        });
                    }*/
                }
            }
        });
    };
};

export const getScreenLastEventAttributeDistribution = (appId, screen, event, attribute) => {
    return (dispatch, getState) => {
        const { timeSlot } = getState().dropOff;
        const { filters, searchFilters } = makeFilters(getState(), screen, {screenId: screen, event, attribute, isLast: true, dropOff: true, of: SESSION, timeSlot});
        return dispatch({
            types: [
                DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION_PENDING, DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION, DROP_OFF_SCREEN_LAST_EVENT_ATTRIBUTE_DISTRIBUTION_FAILED
            ],
            payload: {
                promise: dropOffEventAttributeDistributionAPI(appId, getState().auth, filters, searchFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                attribute
            }
        });
    };
};

export const getScreenUsers = (appId, screen) => {
    return (dispatch, getState) => {
        const { timeSlot } = getState().dropOff;
        const { filters, searchFilters } = makeFilters(getState(), screen, {screenId: screen, dropOff: true, timeSlot});
        return dispatch({
            types: [
                DROP_OFF_SCREEN_USERS_PENDING, DROP_OFF_SCREEN_USERS, DROP_OFF_SCREEN_USERS_FAILED
            ],
            payload: {
                promise: searchUserProfiles(getState().auth, appId, filters, searchFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

export const getScreenEventUsers = (appId, screen, event) => {
    return (dispatch, getState) => {
        const { timeSlot } = getState().dropOff;
        const { filters, searchFilters } = makeFilters(getState(), screen, {screenId: screen, event, dropOff: true, timeSlot});
        return dispatch({
            types: [
                DROP_OFF_SCREEN_EVENT_USERS_PENDING, DROP_OFF_SCREEN_EVENT_USERS, DROP_OFF_SCREEN_EVENT_USERS_FAILED,
            ],
            payload: {
                promise: searchUserProfiles(getState().auth, appId, filters, searchFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

export const getScreenLastEventUsers = (appId, screen, event) => {
    return (dispatch, getState) => {
        const { timeSlot } = getState().dropOff;
        const { filters, searchFilters } = makeFilters(getState(), screen, {screenId: screen, event, isLast: true, dropOff: true, timeSlot});
        return dispatch({
            types: [
                DROP_OFF_SCREEN_LAST_EVENT_USERS_PENDING, DROP_OFF_SCREEN_LAST_EVENT_USERS, DROP_OFF_SCREEN_LAST_EVENT_USERS_FAILED
            ],
            payload: {
                promise: searchUserProfiles(getState().auth, appId, filters, searchFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

export const getEventUsers = (appId, event) => {
    return (dispatch, getState) => {
        const { timeSlot } = getState().dropOff;
        const { filters, searchFilters } = makeFilters(getState(), null, {event, dropOff: true, timeSlot});
        return dispatch({
            types: [
                DROP_OFF_EVENT_USERS_PENDING, DROP_OFF_EVENT_USERS, DROP_OFF_EVENT_USERS_FAILED
            ],
            payload: {
                promise: searchUserProfiles(getState().auth, appId, filters, searchFilters)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                //
            }
        });
    };
};

export const updateCurrentActive = (appId, current) => {
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_DROP_OFF_CURRENT_ACTIVE,
            payload: current,
            meta: {
                //If Any
            }
        });
        if(current === SCREEN){
            dispatch(getDropOffsByScreens(appId));
            dispatch(updateScreen(null));
        }else{
            dispatch(getDropOffsByEvents(appId));
        }
    };
};

export const updateGroup = (group) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_DROP_OFF_GROUP,
            payload: group,
            meta: {
                //If Any
            }
        });
    };
};

export const updateSegment = (segmentId) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_DROP_OFF_SEGMENT,
            payload: segmentId,
            meta: {
                //If Any
            }
        });
    };
};

export const updateTimeSlot = (timeSlot) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_DROP_TIME_SLOT,
            payload: timeSlot,
            meta: {
                //If Any
            }
        });
    };
};

export const updateFilters = (filters) => {
    return (dispatch, getState) => {
        return dispatch({
            type: UPDATE_DROP_OFF_FILTERS,
            payload: filters,
            meta: {
                //If Any
            }
        });
    };
};

export const updateScreen = (appId, screen) => {
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_DROP_OFF_SCREEN,
            payload: screen,
            meta: {
                //If Any
            }
        });
        const { timeSlot } = getState().dropOff;
        if(screen){ //if screen is not null
            if(!timeSlot){ //FIXME: prevent redundant call on empty timeSlot
                dispatch(getDropOffScreenTimeSpent(appId, screen));
            }
            dispatch(getDropOffScreenEvents(appId, screen, timeSlot));
            dispatch(getDropOffScreenLastEvents(appId, screen, timeSlot));
        }
    };
};

export const updateEvent = (appId, event) => {
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_DROP_OFF_EVENT,
            payload: event,
            meta: {
                //If Any
            }
        });
        //FIXME: need better implementation here
        dispatch(getEventAttributes(appId, event));
    };
};

export const updateScreenEvent = (appId, screen, event) => {
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_DROP_OFF_SCREEN_EVENT,
            payload: event,
            meta: {
                //If Any
            }
        });
        dispatch(getScreenEventAttributes(appId, screen, event));
    };
};

export const updateScreenLastEvent = (appId, screen, event) => {
    return (dispatch, getState) => {
        dispatch({
            type: UPDATE_DROP_OFF_SCREEN_LAST_EVENT,
            payload: event,
            meta: {
                //If Any
            }
        });
        dispatch(getScreenLastEventAttributes(appId, screen, event));
    };
};

export const loadDropOffData = (appId) => {
    return (dispatch, getState) => {
        //Counts
        dispatch(getDropOffSessionsCount(appId));
        dispatch(getDropOffScreensCount(appId));
        dispatch(getDropOffEventsCount(appId));

        //by screen
        dispatch(getDropOffsByScreens(appId));
        //for selected screen
        const {
            current, screen,
            current_event, current_screen_event, current_screen_last_event,
            event_attribute_distribution, screen_event_attribute_distribution, screen_last_event_attribute_distribution
        } = getState().dropOff;
        if(current === SCREEN && screen) {
            dispatch(getDropOffScreenTimeSpent(appId, screen));
            dispatch(getDropOffScreenEvents(appId, screen));
            dispatch(getDropOffScreenLastEvents(appId, screen));
            dispatch(getScreenUsers(appId, screen));
            if(current_screen_event){
                dispatch(updateScreenEvent(appId, screen, current_screen_event));
                Object.keys(screen_event_attribute_distribution).forEach(attribute => {
                    dispatch(getScreenEventAttributeDistribution(appId, screen, current_screen_event, attribute));
                });
                dispatch(getScreenEventUsers(appId, screen, current_screen_event));
            }
            if(current_screen_last_event){
                dispatch(updateScreenLastEvent(appId, screen, current_screen_last_event));
                Object.keys(screen_last_event_attribute_distribution).forEach(attribute => {
                    dispatch(getScreenLastEventAttributeDistribution(appId, screen, current_screen_last_event, attribute));
                });
                dispatch(getScreenEventUsers(appId, screen, current_screen_last_event));
            }
        }else{
            //by event
            dispatch(getDropOffsByEvents(appId));
            if(current_event){
                dispatch(updateEvent(appId, current_event));
                Object.keys(event_attribute_distribution).forEach(attribute => {
                    dispatch(getEventAttributeDistribution(appId, current_event, attribute));
                });
                dispatch(getEventUsers(appId, current_event));
            }
        }
    };
};