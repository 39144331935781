import {Grid} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import React, {Component} from 'react';
import {Bar, BarChart, CartesianGrid, Line, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {resetMessageAnalysis} from "./actions"
import Box from "../../../../../../../../components/reusable/Box";
import LabelList from "recharts/lib/component/LabelList";
import {randomColorWithIndex, roundOffNumber} from "../../../../../../../../utils";
import MultiUtilityChart from "../../../../../../../../components/reusable/Recharts/MultiUtilityChart";
import connect from "react-redux/lib/components/connect";

function mapDispatchToProps(dispatch) {
    return {
        resetMessageAnalysis: () => dispatch(resetMessageAnalysis()),
        boundDispatch: dispatch
    };
}

const renderCustomizedLabel = (props) => {
    const {index, x, y, value} = props;
    const xR = 40, yR = 20;
    if (index) {
        return (
            <g>
                <ellipse cx={x - xR} cy={y - yR} rx={xR} ry={yR} fill={randomColorWithIndex(7)}/>
                <text x={x - xR} y={y - yR} fill="#000000" textAnchor="middle" dominantBaseline="middle">
                    {roundOffNumber(value, 1)} % &#10095;
                </text>
            </g>
        );
    }
};

const StripedBar = (props) => {
    const {
        x: oX,
        y: oY,
        width: oWidth,
        height: oHeight,
        value,
        fill
    } = props;

    let x = oX;
    let y = oHeight < 0 ? oY + oHeight : oY;
    let width = oWidth;
    let height = Math.abs(oHeight);

    return (
        <rect fill={fill}
              mask='url(#mask-stripe)'
              x={x}
              y={y}
              width={width}
              height={height}/>
    );
};

export class AnalysisContent extends Component {

    constructor(props) {
        super(props);
    }

    componentWillUnmount() {
        this.props.resetMessageAnalysis();
    }

    render() {
        let {stepsMetrics = [], stepsMetricsTimeSeries, messageFunnelAnalysisMetrics, messageFunnelAnalysisTimeSeries = [], goalEvent, windowTime} = this.props;
        stepsMetrics.sort((x, y) => x.key.localeCompare(y.key));
        let stepsMetricsTimeSeriesLocal = JSON.parse(JSON.stringify(stepsMetricsTimeSeries));
        for (let i = 0; i < stepsMetricsTimeSeriesLocal.length; i++) {
            const date1 = stepsMetricsTimeSeriesLocal[i].key;
            for (let j = i + 1; j < stepsMetricsTimeSeriesLocal.length; j++) {
                const date2 = stepsMetricsTimeSeriesLocal[j].key;
                if (date1 === date2) {
                    delete stepsMetricsTimeSeriesLocal[j].key;
                    stepsMetricsTimeSeriesLocal[i] = {...stepsMetricsTimeSeriesLocal[i], ...stepsMetricsTimeSeriesLocal[j]};
                    stepsMetricsTimeSeriesLocal.splice(j, 1);
                }
            }
        }

        stepsMetricsTimeSeriesLocal.sort((x, y) => x.key > y.key ? 1 : (x.key < y.key ? -1 : 0));
        let stepsList = new Set();
        const stepsMetricsTimeSeriesFinal = stepsMetricsTimeSeriesLocal.map((item) => {
                for (let x in item) {
                    if (x === "key") continue;
                    const stepId = "Step-" + x.split("-").slice(-1)[0];
                    item[stepId] = item[x];
                    stepsList.add(stepId);
                    delete item[x];
                }
                return item;
            }
        );

        stepsList = [...stepsList];
        stepsList.sort();
        let lines = [];
        const noOfSteps = this.props.message.uis.length;
        const strokes = ["#2196f3", "#e0bd88", "#62a2d6", "#55aca4"];
        for (let i = 0; i < noOfSteps; i++) {
            lines.push(<Line key={"metrics_line_" + i} type="monotone" dataKey={stepsList[i]} strokeWidth={2}
                             stroke={strokes[i % noOfSteps]}/>)
        }

        const funnelData = messageFunnelAnalysisMetrics.funnels;

        const conversionRate = messageFunnelAnalysisMetrics.conversion_rate;

        let messageFunnelAnalysisTimeSeriesLocal = JSON.parse(JSON.stringify(messageFunnelAnalysisTimeSeries));
        messageFunnelAnalysisTimeSeriesLocal = messageFunnelAnalysisTimeSeriesLocal.map((item) => {
            return {
                "key": item["date"],
                "percentage": item["value"],
            }
        });

        return (
            <div>
                <Grid container spacing={16}>
                    <Box
                        title="Goal Event Analysis"
                        controls={
                            <div>
                                <Typography variant="subheading">
                                    Overall Conversion Rate
                                </Typography>
                                <Typography variant="h6">
                                    {roundOffNumber(conversionRate)} %
                                </Typography>
                            </div>
                        }
                    >
                        <ResponsiveContainer height={300}>
                            <BarChart data={funnelData || []}
                                      margin={{top: 40, right: 10, left: 30, bottom: 5}}
                                      color="#519674">
                                <pattern id="pattern-stripe"
                                         width="8" height="8"
                                         patternUnits="userSpaceOnUse"
                                         patternTransform="rotate(45)">
                                    <rect width="4" height="8" transform="translate(0,0)" fill="white"/>
                                </pattern>
                                <mask id="mask-stripe">
                                    <rect x="0" y="0" width="100%" height="100%" opacity="0.5"
                                          fill="url(#pattern-stripe)"/>
                                </mask>
                                <Tooltip/>
                                <XAxis dataKey="name"/>
                                <YAxis width={90} label={{value: "Users", position: "insideLeft", angle: -90, dy: 40}}/>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <Bar type='monotone' dataKey="count" stackId="a" fill={randomColorWithIndex(0)}>
                                    <LabelList content={renderCustomizedLabel} dataKey="percent"
                                               formatter={roundOffNumber}/>
                                </Bar>
                                <Bar type='monotone' dataKey="dropped" stackId="a"
                                     shape={<StripedBar fill={randomColorWithIndex(0)}/>}/>
                            </BarChart>
                        </ResponsiveContainer>
                    </Box>
                </Grid>
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Card style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 12,
                            padding: 12,
                            paddingBottom: 24
                        }}>
                            <Grid container justify="space-between">
                                <Grid item xs={8}>
                                    <Typography gutterBottom variant="h5">
                                        Conversion Rate Trend
                                    </Typography>
                                </Grid>
                            </Grid>
                            <CardContent>
                                <ResponsiveContainer width={"100%"} height={300}>
                                    <MultiUtilityChart
                                        data={messageFunnelAnalysisTimeSeriesLocal}
                                        dataKey={"key"}
                                        lineDataKeys={["percentage"]}
                                        withHeader
                                        withoutAggregations
                                        yLabel={"Conversion Rate"}
                                    />
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Card style={{display: "flex", flexDirection: "column", marginBottom: 12, padding: 12}}>
                            <Grid container justify="space-between">
                                <Grid item xs={8}>
                                    <Typography gutterBottom variant="h5">
                                        Campaign Performance Stats
                                    </Typography>
                                </Grid>
                            </Grid>
                            <CardContent>
                                <ResponsiveContainer width={"100%"} height={300}>
                                    <BarChart
                                        data={stepsMetrics.map((item, index) => {
                                            return {
                                                key: "Step " + (index + 1) + " (" + item.stepType + ")",
                                                users: item.users
                                            }
                                        })}
                                        margin={{right: 60, left: 10}}>
                                        <XAxis dataKey="key"/>
                                        <YAxis/>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <Tooltip/>
                                        <Bar dataKey="users" fill="#2196f3"/>
                                    </BarChart>
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={16}>
                    <Grid item xs={12}>
                        <Card style={{
                            display: "flex",
                            flexDirection: "column",
                            marginBottom: 12,
                            padding: 12,
                            paddingBottom: 24
                        }}>
                            <Grid container justify="space-between">
                                <Grid item xs={8}>
                                    <Typography gutterBottom variant="h5">
                                        Campaign Performance Trend
                                    </Typography>
                                </Grid>
                            </Grid>
                            <CardContent>
                                <ResponsiveContainer width={"100%"} height={300}>
                                    <MultiUtilityChart
                                        data={stepsMetricsTimeSeriesFinal}
                                        dataKey={"key"}
                                        lineDataKeys={stepsList}
                                        withHeader
                                        withoutAggregations
                                        yLabel={"Conversion Rate"}
                                    />
                                </ResponsiveContainer>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default connect(null, mapDispatchToProps)(AnalysisContent);