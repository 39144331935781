/**
 * Created by jyothi on 14/1/17.
 */

export const MESSAGE_ENUM = {
    IN_APP: 'IN_APP',
    IN_LINE: 'IN_LINE',
    WALKTHROUGH: 'WALKTHROUGH'
};

export const SOURCE_PATHS_PENDING = "SOURCE_PATHS_PENDING";
export const SOURCE_PATHS = "SOURCE_PATHS";
export const SOURCE_PATHS_FAILED = "SOURCE_PATHS_FAILED";
export const SOURCE_ISSUES_PENDING = "SOURCE_ISSUES_PENDING";
export const SOURCE_ISSUES = "SOURCE_ISSUES";
export const SOURCE_ISSUES_FAILED = "SOURCE_ISSUES_FAILED";

export const UPDATE_TARGET_SELECTION = "UPDATE_TARGET_SELECTION";

export const UPDATE_CONFIGURATION_SELECTION = "UPDATE_CONFIGURATION_SELECTION";

export const UPDATE_MESSAGE_SELECTION = "UPDATE_MESSAGE_SELECTION";

export const RESET_MESSAGES = "RESET_MESSAGES";

export const MESSAGES_PENDING = "MESSAGES_PENDING";
export const MESSAGES = "MESSAGES";
export const MESSAGES_FAILED = "MESSAGES_FAILED";

export const ERROR_CONDITIONS = {
    APP_EVENTS: {
        AppLaunch: "AppLaunch",
        ActivityStart: "ActivityStart",
    },
    NETWORK: {
        PoorNetwork: "PoorNetwork",
        NoNetwork: "NoNetwork",
        NetworkFailure: "NetworkFailure",
        poor_network_observer: "poor_network_observer",
        network_unavailable: "network_unavailable"
    },
    USER_INTERACTIONS: {
        NotInteractableError: "NotInteractableError",
        UndoObserver: "UndoObserver",
        ApplicationIdleIssue: "ApplicationIdleIssue",
        SlowResponsivenessError: "SlowResponsivenessError",
        OnKeyBack: "OnKeyBack",
        BackProgrammatic: "BackProgrammatic",
        swipe_observer: "swipe_observer"
    }
};

export const LOGICAL_OPERATORS = {
    GT: "GT",
    GTE: "GTE",
    LT: "LT",
    LTE: "LTE",
    EQ: "EQ",
    NEQ: "NEQ"
};

export const MESSAGE_POSITION_ENUM = {
    TOP: "TOP",
    CENTER: "CENTER",
    BOTTOM: "BOTTOM",
    LEFT: "LEFT",
    RIGHT: "RIGHT"
};

export const UIS_PENDING = "UIS_PENDING";
export const UIS_SUCCESS = "UIS_SUCCESS";
export const UIS_FAILED = "UIS_FAILED";

export const SEND_UI_PENDING = "SEND_UI_PENDING";
export const SEND_UI_SUCCESS = "SEND_UI_SUCCESS";
export const SEND_UI_FAILED = "SEND_UI_FAILED";

export const UPDATE_UI_PENDING = "UPDATE_UI_PENDING";
export const UPDATE_UI_SUCCESS = "UPDATE_UI_SUCCESS";
export const UPDATE_UI_FAILED = "UPDATE_UI_FAILED";

export const DELETE_MESSAGE_PENDING = "DELETE_MESSAGE_PENDING";
export const DELETE_MESSAGE_SUCCESS = "DELETE_MESSAGE_SUCCESS";
export const DELETE_MESSAGE_FAILED = "DELETE_MESSAGE_FAILED";

export const DELETE_UI_PENDING = "DELETE_UI_PENDING";
export const DELETE_UI_SUCCESS = "DELETE_UI_SUCCESS";
export const DELETE_UI_FAILED = "DELETE_UI_FAILED";

export const SEND_MESSAGE_PENDING = "SEND_MESSAGE_PENDING";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";

export const UPDATE_MESSAGE_PENDING = "UPDATE_MESSAGE_PENDING";
export const UPDATE_MESSAGE_SUCCESS = "UPDATE_MESSAGE_SUCCESS";
export const UPDATE_MESSAGE_FAILED = "UPDATE_MESSAGE_FAILED";


export const MESSAGE_DIRECTION_ENUM = {
    UP: "UP",
    LEFT: "LEFT",
    DOWN: "DOWN",
    RIGHT: "RIGHT"
};

export const MESSAGE_FONT_ENUM = {
    DEFAULT: "default",
    SANS_SERIF: "sans_serif",
    SERIF: "serif",
    MONOSPACE: "monospace",
};

export const UPDATE_FIRST_MESSAGE = "UPDATE_FIRST_MESSAGE";
export const UPDATE_NEXT_MESSAGE = "UPDATE_NEXT_MESSAGE";
export const UPDATE_DESIRED_EVENT = "UPDATE_DESIRED_EVENT";

export const PUBLISH_MESSAGE_PENDING = "PUBLISH_MESSAGE_PENDING";
export const PUBLISH_MESSAGE = "PUBLISH_MESSAGE";
export const PUBLISH_MESSAGE_FAILED = "PUBLISH_MESSAGE_FAILED";