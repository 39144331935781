/**
 * Created by Ravi Teja
 * Date: 17-Jan-2020
 * Time: 12:26 PM
 */

import {CONDITIONS_ENUM, LOGICAL_OPERATORS, USER_GROUP_ENUM} from "../../../../../../../../constants";
import {
    MESSAGE_ANALYSIS_FAILED,
    MESSAGE_ANALYSIS_PENDING,
    MESSAGE_ANALYSIS_STEP_METRICS,
    MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES,
    MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_FAILED,
    MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_PENDING,
    MESSAGE_FUNNEL_ANALYSIS,
    MESSAGE_FUNNEL_ANALYSIS_FAILED,
    MESSAGE_FUNNEL_ANALYSIS_PENDING,
    MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES,
    MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_FAILED,
    MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_PENDING,
    RESET_MESSAGE_ANALYSIS
} from "./actionTypes";
import {
    getMessageFunnelAnalysisAPI,
    getMessageFunnelAnalysisTimeSeriesAPI,
    getStepsMetricsAPI,
    getStepsTimeSeriesMetricsAPI
} from "./api"

export const STEP_ENUM = {
    inline_shown: "IN_LINE",
    inapp_shown: "IN_APP"
};

export const getStepsMetrics = (appId, validity, stepsMap, stepType, of) => {
    return (dispatch, getState) => {
        const group = USER_GROUP_ENUM.ALL_USERS;
        const DEFAULT_countConfig = {
            "count": {
                "value": 1,
                "operator": LOGICAL_OPERATORS.GTE
            }
        };
        let postBody = {
            user: [],
            session: [],
            event: [{
                name: stepType,
                ...DEFAULT_countConfig,
                attributes: [{
                    name: "id",
                    value: stepsMap[STEP_ENUM[stepType]] || [],
                    operator: LOGICAL_OPERATORS.EQ
                }]
            }],
            condition: CONDITIONS_ENUM.AND
        };

        return dispatch({
            types: [
                MESSAGE_ANALYSIS_PENDING,
                MESSAGE_ANALYSIS_STEP_METRICS,
                MESSAGE_ANALYSIS_FAILED
            ],
            payload: {
                promise: getStepsMetricsAPI(getState().auth, appId, validity, stepType, group, of, "id", postBody)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                type: STEP_ENUM[stepType]
            }
        });
    };
};

export const getStepsTimeSeriesMetrics = (appId, validity, stepsMap, stepType, of) => {
    return (dispatch, getState) => {
        const group = USER_GROUP_ENUM.ALL_USERS;
        const DEFAULT_countConfig = {
            "count": {
                "value": 1,
                "operator": LOGICAL_OPERATORS.GTE
            }
        };
        let postBody = {
            user: [],
            session: [],
            event: [{
                name: stepType,
                ...DEFAULT_countConfig,
                attributes: [{
                    name: "id",
                    value: stepsMap[STEP_ENUM[stepType]] || [],
                    operator: LOGICAL_OPERATORS.EQ
                }]
            }],
            condition: CONDITIONS_ENUM.AND
        };


        return dispatch({
            types: [
                MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_PENDING,
                MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES,
                MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_FAILED
            ],
            payload: {
                promise: getStepsTimeSeriesMetricsAPI(getState().auth, appId, validity, stepType, group, of, "id", postBody)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {
                type: STEP_ENUM[stepType]
            }
        });
    };
};


export const getMessageFunnelAnalysisMetrics = (appId, messageId, goalEvent, time) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                MESSAGE_FUNNEL_ANALYSIS_PENDING,
                MESSAGE_FUNNEL_ANALYSIS,
                MESSAGE_FUNNEL_ANALYSIS_FAILED
            ],
            payload: {
                promise: getMessageFunnelAnalysisAPI(getState().auth, appId, messageId, goalEvent, time)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {}
        })
    }
};

export const getMessageFunnelAnalysisTimeSeries = (appId, messageId, goalEvent, time) => {
    return (dispatch, getState) => {
        return dispatch({
            types: [
                MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_PENDING,
                MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES,
                MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_FAILED

            ],
            payload: {
                promise: getMessageFunnelAnalysisTimeSeriesAPI(getState().auth, appId, messageId, goalEvent, time)
                    .then((res) => {
                        return res;
                    }),
            },
            meta: {}
        })
    }
};

export const resetMessageAnalysis = () => {
    return (dispatch, getState) => {
        return dispatch({
            type: RESET_MESSAGE_ANALYSIS,
            payload: null,
            meta: {
                //If Any
            }
        });
    };
};