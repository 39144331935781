/**
 * Created by jyothi on 12/10/17.
 */
import React, { Component, } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import {
    loadEventAnalysisData, getTopEvents,
    getEventsData, getEventData, updateSegmentEvents,
    getEventAttributeDistributions, updateEventAttributeDistributionOf,
    resetSegment, getSegmentAttributeDistribution, getEventRetention,
    getEventRepeatedRetention, updateAttributeDistributionOf, updateEventAnalysisUserGroup,
    updateEventAnalysisGlobalFilters, updateEventAnalysisAttributeFilters, updateGroupDays, reloadEventAnalysis, getTimespentTimeSeries
} from './actions';
import {updateHeading, resetHeading } from '../../Navigation/actions';
import {UserCounts, Behavior, TopEvents, TimeSpentTrends} from './components';
import { areArraysStrictEqual } from '../../../../../../utils';
import {  getRetentionData } from '../Retention/actions';
import { updateSessionGroupId, updateUserGroupId, setDefaultFilters } from '../../Filters/actions';
import AttributeDistributions from '../../../../../../components/reusable/AttributeDistributions';
import PieChartIcon from '@material-ui/icons/PieChart';
import Snackbar from "../../../../../../components/reusable/MaterialUi/Snackbar";
import {CUSTOM_EVENT_ENUM} from "./actionTypes";
import Typography from "@material-ui/core/Typography";
import Switch from "../../../../../../components/reusable/Switch";
import InfoRounded from '@material-ui/icons/InfoRounded';
import Tooltip from "@material-ui/core/Tooltip";
import InfoHelper from "../../../../../../components/reusable/InfoHelper";

function mapStateToProps(state) {
    return {
        session: state.auth,
        router: state.routing,
        appState: state.app,
        overview: state.overview,
        customOverview: state.eventAnalysis,
        queryBuilder: state.queryBuilder,
        meta: state.meta,
        filters: state.filters,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        loadData: (userGroupIds, appId, groupId, isThroughFilters = false) => {
            dispatch(setDefaultFilters());
            dispatch(reloadEventAnalysis());
            dispatch(loadEventAnalysisData(userGroupIds, appId, groupId, isThroughFilters));
            dispatch(getEventsData(appId));
        },
        updateUserSelection: (appId, event, groupId) => {
            dispatch(reloadEventAnalysis());
            dispatch(updateUserGroupId(groupId));
            dispatch(loadEventAnalysisData(null, appId, groupId));
            dispatch(updateUserGroupId(groupId));
            dispatch(getEventsData(appId, event, groupId));
        },
        updateSessionSelection: (groupId) => {
            dispatch(updateSessionGroupId(groupId));
        },
        updateFilteredDate: (appId, filteredDate) => {
            dispatch(getRetentionData(appId, filteredDate))
        },
        updateSegmentGlobalFilters: (globalFilters) => {
            dispatch(updateEventAnalysisGlobalFilters(globalFilters));
        },
        updateSegmentEventFilters: (event, eventFilters) => {
            dispatch(updateEventAnalysisAttributeFilters(event, eventFilters));
        },
        handleEventChange: (appId, events) => {
            dispatch(updateSegmentEvents(appId, events));
            //dispatch(getEventData(appId, events));
        },
        loadEventData: (appId, event, withFilters) => dispatch(getEventData(appId, event, withFilters)),
        getEventsData: (appId) => dispatch(getEventsData(appId)),
        getEventAttributeDistributions: (appId, event, attributes, allFlag = false) => dispatch(getEventAttributeDistributions(appId, event, attributes, allFlag)),
        resetHeading: () => dispatch(resetHeading()),
        updateHeading: (heading) => dispatch(updateHeading(heading)),
        resetData: () => dispatch(resetSegment()),
        updateEventAttributeDistributionOf: (event, of) => dispatch(updateEventAttributeDistributionOf(event, of)),
        getSegmentAttributeDistribution: (appId, attribute, of) => dispatch(getSegmentAttributeDistribution(appId, attribute, of)),
        getEventRetention: (appId, event) => dispatch(getEventRetention(appId, event)),
        getEventRepeatedRetention: (appId, event) => dispatch(getEventRepeatedRetention(appId, event)),
        updateAttributeDistributionOf: (appId, of) => dispatch(updateAttributeDistributionOf(appId, of)),
        updateUserGroup: (appId, group) => dispatch(updateEventAnalysisUserGroup(appId, group)),
        updateGroupDays: (days) => dispatch(updateGroupDays(days)),
        getTopEvents: (appId) => dispatch(getTopEvents(appId)),
        bindedDispatch: dispatch //FIXME:
    };
}

const SWITCH_DATA = [{name: "Sessions", value: CUSTOM_EVENT_ENUM.sessions}, {name: "Users", value: CUSTOM_EVENT_ENUM.users}];

class Segment extends Component {

    constructor(props) {

        super(props);
        this.appId = this.props.params.appId;
        this.state = {
            retentionTrend: []
        }

    }

    componentWillMount(){
        this.props.loadData(this.props.appState.userGroupIds, this.props.params.appId); //Assuming meta already be loaded
        this.props.updateHeading("Event Analysis");
    }

    componentWillUnmount(){
        this.props.resetData();
    }

    handleUserSelection = (groupId) => {
        this.props.updateUserSelection(this.props.params.appId, this.props.customOverview.current_event.name, groupId);
    };

    handleSessionSelection = (groupId) => {
        this.props.updateSessionSelection(groupId);
    };

    handleSwitchValueChange = (switchValue) => {
        this.setState({switchValue: switchValue});
        this.props.updateFilteredDate(this.props.params.appId, switchValue.split(" ")[0]);
    };

    handleEventFilters = (event) => (filters) => {
        const filtersObject = {...filters};
        let keyValuePairs = "";
        for(let key in filtersObject){
            if(filtersObject.hasOwnProperty(key) && filtersObject[key].length === 0){
                delete filtersObject[key];
            }else{
                keyValuePairs += ", " + key + " : [ " + filtersObject[key].join(",") + " ]";
            }
        }
        this.props.updateSegmentEventFilters(event, filtersObject);
        this.props.loadEventData(this.props.params.appId, event, true);
        ReactGA.event({
            category: 'User Group Filters',
            action: 'Property Filters',
            label: "{ " + keyValuePairs.trim().slice(2) + " }",
            value: Object.keys(filtersObject).length
        });
    };

    handleGlobalAttributeFilters = (filters) => {
        const filtersObject = {...filters};
        let keyValuePairs = "";
        for(let key in filtersObject){
            if(filtersObject.hasOwnProperty(key) && filtersObject[key].length === 0){
                delete filtersObject[key];
            }else{
                keyValuePairs += ", " + key + " : [ " + filtersObject[key].join(",") + " ]";
            }
        }
        this.props.updateSegmentGlobalFilters(filtersObject);
        this.props.loadData(this.props.appState.userGroupIds, this.props.params.appId, null, true);
        ReactGA.event({
            category: 'User Group Filters',
            action: 'Property Filters',
            label: "{ " + keyValuePairs.trim().slice(2) + " }",
            value: Object.keys(filtersObject).length
        });
    };

    updateEventAttributeDistributions = (event, attributes) => {
        this.props.getEventAttributeDistributions(this.props.params.appId, event, attributes);
    };

    handleEventDistributionOfChange = (event) => (of) => {
        this.props.updateEventAttributeDistributionOf(event, of);
        this.props.getEventAttributeDistributions(this.props.params.appId, event, null, true);
    };

    handleRetentionTrend = (dataStore, currentType, valueType) => {
        let data = [];
        if(typeof dataStore.getHeader === 'function'){
            try {
                const headers = dataStore.getHeader(currentType).slice(1); //removing first item
                data = headers.map(item => item.percent);
            }catch(e){
                data = []
            }
        }
        if(!areArraysStrictEqual(data, this.state.retentionTrend)) {
            this.setState({retentionTrend: data});
        }
    };

    render() {
        const {
            appState: {appEvents = [], app: {features}}, params,
            getSegmentAttributeDistribution,
            updateAttributeDistributionOf
        } = this.props;
        const { appId } = params;
        const {
            distributions = {},
            all_sessions,
            all_users,
            top_events,
            user_attributes = [],
            session_attributes = [],
            of
        } = this.props.customOverview;
        const stats = {users: all_users, sessions: all_sessions};
        const haveEvents = appEvents.length !== 0;
        return (
            <section className="content">
                {
                    !this.props.meta.api_pending && !haveEvents && <Snackbar>No App Events Logged. Log some events to analyse..!</Snackbar>
                }
                {/*<Filters {...this.props}/>*/}
                <UserCounts {...this.props}/>
                <AttributeDistributions
                    title={
                        <div style={{display: 'flex'}}>
                            <Typography variant="subheading" style={{margin: '0 4px'}}>Distribution by property</Typography>
                            <InfoHelper>Showing top 100</InfoHelper>
                        </div>
                    }
                    icon={<PieChartIcon/>}
                    data={distributions}
                    stats={stats}
                    resetFilters={false}
                    updateFilters={this.handleGlobalAttributeFilters}
                    {...this.props}
                    userAndSessionAttributes
                    userAttributes={user_attributes}
                    sessionAttributes={session_attributes}
                    loadAttributeData={(attribute) => getSegmentAttributeDistribution(appId, attribute)}
                >
                    <div>
                        <Switch
                            data={SWITCH_DATA}
                            value={of}
                            handleChange={value => {

                                ReactGA.event({
                                    category: 'Global Property Distributions',
                                    action: 'Selected Users / Sessions Distributions grouped by Properties',
                                    label: value + " selected",
                                    value: value
                                });
                                updateAttributeDistributionOf(appId, value);
                            }}
                            containerStyles={{maxWidth: 200, marginLeft: 'auto'}}
                        />
                    </div>
                </AttributeDistributions>
                <TopEvents {...this.props} data={top_events}/>
                <Behavior
                    {...this.props}
                    updateEventAttributeDistributions={this.updateEventAttributeDistributions}
                    updateFilters={this.handleEventFilters}
                    stats={stats}
                    event={this.props.customOverview}
                    handleEventDistributionOfChange={this.handleEventDistributionOfChange}
                    overallRetention={this.state.retentionTrend}
                />
                {
                    !features.includes("APX_HIDE_360")
                    && <TimeSpentTrends
                        {...this.props}
                    />
                }
            </section>
        );
    }
}

Segment.propTypes = {

};

Segment.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Segment);