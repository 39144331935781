/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */


export const RESET_CONTEXTS = "RESET_CONTEXTS";

export const CONTEXTS_PENDING = "CONTEXTS_PENDING";
export const CONTEXTS = "CONTEXTS";
export const CONTEXTS_FAILED = "CONTEXTS_FAILED";

export const CONTEXT_PENDING = "CONTEXT_PENDING";
export const CONTEXT = "CONTEXT";
export const CONTEXT_FAILED = "CONTEXT_FAILED";

export const SAVE_CONTEXT_PENDING = "SAVE_CONTEXT_PENDING";
export const SAVE_CONTEXT = "SAVE_CONTEXT";
export const SAVE_CONTEXT_FAILED = "SAVE_CONTEXT_FAILED";

export const UPDATE_CONTEXT_PENDING = "UPDATE_CONTEXT_PENDING";
export const UPDATE_CONTEXT = "UPDATE_CONTEXT";
export const UPDATE_CONTEXT_FAILED = "UPDATE_CONTEXT_FAILED";

export const DELETE_CONTEXT_PENDING = "DELETE_CONTEXT_PENDING";
export const DELETE_CONTEXT = "DELETE_CONTEXT";
export const DELETE_CONTEXT_FAILED = "DELETE_CONTEXT_FAILED";

export const UPDATE_LOCAL_CONTEXT = "UPDATE_LOCAL_CONTEXT";