import {
    MESSAGE_ANALYSIS_FAILED,
    MESSAGE_ANALYSIS_PENDING,
    MESSAGE_ANALYSIS_STEP_METRICS,
    
    MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES,
    MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_FAILED,
    MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_PENDING,
    
    RESET_MESSAGE_ANALYSIS,
    
    MESSAGE_FUNNEL_ANALYSIS_PENDING,
    MESSAGE_FUNNEL_ANALYSIS,
    MESSAGE_FUNNEL_ANALYSIS_FAILED,

    MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_PENDING,
    MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES,
    MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_FAILED,
} from "./actionTypes";

const INITIAL_STEPS_METRICS_STATE = {
    stepsMetricsPending: true,
    stepsMetricsFailed: false,
};

const INITIAL_STEPS_METRICS_TIME_SERIES_STATE = {
    stepsMetricsTimeSeriesPending: true,
    stepsMetricsTimeSeriesFailed: false,
};

const INITIAL_MESSAGE_FUNNEL_ANALYSIS_METRICS = {
    messageFunnelAnalysisPending: true,
    messageFunnelAnalysisFailed:false
};

const INITIAL_MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES = {
    messageFunnelAnalysisTimeSeriesPending: true,
    messageFunnelAnalysisTimeSeriesFailed: false,
};

const INITIAL_STATE = {
    progress_state: {
        ...INITIAL_STEPS_METRICS_STATE,
        ...INITIAL_STEPS_METRICS_TIME_SERIES_STATE,
        ...INITIAL_MESSAGE_FUNNEL_ANALYSIS_METRICS,
        ...INITIAL_MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES
    },
    stepsMetrics: [],
    stepsMetricsTimeSeries: [],
    messageFunnelAnalysisMetrics: {},
    messageFunnelAnalysisTimeSeries: []
};


function messageAnalysisReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
        case MESSAGE_ANALYSIS_STEP_METRICS:
            return {
                ...state,
                stepsMetrics: [
                    ...state.stepsMetrics,
                    ...action.payload
                ],
                progress_state: {
                    ...state.progress_state,
                    stepsMetricsPending: false,
                    stepsMetricsFailed: false
                }
            };
        case MESSAGE_ANALYSIS_PENDING:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    stepsMetricsPending: true
                }
            };
        case MESSAGE_ANALYSIS_FAILED:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    stepsMetricsPending: false,
                    stepsMetricsFailed: true
                }
            };

        case MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES:
            return {
                ...state,
                stepsMetricsTimeSeries: [
                    ...state.stepsMetricsTimeSeries,
                    ...action.payload
                ],
                progress_state: {
                    ...state.progress_state,
                    stepsMetricsTimeSeriesPending: false,
                    stepsMetricsTimeSeriesFailed: false
                }
            };
        case MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_PENDING:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    stepsMetricsTimeSeriesPending: true
                }
            };
        case MESSAGE_ANALYSIS_STEP_METRICS_TIME_SERIES_FAILED:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    stepsMetricsTimeSeriesPending: false,
                    stepsMetricsTimeSeriesFailed: true
                }
            };

        case MESSAGE_FUNNEL_ANALYSIS:
            return {
                ...state,
                messageFunnelAnalysisMetrics: {
                    ...action.payload
                },
                progress_state: {
                    ...state.progress_state,
                    messageFunnelAnalysisPending: false,
                    messageFunnelAnalysisFailed: false
                }
            };
        case MESSAGE_FUNNEL_ANALYSIS_PENDING:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    messageFunnelAnalysisPending: true
                }
            };
        case MESSAGE_FUNNEL_ANALYSIS_FAILED:
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    messageFunnelAnalysisPending: false,
                    messageFunnelAnalysisFailed: true
                }
            };
            
        case MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES: {
            return {
                ...state,
                messageFunnelAnalysisTimeSeries: [
                    ...action.payload
                ],
                progress_state: {
                    ...state.progress_state,
                    messageFunnelAnalysisTimeSeriesPending: false,
                    messageFunnelAnalysisTimeSeriesFailed: false
                }
            };
        }
        case MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_PENDING: {
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    messageFunnelAnalysisTimeSeriesPending: true
                }
            };
        }
        case MESSAGE_FUNNEL_ANALYSIS_TIME_SERIES_FAILED: {
            return {
                ...state,
                progress_state: {
                    ...state.progress_state,
                    messageFunnelAnalysisTimeSeriesPending: false,
                    messageFunnelAnalysisTimeSeriesFailed: true
                }
            };
        }

        case RESET_MESSAGE_ANALYSIS:
            return INITIAL_STATE;
        default:
            return state;
    }
}

export default messageAnalysisReducer;