/**
 * Created by Araja Jyothi Babu on 27-Oct-16.
 */
import React from 'react';
import {Button, Grid, Typography} from "@material-ui/core";
import Box from "../../../../../../components/reusable/Box";
import TransferWithinAStation from '@material-ui/icons/TransferWithinAStation';
import Snackbar from "../../../../../../components/reusable/MaterialUi/Snackbar";
import Switch from "../../../../../../components/reusable/Switch";
import MultiSelect from "../../../../../../components/reusable/MaterialUi/MultiSelect";
import {Sunburst, LabelSeries } from 'react-vis';
import "react-vis/dist/style.css";
import {formatNumber, randomColorWithIndex, toTimeSpent} from '../../../../../../utils';
import TextField from '@material-ui/core/TextField';
import InputRange from "react-input-range";
import 'react-input-range/lib/css/index.css';
import Checkbox from "../../../../../../components/reusable/MaterialUi/Checkbox";
import {UserAndSessionProperties, HybridAttributeBuilderWrapper } from "../Segments/NewSegment/components";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import Dialog from "../../../../../../components/reusable/MaterialUi/Dialog";
import IconButton from "@material-ui/core/IconButton";
import FilterIcon from "@material-ui/icons/FilterList";
import Tooltip from "@material-ui/core/Tooltip";
import Loading from "../../../../../../components/reusable/Loading";
import {PATH_WIDGET} from "../SelfComponents";
import SegmentConfig from "../Segments/SegmentConfig";
import AddToDashboard from "../CustomDashboards/AddToDashboard";
import PathGraph from "../../../../../../components/reusable/Path";
import { PATH_ENUM }  from '../../../../../../constants';
import Apxor from 'apxor';

class FiltersHolder extends React.Component {

    state = {
        expand: false
    };

    render(){
        const { children, data = [], event } = this.props;
        const { expand } = this.state;
        //const showFilters = expand || data.length === 0 || data[0].name.length === 0;
        return(
            <Grid container style={{marginTop: 16}}>
                <Grid item xs={12}>
                    <Dialog
                        status={ expand }
                        handleConfirm={() => {
                            this.setState({expand: false});
                        }}
                        title={event + " Properties"}
                        handleClose={() => this.setState({expand: false})}
                        confirmLabel="Apply"
                        dialogProps={{
                            maxWidth: 'md',
                            fullWidth: true
                        }}
                    >
                        <div style={{minHeight: 240}}>
                            {children}
                        </div>
                    </Dialog>
                    { !expand && <div>
                        {data.map((d, i) =>
                            <div key={d.name + i}>
                                <Typography>{d.name}:</Typography>
                                {<Typography variant="caption">{d.value.join(", ")}</Typography>}
                            </div>
                        )}
                    </div>}
                    <Tooltip id={"tooltip-filters-for-" + event} title="Apply Event Filters" placement="top">
                        <IconButton color="secondary" aria-label="Filters"
                                    onClick={() => this.setState({expand: true})}>
                            <FilterIcon/>
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        )
    }

}

export class PathAnalysis extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            start: "",
            end: "",
            size: 5,
            limit: 10,
            days: [],
            currentPathType: PATH_ENUM.EVENT,
            hasDays: false,
            range: {min: 0, max: 0},
            query: {},
            startProperties: [],
            endProperties: [],
            submitted: false,
            uninstall: false
        };
    }

    componentWillReceiveProps(nextProps){
        const { meta } = nextProps;
        //reloading data for updated filters
        const { meta: { globalFiltersChanged }  } = this.props;
        if(meta.globalFiltersChanged && globalFiltersChanged !== meta.globalFiltersChanged){
            this.loadPath();
        }
    }

    formatFiltersQuery = () => {
        const {
            query : { user = [], session = [] } = {user: [], session: []},
            startProperties = [], endProperties = []
        } = this.state;
        const formatList = (list) => list.reduce((a, b) => {
            a[b.name] = b.value;
            return a;
        }, {});
        return {
            userProperties: (user || []),
            sessionProperties: (session || []),
            startProperties: (startProperties || []),
            endProperties: (endProperties || [])
        };
    };

    loadPath = () => {
        const { params: { appId }, getEventPath, getScreenPath } = this.props;
        const { start, end, size, limit, days, currentPathType, uninstall } = this.state;
        const startWith = start && start.length > 0 ? start : undefined;
        const endWith = end && end.length > 0 ? end : undefined;
        const getPath = currentPathType === PATH_ENUM.EVENT ? getEventPath : getScreenPath;
        getPath(appId, startWith, endWith, size, limit, days, uninstall, this.formatFiltersQuery());
    };

    addDashboardConfig = () => {
        const { params: { appId }, getEventPath, getScreenPath } = this.props;
        const { start, end, size, limit, days, currentPathType, uninstall } = this.state;
        const startWith = start && start.length > 0 ? start : undefined;
        const endWith = end && end.length > 0 ? end : undefined;
        return {
            pathType: currentPathType,
            queryParams: {
                start: startWith,
                end: endWith,
                size,
                limit,
                days,
                uninstall
            },
            extraFilters: {
                ...this.formatFiltersQuery()
            }
        }
    };

    render(){
        const {
            params: {appId},
            appState: { appEvents = [], appScreenNames = [] },
            path: { path, path_pending, path_failed },
            getEventAttributes, getEventAttributeValues,
            segmentBuilder: {
                event_attributes = {}, event_attribute_values = {}
            }
        } = this.props;
        const {
            submitted, uninstall,
            start, end, size, limit, currentPathType,
            hasDays, range, startProperties, endProperties,
            pathValue, query
        } = this.state;
        const options = (currentPathType === PATH_ENUM.EVENT ? appEvents : appScreenNames).map(o => ({label: o, value: o}));
        return(
            <Box title="User Behavior"
                 icon={<TransferWithinAStation/>}
                 withPadding
                 controls={
                     <div style={{display: 'flex', justifyContent: 'space-between', minWidth: 480}}>
                         <TextField
                             id="users"
                             label={"Minimum Users"}
                             placeholder={"10"}
                             value={limit}
                             onChange={e => {
                                 const number = Number(e.target.value);
                                 if(number > 0){
                                    Apxor.logEvent("MinimumUsersSelected", {count: number}, "Path");
                                    this.setState({limit: number, submitted: false});
                                 }
                             }}
                             type="number"
                             InputLabelProps={{
                                 shrink: true,
                             }}
                             style={{maxWidth: 120}}
                         />
                         <TextField
                             id="number"
                             label={currentPathType + "s"}
                             value={size}
                             onChange={e => {
                                 const number = Number(e.target.value);
                                 if(number > 0 && number <= 10){
                                     Apxor.logEvent("MinimumEventSelected", {count: number}, "Path");
                                     this.setState({size: number, submitted: false});
                                 }
                             }}
                             type="number"
                             InputLabelProps={{
                                 shrink: true,
                             }}
                             style={{maxWidth: 70}}
                         />
                         {/*<Switch*/}
                         {/*    containerStyles={{minWidth: 200, marginTop: 10}}*/}
                         {/*    value={currentPathType}*/}
                         {/*    handleChange={currentPathType => {*/}
                         {/*    this.setState({currentPathType, start: "", end: "", submitted: false});*/}
                         {/*}}*/}
                         {/*    data={Object.values(PATH_ENUM)}*/}
                         {/*/>*/}
                         {/*<AddToDashboard {...this.props} componentId={PATH_WIDGET} getComponentParams={this.addDashboardConfig}>*/}
                         {/*    {(query.user || query.session) && <SegmentConfig user={query.user} session={query.session} event={[]}/>}*/}
                         {/*    <Typography variant="subheading"><strong>{start}</strong>{` ...[${size}]... `}<strong>{end}</strong></Typography>*/}
                         {/*</AddToDashboard>*/}
                     </div>
                 }
            >
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="subtitle1">Filter By Property</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <UserAndSessionProperties {...this.props} withoutBox onQueryUpdate={query => {
                            this.setState({query, submitted: false});
                        }} />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <br />
                <Grid container spacing={16} justify="center">
                    <Grid item xs={12} md={3}>
                        <MultiSelect
                            options={options}
                            label={ "From " + currentPathType }
                            placeholder={ "From " + currentPathType }
                            value={start}
                            handleChange={start => {
                                Apxor.logEvent("FromEventSelected", {event: start}, "Path");
                                this.setState({start, submitted: false});
                                if(currentPathType === PATH_ENUM.EVENT && start && start.length > 0){
                                    getEventAttributes(appId, start);
                                }
                            }}
                            single
                        />
                        {
                            currentPathType === PATH_ENUM.EVENT && start && start.length > 0 && <FiltersHolder event={start} data={startProperties}>
                                <HybridAttributeBuilderWrapper
                                    {...this.props}
                                    withoutBox
                                    values={event_attribute_values[start]}
                                    data={event_attributes[start] || []}
                                    attributes={startProperties}
                                    handleUpdate={startProperties => {
                                        this.setState({startProperties, submitted: false});
                                    }}
                                    getRecommendations={(attribute, q) => getEventAttributeValues(appId, start, attribute, q)}
                                    forFunnels
                                    showAddButton
                                />
                            </FiltersHolder>
                        }
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={16}>
                            <Grid item xs={4} md={3}>
                                <Checkbox
                                    label="Day Filter"
                                    checked={hasDays}
                                    handleChange={hasDays => {
                                        const days = [...new Array(range.max - range.min + 1).keys()].map((o, i) => range.min + i);
                                        Apxor.logEvent("DayFilterPathsSelected", {checked: hasDays}, "Path");
                                        this.setState({hasDays, days: hasDays ? days: [], submitted: false});
                                    }}
                                />
                            </Grid>
                            <Grid item xs={8} md={6}>
                                { hasDays &&
                                    <div style={{padding: 16}}>
                                        <InputRange
                                            maxValue={90}
                                            minValue={0}
                                            value={range}
                                            onChange={range => this.setState({ range })}
                                            onChangeComplete={range => this.setState({ range }, () => {
                                                const days = [...new Array(range.max - range.min + 1).keys()].map((o, i) => range.min + i);
                                                this.setState({days, submitted: false});
                                            })}
                                        />
                                    </div>
                                }
                            </Grid>
                            {/*<Grid item xs={12} md={3}>*/}
                            {/*    <Checkbox*/}
                            {/*        label="Uninstall"*/}
                            {/*        checked={uninstall}*/}
                            {/*        handleChange={uninstall => {*/}
                            {/*            this.setState({uninstall, submitted: false});*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                        </Grid>
                        <Button
                            style={{margin: '16px auto', display: 'block'}}
                            variant="contained"
                            disabled={submitted || !((start && start.length > 0) || (end && end.length > 0))}
                            onClick={e => {
                                this.loadPath();
                                Apxor.logEvent("SubmitPathClicked", null, "Path");
                                this.setState({submitted: true});
                            }}
                            color="primary"
                        >
                            {path_pending ? <Loading /> : "Submit"}
                        </Button>
                        { path_failed && <Snackbar>Unable to fetch path.</Snackbar> }
                        { submitted && !path_pending && path.length === 0 && <Snackbar>No path data found. Try changing query.</Snackbar>}
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <MultiSelect
                            options={options}
                            label={ "To " + currentPathType }
                            placeholder={ "To " + currentPathType }
                            value={end}
                            handleChange={end => {
                                Apxor.logEvent("ToEventSelected", {event: end}, "Path");
                                this.setState({end, submitted: false});
                                if(currentPathType === PATH_ENUM.EVENT && end && end.length > 0){
                                    getEventAttributes(appId, end);
                                }
                            }}
                            single
                        />
                        {
                            currentPathType === PATH_ENUM.EVENT && end && end.length > 0 && <FiltersHolder event={end} data={endProperties}>
                                <HybridAttributeBuilderWrapper
                                    {...this.props}
                                    withoutBox
                                    values={event_attribute_values[end] || {}}
                                    data={event_attributes[end] || []}
                                    attributes={endProperties}
                                    handleUpdate={endProperties => {
                                        this.setState({endProperties, submitted: false});
                                    }}
                                    getRecommendations={(attribute, q) => getEventAttributeValues(appId, end, attribute, q)}
                                    forFunnels
                                    showAddButton
                                />
                            </FiltersHolder>
                        }
                    </Grid>
                </Grid>
                <PathGraph
                    start={start}
                    end={end}
                    size={size}
                    {...path}
                />
            </Box>
        )
    }
}