import React, {Component, } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {DASHBOARD_ENDPOINTS} from "../../../constants/EndPoints";
import { AppHeader }from './components';
import { MuiThemeProvider, withStyles, withTheme } from '@material-ui/core/styles';
import { getLocation } from './actions';
import { updateSidebar } from '../Application/RequireAuth/Navigation/actions';
import  LinearProgress from '@material-ui/core/LinearProgress';
import {changeInGlobalFilters} from "../../../actions";
import { apxTheme, globalStyles, darkTheme } from '../../../styles';

function mapStateToProps(state) {
    return {
        auth: state.auth,
        router: state.routing,
        app: state.app.app,
        navigation: state.navigation,
        meta: state.meta
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getLocation: () => dispatch(getLocation()),
        changeInGlobalFilters: (applied) => dispatch(changeInGlobalFilters(applied)),
        updateSidebar: (open = true) => dispatch(updateSidebar(open)) //always calls for open
    };
}

class App extends Component{

    componentWillMount(){
        this.props.getLocation(); //getting client location and IP
        this.context.router.push(this.props.location); //updating meta with current location for direct links to dashboard
    }

    componentWillReceiveProps(nextProps){
        /*ifOnDashboard(nextProps.location, () => {
            nextProps.updateSidebar(false);
        })*/
        const { meta: { globalFiltersChanged, api_pending }, changeInGlobalFilters } = nextProps;
        if( globalFiltersChanged && !api_pending){
            if(this.props.meta.api_pending !== api_pending){
                changeInGlobalFilters(false);
            }
        }
    }

    componentDidMount(){ //calls only once in dashboard life cycle

    }

    render() {
        const {
            children, auth, meta: { api_pending }
        } = this.props;

        const {
            classes, ...otherProps
        } = this.props;

        const token = auth.token;
        const user = auth.user;
        const accessExpiry = auth.accessExpiry;
        const isLoggedIn = token && token !== null && typeof token !== 'undefined' && accessExpiry!==null;
        const isPublicRoute = this.props.location.pathname.split('/')[1] === DASHBOARD_ENDPOINTS.REPORTS;
        return (
            <MuiThemeProvider theme={apxTheme}>
                <div className={classes.root}>
                    { api_pending && <LinearProgress color="primary" classes={{root: classes.progress}} /> }
                    <div className={classes.appFrame}>
                        {
                            isLoggedIn &&
                            <AppHeader
                                {...this.props}
                                classes={undefined}
                                user={user}
                                loggedIn={true}
                            />
                        }
                        {
                            isPublicRoute &&
                                <AppHeader
                                    {...otherProps}
                                    isPublicRoute
                                    user={null}
                                    loggedIn={false}
                                />
                        }
                        { children }
                    </div>
                </div>
            </MuiThemeProvider>
        )
    }
}

App.contextTypes = {
    router: PropTypes.object.isRequired,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(withTheme()(withStyles(globalStyles)(App)));
