/**
 * Created by jyothi on 4/12/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {formatNumber} from "../../../utils";

const styles = theme => ({
    count: {
        //paddingTop: apxTheme.spacing.unit * 8,
        borderRadius: '50%',
        backgroundColor: '#D1D1D1',
        width: theme.spacing.unit * 25,
        height: theme.spacing.unit * 25,
        margin: '0 auto',
        textAlign: 'center'
    },
    card: {
        marginBottom: 12,
        padding: theme.spacing.unit * 4,
        minHeight: 240,
        minWidth: 60
    },
    content: {
        paddingBottom: '16px !important',
        textAlign: 'center'
    },
    heading: {
        lineHeight: theme.spacing.unit * 25 + 'px'
    }
});

function CountBox(props) {
    const { classes, heading = "NA", subheading = "NA", type="display3" } = props;

    return (
        <Card className={classes.card}>
            <div className={classes.count}>
                <Typography variant={type} className={classes.heading}>{ isNaN(heading) ? heading : formatNumber(heading, 1) }</Typography>
            </div>
            <CardContent className={classes.content}>
                <Typography variant="body2" color="inherit">{ subheading }</Typography>
            </CardContent>
        </Card>
    );
}

CountBox.propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    heading: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
    ]),
    subheading: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.number
    ]),
    icon: PropTypes.node
};

export default withStyles(styles, { withTheme: true })(CountBox);