export const IMG_DIR = '/assets/img/';
export const BG_IMAGE = IMG_DIR + 'sample-inapp-bg.jpg';
export const USER_IMAGE = IMG_DIR + 'user.png';
export const SCHEME = "apxor://";
export const CLOSE_HREF = `${SCHEME}close?`;
export const CANCEL_HREF = `${SCHEME}cancel?`;

export const DEFAULT_FONT = "Arial, Helvetica, sans-serif";
export const DEFAULT_GRADIENT = "linear-gradient(to bottom, rgba(0,0,0,0) 40%, rgba(0,0,0, 0.75) 65%,rgba(0,0,0, 1) 100%)";

export const BUTTON_ACTION_ENUM = {
    REDIRECT: "REDIRECT",
    CLOSE: "CLOSE",
    CANCEL: "CANCEL"
};

export const { CLOSE } = BUTTON_ACTION_ENUM;

export const GREEN = "#75C221";
export const SMOKE = "#F1F1F1";
export const WHITE = "#FFFFFF";
export const DARK = "#454545";

export const BUTTONS = {
    Single: "Single",
    Double: "Double"
};

export const DEFAULT_BUTTON_CONFIG = {
    action: CLOSE,
    color: SMOKE,
    background: GREEN,
    text: "",
    href: CLOSE_HREF,
    styles: ""
};

export const BACKGROUND_SIZE = {
    COVER: "cover",
    STRETCH: "100% 100%",
    CONTAIN: "contain"
};

export const BACKGROUND_SIZE_ENUM = {
    COVER: "COVER",
    STRETCH: "STRETCH",
    CONTAIN: "CONTAIN"
};

export const { Single, Double } = BUTTONS;

export const TEMPLATES = {
    COVER: 'COVER',
    INTERSTITIAL: 'INTERSTITIAL',
    MODAL: 'MODAL',
    STICKY_TOP: 'STICKY_TOP',
    STICKY_BOTTOM: 'STICKY_BOTTOM'
};

export const { COVER, INTERSTITIAL, STICKY_BOTTOM, STICKY_TOP } = TEMPLATES;

export const DEEP_LINK_ENUM = {
    URI: "URI",
    ACTIVITY: "ACTIVITY"
};

export const { URI, ACTIVITY } = DEEP_LINK_ENUM;