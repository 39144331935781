/**
 * Created by Araja Jyothi Babu on 26-Oct-16.
 */
import React from 'react';
import {ResponsiveContainer, PieChart, Pie, Sector, Cell} from 'recharts';
import Placeholder from '../Placeholder';
import Snackbar from '../../../components/reusable/MaterialUi/Snackbar';
import { OTHERS_KEY, UNKNOWN_KEY } from '../../../constants';
import {formatNumber, hexToRGBA, randomColorWithIndex} from '../../../utils';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import  MenuItem  from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';

const renderActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
    return (
        <g>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <text x={cx} y={cy} dy={-90} textAnchor="middle" fill={fill}>{payload.key}<title>{payload.key}</title></text>
            <text x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>{formatNumber(value)}</text>
            <text x={cx} y={cy} dy={15} textAnchor="middle" fill={fill}>{`(${(percent * 100).toFixed(2)}%)`}</text>
        </g>
    );
};

export default class Donut extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeIndex: 0,
            isOthers: false,
            isUnKnown: false,
            selectValue: ""
        }
    }

    onPieEnter = (data, index) => {
        this.setState({activeIndex: index, isOthers: false});
    };

    handleClick = (data) => {
        const {onClickHandler} = this.props;
        const { key } = data;
        if(data) {
            if (key === OTHERS_KEY) {
                this.setState({isOthers: true});
                return;
            }
            if (typeof onClickHandler === 'function') { //handling click only if applicable
                if (key === UNKNOWN_KEY) { //unknown data filters are avoided
                    this.setState({isUnKnown: true});
                    return;
                }
                onClickHandler({key: key});
            }
            this.setState({isUnKnown: false, isOthers: false, selectValue: key});
        }
    };

    render () {
        const { height = 210, data = {}, title, nameKey, innerRadius = 40, outerRadius = 55, selected = [], isPending = false } = this.props;
        const {mainData = [], remainData = []} = data;
        const { isUnKnown, isOthers } = this.state;
        if(Array.isArray(data)){
            return(
                <div>
                    <ResponsiveContainer height={height}>
                        <PieChart>
                            <Pie
                                nameKey={nameKey || "key"}
                                dataKey={"value"}
                                activeIndex={this.state.activeIndex}
                                activeShape={renderActiveShape}
                                data={ data }
                                innerRadius={innerRadius}
                                outerRadius={outerRadius}
                                onMouseEnter={this.onPieEnter}
                                onClick={this.handleClick}
                                fill="#8884d8">
                                {
                                    data.map((entry, index) => <Cell
                                        key={entry.key + index}
                                        fill={selected.length > 0 && !selected.includes(entry.key) ? hexToRGBA(entry.fill): (entry.fill || randomColorWithIndex(index))}
                                    />)
                                }
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                    <Typography variant="subtitle1">{title}</Typography>
                </div>
            )
        }
        return (
            <div className="text-center no-padding">
                {mainData && mainData.length > 0 ?
                    <ResponsiveContainer height={height}>
                        <PieChart>
                            <Pie
                                nameKey={nameKey || "key"}
                                dataKey={"value"}
                                activeIndex={this.state.activeIndex}
                                activeShape={renderActiveShape}
                                data={ mainData }
                                innerRadius={innerRadius}
                                outerRadius={outerRadius}
                                onMouseEnter={this.onPieEnter}
                                onClick={this.handleClick}
                                fill="#8884d8">
                                {
                                    mainData.map((entry, index) => <Cell
                                        key={entry.key + index}
                                        fill={selected.length > 0 && !selected.includes(entry.key) ? hexToRGBA(entry.fill): entry.fill}
                                    />)
                                }
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>

                    : !isPending && <Placeholder height={height/2}/>
                }
                <div style={{width: '100%', minHeight: 55}}>
                    {remainData && remainData.length > 0 &&
                        <FormControl error={isOthers} fullWidth>
                            <InputLabel htmlFor={title}>others</InputLabel>
                            <Select
                                multiple
                                value={selected}
                                onChange={(e) => {
                                    const { value = [] } = e.target;
                                    if(selected.length < value.length){
                                        this.handleClick({key: value[value.length - 1]});
                                    }else{
                                        selected.forEach(v => {
                                            if(!value.includes(v)){
                                                this.handleClick({key: selected[selected.length - 1]});
                                            }
                                        });
                                    }
                                }}
                                input={<Input id={title}/>}
                            >
                                <MenuItem value=""><em>others</em></MenuItem>
                                {
                                    remainData.map((item, index) =>
                                        <MenuItem value={item.key} key={item[nameKey || "key"]} onClick={(e) => this.handleClick({key: item.key})}>
                                            {`${item[nameKey || "key"]} (${item.value})`}
                                        </MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                    }
                </div>
                <Typography variant="subtitle1">{title}</Typography>
                {
                    isUnKnown && <Snackbar autoHideDuration={4000}>
                        Filtering on Unknown not possible.!
                    </Snackbar>
                }
            </div>
        );
    }
}